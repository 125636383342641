import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WorkflowVersion, WorkflowVersionCreate, WorkflowVersionFull } from '@selfai-platform/pipeline-common';
import { DestroyService, SelfaiAuthService } from '@selfai-platform/shared';
import { ErrorService } from '@selfai-platform/shell';
import { WorkflowStateService } from '@selfai-platform/storage';
import { ConfirmationService } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Observable, combineLatest, from, of, switchMap, take, takeUntil } from 'rxjs';
import { VersionsManagerService, VersionManagerItemsService } from '../services';

@Component({
  selector: 'selfai-platform-versions-manager',
  templateUrl: './versions-manager.component.html',
  styleUrls: ['./versions-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [VersionManagerItemsService, DestroyService, ConfirmationService],
})
export class VersionsManagerComponent implements OnInit {
  form = new FormGroup({
    description: new FormControl('', [Validators.required]),
  });
  versions$!: Observable<WorkflowVersion[]>;
  @ViewChild('op', { static: false }) op: OverlayPanel | undefined;
  tags: string[] = ['default'];

  constructor(
    private readonly versionManagerItemsService: VersionManagerItemsService,
    private readonly selfaiAuthService: SelfaiAuthService,
    private readonly workflowStateService: WorkflowStateService,
    private readonly destroy$: DestroyService,
    private readonly versionsManagerService: VersionsManagerService,
    private readonly errorService: ErrorService,
    private readonly confirmationService: ConfirmationService,
  ) {}

  ngOnInit(): void {
    this.versions$ = this.versionManagerItemsService.getVersions();
  }

  showVersion(workflowVersion: WorkflowVersion) {
    const showVersion$ = this.versionManagerItemsService.getVersionWithContent(workflowVersion.id);
    if (showVersion$) {
      (showVersion$ as Observable<WorkflowVersionFull>)
        .pipe(
          switchMap((value) => {
            if (value) {
              return of(this.versionsManagerService.openDialogWithVersion(value));
            } else {
              return this.errorService.showError('Version not found', { message: 'Version not found' });
            }
          }),
          takeUntil(this.destroy$),
        )
        .subscribe();
    }
  }

  restoreVersion(event: Event, workflowVersion: WorkflowVersion): void {
    const target = event.target as EventTarget;
    this.confirmationService.confirm({
      target,
      message: `Are you sure that you want to restore workflow with version ${workflowVersion.versionNumber}, ${workflowVersion.description}? \n\r 
        You can lost all changes in current workflow. You should save the version before restoration`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.versionManagerItemsService.restoreVersion(workflowVersion);
      },
    });
  }

  removeVersion(event: Event, workflowVersion: WorkflowVersion): void {
    const target = event.target as EventTarget;
    this.confirmationService.confirm({
      target,
      message: `Are you sure that you want to remove workflow with version ${workflowVersion.versionNumber}, ${workflowVersion.description}?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.versionManagerItemsService.removeVersion(workflowVersion);
      },
    });
  }

  exportVersion(event: Event, workflowVersion: WorkflowVersion): void {
    this.workflowStateService
      .getWorkflowState()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data) {
          const { name } = data.thirdPartyData.gui;
          this.versionManagerItemsService.exportVersion(name, workflowVersion);
        }
      });
  }

  cloneAsWorkflow(event: Event, workflowVersion: WorkflowVersion): void {
    const target = event.target as EventTarget;
    this.confirmationService.confirm({
      target,
      message: `Are you sure that you want to restore workflow version ${workflowVersion.versionNumber} as standalone workflow?`,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.versionManagerItemsService
          .getVersionWithContent(workflowVersion.id)
          .pipe(take(1), takeUntil(this.destroy$))
          .subscribe((data) => {
            if (data) {
              this.versionsManagerService.cloneVersionToWorkflow(data);
            }
          });
      },
    });
  }

  saveNewVersionProcess(): void {
    if (this.form.invalid) {
      this.form.markAsDirty();
    } else {
      combineLatest([
        from(this.selfaiAuthService.getProvider().loadUserProfile()),
        this.workflowStateService.getWorkflowState(),
      ])
        .pipe(take(1), takeUntil(this.destroy$))
        .subscribe(([profileInfo, workflowContent]) => {
          if (workflowContent) {
            const { description } = this.form.controls;
            const newVersion: WorkflowVersionCreate = {
              authorId: profileInfo.email || '',
              authorTitle: profileInfo.username || '',
              description: description.value || '',
              content: workflowContent,
            };
            description.patchValue('');
            this.form.markAsUntouched();

            this.versionManagerItemsService.addVersion(newVersion);
          }

          this.op?.hide();
        });
    }
  }
}
