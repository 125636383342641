import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { MessageModule } from 'primeng/message';
import { SplitButtonModule } from 'primeng/splitbutton';
import { AggregatorCamelStepComponent } from './aggregator-camel-step.component';
import { StepAggregatorComponentModule } from './step-aggregator/step-aggregator.module';
import { StepFilterComponentModule } from './step-filter/step-filter.module';
import { StepOtherProcessComponentModule } from './step-other-process/step-other-process.module';
import { StepOutputComponentModule } from './step-output/step-output.module';
import { StepParallelComponentModule } from './step-parallel/step-parallel.module';
import { StepToComponentModule } from './step-to/step-to.module';
import { StepVariableComponentModule } from './step-variable/step-variable.module';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    CardModule,
    ReactiveFormsModule,
    StepFilterComponentModule,
    StepAggregatorComponentModule,
    StepParallelComponentModule,
    StepToComponentModule,
    StepOtherProcessComponentModule,
    StepOutputComponentModule,
    StepVariableComponentModule,
    SplitButtonModule,
    MessageModule,
  ],
  declarations: [AggregatorCamelStepComponent],
  exports: [AggregatorCamelStepComponent],
})
export class AggregatorCamelStepComponentModule {}
