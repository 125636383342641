import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityDataService } from '@ngrx/data';
import { KE_CURRENT_USER } from '@selfai-platform/store';
import { Profile } from '@selfai-platform/pipeline-common';
import { KeCurrentUserService } from './ke-current-user.service';

@Injectable({ providedIn: 'root' })
export class KeCurrentUserStore extends EntityCollectionServiceBase<Profile> {
  constructor(
    public entityDataService: EntityDataService,
    public keUserService: KeCurrentUserService,
    public serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    entityDataService.registerService(KE_CURRENT_USER, keUserService);
    super(KE_CURRENT_USER, serviceElementsFactory);
  }
}
