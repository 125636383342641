import { createAction, props } from '@ngrx/store';
import { UiSettings } from '@selfai-platform/bi-domain';

export const loadUiSettings = createAction(
  '[Widget] Load Widget Settings',
  props<{ widgetId: string; settings: UiSettings }>(),
);

export const setUiSettings = createAction(
  '[Widget] Set Widget Settings',
  props<{ widgetId: string; settings: UiSettings }>(),
);

export const clearUiSettings = createAction('[Widget] Clear Widget Settings', props<{ widgetId: string }>());
