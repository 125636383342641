<div [class.ddp-selectbox-disabled]="disabled">
  <div class="ddp-type-selectbox" [class.ddp-selected]="isShowOptions" (click)="onOffShowOptions()">
    <span class="ddp-txt-selectbox">
      {{ selectedItem == null ? unselectedMessage : viewKey ? selectedItem[viewKey] : selectedItem }}
    </span>
    <ul
      [ngClass]="{ 'ddp-selectdown': isUpSelect == false, 'ddp-pass': isOptionToLeft, 'ddp-pass2': !isOptionToLeft }"
      class="ddp-list-selectbox"
      infinite-scroll
      [scrollWindow]="false"
      [infiniteScrollDistance]="5"
      [infiniteScrollThrottle]="150"
      (scrolled)="onScroll()"
    >
      <li *ngFor="let item of array">
        <a href="javascript:" (click)="selectItem(item)">{{ viewKey ? item[viewKey] : item }}</a>
      </li>
    </ul>
  </div>
</div>
