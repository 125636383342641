import {
  UIFormatCurrencyType,
  UIFormatType,
  UiChartFormatNumericAlias,
  UiChartFormatSettings,
  UiChartFormatSymbolPosition,
} from '@selfai-platform/bi-domain';

export function formatValue(
  value: string | number | undefined | null,
  format: UiChartFormatSettings,
  baseline?: number,
): string {
  if (value === 'Infinity' || value === '-Infinity' || value === 'NaN') {
    return value;
  }

  if (!format) return;

  let formatedValue = value;

  if (!baseline && !isNaN(baseline) && baseline !== 0) {
    formatedValue = Number(formatedValue) + baseline;
  }

  const customSymbol = format.customSymbol;

  if (format.abbr && format.type !== 'exponent10') {
    switch (format.abbr) {
      case UiChartFormatNumericAlias.AUTO:
        formatedValue =
          Math.abs(Number(formatedValue)) > 1000000000
            ? Number(formatedValue) / 1000000000
            : Math.abs(Number(formatedValue)) > 1000000
            ? Number(formatedValue) / 1000000
            : Math.abs(Number(formatedValue)) > 1000
            ? Number(formatedValue) / 1000
            : formatedValue;
        break;
      case UiChartFormatNumericAlias.KILO:
        formatedValue = Number(formatedValue) / 1000;
        break;
      case UiChartFormatNumericAlias.MEGA:
        formatedValue = Number(formatedValue) / 1000000;
        break;
      case UiChartFormatNumericAlias.GIGA:
        formatedValue = Number(formatedValue) / 1000000000;
        break;
    }
  } else if (!customSymbol && format.type === 'percent') {
    formatedValue = Number(formatedValue) * 100;
  }

  if (format.type !== 'exponent10') {
    const decimal = format.decimal || 0;
    formatedValue = Math.round(Number(formatedValue) * Math.pow(10, decimal)) / Math.pow(10, decimal);
  }

  if (format.type !== 'exponent10' && format.useThousandsSep) {
    const arrSplitFloatPoint = String(formatedValue).split('.');

    let floatValue = '';
    if (1 < arrSplitFloatPoint.length) {
      floatValue = arrSplitFloatPoint[1];
    }

    formatedValue = arrSplitFloatPoint[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    if ('' !== floatValue) {
      formatedValue += '.' + floatValue;
    }
  }

  if (formatedValue && format.type !== 'exponent10' && format.decimal > 0) {
    const stringValue = String(formatedValue);
    if (stringValue.indexOf('.') === -1) {
      formatedValue += '.';
      for (let num = 0; num < format.decimal; num++) {
        formatedValue += '0';
      }
    } else {
      for (let num: number = stringValue.split('.')[1].length; num < format.decimal; num++) {
        formatedValue += '0';
      }
    }
  }

  if (format.abbr && format.type !== 'exponent10') {
    switch (format.abbr) {
      case UiChartFormatNumericAlias.AUTO:
        formatedValue +=
          Math.abs(Number(value)) > 1000000000
            ? 'B'
            : Math.abs(Number(value)) > 1000000
            ? 'M'
            : Math.abs(Number(value)) > 1000
            ? 'K'
            : '';
        break;
      case UiChartFormatNumericAlias.KILO:
        formatedValue += 'K';
        break;
      case UiChartFormatNumericAlias.MEGA:
        formatedValue += 'M';
        break;
      case UiChartFormatNumericAlias.GIGA:
        formatedValue += 'B';
        break;
    }
  }

  const customSymbolVal = customSymbol ? customSymbol.value : '';

  if (customSymbolVal.length === 0 && format.type === UIFormatType.CURRENCY) {
    switch (format.sign) {
      case UIFormatCurrencyType.KRW:
        formatedValue = '₩ ' + formatedValue;
        break;
      case UIFormatCurrencyType.USD:
        formatedValue = '$ ' + formatedValue;
        break;
      case UIFormatCurrencyType.USCENT:
        formatedValue = '￠ ' + formatedValue;
        break;
      case UIFormatCurrencyType.GBP:
        formatedValue = '£ ' + formatedValue;
        break;
      case UIFormatCurrencyType.JPY:
      case UIFormatCurrencyType.CNY:
        formatedValue = '¥ ' + formatedValue;
        break;
      case UIFormatCurrencyType.EUR:
        formatedValue = '€ ' + formatedValue;
        break;
      case UIFormatCurrencyType.RUB:
        formatedValue = formatedValue + ' ₽';
        break;
    }
  } else if (customSymbolVal.length === 0 && format.type === UIFormatType.PERCENT) {
    formatedValue = formatedValue + '%';
  } else if (format.type === UIFormatType.EXPONENT10) {
    formatedValue = Number(formatedValue).toExponential(format.decimal);
  }

  if (customSymbolVal.length > 0) {
    formatedValue =
      UiChartFormatSymbolPosition.BEFORE === customSymbol.pos
        ? customSymbolVal + formatedValue
        : formatedValue + customSymbolVal;
  }

  return String(formatedValue);
}
