import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { AlertService } from '@selfai-platform/shared';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private totalRequests = 0;

  protected router: Router;

  constructor(protected injector: Injector, protected alertPrimeService: AlertService) {
    this.router = injector.get(Router);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const showLoading = !request.headers.has('hideLoading');
    if (showLoading) {
      this.totalRequests += 1;
    }
    if (request.responseType === 'json') {
      const jsonHeaders = request.headers.append('x-requested-with', 'XMLHttpRequest');
      request = request.clone({ headers: jsonHeaders });
    }
    return next.handle(request).pipe(
      finalize(() => {
        if (showLoading) {
          this.decreaseRequests();
        }
      }),
    );
  }

  private decreaseRequests() {
    if (this.totalRequests > 0) {
      this.totalRequests -= 1;
    }
  }
}
