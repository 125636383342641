import { Injectable } from '@angular/core';
import { KeRefButtonEditResult, KeRefButttonEditorOptions } from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { Observable } from 'rxjs';
import { KeRefButtonItemsService } from './ke-ref-button-items.service';
import { KeRefButtonsEditorComponent } from '../components';

@Injectable()
export class KeRefButtonsService {
  constructor(
    private readonly dialogService: DialogService<KeRefButtonEditResult, KeRefButttonEditorOptions>,
    private readonly refButtonItemsService: KeRefButtonItemsService,
  ) {}

  public showEditor(data: KeRefButttonEditorOptions): Observable<KeRefButtonEditResult> {
    this.refButtonItemsService.next(data.items);

    return this.dialogService.showDialog(KeRefButtonsEditorComponent, {
      header: 'Reference buttons',
      width: '75%',
      data: data,
    });
  }
}
