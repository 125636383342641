<button
  type="button"
  pButton
  class="p-button-text p-button-link p-button-danger p-0 ml-1"
  appearance="icon"
  (click)="openErrorDialog()"
  (mouseenter)="op.show($event)"
  (mouseleave)="op.hide()"
  icon="pi pi-exclamation-triangle"
></button>

<p-overlayPanel #op>
  <ng-template pTemplate>
    <div class="surface-card">
      <div class="flex justify-content-between mb-3">
        <div>
          <span class="block font-medium mb-3">
            <ng-container [ngSwitch]="state.status">
              <div class="p-error" *ngSwitchCase="'status_failed'">
                <i class="pi pi-exclamation-triangle"></i>
                Failed
              </div>
              <div class="text-blue-500" *ngSwitchCase="'status_running'">
                <i class="pi pi-spinner"></i>
                Running
              </div>
              <div class="text-green-500" *ngSwitchCase="'status_completed'">
                <i class="pi pi-check"></i>
                Completed
              </div>
            </ng-container>
          </span>
        </div>
      </div>
      <div class="text-700">Execution start: {{ state.started | date: 'medium' }}</div>
      <span class="text-700">Execution end: {{ state.ended | date: 'medium' }}</span>
    </div>
  </ng-template>
</p-overlayPanel>
