import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AggregatorCoreApiService } from './aggregator-core-api.service';
import { AggrtConfigService } from './aggrt-config.service';

@Injectable({ providedIn: 'root' })
export class HazelCastApiService extends AggregatorCoreApiService {
  constructor(private readonly http: HttpClient, aggrtConfigService: AggrtConfigService) {
    super(aggrtConfigService);
  }

  loadHazelCast(name: string, key: string): Observable<Record<string, unknown>> {
    const url = this.buildUrl(`/hz/getValue/${name}/${key}`);

    return this.http.get<Record<string, unknown>>(url);
  }
}
