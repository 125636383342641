import { Injectable, Injector } from '@angular/core';

import { Workspace, BookTree } from '@selfai-platform/bi-domain';

import { AbstractService } from '../../common/service/abstract.service';
import { CommonUtil } from '../../common/util/common.util';
import { Page } from '../../domain/common/page';
import { Workspaces, WorkspacesResponse } from '../../domain/workspace/workspace';

export interface WorkapceApiPequestParameters {
  limit: number;
  offset: number;
  filter?: string;
  search?: string;
}

@Injectable()
export class WorkspaceService extends AbstractService {
  constructor(protected override injector: Injector) {
    super(injector);
  }

  public getMyWorkspace(projection?: string, params?: WorkapceApiPequestParameters): Promise<Workspace> {
    const urlApi = 'workspaces/my';
    const url = this.buildUrlFromWorkspaceApiParameters(urlApi, projection, params);
    return this.get(url);
  }

  public getSharedWorkspaces(projection: string, params?: any): Promise<WorkspacesResponse> {
    let url = this.API_URL + `workspaces/my/public?projection=${projection}`;

    if (params) {
      url += '&' + CommonUtil.objectToUrlString(params);
    }
    return this.get(url);
  }

  public getSharedFavoriteWorkspaces(projection: string, params?: any): Promise<Workspaces> {
    let url = this.API_URL + `workspaces/my/public?onlyFavorite=true&projection=${projection}`;

    if (params) {
      url += '&' + CommonUtil.objectToUrlString(params);
    }
    return this.get(url);
  }

  public getFolder(id: string, projection?: string) {
    return this.get(this.API_URL + 'books/' + id + '?projection=' + projection);
  }

  public changeFolder(bookIds: any, targetId: string) {
    return this.post(this.API_URL + 'books/' + bookIds + '/move/' + targetId, null);
  }

  public getWorkSpace(
    workspaceId: string,
    projection?: string,
    params?: WorkapceApiPequestParameters,
  ): Promise<Workspace> {
    const apirootUrl = 'workspaces/' + workspaceId;
    const url = this.buildUrlFromWorkspaceApiParameters(apirootUrl, projection, params);
    return this.get<Workspace>(url);
  }

  private buildUrlFromWorkspaceApiParameters(
    urlApi: string,
    projection: string | undefined,
    params?: WorkapceApiPequestParameters,
  ): string {
    let url = this.API_URL + urlApi;
    if (projection) {
      url = `${url}?projection=${projection}`;
    }
    if (params) {
      url = `${url}${projection ? '&' : '?'}limit=${params.limit}&offset=${params.offset}&filter=${
        params.filter || 'nameup'
      }`;
      if (params.search) {
        url = `${url}&search=${params.search}`;
      }
    }
    return url;
  }

  public getWorkSpaceAll(params: any, projection: string = 'forListView'): Promise<any> {
    let url = this.API_URL + `workspaces?projection=${projection}`;
    if (params) {
      url += '&' + CommonUtil.objectToUrlString(params);
    }
    return this.get(url);
  }

  public getWorkspaceMembers(workspaceId: string, type: string = 'all', page?: Page): Promise<any> {
    let url: string = this.API_URL + 'workspaces/' + workspaceId + '/members?type=' + type;
    if (page) {
      url += '&' + CommonUtil.objectToUrlString(page);
    }
    return this.get(url);
  }

  public getWorkspaceUsers(workspaceId: string, page?: Page): Promise<any> {
    return this.getWorkspaceMembers(workspaceId, 'user', page);
  }

  public getWorkspaceGroups(workspaceId: string, page?: Page): Promise<any> {
    return this.getWorkspaceMembers(workspaceId, 'group', page);
  }

  public updateWorkspaceUser(workspaceId: string, member: any[]): Promise<any[]> {
    return this.put(this.API_URL + 'workspaces/' + workspaceId + '/members', member);
  }

  public getDataSources(workspaceId: string, params: any, projection: string = 'forListInWorkspaceView'): Promise<any> {
    let url = this.API_URL + 'workspaces/' + workspaceId + '/datasources';
    if (params) {
      url += '?' + CommonUtil.objectToUrlString(params);
    }

    return this.get(url + '&projection=' + projection);
  }

  public getWorkbooks(workspaceId: string, bookId: string, projection: string): Promise<BookTree> {
    return this.get(
      this.API_URL + `workspaces/${workspaceId}/books/${bookId}?bookType=workbook&projection=${projection}`,
    );
  }

  public getWorkspaceImportAvailable(workbookId: string, params?: { excludes: string }): Promise<any> {
    let url = this.API_URL + `workspaces/import/books/${workbookId}/available`;
    if (params) {
      url += '?' + CommonUtil.objectToUrlString(params);
    }
    return this.get(url);
  }

  public createWorkspace(workspace: Workspace): Promise<any> {
    return this.post(this.API_URL + 'workspaces', workspace);
  }

  public updateWorkspace(workspaceId: string, workspace: Workspace): Promise<any> {
    return this.patch(this.API_URL + 'workspaces/' + workspaceId, workspace);
  }

  public changeRoleSetInWorkspace(workspaceId: string, fromRoleSetName: string, toRoleSetName: string, mapper: any) {
    return this.put(this.API_URL + `workspaces/${workspaceId}/rolesets/${fromRoleSetName}/to/${toRoleSetName}`, mapper);
  }

  public deleteRoleSetInWorkspace(workspaceId: string, roleSetName: string) {
    return this.delete(this.API_URL + `workspaces/${workspaceId}/rolesets/${roleSetName}`);
  }

  public setFavorite(id: string) {
    return this.post(this.API_URL + 'workspaces/' + id + '/favorite', id);
  }

  public deleteFavorite(id: string) {
    return this.delete(this.API_URL + 'workspaces/' + id + '/favorite');
  }

  public setNotebookServer(workspaceId: string, connectorIds: string[]) {
    const links = connectorIds
      .map((id) => {
        return id;
      })
      .join(',');
    return this.post(this.API_URL + `workspaces/${workspaceId}/connectors/${links}`, null);
  }

  public getNotebookServers(workspaceId: string) {
    return this.get(this.API_URL + `workspaces/${workspaceId}/connectors`);
  }

  public getConnections(workspaceId: string, params: any) {
    let url = this.API_URL + `workspaces/${workspaceId}/connections`;
    if (params) {
      url += '?' + CommonUtil.objectToUrlString(params);
    }
    return this.get(url);
  }

  public getWorkspaceByAdmin(params: any) {
    let url = this.API_URL + `workspaces/byadmin`;
    if (params) {
      url += '?' + CommonUtil.objectToUrlString(params);
    }
    return this.get(url);
  }

  public changeWorkspaceStatus(workspaceId: string, status: string): Promise<any> {
    return this.post(this.API_URL + `workspaces/${workspaceId}/activate/${status}`, null);
  }

  public transferWorkspaceOwner(workspaceId: string, userName: string): Promise<any> {
    return this.post(this.API_URL + `workspaces/${workspaceId}/delegate/${userName}`, null);
  }

  public getNoteBookConnectorList(workspaceId: string, params: object, projection: string = 'default'): Promise<any> {
    let url = this.API_URL + `workspaces/${workspaceId}/connectors`;
    if (params) {
      url += '?' + CommonUtil.objectToUrlString(params);
    }
    return this.get(url + `&projection=${projection}`);
  }

  public getDataConnectionList(workspaceId: string, params: object, projection: string = 'default'): Promise<any> {
    let url = this.API_URL + `workspaces/${workspaceId}/connections`;
    if (params) {
      url += '?' + CommonUtil.objectToUrlString(params);
    }
    return this.get(url + `&projection=${projection}`);
  }

  public getWorkspaceStatistics(workspaceId: string, params: object): Promise<any> {
    let url = this.API_URL + `workspaces/${workspaceId}/statistics`;
    if (params) {
      url += '?' + CommonUtil.objectToUrlString(params);
    }
    return this.get(url);
  }

  public getDefaultSharedWorkspaceId(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.getSharedWorkspaces('forListView')
        .then((data) => {
          resolve(data._embedded.workspaces[0].id);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
