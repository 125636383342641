<div class="ddp-ui-chart-side ddp-show">
  <div class="ddp-ui-side-title">
    {{ 'msg.page.ui.number.format.title' | translate }}
  </div>
  <div class="ddp-wrap-downmenu">
    <div class="ddp-box-down">
      <div class="ddp-list-label">
        {{ 'msg.comm.name.mea' | translate }}
      </div>
      <component-select
        [array]="uiOption.fieldMeasureList"
        [viewKey]="'alias'"
        [defaultIndex]="getFieldIndex()"
        (onSelected)="formatByMeasure($event)"
      ></component-select>
    </div>
    <div
      *ngIf="selectedField"
      format-item
      class="ddp-box-down"
      #commonFormat
      (changeFormat)="onChange($event)"
      [format]="format"
      [uiOption]="uiOption"
    ></div>
  </div>
</div>
