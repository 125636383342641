import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DialogService } from '../../dialog';
import { ErrorOptions } from '../error-options';

@Component({
  selector: 'selfai-platform-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorDialogComponent implements OnInit {
  error?: ErrorOptions;

  constructor(private readonly dialogService: DialogService<void, ErrorOptions>) {}

  ngOnInit(): void {
    this.error = this.dialogService.data;
  }

  sendOk() {
    this.dialogService.close();
  }
}
