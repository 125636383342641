import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ColorPickerModule } from 'primeng/colorpicker';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ChartMarkLineSettingComponent } from './chart-mark-line-setting.component';

@NgModule({
  imports: [
    CommonModule,
    DropdownModule,
    ColorPickerModule,
    InputTextModule,
    TranslateModule,
    ReactiveFormsModule,
    SelectButtonModule,
  ],
  declarations: [ChartMarkLineSettingComponent],
  exports: [ChartMarkLineSettingComponent],
})
export class ChartMarkLineSettingComponentModule {}
