<div class="ddp-type-contents ddp-resize">
  <a (click)="closeComp()" href="javascript:" class="ddp-btn-close"></a>

  <div class="ddp-type-contents-in ddp-type" *ngIf="dashboard">
    <div class="ddp-data-board-title">
      <em class="ddp-icon-dashboard2"></em>
      {{ dashboard.name }}
    </div>

    <create-board-ds-network
      [workspaceId]="workspaceId"
      [dashboard]="dashboard"
      (onChange)="checkAllowDone($event)"
    ></create-board-ds-network>
  </div>

  <div class="ddp-ui-buttons">
    <a (click)="closeComp()" href="javascript:" class="ddp-btn-type-popup">{{ 'msg.comm.btn.cancl' | translate }}</a>

    <a (click)="save()" [class.ddp-disabled]="isDenyDone" href="javascript:" class="ddp-btn-type-popup ddp-bg-black">
      {{ 'msg.comm.btn.save' | translate }}
    </a>
  </div>
</div>
