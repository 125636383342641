/* eslint-disable max-lines */
import {
  AxisType,
  BaseOption,
  CHART_STRING_DELIMITER,
  ChartAxisLabelType,
  ChartColorType,
  ChartType,
  Pivot,
  PivotField,
  PivotTableInfo,
  ShelveType,
  UIChartAxis,
  UIChartAxisLabelValue,
  UIChartColorByDimension,
  UIChartDataLabel,
  UIChartFormat,
  UIChartFormatItem,
  UIFormatCurrencyType,
  UIFormatNumericAliasType,
  UIFormatType,
  UIOption,
  UiChartDataLabelDisplayType,
  UiChartFormatSymbolPosition,
} from '@selfai-platform/bi-domain';
import { TooltipComponentOption } from 'echarts';
import * as _ from 'lodash';

export class FormatOptionConverter {
  public static convertFormatSeries(chartOption: BaseOption, uiOption: UIOption, pivot: Pivot): BaseOption {
    let format: UIChartFormat = uiOption.valueFormat;
    if (_.isUndefined(format)) {
      return chartOption;
    }

    const axisFormat = this.getlabelAxisScaleFormat(uiOption);
    if (axisFormat) format = axisFormat;

    const series = chartOption.series;

    _.each(series, (option: any, index) => {
      if (_.isUndefined(option.label)) {
        option.label = {};
      }
      if (_.isUndefined(option.label.normal)) {
        option.label = {};
      }

      option.label.formatter = (params): string => {
        let uiData = _.cloneDeep(option.uiData);

        if (uiData && uiData instanceof Array) uiData = option.uiData[params.dataIndex];

        return this.getFormatValueSeries(params, format, pivot, uiOption, option, uiData);
      };
    });

    return chartOption;
  }

  public static convertFormatTooltip(
    chartOption: BaseOption,
    uiOption: UIOption,
    fieldInfo: PivotTableInfo,
    pivot: Pivot,
  ): BaseOption {
    let format: UIChartFormat = uiOption.valueFormat;
    if (_.isUndefined(format)) {
      return chartOption;
    }

    const axisFormat = this.getlabelAxisScaleFormatTooltip(uiOption);
    if (axisFormat) format = axisFormat;

    if (_.isUndefined(chartOption.tooltip)) {
      chartOption.tooltip = {};
    }
    (chartOption.tooltip as TooltipComponentOption).formatter = (params): any => {
      const option = chartOption.series[params.seriesIndex];

      let uiData = _.cloneDeep(option.uiData);

      if (uiData && uiData instanceof Array) uiData = option.uiData[params.dataIndex];

      return this.getFormatValueTooltip(params, uiOption, fieldInfo, format, pivot, option, uiData);
    };

    return chartOption;
  }

  public static getFormatValue(value: any, format: UIChartFormatItem, baseline?: number): string {
    if (value === 'Infinity' || value === '-Infinity' || value === 'NaN') {
      return value;
    }

    if (!format) return;

    if (!_.isUndefined(baseline) && !isNaN(baseline) && baseline != 0) {
      value += baseline;
    }

    const customSymbol = format.customSymbol;
    const originalValue = _.cloneDeep(value);

    if (format.abbr && format.type != String(UIFormatType.EXPONENT10)) {
      switch (format.abbr) {
        case String(UIFormatNumericAliasType.AUTO):
          value =
            Math.abs(value) > 1000000000
              ? Number(value) / 1000000000
              : Math.abs(value) > 1000000
              ? Number(value) / 1000000
              : Math.abs(value) > 1000
              ? Number(value) / 1000
              : value;
          break;
        case String(UIFormatNumericAliasType.KILO):
          value = Number(value) / 1000;
          break;
        case String(UIFormatNumericAliasType.MEGA):
          value = Number(value) / 1000000;
          break;
        case String(UIFormatNumericAliasType.GIGA):
          value = Number(value) / 1000000000;
          break;
      }
    } else if (!customSymbol && format.type == String(UIFormatType.PERCENT)) {
      value = value * 100;
    }

    if (format.type != String(UIFormatType.EXPONENT10)) {
      value = Math.round(Number(value) * Math.pow(10, format.decimal)) / Math.pow(10, format.decimal);
    }

    if (format.type !== 'exponent10' && format.useThousandsSep) {
      const arrSplitFloatPoint = String(value).split('.');

      let floatValue = '';
      if (1 < arrSplitFloatPoint.length) {
        floatValue = arrSplitFloatPoint[1];
      }

      value = arrSplitFloatPoint[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      if ('' !== floatValue) {
        value += '.' + floatValue;
      }
    }

    if (value && format.type != String(UIFormatType.EXPONENT10) && format.decimal > 0) {
      const stringValue = String(value);
      if (stringValue.indexOf('.') == -1) {
        value += '.';
        for (let num = 0; num < format.decimal; num++) {
          value += '0';
        }
      } else {
        for (let num: number = stringValue.split('.')[1].length; num < format.decimal; num++) {
          value += '0';
        }
      }
    }

    if (format.abbr && format.type != String(UIFormatType.EXPONENT10)) {
      switch (format.abbr) {
        case String(UIFormatNumericAliasType.AUTO):
          value +=
            Math.abs(originalValue) > 1000000000
              ? 'B'
              : Math.abs(originalValue) > 1000000
              ? 'M'
              : Math.abs(originalValue) > 1000
              ? 'K'
              : '';
          break;
        case String(UIFormatNumericAliasType.KILO):
          value += 'K';
          break;
        case String(UIFormatNumericAliasType.MEGA):
          value += 'M';
          break;
        case String(UIFormatNumericAliasType.GIGA):
          value += 'B';
          break;
      }
    }

    const customSymbolVal = customSymbol ? customSymbol.value : '';

    if (0 === customSymbolVal.length && format.type == String(UIFormatType.CURRENCY)) {
      switch (format.sign) {
        case String(UIFormatCurrencyType.RUB):
          value = '₽ ' + value;
          break;
        case String(UIFormatCurrencyType.KRW):
          value = '₩ ' + value;
          break;
        case String(UIFormatCurrencyType.USD):
          value = '$ ' + value;
          break;
        case String(UIFormatCurrencyType.USCENT):
          value = '￠ ' + value;
          break;
        case String(UIFormatCurrencyType.GBP):
          value = '£ ' + value;
          break;
        case String(UIFormatCurrencyType.JPY):
        case String(UIFormatCurrencyType.CNY):
          value = '¥ ' + value;
          break;
        case String(UIFormatCurrencyType.EUR):
          value = '€ ' + value;
          break;
      }
    } else if (0 === customSymbolVal.length && format.type == String(UIFormatType.PERCENT)) {
      value = value + '%';
    } else if (format.type == String(UIFormatType.EXPONENT10)) {
      value = Number(value).toExponential(format.decimal);
    }

    if (customSymbolVal.length > 0) {
      value =
        UiChartFormatSymbolPosition.BEFORE == customSymbol.pos ? customSymbolVal + value : value + customSymbolVal;
    }

    return value;
  }

  public static getFormatValueSeries(
    params: any,
    format: UIChartFormat,
    pivot: Pivot,
    uiOption?: UIOption,
    series?: any,
    uiData?: any,
  ): string {
    if (uiData) {
      if (!uiOption.dataLabel || !uiOption.dataLabel.displayTypes) return '';

      let isUiData = false;
      let result: string[] = [];
      if (
        uiData['categoryName'] &&
        -1 !== uiOption.dataLabel.displayTypes.indexOf(UiChartDataLabelDisplayType.CATEGORY_NAME)
      ) {
        if (typeof uiData['categoryName'] === 'string') {
          const categoryList = _.split(uiData['categoryName'], CHART_STRING_DELIMITER);
          result = this.getTooltipName(categoryList, pivot.columns, result);
        } else {
          const categoryList = _.split(uiData['categoryName'][params.dataIndex], CHART_STRING_DELIMITER);

          result = this.getTooltipName(categoryList, pivot.columns, result);
        }
        isUiData = true;
      }
      if (
        uiData['categoryValue'] &&
        uiData['categoryValue'].length > 0 &&
        -1 !== uiOption.dataLabel.displayTypes.indexOf(UiChartDataLabelDisplayType.CATEGORY_VALUE)
      ) {
        result.push(this.getFormatValue(uiData['categoryValue'][params.dataIndex], format));
        isUiData = true;
      }
      if (
        uiData['categoryPercent'] &&
        uiData['categoryPercent'].length > 0 &&
        -1 !== uiOption.dataLabel.displayTypes.indexOf(UiChartDataLabelDisplayType.CATEGORY_PERCENT)
      ) {
        let value = uiData['categoryPercent'][params.dataIndex];
        value = (Math.floor(Number(value) * Math.pow(10, format.decimal)) / Math.pow(10, format.decimal)).toFixed(
          format.decimal,
        );
        result.push(value + '%');
        isUiData = true;
      }

      if (
        uiData['seriesName'] &&
        -1 !== uiOption.dataLabel.displayTypes.indexOf(UiChartDataLabelDisplayType.SERIES_NAME)
      ) {
        const seriesName = '';
        const seriesNameList = _.split(params.seriesName, CHART_STRING_DELIMITER);

        if (seriesNameList.length > 1) {
          const pivotList = _.cloneDeep(pivot.rows);

          result = this.getTooltipName(seriesNameList, pivotList, result, false, pivot, ShelveType.ROWS);
        } else if (pivot.aggregations.length > 1) {
          const pivotList = _.cloneDeep(pivot.aggregations);

          result = this.getTooltipName(seriesNameList, pivotList, result, false, pivot, ShelveType.AGGREGATIONS);
        } else {
          if (typeof uiData['categoryName'] === 'string') {
            result = this.getTooltipName(
              _.split(uiData['categoryName'], CHART_STRING_DELIMITER),
              pivot.columns,
              result,
            );
          } else {
            const categoryList = _.split(uiData['categoryName'][params.dataIndex], CHART_STRING_DELIMITER);

            result = this.getTooltipName(categoryList, pivot.columns, result);
          }
        }
        isUiData = true;
      }
      if (
        uiData['seriesValue'] &&
        -1 !== uiOption.dataLabel.displayTypes.indexOf(UiChartDataLabelDisplayType.SERIES_VALUE)
      ) {
        const seriesValue =
          typeof uiData['seriesValue'][params.dataIndex] === 'undefined'
            ? uiData['seriesValue']
            : uiData['seriesValue'][params.dataIndex];
        result.push(this.getFormatValue(seriesValue, format));
        isUiData = true;
      }
      if (
        uiData['seriesPercent'] &&
        uiData['seriesPercent'].length > 0 &&
        -1 !== uiOption.dataLabel.displayTypes.indexOf(UiChartDataLabelDisplayType.SERIES_PERCENT)
      ) {
        let value = uiData['seriesPercent'][params.dataIndex];
        value = (Math.floor(Number(value) * Math.pow(10, format.decimal)) / Math.pow(10, format.decimal)).toFixed(
          format.decimal,
        );
        result.push(value + '%');
        isUiData = true;
      }
      if (
        uiData['xAxisValue'] &&
        -1 !== uiOption.dataLabel.displayTypes.indexOf(UiChartDataLabelDisplayType.XAXIS_VALUE)
      ) {
        const axisFormat = this.getlabelAxisScaleFormat(uiOption, AxisType.X);
        result.push(this.getFormatValue(uiData['xAxisValue'], axisFormat ? axisFormat : uiOption.valueFormat));
        isUiData = true;
      }
      if (
        uiData['yAxisValue'] &&
        -1 !== uiOption.dataLabel.displayTypes.indexOf(UiChartDataLabelDisplayType.YAXIS_VALUE)
      ) {
        const axisFormat = this.getlabelAxisScaleFormat(uiOption, AxisType.Y);
        result.push(this.getFormatValue(uiData['yAxisValue'], axisFormat ? axisFormat : uiOption.valueFormat));
        isUiData = true;
      }
      if (uiData['value'] && -1 !== uiOption.dataLabel.displayTypes.indexOf(UiChartDataLabelDisplayType.VALUE)) {
        result.push(this.getFormatValue(params.value, format));
        isUiData = true;
      }
      if (uiData['nodeName'] && -1 !== uiOption.dataLabel.displayTypes.indexOf(UiChartDataLabelDisplayType.NODE_NAME)) {
        result.push(uiData['nodeName'][params.dataIndex]);
        isUiData = true;
      }

      let label = '';

      if (isUiData) {
        for (let num = 0; num < result.length; num++) {
          if (num > 0) {
            label += '\n';
          }
          if (series.label && series.label.rich) {
            label += '{align|' + result[num] + '}';
          } else {
            label += result[num];
          }
        }

        return label;
      } else {
        return label;
      }
    }

    return this.noUIDataFormat(params, format);
  }

  public static noUIDataFormat(params: any, format: UIChartFormat): string {
    if (params.componentType === 'markLine') {
      return params.data.value;
    } else if (params.componentType === 'series') {
      if (_.isUndefined(params.value)) return '';
      let value = _.isArray(params.value) ? _.last(params.value) : params.value;
      if (_.isNull(value)) return;

      if (format && format.isAll) {
        value = this.getFormatValue(value, format);
      } else if (format && !format.isAll) {
        for (const eachFormat of format.each) {
          if (
            params.seriesName == eachFormat.name ||
            params.seriesName == eachFormat.aggregationType + '(' + eachFormat.name + ')' ||
            params.name == eachFormat.name ||
            params.name == eachFormat.aggregationType + '(' + eachFormat.name + ')'
          ) {
            value = this.getFormatValue(value, eachFormat);
          }
        }
      } else {
        value = value.toLocaleString();
      }

      return value;
    }
  }

  public static getlabelAxisScaleFormat(uiOption: UIOption, axisType?: AxisType): UIChartFormat {
    const label: UIChartDataLabel = uiOption.dataLabel;

    if (!label || typeof label.useDefaultFormat === 'undefined' || true === label.useDefaultFormat) return null;

    let valueAxis: UIChartAxis;

    if (
      (uiOption.xAxis && ChartAxisLabelType.VALUE == uiOption.xAxis.label.type && !axisType) ||
      (axisType && axisType == AxisType.X)
    ) {
      valueAxis = uiOption.xAxis;
    }

    if (
      (uiOption.yAxis && ChartAxisLabelType.VALUE == uiOption.yAxis.label.type && !axisType) ||
      (axisType && axisType == AxisType.Y)
    ) {
      valueAxis = uiOption.yAxis;
    }

    const axisFormat = <UIChartAxisLabelValue>valueAxis.label ? (<UIChartAxisLabelValue>valueAxis.label).format : null;

    if (!valueAxis || !axisFormat) return null;

    return axisFormat;
  }

  public static getlabelAxisScaleFormatTooltip(uiOption: UIOption, axisType?: AxisType): UIChartFormat {
    const toolTip: UIChartDataLabel = uiOption.toolTip;

    if (!toolTip || typeof toolTip.useDefaultFormat === 'undefined' || true === toolTip.useDefaultFormat) return null;

    let valueAxis: UIChartAxis;

    if (
      (uiOption.xAxis && ChartAxisLabelType.VALUE == uiOption.xAxis.label.type && !axisType) ||
      (axisType && axisType == AxisType.X)
    ) {
      valueAxis = uiOption.xAxis;
    }

    if (
      (uiOption.yAxis && ChartAxisLabelType.VALUE == uiOption.yAxis.label.type && !axisType) ||
      (axisType && axisType == AxisType.Y)
    ) {
      valueAxis = uiOption.yAxis;
    }

    const axisFormat = <UIChartAxisLabelValue>valueAxis.label ? (<UIChartAxisLabelValue>valueAxis.label).format : null;

    if (!valueAxis || !axisFormat) return null;

    return axisFormat;
  }

  public static getTooltipName(
    categoryList: any,
    targetPivotList: PivotField[],
    result: any,
    titleUseFl?: boolean,
    pivot?: Pivot,
    pivotType?: ShelveType,
  ): any {
    categoryList.forEach((item, index) => {
      const targetPivot = (
        pivotType && ShelveType.AGGREGATIONS == pivotType
          ? _.find(targetPivotList, { alias: item })
          : targetPivotList[index]
      ) as PivotField;

      if (!targetPivot) return result;

      if ('timestamp' == targetPivot.type) {
        let resultData = '';

        if (titleUseFl) {
          let granularity = targetPivot.format.unit.toString().slice(0, 1).toUpperCase();
          granularity += targetPivot.format.unit
            .toString()
            .slice(1, targetPivot.format.unit.toString().length)
            .toLowerCase();

          const name = targetPivot['fieldAlias'] ? targetPivot['fieldAlias'] : targetPivot.name;

          const defaultAlias = targetPivot.granularity + '(' + name + ')';

          if (defaultAlias === targetPivot.alias) {
            resultData = granularity + ' of ' + name + ' : ';
          } else {
            resultData = targetPivot['alias'] + ' : ';
          }
        }

        resultData += item;
        result.push(resultData);
      } else if ('measure' == targetPivot.type) {
        if (pivot && pivot.aggregations.length > 1) {
          let aggregationType: string;
          if (targetPivot.aggregationType) {
            aggregationType = targetPivot.aggregationType.toString().slice(0, 1).toUpperCase();
            aggregationType += targetPivot.aggregationType
              .toString()
              .slice(1, targetPivot.aggregationType.toString().length)
              .toLowerCase();

            if ('Avg' == aggregationType) aggregationType = 'Average';
          }

          const name = targetPivot['fieldAlias'] ? targetPivot['fieldAlias'] : targetPivot.name;

          const defaultAlias = targetPivot.aggregationType + '(' + name + ')';

          if (defaultAlias === targetPivot.alias) {
            result.push((aggregationType ? aggregationType + ' of ' : '') + name);
          } else {
            result.push(targetPivot['alias']);
          }
        }
      } else {
        let resultData = '';
        if (titleUseFl) {
          resultData = targetPivot.alias + ' : ';
        }

        resultData += item;
        result.push(resultData);
      }
    });

    return result;
  }

  public static getTooltipValue(
    aliasValue: string,
    aggregations: PivotField[],
    format: UIChartFormat,
    value: number,
    seriesName?: string,
  ): string {
    let seriesValue = '';

    let aggValue = _.find(aggregations, { alias: aliasValue });

    if (!aggValue && seriesName) aggValue = _.find(aggregations, { alias: seriesName });

    const aggValueName = aggValue.fieldAlias ? aggValue.fieldAlias : aggValue.name;

    const defaultAlias = aggValue.aggregationType + '(' + aggValueName + ')';

    if (defaultAlias === aggValue.alias) {
      let aggregationType = '';
      if (aggValue.aggregationType) {
        aggregationType = aggValue.aggregationType.toString().slice(0, 1).toUpperCase();
        aggregationType += aggValue.aggregationType
          .toString()
          .slice(1, aggValue.aggregationType.toString().length)
          .toLowerCase();

        if ('Avg' == aggregationType) aggregationType = 'Average';
        aggregationType += ' of ';
      }

      seriesValue = aggregationType + aggValueName + ' : ' + this.getFormatValue(value, format);
    } else {
      seriesValue = aggValue.alias + ' : ' + this.getFormatValue(value, format);
    }

    return seriesValue;
  }

  public static getFormatValueTooltip(
    params: any,
    uiOption: UIOption,
    fieldInfo: PivotTableInfo,
    format: UIChartFormat,
    pivot: Pivot,
    series?: any,
    uiData?: any,
  ): string {
    if (uiData) {
      if (uiOption?.measureValueFormat) {
        format = uiOption.measureValueFormat[uiData.name];
      }
      if (!uiOption.toolTip) uiOption.toolTip = {};
      if (!uiOption.toolTip.displayTypes) uiOption.toolTip.displayTypes = this.setDisplayTypes(uiOption.type, pivot);

      let result: string[] = [];
      if (
        uiData['categoryName'] &&
        -1 !== uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.CATEGORY_NAME)
      ) {
        if (typeof uiData['categoryName'] === 'string') {
          result = this.getTooltipName([uiData['categoryName']], pivot.columns, result, true);
        } else {
          const categoryList = _.split(uiData['categoryName'][params.dataIndex], CHART_STRING_DELIMITER);

          result = this.getTooltipName(categoryList, pivot.columns, result, true);
        }
      }
      if (
        uiData['categoryValue'] &&
        uiData['categoryValue'].length > 0 &&
        -1 !== uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.CATEGORY_VALUE)
      ) {
        const splitValue = _.split(uiData.name, CHART_STRING_DELIMITER);
        const name = splitValue[splitValue.length - 1];
        const seriesName =
          -1 !== uiData.name.indexOf(CHART_STRING_DELIMITER) ? splitValue[splitValue.length - 1] : uiData.name;

        let categoryValue = FormatOptionConverter.getTooltipValue(
          name,
          pivot.aggregations,
          format,
          uiData['categoryValue'][params.dataIndex],
          seriesName,
        );

        if (-1 !== uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.CATEGORY_PERCENT)) {
          let value = uiData['categoryPercent'][params.dataIndex];

          value = (Math.floor(Number(value) * Math.pow(10, format.decimal)) / Math.pow(10, format.decimal)).toFixed(
            format.decimal,
          );

          categoryValue += ' (' + value + '%)';
        }

        result.push(categoryValue);
      }
      if (
        uiData['categoryPercent'] &&
        uiData['categoryPercent'].length > 0 &&
        -1 !== uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.CATEGORY_PERCENT)
      ) {
        if (-1 == uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.CATEGORY_VALUE)) {
          let value = uiData['categoryPercent'][params.dataIndex];
          value = Math.floor(Number(value) * Math.pow(10, format.decimal)) / Math.pow(10, format.decimal);

          const splitData = _.split(uiData.name, CHART_STRING_DELIMITER);
          const name =
            -1 !== uiData.name.indexOf(CHART_STRING_DELIMITER) ? splitData[splitData.length - 1] : uiData.name;

          const formatType = _.cloneDeep(format.type);
          format.type = 'number';
          let categoryValue = FormatOptionConverter.getTooltipValue(name, pivot.aggregations, format, value);
          categoryValue += '%';
          format.type = formatType;

          result.push(categoryValue);
        }
      }

      if (
        uiData['seriesName'] &&
        -1 !== uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.SERIES_NAME)
      ) {
        const seriesNameList = _.split(params.seriesName, CHART_STRING_DELIMITER);

        if (seriesNameList.length > 1) {
          const pivotList = _.cloneDeep(pivot.rows);

          result = this.getTooltipName(seriesNameList, pivotList, result, true, pivot, ShelveType.ROWS);
        } else {
          if (typeof uiData['seriesName'] === 'string') {
            result = this.getTooltipName([uiData['seriesName']], pivot.columns, result, true);
          } else {
            const categoryList = _.split(uiData['seriesName'][params.dataIndex], CHART_STRING_DELIMITER);

            result = this.getTooltipName(categoryList, pivot.columns, result, true);
          }
        }
      }
      if (
        uiData['seriesValue'] &&
        -1 !== uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.SERIES_VALUE)
      ) {
        const splitData = _.split(uiData.name, CHART_STRING_DELIMITER);
        const name = -1 !== uiData.name.indexOf(CHART_STRING_DELIMITER) ? splitData[splitData.length - 1] : uiData.name;

        const value =
          typeof uiData['seriesValue'][params.dataIndex] === 'undefined'
            ? uiData['seriesValue']
            : uiData['seriesValue'][params.dataIndex];

        let seriesValue = FormatOptionConverter.getTooltipValue(name, pivot.aggregations, format, value);

        if (
          typeof uiData['seriesPercent'][params.dataIndex] !== 'undefined' &&
          -1 !== uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.SERIES_PERCENT)
        ) {
          let value = uiData['seriesPercent'][params.dataIndex];
          value = (Math.floor(Number(value) * Math.pow(10, format.decimal)) / Math.pow(10, format.decimal)).toFixed(
            format.decimal,
          );

          seriesValue += ' (' + value + '%)';
        }

        result.push(seriesValue);
      }
      if (
        uiData['seriesPercent']?.length > 0 &&
        typeof uiData['seriesPercent'][params.dataIndex] !== 'undefined' &&
        -1 !== uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.SERIES_PERCENT)
      ) {
        if (-1 == uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.SERIES_VALUE)) {
          let value = uiData['seriesPercent'][params.dataIndex];
          value = Math.floor(Number(value) * Math.pow(10, format.decimal)) / Math.pow(10, format.decimal);

          const splitData = _.split(uiData.name, CHART_STRING_DELIMITER);
          const name =
            -1 !== uiData.name.indexOf(CHART_STRING_DELIMITER) ? splitData[splitData.length - 1] : uiData.name;

          let seriesPercent = FormatOptionConverter.getTooltipValue(name, pivot.aggregations, format, value);

          seriesPercent += '%';
          result.push(seriesPercent);
        }
      }

      return result.join('<br/>');
    }

    return this.noUIDataFormatTooltip(uiOption, params, format, fieldInfo);
  }

  public static setDisplayTypes(chartType: ChartType, pivot?: Pivot): UiChartDataLabelDisplayType[] {
    const displayTypes = [];

    switch (chartType) {
      case ChartType.BAR:
      case ChartType.LINE:
      case ChartType.COMBINE:
        if (
          ((chartType === ChartType.BAR || chartType === ChartType.LINE) &&
            pivot?.aggregations &&
            pivot.aggregations.length <= 1 &&
            pivot.rows.length < 1) ||
          (chartType === ChartType.COMBINE && pivot.aggregations.length <= 1)
        ) {
          displayTypes[0] = UiChartDataLabelDisplayType.CATEGORY_NAME;
          displayTypes[1] = UiChartDataLabelDisplayType.CATEGORY_VALUE;
        } else {
          displayTypes[3] = UiChartDataLabelDisplayType.SERIES_NAME;
          displayTypes[4] = UiChartDataLabelDisplayType.SERIES_VALUE;
        }
        break;

      case ChartType.CONTROL:
      case ChartType.WATERFALL:
        displayTypes[0] = UiChartDataLabelDisplayType.CATEGORY_NAME;
        displayTypes[1] = UiChartDataLabelDisplayType.CATEGORY_VALUE;
        break;
      case ChartType.HEATMAP:
      case ChartType.GAUGE:
        displayTypes[0] = UiChartDataLabelDisplayType.CATEGORY_NAME;
        break;
      case ChartType.TREEMAP:
      case ChartType.PIE:
        displayTypes[3] = UiChartDataLabelDisplayType.SERIES_NAME;
        displayTypes[4] = UiChartDataLabelDisplayType.SERIES_VALUE;
        break;
      case ChartType.SCATTER:
        displayTypes[3] = UiChartDataLabelDisplayType.SERIES_NAME;
        break;
      case ChartType.BOXPLOT:
        displayTypes[0] = UiChartDataLabelDisplayType.CATEGORY_NAME;
        displayTypes[12] = UiChartDataLabelDisplayType.HIGH_VALUE;
        displayTypes[13] = UiChartDataLabelDisplayType.THREE_Q_VALUE;
        displayTypes[14] = UiChartDataLabelDisplayType.MEDIAN_VALUE;
        displayTypes[15] = UiChartDataLabelDisplayType.FIRST_Q_VALUE;
        displayTypes[16] = UiChartDataLabelDisplayType.LOW_VALUE;
        break;
      case ChartType.SANKEY:
        displayTypes[9] = UiChartDataLabelDisplayType.NODE_NAME;
        displayTypes[11] = UiChartDataLabelDisplayType.NODE_VALUE;
        break;
      case ChartType.GRAPH:
      case ChartType.GRAPHV2:
        displayTypes[9] = UiChartDataLabelDisplayType.NODE_NAME;
        displayTypes[10] = UiChartDataLabelDisplayType.LINK_VALUE;
        break;
      case ChartType.RADAR:
        displayTypes[0] = UiChartDataLabelDisplayType.CATEGORY_NAME;
        displayTypes[8] = UiChartDataLabelDisplayType.VALUE;
        break;
      case ChartType.MAP:
        displayTypes[17] = UiChartDataLabelDisplayType.LAYER_NAME;
        displayTypes[18] = UiChartDataLabelDisplayType.LOCATION_INFO;
        displayTypes[19] = UiChartDataLabelDisplayType.DATA_VALUE;
    }

    return displayTypes;
  }

  public static noUIDataFormatTooltip(
    uiOption: UIOption,
    params: any,
    format: UIChartFormat,
    fieldInfo: PivotTableInfo,
  ): string {
    const colorType: ChartColorType = uiOption.color.type;
    const targetField: string = (<UIChartColorByDimension>uiOption.color).targetField;

    if (params.componentType === 'markLine') {
      return params.seriesName + '<br />' + params.data.value;
    } else if (params.componentType === 'series') {
      const colorEl = params.marker;

      let legendName = '';

      if (_.isUndefined(params.value)) return '';
      let value = _.isArray(params.value) ? _.last(params.value) : params.value;
      if (_.isNull(value)) return;

      if (format && format.isAll) {
        value = this.getFormatValue(value, format);
      } else if (format && !format.isAll) {
        for (const eachFormat of format.each) {
          if (
            params.seriesName == eachFormat.name ||
            params.seriesName == eachFormat.aggregationType + '(' + eachFormat.name + ')' ||
            params.name == eachFormat.name ||
            params.name == eachFormat.aggregationType + '(' + eachFormat.name + ')'
          ) {
            value = this.getFormatValue(value, eachFormat);
          }
        }
      } else {
        value = value.toLocaleString();
      }

      let seriesName = '';

      if (params.seriesName !== params.componentSubType)
        seriesName = params.name + CHART_STRING_DELIMITER + params.seriesName;

      switch (colorType) {
        case ChartColorType.DIMENSION:
          {
            let fieldIdx = _.indexOf(fieldInfo.cols, targetField);

            const nameArr =
              fieldIdx < 0
                ? _.split(params.seriesName, CHART_STRING_DELIMITER)
                : _.split(params.name, CHART_STRING_DELIMITER);
            if (fieldIdx < 0) fieldIdx = _.indexOf(fieldInfo.rows, targetField);

            legendName = nameArr[fieldIdx];
          }

          break;

        case ChartColorType.SERIES:
          legendName = _.last(_.split(params.seriesName, CHART_STRING_DELIMITER));
          break;

        case ChartColorType.MEASURE:
          legendName = '';
          break;
      }

      if (params && params.data.name && params.data.value)
        legendName = _.last(_.split(params.data.name, CHART_STRING_DELIMITER));

      if (legendName && '' !== legendName) value = ' : ' + value;

      return colorEl + legendName + value + '<br />' + seriesName;
    }
  }

  public static getDecimalValue(value: any, decimal: number, useThousandsSep: boolean): string {
    const numberValue = Number(value);

    if (useThousandsSep) {
      return numberValue.toLocaleString(undefined, { maximumFractionDigits: decimal, minimumFractionDigits: decimal });
    } else return numberValue.toFixed(decimal);
  }

  public static getNumberValue(value: any): number {
    if (_.isUndefined(value) || _.isNull(value)) return 0;

    return parseFloat(
      value
        .toString()
        .replace(',', '.')
        .replace(/[^0-9.]+/g, ''),
    );
  }

  public static getNumberValueForMinMaxAxis(value: any): number {
    if (_.isUndefined(value) || _.isNull(value)) return 0;

    const formattedValue = value.toString().split(',').join('');
    return parseFloat(
      formattedValue
        .toString()
        .replace(/[^0-9.]+/g, ''),
    );
  }
}
