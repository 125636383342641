import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { PipelineConfigService } from '@selfai-platform/shared';
import { KE_WORKFLOW_SETTINGS_PRESETS_ENTITY_NAME } from '@selfai-platform/store';
import { Observable } from 'rxjs';
import { WorkflowPresets } from '../../workflow-presets/interfaces/workflow-presets.interface';

@Injectable({
  providedIn: 'root',
})
export class WorkflowSettingsPresetsService extends DefaultDataService<WorkflowPresets> {
  config = this.pipelineConfigService.getConfig();
  apiUrl = `${this.config.hosts.api}/${this.config.versions.url}`;

  constructor(
    public override http: HttpClient,
    public override httpUrlGenerator: HttpUrlGenerator,
    private pipelineConfigService: PipelineConfigService,
  ) {
    super(KE_WORKFLOW_SETTINGS_PRESETS_ENTITY_NAME, http, httpUrlGenerator);
  }

  override getById(id: string): Observable<WorkflowPresets> {
    return this.http.get<WorkflowPresets>(`${this.apiUrl}/workflows/${id}/preset`);
  }
}
