import { Pivot, PivotField, ShelveType } from '@selfai-platform/bi-domain';

export function formatTooltipName(
  categoryList: string[],
  targetPivotList: PivotField[],
  titleUseFl?: boolean,
  pivot?: Pivot,
  pivotType?: ShelveType,
): string[] {
  const result: string[] = [];

  categoryList.forEach((item, index) => {
    let targetPivot;
    if (pivotType && ShelveType.AGGREGATIONS === pivotType) {
      targetPivot = targetPivotList.find((p) => p.alias === item);
    } else {
      targetPivot = targetPivotList[index];
    }

    if (!targetPivot) return result;

    if ('timestamp' === targetPivot.type) {
      let resultData = '';

      if (titleUseFl) {
        const granularity =
          targetPivot.format.unit.charAt(0).toUpperCase() + targetPivot.format.unit.slice(1).toLowerCase();

        const name = targetPivot.fieldAlias ? targetPivot.fieldAlias : targetPivot.name;
        const defaultAlias = targetPivot.granularity + '(' + name + ')';

        resultData =
          defaultAlias === targetPivot.alias ? granularity + ' of ' + name + ' : ' : targetPivot.alias + ' : ';
      }

      resultData += item;
      result.push(resultData);
    } else if ('measure' === targetPivot.type) {
      if (pivot && pivot.aggregations.length > 1) {
        let aggregationType = targetPivot.aggregationType
          ? targetPivot.aggregationType.charAt(0).toUpperCase() + targetPivot.aggregationType.slice(1).toLowerCase()
          : '';

        if ('Avg' === aggregationType) aggregationType = 'Average';

        const name = targetPivot.fieldAlias ? targetPivot.fieldAlias : targetPivot.name;
        const defaultAlias = targetPivot.aggregationType + '(' + name + ')';

        result.push(
          defaultAlias === targetPivot.alias
            ? (aggregationType ? aggregationType + ' of ' : '') + name
            : targetPivot.alias,
        );
      }
    } else {
      let resultData = '';

      if (titleUseFl && targetPivot.alias) {
        resultData = targetPivot.alias + ' : ';
      }

      resultData += item;
      result.push(resultData);
    }
  });

  return result;
}
