import { Injectable } from '@angular/core';
import { CHART_STRING_DELIMITER, ShelveFieldType, ShelveType } from '@selfai-platform/bi-domain';
import _ from 'lodash';
import { ApiChartData, ChartData, ChartDataService } from '../chart-data.service';
import { getFieldTypeCount } from '../get-field-type-count';

@Injectable({
  providedIn: 'root',
})
export class GraphChartDataService extends ChartDataService {
  override mapApiData(apiWidgetData: ApiChartData): ChartData {
    {
      for (const node of apiWidgetData.nodes) {
        node.originalName = node.name;

        node.name =
          node.name + (node.fields && node.fields.length === 1 ? CHART_STRING_DELIMITER + node.fields[0] : '');
      }

      for (const link of apiWidgetData.links) {
        link.originalSource = link.source;
        link.originalTarget = link.target;

        link.source = link.source + CHART_STRING_DELIMITER + link.sourceField;
        link.target = link.target + CHART_STRING_DELIMITER + link.targetField;

        _.forEach(apiWidgetData.nodes, (item) => {
          if (item.fields.length > 1 && item.originalName === link.source.split(CHART_STRING_DELIMITER)[0]) {
            link.source = link.source.split(CHART_STRING_DELIMITER)[0];
          }

          if (item.fields.length > 1 && item.originalName === link.target.split(CHART_STRING_DELIMITER)[0]) {
            link.target = link.target.split(CHART_STRING_DELIMITER)[0];
          }
        });
      }
    }

    return {
      ...(apiWidgetData as ChartData),
    };
  }

  // TODO: rename to noDataAvailable
  override hasNoData(data: ChartData): boolean {
    return (
      getFieldTypeCount(data, ShelveType.COLUMNS, ShelveFieldType.DIMENSION) === 1 &&
      getFieldTypeCount(data, ShelveType.COLUMNS, ShelveFieldType.TIMESTAMP) === 0 &&
      getFieldTypeCount(data, ShelveType.ROWS, ShelveFieldType.DIMENSION) === 1 &&
      getFieldTypeCount(data, ShelveType.ROWS, ShelveFieldType.TIMESTAMP) === 0 &&
      getFieldTypeCount(data, ShelveType.AGGREGATIONS, ShelveFieldType.MEASURE) +
        getFieldTypeCount(data, ShelveType.AGGREGATIONS, ShelveFieldType.CALCULATED) ===
        1 &&
      getFieldTypeCount(data, ShelveType.COLUMNS, ShelveFieldType.MEASURE) === 0 &&
      getFieldTypeCount(data, ShelveType.COLUMNS, ShelveFieldType.CALCULATED) === 0 &&
      getFieldTypeCount(data, ShelveType.ROWS, ShelveFieldType.MEASURE) === 0 &&
      getFieldTypeCount(data, ShelveType.ROWS, ShelveFieldType.CALCULATED) === 0 &&
      getFieldTypeCount(data, ShelveType.AGGREGATIONS, ShelveFieldType.DIMENSION) === 0 &&
      getFieldTypeCount(data, ShelveType.AGGREGATIONS, ShelveFieldType.TIMESTAMP) === 0
    );
  }
}
