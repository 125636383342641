import { Injectable, OnDestroy } from '@angular/core';
import { WorkflowVersion, WorkflowVersionFull } from '@selfai-platform/pipeline-common';
import { getDataFromLocalStorage, LocalStorageKey, saveDataToLocalStorage } from '@selfai-platform/shared';
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from 'rxjs';
import { IVersionsManagerApi } from './i-versions-manager-storage';

@Injectable()
export class VersionsManagerStorageService implements IVersionsManagerApi, OnDestroy {
  private versions$: BehaviorSubject<Record<string, WorkflowVersion[]>>;
  private versionSubscription: Subscription;

  constructor() {
    this.versions$ = new BehaviorSubject(
      getDataFromLocalStorage<Record<string, WorkflowVersion[]>>(LocalStorageKey.WORKFLOW_VERSIONS, {}),
    );

    this.versionSubscription = this.versions$.subscribe((data) => {
      saveDataToLocalStorage(LocalStorageKey.WORKFLOW_VERSIONS, data);
    });
  }

  getVersionsByWorkflowId(workflowId: string): Observable<WorkflowVersion[]> {
    const versions = this.versions$.value[workflowId] || [];
    const versionsWithNoContent = versions.map((t) => {
      return {
        ...t,
        content: undefined,
      };
    });
    const obs = new ReplaySubject<WorkflowVersion[]>(1);
    obs.next(versionsWithNoContent);

    setTimeout(() => {
      obs.complete();
    }, 1000);

    return obs.asObservable();
  }

  getVersionContent(workflowId: string, versionId: string): Observable<WorkflowVersionFull | undefined> {
    const versions = this.versions$.value[workflowId] || [];
    const version = versions.find((t) => t.id === versionId) as WorkflowVersionFull;
    const obs = new ReplaySubject<WorkflowVersionFull | undefined>(1);
    obs.next(version);
    setTimeout(() => {
      obs.complete();
    }, 1000);

    return obs.asObservable();
  }

  createVersion(workflowId: string, workflowVersion: WorkflowVersionFull): Observable<void> {
    const allversions = this.versions$.value;
    const versions = allversions[workflowId] || [];
    const updatedVersionsForWf = [...versions, workflowVersion];
    allversions[workflowId] = updatedVersionsForWf;
    this.versions$.next(allversions);
    const obs = new ReplaySubject<void>(1);
    obs.next();
    setTimeout(() => {
      obs.complete();
    }, 1000);

    return obs.asObservable();
  }

  removeVersion(workflowId: string, versionId: string): Observable<void> {
    const allversions = this.versions$.value;
    const versions = allversions[workflowId] || [];
    const updatedVersionsForWf = versions.filter((t) => t.id !== versionId);
    allversions[workflowId] = updatedVersionsForWf;
    this.versions$.next(allversions);
    const obs = new ReplaySubject<void>(1);
    obs.next();
    setTimeout(() => {
      obs.complete();
    }, 1000);

    return obs.asObservable();
  }

  ngOnDestroy(): void {
    this.versionSubscription.unsubscribe();
  }
}
