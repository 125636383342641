<div *ngIf="(pageNumber || pageNumber === 0) && pageSize && totalRecords" class="flex align-items-center justify-content-between p-2">
  <div>
    {{
      'shell.table-pagination.label-current-page-report'
        | translate: { start: first, end: last, total: totalRecords }
    }}
  </div>
  <div>
    <p-paginator
      #paginator
      (onPageChange)="onPageChange($event)"
      [rows]="pageSize"
      [first]="first"
      [totalRecords]="totalRecords"
      [showFirstLastIcon]="false"
      [showCurrentPageReport]="false"
      [alwaysShow]="true"
      [templateLeft]="templateLeft"
      [templateRight]="templateRight"
    ></p-paginator>

    <ng-template #templateLeft>
      <button
        (click)="paginator.changePageToPrev($event)"
        [disabled]="paginator.isFirstPage()"
        class="p-button-link p-button-text"
        pButton
        icon="pi pi-angle-left"
        label="{{ 'shell.table-pagination.label-prev-page' | translate }}"
      ></button>
    </ng-template>
    <ng-template #templateRight>
      <button
        (click)="paginator.changePageToNext($event)"
        [disabled]="paginator.isLastPage()"
        class="p-button-link p-button-text"
        pButton
        icon="pi pi-angle-right"
        iconPos="right"
        label="{{ 'shell.table-pagination.label-next-page' | translate }}"
      ></button>
    </ng-template>
  </div>
</div>
