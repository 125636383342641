/* eslint-disable max-lines */
import { Component, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import {
  BaseOption,
  CHART_STRING_DELIMITER,
  ChartColorList,
  ChartColorType,
  ChartType,
  ColorCustomMode,
  ColorRange,
  ColorRangeType,
  DIRECTION,
  Pivot,
  PivotTableInfo,
  Position,
  PositionLabel,
  SeriesType,
  ShelveFieldType,
  ShelveType,
  Sort,
  SymbolType,
  UIChartColor,
  UIChartColorByDimension,
  UIChartColorBySeries,
  UIChartColorByValue,
  UIChartFormat,
  UIOption,
  UIScatterChart,
  UiChartDataLabelDisplayType,
  createPivotTableInfo,
} from '@selfai-platform/bi-domain';
import { AlertService, DestroyService } from '@selfai-platform/shared';
import { BarSeriesOption, GaugeSeriesOption, LegendComponentOption, XAXisComponentOption } from 'echarts';
import { GridComponentOption, TooltipComponentOption, VisualMapComponentOption } from 'echarts/components';
import * as _ from 'lodash';
import { FormatOptionConverter } from '../../converters';
import { provideBaseChartServices } from '../../services';
import { EChartService } from '../../services/echart.service';
import { OptionGenerator } from '../../utils';
import { BaseChart } from '../base-chart';

import optGen = OptionGenerator;
import UI = OptionGenerator.UI;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'gauge-chart',
  template: '',
  styleUrls: ['./chart-host.component.scss'],
  providers: [...provideBaseChartServices()],
})
export class GaugeChartComponent extends BaseChart {
  @Input()
  private sorts: Sort[];

  @Output()
  protected changePivotData = new EventEmitter();

  @Output()
  protected showGuide = new EventEmitter();

  constructor(
    elementRef: ElementRef,
    eChartService: EChartService,
    destroy$: DestroyService,
    injector: Injector,
    private translate: TranslateService,
    private alertService: AlertService,
  ) {
    super(elementRef, destroy$, eChartService, injector);
  }

  override isValid(shelve: Pivot): boolean {
    return (
      this.getFieldTypeCount(shelve, ShelveType.ROWS, ShelveFieldType.DIMENSION) +
        this.getFieldTypeCount(shelve, ShelveType.ROWS, ShelveFieldType.TIMESTAMP) >
        0 &&
      this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.MEASURE) +
        this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.CALCULATED) ==
        1 &&
      this.getFieldTypeCount(shelve, ShelveType.ROWS, ShelveFieldType.MEASURE) == 0 &&
      this.getFieldTypeCount(shelve, ShelveType.ROWS, ShelveFieldType.CALCULATED) == 0 &&
      this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.DIMENSION) == 0 &&
      this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.TIMESTAMP) == 0
    );
  }

  override draw(isKeepRange?: boolean): void {
    this.removeNegative();

    this.uiOption.stackedMaxvalue = 100;
    this.uiOption.stackedMinValue = 0;

    this.data = this.setGaugeChartData(isKeepRange);

    this.data.columns = this.setUIData();

    const rows: string[] = [];

    _.each(this.data.columns, (series) => {
      _.each(series, (column) => {
        this.data.info.maxValue = column.value > this.data.info.maxValue ? column.value : this.data.info.maxValue;
        this.data.info.minValue = this.data.info.minValue > column.value ? column.value : this.data.info.minValue;

        const columnName: string = _.split(column.name, CHART_STRING_DELIMITER)[1];

        rows.push(columnName);
      });
    });

    this.setDataInfo();

    this.uiOption.fieldDimensionDataList = rows;

    this.pivotInfo = createPivotTableInfo([], rows, this.fieldInfo.aggs);

    super.draw(isKeepRange);
  }

  protected override setMeasureColorRange(schema): ColorRange[] {
    const rangeList = [];

    const colorList = ChartColorList[schema];

    const rowsList: string[] = _.max(this.data.columns);

    const rowsListLength = rowsList.length;

    const colorListLength = colorList.length > rowsListLength ? rowsListLength - 1 : colorList.length - 1;

    const minValue = this.uiOption.minValue >= 0 ? 0 : _.cloneDeep(this.uiOption.minValue);

    const addValue = (this.uiOption.maxValue - minValue) / colorListLength;

    let maxValue = _.cloneDeep(this.uiOption.maxValue);

    let shape;
    if ((<UIScatterChart>this.uiOption).pointShape) {
      shape = (<UIScatterChart>this.uiOption).pointShape.toString().toLowerCase();
    }

    for (let index = colorListLength; index >= 0; index--) {
      const color = colorList[index];

      if (colorListLength == index) {
        rangeList.push(
          UI.Range.colorRange(
            ColorRangeType.SECTION,
            color,
            parseFloat(maxValue.toFixed(1)),
            null,
            parseFloat(maxValue.toFixed(1)),
            null,
            shape,
          ),
        );
      } else {
        let min = 0 == index ? null : parseFloat((maxValue - addValue).toFixed(1));

        if (min < this.uiOption.minValue && min < 0) min = _.cloneDeep(parseInt(this.uiOption.minValue.toFixed(1)));

        rangeList.push(
          UI.Range.colorRange(
            ColorRangeType.SECTION,
            color,
            min,
            parseFloat(maxValue.toFixed(1)),
            min,
            parseFloat(maxValue.toFixed(1)),
            shape,
          ),
        );

        maxValue = min;
      }
    }

    return rangeList;
  }

  protected override convertSeriesData(): BaseOption {
    const objData = this.data;

    const rows: string[] = [];

    let columnLength = _.max(objData.columns)['length'];

    this.chartOption.series = [];

    _.each(objData.columns, (series) => {
      const seriesData = series.map((column) => {
        const seriesName: string = column.name;

        const columnName: string = _.split(seriesName, CHART_STRING_DELIMITER)[1];

        rows.push(columnName);

        const resultSeries: BarSeriesOption = {
          type: SeriesType.BAR,
          name: ChartType.GAUGE.toString(),
          data: [
            {
              value: column.percentage,
              name: column.name,
              selected: false,
              itemStyle: optGen.ItemStyle.opacity1(),
            },
          ],
          itemStyle: optGen.ItemStyle.auto(),
          label: optGen.LabelStyle.defaultLabelStyle(true, PositionLabel.INSIDE),
        };

        return resultSeries;
      });

      if (1 == columnLength) columnLength = 2;

      for (let index = 0; index < columnLength; index++) {
        let item = seriesData[index];

        if (!item) {
          item = {
            type: SeriesType.BAR,
            name: ChartType.GAUGE.toString(),

            data: [
              {
                value: 0,
                name: '',
                label: {
                  formatter: '',
                },
              },
            ],
            originData: [],
            itemStyle: optGen.ItemStyle.auto(),
            label: optGen.LabelStyle.defaultLabelStyle(true, PositionLabel.INSIDE),
          };
        }

        if (this.chartOption.series[index]) {
          const singleSeries = this.chartOption.series[index];

          this.chartOption.series[index].data = singleSeries.data.concat(item.data);

          this.chartOption.series[index].originData = _.cloneDeep(singleSeries.data);

          this.chartOption.series[index].name = singleSeries.name + CHART_STRING_DELIMITER + item.name;
        } else {
          if (index > 0 && this.chartOption.series[0].data.length > item.data.length) {
            const lengthDiff = this.chartOption.series[0].data.length - item.data.length;

            for (let num = 0; num < lengthDiff; num++) {
              item.data.splice(0, 0, { value: 0 });
            }
          }

          item.originData = _.cloneDeep(item.data);
          (this.chartOption.series as GaugeSeriesOption[]).push(item);
        }

        this.chartOption.series[index].data.forEach((item, dataIndex) => {
          if (!item['uiData']) {
            item['uiData'] = _.find(objData.columns[dataIndex], { name: item.name });
          }
        });
      }
    });

    return this.chartOption;
  }

  protected override convertSeries(): BaseOption {
    this.chartOption = super.convertSeries();

    if (
      _.eq(this.uiOption.color.type, ChartColorType.MEASURE) &&
      this.uiOption.color['customMode'] &&
      ColorCustomMode.GRADIENT == this.uiOption.color['customMode']
    ) {
      _.each(this.data.columns, (column, columnIndex) => {
        const totalValue: number = column.categoryValue;

        _.each(this.chartOption.series as GaugeSeriesOption[], (series) => {
          const data = series.data[columnIndex];

          if (!this.uiOption.color['ranges']) {
            return false;
          }

          let value: number = null;
          if (data && isNaN(data)) {
            value = data.value;
          } else {
            value = data;
          }

          const maxValue: number = this.data.info.maxValue;
          const rangePercent: number = (maxValue / totalValue) * 100;
          const codes: string[] = _.cloneDeep((this.chartOption.visualMap as VisualMapComponentOption).color).reverse();
          let index: number = Math.round((value / rangePercent) * codes.length);
          index = index == codes.length ? codes.length - 1 : index;
          series.data[columnIndex].itemStyle = {
            color: codes[index],
          };
        });
      });

      delete this.chartOption.visualMap;
    } else if (_.eq(this.uiOption.color.type, ChartColorType.MEASURE)) {
      _.each(this.data.columns, (column, columnIndex) => {
        const totalValue: number = column.categoryValue;

        _.each(this.chartOption.series as GaugeSeriesOption[], (series) => {
          const data = series.data[columnIndex];

          if (!this.uiOption.color['ranges']) {
            return false;
          }

          let value: number = null;
          if (data && isNaN(data)) {
            value = data.value;
          } else {
            value = data;
          }

          const originalValue: number = totalValue * (value / 100);
          const ranges = _.cloneDeep((<UIChartColorByValue>this.uiOption.color).ranges);
          let index = 0;
          _.each(this.uiOption.color['ranges'], (range, rangeIndex) => {
            const min: number = range.fixMin != null ? range.fixMin : 0;
            const max: number = range.fixMax != null ? range.fixMax : min;
            if (originalValue >= min && originalValue <= max) {
              index = Number(rangeIndex);

              return false;
            }
          });
          series.data[columnIndex].itemStyle = {
            color: ranges[index].color,
          };
        });
      });

      delete this.chartOption.visualMap;
    }

    return this.chartOption;
  }

  protected override selection(): void {
    this.addChartSelectEventListener();
  }

  protected override additionalSeries(): BaseOption {
    this.chartOption = this.convertLabelRotate();

    this.chartOption = this.convertGaugeFormatSeries(this.chartOption, this.uiOption);

    return this.chartOption;
  }

  protected override additionalLegend(): BaseOption {
    const series = this.chartOption.series as GaugeSeriesOption[];
    const legendData = (this.chartOption.legend as LegendComponentOption).data;

    const schema = this.uiOption.color['schema'];
    const list = _.cloneDeep(ChartColorList[schema]);

    if ((<UIChartColorByDimension>this.uiOption.color).mapping) {
      Object.keys((<UIChartColorByDimension>this.uiOption.color).mapping).forEach((key, index) => {
        const mappingValue = (<UIChartColorByDimension>this.uiOption.color).mapping[key];
        if (mappingValue) list[index] = (<UIChartColorByDimension>this.uiOption.color).mapping[key];
      });
    }

    if (ChartColorType.DIMENSION === this.uiOption.color.type) {
      _.each(series, (obj) => {
        // obj.itemStyle.color some problem with typings in echarts
        (obj.itemStyle as any).color = (params: any): string => {
          const name: string = _.split(params.data.name, CHART_STRING_DELIMITER)[1];
          let colorIdx = _.indexOf(legendData, name);
          colorIdx = colorIdx >= list.length ? colorIdx % list.length : colorIdx;

          return list[colorIdx] as string;
        };
      });
    }

    return this.chartOption;
  }

  protected override additionalTooltip(): BaseOption {
    let format: UIChartFormat = this.uiOption.valueFormat;

    const axisFormat = FormatOptionConverter.getlabelAxisScaleFormatTooltip(this.uiOption);
    if (axisFormat) format = axisFormat;

    if (_.isUndefined(this.chartOption.tooltip)) {
      this.chartOption.tooltip = {};
    }
    (this.chartOption.tooltip as TooltipComponentOption).formatter = (params): any => {
      const option = this.chartOption.series[params.seriesIndex];

      let uiData = _.cloneDeep(option.data[params.dataIndex]['uiData']);
      if (!uiData) uiData = _.cloneDeep(option['uiData']);

      return this.getFormatGaugeValueSeriesTooltip(params, format, this.uiOption, option, uiData);
    };

    return this.chartOption;
  }

  protected override initOption(): BaseOption {
    return {
      type: ChartType.GAUGE,
      grid: [OptionGenerator.Grid.verticalMode(17, 10, 0, 10, false, false, false)],
      xAxis: [OptionGenerator.Axis.valueAxis(Position.INSIDE, null, false, false, true, true, true)],
      yAxis: [OptionGenerator.Axis.categoryAxis(Position.INSIDE, null, true, true, true, true)],
      legend: OptionGenerator.Legend.custom(false, false, Position.LEFT, SymbolType.CIRCLE, '100%', 20),
      tooltip: OptionGenerator.Tooltip.itemTooltip(),
      toolbox: OptionGenerator.Toolbox.hiddenToolbox(),
      brush: OptionGenerator.Brush.selectBrush(),
      series: [],
    };
  }

  protected override setUIData(): any {
    _.each(this.data.columns, (data) => {
      let categoryValue = 0;
      let categoryPercent = 0;

      if (Array.isArray(data)) {
        for (const item of data) {
          categoryPercent += Number.isNaN(item.percentage) ? 0 : item.percentage;
          categoryValue += item.value;
        }
      } else {
        console.error('format data is not array', data);
      }

      categoryPercent = Math.round(categoryPercent);
      categoryValue = Math.round(categoryValue);

      for (const item of data) {
        item['categoryPercent'] = categoryPercent;
        item['categoryValue'] = categoryValue;
      }
    });

    return this.data.columns;
  }

  protected override setMapping(): UIChartColor {
    return this.uiOption.color;
  }

  protected override setDataLabel(): UIOption {
    if (!this.pivot || !this.pivot.rows) return this.uiOption;

    const spliceCategoryTypeList = (categoryTypeList, dataLabel: any): any => {
      const previewFl = !!dataLabel.previewList;

      let index: number;

      for (const item of categoryTypeList) {
        index = dataLabel.displayTypes.indexOf(item);

        if (-1 !== index) {
          dataLabel.displayTypes[index] = null;

          if (previewFl) {
            _.remove(dataLabel.previewList, { value: item });
          }
        }
      }

      return dataLabel;
    };

    if (this.pivot.rows.length < 2) {
      const categoryTypeList = [UiChartDataLabelDisplayType.CATEGORY_NAME];

      if (this.uiOption.dataLabel && this.uiOption.dataLabel.displayTypes)
        this.uiOption.dataLabel = spliceCategoryTypeList(categoryTypeList, this.uiOption.dataLabel);

      if (this.uiOption.toolTip && this.uiOption.toolTip.displayTypes)
        this.uiOption.toolTip = spliceCategoryTypeList(categoryTypeList, this.uiOption.toolTip);
    }

    return this.uiOption;
  }

  protected override additionalGrid(): BaseOption {
    (this.chartOption.grid as GridComponentOption[]).map((obj) => {
      if (false == this.uiOption.xAxis.showLabel && true == this.uiOption.xAxis.showName) {
        obj.bottom = Number(obj.bottom) + 20;
      }
    });

    return this.chartOption;
  }

  protected override additionalXAxis(): BaseOption {
    (this.chartOption.xAxis as XAXisComponentOption[]).map((obj) => {
      obj.max = 100;
    });

    return this.chartOption;
  }

  private gaugeSetMapping(gaugeDimensionList: string[]): UIChartColor {
    if (!this.uiOption.color) return;

    if (!(<UIChartColorByDimension>this.uiOption.color).mapping)
      (<UIChartColorByDimension>this.uiOption.color).mapping = {} as {
        alias: string;
        color: string;
      };

    if ((<UIChartColorByDimension>this.uiOption.color).schema) {
      let colorChangedFl = false;

      for (const key in (<UIChartColorByDimension>this.uiOption.color).mapping) {
        const index = _.findIndex(gaugeDimensionList, (data) => {
          return data == key;
        });

        if (-1 == index || colorChangedFl) {
          delete (<UIChartColorByDimension>this.uiOption.color).mapping[key];
          colorChangedFl = true;
        }
      }

      gaugeDimensionList.forEach((item, index) => {
        if (
          (<UIChartColorByDimension>this.uiOption.color).schema &&
          !(<UIChartColorByDimension>this.uiOption.color).mapping[item]
        ) {
          const colorListLength = ChartColorList[(<UIChartColorBySeries>this.uiOption.color).schema].length;

          const editIndex = index >= colorListLength ? index % colorListLength : index;
          (<UIChartColorByDimension>this.uiOption.color).mapping[item] =
            ChartColorList[(<UIChartColorByDimension>this.uiOption.color).schema][editIndex];
        }
      });

      (<UIChartColorByDimension>this.uiOption.color).mappingArray = [];

      Object.keys((<UIChartColorByDimension>this.uiOption.color).mapping).forEach((key) => {
        (<UIChartColorByDimension>this.uiOption.color).mappingArray.push({
          alias: key,
          color: (<UIChartColorByDimension>this.uiOption.color).mapping[key],
        });
      });
    }

    return this.uiOption.color;
  }

  private convertLabelRotate(): BaseOption {
    let rotate: number;
    if (!this.uiOption.dataLabel.enableRotation) {
      rotate = 0;
    } else if (this.uiOption.dataLabel.enableRotation) {
      rotate = 90;
    }

    const series = this.chartOption.series as GaugeSeriesOption[];

    series.map((item) => {
      if (item.axisLabel) item.axisLabel.rotate = rotate;
    });

    return this.chartOption;
  }

  private setGaugeChartData(isKeepRange?: boolean): any {
    if (isKeepRange) return this.data;

    const shelve = this.pivot;
    const data = this.data;

    const rowsList = shelve.rows.map((item) => {
      return item.alias;
    });

    let value = [];
    let percentage = [];

    const columnList = rowsList.map((rowData, rowIdx) => {
      const rowDuplicateList = data.rows.map((rowValue) => {
        return _.split(rowValue, CHART_STRING_DELIMITER)[rowIdx];
      });

      const uniqRows = _.uniq(rowDuplicateList);

      let column = uniqRows.map((uniqRow) => {
        value = [];
        percentage = [];

        rowDuplicateList.forEach((item, index) => {
          value.push(uniqRow === item ? data.columns[0].value[index] : 0);

          percentage.push(uniqRow === item ? data.columns[0].percentage[index] : 0);
        });

        let sumData = value.reduce((sum, current) => {
          return sum + current;
        });

        const sumPercent = percentage.reduce((sum, current) => {
          return sum + current;
        });
        sumData = Math.floor(sumData * 1000000) / 1000000;

        return {
          name: rowData + CHART_STRING_DELIMITER + uniqRow + CHART_STRING_DELIMITER + data.columns[0].name,
          value: sumData,
          percentage: sumPercent,
        };
      });

      if (this.sorts && this.sorts.length > 0) {
        let sortFl = false;
        for (const sort of this.sorts) {
          _.sortBy(column, (item) => {
            if (item.name.split(CHART_STRING_DELIMITER)[0] === sort.field) {
              column = _.sortBy(column, 'name');
              sortFl = true;
            } else if (item.name.split(CHART_STRING_DELIMITER)[2] === sort.field) {
              column = _.sortBy(column, 'value');
              sortFl = true;
            }
          });

          if (sortFl) {
            if (DIRECTION.DESC === sort.direction) column = column.sort().reverse();
            break;
          }
        }
      }

      return column;
    });

    data.rows = rowsList;
    data.columns = columnList;

    return data;
  }

  private removeNegative(): void {
    if (this.data.info.minValue < 0) {
      this.changePivotData.emit({
        shelveTypeList: [ShelveType.AGGREGATIONS],
        shelveFieldTypeList: [String(ShelveFieldType.MEASURE), String(ShelveFieldType.CALCULATED)],
      });

      this.showGuide.emit();

      this.alertService.info(this.translate.instant('msg.page.gauge.chart.negative.value'));

      return;
    }
  }

  private getFormatValueTooltip(params: any, uiOption: UIOption, fieldInfo: PivotTableInfo, pivot: Pivot): string {
    const format: UIChartFormat = uiOption.valueFormat;
    const colorType: ChartColorType = uiOption.color.type;
    const targetField: string = (<UIChartColorByDimension>uiOption.color).targetField;

    if (params.componentType === 'markLine') {
      return params.seriesName + '<br />' + params.data.value;
    } else if (params.componentType === 'series') {
      const colorEl = params.marker;

      let legendName = '';

      if (_.isUndefined(params.value)) return '';
      let value = _.isArray(params.value) ? _.last(params.value) : params.value;
      if (_.isNull(value)) return;

      if (format && format.isAll) {
        value = FormatOptionConverter.getFormatValue(value, format);
      } else {
        value = value.toLocaleString();
      }

      let seriesName = '';

      if (-1 < params.seriesName.indexOf(ChartType.GAUGE)) {
        seriesName = params.data.name;
      }

      switch (colorType) {
        case ChartColorType.DIMENSION:
          {
            let fieldIdx = _.indexOf(fieldInfo.cols, targetField);

            const nameArr =
              fieldIdx < 0
                ? _.split(params.seriesName, CHART_STRING_DELIMITER)
                : _.split(params.name, CHART_STRING_DELIMITER);
            if (fieldIdx < 0) fieldIdx = _.indexOf(fieldInfo.rows, targetField);

            legendName = nameArr[fieldIdx];
          }

          break;

        case ChartColorType.MEASURE:
          legendName = '';
          break;
      }

      if (params && params.data.name && params.data.value)
        legendName = _.last(_.split(params.data.name, CHART_STRING_DELIMITER));

      if (legendName && '' !== legendName) value = ' : ' + value;

      return colorEl + legendName + value + '<br />' + seriesName;
    }
  }

  private convertGaugeFormatSeries(chartOption: BaseOption, uiOption: UIOption): BaseOption {
    let format: UIChartFormat = uiOption.valueFormat;
    if (_.isUndefined(format)) {
      return chartOption;
    }

    const axisFormat = FormatOptionConverter.getlabelAxisScaleFormat(uiOption);
    if (axisFormat) format = axisFormat;

    const series = chartOption.series as GaugeSeriesOption[];
    _.each(series, (option) => {
      // any because problem with typings GaugeSeriesOption
      let label = (option as any).label;
      if (_.isUndefined(label)) {
        label = {};
      }

      label.formatter = (params): any => {
        let uiData = _.cloneDeep(option.data[params.dataIndex]['uiData']);
        if (!uiData) uiData = _.cloneDeep(option['uiData']);

        return this.getFormatGaugeValueSeries(params, format, uiOption, option, uiData);
      };
    });

    return chartOption;
  }

  private getFormatGaugeValueSeries(
    params: any,
    format: UIChartFormat,
    uiOption?: UIOption,
    series?: any,
    uiData?: any,
  ): string {
    if (uiData) {
      if (!uiOption.dataLabel || !uiOption.dataLabel.displayTypes) return '';

      let isUiData = false;
      const result: string[] = [];
      if (
        this.pivot.rows.length > 1 &&
        -1 !== uiOption.dataLabel.displayTypes.indexOf(UiChartDataLabelDisplayType.CATEGORY_NAME)
      ) {
        result.push(_.split(params.data.name, CHART_STRING_DELIMITER)[0]);
        isUiData = true;
      }
      if (-1 !== uiOption.dataLabel.displayTypes.indexOf(UiChartDataLabelDisplayType.CATEGORY_VALUE)) {
        result.push(FormatOptionConverter.getFormatValue(uiData['categoryValue'], format));
        isUiData = true;
      }
      if (-1 !== uiOption.dataLabel.displayTypes.indexOf(UiChartDataLabelDisplayType.CATEGORY_PERCENT)) {
        let value = uiData['categoryPercent'];
        value = Math.floor(Number(value) * Math.pow(10, format.decimal)) / Math.pow(10, format.decimal);
        result.push(value + '%');
        isUiData = true;
      }

      if (-1 !== uiOption.dataLabel.displayTypes.indexOf(UiChartDataLabelDisplayType.SERIES_NAME)) {
        result.push(_.split(params.data.name, CHART_STRING_DELIMITER)[1]);
        isUiData = true;
      }
      if (-1 !== uiOption.dataLabel.displayTypes.indexOf(UiChartDataLabelDisplayType.SERIES_VALUE)) {
        result.push(FormatOptionConverter.getFormatValue(uiData.value, format));
        isUiData = true;
      }
      if (-1 !== uiOption.dataLabel.displayTypes.indexOf(UiChartDataLabelDisplayType.SERIES_PERCENT)) {
        const percentValue =
          Math.floor(Number(uiData.percentage) * Math.pow(10, format.decimal)) / Math.pow(10, format.decimal);
        result.push(percentValue + '%');
        isUiData = true;
      }

      let label = '';

      if (isUiData) {
        for (let num = 0; num < result.length; num++) {
          if (num > 0) {
            label += '\n';
          }
          if (series.label && series.label.rich) {
            label += '{align|' + result[num] + '}';
          } else {
            label += result[num];
          }
        }

        return label;
      } else {
        return label;
      }
    }

    return FormatOptionConverter.noUIDataFormat(params, format);
  }

  private getFormatGaugeValueSeriesTooltip(
    params: any,
    format: UIChartFormat,
    uiOption?: UIOption,
    series?: any,
    uiData?: any,
  ): string {
    if (uiData) {
      if (!uiOption.toolTip) uiOption.toolTip = {};
      if (!uiOption.toolTip.displayTypes)
        uiOption.toolTip.displayTypes = FormatOptionConverter.setDisplayTypes(uiOption.type);

      const result: string[] = [];

      if (
        this.pivot.rows.length > 1 &&
        -1 !== uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.CATEGORY_NAME)
      ) {
        const categoryNameList = _.split(params.data.name, CHART_STRING_DELIMITER);
        result.push(categoryNameList[0]);
      }
      if (-1 !== uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.CATEGORY_VALUE)) {
        const splitValue = _.split(params.data.name, CHART_STRING_DELIMITER);
        const name = splitValue[splitValue.length - 1];
        let categoryValue = FormatOptionConverter.getTooltipValue(
          name,
          this.pivot.aggregations,
          format,
          uiData['categoryValue'],
        );

        if (-1 !== uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.CATEGORY_PERCENT)) {
          let value = uiData['categoryPercent'];
          value = Math.floor(Number(value) * Math.pow(10, format.decimal)) / Math.pow(10, format.decimal);

          categoryValue += ' (' + value + '%)';
        }

        result.push(categoryValue);
      }
      if (-1 !== uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.CATEGORY_PERCENT)) {
        if (-1 == uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.CATEGORY_VALUE)) {
          const splitValue = _.split(params.data.name, CHART_STRING_DELIMITER);
          const name = splitValue[splitValue.length - 1];

          let categoryValue = FormatOptionConverter.getTooltipValue(
            name,
            this.pivot.aggregations,
            format,
            uiData['categoryPercent'],
          );

          categoryValue += '%';

          result.push(categoryValue);
        }
      }

      if (-1 !== uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.SERIES_NAME)) {
        const categoryNameList = _.split(params.data.name, CHART_STRING_DELIMITER);
        result.push(categoryNameList[0] + ' : ' + categoryNameList[1]);
      }
      if (-1 !== uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.SERIES_VALUE)) {
        const splitValue = _.split(params.data.name, CHART_STRING_DELIMITER);
        const name = splitValue[splitValue.length - 1];
        let seriesValue = FormatOptionConverter.getTooltipValue(name, this.pivot.aggregations, format, uiData['value']);

        if (-1 !== uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.SERIES_PERCENT)) {
          const value =
            Math.floor(Number(uiData['percentage']) * Math.pow(10, format.decimal)) / Math.pow(10, format.decimal);

          seriesValue += ' (' + value + '%)';
        }

        result.push(seriesValue);
      }
      if (-1 !== uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.SERIES_PERCENT)) {
        if (-1 == uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.SERIES_VALUE)) {
          const splitValue = _.split(params.data.name, CHART_STRING_DELIMITER);
          const name = splitValue[splitValue.length - 1];
          let seriesValue = FormatOptionConverter.getTooltipValue(
            name,
            this.pivot.aggregations,
            format,
            uiData['value'],
          );

          seriesValue += '%';

          result.push(seriesValue);
        }
      }

      return result.join('<br/>');
    }

    return this.getFormatValueTooltip(params, uiOption, this.fieldInfo, this.pivot);
  }
}
