import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DatasourceType } from '@selfai-platform/pipeline-common';
import { ExecuteWithPipe } from '@selfai-platform/shared';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { combineLatest, map, mergeMap, Observable } from 'rxjs';
import { DataSourceListDomainService, DataSourcePermissionService } from '../../../data-sources/services';
import { getMapIconsByType } from '../../../data-sources/utilities';
import { dataSourceListRoute, getDataSourceEditRoute } from '../../../utils';

@Component({
  selector: 'selfai-platform-data-source-selector',
  standalone: true,
  imports: [
    CommonModule,
    DropdownModule,
    ReactiveFormsModule,
    TranslateModule,
    ButtonModule,
    TooltipModule,
    RouterModule,
    ExecuteWithPipe,
    MonacoEditorModule,
    FormsModule,
  ],
  templateUrl: './data-source-selector.component.html',
  styleUrl: './data-source-selector.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataSourceSelectorComponent implements OnInit {
  @Input({ required: true }) control: FormControl<string>;
  @Input() excludedDataSourceTypes: DatasourceType[] = [];

  readonly editorOptions = {
    theme: 'vs',
    language: 'json',
    automaticLayout: true,
    minimap: { enabled: false },
    wordWrap: 'on',
    readOnly: true,
    scrollbar: {
      vertical: 'hidden',
      horizontal: 'hidden',
    },
  } as const;

  datasources$ = this.dataSourceListDomainService
    .getDataSourceList()
    .pipe(map((d) => d.filter((t) => !this.excludedDataSourceTypes.includes(t.params.datasourceType))));

  datasourcesLoading$ = this.dataSourceListDomainService.getDataSourceListLoading();
  canEditMap$: Observable<Map<string, boolean>> = this.mapEditPermissionsByDataSources();

  selectedDatasource: string | null;
  dataSourceListRoute = dataSourceListRoute;
  getDataSourceEditRoute = getDataSourceEditRoute;

  iconsByDataSourceType = getMapIconsByType();

  get selectedDatasourceJson$(): Observable<string> {
    return this.datasources$.pipe(
      map((d) => d.find((t) => t.id === this.control.value)),
      map((d) => (d ? JSON.stringify(d, null, '\t') : '')),
    );
  }

  constructor(
    private readonly dataSourceListDomainService: DataSourceListDomainService,
    private readonly dataSourcePermissionService: DataSourcePermissionService,
  ) {}

  ngOnInit(): void {
    this.dataSourceListDomainService.loadDataSourceList();
  }

  onReloadDataSources(): void {
    this.dataSourceListDomainService.loadDataSourceList();
  }

  private mapEditPermissionsByDataSources(): Observable<Map<string, boolean>> {
    return this.datasources$
      .pipe(
        mergeMap((datasources) =>
          combineLatest(
            datasources.map((datasource) =>
              this.dataSourcePermissionService
                .canUpdateSource(datasource.ownerId)
                .pipe(map((canEdit) => ({ canEdit, dataSourceId: datasource.id }))),
            ),
          ),
        ),
      )
      .pipe(
        map((permissions) => {
          const permissionsMap = new Map<string, boolean>();
          permissions.forEach((permission) => permissionsMap.set(permission.dataSourceId, permission.canEdit));

          return permissionsMap;
        }),
      );
  }
}
