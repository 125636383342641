import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityDataService } from '@ngrx/data';
import { SchedulesListDataService } from './schedules-list-data.service';
import { SchedulesListItem } from '../models';
import { KE_SCHEDULES_LIST_ENTITY_NAME } from '@selfai-platform/store';

@Injectable({ providedIn: 'root' })
export class SchedulesListStore extends EntityCollectionServiceBase<SchedulesListItem> {
  constructor(
    public entityDataService: EntityDataService,
    public schedulesDataService: SchedulesListDataService,
    public serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    entityDataService.registerService(KE_SCHEDULES_LIST_ENTITY_NAME, schedulesDataService);
    super(KE_SCHEDULES_LIST_ENTITY_NAME, serviceElementsFactory);
  }

  [name: string]: unknown;
}
