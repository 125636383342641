import { Observable } from 'rxjs';
import { WorkflowSettingsPresetsBinding } from '../interface/workflow-settings-presets-binding.interface';
import { WorkflowSettingsPresetsBindingStore } from '../store/workflow-settings-presets-binding.store';
import { Injectable } from '@angular/core';

@Injectable()
export class WorkflowSettingsPresetsBindingDomainService {
  workflowSettingsPresetsBinding$!: Observable<WorkflowSettingsPresetsBinding[]>;

  constructor(private workflowSettingsPresetsBindingStore: WorkflowSettingsPresetsBindingStore) {
    this.workflowSettingsPresetsBinding$ = workflowSettingsPresetsBindingStore.entities$;
  }

  add(data: WorkflowSettingsPresetsBinding): void {
    this.workflowSettingsPresetsBindingStore.add(data);
  }
}
