import { CommonModule, DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import {
  DestroyService,
  ExecuteWithPipe,
  LocalStorageKey,
  PREFIX_SETTINGS_LOCAL_STORAGE_KEY,
  SearchQueryService,
  mapPaginationBiToPrime,
} from '@selfai-platform/shared';
import { ConfirmationService, FilterService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxChangeEvent, CheckboxModule } from 'primeng/checkbox';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { SkeletonModule } from 'primeng/skeleton';
import { Table, TableColResizeEvent, TableLazyLoadEvent, TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { Observable, map, switchMap, take, takeUntil, tap } from 'rxjs';
import { DataListViewTemplateName } from '../../constants';
import { DataListItemAction, GroupAction, TableColumn } from '../../models';
import { DataListViewItem } from '../../models/data-view-list-item.model';
import {
  ActionsForItemService,
  DataListViewBaseService,
  DataListViewLazyLoadService,
  SelectedItemsService,
} from '../../services';
import { DataListViewPaginationComponent } from '../data-list-view-pagination';
import { DataListViewTemplateNameDirective } from '../data-list-view-template-name.directive';
import { ResizableColumnComponent } from '../resizable-column';

@Component({
  selector: 'selfai-platform-data-list-view-table',
  templateUrl: './data-list-view-table.component.html',
  styleUrls: ['./data-list-view-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TableModule,
    InputTextModule,
    ButtonModule,
    TooltipModule,
    CheckboxModule,
    MenuModule,
    SkeletonModule,
    RouterModule,
    TranslateModule,
    ConfirmPopupModule,
    DropdownModule,
    DataListViewPaginationComponent,
    ExecuteWithPipe,
    ResizableColumnComponent,
  ],
  providers: [DestroyService, ConfirmationService, DatePipe],
})
export class DataListViewTableComponent<T extends object & DataListViewItem> implements OnInit {
  @Input() columns: TableColumn<T>[] = [];
  @Input() isNameEditable? = false;
  @Input() emptyMessage?: string;
  @Input() showEntityIcon = true;
  @Input() dataIsLazy = true;
  @Input() isFavoriteSupported = false;
  /**
   * Actions for selected items
   */
  @Input() groupActions: GroupAction[] = [];
  @Input() globalFilterFields?: string[];

  @Input()
  templates: QueryList<DataListViewTemplateNameDirective<T>>;

  @Output() renameItem = new EventEmitter<T>();
  @Output() toggleFavorite = new EventEmitter<{ itemId: string; isFavorite: boolean }>();

  @ViewChild('table', { read: Table }) table: Table;

  onlyFavorite = false;

  get temlateByName(): Record<DataListViewTemplateName, DataListViewTemplateNameDirective<T>> {
    return this.templates?.reduce(
      (acc, template: DataListViewTemplateNameDirective<T>) => ({
        ...acc,
        [template.name]: template,
      }),
      {} as Record<DataListViewTemplateName, DataListViewTemplateNameDirective<T>>,
    );
  }

  get customFieldValueTemplates(): Record<keyof T, DataListViewTemplateNameDirective<T>> {
    return this.templates?.reduce(
      (acc, template: DataListViewTemplateNameDirective<T>) => {
        if (template.name.startsWith('fieldValue.')) {
          const field = template.name.replace('fieldValue.', '') as keyof T;

          acc[field] = template;
        }

        return acc;
      },
      {} as Record<keyof T, DataListViewTemplateNameDirective<T>>,
    );
  }

  get columnsLength(): number {
    // all dynamic columns + 1 for actions column
    let length = this.columns.length + 1;

    if (this.showEntityIcon) {
      length++;
    }

    if (this.isShowedCheckbox) {
      length++;
    }

    if (this.isFavoriteSupported) {
      length++;
    }

    return length;
  }

  get isShowedCheckbox(): boolean {
    return this.groupActions.length > 0;
  }

  get tableName(): `${LocalStorageKey}_dataListViewTable` | null {
    // if prefixSettingsLocalStorageKey was not overrided, return null
    return this.prefixSettingsLocalStorageKey === LocalStorageKey.PREFIX_SETTINGS
      ? `${this.prefixSettingsLocalStorageKey}_dataListViewTable`
      : null;
  }

  data$: Observable<T[]>;
  loading$ = this.dataListViewBaseService.isLoading();
  pageParams$ = this.dataListViewBaseService.pageParams$;
  totalRecords$ = this.dataListViewBaseService.getTotalItems();
  querySearch$ = this.searchQueryService.querySearch$;
  selectedItems$: Observable<T[]> = this.selectedItemsService.getSelectedItems();
  editingName: T | null = null;

  protected readonly destroy$: DestroyService = inject(DestroyService);
  protected readonly translate: TranslateService = inject(TranslateService);

  constructor(
    protected readonly dataListViewBaseService: DataListViewBaseService<T>,
    protected readonly selectedItemsService: SelectedItemsService<T>,
    private readonly dataListViewLazyLoadService: DataListViewLazyLoadService,
    private readonly searchQueryService: SearchQueryService,
    private readonly actionsForItemService: ActionsForItemService<T>,
    private readonly filterService: FilterService,
    @Inject(PREFIX_SETTINGS_LOCAL_STORAGE_KEY) private readonly prefixSettingsLocalStorageKey: LocalStorageKey,
  ) {}

  ngOnInit(): void {
    this.data$ = this.dataIsLazy ? this.dataListViewBaseService.getData() : this.getFilteredData();

    this.dataListViewBaseService
      .getFilterValue('onlyFavorite')
      .pipe(take(1))
      .subscribe((value) => {
        this.onlyFavorite = value ? value.toString() === 'true' : false;
      });

    this.searchQueryService.listenQuerySearchWithDebounce(500).pipe(takeUntil(this.destroy$)).subscribe();
  }

  onLazyLoad(event: TableLazyLoadEvent): void {
    if (this.dataIsLazy) {
      this.dataListViewLazyLoadService.onLazyLoad(event);
    }
  }

  onPageChange(event: { pageSize: number; pageNumber: number }): void {
    this.dataListViewBaseService.onPageChange(mapPaginationBiToPrime(event));
  }

  onSelectedItemsChange(event: CheckboxChangeEvent, item: T): void {
    event.originalEvent.stopPropagation();
    this.selectedItems$.pipe(take(1)).subscribe((selectedItems) => {
      if (event.checked.includes(item.id)) {
        this.selectedItemsService.setSelectedItems([...selectedItems, item]);
      } else {
        this.selectedItemsService.setSelectedItems(selectedItems.filter((selectedItem) => selectedItem.id !== item.id));
      }
    });
  }

  onColResize(event: TableColResizeEvent): void {
    const { element } = event;
    const nextColumn = element.nextElementSibling as HTMLTableCellElement;
    const nextColumnWidth = nextColumn.offsetWidth;
    const nextColumnMinWidth = Number(nextColumn.style.minWidth.replace(/[^\d.]/g, '')) || 15;
    const delta = nextColumnWidth - nextColumnMinWidth;

    if (delta < 0) {
      const newColumnWidth = element.offsetWidth + delta;
      this.table.resizeTableCells(newColumnWidth, nextColumnMinWidth);
    }
  }

  startEditName(item: T): void {
    this.editingName = item;
  }

  saveName(): void {
    if (this.editingName) {
      this.renameItem.emit({ ...this.editingName, name: this.editingName.name });
    }

    this.editingName = null;
  }

  selectedItemsChange(selectedItems: T[]) {
    this.selectedItemsService.setSelectedItems(selectedItems);
  }

  filterByFavorite() {
    this.onlyFavorite = !this.onlyFavorite;
    this.dataListViewBaseService.updateFilter('onlyFavorite', this.onlyFavorite);
  }

  // TODO: check performance issue
  // arrow function bcz directive executeWith is lost context
  getActionsForItem = (item: T): Observable<DataListItemAction[]> => {
    return this.actionsForItemService.getActionsForItem(item);
  };

  private getFilteredData(): Observable<T[]> {
    return this.searchQueryService.querySearch$.pipe(
      switchMap((query) => {
        return this.dataListViewBaseService.getData().pipe(
          map((data) => {
            return { data, query };
          }),
        );
      }),
      map(({ data, query }) => this.filterService.filter(data, this.globalFilterFields || ['name'], query, 'contains')),
      tap((data) => this.dataListViewBaseService.setTotalItems(data.length)),
    );
  }
}
