<p-confirmPopup></p-confirmPopup>

<ng-container
  *ngIf="{
    data: data$ | async,
    loading: loading$ | async,
    totalRecords: totalRecords$ | async,
    selectedItems: selectedItems$ | async,
  } as obs"
>
  <ng-container *ngIf="pageParams$ | async as pageParams">
    <ng-container *ngIf="!obs.loading && obs.data; else loadingTemplate">
      <p-dataView
        [lazy]="dataIsLazy"
        [paginator]="!dataIsLazy"
        [rows]="pageParams.pageSize"
        [first]="pageParams.pageSize * (pageParams.pageNumber - 1)"
        [totalRecords]="obs.totalRecords"
        [sortField]="pageParams.sortField"
        [sortOrder]="pageParams.sortOrder === 'asc' ? 1 : -1"
        [loading]="obs.loading"
        [lazyLoadOnInit]="false"
        (onLazyLoad)="onLazyLoad($event)"
        dataKey="id"
        [value]="obs.data"
        [layout]="'grid'"
        [emptyMessage]="emptyMessage || ('data-list-view.empty-message' | translate)"
      >
        <ng-template let-items pTemplate="gridItem">
          <div class="p-grid p-nogutter grid grid-nogutter">
            <div class="col-3 p-2" *ngFor="let item of items">
              <div class="p-5 flex flex-column gap-3 border-1 surface-border surface-card border-round h-full">
                <div class="flex flex-wrap align-items-center justify-content-between">
                  <div class="text-primary flex align-items-center gap-3">
                    <i *ngIf="showEntityIcon && item.icon" tooltipPosition="top" class="{{ item.icon }} text-2xl"></i>

                    <ng-container *ngIf="item.labels?.length">
                      <i
                        *ngFor="let label of item.labels"
                        class="{{ label.icon }}"
                        style="font-size: 1rem"
                        tooltipPosition="top"
                        pTooltip="{{ label.tooltipTranslate | translate }}"
                      ></i>
                    </ng-container>
                  </div>
                  <div *ngIf="getItemsMenu | executeWith: item | async as menuItems">
                    <p-button
                      type="button"
                      [text]="true"
                      icon="pi pi-ellipsis-v"
                      (click)="menu.toggle($event)"
                    ></p-button>

                    <p-menu
                      #menu
                      [popup]="true"
                      [appendTo]="'body'"
                      [style]="{ width: 'auto' }"
                      [model]="menuItems"
                    ></p-menu>
                  </div>
                </div>

                <div>
                  <p class="text-xl name-color text-center item-name" [title]="item.name">
                    <a
                      [routerLink]="item.routerLinkToItem?.routerLink"
                      [queryParams]="item.routerLinkToItem?.queryParams"
                      class="text-700"
                    >
                      {{ item.name }}
                    </a>
                  </p>
                </div>

                <a *ngIf="temlateByName.gridItemPreview" class="surface-ground border-round square">
                  <ng-container
                    *ngTemplateOutlet="temlateByName.gridItemPreview.template; context: { $implicit: item }"
                  ></ng-container>
                </a>

                <div *ngIf="temlateByName.gridItemInfo" class="py-3">
                  <ng-container
                    *ngTemplateOutlet="temlateByName.gridItemInfo.template; context: { $implicit: item }"
                  ></ng-container>
                </div>
                <div class="flex align-items-center justify-content-between">
                  <p-checkbox
                    [value]="item.id"
                    [ngModel]="selectedItemIds$ | async"
                    (onChange)="onSelectedItemsChange($event, item)"
                  ></p-checkbox>

                  <ng-container *ngIf="isFavoriteSupported">
                    <button
                      pButton
                      type="button"
                      class="p-button-text p-button-link p-button-lg"
                      appearance="icon"
                      pTooltip="{{
                        (item.favorite ? 'data-list-view.remove-favorite' : 'data-list-view.add-favorite') | translate
                      }}"
                      [icon]="item.favorite ? 'pi pi-star-fill text-yellow-500' : 'pi pi-star text-gray-800'"
                      (click)="toggleFavorite.emit({ itemId: item.id, isFavorite: !item.favorite })"
                    ></button>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </p-dataView>
    </ng-container>

    <ng-template #loadingTemplate>
      <p-dataView [value]="[].constructor(pageParams.pageSize)" [layout]="'grid'">
        <ng-template pTemplate="gridItem" let-items>
          <div class="p-grid p-nogutter grid grid-nogutter" ngFor>
            <div class="col-3 p-2" *ngFor="let item of items">
              <div class="p-5 flex flex-column gap-3 border-1 surface-border surface-card border-round h-full">
                <div class="flex flex-wrap align-items-center justify-content-between">
                  <div class="text-primary flex align-items-center gap-3">
                    <p-skeleton *ngIf="showEntityIcon" width="21px" height="21px"></p-skeleton>
                  </div>
                </div>

                <p-skeleton width="100%" height="1.5rem"></p-skeleton>

                <div class="p-2">
                  <ng-container *ngIf="temlateByName.gridItemPreviewSkeleton; else defaultSkeleton">
                    <ng-container *ngTemplateOutlet="temlateByName.gridItemPreviewSkeleton.template"></ng-container>
                  </ng-container>
                  <ng-template #defaultSkeleton>
                    <div class="square flex">
                      <p-skeleton width="100%" height="100%"></p-skeleton>
                    </div>
                  </ng-template>
                </div>
                <p-checkbox *ngIf="isShowedCheckbox"></p-checkbox>
              </div>
            </div>
          </div>
        </ng-template>
      </p-dataView>
    </ng-template>

    <selfai-platform-data-list-view-pagination
      [pageNumber]="pageParams.pageNumber"
      [pageSize]="pageParams.pageSize"
      [totalRecords]="obs.totalRecords"
      (pageChange)="onPageChange($event)"
    ></selfai-platform-data-list-view-pagination>
  </ng-container>
</ng-container>
