import { Injectable } from '@angular/core';
import { Filter } from '@selfai-platform/bi-domain';
import { getIdContextKey } from '@selfai-platform/shared';
import { Observable, map, take } from 'rxjs';
import { FieldSelection, WidgetNode } from '../../models';
import { FilterSelectionStore } from '../../stores';

@Injectable({
  providedIn: 'root',
})
export class FilterSelectionService {
  constructor(private readonly store: FilterSelectionStore) {}

  toggleSelection(dashboardId: string, toggledField: FieldSelection, contextId?: string): void {
    this.getFieldSelection(dashboardId, toggledField, contextId)
      .pipe(take(1))
      .subscribe((fieldValues) => {
        const index = fieldValues.findIndex((fieldValue) => fieldValue.value === toggledField.value);
        if (index !== -1) {
          this.store.upsertOneInCache({
            id: dashboardId,
            contextId,
            fields: [...fieldValues.slice(0, index), ...fieldValues.slice(index + 1)],
          });
        } else {
          this.store.upsertOneInCache({ id: dashboardId, contextId, fields: [...fieldValues, toggledField] });
        }
      });
  }

  getSelection(dashboardId: string, contextId?: string): Observable<FieldSelection[]> {
    return this.store.entityMap$.pipe(
      map((entityMap) => {
        return entityMap[getIdContextKey(dashboardId, contextId)]?.fields || [];
      }),
    );
  }

  resetAllSelection(dashboardId: string, contextId?: string): void {
    this.store.upsertOneInCache({ id: dashboardId, contextId, fields: [] });
  }

  mapNodeToSelection(widgetId: string, widgetNode: WidgetNode, dataSourceEngineName: string): FieldSelection {
    // TODO: if field is measure, then in value something else
    // Possible this solution to work only for dimension fields
    return {
      fieldName: widgetNode.fields[0],
      value: widgetNode.originalName,
      widgetId,
      dataSourceEngineName,
    };
  }

  getFieldSelection(
    dashboardId: string,
    searchedField: Omit<FieldSelection, 'value'>,
    contextId?: string,
  ): Observable<FieldSelection[]> {
    return this.getSelection(dashboardId, contextId).pipe(
      map((fields) =>
        fields.filter(
          (field) =>
            !(
              field.fieldName === searchedField.fieldName &&
              field.dataSourceEngineName === searchedField.dataSourceEngineName
            ),
        ),
      ),
    );
  }

  mapSelectionToApiFilter(selection: FieldSelection): Filter {
    return {
      dataSource: selection.dataSourceEngineName,
      field: selection.fieldName,
      ref: selection.fieldName,
      type: 'inclusion',
      valueList: [selection.value as string],
    };
  }
}
