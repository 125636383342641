<div class="editor-container">
  <ng-container *ngIf="!maximized">
    <ngx-monaco-editor
      [options]="editorOptions"
      [(ngModel)]="value"
      class="p-inputtext"
      [ngClass]="{ 'monako-minimazed': canMaximized }"
    ></ngx-monaco-editor>
    <div class="button-wrapper">
      <button class="p-link toggle-word-wrap">
        <i class="pi pi-reply" (click)="toggleWordWrap()"></i>
      </button>
      <button *ngIf="canMaximized" class="p-link">
        <i class="pi pi-window-maximize" (click)="maximazeEditor()"></i>
      </button>
    </div>
  </ng-container>
  <ng-container *ngIf="maximized">
    <div class="fake-editor"></div>
  </ng-container>
  <ng-container *ngIf="maximized">
    <p-dialog
      header="Code editor"
      [(visible)]="maximized"
      appendTo="body"
      [closeOnEscape]="true"
      [closable]="true"
      [style]="{ width: '50vw', height: '75vw' }"
      [maximizable]="true"
    >
      <ngx-monaco-editor
        [disabled]="disabled"
        [options]="editorOptions"
        [(ngModel)]="value"
        class="p-inputtext"
        style="height: 100%"
      ></ngx-monaco-editor>
    </p-dialog>
  </ng-container>
</div>
