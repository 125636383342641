export class ColorPicker {
  color: string;

  showAlpha: boolean;

  preferredFormat: string;

  showInput: boolean;

  showPalette: boolean;

  showInitial: boolean;

  palette: string[][];

  showUserColor: boolean;

  clickoutFiresChange: boolean;
}
