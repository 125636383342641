import { Datasource } from "../../../datasource";
import { Dashboard, GetDashboardResult, BoardDataSource } from "../../models";
import { isSameDataSource } from "./is-same-datasource";

export function getDataSourceFromBoardDataSource(
    board: Dashboard | GetDashboardResult,
    boardDataSource: BoardDataSource,
  ): Datasource | null {
    if (boardDataSource) {
      return (board as any).dataSources.find((item: Datasource) => isSameDataSource(boardDataSource, item));
    }
  
    return null;
  }