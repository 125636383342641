import { EntityMetadataMap } from '@ngrx/data';
import { getIdContextKey } from '@selfai-platform/shared';
import { GLOBAL_FILTERS_ENTITY_NAME } from '../constants';
import { GlobalFiltersState } from '../models';

const entityMetadata: EntityMetadataMap = {
  [GLOBAL_FILTERS_ENTITY_NAME]: {
    selectId: (entity: GlobalFiltersState) => getIdContextKey(entity.id, entity.contextId),
  },
};

const pluralNames = {
  [GLOBAL_FILTERS_ENTITY_NAME]: 'GlobalFiltersAll',
};

export const biDomainFiltersEntityConfig = {
  entityMetadata,
  pluralNames,
};
