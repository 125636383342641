import { Component, ElementRef, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';

import * as _ from 'lodash';
import { takeUntil } from 'rxjs';

import {
  AggregationType,
  ByTimeUnit,
  ChartType,
  DIRECTION,
  Dashboard,
  DashboardDomainService,
  EventType,
  GranularityType,
  MapLayerType,
  PageWidget,
  Pivot,
  PivotField,
  SeriesType,
  Shelf,
  TimeUnit,
  UIChartAxis,
  UIChartColorByValue,
  UIMapOption,
  UIOption,
} from '@selfai-platform/bi-domain';
import { DestroyService } from '@selfai-platform/shared';

import { AbstractComponent } from '../../common/component/abstract.component';
import { Modal } from '../../common/domain/modal';
import { StringUtil } from '../../common/util/string.util';

@Component({
  selector: 'pivot-context',
  templateUrl: './pivot-context.component.html',
  providers: [DestroyService],
})
export class PivotContextComponent extends AbstractComponent implements OnInit, OnDestroy {
  @Input()
  public uiOption: UIOption;

  @Input()
  public editingField: PivotField;

  @Input()
  public filterFiledList: string[] = [];

  @Input()
  public widget: PageWidget;

  @Input()
  public chartType: string;

  @Input()
  public pivot: Pivot;

  @Input()
  public shelf: Shelf;

  @Input()
  public combineAggIndex: number;

  @Input()
  public aggTypeList: any[];

  @Output()
  public editingFieldChange: EventEmitter<PivotField> = new EventEmitter();

  @Output()
  public changePivotContext: EventEmitter<any> = new EventEmitter();

  public editingFieldAlias: string;

  public fix2DepthContext = false;

  private dashboard: Dashboard;

  constructor(
    protected elementRef: ElementRef,
    protected injector: Injector,
    private readonly dashboardDomainService: DashboardDomainService,
    private readonly destroy$: DestroyService,
  ) {
    super(elementRef, injector);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.dashboardDomainService.loadDashboard(this.widget.dashBoardId).pipe(takeUntil(this.destroy$)).subscribe();

    this.dashboardDomainService
      .getDashboard(this.widget.dashBoardId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((dashboard) => {
        this.dashboard = dashboard;
      });
  }

  public onChangeFilter($event): void {
    if (this.editingField.field.filtering) {
      $event.target ? ($event.target.checked = true) : ($event.currentTarget.checked = true);
      this.alertPrimeService.warn(this.translateService.instant('msg.board.alert.recomm-filter.del.error'));
      return;
    }

    this.changePivotContext.emit({
      type: 'toggleFilter',
      value: this.editingField,
    });
  }

  public setEditingFieldAlias(editingField: PivotField) {
    if (this.isSetPivotAlias(editingField)) {
      if (editingField.pivotAlias) {
        this.editingFieldAlias = editingField.pivotAlias.trim();
      } else {
        this.editingFieldAlias = editingField.alias.trim();
      }
    } else {
      this.editingFieldAlias = '';
    }
  }

  public getDisplayEditingPivotAlias(editingField: PivotField): string {
    if (editingField.pivotAlias) {
      return editingField.pivotAlias;
    } else {
      return editingField.alias ? editingField.alias : 'NONE';
    }
  }

  public isSetPivotAlias(editingField: PivotField): boolean {
    if (editingField.pivotAlias) {
      return true;
    } else {
      return (
        editingField.alias && editingField.alias !== editingField.name && editingField.fieldAlias !== editingField.alias
      );
    }
  }

  public onAliasApply(event: Event): void {
    event.stopPropagation();

    if (this.editingField.pivotAlias && this.editingField.pivotAlias.trim().length > 50) {
      this.alertPrimeService.info(this.translateService.instant('msg.page.alert.chart.title.maxlength.warn'));
      return;
    }

    const list = this.returnPivotShelf();

    let duppIndex: number = _.findIndex(list, (item) => {
      return item.pivotAlias == this.editingFieldAlias || item.fieldAlias == this.editingFieldAlias;
    });
    if (duppIndex == -1) {
      this.dashboard.configuration.fields.forEach((field, index) => {
        if (field.nameAlias && field.nameAlias.nameAlias === this.editingFieldAlias) {
          duppIndex = index;
          return false;
        }
      });
    }

    if (duppIndex > -1) {
      this.alertPrimeService.info(this.translateService.instant('msg.page.alert.chart.alias.dupp.warn'));
      return;
    }

    if (this.editingFieldAlias.trim() === '') {
      this.onAliasReset(null);
      return;
    }

    this.editingFieldAlias =
      this.editingFieldAlias == this.editingField.name ? this.editingFieldAlias + ' ' : this.editingFieldAlias;
    this.editingField.alias = this.editingFieldAlias;
    this.editingField.pivotAlias = this.editingFieldAlias;
    this.fix2DepthContext = false;

    this.changePivotContext.emit({
      type: 'changePivot',
      value: EventType.PIVOT_ALIAS,
    });

    this.editingFieldChange.emit(this.editingField);
  }

  public onAliasReset(event: Event): void {
    if (event) {
      event.stopPropagation();
    }

    delete this.editingField.alias;
    delete this.editingField.pivotAlias;
    this.editingFieldAlias = '';
    this.fix2DepthContext = false;

    this.changePivotContext.emit({
      type: 'changePivot',
      value: EventType.PIVOT_ALIAS,
    });
  }

  public unescapeCustomColumnExpr(expr: string) {
    return StringUtil.unescapeCustomColumnExpr(expr);
  }

  protected getAlignName() {
    if (this.editingField.direction === DIRECTION.ASC) {
      return 'Ascending';
    } else if (this.editingField.direction === DIRECTION.DESC) {
      return 'Descending';
    }
    return 'In order of data';
  }

  protected onChangeOrder(direction: string) {
    this.editingField.direction = DIRECTION[direction];

    const list = this.returnPivotShelf();

    list.forEach((item) => {
      delete item.lastDirection;
    });
    this.editingField.lastDirection = true;

    this.changePivotContext.emit({ type: 'changePivot' });
  }

  protected hasAlign(direction: string) {
    if (direction === 'NONE' && !this.editingField.hasOwnProperty('direction')) {
      return true;
    }
    return this.editingField.direction === DIRECTION[direction];
  }

  protected onChangeAggregationType(aggregationTypeId: string, aggTypeOption: number) {
    event.stopPropagation();

    if (
      -1 !== this.editingField.aggregationTypeList.indexOf(aggregationTypeId) &&
      String(AggregationType.PERCENTILE) !== aggregationTypeId
    ) {
      return;
    }

    if (String(AggregationType.PERCENTILE) === aggregationTypeId && !aggTypeOption) {
      return;
    }

    this.editingField.aggregationType = aggregationTypeId;

    if (aggTypeOption) {
      this.setEditingFieldOptions('value=', aggTypeOption);
    }

    this.changePivotContext.emit({
      type: 'changePivot',
      value: EventType.AGGREGATION,
    });
  }

  public combineChangeSeriesConvertType(seriesType: SeriesType) {
    this.combineAggIndex = this.pivot.aggregations.indexOf(this.editingField);

    this.setEditingFieldOptions('series.type=', seriesType);

    this.changePivotContext.emit({ type: 'changePivot' });
  }

  public onChangeFormat(formatType: string): void {
    if (formatType != '') {
      const field: PivotField = _.cloneDeep(this.editingField);
      if (!field.format) {
        field.format = {};
      }
      field.format.type = formatType;
      this.changePivotContext.emit({ type: 'format', value: field });
    } else {
      this.changePivotContext.emit({ type: 'format', value: null });
    }
  }

  public onChangeShowValue(showValue: boolean): void {
    this.editingField.showValue = showValue;

    this.changePivotContext.emit({ type: 'pivotFilter' });
  }

  protected isPossibleSeries() {
    if (
      !_.eq(this.chartType, ChartType.BAR) &&
      !_.eq(this.chartType, ChartType.LINE) &&
      !_.eq(this.chartType, ChartType.CONTROL)
    ) {
      return false;
    }

    if (this.pivot.aggregations.length < 2) {
      return false;
    }

    if (this.pivot.aggregations[0] == this.editingField) {
      return false;
    }

    return true;
  }

  public onChangeSecondaryAxis($event: Event): void {
    const secondaryAxis: UIChartAxis = _.cloneDeep(this.uiOption.yAxis);
    secondaryAxis.name = this.editingField.alias;
    this.uiOption.secondaryAxis = secondaryAxis;

    this.changePivotContext.emit({ type: 'changePivot' });
  }

  protected getGranularityName(field: PivotField, byUnitShowFl?: boolean) {
    return field.format && field.format.unit
      ? field.format.byUnit && byUnitShowFl
        ? field.format.unit.toString() + ' BY ' + field.format.byUnit.toString()
        : field.format.unit.toString()
      : '';
  }

  protected onChangeGranularity(discontinuous: boolean, unit: string, byUnit?: string) {
    if (
      this.editingField.format.discontinuous == discontinuous &&
      this.editingField.format.unit == TimeUnit[unit] &&
      this.editingField.format.byUnit == ByTimeUnit[byUnit]
    ) {
      return;
    }

    if (
      this.uiOption.color &&
      (<UIChartColorByValue>this.uiOption.color).ranges &&
      (<UIChartColorByValue>this.uiOption.color).ranges.length > 0 &&
      (this.editingField.format.discontinuous !== discontinuous || this.editingField.format.unit !== TimeUnit[unit])
    ) {
      const modal = new Modal();
      modal.name = this.translateService.instant('msg.page.chart.color.measure.range.grid.original.description');
      modal.data = {
        data: { discontinuous: discontinuous, unit: unit, byUnit: byUnit },
        eventType: EventType.GRANULARITY,
      };

      this.changePivotContext.emit({ type: 'showPopup', value: modal });
      return;
    }

    this.changePivotContext.emit({
      type: 'onSetGranularity',
      value: { discontinuous, unit, byUnit },
    });
  }

  public isUseGranularity(discontinuous: boolean, unit: string, byUnit?: string): boolean {
    return this.useGranularity(discontinuous, unit, this.editingField.granularity, byUnit);
  }

  protected isGranularitySelected(field: PivotField, discontinuous: boolean, unit: string, byUnit?: string) {
    if (_.isUndefined(field.format)) {
      return false;
    }

    if (!discontinuous) {
      return !field.format.discontinuous && field.format.unit == TimeUnit[unit];
    } else {
      return (
        field.format.discontinuous &&
        field.format.unit == TimeUnit[unit] &&
        (!byUnit || (byUnit && field.format.byUnit == ByTimeUnit[byUnit]))
      );
    }
  }

  public getAliasPlaceholder(field: PivotField): string {
    const displayName: string = field.fieldAlias ? field.fieldAlias : field.name;
    return field['aggregationType'] ? field['aggregationType'] + '(' + displayName + ')' : displayName;
  }

  protected isSecondaryAxis(): boolean {
    if (this.uiOption.secondaryAxis) {
      return false;
    }

    if (this.uiOption.secondaryAxis && this.uiOption.secondaryAxis.name == this.editingField.alias) {
      return true;
    } else {
      return false;
    }
  }

  public clickOutside() {
    this.editingField = null;
    this.fix2DepthContext = false;

    this.changePivotContext.emit({ type: 'outside', value: this.editingField });
  }

  public showAggregate(): boolean {
    let returnValue = false;

    if (this.editingField.type == 'measure' && !this.editingField.aggregated) {
      if (ChartType.MAP === this.uiOption.type) {
        const mapUIOption = <UIMapOption>this.uiOption;
        const layerType = mapUIOption.layers[mapUIOption.layerNum].type;

        if (MapLayerType.TILE === layerType) {
          returnValue = true;
        }
      } else {
        returnValue = true;
      }
    }

    return returnValue;
  }

  private useGranularity(discontinuous: boolean, unit: string, granularity: GranularityType, byUnit?: string): boolean {
    const getGranularityScore = (granularity: string): number => {
      let score = 0;
      switch (granularity) {
        case String(GranularityType.SECOND):
          score = 1;
          break;
        case String(GranularityType.MINUTE):
          score = 2;
          break;
        case String(GranularityType.HOUR):
          score = 3;
          break;
        case String(GranularityType.DAY):
          score = 4;
          break;
        case String(GranularityType.WEEK):
          score = 4;
          break;
        case String(GranularityType.MONTH):
          score = 6;
          break;
        case String(GranularityType.QUARTER):
          score = 6;
          break;
        case String(GranularityType.YEAR):
          score = 8;
          break;
      }
      return score;
    };

    const minGranularityScore: number = getGranularityScore(String(granularity));

    const granularityScore: number = getGranularityScore(unit);

    return granularityScore >= minGranularityScore;
  }

  private setEditingFieldOptions(key: string, optionData: any) {
    if (!this.editingField.options) {
      this.editingField.options = key + optionData;
    } else {
      if (this.editingField.options.indexOf(key) != -1) {
        const optionsList = this.editingField.options.split(',');

        for (let num = 0; num < optionsList.length; num++) {
          const option = optionsList[num];

          if (option.indexOf(key) != -1) {
            optionsList[num] = num !== 0 ? key + optionData : key + optionData;
          }
        }

        this.editingField.options = optionsList.join();
      } else {
        this.editingField.options = this.editingField.options + ',' + key + optionData;
      }
    }
  }

  private returnPivotShelf(): PivotField[] {
    let list = [];

    if (ChartType.MAP === this.uiOption.type) {
      list = this.shelf.layers[(<UIMapOption>this.uiOption).layerNum].fields;
    } else {
      list = _.concat(this.pivot.columns, this.pivot.rows, this.pivot.aggregations);
    }

    return list;
  }
}
