import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { BiPermissionService } from '@selfai-platform/bi-domain';
import { BiPermission } from '@selfai-platform/shared';

import { UserService } from '../../user/service/user.service';

@Injectable()
export class RecyclebinAdminGuard {
  constructor(
    private readonly userService: UserService,
    private readonly permissionService: BiPermissionService,
    private readonly router: Router,
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise<boolean>((resolve, reject) => {
      this.userService
        .isLoggedIn()
        .then((loggedIn) => {
          const canActivate = loggedIn && this.permissionService.hasOneOfPermission([BiPermission.KD_RECYCLEBIN_ADMIN]);

          if (!canActivate) {
            this.router.navigate(['access-denied']);
          }

          resolve(canActivate);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
