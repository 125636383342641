import { LoggingApi, LoggingApiEnabled, LoggingFormValue } from '@selfai-platform/pipeline-common';

export function normalizeLoginToApiModel(loggingForm: LoggingFormValue): LoggingApi {
  const { enabled, column } = loggingForm;

  if (enabled) {
    return {
      Logging: {
        Enabled: {
          'Identification field': {
            type: 'column',
            value: column as string,
          },
        },
      },
    };
  }

  return {
    Logging: {
      Disabled: {},
    },
  };
}

export function normalizeLoggingFromApiModel(dataLogging: LoggingApi['Logging'] | null): LoggingFormValue | null {
  if (!dataLogging) {
    return null;
  }

  if ((dataLogging as LoggingApiEnabled).Enabled) {
    return {
      enabled: true,
      column: (dataLogging as LoggingApiEnabled).Enabled['Identification field']?.value as string,
    };
  }

  return {
    enabled: false,
    column: null,
  };
}
