<form [formGroup]="form" class="pt-4">
  <div class="grid">
    <div class="col-5">
      <div class="p-float-label mb-5">
        <input class="w-full" pInputText formControlName="name" />
        <label>{{ "msg.workspace.settings.schedules.form.label.name" | translate }}</label>
      </div>
    </div>
    <div class="col-5">
      <div class="p-float-label mb-5">
        <textarea rows="1" class="w-full" pInputTextarea [autoResize]="true" formControlName="description"></textarea>
        <label>{{ "msg.workspace.settings.schedules.form.label.description" | translate }}</label>
      </div>
    </div>
    <div class="col-2 text-right">
      <p-button
      class="mr-2"
      icon="pi {{ isDisable ? 'pi-pen-to-square' : 'pi-times-circle' }}"
      (click)="editChange()"
      pTooltip=" {{ isDisable ? ('msg.workspace.settings.schedules.form.tooltip.edit-title-and-description' | translate) : ('msg.workspace.settings.schedules.form.tooltip.cancel' | translate) }}"
      tooltipPosition="top"
      >
    </p-button>
      <p-button
        icon="pi pi-save"
        pTooltip="{{ 'msg.workspace.settings.schedules.form.tooltip.save' | translate }}"
        tooltipPosition="top"
        [disabled]="isDisable"
        (click)="onSubmit()">
      </p-button>
    </div>
  </div>
</form>
