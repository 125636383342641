import { ExpressionSource } from './expression-source.enum';
import { Expression } from './expression.enum';
import { SelectionColumnApi } from './selection.model';

export const mapExpressionLegacy: Record<keyof ExpressionTypeLegacy, Expression> = {
  'Percentage (%)': Expression.PERCENTAGE,
  'Addition (+)': Expression.ADDITION,
  'Subtraction (-)': Expression.SUBSTRACTION,
  'Division (/)': Expression.DIVISION,
  'Multiplication (*)': Expression.MULTIPLICATION,
  'Expression ((column1 + column2) / column3)': Expression.EXPRESSION,
};

export interface ExpressionTypeLegacy {
  'Percentage (%)'?: ExpressionLegacy;
  'Addition (+)'?: ExpressionLegacy;
  'Subtraction (-)'?: ExpressionLegacy;
  'Division (/)'?: ExpressionLegacy;
  'Multiplication (*)'?: ExpressionLegacy;
  'Expression ((column1 + column2) / column3)'?: ExpressionLegacy;
}

export interface ExpressionLegacy {
  'Source value #1'?: ExpressionSourceLegacy | null;
  'Source value #2'?: ExpressionSourceLegacy | null;
  'input expression'?: string | null;
  'Division by zero strategy'?: Partial<Record<DivisionZeroStrategy, never>>;
}

export enum DivisionZeroStrategy {
  Zero = `'0' if divider is zero`,
  Null = `'null' if divider is zero`,
}

export interface ExpressionSourceLegacy {
  'Select existing column'?: {
    Column: SelectionColumnApi | null;
  };
  'Input amount'?: {
    Amount: number | null;
  };
  'Input column name'?: {
    'Column name': string | null;
  };
}

export const mapExpressionSourceTypeLegacy: Record<keyof ExpressionSourceLegacy, ExpressionSource> = {
  'Select existing column': ExpressionSource.SELECT_EXISTING_COLUMN,
  'Input column name': ExpressionSource.INPUT_COLUMN_NAME,
  'Input amount': ExpressionSource.INPUT_AMOUNT,
};
