import { Component, ElementRef, Injector } from '@angular/core';

import * as _ from 'lodash';

import { BaseOptionComponent } from './base-option.component';

@Component({
  selector: 'split-option',
  templateUrl: './split-option.component.html',
})
export class SplitOptionComponent extends BaseOptionComponent {
  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }
}
