import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { RangeSliderComponent } from './range-slider.component';

@NgModule({
  declarations: [RangeSliderComponent],
  exports: [RangeSliderComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class RangeSliderModule {}
