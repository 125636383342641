import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { EditSelectionFieldComponent } from './components';

@NgModule({
  imports: [
    CommonModule,
    MessageModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    DropdownModule,
  ],
  declarations: [EditSelectionFieldComponent],
  exports: [EditSelectionFieldComponent],
})
export class EditSelectionFieldComponentModule {}
