export const CodeLanguage = {
  PYTHON: 'python',
  SQL: 'sql',
  SPARKSQL: 'sparksql',
  R: 'r',
  REGEXP: 'regexp',
  SCALA: 'scala',
} as const;

export type CodeLanguage = typeof CodeLanguage[keyof typeof CodeLanguage];
