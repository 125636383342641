import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SchedulerPermissionAction } from '@selfai-platform/shared';
import { CronJobsModule } from 'ngx-cron-jobs';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { TooltipModule } from 'primeng/tooltip';
import { combineLatest, Observable } from 'rxjs';
import { SchedulesPermissionService } from '../../../schedules/services/schedules-permission.service';
import { WorkflowPresets } from '../../../workflow-presets';
import { WorkflowSettingsSchedules } from '../../interface';
import { WorkflowSettingsSchedulesFormComponent } from '../workflow-settings-schedules-form/workflow-settings-schedules-form.component';

@Component({
  selector: 'selfai-platform-workflow-settings-schedules',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    PaginatorModule,
    ReactiveFormsModule,
    ButtonModule,
    OverlayPanelModule,
    CronJobsModule,
    WorkflowSettingsSchedulesFormComponent,
    TooltipModule,
    TranslateModule,
  ],
  templateUrl: './workflow-settings-schedules.component.html',
  styleUrl: './workflow-settings-schedules.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowSettingsSchedulesComponent implements OnChanges, OnInit {
  showCreateForm: boolean = false;
  showEditForms: { [id: string]: boolean } = {};
  @Input() presets!: WorkflowPresets[];
  @Input() schedules!: WorkflowSettingsSchedules[];
  @Input() workflowId: string;
  @Output() addSchedule = new EventEmitter();
  @Output() editSchedule = new EventEmitter();
  @Output() deleteSchedule = new EventEmitter();

  public permissions$: Observable<Record<string, boolean>>;

  constructor(private readonly schedulesPermissionService: SchedulesPermissionService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['schedule']?.currentValue) {
      this.schedules.forEach((el) => {
        this.showEditForms[el.id] = false;
      });
    }
  }

  getPresetName(id: string): string {
    return (
      this.presets.find((el) => {
        return el.id === id;
      })?.name || ''
    );
  }

  addScheduleChange(): void {
    this.showCreateForm = !this.showCreateForm;
  }

  editScheduleChange(id: string): void {
    this.showEditForms[id] = !this.showEditForms[id];
  }

  onAddSchedule(data: WorkflowSettingsSchedules): void {
    this.addSchedule.emit(data);
    this.addScheduleChange();
  }

  onDeleteSchedule(id: string): void {
    this.deleteSchedule.emit(id);
  }

  onEditSchedule(data: WorkflowSettingsSchedules): void {
    this.editSchedule.emit(data);
    this.editScheduleChange(data.id);
  }

  ngOnInit(): void {
    this.permissions$ = combineLatest({
      canEdit: this.schedulesPermissionService.checkPermission(SchedulerPermissionAction.Update),
      canCreate: this.schedulesPermissionService.checkPermission(SchedulerPermissionAction.Create),
      canDelete: this.schedulesPermissionService.checkPermission(SchedulerPermissionAction.Delete),
    });
  }
}
