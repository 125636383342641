import { cloneDeep, extend, findIndex, isNil, merge, remove, uniq } from 'lodash';

import {
  BoardConfiguration,
  BoardDataSource,
  BoardDataSourceRelation,
  BoardWidgetOptions,
  ChartType,
  CustomField,
  Dashboard,
  DashboardPageRelation,
  Datasource,
  DatasourceField,
  FieldFormatType,
  FieldRole,
  Filter,
  FilterWidget,
  FilterWidgetConfiguration,
  GetDashboardResult,
  LayoutWidgetInfo,
  PageWidget,
  TextWidget,
  Widget,
  WidgetShowType,
  createLayoutWidgetInfo,
} from '@selfai-platform/bi-domain';
import { isNullOrUndefined, isObject } from '@selfai-platform/shared';

import { CommonConstant } from '../../common/constant/common.constant';
import { CommonUtil } from '../../common/util/common.util';
import { ChartLimitInfo } from '../dto';

import { isSameDataSource } from './is-same-datasource';

export class DashboardUtil {
  public static getFirstBoardDataSource(board: Dashboard): BoardDataSource {
    const boardDataSource: BoardDataSource = board.configuration.dataSource;
    return 'multi' === boardDataSource.type ? boardDataSource.dataSources[0] : boardDataSource;
  }

  public static getRelationBoardDataSources(board: Dashboard, engineName: string): Datasource[] {
    const boardDs: BoardDataSource = board.configuration.dataSource;
    if ('multi' === boardDs.type && boardDs.associations) {
      return boardDs.associations
        .filter((rel: BoardDataSourceRelation) => engineName === rel.source || engineName === rel.target)
        .map((rel: BoardDataSourceRelation) => {
          const relDsId: string = rel.source === engineName ? rel.target : rel.source;
          return board.dataSources.find((item) => item.engineName === relDsId);
        });
    } else {
      return [];
    }
  }

  public static getRelationDsFilters(board: Dashboard, engineName: string): Filter[] {
    const boardDs: BoardDataSource = board.configuration.dataSource;
    if ('multi' === boardDs.type && boardDs.associations) {
      return boardDs.associations
        .filter((rel: BoardDataSourceRelation) => engineName === rel.source || engineName === rel.target)
        .reduce((acc, rel: BoardDataSourceRelation) => {
          let relDsId: string = undefined;
          let relField: string = undefined;
          if (rel.source === engineName) {
            relDsId = boardDs.dataSources.find((item) => item.engineName === rel.target).id;
            relField = rel.columnPair[Object.keys(rel.columnPair)[0]];
          } else {
            relDsId = boardDs.dataSources.find((item) => item.engineName === rel.source).id;
            relField = Object.keys(rel.columnPair)[0];
          }
          acc = acc.concat(
            board.configuration.filters.filter((item) => item.dataSource === relDsId && item.field === relField),
          );
          return acc;
        }, []);
    } else {
      return [];
    }
  }

  public static findDataSourceOnBoard(
    board: Dashboard | GetDashboardResult,
    dataSource: Partial<Pick<Datasource, 'id' | 'engineName' | 'name'>>,
  ): BoardDataSource {
    const boardDs: BoardDataSource = board.configuration.dataSource as BoardDataSource;
    if ('multi' === boardDs.type) {
      return boardDs.dataSources.find((item) => isSameDataSource(item, dataSource));
    } else {
      return isSameDataSource(boardDs, dataSource) ? boardDs : undefined;
    }
  }

  public static getDataSourceFromBoardDataSource(
    board: Dashboard | GetDashboardResult,
    boardDataSource: BoardDataSource,
  ): Datasource | null {
    if (boardDataSource) {
      return (board as any).dataSources.find((item) => isSameDataSource(boardDataSource, item));
    }
    return null;
  }

  public static getFiltersForBoardDataSource(board: Dashboard, engineName: string): Filter[] {
    return board.configuration.filters.filter((filter) => filter.dataSource === engineName);
  }

  public static isCurrentDateTime(field: DatasourceField): boolean {
    return (
      CommonConstant.COL_NAME_CURRENT_DATETIME === field.name &&
      field.role === FieldRole.TIMESTAMP &&
      field.format &&
      field.format.type === FieldFormatType.TEMPORARY_TIME
    );
  }

  public static getFieldsForMainDataSource(boardConf: BoardConfiguration, engineName: string): DatasourceField[] {
    if (!boardConf.fields) {
      return [];
    }
    return boardConf.fields.filter((item) => {
      if (item.dataSource === engineName) {
        return !DashboardUtil.isCurrentDateTime(item);
      } else {
        return false;
      }
    });
  }

  public static getBoardConfiguration(board: Dashboard | GetDashboardResult): BoardConfiguration | null {
    return board?.configuration || null;
  }

  public static updateBoardConfiguration(board: Dashboard, boardConf: BoardConfiguration): Dashboard {
    if (board && boardConf) {
      board.configuration = merge(board.configuration, boardConf);

      const widgetOpts: BoardWidgetOptions = board.configuration.options.widget;
      if (widgetOpts && WidgetShowType.BY_WIDGET !== widgetOpts.showTitle) {
        board.configuration.widgets.forEach((item) => (item.title = WidgetShowType.ON === widgetOpts.showTitle));
      }

      if (board.configuration.options.layout.widgetPadding !== board.configuration.layout.dimensions.borderWidth) {
        board.configuration.layout.dimensions.borderWidth = board.configuration.options.layout.widgetPadding;
      }
    }

    return board;
  }

  public static getLayoutWidgetInfos(board: Dashboard): LayoutWidgetInfo[] {
    if (board.configuration.widgets) {
      return board.configuration.widgets.filter(
        (item) => item.isInLayout && board.widgets.some((widget) => widget.id === item.ref),
      );
    } else {
      return [];
    }
  }

  public static getLayoutWidgetInfoByComponentId(board: Dashboard, layoutCompId: string): LayoutWidgetInfo {
    return board.configuration.widgets.find((item) => item.id === layoutCompId);
  }

  public static getWidgetByLayoutComponentId(board: Dashboard, layoutCompId: string): Widget {
    const layoutWidgetInfo: LayoutWidgetInfo = this.getLayoutWidgetInfoByComponentId(board, layoutCompId);
    if (layoutWidgetInfo) {
      return board.widgets.find((item) => item.id === layoutWidgetInfo.ref);
    } else {
      return null;
    }
  }

  public static getFields(board: Dashboard): DatasourceField[] {
    return board.configuration.fields;
  }

  /**
   * @todo: For some reason, fields is already in three different places
   */
  public static getFieldByName(board: Dashboard, engineName: string, fieldName: string, ref?: string): DatasourceField {
    const { customFields = [] } = board.configuration;

    let fields = DashboardUtil.fieldsFiltered(engineName, board.configuration.fields);

    if (fields.length === 0) {
      fields = DashboardUtil.findByDataSources(board.dataSources, engineName);
    }

    const findFieldIndex = DashboardUtil.createQueryAndFindIndex(fields, fieldName, ref);

    if (findFieldIndex > -1) {
      return fields[findFieldIndex];
    }

    const customField = DashboardUtil.findCustomFieldByName(customFields, fieldName);
    return customField;
  }

  static findByDataSources(dataSources: any[], engineName: string): any {
    const dataSource = dataSources.find((item) => item.engineName === engineName || item.name === engineName);
    return dataSource.fields || [];
  }

  static findCustomFieldByName(customFields: any[], fieldName: string): any {
    const index = findIndex(customFields, (obj) => {
      return obj.name === fieldName;
    });
    return customFields[index] as any;
  }

  static createQueryAndFindIndex(fields: any[], fieldName: string, ref?: string): number {
    const query: {
      ref?: string;
      name: string;
    } = {
      name: fieldName,
    };

    if (ref) {
      query.ref = ref;
    }
    return findIndex(fields, query);
  }

  static fieldsFiltered(engineName: string, fields: DatasourceField[] = []): DatasourceField[] {
    return engineName ? fields.filter((item) => item.dataSource === engineName) : fields;
  }

  public static updateField(board: Dashboard, changeField: DatasourceField): Dashboard {
    const fields: DatasourceField[] = board.configuration.fields;
    const idx: number = fields.findIndex((field: DatasourceField) => field.name === changeField.name);
    if (-1 < idx) {
      const field: DatasourceField = fields[idx];
      fields[idx] = merge(field, changeField);
    }
    return board;
  }

  public static getCustomFields(board: Dashboard): CustomField[] {
    if (board.configuration && !board.configuration.customFields) {
      board.configuration.customFields = [];
    }
    return board.configuration.customFields;
  }

  public static setCustomFields(board: Dashboard, customFields: CustomField[]): Dashboard {
    if (customFields) {
      board.configuration.customFields = customFields;
    } else {
      board.configuration.customFields = [];
    }
    return board;
  }

  public static getBoardFilters(board: Dashboard, isClone: boolean = false): Filter[] {
    const boardFilter: Filter[] = board.configuration ? board.configuration.filters : [];
    return isClone ? cloneDeep(boardFilter) : boardFilter;
  }

  public static getBoardFilter(board: Dashboard, field: Pick<DatasourceField, 'dataSource' | 'name'>): Filter {
    return board.configuration.filters.find(
      (item) => item.dataSource === field.dataSource && item.field === field.name,
    );
  }

  public static setBoardFilters(board: Dashboard, filters: Filter[]): Dashboard {
    filters && (board.configuration.filters = filters);
    return board;
  }

  public static addBoardFilter(board: Dashboard, filter: Filter): Dashboard {
    board.configuration.filters || (board.configuration.filters = []);
    board.configuration.filters.push(filter);
    return board;
  }

  public static isNewFilter(board: Dashboard, filter: Filter): boolean {
    return (
      -1 ===
      board.configuration.filters.findIndex(
        (item) => item.dataSource === filter.dataSource && item.field === filter.field,
      )
    );
  }

  public static updateBoardFilter(
    board: Dashboard,
    filter: Filter,
    isOverwrite: boolean = false,
  ): [Dashboard, boolean] {
    const idx: number = board.configuration.filters.findIndex(
      (item) => item.dataSource === filter.dataSource && item.field === filter.field,
    );
    const clonedBoard: Dashboard = cloneDeep(board);
    let isNewFilter = false;
    if (-1 === idx) {
      isNewFilter = true;
      this.addBoardFilter(clonedBoard, filter);
    } else {
      const updatedFilter = isOverwrite ? filter : merge(board.configuration.filters[idx], filter);

      clonedBoard.configuration.filters[idx] = updatedFilter;
    }

    const targetWidget: FilterWidget = <FilterWidget>(
      clonedBoard.widgets.find((item) => this.isSameFilterAndWidget(clonedBoard, filter, item))
    );
    targetWidget && (targetWidget.configuration.filter = filter);

    return [clonedBoard, isNewFilter];
  }

  public static deleteBoardFilter(board: Dashboard, filter: Filter): Dashboard {
    remove(board.configuration.filters, { field: filter.field, dataSource: filter.dataSource });
    return board;
  }

  public static getBoardDataSource(board: Dashboard): BoardDataSource {
    return board.configuration.dataSource;
  }

  public static getDataSourceForApi(dataSource: BoardDataSource, multiDsName?: string): BoardDataSource {
    let resultDataSource = cloneDeep(dataSource);

    if (dataSource.type === 'multi' && multiDsName) {
      resultDataSource =
        resultDataSource.dataSources.find((t) => t.id === multiDsName || t.engineName === multiDsName) ||
        resultDataSource;
    }

    resultDataSource.name = isNil(resultDataSource.engineName) ? resultDataSource.name : resultDataSource.engineName;

    if ('joins' in resultDataSource && resultDataSource.joins.length > 0) {
      resultDataSource.joins.forEach((join) => {
        if (join.engineName) join.name = join.engineName;
        else join.engineName = join.name;

        if (join.join) {
          if (join.join.engineName) join.join.name = join.join.engineName;
          else join.join.engineName = join.join.name;
        }
      });
    }

    return resultDataSource;
  }

  public static getLayoutComponents(board: Dashboard): LayoutWidgetInfo[] {
    if (board && board.configuration && board.configuration.widgets) {
      return board.configuration.widgets.filter((item) => item.isInLayout);
    } else {
      return [];
    }
  }

  public static setVisibleWidgetTitle(board: Dashboard, widgetId: string, isVisible: boolean): Dashboard {
    board.configuration.widgets.some((item) => {
      if (item.ref === widgetId) {
        item.title = isVisible;
        return true;
      }
    });
    return board;
  }

  public static setUseWidgetInLayout(board: Dashboard, widgetId: string, isInLayout: boolean): Dashboard {
    board.configuration.widgets.some((item) => {
      if (item.ref === widgetId) {
        item.isInLayout = isInLayout;
        return true;
      }
    });
    return board;
  }

  public static getWidgets(board: Dashboard): Widget[] {
    return board && board.widgets ? board.widgets : [];
  }

  public static getWidget(board: Dashboard, widgetId: string): Widget {
    return board.widgets.find((item) => item.id === widgetId);
  }

  public static addWidget(board: Dashboard, widget: Widget, isInLayout: boolean = false): Dashboard {
    board.widgets.push(widget as TextWidget | PageWidget | FilterWidget);

    const newWidget: LayoutWidgetInfo = createLayoutWidgetInfo(widget.id, widget.type);
    isInLayout && (newWidget.isInLayout = true);
    if (WidgetShowType.BY_WIDGET !== board.configuration.options.widget.showTitle) {
      newWidget.title = WidgetShowType.ON === board.configuration.options.widget.showTitle;
    }

    board.configuration.widgets.push(newWidget);

    return board;
  }

  public static updateWidget(board: Dashboard, widget: Widget): Dashboard {
    board.widgets = board.widgets.map((item) => (item.id === widget.id ? extend({}, item, widget) : item));
    return board;
  }

  public static removeWidget(board: Dashboard, widgetId: string): Dashboard {
    remove(board.widgets, (widget) => widget.id === widgetId);
    remove(board.configuration.widgets, (layoutWidget) => layoutWidget.ref === widgetId);
    return board;
  }

  public static getPageWidgets(board: Dashboard): PageWidget[] {
    if (board && board.widgets && board.widgets.length > 0) {
      return board.widgets.filter((item) => 'page' === item.type).map((item) => <PageWidget>item);
    } else {
      return [];
    }
  }

  public static getTextWidgets(board: Dashboard): TextWidget[] {
    if (board && board.widgets && board.widgets.length > 0) {
      return board.widgets.filter((item) => 'text' === item.type).map((item) => <TextWidget>item);
    } else {
      return [];
    }
  }

  public static getFilterWidgets(board: Dashboard): FilterWidget[] {
    if (board && board.widgets && board.widgets.length > 0) {
      return board.configuration.filters
        .map((filter) => this.getFilterWidgetByFilter(board, filter))
        .filter((filter) => isObject(filter));
    } else {
      return [];
    }
  }

  public static getFilterWidgetByFilter(board: Dashboard, filter: Filter): FilterWidget {
    return <FilterWidget>board.widgets.find((item) => this.isSameFilterAndWidget(board, filter, item));
  }

  public static isSameFilterAndWidget(board: Dashboard, filter: Filter, widget: Widget): boolean {
    if ('filter' === widget.type) {
      const filterInWidget: Filter = (<FilterWidgetConfiguration>widget.configuration).filter;
      return filterInWidget.dataSource === filter.dataSource && filterInWidget.field === filter.field;
    } else {
      return false;
    }
  }

  public static setDashboardConfRelations(board: Dashboard, rels: DashboardPageRelation[]): Dashboard {
    if (rels) {
      board.configuration.relations = rels;
    }
    return board;
  }

  public static convertSpecToUI(boardInfo: Dashboard): Dashboard {
    if (boardInfo.configuration['userDefinedFields']) {
      boardInfo.configuration.customFields = cloneDeep(
        CommonUtil.objectToArray(boardInfo.configuration['userDefinedFields']),
      );
    }

    if (boardInfo.dataSources) {
      const filters: Filter[] = DashboardUtil.getBoardFilters(boardInfo);
      if (filters && 0 < filters.length) {
        const uniqFilterKeyList: string[] = [];
        boardInfo.configuration.filters = filters.filter((filter: Filter) => {
          const uniqFilterKey: string = filter.dataSource + '_' + filter.field;
          if (-1 === uniqFilterKeyList.indexOf(uniqFilterKey)) {
            const filterDs: Datasource = boardInfo.dataSources.find((ds) => ds.id === filter.dataSource);
            filterDs && (filter.dataSource = filterDs.engineName);
            if (isNullOrUndefined(filter.dataSource)) {
              const fieldDs: Datasource = boardInfo.dataSources.find((ds) =>
                ds.fields.some((item) => item.name === filter.field),
              );
              fieldDs && (filter.dataSource = fieldDs.engineName);
            }
            uniqFilterKeyList.push(uniqFilterKey);
            return true;
          } else {
            return false;
          }
        });
      }

      const widgets: Widget[] = boardInfo.widgets;
      if (widgets && 0 < widgets.length) {
        widgets.forEach((widget: Widget) => {
          if ('filter' === widget.type) {
            const filter: Filter = (<FilterWidget>widget).configuration.filter;
            const filterDs: Datasource = boardInfo.dataSources.find((ds) => ds.id === filter.dataSource);
            filterDs && (filter.dataSource = filterDs.engineName);

            if (isNullOrUndefined(filter.dataSource)) {
              const fieldDs: Datasource = boardInfo.dataSources.find((ds) =>
                ds.fields.some((item) => item.name === filter.field),
              );
              fieldDs && (filter.dataSource = fieldDs.engineName);
            }
          } else if ('page' === widget.type) {
            if (widget.configuration['fields']) {
              (<PageWidget>widget).configuration.customFields = widget.configuration['fields'];
            }
          }
        });
      }
    }

    return boardInfo;
  }

  public static getAllFiltersDsRelations(
    board: Dashboard,
    engineName: string,
    paramFilters?: Filter[],
    name?: string,
  ): Filter[] {
    const totalFilters: Filter[] = cloneDeep(paramFilters ? paramFilters : board.configuration.filters);
    let currentEngineNameFilters: Filter[] = totalFilters.filter((filter) => {
      if (!isNil(filter.dataSource)) {
        return filter.dataSource === engineName || filter.dataSource === name;
      }
      return false;
    });
    const dashboardDatasource: BoardDataSource = board.configuration.dataSource;

    if ('multi' !== dashboardDatasource.type || !dashboardDatasource.associations) {
      return currentEngineNameFilters;
    }

    let relatedDatasourceFilters: Filter[] = [];

    const sourceDatasource: BoardDataSource = dashboardDatasource.dataSources.find(
      (item) =>
        item.engineName === engineName ||
        item.name === engineName ||
        (item.connType == 'LINK' && item.engineName.startsWith(engineName + '_')),
    );

    const filteredRelDsAssociations = dashboardDatasource.associations.filter(
      (rel: BoardDataSourceRelation) =>
        sourceDatasource.engineName === rel.source || sourceDatasource.engineName === rel.target,
    );

    const reducedDsFilters = filteredRelDsAssociations.reduce((acc: Filter[], rel: BoardDataSourceRelation) => {
      let srcField: string = undefined;
      let relDsEngineName: string = undefined;
      let relField: string = undefined;
      let relDsType = undefined;

      if (rel.source === sourceDatasource.engineName) {
        relDsEngineName = dashboardDatasource.dataSources.find((item) => item.engineName === rel.target).engineName;
        relDsType = dashboardDatasource.dataSources.find((item) => item.engineName === rel.target).connType;
        srcField = Object.keys(rel.columnPair)[0];
        relField = rel.columnPair[srcField];
      } else {
        relDsEngineName = dashboardDatasource.dataSources.find((item) => item.engineName === rel.source).engineName;
        relDsType = dashboardDatasource.dataSources.find((item) => item.engineName === rel.target).connType;
        relField = Object.keys(rel.columnPair)[0];
        srcField = rel.columnPair[relField];
      }
      const resultAcc = acc.concat(
        totalFilters
          .filter(
            (item: Filter) =>
              item.dataSource === relDsEngineName ||
              (relDsType === 'LINK' && relDsEngineName.startsWith(item.dataSource + '_') && item.field === relField),
          )
          .map((item: Filter) => {
            if (paramFilters) {
              item.dataSource = engineName;
              item.field = srcField;
            }
            return item;
          }),
      );

      return resultAcc;
    }, []);
    relatedDatasourceFilters = reducedDsFilters;

    if (relatedDatasourceFilters.length > 0) {
      currentEngineNameFilters.forEach((item1) => {
        const idx: number = relatedDatasourceFilters.findIndex((item2) => item1.field === item2.field);
        if (idx > -1 && 'include' === item1.type) {
          const selection = relatedDatasourceFilters.splice(idx, 1)[0];
          item1['valueList'] = item1['valueList']
            ? uniq(item1['valueList'].concat(selection['valueList']))
            : selection['valueList'];
        }
      });

      currentEngineNameFilters = currentEngineNameFilters.concat(relatedDatasourceFilters);
    }
    return currentEngineNameFilters;
  }

  public static getChartLimitInfo(
    widgetId: string,
    type: ChartType,
    data: {
      rows: unknown[];
      info: {
        totalCategory: number;
      };
      columns: unknown[];
    },
  ): ChartLimitInfo {
    const limitInfo: ChartLimitInfo = {
      id: widgetId,
      isShow: false,
      currentCnt: 0,
      maxCnt: 0,
    };

    const excludeChartTypes =
      ChartType.GRAPH !== type && ChartType.SANKEY !== type && ChartType.GAUGE !== type && ChartType.TREEMAP !== type;

    if (excludeChartTypes && data.info) {
      limitInfo.maxCnt = data.info.totalCategory;
      if (ChartType.PIE === type || ChartType.LABEL === type || ChartType.WORDCLOUD === type) {
        if (data.columns && 0 < data.columns.length) {
          const firstColumn = data.columns[0] as { value: unknown[] };
          limitInfo.currentCnt = firstColumn.value.length;
        }
      }
      if (ChartType.GRID === type || ChartType.HEATMAP === type) {
        if (data.rows && 0 < data.rows.length) {
          limitInfo.currentCnt = data.rows.length;
        } else {
          limitInfo.currentCnt = data.columns.length;
        }
      } else {
        if (data.rows && 0 < data.rows.length) {
          limitInfo.currentCnt = data.rows.length;
        }
      }
      limitInfo.isShow = limitInfo.currentCnt < limitInfo.maxCnt;
    }
    return limitInfo;
  }
}
