<p-table class="table" #tableRef [value]="viewItems" [globalFilterFields]="['key']">
  <ng-template pTemplate="caption">
    <div class="flex">
      <span class="p-input-icon-left">
        <i class="pi pi-search"></i>
        <input pInputText type="text" (input)="filter($event)" placeholder="Search" />
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th pSortableColumn="key">
        Key
        <p-sortIcon field="key"></p-sortIcon>
      </th>
      <th>Value</th>
      <th pSortableColumn="description">
        Description
        <p-sortIcon field="description"></p-sortIcon>
      </th>
      <th pSortableColumn="modified">
        Modified
        <p-sortIcon field="modified"></p-sortIcon>
      </th>
      <th pSortableColumn="modifiedAuthor">
        Author
        <p-sortIcon field="modifiedAuthor"></p-sortIcon>
      </th>
      <th>Actions</th>
      <th pSortableColumn="usedInCurrentWorkflow">
        Used
        <p-sortIcon field="usedInCurrentWorkflow"></p-sortIcon>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-item>
    <tr>
      <td pEditableColumn class="editable-cell p-0">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input
              pInputText
              required
              name="key"
              #key="ngModel"
              [ngModel]="item.key"
              [ngModelOptions]="options"
              (keydown.enter)="onEnterClick($event)"
              (ngModelChange)="onValueChange($event, 'key', item)"
            >
          </ng-template>
          <ng-template pTemplate="output">
            {{ item.key }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td pEditableColumn class="editable-cell p-0">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <input
              pInputText 
              required
              name="value"
              [ngModel]="item.value"
              [ngModelOptions]="options"
              (keydown.enter)="onEnterClick($event)"
              (ngModelChange)="onValueChange($event, 'value', item)"
            >
          </ng-template>
          <ng-template pTemplate="output">
            {{ item.value }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td pEditableColumn class="editable-cell p-0">
        <p-cellEditor>
          <ng-template pTemplate="input">
            <textarea
              pInputTextarea
              name="description"
              [ngModel]="item.description"
              [ngModelOptions]="options"
              [autoResize]="true"
              (keydown.enter)="onEnterClick($event)"
              (ngModelChange)="onValueChange($event, 'description', item)"
            ></textarea>
          </ng-template>
          <ng-template pTemplate="output">
            {{ item.description }}
          </ng-template>
        </p-cellEditor>
      </td>
      <td>{{ item.modified | date: 'dd.MM.yyyy HH:mm' }}</td>
      <td>{{ item.modifiedAuthor }}</td>
      <td>
        <button
          *ngIf="!item.usedInCurrentWorkflow"
          type="button"
          pButton
          class="p-button-text p-button-link p-button-danger p-0"
          appearance="icon"
          icon="pi pi-trash"
          (click)="removeItem(item)"
          pTooltip="Remove"
        ></button>
      </td>
      <td>
        <selfai-platform-used-in-panel [usedInNodes]="(usedInMap$ | async)?.get(item.key)"></selfai-platform-used-in-panel>
       
      </td>
    </tr>
  </ng-template>
</p-table>
<div class="add-row">
  <button
    label="Add variable"
    pButton
    pRipple
    icon="pi pi-plus"
    type="button"
    class="p-button-text p-button-sm"
    (click)="addVariable()"
  ></button>
</div>
