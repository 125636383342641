import { BoardLayoutOptions, createBoardLayoutOptions } from './board-layout-options';
import { BoardSyncOptions } from './board-sync-options';
import { BoardWidgetOptions, createBoardWidgetOptions } from './board-widget-options';

export interface BoardGlobalOptions {
  layout: BoardLayoutOptions;
  widget: BoardWidgetOptions;
  sync?: BoardSyncOptions;
}

export function createBoardGlobalOptions(
  boardGlobalOptionsParams: Partial<BoardGlobalOptions> = {},
): BoardGlobalOptions {
  return {
    layout: createBoardLayoutOptions(),
    widget: createBoardWidgetOptions(),
    ...boardGlobalOptionsParams,
  };
}
