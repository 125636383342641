import { KtdGridLayout } from '@katoid/angular-grid-layout';

import {
  BoardConfiguration,
  DashboardLayoutContentSettings,
  DashboardLayoutContentSettingsItem,
} from '@selfai-platform/bi-domain';

export function createConfigurationFromKtdGridLayout(
  gridLayout: KtdGridLayout,
  currentConfiguration: BoardConfiguration,
): DashboardLayoutContentSettings[] {
  const content: DashboardLayoutContentSettings[] = [
    {
      type: 'ktdgrid',
      content: [],
      isClosable: true,
      reorderEnabled: true,
      title: 'ktdgrid',
      width: 100,
    },
  ];
  for (const gridItem of gridLayout) {
    const settingsItem: DashboardLayoutContentSettingsItem = {
      height: gridItem.h,
      width: gridItem.w,
      title: `${gridItem.x};${gridItem.y}`,
      type: 'stack',
      reorderEnabled: true,
      isClosable: true,
      activeItemIndex: 0,
      content: [
        {
          id: gridItem.id,
          title: gridItem.id,
          isClosable: true,
          type: 'component',
          reorderEnabled: true,
          componentName: '',
          componentState: {
            id: gridItem.id,
            imageUrl: '',
            type: 'text',
          },
        },
      ],
    };
    content[0].content.push(settingsItem);
  }
  return content;
}
