<div class="ddp-ui-chart-side ddp-show">
  <div class="ddp-ui-side-title">
    {{ 'msg.page.ui.xaxis.title' | translate }}
  </div>
  <div class="ddp-wrap-downmenu">
    <div *ngIf="possibleChartCheck('axis', uiOption.type)" class="ddp-box-down">
      <div class="ddp-wrap-divide">
        <div class="ddp-divide2">
          <div class="ddp-wrap-option-slider">
            <span class="ddp-label-slider" style="width: 100px; display: block">
              {{ 'msg.page.chart.axis.show.axis.title' | translate }}
            </span>

            <div class="ddp-checkbox-slide ddp-checkbox-automatic2">
              <input
                type="checkbox"
                (click)="showAxisName('row', !uiOption.xAxis.showName)"
                [ngModel]="uiOption.xAxis.showName"
              />
              <label><span class="ddp-slide"></span></label>
            </div>
          </div>

          <component-input
            [disabled]="!uiOption.xAxis.showName"
            [inputClass]="'ddp-input-typebasic'"
            [placeHolder]="'msg.page.ui.x-axis.title.ph' | translate"
            [value]="nameUiOption.xAxis.customName"
            (changeValue)="nameUiOption.xAxis.customName = $event; axisName('row', $event)"
          ></component-input>
        </div>
      </div>

      <axis-category-option
        *ngIf="'category' == uiOption.xAxis?.label?.type.toString()"
        [axis]="uiOption.xAxis"
        (changeCategoryAxis)="changeXAxisValue($event)"
      ></axis-category-option>

      <axis-value-option
        *ngIf="'value' == uiOption.xAxis?.label?.type.toString()"
        [axis]="uiOption.xAxis"
        [uiOption]="uiOption"
        (changeValueAxis)="changeXAxisValue($event)"
        (changeBaseline)="changeBaseline($event)"
      ></axis-value-option>

      <ng-container *ngFor="let markLine of markLines; trackBy: trackByIndex; let i = index">
        <div class="divider"></div>
        <div class="relative">
          <button
            pButton
            class="mark-line-delete p-button-text p-button-link p-button-danger p-0"
            appearance="icon"
            tooltipPosition="top"
            pTooltip="{{ 'bi.chart.mark-setting.delete' | translate }}"
            icon="pi pi-trash"
            (click)="deleteMarkLine(i)"
          ></button>

          <selfai-platform-bi-chart-mark-line-setting
            [setting]="markLine"
            [measureList]="uiOption.fieldMeasureList"
            (changeDataSetting)="markLineChange($event, i)"
            (changeUiSetting)="markLineUiSettingChange($event, i)"
            [index]="i"
          ></selfai-platform-bi-chart-mark-line-setting>
        </div>
      </ng-container>

      <div class="divider"></div>
      <button pButton (click)="addMarkLine()">{{ 'bi.chart.mark-setting.add' | translate }}</button>
    </div>
  </div>
</div>
