import { Injectable } from '@angular/core';
import { getIdContextKey } from '@selfai-platform/shared';
import { Observable, map } from 'rxjs';
import { DataSettings } from '../models/widget-data.model';
import { pivotNormalizer } from '../normalizers';
import { WidgetDataSettingsStore } from '../stores/data-settings.store';

@Injectable({
  providedIn: 'root',
})
export class DataSettingsService {
  constructor(private readonly store: WidgetDataSettingsStore) {}

  loadSettings(widgetId: string, contextId?: string): Observable<DataSettings> {
    return this.store.getByKey(getIdContextKey(widgetId, contextId)).pipe(map((entity) => entity.settings));
  }

  setSettings(widgetId: string, settings: DataSettings, contextId?: string): void {
    this.store.upsertOneInCache({
      widgetId,
      contextId,
      settings: { ...settings, pivot: pivotNormalizer(settings.pivot) },
    });
  }

  getSettings(widgetId: string, contextId?: string): Observable<DataSettings | undefined> {
    return this.store.entityMap$.pipe(map((entityMap) => entityMap[getIdContextKey(widgetId, contextId)]?.settings));
  }
}
