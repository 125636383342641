import { EntityMetadataMap } from '@ngrx/data';
import { getIdContextKey } from '@selfai-platform/shared';
import {
  DASHBOARD_FILTERS_ENTITY_NAME,
  FILTER_SELECTION_ENTITY_NAME,
  WIDGET_DATA_ENTITY_NAME,
  WIDGET_DATA_SETTINGS_ENTITY_NAME,
  WIDGET_FILTERS_ENTITY_NAME,
} from '../const';
import {
  DashboardFiltersState,
  DataSettingsState,
  FilterSelectionState,
  WidgetDataState,
  WidgetFiltersState,
} from '../models';

const entityMetadata: EntityMetadataMap = {
  [FILTER_SELECTION_ENTITY_NAME]: {
    selectId: (entity: FilterSelectionState) => getIdContextKey(entity.id, entity.contextId),
  },
  [WIDGET_DATA_SETTINGS_ENTITY_NAME]: {
    selectId: (entity: DataSettingsState) => getIdContextKey(entity.widgetId, entity.contextId),
  },
  [WIDGET_DATA_ENTITY_NAME]: {
    selectId: (entity: WidgetDataState) => getIdContextKey(entity.widgetId, entity.contextId),
  },
  [WIDGET_FILTERS_ENTITY_NAME]: {
    selectId: (entity: WidgetFiltersState) => getIdContextKey(entity.id, entity.contextId),
  },
  [DASHBOARD_FILTERS_ENTITY_NAME]: {
    selectId: (entity: DashboardFiltersState) => getIdContextKey(entity.dashboardId, entity.contextId),
  },
};

const pluralNames = {
  [FILTER_SELECTION_ENTITY_NAME]: 'FiltersSelection',
  [WIDGET_DATA_SETTINGS_ENTITY_NAME]: 'WidgetsDataSettings',
  [WIDGET_DATA_ENTITY_NAME]: 'WidgetsData',
  [WIDGET_FILTERS_ENTITY_NAME]: 'WidgetsFilters',
};

export const biChartEngineEntityConfig = {
  entityMetadata,
  pluralNames,
};
