import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WorkflowInfo } from '@selfai-platform/pipeline-common';
import { HistoryBackService } from '@selfai-platform/shared';
import { KE_ROOT_ROUTE, KE_USER_FUNCTIONS_PATH } from '@selfai-platform/shell';
import { Observable, take } from 'rxjs';
import { UserFunctionComponent } from '../../components';
import { API_RESPONSE_MESSAGES } from '../../constants';
import { FunctionTag, Namespace, UserFunctionsListItem } from '../../models';
import {
  FunctionsResponseCallbacksService,
  UserFunctionDomainService,
  UserFunctionsListDomainService,
} from '../../services';
import { NamespacesListStore, UserFunctionsTagsStore, WorkflowsListStore } from '../../store';

@Component({
  selector: 'selfai-platform-user-function-container',
  templateUrl: './user-function-container.component.html',
  styleUrls: ['./user-function-container.component.scss'],
  standalone: true,
  imports: [UserFunctionComponent, AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserFunctionContainerComponent implements OnInit {
  public userFunctions$: Observable<UserFunctionsListItem[]> =
    this.userFunctionsListDomainService.getFilteredFunctionsList();
  public workflows$: Observable<WorkflowInfo[]> = this.workflowsStore.entities$ as Observable<WorkflowInfo[]>;
  public namespaces$: Observable<Namespace[]> = this.namespacesStore.entities$ as Observable<Namespace[]>;
  public tags$: Observable<FunctionTag[]> = this.tagsStore.entities$ as Observable<FunctionTag[]>;
  public loading$: Observable<boolean> = this.userFunctionDomainService.loading$;
  public id: string = this.route?.snapshot?.params['id'];
  public editMode = !!this.id;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly userFunctionDomainService: UserFunctionDomainService,
    private readonly userFunctionsListDomainService: UserFunctionsListDomainService,
    // TODO: Shouldn't use store directly, should use domain service instead
    private readonly workflowsStore: WorkflowsListStore,
    private readonly namespacesStore: NamespacesListStore,
    private readonly tagsStore: UserFunctionsTagsStore,
    private readonly history: HistoryBackService,
    private readonly responseCallbacksService: FunctionsResponseCallbacksService,
  ) {}

  public ngOnInit(): void {
    if (this.editMode) {
      this.userFunctionDomainService.getFunction(this.id);
    }
    this.workflowsStore.getAll();
    this.namespacesStore.getAll();
    this.tagsStore.getAll();
  }

  public onSubmit(userFunction: UserFunctionsListItem): void {
    if (this.editMode) {
      this.userFunctionDomainService
        .updateFunction(userFunction)
        .pipe(take(1))
        .subscribe({
          next: () => this.responseCallbacksService.handleNextCallback(API_RESPONSE_MESSAGES.UPDATE_SUCCESS),
          error: ({ error }) =>
            this.responseCallbacksService.handleErrorCallback(
              API_RESPONSE_MESSAGES.UPDATE_ERROR,
              JSON.stringify(error),
            ),
        });
    } else {
      this.userFunctionDomainService
        .addFunction(userFunction)
        .pipe(take(1))
        .subscribe({
          next: () => this.responseCallbacksService.handleNextCallback(API_RESPONSE_MESSAGES.CREATE_SUCCESS),
          error: ({ error }) =>
            this.responseCallbacksService.handleErrorCallback(
              API_RESPONSE_MESSAGES.CREATE_ERROR,
              JSON.stringify(error),
            ),
        });
    }
  }

  public onBack(): void {
    this.history.back(['/', KE_ROOT_ROUTE, KE_USER_FUNCTIONS_PATH]);
  }
}
