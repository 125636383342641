import { ElementRef, EventEmitter, Injector, Output, Directive } from '@angular/core';

import { AbstractComponent } from './abstract.component';

@Directive()
export class AbstractPopupComponent extends AbstractComponent {
  @Output('close')
  public closeEvent: EventEmitter<void> = new EventEmitter<void>();

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
    this.addBodyScrollHidden();
  }

  public ngOnDestroy() {
    this.removeBodyScrollHidden();
    super.ngOnDestroy();
  }

  public close() {
    this.removeBodyScrollHidden();
    this.closeEvent.emit();
  }

  public translateEmbedded(value) {
    switch (value) {
      case 'Enabled': {
        return this.translateService.instant('msg.status.enabled');
      }
      default: {
        return value;
      }
    }
  }
}
