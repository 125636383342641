import { Dashboard, PageWidgetConfiguration } from '@selfai-platform/bi-domain';

export function shelfMigration(widgetConfiguration: PageWidgetConfiguration, boardInfo: Dashboard) {
  widgetConfiguration.shelf.layers = widgetConfiguration.shelf.layers.map((layer, idx: number) => {
    if (Array === layer.constructor) {
      return {
        name: 'layer' + (idx + 1),
        ref: widgetConfiguration.dataSource.engineName,
        fields: [].concat(layer),
      };
    } else {
      if ('' === layer.ref) {
        layer.ref = boardInfo.configuration.fields.find((field) => field.name === layer.fields[0].name).dataSource;
      }
      return layer;
    }
  });
  widgetConfiguration.dataSource = boardInfo.configuration.dataSource;
  return widgetConfiguration;
}
