import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { KdBackendApiService } from '../../common';
import { WorkspaceDetailViewModel } from '../../workspace';
import {
  PinsApiModel,
  WorkbookAddApiBody,
  WorkbookDetailApiProjections,
  WorkbookListApiResponse,
  WorkbookListQueryParams,
} from '../models';

@Injectable()
export class WorkbookApiService {
  constructor(private readonly kdBackendApiService: KdBackendApiService) {}

  getDashboardList(params: WorkbookListQueryParams = {}): Observable<WorkbookListApiResponse> {
    return this.kdBackendApiService.get<WorkbookListApiResponse>(
      this.kdBackendApiService.buildPath<WorkbookListQueryParams>('/workbooks', {
        projection: 'forDetailView',
        ...params,
      }),
    );
  }

  updateWorkbook(workbookId: string, workbook: Partial<WorkbookListApiResponse>): Observable<void> {
    return this.kdBackendApiService.patch<void>(
      this.kdBackendApiService.buildPath<void>(`books/${workbookId}`),
      workbook,
    );
  }

  addWorkbook(
    workspaceId: string,
    workbook: Omit<WorkbookAddApiBody, 'workspace'>,
  ): Observable<WorkspaceDetailViewModel> {
    const url = this.kdBackendApiService.buildPath('books');

    return this.kdBackendApiService.post<WorkspaceDetailViewModel>(url, {
      ...workbook,
      type: 'workbook',
      workspace: `/api/${workspaceId}`,
    });
  }

  loadWorkbook(workbookId: string): Observable<WorkbookDetailApiProjections> {
    return this.kdBackendApiService.get<WorkbookDetailApiProjections>(
      this.kdBackendApiService.buildPath<WorkbookListQueryParams>(`workbooks/${workbookId}`, {
        projection: 'forDetailView',
      }),
    );
  }

  copyWorkbook(workbookId: string): Observable<WorkspaceDetailViewModel> {
    const url = this.kdBackendApiService.buildPath(`books/${workbookId}/copy`);

    return this.kdBackendApiService.post<WorkspaceDetailViewModel>(url, null);
  }

  addAllPins(workbookId: string, pinsDashboard: string[]): Observable<unknown> {
    const url = this.kdBackendApiService.buildPath(`workbooks/${workbookId}/pins`);

    return this.kdBackendApiService.post<unknown>(url, pinsDashboard);
  }

  getPins(workbookId: string): Observable<PinsApiModel> {
    const url = this.kdBackendApiService.buildPath(`workbooks/${workbookId}/pins`);

    return this.kdBackendApiService.get<PinsApiModel>(url);
  }

  deleteDashboardFromPins(workbookId: string, dashboardId: string): Observable<void> {
    const url = this.kdBackendApiService.buildPath(`workbooks/${workbookId}/pins/${dashboardId}`);

    return this.kdBackendApiService.delete<void>(url);
  }

  addDashboardToPins(workbookId: string, dashboardId: string): Observable<void> {
    const url = this.kdBackendApiService.buildPath(`workbooks/${workbookId}/pins/${dashboardId}`);

    return this.kdBackendApiService.post<void>(url, {});
  }

  deleteAllPins(workbookId: string): Observable<void> {
    const url = this.kdBackendApiService.buildPath(`workbooks/${workbookId}/pins`);

    return this.kdBackendApiService.delete<void>(url);
  }
}
