<div class="user-function-clone-dialog">
  <div class="mt-4 mb-4 w-full">
    <p-floatLabel>
      <input id="functionName" class="w-full" type="text" pInputText [(ngModel)]="newFunctionName" />
      <label for="functionName">New function name</label>
    </p-floatLabel>
  </div>

  <div class="flex">
    <p-button class="mr-2" label="Close" [outlined]="true" severity="secondary" (click)="cancel()"></p-button>
    <p-button label="Clone function" severity="success" (click)="cloneFunction()"></p-button>
  </div>
</div>
