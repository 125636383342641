import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { SpelValueFieldComponent } from './spel-value-field.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, InputTextModule, ButtonModule],
  declarations: [SpelValueFieldComponent],
  exports: [SpelValueFieldComponent],
})
export class SpellValueFieldComponentModule {}
