import moment from 'moment';

import { TimeUnit } from '@selfai-platform/bi-domain';

import { quarterToMonth } from './quarterToMonth';

export function getDateTimeFormat(date: Date | string, timeUnit: TimeUnit, isStart: boolean = true): string {
  if (date.constructor === String) {
    date = (<string>date).replace('.000Z', '');
  }
  switch (timeUnit) {
    case TimeUnit.SECOND:
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    case TimeUnit.MINUTE:
      return moment(date).format('YYYY-MM-DD HH:mm');
    case TimeUnit.HOUR:
      return moment(date).format('YYYY-MM-DD HH');
    case TimeUnit.DAY:
      return moment(date).format('YYYY-MM-DD');
    case TimeUnit.WEEK:
      return <string>date;
    case TimeUnit.MONTH:
      return moment(date).format('YYYY-MM');
    case TimeUnit.QUARTER:
      if (date instanceof Date) {
        return moment(date).format('YYYY-MM');
      } else {
        const splitDate: string[] = date.split(/\s|-/);
        let strYear = '';
        let strQuarter = '';
        if (-1 < splitDate[0].indexOf('Q')) {
          strYear = splitDate[1];
          strQuarter = quarterToMonth(splitDate[0], isStart);
        } else {
          strYear = splitDate[0];
          strQuarter = quarterToMonth(splitDate[1], isStart);
        }
        return strYear + '-' + strQuarter;
      }
    case TimeUnit.YEAR:
      return moment(date).format('YYYY');
    default:
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
  }
}
