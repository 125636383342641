import { PeriodUnit } from '@selfai-platform/pipeline-common';
import { AggregatorDictionaries } from '../models';
import { stepTypeDefault } from './step-type-default.const';

export const dictionariesDefault: AggregatorDictionaries = {
  periodUnits: Object.values(PeriodUnit),
  stepType: stepTypeDefault,
  inputMode: ['KAFKA', 'REST', 'FILE', 'DIRECT'],
  outputConnectorType: ['KAFKA', 'REST', 'FILE'],
  outputMode: ['KAFKA', 'REST', 'FILE', 'NONE'],
};
