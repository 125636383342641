import { Injectable } from '@angular/core';
import { Profile } from '@selfai-platform/pipeline-common';
import { Observable } from 'rxjs';
import { KeUserApiService } from './ke-user-api.services';

@Injectable({
  providedIn: 'root',
})
export class KeUserService {
  constructor(private readonly keUserApiService: KeUserApiService) {}

  getCurrentUserProfile(): Observable<Profile> {
    return this.keUserApiService.getCurrentUserProfile();
  }
}
