import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Observable } from 'rxjs';
import { KdBackendApiService } from '../../common';
import { DataSettings } from '../models/widget-data-settings.model';

@Injectable()
export class WidgetDataSettingsDataService extends DefaultDataService<DataSettings> {
  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private readonly kdBackendApiService: KdBackendApiService,
  ) {
    super('WidgetDataSettings', http, httpUrlGenerator);
  }

  // TODO: use real url
  override getById(widgetId: string): Observable<DataSettings> {
    const url = this.kdBackendApiService.buildPath(`/custom/url/for/loadSettings/${widgetId}`);

    return this.kdBackendApiService.get<DataSettings>(url);
  }

  // TODO: use real url
  override upsert(entity: DataSettings): Observable<DataSettings> {
    // const url = this.kdBackendApiService.buildPath(`/custom/url/for/setSettings/${widgetId}`);
    return this.kdBackendApiService.post<DataSettings>(`/custom/url/for/setSettings`, entity);
  }
}
