import { TreeNode } from 'primeng/api';

export type OperationTree = OperationTreeItem[];

export interface OperationTreeItem extends Omit<TreeNode<{ id: string }>, 'children'> {
  label: string;
  type: OperationTreeType;
  description?: string;
  children?: OperationTreeItem[];
}

export enum OperationTreeType {
  OPERATION = 'operation',
  CATEGORY = 'category',
}
