import { UiChartDataLabelDisplayType, UiChartGraphSettings } from '@selfai-platform/bi-domain';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { WidgetNode } from '../../models';

interface LabelParams extends CallbackDataParams {
  data: WidgetNode;
}

export function formatGraphChartLabel(params: LabelParams, uiSettings: UiChartGraphSettings): string {
  if (!uiSettings.dataLabel.showValue || uiSettings.dataLabel.displayTypes.length === 0) {
    return '';
  }

  const { data } = params;

  const displayTypes = uiSettings.dataLabel.displayTypes.filter(Boolean).map((type) => {
    switch (type) {
      case UiChartDataLabelDisplayType.NODE_NAME:
        return data.originalName;
      case UiChartDataLabelDisplayType.LINK_VALUE:
        return data.value;
      default:
        return '';
    }
  });

  return displayTypes.join(`\n`);
}
