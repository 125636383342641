import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { KE_NAMESPACES_ENTITY_NAME } from '@selfai-platform/store';
import { map, Observable } from 'rxjs';
import { Namespace } from '../models';
import { UserFunctionsApiService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class NamespacesDataService extends DefaultDataService<Namespace> {
  constructor(
    public override http: HttpClient,
    public override httpUrlGenerator: HttpUrlGenerator,
    private readonly userFunctionsApiService: UserFunctionsApiService,
  ) {
    super(KE_NAMESPACES_ENTITY_NAME, http, httpUrlGenerator);
  }

  override getAll(): Observable<Namespace[]> {
    return this.userFunctionsApiService
      .loadNamespacesList()
      .pipe(map((items) => items.map((item) => ({ ...item, name: item.name }))));
  }
}
