import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { ShellConfig, SHELL_CONFIG } from '../../shell-config';
import { getStartPageWidgetsConfigs } from './home-page-base-widget/deafult-widgets';
import { HomePageWidgetConfig } from './home-page-base-widget/home-page-base-widget-config';
import { BreadcrumbsMenuItem, BreadcrumbsMenuService } from '../../breadcrumbs-menu';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'selfai-platform-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomePageComponent implements OnInit {
  widgetConfigs: HomePageWidgetConfig[];

  private readonly breadcrumbItems: BreadcrumbsMenuItem[] = [
    {
      label: this.translate.instant('shell.menu.multiverse'),
      routerLink: ['/'],
    },
  ];

  constructor(
    @Inject(SHELL_CONFIG) shellConfig: ShellConfig,
    private readonly breadcrumbsMenuService: BreadcrumbsMenuService,
    private readonly translate: TranslateService,
  ) {
    const widgetNames = shellConfig.startPage.widgets;
    this.widgetConfigs = getStartPageWidgetsConfigs(widgetNames);
  }

  ngOnInit(): void {
    this.breadcrumbsMenuService.setBreadcrumbsMenu(this.breadcrumbItems);
  }
}
