import { Directive, ElementRef, EventEmitter, Injector, Input, OnDestroy, OnInit, Output } from '@angular/core';

import * as _ from 'lodash';

import { ChartType, UIOption } from '@selfai-platform/bi-domain';

import { AbstractComponent } from '../../common/component/abstract.component';

const possibleChartObj: any = {
  color: [
    'bar',
    'grid',
    'line',
    'scatter',
    'control',
    'pie',
    'wordcloud',
    'boxplot',
    'radar',
    'heatmap',
    'combine',
    'treemap',
    'gauge',
    'graph',
    'graphv2',
    'sankey',
    'label'
  ],
  colorD: ['bar', 'line', 'scatter', 'pie', 'wordcloud', 'gauge', 'graph', 'graphv2', 'sankey', 'treemap'],
  colorS: ['bar', 'line', 'control', 'radar', 'combine', 'label'],
  colorV: [
    'bar',
    'grid',
    'line',
    'grid',
    'control',
    'scatter',
    'heatmap',
    'pie',
    'wordcloud',
    'treemap',
    'boxplot',
    'gauge',
  ],
  colorN: ['grid'],
  axis: ['bar', 'line', 'control', 'scatter', 'heatmap', 'boxplot', 'waterfall', 'combine', 'gauge'],
  logScale: ['bar', 'line', 'scatter', 'combine', 'control'],
  valueLabel: [
    'bar',
    'line',
    'control',
    'scatter',
    'heatmap',
    'waterfall',
    'combine',
    'pie',
    'gauge',
    'radar',
    'graph',
    'graphv2',
    'sankey',
    'treemap',
  ],
  labelCategoryName: ['bar', 'line', 'heatmap', 'control', 'waterfall', 'combine', 'radar', 'sankey', 'gauge'],
  labelCategoryValue: ['bar', 'line', 'control', 'waterfall', 'combine'],
  labelCategoryPercent: ['bar', 'line', 'control', 'combine'],
  labelSeriesName: ['bar', 'line', 'scatter', 'heatmap', 'pie', 'control', 'combine', 'treemap', 'gauge'],
  labelSeriesValue: ['bar', 'line', 'heatmap', 'pie', 'control', 'combine', 'treemap', 'gauge'],
  labelSeriesPercent: ['bar', 'line', 'pie', 'control', 'combine', 'treemap', 'gauge'],
  labelXAxisValue: ['scatter'],
  labelYAxisValue: ['scatter'],
  labelValue: ['radar'],
  labelNodeName: ['graph', 'graphv2', 'sankey'],
  labelNodeValue: ['sankey'],
  labelLinkValue: ['graph', 'graphv2'],
  labelPosition: ['bar', 'line', 'scatter', 'control', 'waterfall', 'radar', 'graph', 'graphv2', 'gauge'],
  labelRotation: ['bar', 'combine', 'gauge'],
  labelColor: [
    'bar',
    'line',
    'scatter',
    'control',
    'waterfall',
    'combine',
    'radar',
    'graph',
    'graphv2',
    'sankey',
    'gauge',
  ],
  labelAlign: [
    'bar',
    'line',
    'scatter',
    'heatmap',
    'pie',
    'control',
    'waterfall',
    'combine',
    'radar',
    'graph',
    'graphv2',
    'gauge',
  ],
  labelDirection: ['gauge'],
  labelAxisScaleFormat: ['bar', 'line', 'scatter', 'control', 'waterfall', 'combine', 'gauge'],
  labelUseOutSideLabel: ['pie'],
  tooltipAxisScaleFormat: ['bar', 'line', 'scatter', 'control', 'boxplot', 'waterfall', 'combine', 'gauge'],
  tooltipCategoryName: [
    'bar',
    'line',
    'heatmap',
    'control',
    'waterfall',
    'combine',
    'gauge',
    'radar',
    'boxplot',
    'sankey',
  ],
  tooltipCategoryValue: ['bar', 'line', 'control', 'waterfall', 'combine'],
  tooltipCategoryPercent: ['bar', 'line', 'control', 'combine'],
  tooltipSeriesName: ['bar', 'line', 'scatter', 'heatmap', 'pie', 'control', 'combine', 'treemap', 'gauge'],
  tooltipSeriesValue: ['bar', 'line', 'heatmap', 'pie', 'control', 'combine', 'treemap', 'gauge'],
  tooltipSeriesPercent: ['bar', 'line', 'pie', 'control', 'combine', 'treemap', 'gauge'],
  tooltipXAxisValue: ['scatter'],
  tooltipYAxisValue: ['scatter'],
  tooltipValue: ['radar'],
  tooltipNodeName: ['graph', 'graphv2', 'sankey'],
  tooltipNodeValue: ['graph', 'graphv2', 'sankey'],
  tooltipLinkValue: ['graph', 'graphv2'],
  tooltipHighValue: ['boxplot'],
  tooltip3QValue: ['boxplot'],
  tooltipMedianValue: ['boxplot'],
  tooltip1QValue: ['boxplot'],
  tooltipLowValue: ['boxplot'],
  legend: ['bar', 'line', 'control', 'scatter', 'heatmap', 'pie', 'radar'],
  minimap: ['bar', 'line', 'control', 'scatter', 'heatmap', 'boxplot', 'waterfall'],
  barExpress: ['bar', 'waterfall'],
  lineExpress: ['line', 'control'],
  scatterExpress: ['scatter'],
  gridExpress: ['grid'],
  pieExpress: ['pie'],
  combineExpress: ['combine'],
  radarExpress: ['radar'],
  brush: ['bar'],
  kpiExpress: ['label'],

  yAxisTitle: ['bar', 'line', 'control', 'scatter', 'heatmap', 'boxplot', 'waterfall', 'combine'],

  yAxisGrid: ['bar', 'line', 'scatter', 'combine'],

  yAxisBaseline: ['bar', 'line', 'scatter', 'combine'],

  yAxisMinMax: ['bar', 'line', 'scatter', 'combine'],
  yAxisLog: ['bar'],
};

@Directive()
export class BaseOptionComponent extends AbstractComponent implements OnInit, OnDestroy {
  @Input()
  public uiOption: UIOption;

  @Output()
  public uiOptionChange: EventEmitter<UIOption> = new EventEmitter();

  @Output('drawChartParam')
  public setDrawChartParam: EventEmitter<any> = new EventEmitter();

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public update(drawChartParam?: any) {
    if (drawChartParam) {
      this.setDrawChartParam.emit(drawChartParam);
    } else {
      this.uiOptionChange.emit(this.uiOption);
      if (this.uiOption.type === ChartType.GRID) {
      }
    }
  }

  public possibleChartCheck(type: string, chartType: ChartType) {
    if (!chartType) return false;
    return _.indexOf(possibleChartObj[type], chartType) > -1;
  }

  protected capitalize(string): string {
    return _.capitalize(string);
  }

  protected toggleSelectBox(event): void {
    const $this = $(event.currentTarget).find($('ul[data-type="selectbox"]'));
    const $selectBoxes = $('ul[data-type="selectbox"]');
    const isShow = $this.is(':visible');
    if (isShow) {
      $this.hide();
      $('html').off('click');
    } else {
      $('html')
        .off('click')
        .on('click', () => {
          $selectBoxes.hide();
          $('html').off('click');
        });
      $selectBoxes.hide();
      $this.show();
      event.stopPropagation();
    }
  }
}
