import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { BreadcrumbsMenuItem } from './breadcrumbs-menu.model';

export const BREADCRUMBS_MENU_ENTITY_NAME = 'BreadcrumbMenu';

@Injectable({ providedIn: 'root' })
export class BreadcrumbsMenuStore extends EntityCollectionServiceBase<{
  id: 'breadcrumb';
  breadcrumbMenuItems: BreadcrumbsMenuItem[];
}> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super(BREADCRUMBS_MENU_ENTITY_NAME, serviceElementsFactory);
  }
}
