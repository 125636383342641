import { DashboardPageRelation } from '@selfai-platform/bi-domain';

export function findParentWidgetId(widgetId: string, relations: DashboardPageRelation[]): string {
  let parentId = '';

  relations.some((item) => {
    if (item.children) {
      if (-1 < item.children.findIndex((child) => child.ref === widgetId)) {
        parentId = item.ref;
        return true;
      } else {
        parentId = findParentWidgetId(widgetId, item.children);
        return '' !== parentId;
      }
    } else {
      return false;
    }
  });

  return parentId;
}
