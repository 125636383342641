import { Component } from '@angular/core';

import {
  AxisType,
  BaseOption,
  CHART_STRING_DELIMITER,
  ChartType,
  LineType,
  Pivot,
  Position,
  SeriesType,
  ShelveFieldType,
  ShelveType,
  UIChartFormat,
  UiChartDataLabelDisplayType,
  createPivotTableInfo,
} from '@selfai-platform/bi-domain';
import { BoxplotSeriesOption, ScatterSeriesOption } from 'echarts';
import { prepareBoxplotData } from 'echarts/extension/dataTool';
import * as _ from 'lodash';
import { AxisOptionConverter, FormatOptionConverter } from '../../converters';
import { provideBaseChartServices } from '../../services';
import { OptionGenerator } from '../../utils';
import { BaseChart } from '../base-chart';
import optGen = OptionGenerator;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'boxplot-chart',
  template: '',
  styleUrls: ['./chart-host.component.scss'],
  providers: [...provideBaseChartServices()],
})
export class BoxPlotChartComponent extends BaseChart {
  override isValid(shelve: Pivot): boolean {
    return (
      this.getFieldTypeCount(shelve, ShelveType.ROWS, ShelveFieldType.DIMENSION) +
        this.getFieldTypeCount(shelve, ShelveType.ROWS, ShelveFieldType.TIMESTAMP) ==
        1 &&
      this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.MEASURE) +
        this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.CALCULATED) ==
        1 &&
      this.getFieldTypeCount(shelve, ShelveType.COLUMNS, ShelveFieldType.DIMENSION) +
        this.getFieldTypeCount(shelve, ShelveType.COLUMNS, ShelveFieldType.TIMESTAMP) >
        0 &&
      this.getFieldTypeCount(shelve, ShelveType.COLUMNS, ShelveFieldType.MEASURE) == 0 &&
      this.getFieldTypeCount(shelve, ShelveType.COLUMNS, ShelveFieldType.CALCULATED) == 0 &&
      this.getFieldTypeCount(shelve, ShelveType.ROWS, ShelveFieldType.MEASURE) == 0 &&
      this.getFieldTypeCount(shelve, ShelveType.ROWS, ShelveFieldType.CALCULATED) == 0 &&
      this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.DIMENSION) == 0 &&
      this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.TIMESTAMP) == 0
    );
  }

  override draw(isKeepRange?: boolean): void {
    const cols: string[] = [];

    this.data.columns.map((column) => {
      cols.push(column.name);

      return column.value;
    });

    this.pivotInfo = createPivotTableInfo(cols, [], this.fieldInfo.aggs);

    super.draw(isKeepRange);
  }

  protected override selectSeriesData(seriesData) {
    (this.chartOption.series as BoxplotSeriesOption[]).forEach((seriesItem) => {
      seriesItem.data.forEach((dataItem: any) => {
        if (dataItem.name === seriesData.name) {
          dataItem.itemStyle.opacity = 1;
          dataItem.selected = true;

          return true;
        }

        return false;
      });
    });
  }

  protected override unselectSeriesData(seriesData) {
    (this.chartOption.series as BoxplotSeriesOption[]).forEach((seriesItem) => {
      // TODO: Need use BoxplotDataItemOption instead of any
      seriesItem.data.forEach((dataItem: any) => {
        if (Object.prototype.hasOwnProperty.call(dataItem, 'name') && dataItem.name === seriesData.name) {
          dataItem.itemStyle.opacity = 0.2;
          dataItem.selected = false;

          return true;
        }

        return false;
      });
    });
  }

  protected override initOption(): BaseOption {
    return {
      type: ChartType.BOXPLOT,
      grid: [OptionGenerator.Grid.verticalMode(10, 0, 0, 10, false, true, false)],
      xAxis: [OptionGenerator.Axis.categoryAxis(Position.INSIDE, null, false, true, true, true)],
      yAxis: [OptionGenerator.Axis.valueAxis(Position.INSIDE, null, false, false, true, true, true)],
      dataZoom: [OptionGenerator.DataZoom.horizontalDataZoom(), OptionGenerator.DataZoom.horizontalInsideDataZoom()],
      tooltip: OptionGenerator.Tooltip.itemTooltip(),
      toolbox: OptionGenerator.Toolbox.hiddenToolbox(),
      series: [],
    };
  }

  protected override convertSeriesData(): BaseOption {
    const columns = this.data.columns;

    let boxPlotData = columns.map((column) => {
      return column.value;
    });
    boxPlotData = prepareBoxplotData(boxPlotData);

    const boxItem: BoxplotSeriesOption = {
      type: SeriesType.BOXPLOT,
      name: this.fieldInfo.aggs[0],
      data: boxPlotData.boxData.map((val, idx) => {
        return {
          name: columns[idx].name,
          value: val,
          selected: false,
          itemStyle: OptionGenerator.ItemStyle.opacity1(),
        };
      }),
      itemStyle: { borderWidth: 1, borderType: LineType.SOLID },
      emphasis: {
        itemStyle: {
          borderWidth: 1,
          borderType: LineType.SOLID,
        },
      },
      tooltip: {
        formatter: (params) => {
          return this.tooltipFormatter(params);
        },
      },
    };

    const outlierItem: ScatterSeriesOption = {
      type: SeriesType.SCATTER,
      symbolSize: 8,
      itemStyle: optGen.ItemStyle.auto(),
      data: boxPlotData.outliers.map((val) => {
        return {
          name: columns[val[0]].name,
          value: val,
          selected: false,
          itemStyle: OptionGenerator.ItemStyle.opacity1(),
        };
      }),
      tooltip: {
        formatter: (param) => {
          return FormatOptionConverter.getFormatValue(
            param.value[1],
            this.uiOption.valueFormat.isAll ? this.uiOption.valueFormat : this.uiOption.valueFormat.each[0],
          );
        },
      },
    };

    this.chartOption.series = [boxItem, outlierItem];

    return this.chartOption;
  }

  protected override additionalSeries(): BaseOption {
    this.chartOption.series[1].itemStyle.color = '#ca4819';

    return this.chartOption;
  }

  protected override selection(): void {
    this.addChartSelectEventListener();
  }

  protected override additionalXAxis(): BaseOption {
    this.chartOption.xAxis[0].data = this.data.columns.map((column) => {
      return column.name;
    });

    this.chartOption = AxisOptionConverter.convertAxisLabelMaxLength(this.chartOption, this.uiOption, AxisType.X);

    return this.chartOption;
  }

  protected override setSelectData(params: any, colValues: string[], rowValues: string[]): any {
    const returnDataList: any = [];

    let targetValues: string[] = [];
    _.forEach(this.pivot, (value, key) => {
      let deepCopyShelve = _.cloneDeep(this.pivot[key]);

      deepCopyShelve = _.filter(deepCopyShelve, (obj) => {
        if (_.eq(obj.type, ShelveFieldType.DIMENSION) || _.eq(obj.type, ShelveFieldType.TIMESTAMP)) {
          return obj;
        }
      });

      deepCopyShelve.map((obj, idx) => {
        if (!_.isNull(params)) {
          if (_.eq(key, ShelveType.ROWS)) return;
          targetValues = colValues;
        }

        if (!_.isEmpty(targetValues) && targetValues[idx]) {
          if (-1 === _.findIndex(returnDataList, { name: obj.name })) {
            returnDataList.push(obj);
          }
          returnDataList[returnDataList.length - 1].data = [targetValues[idx]];
        }
      });
    });

    return returnDataList;
  }

  private tooltipFormatter(params): any {
    let format: UIChartFormat = this.uiOption.valueFormat;

    const axisFormat = FormatOptionConverter.getlabelAxisScaleFormatTooltip(this.uiOption);
    if (axisFormat) format = axisFormat;

    let result: string[] = [];

    if (!this.uiOption.toolTip || !this.uiOption.toolTip.displayTypes) {
      const nameList = _.split(params.name, CHART_STRING_DELIMITER);
      result = FormatOptionConverter.getTooltipName(nameList, this.pivot.columns, result, true);

      result.push('High: ' + FormatOptionConverter.getFormatValue(params.data.value[5], format));
      result.push('3Q: ' + FormatOptionConverter.getFormatValue(params.data.value[4], format));
      result.push('Median: ' + FormatOptionConverter.getFormatValue(params.data.value[3], format));
      result.push('1Q: ' + FormatOptionConverter.getFormatValue(params.data.value[2], format));
      result.push('Low: ' + FormatOptionConverter.getFormatValue(params.data.value[1], format));
    } else {
      if (-1 !== this.uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.CATEGORY_NAME)) {
        const nameList = _.split(params.name, CHART_STRING_DELIMITER);
        result = FormatOptionConverter.getTooltipName(nameList, this.pivot.columns, result, true);
      }
      if (-1 !== this.uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.HIGH_VALUE)) {
        result.push('High: ' + FormatOptionConverter.getFormatValue(params.data.value[5], format));
      }
      if (-1 !== this.uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.THREE_Q_VALUE)) {
        result.push('3Q: ' + FormatOptionConverter.getFormatValue(params.data.value[4], format));
      }
      if (-1 !== this.uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.MEDIAN_VALUE)) {
        result.push('Median: ' + FormatOptionConverter.getFormatValue(params.data.value[3], format));
      }
      if (-1 !== this.uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.FIRST_Q_VALUE)) {
        result.push('1Q: ' + FormatOptionConverter.getFormatValue(params.data.value[2], format));
      }
      if (-1 !== this.uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.LOW_VALUE)) {
        result.push('Low: ' + FormatOptionConverter.getFormatValue(params.data.value[1], format));
      }
    }

    return result.join('<br/>');
  }
}
