export enum MetadataRole {
  MEASURE = 'MEASURE',
  DIMENSION = 'DIMENSION',
  TIMESTAMP = 'TIMESTAMP',
  ALL = 'ALL',
}

export enum WKT {
  POINT = 'POINT',
  LINESTRING = 'LINESTRING',
  POLYGON = 'POLYGON',
}

export enum Logical {
  STRING = 'STRING',
  BOOLEAN = 'BOOLEAN',
  NUMBER = 'NUMBER',
  INTEGER = 'INTEGER',
  DOUBLE = 'DOUBLE',
  TIMESTAMP = 'TIMESTAMP',
  LNG = 'LNG',
  LNT = 'LNT',
  GEO_POINT = 'GEO_POINT',
  GEO_LINE = 'GEO_LINE',
  GEO_POLYGON = 'GEO_POLYGON',
  ARRAY = 'ARRAY',
  STRUCT = 'STRUCT',
  MAP_KEY = 'MAP_KEY',
  MAP_VALUE = 'MAP_VALUE',
  IP_V4 = 'IP_V4',
  DISTRICT = 'DISTRICT',
  EMAIL = 'EMAIL',
  SEX = 'SEX',
  CREDIT_CARD = 'CREDIT_CARD',
  NIN = 'NIN',
  POSTAL_CODE = 'POSTAL_CODE',
  PHONE_NUMBER = 'PHONE_NUMBER',
  URL = 'URL',
  HTTP_CODE = 'HTTP_CODE',
  ALL = 'ALL',
  USER_DEFINED = 'user_defined',
  LONG = 'LONG',
}

export enum MetadataSource {
  ENGINE = 'ENGINE',
  JDBC = 'JDBC',
  STAGEDB = 'STAGEDB',

  ALL = 'ALL',
}
