import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Filter, GetDashboardResult, SearchQueryRequest, Widget } from '@selfai-platform/bi-domain';

import { CommonConstant } from '../../common/constant/common.constant';
import { AbstractService } from '../../common/service/abstract.service';
import { CreateWidgetResult, GetWidgetResult, UpdateWidgetOptions, UpdateWidgetResult } from '../dto';

@Injectable()
export class WidgetService extends AbstractService {
  async loadPropMapView() {
    const PROP_MAP_CONFIG = CommonConstant.PROP_MAP_CONFIG;
    const propMapConfig = sessionStorage.getItem(PROP_MAP_CONFIG);
    const mapViewApi = this.API_URL + `widgets/properties/mapview`;

    if (propMapConfig !== undefined && propMapConfig !== null) {
      return;
    }

    const widgetMapSettings = await this.get<any>(mapViewApi);
    sessionStorage.setItem(PROP_MAP_CONFIG, JSON.stringify(widgetMapSettings));
  }

  async getWidget(widgetId: string): Promise<GetWidgetResult & { dashBoard: GetDashboardResult }> {
    const widgetApi = this.API_URL + `widgets/${widgetId}?projection=forDetailView`;
    const widgetResponse = this.get<GetWidgetResult>(widgetApi);

    const boardApi = this.API_URL + `widgets/${widgetId}/dashBoard?projection=forDetailView`;
    const boardResponse = this.get<GetDashboardResult>(boardApi);

    return Promise.all([widgetResponse, boardResponse]).then(([widget, dashBoard]) => ({ ...widget, dashBoard }));
  }

  createWidget(widget: Widget | GetWidgetResult, dashboardId: string): Promise<CreateWidgetResult> {
    const url = this.API_URL + 'widgets';
    const param = {
      name: widget.name,
      pivot: widget.pivot,
      type: widget.type,
      configuration: widget.configuration,
      dashBoard: '/api/dashboards/' + dashboardId,
    };

    return this.post(url, param);
  }

  updateWidget(widgetId: string, options: UpdateWidgetOptions): Promise<UpdateWidgetResult> {
    return this.patch(this.API_URL + 'widgets/' + widgetId, options);
  }

  deleteWidget(widgetId: string): Promise<void> {
    return this.delete(this.API_URL + 'widgets/' + widgetId);
  }

  previewConfig(query: SearchQueryRequest, original: boolean, preview: boolean): Promise<any> {
    const config = {
      type: 'page',
      dataSource: query.dataSource,
      fields: query.userFields,
      filters: query.filters,
      pivot: query.pivot,
      limit: query.limits,
    };

    const url = this.API_URL + `widgets/config/data?original=${original}&preview=${preview}`;
    return this.post(url, config);
  }

  downloadConfig(
    query: SearchQueryRequest,
    original: boolean,
    maxRowsPerSheet: number,
    fileType?: string,
  ): Observable<Blob> {
    const config = {
      type: 'page',
      dataSource: query.dataSource,
      fields: query.userFields,
      filters: query.filters,
      pivot: query.pivot,
      limit: query.limits,
    };

    const strType: string = 'CSV' === fileType ? 'application/csv' : 'application/vnd.ms-excel';

    const url: string =
      this.API_URL + 'widgets/config/download?original=' + original + '&maxRowsPerSheet=' + maxRowsPerSheet;

    return this.http.post<BlobPart>(url, config).pipe(
      map((res) => {
        return new Blob([res], { type: strType });
      }),
    );
  }

  previewWidget(widgetId: string, original: boolean, preview: boolean, param: any = null): Promise<any> {
    const url = this.API_URL + `widgets/${widgetId}/data?original=${original}&preview=${preview}`;
    return this.post(url, param);
  }

  downloadWidget(
    widgetId: string,
    original: boolean,
    maxRowsPerSheet?: number,
    fileType?: string,
    param: Filter[] | null = null,
  ): Observable<Blob> {
    let url: string = this.API_URL + 'widgets/' + widgetId + '/download?original=' + original;

    if (maxRowsPerSheet) {
      url = url + '&maxRowsPerSheet=' + maxRowsPerSheet;
      url = url + '&limit=' + maxRowsPerSheet;
    }

    const strType: string = fileType === 'CSV' ? 'application/csv' : 'application/vnd.ms-excel';

    const headers = new HttpHeaders().set('Accept', strType);

    return this.http.post<BlobPart>(url, param, { headers, responseType: 'blob' as 'json' }).pipe(
      map((res) => {
        return new Blob([res], { type: strType });
      }),
    );
  }
}
