<div class="ddp-ui-slider-type type-per ddp-slider-bg">
  <div class="ddp-range-num">
    <div class="ddp-data-num ddp-min">
      <span class="ddp-label">{{ 'msg.minimum' | translate }}</span>
      <component-input
        [compType]="'apply'"
        [value]="filter.min"
        [valueType]="'number'"
        (changeValue)="setMinValue($event)"
      ></component-input>
    </div>
    <div class="ddp-data-num ddp-max">
      <span class="ddp-label">{{ 'msg.maximum' | translate }}</span>
      <component-input
        [compType]="'apply'"
        [value]="filter.max"
        [valueType]="'number'"
        (changeValue)="setMaxValue($event)"
      ></component-input>
    </div>
  </div>

  <div *ngIf="formControlRange" class="slider">
    <p-slider
      [formControl]="formControlRange"
      [range]="true"
      [max]="inputFilter.maxValue"
      [min]="inputFilter.minValue"
    ></p-slider>
  </div>

  <div class="ddp-data-range">
    <span class="ddp-label-range">{{ 'msg.board.th.avail.input.range' | translate }}</span>
    <span class="ddp-view-range">
      <span>{{ filter.minValue }} ~</span>
      <span>{{ filter.maxValue }}</span>
    </span>
  </div>
</div>
