export const DatasourceType = {
  JDBC: 'jdbc',
  EX_FILE: 'externalFile',
  GOOGLE_SP: 'googleSpreadsheet',
  HDFS: 'hdfs',
  KAFKA: 'kafka',
  LIBRARY: 'libraryFile',
  S3: 's3',
} as const;

export type DatasourceType = typeof DatasourceType[keyof typeof DatasourceType];
