import { DatasourceField } from '../../datasource';
import { MarkLineSetting } from '../models';

const MarkLineType = {
  AVARAGE: 'average',
  PERCENTILE: 'percentile',
} as const;

export type MarkLineType = typeof MarkLineType[keyof typeof MarkLineType];

const MarkLineStyle = {
  SOLID: 'solid',
  DASHED: 'dashed',
  DOTTED: 'dotted',
} as const;

export type MarkLineStyle = typeof MarkLineStyle[keyof typeof MarkLineStyle];

const LabelRotate = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
} as const;

export type LabelRotate = typeof LabelRotate[keyof typeof LabelRotate];

export function getDefaultMarkLineSetting(measureList: DatasourceField[]): MarkLineSetting {
  return {
    measureName: measureList[0].name,
    markLineType: MarkLineType.AVARAGE,
    lineStyle: MarkLineStyle.SOLID,
    lineColor: '#ff0000',
    labelRotate: null,
  };
}
