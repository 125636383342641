import { Component, OnInit } from '@angular/core';
import { MenuItemsService, MenuService } from '@selfai-platform/shell';

@Component({
  selector: 'selfai-platform-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  readonly logoPath = 'assets/images/logo_multiverse.png';

  readonly menuIsOpened$ = this.menuService.isOpened$;
  readonly menuMode$ = this.menuService.menuMode$;
  readonly menu$ = this.menuItemService.menu$;

  constructor(private readonly menuService: MenuService, private readonly menuItemService: MenuItemsService) {}
}
