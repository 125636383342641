import { InjectionToken } from '@angular/core';
import { ImplementorType } from '../enums';

export type ImplementorListTypes = ImplementorType;

export const IMPLEMENTOR_TYPE_LIST = new InjectionToken<ImplementorListTypes[]>('ImplementorTypeList', {
  providedIn: 'root',
  factory: () => [
    ImplementorType.POSTGRESQL,
    ImplementorType.MYSQL,
    ImplementorType.ORACLE,
    ImplementorType.MSSQL,
    ImplementorType.HIVE,
    ImplementorType.HAWQ,
    ImplementorType.PRESTO,
    ImplementorType.PHOENIX,
    ImplementorType.NVACCEL,
    ImplementorType.STAGE,
    ImplementorType.DRUID,
    ImplementorType.FILE,
    ImplementorType.NONE,
    ImplementorType.ALL,
  ],
});
