import {
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  KeyValueDiffers,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import * as _ from 'lodash';
import { cloneDeep } from 'lodash/fp';

import {
  BoardConfiguration,
  BoardDataSource,
  CustomField,
  Dashboard,
  Datasource,
  DatasourceField,
  DatasourceField as Field,
  FieldRole,
  Filter,
  LogicalType,
  Widget,
} from '@selfai-platform/bi-domain';

import { AbstractComponent } from '../../../common/component/abstract.component';
import { EventBroadcaster } from '../../../common/event/event.broadcaster';
import { StringUtil } from '../../../common/util/string.util';
import { PageDataContextComponent } from '../../../page/page-data/page-data-context.component';
import { getMainDataSources } from '../../util';
import { DashboardUtil } from '../../util/dashboard.util';
import { FilterUtil } from '../../util/filter.util';

@Component({
  selector: 'datasource-panel',
  templateUrl: './datasource-panel.component.html',
  styleUrls: ['./datasource-panel.component.scss'],
})
export class DatasourcePanelComponent extends AbstractComponent implements OnInit, OnDestroy, DoCheck {
  private DIM_PAGE_SIZE = 10;
  private MEA_PAGE_SIZE = 7;

  @ViewChild(PageDataContextComponent, { static: true })
  private _dataContext: PageDataContextComponent;

  private _totalFields: (Field | CustomField)[] = [];

  private _differ: any;

  public boardDs: BoardDataSource;
  public dsFields: Field[] = [];
  public dsCustomFields: CustomField[] = [];

  public dimensionFields: (Field | CustomField)[] = [];
  public measureFields: (Field | CustomField)[] = [];

  public displayDimensions: (Field | CustomField)[] = [];
  public displayMeasures: (Field | CustomField)[] = [];

  public dimPage = 1;
  public dimTotalPage = 1;
  public meaPage = 1;
  public meaTotalPage = 1;

  public searchText = '';

  public isShowDataPreview = false;
  public isColumnDetail = false;
  public selectedField: Field | CustomField;

  public customFieldPopupType = 'dimension';
  public selectedCustomField: CustomField;
  public isShowCustomFiled = false;

  public dataSourceList: Datasource[] = [];
  public dataSource: Datasource;

  public isSelectedDimension = true;
  public isSelectedMeasure = true;

  public unescapeCustomColumnExpr = StringUtil.unescapeCustomColumnExpr;

  @Input()
  public dashboard: Dashboard;

  @Input()
  public chartFilters: Filter[] = [];

  @Output() public onFilterClick = new EventEmitter();

  @Output() public onUpdateFilter = new EventEmitter();

  @Output() public onDeleteFilter = new EventEmitter();

  @Output() public onUpdateCustomField = new EventEmitter();

  @Output() public onDeleteCustomField = new EventEmitter();

  @Output('changeFieldAlias')
  public changeFieldAliasEvent: EventEmitter<Field> = new EventEmitter();

  public widgets: Widget[] = [];
  public globalFilters: Filter[] = [];
  public showDatasourcePanel = false;

  constructor(
    private differs: KeyValueDiffers,
    protected broadCaster: EventBroadcaster,
    protected elementRef: ElementRef,
    protected injector: Injector,
  ) {
    super(elementRef, injector);
    this._differ = differs.find({}).create();
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngDoCheck() {
    if (this._differ.diff(this.dashboard)) {
      this.widgets = DashboardUtil.getPageWidgets(this.dashboard);
      this.globalFilters = DashboardUtil.getBoardFilters(this.dashboard);
      this.dataSourceList = getMainDataSources(this.dashboard);

      this.selectDataSource(this.dataSourceList[0]);
    }
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public selectDataSource(dataSource: Datasource) {
    if (dataSource && dataSource.valid) {
      this.dataSource = dataSource;
      const boardConf: BoardConfiguration = this.dashboard.configuration;

      this.boardDs = DashboardUtil.findDataSourceOnBoard(this.dashboard, dataSource);

      this.dsFields = _.cloneDeep(DashboardUtil.getFieldsForMainDataSource(boardConf, dataSource.engineName));
      this._totalFields = this.dsFields;
      if (boardConf.customFields) {
        this.dsCustomFields = boardConf.customFields.filter((filter) => filter.dataSource === this.boardDs.engineName);

        this._totalFields = this._totalFields.concat(this.dsCustomFields);
      }

      this._setFields();
    }
  }

  public toggleDatasourcePanel() {
    this.showDatasourcePanel = !this.showDatasourcePanel;
    this.changeDetect.detectChanges();
    if (this.showDatasourcePanel) {
      this._setFields();
    }
  }

  public onDataPreviewPopup(): void {
    this.selectedField = null;
    this.isColumnDetail = false;
    this.isShowDataPreview = true;
  }

  public searchField(inputText: string) {
    this.searchText = inputText;
    this._setFields(this.searchText);
  }

  public prevDimPage(): void {
    if (this.dimPage <= 1) return;

    this.dimPage--;

    const start: number = (this.dimPage - 1) * this.DIM_PAGE_SIZE;
    const end: number = this.dimPage * this.DIM_PAGE_SIZE;
    this.displayDimensions = this.dimensionFields.slice(start, end);
  }

  public nextDimPage(): void {
    if (this.dimPage >= this.dimTotalPage) return;

    this.dimPage++;

    const start: number = (this.dimPage - 1) * this.DIM_PAGE_SIZE;
    const end: number = this.dimPage * this.DIM_PAGE_SIZE;
    this.displayDimensions = this.dimensionFields.slice(start, end);
  }

  public prevMeaPage(): void {
    if (this.meaPage <= 1) return;

    this.meaPage--;

    const start: number = (this.meaPage - 1) * this.MEA_PAGE_SIZE;
    const end: number = this.meaPage * this.MEA_PAGE_SIZE;
    this.displayMeasures = this.measureFields.slice(start, end);
  }

  public nextMeaPage(): void {
    if (this.meaPage >= this.meaTotalPage) return;

    this.meaPage++;

    const start: number = (this.meaPage - 1) * this.MEA_PAGE_SIZE;
    const end: number = this.meaPage * this.MEA_PAGE_SIZE;
    this.displayMeasures = this.measureFields.slice(start, end);
  }

  public openFieldDetailLayer(event: MouseEvent, field: Field) {
    event.stopPropagation();

    this.selectedField = field;

    this._dataContext.init(field, this.dashboard.configuration.dataSource, $(event.currentTarget), true);
  }

  public toggleFilter(event: MouseEvent, field: Field) {
    if (this.isCustomMeasureField(field)) {
      return;
    }

    this.onFilterClick.emit();
    event.stopPropagation();

    if (field.aggregated) {
      this.alertPrimeService.info(this.translateService.instant('msg.page.custom.measure.aggregation.unavailable'));
      return;
    }

    if (field.useFilter) {
      if (field.filtering) {
        this.alertPrimeService.warn(this.translateService.instant('msg.board.alert.recomm-filter.del.error'));
        return;
      }

      field.useFilter = false;

      const idx = _.findIndex(this.globalFilters, { field: field.name });
      if (idx > -1) {
        this.onDeleteFilter.emit(this.globalFilters[idx]);
      }
      return;
    } else {
      field.useFilter = true;

      this.showDatasourcePanel = false;

      if (field.logicalType === LogicalType.TIMESTAMP) {
        this.onUpdateFilter.emit(FilterUtil.getTimeRangeFilter(field, undefined, undefined, this.dataSource));
      } else if (field.role === FieldRole.MEASURE) {
        this.onUpdateFilter.emit(FilterUtil.getBasicBoundFilter(field));
      } else {
        const inclusionFilter = FilterUtil.getBasicInclusionFilter(field);
        if (field.type === 'user_expr') {
          inclusionFilter.ref = 'user_defined';
        }
        this.onUpdateFilter.emit(inclusionFilter);
      }
    }
  }

  getFieldDisplayName(field: DatasourceField): string {
    let fieldName = field.name;
    if (field['nameAlias'] && field['nameAlias']['nameAlias'] !== field.name) {
      fieldName = field['nameAlias']['nameAlias'];
    }
    if (field.join) {
      fieldName = `${fieldName} (${field.join.joinAlias})`;
    }
    return fieldName;
  }

  public openCustomFieldPopup(popupType: string, customField?: CustomField) {
    this.customFieldPopupType = popupType;
    if (customField) {
      this.selectedCustomField = customField;
    } else {
      this.selectedCustomField = null;
    }
    this.isShowCustomFiled = true;
  }

  public openCustomFieldPopupFromContext(customField: CustomField) {
    this.customFieldPopupType = customField.role.toString();
    this.selectedCustomField = customField;
    this.isShowCustomFiled = true;
  }

  public updateCustomField(data: any) {
    this.onUpdateCustomField.emit(data);
    this.isShowCustomFiled = false;
  }

  public deleteCustomField(field: CustomField) {
    this.onDeleteCustomField.emit(field);
    this.isShowCustomFiled = false;
  }

  public isCustomMeasureField(field: Field) {
    return FieldRole.MEASURE === field.role && 'user_expr' === field.type;
  }

  public onColumnDetailPopup(field: Field): void {
    this.selectedField = field;
    this.isColumnDetail = true;
    this.isShowDataPreview = true;
  }

  public changeDatasourceFieldAlias(changeField: Field) {
    this.changeFieldAliasEvent.emit(changeField);
  }

  private _setFields(searchText?: string) {
    if (this.showDatasourcePanel) {
      const totalFields = cloneDeep(this._totalFields);

      this._setUseChart(totalFields);
      this._setUseFilter(totalFields);

      if (this.dashboard.configuration.dataSource.associations) {
        const dsList = this.dashboard.dataSources;
        const currentEngineName = this.dataSource.engineName;
        const associations = this.dashboard.configuration.dataSource.associations.filter(
          (item) => item.source === currentEngineName || item.target === currentEngineName,
        );
        if (associations && associations.length > 0) {
          totalFields.forEach((field) => {
            associations.forEach((ass) => {
              const colPairKeys: string[] = Object.keys(ass.columnPair);
              if (currentEngineName === ass.source) {
                const pairKey = colPairKeys.find((item) => field.name === item);
                if (pairKey) {
                  field['assInfo'] || (field['assInfo'] = []);
                  field['assInfo'].push(
                    dsList.find((ds) => ds.engineName === ass.target).name + ' : ' + ass.columnPair[pairKey],
                  );
                }
              } else if (currentEngineName === ass.target) {
                const pairItem = colPairKeys.find((item) => field.name === ass.columnPair[item]);
                if (pairItem) {
                  field['assInfo'] || (field['assInfo'] = []);
                  field['assInfo'].push(dsList.find((ds) => ds.engineName === ass.source).name + ' : ' + pairItem);
                }
              }
            });
          });
        }
      }

      if (this.dashboard.configuration.dataSource.joins && this.dashboard.configuration.dataSource.joins.length > 0) {
        this.dashboard.configuration.dataSource.joins.forEach((join) => {
          totalFields.forEach((field) => {
            if ('dsId' in field && field?.dsId === join.id) {
              field.join = join;
            }
          });
        });
      }

      this.dimensionFields = totalFields.filter((item) => item.role !== FieldRole.MEASURE);
      this.measureFields = totalFields.filter((item) => item.role === FieldRole.MEASURE);

      if (searchText) {
        searchText = searchText.toLowerCase();
        this.dimensionFields = this.dimensionFields.filter((item) => item.name.toLowerCase().includes(searchText));
        this.measureFields = this.measureFields.filter((item) => item.name.toLowerCase().includes(searchText));
      }

      if (this.DIM_PAGE_SIZE < this.dimensionFields.length) {
        this.dimPage = 1;
        this.dimTotalPage = Math.ceil(this.dimensionFields.length / this.DIM_PAGE_SIZE);
        this.displayDimensions = this.dimensionFields.slice(0, this.DIM_PAGE_SIZE);
      } else {
        this.dimPage = 1;
        this.dimTotalPage = 1;
        this.displayDimensions = this.dimensionFields;
      }

      if (this.MEA_PAGE_SIZE < this.measureFields.length) {
        this.meaPage = 1;
        this.meaTotalPage = Math.ceil(this.measureFields.length / this.MEA_PAGE_SIZE);
        this.displayMeasures = this.measureFields.slice(0, this.MEA_PAGE_SIZE);
      } else {
        this.meaPage = 1;
        this.meaTotalPage = 1;
        this.displayMeasures = this.measureFields;
      }
    }
  }

  private _setUseChart(totalFields: (Field | CustomField)[]) {
    totalFields.forEach((field) => (field.useChart = false));

    if (this.widgets && this.widgets.length > 0) {
      this.widgets.forEach((widget: Widget) => {
        if (
          undefined !== widget.configuration['chart']['layerNum'] &&
          widget.type === 'page' &&
          widget.configuration &&
          widget.configuration['shelf']
        ) {
          const pivotConf = widget.configuration['shelf'];
          const layerNum = widget.configuration['chart']['layerNum'];
          if (undefined !== layerNum && pivotConf.layers && 0 < pivotConf.layers[layerNum].length) {
            pivotConf.layers[layerNum].forEach((layer) => {
              const idx: number = totalFields.findIndex((field) => field.name === layer.name);
              if (-1 < idx) {
                totalFields[idx].useChart = true;
              }
            });
          }
        } else if (widget.type === 'page' && widget.configuration && widget.configuration['pivot']) {
          const pivotConf = widget.configuration['pivot'];
          if (pivotConf.columns && 0 < pivotConf.columns.length) {
            pivotConf.columns.forEach((column) => {
              const idx: number = totalFields.findIndex((field) => field.name === column.name);
              if (-1 < idx) {
                totalFields[idx].useChart = true;
              }
            });
          }
          if (pivotConf.rows && 0 < pivotConf.rows.length) {
            pivotConf.rows.forEach((row) => {
              const idx: number = totalFields.findIndex((field) => field.name === row.name);
              if (-1 < idx) {
                totalFields[idx].useChart = true;
              }
            });
          }
          if (pivotConf.aggregations && 0 < pivotConf.aggregations.length) {
            pivotConf.aggregations.forEach((aggregation) => {
              const idx: number = totalFields.findIndex((field) => field.name === aggregation.name);
              if (-1 < idx) {
                totalFields[idx].useChart = true;
              }
            });
          }
        }
      });
    }
  }

  private _setUseFilter(totalFields: (Field | CustomField)[]) {
    totalFields.forEach((field) => {
      field.useChartFilter = false;
      field.useFilter = false;
      field['isCustomMeasure'] = this.isCustomMeasureField(<Field>field);
    });

    totalFields.forEach((field) => {
      let idx = _.findIndex(this.globalFilters, { field: field.name });
      if (idx > -1) field.useFilter = true;

      idx = _.findIndex(this.chartFilters, { field: field.name });
      if (idx > -1) field.useChartFilter = true;
    });
  }
}
