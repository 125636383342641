import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { FormFieldComponentModule } from '../../../../form-field/form-field.module';
import { StepToComponent } from './step-to.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, DropdownModule, FormFieldComponentModule],
  declarations: [StepToComponent],
  exports: [StepToComponent],
})
export class StepToComponentModule {}
