<div class="ddp-list-sub2">
  <div class="ddp-wrap-option-multy ddp-clear">
    <div class="ddp-col-6">
      <span class="ddp-label-txt">{{ 'msg.page.calc.label' | translate }}</span>
    </div>
    <div class="ddp-col-6">
      <component-input
        [inputClass]="'ddp-input-typebasic'"
        [value]="totalValueStyle['label']"
        (changeValue)="totalValueStyle['label'] = $event; changeLabel(totalValueStyle['label'])"
      ></component-input>
    </div>
  </div>

  <div class="ddp-wrap-option-multy ddp-clear">
    <div class="ddp-col-6">
      <span class="ddp-label-txt">{{ 'msg.page.calc.label.operator' | translate }}</span>
    </div>
    <div class="ddp-col-6">
      <component-select
        [array]="operatorList"
        [viewKey]="'name'"
        [defaultIndex]="operatorDefaultIdx"
        (onSelected)="changeOperator($event)"
      ></component-select>
    </div>
  </div>

  <div class="ddp-wrap-option-multy ddp-clear">
    <div class="ddp-col-6">
      <span class="ddp-label-txt">{{ 'msg.page.common.align.horizontal' | translate }}</span>
    </div>
    <div class="ddp-col-6">
      <component-select
        [array]="hAlignList"
        [viewKey]="'name'"
        [defaultIndex]="hAlignDefaultIdx"
        (onSelected)="changeHAlign($event)"
      ></component-select>
    </div>
  </div>

  <div class="ddp-wrap-option-multy ddp-clear">
    <div class="ddp-col-6">
      <span class="ddp-label-txt">{{ 'msg.page.common.align.vertical' | translate }}</span>
    </div>
    <div class="ddp-col-6 ddp-txt-right">
      <ul class="ddp-list-express ddp-tab3 ddp-type">
        <li [ngClass]="{ 'ddp-selected': totalValueStyle?.vAlign === 'TOP' }" (click)="changeValign('TOP')">
          <em class="ddp-icon-top"></em>
        </li>
        <li [ngClass]="{ 'ddp-selected': totalValueStyle?.vAlign === 'MIDDLE' }" (click)="changeValign('MIDDLE')">
          <em class="ddp-icon-middle"></em>
        </li>
        <li [ngClass]="{ 'ddp-selected': totalValueStyle?.vAlign === 'BOTTOM' }" (click)="changeValign('BOTTOM')">
          <em class="ddp-icon-bottom"></em>
        </li>
      </ul>
    </div>
  </div>

  <div class="ddp-wrap-option-multy ddp-clear">
    <div class="ddp-col-6">
      <span class="ddp-label-txt">{{ 'msg.page.common.font.size' | translate }}</span>
    </div>
    <div class="ddp-col-6 ddp-txt-right">
      <ul class="ddp-list-express ddp-tab3 ddp-type">
        <li
          [ngClass]="{ 'ddp-selected': 'SMALL' == totalValueStyle?.fontSize?.toString() }"
          (click)="changeFontSize('SMALL')"
        >
          <em class="ddp-icon-font-s"></em>
        </li>
        <li
          [ngClass]="{ 'ddp-selected': 'NORMAL' == totalValueStyle?.fontSize?.toString() }"
          (click)="changeFontSize('NORMAL')"
        >
          <em class="ddp-icon-font-m"></em>
        </li>
        <li
          [ngClass]="{ 'ddp-selected': 'LARGE' == totalValueStyle?.fontSize?.toString() }"
          (click)="changeFontSize('LARGE')"
        >
          <em class="ddp-icon-font-l"></em>
        </li>
      </ul>
    </div>
  </div>

  <div class="ddp-wrap-option-multy ddp-clear">
    <div class="ddp-col-8">
      <span class="ddp-label-txt">{{ 'msg.page.calc.label.font.color' | translate }}</span>
    </div>
    <div class="ddp-col-4 ddp-txt-right">
      <div class="ddp-type-selectbox ddp-color-selectbox2">
        <color-picker
          [showAlpha]="true"
          [color]="totalValueStyle['fontColor']"
          [showInitial]="true"
          [showInput]="true"
          [showUserColor]="true"
          (selected)="changeFontColor($event)"
        ></color-picker>
      </div>
    </div>
  </div>

  <div class="ddp-wrap-option-multy ddp-clear">
    <div class="ddp-col-8">
      <span class="ddp-label-txt">{{ 'msg.page.calc.label.font.background.color' | translate }}</span>
    </div>
    <div class="ddp-col-4 ddp-txt-right">
      <div class="ddp-type-selectbox ddp-color-selectbox2">
        <color-picker
          [showAlpha]="true"
          [color]="totalValueStyle['backgroundColor']"
          [showInitial]="true"
          [showInput]="true"
          [showUserColor]="true"
          (selected)="changeFontBackground($event)"
        ></color-picker>
      </div>
    </div>
  </div>

  <div class="ddp-wrap-option-multy ddp-clear">
    <div class="ddp-col-8">
      <span class="ddp-label-txt">{{ 'msg.page.common.grid.body.font.style' | translate }}</span>
    </div>
    <div class="ddp-col-4 ddp-txt-right">
      <ul class="ddp-list-express ddp-type">
        <li [ngClass]="{ 'ddp-selected': isFontStyle('BOLD') }" (click)="changeFontStyle('BOLD')">
          <em class="ddp-icon-font-bold"></em>
        </li>
        <li [ngClass]="{ 'ddp-selected': isFontStyle('ITALIC') }" (click)="changeFontStyle('ITALIC')">
          <em class="ddp-icon-font-italic"></em>
        </li>
      </ul>
    </div>
  </div>
</div>
