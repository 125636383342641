import { CreateDashboardApiModel, UpdateDashboardApiModel } from '@selfai-platform/bi-api';
import { omit } from 'lodash';
import { Dashboard, createBoardGlobalOptions } from '../models';

export function normalizeUpdateDashboardBody(
  dashboard: Partial<Dashboard>,
): UpdateDashboardApiModel {
  const updateDashboardBody = {
    ...omit(dashboard, ['_links', 'dataSources', 'widgets', 'workBook']),
  } as UpdateDashboardApiModel;

  if (dashboard?.workBook) {
    updateDashboardBody.workBook = `/api/workbooks/${dashboard.workBook.id}`;
  }
  if (dashboard?.dataSources) {
    updateDashboardBody.dataSources = dashboard.dataSources?.map(({ id }) => id);
  }
  if (dashboard?.widgets) {
    updateDashboardBody.widgets = dashboard.widgets?.map(({ id }) => id);
  }

  return updateDashboardBody;
}

export function normalizeAddDashboardBody({
  workbookId,
  dashboard,
}: {
  workbookId: string;
  dashboard: Dashboard;
}): CreateDashboardApiModel {
  return {
    ...omit(dashboard, [
      'createdBy',
      'createdTime',
      'modifiedBy',
      'modifiedTime',
      'id',
      'dataSource',
      'widgets',
      'dataSources',
      '_links',
    ]),
    workBook: `/api/workbooks/${workbookId}`,
    configuration: {
      ...createBoardGlobalOptions(),
      ...dashboard.configuration,
    } as any,
    widgets: dashboard.widgets?.map(({ id }) => `/api/widgets/${id}`),
    dataSources: dashboard.dataSources?.map(({ id }) => `/api/datasources/${id}`),
  };
}
