import { Dashboard, createBoardConfiguration, PageWidget, ChartType } from '@selfai-platform/bi-domain';

export function checkAndCorrectBoardInfo(boardInfo: Dashboard) {
  if (boardInfo.widgets) {
    boardInfo.widgets.forEach((widget) => {
      if ('page' === widget.type) {
        const pgeWidget: PageWidget = <PageWidget>widget;
        pgeWidget.mode = 'chart';
        //TODO check this part of code. Maybe it is not necessary for widget configuration filters
        if (pgeWidget.configuration && pgeWidget.configuration.dataSource && pgeWidget.configuration.filters) {
          if (ChartType.MAP !== pgeWidget.configuration.chart.type) {
            pgeWidget.configuration.filters.forEach((item) => {
              item.dataSource =
                pgeWidget.configuration.dataSource.engineName || pgeWidget.configuration.dataSource.name;
            });
          }
        }
      }
      widget.dashBoardId = boardInfo.id;
      if (widget.configuration) {
        if (boardInfo.configuration.hasOwnProperty('customFields')) {
          widget.configuration['customFields'] = boardInfo.configuration.customFields;
        }
      }
    });
  } else {
    boardInfo.widgets = [];
  }

  boardInfo.configuration || (boardInfo.configuration = createBoardConfiguration());
}
