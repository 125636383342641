import { ChartSelectMode } from '../enums';

export interface ChartSelectInfo {
  mode: ChartSelectMode;
  data: any;
  params: any;
}

export function createChartSelectInfo(mode: ChartSelectMode, data: any, params?: any): ChartSelectInfo {
  return {
    mode,
    data,
    params,
  };
}
