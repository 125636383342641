import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DataSourcesAdditionsComponent } from '../data-source-additions/data-sources-additions.component';
import { DataSourceUniqueNameComponent, DataSourceVisibilityComponent } from '../data-source-form-inputs';
import { DataSourcesBasic } from '../data-sources-basic';

@Component({
  selector: 'selfai-platform-data-sources-s3',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    CheckboxModule,
    ButtonModule,
    RadioButtonModule,
    InputTextareaModule,
    DropdownModule,
    DataSourcesAdditionsComponent,
    DataSourceUniqueNameComponent,
    DataSourceVisibilityComponent,
  ],
  templateUrl: './data-sources-s3.component.html',
  styleUrls: ['./data-sources-s3.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataSourcesS3Component extends DataSourcesBasic implements OnInit {
  paramsForm = this.fb.group({
    endpoint: ['', Validators.required],
    accessKey: ['', Validators.required],
    secretKey: ['', Validators.required],
    bucket: ['', Validators.required],
    pattern: ['', Validators.required],
    withoutSSL: [false],
  });

  constructor() {
    super('s3');
  }
}
