export interface UiChartDataLabelSettings {
  showValue: boolean;
  displayTypes?: UiChartDataLabelDisplayType[];
  position?: string;
  textStyle?: {
    backgroundColor?: string;
    color?: string;
    borderColor?: string;
  };
}

export enum UiChartDataLabelDisplayType {
  CATEGORY_NAME = 'CATEGORY_NAME',
  CATEGORY_VALUE = 'CATEGORY_VALUE',
  CATEGORY_PERCENT = 'CATEGORY_PERCENT',
  SERIES_NAME = 'SERIES_NAME',
  SERIES_VALUE = 'SERIES_VALUE',
  SERIES_PERCENT = 'SERIES_PERCENT',
  XAXIS_VALUE = 'XAXIS_VALUE',
  YAXIS_VALUE = 'YAXIS_VALUE',
  VALUE = 'VALUE',
  NODE_NAME = 'NODE_NAME',
  LINK_VALUE = 'LINK_VALUE',
  NODE_VALUE = 'NODE_VALUE',
  HIGH_VALUE = 'HIGH_VALUE',
  THREE_Q_VALUE = 'THREE_Q_VALUE',
  MEDIAN_VALUE = 'MEDIAN_VALUE',
  FIRST_Q_VALUE = 'FIRST_Q_VALUE',
  LOW_VALUE = 'LOW_VALUE',
  LAYER_NAME = 'LAYER_NAME',
  LOCATION_INFO = 'LOCATION_INFO',
  DATA_VALUE = 'DATA_VALUE',
}
