import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AggregatorRouteFormGroup } from '@selfai-platform/pipeline-common';
import { ReplaySubject } from 'rxjs';
import { AggregatorItemService } from './aggregator-item.service';

@Injectable()
export class AggregatorActiveItemService extends ReplaySubject<string | null> {
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly aggregatorItemService: AggregatorItemService,
  ) {
    super(1);
  }

  getActiveItem(): FormGroup<AggregatorRouteFormGroup> | undefined {
    const uuid = this.getActiveItemId();

    if (uuid) {
      return this.aggregatorItemService.getRouteById(uuid);
    }

    return undefined;
  }

  getActiveItemId(): string | null {
    return this.activatedRoute.snapshot.paramMap.get('uuid');
  }
}
