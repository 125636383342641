import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WorkflowPresets } from '../../workflow-presets/interfaces/workflow-presets.interface';
import { WorkflowSettingsPresetsStore } from '../store/workflow-settings-presets.store';

@Injectable()
export class WorkflowSettingsPresetsDomainService {
  workflowSettingsPresets$: Observable<WorkflowPresets[]>;

  constructor(private workflowSettingsPresetsStore: WorkflowSettingsPresetsStore) {
    this.workflowSettingsPresets$ = workflowSettingsPresetsStore.entities$;
  }

  getItem(id: string): void {
    this.workflowSettingsPresetsStore.getByKey(id);
  }
}
