/* eslint-disable max-lines */
import { isNullOrUndefined } from '@selfai-platform/shared';

import * as _ from 'lodash';

import { ChartUtil } from '../utils/chart-util';
import { OptionGenerator } from '../utils/option-generator';

import {
  AxisLabelType,
  AxisType,
  BaseOption,
  CHART_STRING_DELIMITER,
  ChartColorList,
  ChartColorType,
  ChartPivotType,
  ChartType,
  ColorCustomMode,
  ColorRange,
  ColorRangeType,
  EventType,
  GeoField,
  MapLayerType,
  PivotTableInfo,
  UIChartColor,
  UIChartColorByDimension,
  UIChartColorBySeries,
  UIChartColorByValue,
  UIMapOption,
  UIOption,
  UIScatterChart,
  VisualMapDimension,
  VisualMapType,
} from '@selfai-platform/bi-domain';
import { PiecewiseVisualMapComponentOption, SeriesOption, VisualMapComponentOption } from 'echarts';
import { FormatOptionConverter } from './format-option-converter';

import UI = OptionGenerator.UI;

export class ColorOptionConverter {
  public static convertColor(
    option: BaseOption,
    uiOption: UIOption,
    fieldOriginInfo: PivotTableInfo,
    fieldInfo: PivotTableInfo,
    pivotInfo: PivotTableInfo,
    drawByType?: EventType,
    series?: SeriesOption[],
    data?: any,
  ): BaseOption {
    const color: UIChartColor | undefined = uiOption.color;

    if (!color) return option;

    switch (color.type) {
      case ChartColorType.DIMENSION: {
        option = this.convertColorByDimension(option, fieldOriginInfo, fieldInfo, pivotInfo, uiOption);
        break;
      }
      case ChartColorType.SERIES: {
        const schema = (<UIChartColorBySeries>color).schema as keyof typeof ChartColorList;
        const colorCodes = _.cloneDeep(ChartColorList[schema]);

        if ((<UIChartColorBySeries>color).mapping) {
          Object.values((<UIChartColorBySeries>color).mapping).forEach((value: string, index) => {
            colorCodes[index] = value;
          });
        }

        option = this.convertColorBySeries(option, fieldInfo, colorCodes, series);
        break;
      }
      case ChartColorType.MEASURE: {
        if (uiOption.color['customMode'] && ColorCustomMode.GRADIENT == uiOption.color['customMode']) {
          option = this.convertColorByValueGradation(option, uiOption);
        } else {
          option = this.convertColorByValue(option, uiOption);
        }
        break;
      }
    }

    return option;
  }

  public static convertColorBySeries(
    option: BaseOption,
    fieldInfo: PivotTableInfo,
    codes: string[],
    series?: SeriesOption[],
  ): BaseOption {
    if (!series) {
      series = option.series as SeriesOption[];
    }

    if (!_.isUndefined(option.visualMap)) delete option.visualMap;

    _.each(series, (obj: any) => {
      const aggName = _.last(_.split(obj.name, CHART_STRING_DELIMITER));

      const fieldIdx = _.indexOf(fieldInfo.aggs, aggName);

      const colorIdx = fieldIdx >= codes['length'] ? fieldIdx % codes['length'] : fieldIdx;

      if (_.isUndefined(obj.itemStyle)) obj.itemStyle = OptionGenerator.ItemStyle.auto();

      obj.itemStyle.color = codes[colorIdx];
    });

    return option;
  }

  public static convertColorByDimension(
    option: BaseOption,
    fieldOriginInfo: PivotTableInfo,
    fieldInfo: PivotTableInfo,
    pivotInfo: PivotTableInfo,
    uiOption: UIOption,
  ): BaseOption {
    const schema = (<UIChartColorByDimension>uiOption.color).schema;
    const codes = _.cloneDeep(ChartColorList[schema]);
    const targetField = (<UIChartColorByDimension>uiOption.color).targetField;

    const series = option.series;

    if (!_.isUndefined(option.visualMap)) delete option.visualMap;

    let legendData: string[];

    let fieldIdx: number;

    let pivotType: ChartPivotType;

    let paramType: string;

    _.forEach(fieldOriginInfo, (value, key) => {
      if (_.indexOf(value, targetField) > -1) {
        fieldIdx = _.indexOf(value, targetField);
        pivotType = _.eq(key, ChartPivotType.COLS)
          ? ChartPivotType.COLS
          : _.eq(key, ChartPivotType.ROWS)
          ? ChartPivotType.ROWS
          : ChartPivotType.AGGS;
        paramType = _.eq(key, ChartPivotType.COLS) || _.eq(key, ChartPivotType.AGGS) ? 'name' : 'seriesName';
      }
    });

    if (isNullOrUndefined(pivotType) || isNullOrUndefined(paramType)) {
      _.forEach(fieldInfo, (value, key) => {
        if (_.indexOf(value, targetField) > -1) {
          fieldIdx = _.indexOf(value, targetField);
          pivotType = _.eq(key, ChartPivotType.COLS)
            ? ChartPivotType.COLS
            : _.eq(key, ChartPivotType.ROWS)
            ? ChartPivotType.ROWS
            : ChartPivotType.AGGS;
          paramType = _.eq(key, ChartPivotType.COLS) || _.eq(key, ChartPivotType.AGGS) ? 'name' : 'seriesName';
        }
      });
    }

    if (fieldOriginInfo[pivotType] && fieldOriginInfo[pivotType].length > 1) {
      legendData = pivotInfo[pivotType].map((value) => {
        return !_.split(value, CHART_STRING_DELIMITER)[fieldIdx]
          ? value
          : _.split(value, CHART_STRING_DELIMITER)[fieldIdx];
      });

      legendData = _.uniq(legendData);
    } else {
      legendData = pivotInfo[pivotType];
    }

    const setColor = (params) => {
      let name = _.split(params[paramType], CHART_STRING_DELIMITER)[fieldIdx];
      if (_.isUndefined(name)) name = params[paramType];

      legendData = _.uniq(legendData);

      let colorIdx = _.indexOf(legendData, name);
      colorIdx = colorIdx >= codes['length'] ? colorIdx % codes['length'] : colorIdx;

      return codes[colorIdx];
    };

    _.each(series, (obj: any) => {
      if (_.isUndefined(obj.itemStyle)) {
        obj.itemStyle = OptionGenerator.ItemStyle.auto();
      }

      if (!_.isUndefined(obj.itemStyle) && !_.isUndefined(obj.itemStyle)) {
        obj.itemStyle.color = (params: any) => {
          return setColor(params);
        };
      }

      if (!_.isUndefined(obj.itemStyle.borderWidth)) {
        obj.itemStyle.borderColor = (params: any) => {
          return setColor(params);
        };
        delete obj.itemStyle.color;
      }

      if (!_.isUndefined(obj.textStyle)) {
        obj.textStyle.color = (params: any) => {
          return setColor(params);
        };
      }
    });

    return option;
  }

  public static convertColorByValue(option: BaseOption, uiOption: UIOption): BaseOption {
    const ranges = (<UIChartColorByValue>uiOption.color).ranges;
    const schema = (<UIChartColorByDimension>uiOption.color).schema;
    const codes = _.cloneDeep(ChartColorList[schema]);

    if (_.isUndefined(option.visualMap)) option.visualMap = OptionGenerator.VisualMap.continuousVisualMap();

    const valueAxis = !uiOption.yAxis
      ? null
      : AxisLabelType.COLUMN == uiOption.yAxis.mode
      ? uiOption.yAxis
      : uiOption.xAxis;

    if (uiOption.type === ChartType.WORDCLOUD) {
      if (!uiOption.valueFormat) uiOption.valueFormat = {};
      if (!uiOption.valueFormat.decimal) uiOption.valueFormat.decimal = 2;
      if (!uiOption.valueFormat.useThousandsSep) uiOption.valueFormat.useThousandsSep = true;
    }

    const optionVisualMap = option.visualMap as PiecewiseVisualMapComponentOption;

    optionVisualMap.color = <any>codes;

    if (ranges && ranges.length > 0) {
      const rangeList = <any>_.cloneDeep(ranges);

      for (const item of rangeList) {
        if (null == item.lte) {
          const itemGtVal =
            uiOption.valueFormat != null
              ? FormatOptionConverter.getDecimalValue(
                  item.gt,
                  uiOption.valueFormat.decimal,
                  uiOption.valueFormat.useThousandsSep,
                )
              : item.gt;
          item.label = '> ' + itemGtVal;
        } else if (null == item.gt) {
          const itemLteVal =
            uiOption.valueFormat != null
              ? FormatOptionConverter.getDecimalValue(
                  item.lte,
                  uiOption.valueFormat.decimal,
                  uiOption.valueFormat.useThousandsSep,
                )
              : item.lte;
          item.label = '≤ ' + itemLteVal;
        } else {
          const itemGtVal =
            uiOption.valueFormat != null
              ? FormatOptionConverter.getDecimalValue(
                  item.gt,
                  uiOption.valueFormat.decimal,
                  uiOption.valueFormat.useThousandsSep,
                )
              : item.gt;
          const itemLteVal =
            uiOption.valueFormat != null
              ? FormatOptionConverter.getDecimalValue(
                  item.lte,
                  uiOption.valueFormat.decimal,
                  uiOption.valueFormat.useThousandsSep,
                )
              : item.lte;
          item.label = itemGtVal + ' - ' + itemLteVal;
        }

        if (valueAxis && null !== valueAxis.baseline && undefined !== valueAxis.baseline) {
          item.fixMin = null == item.fixMin ? null : item.fixMin - <number>valueAxis.baseline;
          item.fixMax = null == item.fixMax ? null : item.fixMax - <number>valueAxis.baseline;
          item.gt = null == item.gt ? null : item.gt - <number>valueAxis.baseline;
          item.lte = null == item.lte ? null : item.lte - <number>valueAxis.baseline;
        }
      }

      delete optionVisualMap.itemHeight;
      optionVisualMap.type = VisualMapType.PIECEWISE;

      optionVisualMap.pieces = rangeList;
    }

    if (!_.isUndefined(option.xAxis) && !_.isUndefined(option.yAxis)) {
      if (
        (_.eq(option.xAxis[0].type, AxisType.VALUE) || _.eq(option.xAxis[0].type, AxisType.LOG)) &&
        (_.eq(option.yAxis[0].type, AxisType.VALUE) || _.eq(option.yAxis[0].type, AxisType.LOG))
      ) {
        optionVisualMap.dimension = VisualMapDimension.Y;
      } else if (_.eq(option.xAxis[0].type, AxisType.CATEGORY) && _.eq(option.yAxis[0].type, AxisType.CATEGORY)) {
        delete optionVisualMap.dimension;
      } else {
        optionVisualMap.dimension =
          _.eq(option.xAxis[0].type, AxisType.VALUE) || _.eq(option.xAxis[0].type, AxisType.LOG)
            ? VisualMapDimension.X
            : VisualMapDimension.Y;
      }
    }

    return option;
  }

  public static convertColorByValueGradation(option: BaseOption, uiOption: UIOption): BaseOption {
    let codes = uiOption.color['visualGradations']
      ? _.cloneDeep(uiOption.color['visualGradations'])
      : _.cloneDeep(uiOption.color['ranges']);

    codes = codes
      .map((item) => {
        return item.color;
      })
      .reverse();
    let optionVisualMap: VisualMapComponentOption = option.visualMap as VisualMapComponentOption;

    if (_.isUndefined(optionVisualMap)) optionVisualMap = OptionGenerator.VisualMap.continuousVisualMap();

    optionVisualMap.color = <any>codes;

    optionVisualMap.type = VisualMapType.CONTINUOUS;

    optionVisualMap.min =
      (option['dataInfo'] as any).minValue >= 0 ? 0 : parseInt((option['dataInfo'] as any).minValue.toFixed(0));
    optionVisualMap.max = parseInt((option['dataInfo'] as any).maxValue.toFixed(0));

    if (!_.isUndefined(option.xAxis) && !_.isUndefined(option.yAxis)) {
      if (
        (_.eq(option.xAxis[0].type, AxisType.VALUE) || _.eq(option.xAxis[0].type, AxisType.LOG)) &&
        (_.eq(option.yAxis[0].type, AxisType.VALUE) || _.eq(option.yAxis[0].type, AxisType.LOG))
      ) {
        optionVisualMap.dimension = VisualMapDimension.Y;
      } else if (_.eq(option.xAxis[0].type, AxisType.CATEGORY) && _.eq(option.yAxis[0].type, AxisType.CATEGORY)) {
        delete optionVisualMap.dimension;
      } else {
        optionVisualMap.dimension =
          _.eq(option.xAxis[0].type, AxisType.VALUE) || _.eq(option.xAxis[0].type, AxisType.LOG)
            ? VisualMapDimension.X
            : VisualMapDimension.Y;
      }
    }

    return option;
  }

  public static setMeasureColorRange(uiOption, data, colorList: any, colorAlterList = []): ColorRange[] {
    const rangeList = [];

    let rowsListLength = data.rows.length;

    switch (uiOption.type) {
      case ChartType.GRID:
        {
          let gridRowsListLength = 0;

          if (data.rows.length > 0 && !_.isEmpty(data.rows[0])) {
            gridRowsListLength += data.rows.length;
          }

          if (data.columns.length > 0 && -1 !== data.columns[0].name.indexOf(CHART_STRING_DELIMITER)) {
            gridRowsListLength += data.columns.length;
          } else {
            gridRowsListLength += data.columns[0].value.length;
          }

          rowsListLength = gridRowsListLength;
        }
        break;

      case ChartType.PIE:
      case ChartType.WORDCLOUD:
        rowsListLength = data.columns[0].value.length;

        if (uiOption.type === ChartType.WORDCLOUD) {
          if (!uiOption['valueFormat']) uiOption['valueFormat'] = {};
          uiOption['valueFormat']['decimal'] = 2;
        }
        break;
      case ChartType.HEATMAP:
        rowsListLength = data.columns.length + data.rows.length;
        break;

      case ChartType.GAUGE:
        rowsListLength = (<any>_.max(data.columns)).length;
    }

    const colorListLength = colorAlterList.length > 0 ? colorAlterList.length - 1 : colorList.length - 1;

    const minValue = uiOption.minValue >= 0 ? 0 : _.cloneDeep(uiOption.minValue);

    const addValue = (uiOption.maxValue - minValue) / colorListLength;

    let maxValue = _.cloneDeep(uiOption.maxValue);

    let shape;
    if ((<UIScatterChart>uiOption).pointShape) {
      shape = (<UIScatterChart>uiOption).pointShape.toString().toLowerCase();
    }

    const formatValue = (value) => {
      return uiOption.validate != null
        ? parseFloat(
            (
              (Number(value) * Math.pow(10, uiOption.valueFormat.decimal)) /
              Math.pow(10, uiOption.valueFormat.decimal)
            ).toFixed(uiOption.valueFormat.decimal),
          )
        : value;
    };

    const formatMinValue = formatValue(uiOption.minValue);

    const formatMaxValue = formatValue(uiOption.maxValue);

    for (let index = colorListLength; index >= 0; index--) {
      const color = colorList[index];

      if (colorListLength == index) {
        rangeList.push(
          UI.Range.colorRange(ColorRangeType.SECTION, color, formatMaxValue, null, formatMaxValue, null, shape),
        );
      } else {
        let min = 0 == index ? null : formatValue(maxValue - addValue);

        if (min < uiOption.minValue && min < 0) min = _.cloneDeep(formatMinValue);

        rangeList.push(
          UI.Range.colorRange(
            ColorRangeType.SECTION,
            color,
            min,
            formatValue(maxValue),
            min,
            formatValue(maxValue),
            shape,
          ),
        );

        maxValue = min;
      }
    }

    return rangeList;
  }

  public static setMapMeasureColorRange(
    uiOption: UIMapOption,
    data: any,
    colorList: any,
    layerIndex: number,
    layers: GeoField[],
    colorAlterList = [],
  ): ColorRange[] {
    const rangeList = [];

    const colorListLength = colorAlterList.length > 0 ? colorAlterList.length - 1 : colorList.length - 1;

    let alias = ChartUtil.getFieldAlias(
      uiOption.layers[layerIndex].color.column,
      layers,
      uiOption.layers[layerIndex].color.aggregationType,
    );

    if (
      !_.isUndefined(uiOption['analysis']) &&
      !_.isUndefined(uiOption['analysis']['use']) &&
      uiOption['analysis']['use']
    ) {
      if (
        uiOption['analysis']['operation']['choropleth'] &&
        uiOption['analysis']['operation']['aggregation']['column'] == 'count'
      ) {
        alias = uiOption['analysis']['operation']['aggregation']['column'];
      }
    } else {
      if (uiOption.layers[layerIndex].type == MapLayerType.CLUSTER && uiOption.layers[layerIndex]['clustering']) {
        alias = 'count';
      }
    }

    let minValue = 0;
    let maxValue = 0;
    if (!_.isUndefined(data.valueRange[alias])) {
      minValue = _.cloneDeep(data.valueRange[alias].minValue);
      maxValue = _.cloneDeep(data.valueRange[alias].maxValue);
    }

    if (
      !_.isUndefined(uiOption.layers[uiOption.layerNum]['isColorOptionChanged']) &&
      uiOption.layers[uiOption.layerNum]['isColorOptionChanged']
    ) {
      delete uiOption.layers[uiOption.layerNum]['isColorOptionChanged'];
      uiOption.layers[layerIndex].color.minValue = minValue;
      uiOption.layers[layerIndex].color.maxValue = maxValue;
    } else {
      _.isUndefined(uiOption.layers[layerIndex].color.minValue) || uiOption.layers[layerIndex].color.minValue > minValue
        ? (uiOption.layers[layerIndex].color.minValue = minValue)
        : (minValue = uiOption.layers[layerIndex].color.minValue);
      _.isUndefined(uiOption.layers[layerIndex].color.maxValue) || uiOption.layers[layerIndex].color.maxValue < maxValue
        ? (uiOption.layers[layerIndex].color.maxValue = maxValue)
        : (maxValue = uiOption.layers[layerIndex].color.maxValue);
    }

    const addValue = (maxValue - minValue) / colorListLength;

    let shape;

    const formatValue = (value) => {
      return parseFloat(
        (
          (Number(value) * Math.pow(10, uiOption.valueFormat.decimal)) /
          Math.pow(10, uiOption.valueFormat.decimal)
        ).toFixed(uiOption.valueFormat.decimal),
      );
    };

    const formatMinValue = formatValue(minValue);

    const formatMaxValue = formatValue(maxValue);

    for (let index = colorListLength; index >= 0; index--) {
      const color = colorList[index];

      if (colorListLength === index) {
        rangeList.push(
          UI.Range.colorRange(ColorRangeType.SECTION, color, formatMaxValue, null, formatMaxValue, null, shape),
        );
      } else {
        let min = 0 == index ? null : formatValue(maxValue - addValue);

        if (min < minValue && min < 0) min = _.cloneDeep(formatMinValue);

        rangeList.push(
          UI.Range.colorRange(
            ColorRangeType.SECTION,
            color,
            min,
            formatValue(maxValue),
            min,
            formatValue(maxValue),
            shape,
          ),
        );

        maxValue = min;
      }
    }

    return rangeList;
  }
}
