import { FactoryProvider, Injector, ValueProvider } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { MAIN_MENU_CHUNK } from './main-menu-chunk.token';

export function provideMainMenuChunk(item: MenuItem): ValueProvider {
  return {
    provide: MAIN_MENU_CHUNK,
    useValue: item,
    multi: true,
  };
}

export function provideMainMenuChunkFactory(
  factory: (injector: Injector) => Promise<MenuItem | null> | MenuItem | null,
): FactoryProvider {
  return {
    provide: MAIN_MENU_CHUNK,
    useFactory: factory,
    multi: true,
    deps: [Injector],
  };
}
