import {
  BaseOption,
  DataLabelPosition,
  Position,
  UIChartDataLabel,
  UiChartDataLabelDisplayType,
  UIChartFormat,
  UIOption,
} from '@selfai-platform/bi-domain';
import * as _ from 'lodash';
import { FormatOptionConverter } from './format-option-converter';

export class LabelOptionConverter {
  public static convertLabel(chartOption: BaseOption, uiOption: UIOption): BaseOption {
    if (!uiOption.dataLabel) {
      return chartOption;
    }

    chartOption = this.convertLabelShow(chartOption, uiOption);

    chartOption = this.convertLabelPosition(chartOption, uiOption);

    chartOption = this.convertLabelRotation(chartOption, uiOption);

    chartOption = this.convertLabelColor(chartOption, uiOption);

    chartOption = this.convertLabelBackgroundColor(chartOption, uiOption);

    chartOption = this.convertLabelOutlineColor(chartOption, uiOption);

    chartOption = this.convertLabelAlign(chartOption, uiOption);

    return chartOption;
  }

  public static convertLabelShow(chartOption: BaseOption, uiOption: UIOption): BaseOption {
    const label: UIChartDataLabel = uiOption.dataLabel;

    const series = chartOption.series;

    _.each(series, (option: any) => {
      if (_.isUndefined(option.label)) {
        option.label = { normal: {} };
      }
      option.label.show = label.showValue;
    });

    if (_.isUndefined((chartOption as any).label)) {
      (chartOption as any).label = {};
    }
    if (_.isUndefined((chartOption as any).label)) {
      (chartOption as any).label = {};
    }
    (chartOption as any).label.show = label.showValue;

    return chartOption;
  }

  public static convertLabelPosition(chartOption: BaseOption, uiOption: UIOption): BaseOption {
    const label: UIChartDataLabel = uiOption.dataLabel;
    if (!label.pos) {
      return chartOption;
    }

    let position: Position = null;

    switch (label.pos) {
      case DataLabelPosition.OUTSIDE_TOP:
      case DataLabelPosition.TOP:
        position = Position.TOP;
        break;
      case DataLabelPosition.OUTSIDE_RIGHT:
        position = Position.RIGHT;
        break;
      case DataLabelPosition.INSIDE_TOP:
        position = Position.INSIDETOP;
        break;
      case DataLabelPosition.INSIDE_BOTTOM:
        position = Position.INSIDEBOTTOM;
        break;
      case DataLabelPosition.INSIDE_RIGHT:
        position = Position.INSIDERIGHT;
        break;
      case DataLabelPosition.INSIDE_LEFT:
        position = Position.INSIDELEFT;
        break;
      case DataLabelPosition.CENTER:
        position = Position.INSIDE;
        break;
      case DataLabelPosition.BOTTOM:
        position = Position.BOTTOM;
        break;
      default:
        position = Position.TOP;
        break;
    }

    const series = chartOption.series;

    _.each(series, (option: any) => {
      if (_.isUndefined(option.label)) {
        option.label = { normal: {} };
      }
      option.label.position = position;
    });

    if (_.isUndefined((chartOption as any).label)) {
      (chartOption as any).label = {};
    }

    (chartOption as any).label.position = position;

    return chartOption;
  }

  public static convertLabelRotation(chartOption: BaseOption, uiOption: UIOption): BaseOption {
    const label: UIChartDataLabel = uiOption.dataLabel;

    const series = chartOption.series;

    _.each(series, (option: any) => {
      if (_.isUndefined(option.label)) {
        option.label = {};
      }
      option.label.rotate = label.enableRotation ? 90 : 0;
      option.label.align = label.enableRotation ? 'top' : null;
    });

    return chartOption;
  }

  public static convertLabelColor(chartOption: BaseOption, uiOption: UIOption): BaseOption {
    const label: UIChartDataLabel = uiOption.dataLabel;

    const series = chartOption.series;

    _.each(series, (option: any) => {
      if (_.isUndefined(option.label)) {
        option.label = {};
      }
      if (label.textColor) {
        option.label.color = label.textColor;
      } else {
        delete option.label.color;
      }
    });

    return chartOption;
  }

  public static convertLabelBackgroundColor(chartOption: BaseOption, uiOption: UIOption): BaseOption {
    const label: UIChartDataLabel = uiOption.dataLabel;

    const series = chartOption.series;

    _.each(series, (option: any) => {
      if (_.isUndefined(option.label)) {
        option.label = {};
      }
      if (label.textBackgroundColor) {
        option.label.backgroundColor = label.textBackgroundColor;
      } else {
        delete option.label.backgroundColor;
      }
    });

    return chartOption;
  }

  public static convertLabelOutlineColor(chartOption: BaseOption, uiOption: UIOption): BaseOption {
    const label: UIChartDataLabel = uiOption.dataLabel;

    const series = chartOption.series;

    _.each(series, (option: any) => {
      if (_.isUndefined(option.label)) {
        option.label = {};
      }
      if (label.textOutlineColor) {
        option.label.textBorderColor = label.textOutlineColor;
        option.label.textBorderWidth = 2;
      } else {
        delete option.label.textBorderColor;
        delete option.label.textBorderWidth;
      }
    });

    return chartOption;
  }

  public static convertLabelAlign(chartOption: BaseOption, uiOption: UIOption): BaseOption {
    const label: UIChartDataLabel = uiOption.dataLabel;

    const series = chartOption.series;

    _.each(series, (option: any) => {
      if (_.isUndefined(option.label)) {
        option.label = { normal: {} };
      }
      if (label.textAlign && label.displayTypes && _.filter(label.displayTypes).length >= 2) {
        let color = '#FFF';

        if (label.textColor && label.textColor !== ' ') {
          color = label.textColor;
        } else {
          if (label.pos) {
            if (_.eq(label.pos, DataLabelPosition.CENTER) || String(label.pos).indexOf('INSIDE') != -1) {
              color = '#FFF';
            } else {
              color = null;
            }
          }
        }

        option.label.rich = {
          align: {
            align: String(label.textAlign).toLowerCase(),
            color: color,
          },
        };
      } else {
        if (' ' == option.label.color) delete option.label.color;
        delete option.label.rich;
      }
    });

    return chartOption;
  }

  public static setDataLabelPreviewList(uiOption: UIOption): object[] {
    uiOption.dataLabel.previewList = [];

    let format: UIChartFormat = uiOption.valueFormat;

    const axisFormat = FormatOptionConverter.getlabelAxisScaleFormat(uiOption);
    if (axisFormat) format = axisFormat;

    const numValue = FormatOptionConverter.getFormatValue(1000, format);

    if (uiOption.dataLabel.displayTypes) {
      for (const type of uiOption.dataLabel.displayTypes) {
        switch (type) {
          case UiChartDataLabelDisplayType.CATEGORY_NAME:
            uiOption.dataLabel.previewList.push({
              name: 'Category Name',
              value: UiChartDataLabelDisplayType.CATEGORY_NAME,
            });
            break;
          case UiChartDataLabelDisplayType.CATEGORY_VALUE:
            uiOption.dataLabel.previewList.push({ name: numValue, value: UiChartDataLabelDisplayType.CATEGORY_VALUE });
            break;
          case UiChartDataLabelDisplayType.CATEGORY_PERCENT:
            uiOption.dataLabel.previewList.push({ name: '100%', value: UiChartDataLabelDisplayType.CATEGORY_PERCENT });
            break;
          case UiChartDataLabelDisplayType.SERIES_NAME:
            uiOption.dataLabel.previewList.push({
              name: 'Series Name',
              value: UiChartDataLabelDisplayType.SERIES_NAME,
            });
            break;
          case UiChartDataLabelDisplayType.SERIES_VALUE:
            uiOption.dataLabel.previewList.push({ name: numValue, value: UiChartDataLabelDisplayType.SERIES_VALUE });
            break;
          case UiChartDataLabelDisplayType.SERIES_PERCENT:
            uiOption.dataLabel.previewList.push({ name: '100%', value: UiChartDataLabelDisplayType.SERIES_PERCENT });
            break;
          case UiChartDataLabelDisplayType.XAXIS_VALUE:
            uiOption.dataLabel.previewList.push({ name: numValue, value: UiChartDataLabelDisplayType.XAXIS_VALUE });
            break;
          case UiChartDataLabelDisplayType.YAXIS_VALUE:
            uiOption.dataLabel.previewList.push({ name: numValue, value: UiChartDataLabelDisplayType.YAXIS_VALUE });
            break;
          case UiChartDataLabelDisplayType.VALUE:
            uiOption.dataLabel.previewList.push({ name: numValue, value: UiChartDataLabelDisplayType.VALUE });
            break;
          case UiChartDataLabelDisplayType.NODE_NAME:
            uiOption.dataLabel.previewList.push({ name: 'Node Name', value: UiChartDataLabelDisplayType.NODE_NAME });
            break;
          case UiChartDataLabelDisplayType.LINK_VALUE:
            uiOption.dataLabel.previewList.push({ name: numValue, value: UiChartDataLabelDisplayType.LINK_VALUE });
            break;
          case UiChartDataLabelDisplayType.NODE_VALUE:
            uiOption.dataLabel.previewList.push({ name: numValue, value: UiChartDataLabelDisplayType.NODE_VALUE });
            break;
        }
      }
    }

    return uiOption.dataLabel.previewList;
  }
}
