<form *ngIf="{ loading: loading$ | async, error: error$ | async } as obs" [formGroup]="form" (ngSubmit)="submit()">
  <div class="p-float-label mt-4 mb-3">
    <input
      [disabled]="!!obs.loading"
      pAutoFocus
      [autofocus]="true"
      class="w-full"
      formControlName="hzName"
      pInputText
      type="text"
    />
    <label for="float-input">Map name</label>
  </div>

  <div class="p-float-label mt-4 mb-3">
    <input [disabled]="!!obs.loading" class="w-full" formControlName="hzKey" pInputText type="text" />
    <label for="float-input">Map key</label>
  </div>

  <ng-container *ngIf="obs.error">
    <p-message class="block" severity="error" text="Error. Try another name or key"></p-message>
  </ng-container>

  <pre *ngIf="resultJson$ | async as resultJson" [innerHtml]="resultJson | json"></pre>

  <div class="mt-5">
    <button
      type="button"
      class="mr-3 p-button-secondary p-button-outlined"
      (click)="closeDialog()"
      (keydown.enter)="closeDialog()"
      pRipple
      pButton
      label="Close"
    ></button>

    <button type="submit" class="mr-3 p-button-success" pRipple pButton label="Submit"></button>
  </div>
</form>
