import { Inject, Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription, debounceTime, distinctUntilChanged, filter, skip } from 'rxjs';
import { DEFAULT_PAGE_PARAMS } from '../tokens';
import { cleanObjectUndefined } from '../utils';
import { PageParamsAdapter } from './page-params.adapter';
import { DefaultPageParams, Filter, PageParams } from './page-params.model';

export function mapFiltersBiToUrlQueryParams(filters?: Filter[]): Record<string, string> {
  if (!filters) {
    return {};
  }

  return filters.reduce((acc, { fieldName, value }) => {
    acc[`filter:${fieldName}`] = value.toString();

    return acc;
  }, {} as Record<string, string>);
}

@Injectable()
export class UrlPageParamsService implements OnDestroy, PageParamsAdapter {
  private readonly pageParams$ = new BehaviorSubject<PageParams | undefined>(undefined);
  private readonly subscription = new Subscription();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    @Inject(DEFAULT_PAGE_PARAMS) private readonly defaultPageParams?: DefaultPageParams,
  ) {
    this.initEventsForClearPageParams();
    this.initEventsForInitPageParams();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initFromQueryParams(): void {
    const defaultPageParams = this.defaultPageParams;
    const { query, pageSize, pageNumber, sortField, sortOrder, filters } = this.calcPageParams(defaultPageParams);

    this.setPageParams({
      pageSize,
      pageNumber,
      sortField,
      sortOrder,
      query,
      filters,
    });

    const subPageParams = this.getPageParams()
      // Skip react for setPageParams from above
      .pipe(debounceTime(0), skip(1), filter(Boolean))
      .subscribe((pageParams) => {
        const queryParams = {
          pageSize: pageParams.pageSize,
          pageNumber: pageParams.pageNumber,
          sortField: pageParams.sortField,
          sortOrder: pageParams.sortOrder,
          query: pageParams.query,
          ...mapFiltersBiToUrlQueryParams(pageParams.filters),
        };

        this.router.navigate([], {
          relativeTo: this.route,
          queryParams: cleanObjectUndefined(queryParams),
          queryParamsHandling: 'merge',
          replaceUrl: true,
        });
      });

    this.subscription.add(subPageParams);
  }

  setPageParams(pageParams: Partial<PageParams>): PageParams {
    const newPageParams = { ...(this.pageParams$.value || {}), ...pageParams } as PageParams;
    this.pageParams$.next(newPageParams);

    return newPageParams;
  }

  getPageParams(): Observable<PageParams | undefined> {
    return this.pageParams$.asObservable().pipe(
      distinctUntilChanged((a, b) => {
        return JSON.stringify(a) === JSON.stringify(b);
      }),
    );
  }

  private calcPageParams(defaultPageParams?: DefaultPageParams): PageParams {
    const paramMap = this.route.snapshot.queryParamMap;
    const pageSize = +(paramMap.get('pageSize') || defaultPageParams?.pageSize || 12);
    const pageNumber = +(paramMap.get('pageNumber') || defaultPageParams?.pageNumber) || 1;
    const sortField = paramMap.get('sortField') || defaultPageParams?.sortField;
    const sortOrder = (paramMap.get('sortOrder') as PageParams['sortOrder']) || defaultPageParams?.sortOrder;
    const filterKeys = paramMap.keys.filter((key) => key.startsWith('filter:'));

    const filters: Filter[] = filterKeys.map((key) => {
      const fieldName = key.split(':')[1];
      const value = paramMap.get(key) || undefined;

      return { fieldName, value };
    });

    const query = paramMap.get('query') || undefined;

    return { pageSize, pageNumber, sortField, sortOrder, query, filters };
  }

  private initEventsForInitPageParams(): void {
    this.subscription.add(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          distinctUntilChanged((prev, event) => {
            const newUrl = (event as NavigationEnd).url.split('?')[0];
            const currentUrl = (prev as NavigationEnd).url.split('?')[0];

            return newUrl === currentUrl;
          }),
        )
        .subscribe(() => {
          this.initFromQueryParams();
        }),
    );
  }

  private initEventsForClearPageParams(): void {
    this.subscription.add(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationStart),
          filter((event) => {
            const newUrl = (event as NavigationStart).url.split('?')[0];
            const currentUrl = this.router.url.split('?')[0];

            return newUrl !== currentUrl;
          }),
        )
        .subscribe(() => {
          this.pageParams$.next(undefined);
        }),
    );
  }
}
