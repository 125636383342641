<div class="ddp-ui-chart-side ddp-show">
  <div class="ddp-ui-side-title">
    {{ 'msg.page.calc.option.title' | translate }}
  </div>

  <div class="ddp-wrap-downmenu">
    <div class="ddp-box-down">
      <div class="ddp-wrap-divide">
        <div class="ddp-divide2">
          <div class="ddp-wrap-option-slider">
            <span class="ddp-label-slider">{{ 'msg.page.calc.show.calculated.row' | translate }}</span>

            <div class="ddp-checkbox-slide ddp-checkbox-automatic2">
              <input
                type="checkbox"
                placeholder="{{ 'msg.comm.ui.placeholder.label' | translate }}"
                [ngModel]="uiOption['totalValueStyle']"
                [disabled]="'MASTER' == uiOption['dataType']"
                (click)="showCalculatedRow()"
              />
              <label><span class="ddp-slide"></span></label>
            </div>
          </div>

          <calc-option-slider
            *ngIf="uiOption['totalValueStyle']"
            [totalValueStyle]="uiOption['totalValueStyle']"
            (onChanged)="onChangedCalculatedRowSlider($event)"
          ></calc-option-slider>

          <div class="ddp-wrap-option-slider">
            <span class="ddp-label-slider">{{ 'msg.page.calc.show.calculated.column' | translate }}</span>

            <div class="ddp-checkbox-slide ddp-checkbox-automatic2">
              <input
                type="checkbox"
                placeholder="{{ 'msg.comm.ui.placeholder.label' | translate }}"
                [ngModel]="uiOption['showCalculatedColumnStyle']"
                [disabled]="'MASTER' == uiOption['dataType']"
                (click)="showCalculatedColumn()"
              />
              <label><span class="ddp-slide"></span></label>
            </div>
          </div>

          <calc-option-slider
            *ngIf="uiOption['showCalculatedColumnStyle']"
            [totalValueStyle]="uiOption['showCalculatedColumnStyle']"
            (onChanged)="onChangedCalculatedColumnSlider($event)"
          ></calc-option-slider>
        </div>
      </div>
    </div>
  </div>
</div>
