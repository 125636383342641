import { ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef, Injectable, Injector } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Toast as ToastComponent } from 'primeng/toast';
import { v4 as uuidv4 } from 'uuid';
import { AlertOptions } from './alert-options.model';
import { AlertSeverity } from './alert-severity.model';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private key?: string;
  private readonly messageService: MessageService = new MessageService();

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
  ) {
    this.createComponent();
  }

  message(message: string, severity: AlertSeverity, options: AlertOptions = {}): void {
    this.messageService.add({ key: this.key, severity, detail: message, ...options });
  }

  error(message: string, options: AlertOptions = {}): void {
    this.message(message, 'error', options);
  }

  success(message: string, options: AlertOptions = {}): void {
    this.message(message, 'success', options);
  }

  info(message: string, options: AlertOptions = {}): void {
    this.message(message, 'info', options);
  }

  warn(message: string, options: AlertOptions = {}): void {
    this.message(message, 'warn', options);
  }

  protected createComponent() {
    const injector = Injector.create({
      providers: [{ provide: MessageService, useValue: this.messageService }],
      parent: this.injector,
    });

    // TODO: dispose of componentFactoryResolver bcz it's deprecated
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ToastComponent);
    const componentRef = componentFactory.create(injector);
    this.key = uuidv4();
    componentRef.instance.key = this.key;

    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<ToastComponent>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
  }

  clear(): void {
    this.messageService.clear(this.key);
  }
}
