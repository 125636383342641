import { Filter, createFilter } from './filter.model';

export interface WildCardFilter extends Filter {
  contains?: ContainsType;
  type: 'wildcard';
  value?: string;
}

export function createWildCardFilter(params: Partial<WildCardFilter> = {}): WildCardFilter {
  return {
    ...createFilter(),
    type: 'wildcard',
    ...params,
  };
}

export enum ContainsType {
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
  BOTH = 'BOTH',
}
