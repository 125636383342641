<div class="read-data-frame h-full">
  <form class="h-full" *ngIf="hasParameters" [formGroup]="form" (ngSubmit)="onSubmit()">
    <selfai-platform-data-source-selector
      [control]="form.controls.dataSourceId"
      class="h-full"
    ></selfai-platform-data-source-selector>
  </form>
  <p-message
    *ngIf="!hasParameters"
    text="{{ 'workflow.cubes.read-data-frame.no-parameters' | translate }}"
    severity="error"
  ></p-message>
</div>
