import { Injectable } from '@angular/core';
import { Session } from '@selfai-platform/pipeline-common';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SessionListStateService extends ReplaySubject<Session[] | undefined> {
  private currentValue: Session[] | undefined;

  constructor() {
    super(1);
  }

  getSessionList(): Observable<Session[] | undefined> {
    return this.asObservable();
  }

  setSessionList(sessionList: Session[]): void {
    if (!this.currentValue && !sessionList) {
      return;
    }
    if (this.currentValue === sessionList) {
      return;
    }
    if (this.currentValue && sessionList && JSON.stringify(this.currentValue) === JSON.stringify(sessionList)) {
      return;
    }
    this.currentValue = sessionList;
    this.next(sessionList);
  }

  clearSessionList(): void {
    this.next(undefined);
  }
}
