import { Injectable } from '@angular/core';
import {
  GraphNodeOptionSerialized,
  GraphNodeUpdateParameterValuesOptions,
  GraphNodeUpdatePropertyOptions,
  KeRefButtonItem,
  PipelineWorkflowVariableItem,
  WorkflowEditorActions,
  WorkflowSerialized,
} from '@selfai-platform/pipeline-common';

interface AngularJsBridgeServiceNg1 {
  registerHook(hookName: string, hook: unknown): void;
  emitEvent(event: string, ...args: unknown[]): void;
}

@Injectable({ providedIn: 'root' })
export class AngularJsBridgeService {
  private angularJsHookService: AngularJsBridgeServiceNg1 | null = null;

  setHookService(angularJsHookService: AngularJsBridgeServiceNg1): void {
    this.angularJsHookService = angularJsHookService;
  }

  clearHookService(): void {
    this.angularJsHookService = null;
  }

  registerHook(hookName: string, hook: { (args: unknown): void }): void {
    this.angularJsHookService?.registerHook(hookName, hook);
  }

  emitEventToAngularjs(event: WorkflowEditorActions, ...args: unknown[]): void;
  emitEventToAngularjs(event: WorkflowEditorActions.OpenNodeModalDialogUnselectedNode, selectedNodeId: string): void;
  emitEventToAngularjs(event: WorkflowEditorActions.OpenNodeModalDialogSelectedNode, selectedNodeId: string): void;
  emitEventToAngularjs(event: WorkflowEditorActions.MoveToNode, nodeIds: string[]): void;
  emitEventToAngularjs(event: WorkflowEditorActions.SelectNode, nodeIds: string[]): void;
  emitEventToAngularjs(event: WorkflowEditorActions.UnselectNode): void;
  emitEventToAngularjs(event: WorkflowEditorActions.RestoreNodes, nodeIds: string[]): void;
  emitEventToAngularjs(event: WorkflowEditorActions.RemoveFromRecycleBin, nodeIds: string[]): void;
  emitEventToAngularjs(
    event: WorkflowEditorActions.AddNode,
    cubeData: GraphNodeOptionSerialized,
    cursorPosition: { x: number; y: number },
  ): void;
  emitEventToAngularjs(event: WorkflowEditorActions.ReOpen): void;
  emitEventToAngularjs(event: WorkflowEditorActions.SaveAndOpen, workflow: WorkflowSerialized): void;
  emitEventToAngularjs(event: WorkflowEditorActions.RunWorkflow, selectedNodeIds: string[]): void;
  emitEventToAngularjs(event: WorkflowEditorActions.UpdateNodeProperties, data: GraphNodeUpdatePropertyOptions): void;
  emitEventToAngularjs(
    event: WorkflowEditorActions.UpdateNodeParamterValues,
    graphNodeUpdateOptions: GraphNodeUpdateParameterValuesOptions,
  ): void;
  emitEventToAngularjs(event: WorkflowEditorActions.CubeCommentSave, data: { nodeId: string; comment: string }): void;
  emitEventToAngularjs(
    event: WorkflowEditorActions.SaveVariables,
    data: PipelineWorkflowVariableItem[] | undefined,
  ): void;
  emitEventToAngularjs(event: WorkflowEditorActions.SaveReferenceButtons, data: KeRefButtonItem[] | undefined): void;
  emitEventToAngularjs(event: string, ...args: unknown[]): void;

  emitEventToAngularjs(event: string, ...args: unknown[]): void {
    this.angularJsHookService?.emitEvent(event, ...args);
  }
}
