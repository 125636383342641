<div class="ddp-wrap-dropmenu">
  <div class="ddp-ui-dropmenu" [class.ddp-selected]="'analysis' === dataLayerKey">
    <div class="ddp-ui-drop-title" #panel>
      {{ 'msg.page.prediction.analysis' | translate }}
      <em class="ddp-icon-drop-view"></em>
    </div>

    <div class="ddp-ui-drop-contents">
      <analysis-prediction
        *ngIf="selectChart === 'line'"
        [dataSubLayerKey]="dataSubLayerKey"
        [selectChart]="selectChart"
        [isChartShow]="isChartShow"
        [widgetConfiguration]="widgetConfiguration"
        [dataLayerKey]="dataLayerKey"
        (changeAnalysisPredictionNoti)="changeAnalysisPredictionNoti()"
        (changeForecastNoti)="changeForecastNoti()"
        (changeConfidenceNoti)="changeConfidenceNoti()"
      ></analysis-prediction>

      <map-spatial
        #MapSpatialComponent
        *ngIf="selectChart === 'map'"
        [uiOption]="uiOption"
        [shelf]="shelf"
        (changeAnalysisNoti)="changeAnalysisNoti($event)"
      ></map-spatial>
    </div>
  </div>
</div>
