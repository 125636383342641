import { UiChartDataLabelDisplayType, UiChartFormatSettings, UiChartTooltipSettings } from '@selfai-platform/bi-domain';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { DataSettings, WidgetNode } from '../../models';
import { formatTooltipName } from './format-tooltip-name';
import { formatTooltipValue } from './format-tooltip-value';

interface TooltipParams extends CallbackDataParams {
  data: WidgetNode & {
    originalSource?: string;
    originalTarget?: string;
    target?: string;
  };
}

// TODO: need to be refactored. was moved from legacy code with small fixes
export function formatGraphChartTooltip({
  tooltipParams,
  format,
  tooltipSettings,
  dataSettings,
}: {
  tooltipParams: TooltipParams;
  format: UiChartFormatSettings;
  tooltipSettings: UiChartTooltipSettings;
  dataSettings: DataSettings;
}): string {
  if (tooltipParams.data.value) {
    let result: string[] = [];

    if (tooltipSettings.displayTypes.includes(UiChartDataLabelDisplayType.NODE_NAME)) {
      if (tooltipParams.data.target) {
        result = [
          ...result,
          ...formatTooltipName(
            [tooltipParams.data.originalSource],
            dataSettings.pivot.columns,
            true,
            dataSettings.pivot,
          ),
        ];

        result = [
          ...result,
          ...formatTooltipName([tooltipParams.data.originalTarget], dataSettings.pivot.rows, true, dataSettings.pivot),
        ];
      } else {
        if (tooltipParams.data.fields && tooltipParams.data.fields.length > 0) {
          let columnField;
          for (const field of tooltipParams.data.fields) {
            columnField = dataSettings.pivot.columns.find((column) => column.alias === field);
            result = [
              ...result,
              ...formatTooltipName(
                [tooltipParams.data.originalName],
                !columnField ? dataSettings.pivot.rows : dataSettings.pivot.columns,
                true,
                dataSettings.pivot,
              ),
            ];
          }
        }
      }
    }

    if (tooltipSettings.displayTypes.indexOf(UiChartDataLabelDisplayType.LINK_VALUE) !== -1) {
      const value = formatTooltipValue(
        dataSettings.pivot.aggregations[0].alias,
        dataSettings.pivot.aggregations,
        format,
        tooltipParams.data.value,
      );

      result.push(value);
    }

    return result.join('<br/>');
  }
}
