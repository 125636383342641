import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { SelfaiAuthService } from '../auth';
import { PipelineFeatureEnableService, PipelineFeatureName } from '../config';

@Injectable({
  providedIn: 'root',
})
export class EnabledFeatureGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly selfaiAuthService: SelfaiAuthService,
    private readonly pipelineFeatureEnableService: PipelineFeatureEnableService,
  ) {
  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    try {
      return await this.isAccessAllowed(route);
    } catch (error) {
      throw new Error('An error happened during access validation. Details:' + error);
    }
  }

  async isAccessAllowed(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const isFeatureEnabled = this.pipelineFeatureEnableService.isEnabledFeature(route.routeConfig?.path as PipelineFeatureName)
    if (!isFeatureEnabled) {
      await this.router.navigate(['feature-is-disabled']);
    }
    return isFeatureEnabled;
  }
}
