import {
  ApplicationRef,
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { KtdGridComponent } from '@katoid/angular-grid-layout';

import {
  debounceTime,
  distinctUntilChanged,
  filter,
  fromEvent,
  merge,
  skip,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';

import {
  BoardDataSource,
  ChartSelectInfo,
  Dashboard,
  Datasource,
  Filter,
  LayoutMode,
  PresentationDashboard,
  TempDsStatus,
  WIDGET_CONTEXT_ID,
  Widget,
  WorkbookActionsService,
} from '@selfai-platform/bi-domain';
import { DestroyService, isNullOrUndefined } from '@selfai-platform/shared';
import { MenuMode, MenuService } from '@selfai-platform/shell';

import { ConfirmModalComponent } from '../common/component/modal/confirm/confirm.component';
import { Modal } from '../common/domain/modal';
import { EventBroadcaster } from '../common/event/event.broadcaster';
import { ImageService } from '../common/service/image.service';
import { PopupService } from '../common/service/popup.service';
import { DatasourceService } from '../datasource/service/datasource.service';

import { DashboardFiltersService } from '@selfai-platform/bi-chart-engine';
import { DashboardLayoutFilterService } from './component/dashboard-layout/dashboard-layout/dashboard-layot-filter.service';
import { DashboardLayoutComponent } from './component/dashboard-layout/dashboard.layout.component';
import { SelectionFilterComponent } from './component/selection-filter/selection-filter.component';
import { DashboardApiLegacyService } from './service/dashboard-api.service';
import { WidgetService } from './service/widget.service';
import { getMainDataSources } from './util';
import { DashboardUtil } from './util/dashboard.util';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['dashboard.component.scss'],
  providers: [
    DashboardLayoutFilterService,
    DestroyService,
    {
      provide: WIDGET_CONTEXT_ID,
      useValue: 'view',
    },
  ],
})
export class DashboardComponent extends DashboardLayoutComponent implements OnInit, OnDestroy {
  @ViewChild(SelectionFilterComponent)
  public selectionFilter: SelectionFilterComponent;

  @ViewChild(ConfirmModalComponent, { static: true })
  private _confirmModalComp: ConfirmModalComponent;

  @ViewChild('gridComponent')
  private ktGridComponent?: KtdGridComponent;

  public fullSizeWidget: Widget;

  public datasourceStatus: TempDsStatus = TempDsStatus.ENABLE;
  public enumStatus = TempDsStatus;

  public expiredDatasource: Datasource;
  public ingestionStatus: { progress: number; message: string; step?: number };

  public isError = false;
  public errorMsg: string;
  public ifWorkbook: boolean = window.location.pathname.includes('workbook');

  @Input()
  public inputDashboard: Dashboard;

  @Input() readonly = false;

  @Input()
  public standalone = false;

  @Input()
  public isManagementUser = false;

  @Output()
  public dashboardEvent = new EventEmitter<{ name: string; data?: any }>();

  get layoutComponents() {
    return DashboardUtil.getLayoutComponents(this.dashboard);
  }

  constructor(
    protected broadCaster: EventBroadcaster,
    protected widgetService: WidgetService,
    protected datasourceService: DatasourceService,
    protected popupService: PopupService,
    protected elementRef: ElementRef,
    protected injector: Injector,
    protected appRef: ApplicationRef,
    protected componentFactoryResolver: ComponentFactoryResolver,
    private readonly menuService: MenuService,
    private readonly destroy$: DestroyService,
    public imageService: ImageService,
    protected dashboardApiService: DashboardApiLegacyService,
    private readonly dashboardFiltersService: DashboardFiltersService,
    public readonly workbookActionsService: WorkbookActionsService,
  ) {
    super(
      broadCaster,
      widgetService,
      datasourceService,
      popupService,
      appRef,
      componentFactoryResolver,
      elementRef,
      injector,
      imageService,
      dashboardApiService,
    );
  }

  public ngOnInit() {
    this.menuService.isOpened$
      .pipe(
        skip(1),
        distinctUntilChanged(),

        debounceTime(300),
        withLatestFrom(this.menuService.menuMode$),
        filter(([, menuMode]) => menuMode === MenuMode.VERTICAL),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.ktGridComponent?.resize();
      });

    merge(fromEvent(window, 'resize'), fromEvent(window, 'orientationchange'))
      .pipe(
        debounceTime(50),
        tap(() => {
          this.ktGridComponent?.resize();
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();

    super.ngOnInit();

    this.subscriptions.push(
      this.broadCaster.on<any>('CHANGE_FILTER_WIDGET').subscribe((data) => {
        this.changeFilterWidgetEventHandler(data.filter);
      }),
    );
  }

  public ngOnChanges(changes: SimpleChanges) {
    const boardChanges: SimpleChange = changes.inputDashboard;
    if (boardChanges.currentValue) {
      this.dashboard = boardChanges.currentValue;
      this._initViewPage();
    }
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public onLayoutInitialised() {
    const ptBoardInfo: PresentationDashboard = <PresentationDashboard>this.dashboard;
    if (ptBoardInfo) {
      if (ptBoardInfo.selectionFilters) {
        ptBoardInfo.selectionFilters.forEach((item) => {
          this.selectionFilter.changeFilter(item);
        });
      }
    }
    this.dashboardEvent.emit({ name: 'LAYOUT_INITIALISED' });
  }

  public changeFilterWidgetEventHandler(filter: Filter) {
    this.dashboard = DashboardUtil.updateBoardFilter(this.dashboard, filter, true)[0];

    const boardFilters: Filter[] = DashboardUtil.getBoardFilters(this.dashboard);
    if (boardFilters && boardFilters.length > 0) {
      this.dashboardFiltersService.setDashboardFilters({ dashboardId: this.dashboard.id, filters: boardFilters });
    }
    this.selectionFilter.init();
  }

  public toggleFoldWorkbookDashboardList() {
    this.updateLayoutSize();
  }

  public getSelectedFilters(): ChartSelectInfo[] {
    return this.selectionFilter ? this.selectionFilter.getChartSelectionList() : [];
  }

  public confirm(modal: Modal) {
    if (modal.data.afterConfirm) {
      modal.data.afterConfirm.call(this);
    }
  }

  public showError(msg: string = this.translateService.instant('msg.board.unload.desc')) {
    this.isError = true;
    this.safelyDetectChanges();
    this.errorMsg = msg;
  }

  public hideError() {
    this.isError = false;
    this.safelyDetectChanges();
  }

  public reloadBoard() {
    this.dashboardEvent.emit({ name: 'RELOAD_BOARD' });
  }

  public reIngestion() {
    const modal = new Modal();
    modal.name = this.translateService.instant('msg.board.ui.ingestion.time');

    modal.data = {
      type: 'RE-INGEST',
      afterConfirm: () => {
        this.datasourceStatus = TempDsStatus.PREPARING;
        this.ingestionStatus = { progress: 0, message: '', step: 1 };
        this.showBoardLoading();
      },
    };
    this._confirmModalComp.init(modal);
  }

  // private _processIngestion(progressTopic: string) {
  //   try {
  //     const headers: any = { 'X-AUTH-TOKEN': this.cookieService.get(CookieConstant.KEY.LOGIN_TOKEN) };

  //     const subscription = CommonConstant.stomp.watch(progressTopic).subscribe((msg: Message) => {
  //       const data: { progress: number; message: string } = JSON.parse(msg.body);

  //       if (-1 === data.progress) {
  //         this.ingestionStatus = data;
  //         this.ingestionStatus.step = -1;
  //         this.alertPrimeService.error(data.message);
  //         this.changeDetect.markForCheck();
  //       } else if (100 === data.progress) {
  //         this.datasourceStatus = TempDsStatus.ENABLE;
  //         this.changeDetect.markForCheck();
  //         this._initViewPage();
  //         subscription.unsubscribe();
  //       } else {
  //         this.ingestionStatus = data;
  //         this.ingestionStatus.step = 2;
  //         this.changeDetect.markForCheck();
  //       }
  //     }, headers);
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }

  private _initViewPage() {
    0 === this.router.url.indexOf('/dashboard') && (this.standalone = true);

    this.fullSizeWidget = null;
    this.datasourceStatus = TempDsStatus.ENABLE;
    if (this.selectionFilter) {
      this.selectionFilter.resetFilter(false);
    }

    const dashboard = this.dashboard;
    if (dashboard) {
      const mainDsList: Datasource[] = getMainDataSources(dashboard);

      const linkedDsList: Datasource[] = []; //mainDsList.filter((item) => item.connType === ConnectionType.LINK);
      if (linkedDsList && 0 < linkedDsList.length) {
        this.showBoardLoading();

        const promises = [];

        linkedDsList.forEach((dsInfo) => {
          promises.push(
            new Promise<void>((res, rej) => {
              const boardDsInfo: BoardDataSource = DashboardUtil.findDataSourceOnBoard(dashboard, dsInfo);
              if (isNullOrUndefined(boardDsInfo['temporaryId'])) {
                rej('INVALID_LINKED_DATASOURCE');
                return;
              }
              this.datasourceService
                .getDatasourceDetail(boardDsInfo['temporaryId'])
                .then((ds: Datasource) => {
                  if (this.datasourceStatus || TempDsStatus.ENABLE !== this.datasourceStatus) {
                    ds.temporary && (this.datasourceStatus = ds.temporary.status);
                  }

                  if (ds.temporary && TempDsStatus.ENABLE === ds.temporary.status) {
                    boardDsInfo.metaDataSource = ds;
                    if (dashboard.configuration.filters) {
                      dashboard.configuration.filters = ds.temporary.filters.concat(dashboard.configuration.filters);
                    } else {
                      dashboard.configuration.filters = ds.temporary.filters;
                    }
                  } else {
                    this.expiredDatasource = ds;
                    this.hideBoardLoading();
                  }

                  res();
                })
                .catch((err) => rej(err));
            }),
          );
        });

        Promise.all(promises)
          .then(() => {
            if (TempDsStatus.ENABLE === this.datasourceStatus) {
              this._runDashboard(dashboard);
            } else {
              this.onLayoutInitialised();
              this.hideBoardLoading();
            }
            this.safelyDetectChanges();
          })
          .catch((error) => {
            if ('INVALID_LINKED_DATASOURCE' === error) {
              this.showError();
              this.onLayoutInitialised();
              this.hideBoardLoading();
            } else {
              this.commonExceptionHandler(error);
              this.onLayoutInitialised();
              this.hideBoardLoading();
            }
          });
      } else {
        this.showBoardLoading();
        this._runDashboard(dashboard);
      }
    } else {
      this.destroyDashboard();
    }
  }

  private _runDashboard(targetDashboard: Dashboard) {
    this.initializeDashboard(targetDashboard, this._getLayoutMode())
      .then(() => {
        this.safelyDetectChanges();
      })
      .catch((error) => {
        console.error(error);
        this.hideBoardLoading();
      });
  }

  private _getLayoutMode(): LayoutMode {
    if (this.standalone) {
      return LayoutMode.STANDALONE;
    } else if (this.isManagementUser) {
      return LayoutMode.VIEW_AUTH_MGMT;
    } else {
      return LayoutMode.VIEW;
    }
  }
}
