import { Injectable } from '@angular/core';
import { Observable, take } from 'rxjs';
import { PaginationData, StoreData } from '../interfaces';
import { DataListViewItem } from '../models/data-view-list-item.model';
import { calcPageNumber } from '../utils';
import { DataListViewBaseService } from './data-list-view-base.service';

@Injectable()
export abstract class DataListViewComponentService<T extends DataListViewItem>
  extends DataListViewBaseService<T>
  implements StoreData<T>, PaginationData
{
  onPageChange({ first, rows }: { first: number; rows: number }): void {
    this.pageParams$.pipe(take(1)).subscribe(({ sortField, sortOrder }) => {
      this.urlPageParamsService.setPageParams({
        pageNumber: calcPageNumber(first, rows),
        pageSize: rows,
        sortField,
        sortOrder,
      });
    });
  }

  protected getContextUpdate(): Observable<unknown> {
    return this.reloadTrigger$;
  }
}
