import { SelfaiAppModules } from '@selfai-platform/shared';
import { HomePageWidgets } from '../pages';
import { IShellConfigOptions } from './shell-config-options';

export class ShellConfig implements IShellConfigOptions {
  startPage: { widgets: HomePageWidgets[] };
  modules: SelfaiAppModules[];

  constructor(shellConfigOptions?: Partial<IShellConfigOptions>) {
    this.startPage = shellConfigOptions?.startPage || {
      widgets: [],
    };
    if (!shellConfigOptions?.startPage?.widgets) {
      this.startPage.widgets = [];
    }
    this.modules = shellConfigOptions?.modules || [];
  }
}
