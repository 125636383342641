import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'selfai-platform-workflow-settings-metadata',
  standalone: true,
  imports: [
    CommonModule,
    CardModule,
    ReactiveFormsModule,
    CardModule,
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    TooltipModule,
    TranslateModule,
  ],
  templateUrl: './workflow-settings-metadata.component.html',
  styleUrl: './workflow-settings-metadata.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowSettingsMetadataComponent implements OnChanges {
  isDisable: boolean = true;

  form: FormGroup = this.fb.group({
    name: [{ value: '', disabled: this.isDisable }, [Validators.required]],
    description: [{ value: '', disabled: this.isDisable }, []],
  });
  workflowData: any;
  constructor(private fb: FormBuilder, private changeDetectorRef: ChangeDetectorRef) {}

  @Input() workflow!: any;
  @Output() submitEvent = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['workflow']?.currentValue) {
      this.workflowData = this.workflow.workflow;
      this.form.setValue({ name: this.workflowData.name, description: this.workflowData.description });
    }
  }

  editChange(): void {
    this.isDisable = !this.isDisable;
    if (this.isDisable) {
      this.form.get('name').disable();
      this.form.get('description').disable();
    } else {
      this.form.get('name').enable();
      this.form.get('description').enable();
    }
    this.changeDetectorRef.detectChanges();
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.workflowData.name = this.form.value.name;
      this.workflowData.description = this.form.value.description;
      this.submitEvent.emit(this.workflowData);
      this.editChange();
    }
  }
}
