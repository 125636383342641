import { Injectable, OnDestroy } from '@angular/core';
import { LoaderUtil } from '@selfai-platform/shared';
import { Observable, Subject, Subscription, catchError, concatMap, of, take, tap } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { AggregatorProcessWithRoutes } from '../models/aggregator-process.model';
import { AggregatorAdapter } from './api';

@Injectable()
export class AggregatorProcessUpdateService extends LoaderUtil<void> implements OnDestroy {
  private queue = new Subject<AggregatorProcessWithRoutes>();
  private readonly subsciption = new Subscription();

  constructor(private readonly aggregatorAdapter: AggregatorAdapter) {
    super();

    this.subsciption.add(
      this.getQueue()
        .pipe(
          tap(() => {
            this.markAsLoading();
          }),
          concatMap((process) =>
            this.aggregatorAdapter.saveRoutes(process).pipe(
              catchError(() => {
                this.markAsError();

                return of();
              }),
            ),
          ),
        )
        .subscribe(() => {
          this.markAsSuccess();
        }),
    );
  }

  ngOnDestroy(): void {
    this.subsciption.unsubscribe();
  }

  saveProcess(processWithRoutes: AggregatorProcessWithRoutes): void {
    this.addQueue(processWithRoutes);
  }

  cloneProcess(uuid: string): void {
    this.aggregatorAdapter
      .loadProcessWithRoutes(uuid)
      .pipe(take(1))
      .subscribe((processWithRoutes) => {
        processWithRoutes.process.uuid = uuidv4();
        processWithRoutes.process.processName = `Copy ${processWithRoutes.process.processName}`;

        this.saveProcess(processWithRoutes);
      });
  }

  private addQueue(processWithRoutes: AggregatorProcessWithRoutes): void {
    this.queue.next(processWithRoutes);
  }

  private getQueue(): Observable<AggregatorProcessWithRoutes> {
    return this.queue.asObservable();
  }
}
