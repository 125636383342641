import { PivotField, UiChartFormatSettings } from '@selfai-platform/bi-domain';
import { formatValue } from './format-value';

export function formatTooltipValue(
  aliasValue: string,
  aggregations: PivotField[],
  format: UiChartFormatSettings,
  value: number,
  seriesName?: string,
): string {
  let seriesValue = '';

  let aggValue = aggregations.find((aggregation) => aggregation.alias === aliasValue);

  if (!aggValue && seriesName) {
    aggValue = aggregations.find((aggregation) => aggregation.alias === seriesName);
  }

  if (!aggValue) {
    // Handle the case where aggValue is not found
    return 'Aggregation not found';
  }

  const aggValueName = aggValue.fieldAlias ? aggValue.fieldAlias : aggValue.name;
  const defaultAlias = aggValue.aggregationType + '(' + aggValueName + ')';

  let aggregationType = '';
  if (aggValue.aggregationType) {
    aggregationType =
      aggValue.aggregationType.charAt(0).toUpperCase() + aggValue.aggregationType.slice(1).toLowerCase();
    if (aggregationType === 'Avg') aggregationType = 'Average';
    aggregationType += ' of ';
  }

  seriesValue =
    defaultAlias === aggValue.alias
      ? aggregationType + aggValueName + ' : ' + formatValue(value, format)
      : aggValue.alias + ' : ' + formatValue(value, format);

  return seriesValue;
}
