import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ErrorDialogComponent } from './error-dialog.component';

@NgModule({
  imports: [CommonModule, ButtonModule],
  declarations: [ErrorDialogComponent],
  exports: [ErrorDialogComponent],
})
export class ErrorDialogComponentModule {}
