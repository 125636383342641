import { Pipe, PipeTransform } from '@angular/core';

import moment from 'moment';

@Pipe({
  name: 'moment',
})
export class MomentPipe implements PipeTransform {
  transform(date: Date, type: string): any {
    const now = moment();

    if (type === 'isNew') {
      const diffDate = moment(date);

      return now.diff(diffDate, 'h') <= 23;
    }

    return date;
  }
}
