import { Injectable } from '@angular/core';
import {
  OperationCatalog,
  OperationCatalogItem,
  OperationTree,
  OperationTreeItem,
  OperationTreeType,
} from '@selfai-platform/pipeline-common';
import { AlertService } from '@selfai-platform/shared';
import { catchError, map, Observable, ReplaySubject, throwError } from 'rxjs';
import { handleHttpErrorResponse } from '../../../utils';
import { OperationsAdapter } from './operations.adapter';

@Injectable({
  providedIn: 'root',
})
export class OperationTreeService extends ReplaySubject<OperationTree> {
  constructor(private readonly operationsAdapter: OperationsAdapter, private readonly alertService: AlertService) {
    super(1);
  }

  loadTree(): void {
    this.operationsAdapter
      .loadCatalog()
      .pipe(
        map(({ catalog }) => {
          return catalog.map(this.mapCatalogToTree.bind(this));
        }),
        catchError((e: unknown) => {
          const { text } = handleHttpErrorResponse(e);
          this.alertService.error(text);

          return throwError(() => e);
        }),
      )
      .subscribe({ next: (tree: OperationTreeItem[]) => this.next(tree) });
  }

  getTree(): Observable<OperationTree> {
    return this.asObservable();
  }

  private mapCatalogToTree(catalog: OperationCatalog): OperationTreeItem {
    const treeNode: OperationTreeItem = {
      label: catalog.name,
      data: { id: catalog.id },
      type: OperationTreeType.CATEGORY,
    };

    if (catalog.catalog.length > 0) {
      treeNode.children = catalog.catalog.map(this.mapCatalogToTree.bind(this));
    } else if (catalog.items.length > 0) {
      treeNode.children = catalog.items.map(this.mapCatalogItemsToTree.bind(this));
    }

    return treeNode;
  }

  private mapCatalogItemsToTree(item: OperationCatalogItem): OperationTreeItem {
    return {
      label: item.name,
      data: { id: item.id },
      description: item.description,
      type: OperationTreeType.OPERATION,
    };
  }
}
