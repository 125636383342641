import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { DASHBOARD_FILTERS_ENTITY_NAME } from '../const';
import { DashboardFiltersState } from '../models';

@Injectable({ providedIn: 'root' })
export class DashboardFiltersStore extends EntityCollectionServiceBase<DashboardFiltersState> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super(DASHBOARD_FILTERS_ENTITY_NAME, serviceElementsFactory);
  }
}
