export enum ConnectionValid {
  ENABLE_CONNECTION = 0,
  DISABLE_CONNECTION = 1,
  REQUIRE_CONNECTION_CHECK = 2,
}
export enum InputMandatory {
  MANDATORY = 'MANDATORY',
  OPTIONAL = 'OPTIONAL',
  NONE = 'NONE',
}

export enum Scope {
  EMBEDDED = 'EMBEDDED',
  EXTENSION = 'EXTENSION',
}
