import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { map, Observable } from 'rxjs';
import { AggregatorProcessListItem, AggregatorProcessListState } from '../models/aggregator-process.model';
import { AggregatorProcessApiService } from './api';
import { AGGREGATOR_PROCESS_LIST_ENTITY_NAME } from '@selfai-platform/store';

@Injectable({ providedIn: 'root' })
export class AggregatorProcessListDataService extends DefaultDataService<AggregatorProcessListItem> {
  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private readonly aggregatorProcessApiService: AggregatorProcessApiService,
  ) {
    super(AGGREGATOR_PROCESS_LIST_ENTITY_NAME, http, httpUrlGenerator);
  }

  override getAll(): Observable<AggregatorProcessListState> {
    return this.aggregatorProcessApiService
      .loadProcessList()
      .pipe(
        map((processes) => processes.map((process) => ({ ...process, id: process.uuid, name: process.processName }))),
      );
  }
}
