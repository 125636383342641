import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MessageModule } from 'primeng/message';
import { SkeletonModule } from 'primeng/skeleton';
import { DialogHeaderComponentModule } from '../../dialog-header/dialog-header.module';
import { CmsFormCreatorComponent } from './cms-form-creator.component';
import { CmsFormComponentModule } from './cms-form/cms-form.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DialogHeaderComponentModule,
    MessageModule,
    SkeletonModule,
    CmsFormComponentModule,
  ],
  declarations: [CmsFormCreatorComponent],
  exports: [CmsFormCreatorComponent],
})
export class CmsFormCreatorComponentModule {}
