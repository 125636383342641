import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  CubeEditResult,
  CubeWorkflowData,
  ExpressionItem,
  ExpressionItemFormGroup,
  WorkflowExpressionData,
} from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { BehaviorSubject, Observable, filter, map } from 'rxjs';
import {
  extractSelectionItemsExpressionTool,
  normalizeDataExpressionTool,
} from '../../../converters/expression-tool.normalizer';
import { SelectionStoreService } from '../../../services/selection-store.service';

interface StoreData {
  nodeId: string;
  data: ExpressionItem[];
}

@Injectable()
export class ExpressionToolComponentService extends BehaviorSubject<StoreData> {
  constructor(
    private readonly fb: FormBuilder,
    dialogService: DialogService<CubeEditResult, CubeWorkflowData<WorkflowExpressionData>>,
    selectionStoreService: SelectionStoreService,
  ) {
    let data: StoreData = { data: {} } as StoreData;
    if (dialogService.data) {
      const {
        selectedNode: { id: nodeId, parameters },
      } = dialogService.data;
      if (parameters) {
        data = { nodeId, data: normalizeDataExpressionTool(parameters.serialize()) };

        const selectionColumns = extractSelectionItemsExpressionTool(parameters);
        if (selectionColumns) {
          selectionStoreService.setSelectionsColumns(selectionColumns);
        }
      }
    }

    super(data);
  }

  getItemFormGroups(): Observable<FormGroup<ExpressionItemFormGroup>[]> {
    return this.asObservable().pipe(
      filter(({ data }) => Boolean(data)),
      map(({ data }) => data.map(this.mapItemToFormGroup.bind(this))),
    );
  }

  getNodeId(): Observable<string> {
    return this.asObservable().pipe(map(({ nodeId }) => nodeId));
  }

  mapItemToFormGroup(item: ExpressionItem): FormGroup<ExpressionItemFormGroup> {
    return new FormGroup<ExpressionItemFormGroup>({
      id: new FormControl(item.id) as FormControl<string>,
      resultColumn: new FormControl(item.resultColumn, [Validators.required]),
      type: this.fb.group({
        kind: item.type.kind,
        divisionZeroStrategy: item.type.divisionZeroStrategy || null,
        sqlExpression: item.type.sqlExpression || null,
        sourceValue1: this.fb.group(
          item.type.sourceValue1 || {
            type: null,
            value: null,
          },
        ),
        sourceValue2: this.fb.group(
          item.type.sourceValue2 || {
            type: null,
            value: null,
          },
        ),
      }),
    });
  }
}
