import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ReactiveFormsModule } from '@angular/forms';
import { DialogService, provideDialogService } from '@selfai-platform/shell';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DataSourcesLibraryFilesListContainerComponent } from '../../containers/data-sources-library-files-list-container/data-sources-library-files-list-container.component';
import { DataSourcesFileFormatList } from '../../lists/data-sources-file-format.list';
import { DataSourcesFileSeparatorList } from '../../lists/data-sources-file-separator.list';
import { DataSourcesAdditionsComponent } from '../data-source-additions/data-sources-additions.component';
import { DataSourceUniqueNameComponent, DataSourceVisibilityComponent } from '../data-source-form-inputs';
import { DataSourcesBasic } from '../data-sources-basic';

@Component({
  selector: 'selfai-platform-data-sources-library',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DropdownModule,
    InputTextModule,
    ButtonModule,
    CheckboxModule,
    RadioButtonModule,
    DataSourcesAdditionsComponent,
    DataSourcesLibraryFilesListContainerComponent,
    DataSourceUniqueNameComponent,
    DataSourceVisibilityComponent,
  ],
  providers: [provideDialogService()],
  templateUrl: './data-sources-library.component.html',
  styleUrls: ['./data-sources-library.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataSourcesLibraryComponent extends DataSourcesBasic {
  format = DataSourcesFileFormatList;
  separators = DataSourcesFileSeparatorList;
  paramsForm = this.fb.group({
    libraryPath: [''],
    fileFormat: ['csv'],
    csvFileFormatParams: this.fb.group({
      convert01ToBoolean: [false],
      includeHeader: [false],
      separatorType: ['comma'],
      customSeparator: [''],
    }),
  });

  constructor(private dialogService: DialogService) {
    super('libraryFile');
  }

  onShowDialog(): void {
    this.dialogService
      .showDialog(DataSourcesLibraryFilesListContainerComponent, {
        header: 'Select data frame',
        width: '70%',
        contentStyle: { overflow: 'auto' },
        baseZIndex: 10000,
        maximizable: true,
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((name: string) => {
        this.paramsForm.patchValue({ libraryPath: name });
      });
  }
}
