export function downloadJson(data: object, fileName: string): void {
  const srcData = 'text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(data));

  const element = document.createElement('a');
  element.setAttribute('href', `data:${srcData}`);
  element.setAttribute('download', fileName);
  element.style.visibility = 'hidden';

  document.body.appendChild(element);
  element.dispatchEvent(new MouseEvent('click'));
  element.remove();
}
