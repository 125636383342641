import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { FormFieldComponentModule } from '../../../../form-field/form-field.module';
import { StepOutputComponent } from './step-output.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, DropdownModule, InputTextModule, FormFieldComponentModule],
  declarations: [StepOutputComponent],
  exports: [StepOutputComponent],
})
export class StepOutputComponentModule {}
