import { ChartType, UiSettings } from '@selfai-platform/bi-domain';
import { graphChartInitialSettings } from '../const';

// TODO: need to implement the logic of OptionGenerator.initUiOption
export function getInitialSettings(type: ChartType): UiSettings {
  switch (type) {
    case ChartType.GRAPH:
    case ChartType.GRAPHV2:
      return graphChartInitialSettings;

    default:
      return { type };
  }
}
