import { Injectable } from '@angular/core';
import { MarkLineSetting, SearchQueryPivotOptionsV2 } from '@selfai-platform/bi-domain';
import { ChartData } from '../chart-data.service';

@Injectable({ providedIn: 'root' })
export class CombineChartMarkLineService {
  getOptionsForSearchQuery(markLines: MarkLineSetting[]): Map<string, SearchQueryPivotOptionsV2> {
    const mapOptions = new Map<string, SearchQueryPivotOptionsV2>();

    markLines.forEach((markLine) => {
      const options: SearchQueryPivotOptionsV2 = {
        includeAvg: false,
        percentileRanks: [],
      };

      switch (markLine.markLineType) {
        case 'average':
          options.includeAvg = true;
          break;
        default:
        case 'percentile':
          options.percentileRanks.push(markLine.percentileAmount / 100);
          break;
      }

      mapOptions.set(markLine.measureName, options);
    });

    return mapOptions;
  }

  extractMarkValues(markLinesSetting: MarkLineSetting[], searchQueryResponse: ChartData): number[] {
    return markLinesSetting
      .map((markLine) => {
        switch (markLine.markLineType) {
          case 'percentile':
            return searchQueryResponse.info.totalPercentile?.find(({ measure }) => measure === markLine.measureName)
              ?.rankToValue[(markLine.percentileAmount / 100).toString()];
          default:
          case 'average':
            return searchQueryResponse.info.totalAvg?.find(({ measure }) => measure === markLine.measureName)?.value;
        }
      })
      .filter(Boolean);
  }
}
