import { Injectable } from '@angular/core';
import { Pivot, PivotField, Shelf } from '@selfai-platform/bi-domain';
import { omit } from 'lodash';
import { BehaviorSubject } from 'rxjs';

interface ChartConfiguration {
  pivot: Pivot;
  shelf: Shelf;
}

/**
 * @deprecated Use DataConfigService instead
 */
@Injectable()
export class ChartConfigService extends BehaviorSubject<ChartConfiguration> {
  constructor() {
    super({} as ChartConfiguration);
  }

  setConfig(config: ChartConfiguration): void {
    this.next(config);
  }

  // TODO: should use observable instead of getValue()
  getConfig(): ChartConfiguration {
    return this.getValue();
  }

  isValid(): boolean {
    return true;
  }

  clearColumnsData(): void {
    const config = this.getValue();
    this.next({ ...config, pivot: { ...config.pivot, columns: config.pivot.columns.map(this.clearData.bind(this)) } });
  }

  clearAggregationsData(): void {
    const config = this.getValue();
    this.next({
      ...config,
      pivot: { ...config.pivot, aggregations: config.pivot.aggregations.map(this.clearData.bind(this)) },
    });
  }

  private clearData(field: PivotField): PivotField {
    return omit(field, ['data']);
  }
}
