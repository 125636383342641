import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { DatasourceType } from '@selfai-platform/pipeline-common';
import { HistoryBackService } from '@selfai-platform/shared';
import { KE_DATASOURCES_PATH, KE_ROOT_ROUTE } from '@selfai-platform/shell';
import { Observable, switchMap, take } from 'rxjs';
import { DataSourcesComponent } from '../../components/data-sources/data-sources.component';
import { DataSource } from '../../model';
import { DataSourcesAdditions } from '../../model/data-sources-additions.model';
import { DataSourceAdditionsDomainService, DataSourceDomainService, DataSourceListDomainService } from '../../services';

@Component({
  selector: 'selfai-platform-data-sources-container',
  standalone: true,
  imports: [CommonModule, DataSourcesComponent],
  templateUrl: './data-sources-container.component.html',
  styleUrls: ['./data-sources-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataSourcesContainerComponent implements OnInit {
  dataSources$: Observable<DataSource[]> = this.dataSourceListDomainService.getDataSourceList();
  dataSource$?: Observable<DataSource>;
  loading$ = this.dataSourceDomainService.getDataSourceLoading();
  dataSourcesAdditions$: Observable<DataSourcesAdditions[]> = this.dataSourceAdditionsDomainService.getAdditionParams();
  additionsLoading$: Observable<boolean> = this.dataSourceAdditionsDomainService.getAdditionParamsLoading();
  id: string = this.activatedRoute.snapshot?.params['id'];
  editMode = !!this.id;
  type: DatasourceType = this.activatedRoute.snapshot?.params['type'];

  constructor(
    private activatedRoute: ActivatedRoute,
    private historyBackService: HistoryBackService,
    private dataSourceDomainService: DataSourceDomainService,
    private dataSourceListDomainService: DataSourceListDomainService,
    private dataSourceAdditionsDomainService: DataSourceAdditionsDomainService,
    private destroyRef: DestroyRef,
  ) {
    if (this.editMode) {
      this.dataSource$ = dataSourceDomainService.getDataSource(this.id);
    }
  }

  ngOnInit(): void {
    this.dataSourceListDomainService
      .loadDataSourceList()
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe();

    if (this.editMode) {
      this.dataSourceDomainService
        .loadDataSource(this.id)
        .pipe(
          take(1),
          switchMap((dataSource) =>
            this.dataSourceAdditionsDomainService.loadAdditionParams(dataSource.params.datasourceType).pipe(take(1)),
          ),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe();
    } else {
      this.dataSourceAdditionsDomainService
        .loadAdditionParams(this.type)
        .pipe(take(1), takeUntilDestroyed(this.destroyRef))
        .subscribe();
    }
  }

  onBack(): void {
    this.historyBackService.back(['/', KE_ROOT_ROUTE, KE_DATASOURCES_PATH]);
    this.dataSourceListDomainService.loadDataSourceList();
  }
}
