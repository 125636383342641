import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { WorkflowInfo } from '@selfai-platform/pipeline-common';
import { WorkflowListItem } from '@selfai-platform/pipeline-module';
import { SchedulerPermissionAction, UrlPageParamsService } from '@selfai-platform/shared';
import {
  convertMessageToHtml,
  DataListViewComponentService,
  GroupAction,
  KE_ROOT_ROUTE,
  KE_SCHEDULES_PATH,
  MenuItemsNormalizerService,
  SelectedItemsService,
  TableColumn,
} from '@selfai-platform/shell';
import { ConfirmationService } from 'primeng/api';
import { combineLatest, map, Observable, take } from 'rxjs';
import { WorkflowPresets } from '../../workflow-presets';
import { SCHEDULES_TABLE_COLUMNS_CONFIG } from '../constants/schedules-table-config';
import { SchedulesListItem } from '../models';
import { SchedulesPresetsListStore } from '../store/presets-list.store';
import { SchedulesWorkflowsListStore } from '../store/workflows-list.store';
import { SchedulesListDomainService } from './schedules-list-domain.service';
import { SchedulesPermissionService } from './schedules-permission.service';

@Injectable({
  providedIn: 'root',
})
export class SchedulesDataListViewService extends DataListViewComponentService<SchedulesListItem> {
  constructor(
    private readonly schedulesListDomainService: SchedulesListDomainService,
    private readonly schedulesWorkflowsListStore: SchedulesWorkflowsListStore,
    private readonly schedulesPresetsListStore: SchedulesPresetsListStore,
    private readonly schedulesPermissionService: SchedulesPermissionService,
    private readonly confirmationService: ConfirmationService,
    private readonly translate: TranslateService,
    private readonly router: Router,
    private readonly menuItemsNormalizerService: MenuItemsNormalizerService,
    urlPageParamsService: UrlPageParamsService,
    selectedItemService: SelectedItemsService<SchedulesListItem>,
  ) {
    super(urlPageParamsService, selectedItemService);
  }

  override loadData(): Observable<{ items: SchedulesListItem[]; totalItems: number }> {
    return this.schedulesListDomainService.loadSchedulesList().pipe(
      map(({ schedules }) => ({
        items: schedules,
        totalItems: schedules.length,
      })),
    );
  }

  override getData(): Observable<SchedulesListItem[]> {
    return combineLatest({
      presets: this.schedulesPresetsListStore.entities$ as Observable<WorkflowPresets[]>,
      workflows: this.schedulesWorkflowsListStore.entities$ as Observable<WorkflowListItem[]>,
      schedules: this.schedulesListDomainService.getSchedulesList(),
      schedulesPermissionGet: this.schedulesPermissionService.checkPermission(SchedulerPermissionAction.Get),
      schedulesPermissionUpdate: this.schedulesPermissionService.checkPermission(SchedulerPermissionAction.Update),
    }).pipe(
      map(({ presets, workflows, schedules, ...permissions }) =>
        schedules.map((item) => this.normalizeForView(item, workflows, presets, { ...permissions })),
      ),
    );
  }

  override isLoading(): Observable<boolean> {
    return this.schedulesListDomainService.getSchedulesListLoading();
  }

  override isLoaded(): Observable<boolean> {
    return this.schedulesListDomainService.getSchedulesListLoaded();
  }

  override hasError(): Observable<boolean> {
    return this.schedulesListDomainService.getSchedulesListError().pipe(map((error) => !!error));
  }

  public getColumns(): TableColumn<SchedulesListItem>[] {
    return SCHEDULES_TABLE_COLUMNS_CONFIG as TableColumn<SchedulesListItem>[];
  }

  public getGroupActions(): Observable<GroupAction[]> {
    return this.schedulesPermissionService.checkPermission(SchedulerPermissionAction.Delete).pipe(
      map((permission) => {
        if (permission) {
          return [
            {
              tooltipTranslate: 'schedules-list.toolbar.actions.delete-selected',
              icon: 'pi pi-trash',
              buttonClass: 'p-button-danger',
              action: () => this.deleteSelected(),
            },
          ];
        } else {
          return [];
        }
      }),
    );
  }

  private deleteSelected(): void {
    this.confirmationService.confirm({
      key: 'dialog',
      message: convertMessageToHtml(
        this.translate.instant('schedules-list.toolbar.actions.delete-selected.confirmation'),
      ),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.selectedItemsService
          .getSelectedItemIds()
          .pipe(take(1))
          .subscribe((ids) => {
            ids.forEach((id) => {
              this.schedulesListDomainService.deleteSchedule(id);
            });
          });
      },
    });
  }

  private normalizeForView(
    schedule: SchedulesListItem,
    workflows: WorkflowInfo[],
    presets: WorkflowPresets[],
    permissions: Record<string, boolean>,
  ): SchedulesListItem {
    let normalized = {
      ...schedule,
      name: schedule.schedule.cron,
      workflowName: workflows?.find((workflow) => workflow.id === schedule.workflowId)?.name,
      presetName: presets?.find((preset) => preset.id === schedule.executionInfo.presetId)?.name,
      cron: schedule.schedule.cron,
      timezone: schedule.schedule.timezone,
      presets,
      icon: 'pi pi-calendar-clock',
      iconClass: 'text-indigo-500',
    };
    if (permissions?.['schedulesPermissionGet']) {
      normalized = {
        ...normalized,
        routerLinkToItem: {
          routerLink: ['/', KE_ROOT_ROUTE, KE_SCHEDULES_PATH, 'edit', schedule.id],
        },
      };
    }
    return normalized;
  }
}
