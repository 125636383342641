import { AggregationType } from '../../../../datasource';
import { Filter, createFilter } from './filter.model';

export interface MeasurePositionFilter extends Filter {
  aggregation?: AggregationType;
  position?: PositionType;
  value?: number;
}

export function createMeasurePositionFilter(params: Partial<MeasurePositionFilter> = {}): MeasurePositionFilter {
  return {
    ...createFilter(),
    type: 'measure_position',
    ...params,
  };
}

export enum PositionType {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}
