<div class="p-dialog-header sticky px-0 top-0 bg-white z-1 mb-3">
  <span class="p-dialog-title">
    {{ "msg.board.ui.data.join" | translate }}
  </span>

  <div class="p-dialog-header-icons">
    <button
      type="button"
      class="mr-3 p-button-outlined p-button-secondary"
      (click)="closeDialog()"
      tabindex="-1"
      pRipple
      pButton
      label="{{ 'msg.comm.btn.cancl' | translate }}"
    ></button>
    <button
      type="button"
      class="mr-3 p-button"
      (click)="completeJoin()"
      tabindex="-1"
      pRipple
      pButton
      label="{{ 'msg.board.ui.data.join' | translate }}"
    ></button>
  </div>
</div>

<div class="content bi-incapsulation">
  <div [style.bottom]="editingJoin.joinInfoList.length !== 0 ? '' : '0px'">
    <div class="flex">
      <div class="col-6">
        <div class="ddp-ui-pop-top">
          <span class="ddp-txt-title">{{
            "msg.board.ui.master.dsource" | translate
          }}</span>
        </div>

        <div class="data-source-name-container">
          <span class="ddp-data-name">
            <em class="ddp-icon-wrangled-m"></em>
            {{ joinPopupLeftName() }}
          </span>
          <ul class="grid-view">
            <li
              [class.selected]="editingJoin.viewModeForLeft === 'grid'"
              (click)="editingJoin.viewModeForLeft = 'grid'"
            >
              <a href="javascript:"><em class="ddp-icon-grid"></em></a>
              <div class="ddp-ui-tooltip-info">
                <em class="ddp-icon-view-top"></em>
                {{ "msg.comm.ui.list.grid.view" | translate }}
              </div>
            </li>
            <li
              [class.selected]="editingJoin.viewModeForLeft === 'table'"
              (click)="editingJoin.viewModeForLeft = 'table'"
            >
              <a href="javascript:"><em class="ddp-icon-listgrid"></em></a>
              <div class="ddp-ui-tooltip-info">
                <em class="ddp-icon-view-top"></em>
                {{ "msg.comm.ui.list.list.view" | translate }}
              </div>
            </li>
          </ul>
        </div>

        <div class="grid-container flow-auto">
          <div
            grid-component
            #leftGrid
            style="width: 100%; height: 100%"
            [hidden]="editingJoin.viewModeForLeft === 'table'"
          ></div>

          <div
            class="ddp-wrap-scroll"
            *ngIf="editingJoin.viewModeForLeft === 'table'"
          >
            <table class="ddp-table-grid-list">
              <colgroup>
                <col width="60px" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr
                  *ngFor="let field of getGridFields(editingJoin.left?.uiFields)"
                >
                  <td class="ddp-txt-center">
                    <selfai-bi-shared-field-icon
                      [type]="field.logicalType?.toString()"
                      [view]="'FIELD'"
                    ></selfai-bi-shared-field-icon>
                  </td>
                  <td>{{ field.name }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="ddp-ui-pop-top">
          <span class="ddp-txt-title">{{
            "msg.board.ui.dsource.join" | translate
          }}</span>
        </div>

        <div class="flex justify-content-between">
          <div
            class="ddp-wrap-drop-search"
            [class.ddp-selected]="editingJoin.isJoinDatasourceFl"
            (click)="openOptionsJoinDatasources()"
          >
            <div class="ddp-type-selectbox2">
              <span class="ddp-txt-selectbox ddp-result">
                {{
                  editingJoin.right
                    ? editingJoin.right.name
                    : ("msg.board.ui.please.choose.dsource" | translate)
                }}
              </span>
            </div>

            <div class="ddp-ui-drop-search">
              <input
                id="joinSearchText"
                type="text"
                class="ddp-input-search"
                placeholder="{{ 'msg.board.ui.search' | translate }}"
                [(ngModel)]="editingJoin.joinSearchText"
                (focus)="editingJoin.isJoinDatasourceFl = true"
                (keyup.esc)="editingJoin.isJoinDatasourceFl = false"
              />
              <ul
                class="ddp-list-selectbox2"
                (clickOutside)="editingJoin.isJoinDatasourceFl = false"
                [excludeBeforeClick]="true"
                [exclude]="'.ddp-wrap-drop-search'"
              >
                <li
                  *ngFor="let candidateDS of filteredCandidateDatasources"
                  (click)="loadDataToRightJoinGrid(candidateDS)"
                  [innerHTML]="
                    highlightSearchText(
                      candidateDS.name,
                      editingJoin.joinSearchText
                    )
                  "
                ></li>
              </ul>
            </div>
          </div>

          <ul class="grid-view" [class.ddp-disabled]="!editingJoin.right">
            <li
              [class.selected]="editingJoin.viewModeForRight === 'grid'"
              (click)="editingJoin.viewModeForRight = 'grid'"
            >
              <a href="javascript:"><em class="ddp-icon-grid"></em></a>
              <div class="ddp-ui-tooltip-info">
                <em class="ddp-icon-view-top"></em>
                {{ "msg.comm.ui.list.grid.view" | translate }}
              </div>
            </li>
            <li
              [class.selected]="editingJoin.viewModeForRight === 'table'"
              (click)="editingJoin.viewModeForRight = 'table'"
            >
              <a href="javascript:"><em class="ddp-icon-listgrid"></em></a>
              <div class="ddp-ui-tooltip-info">
                <em class="ddp-icon-view-top"></em>
                {{ "msg.comm.ui.list.list.view" | translate }}
              </div>
            </li>
          </ul>
        </div>

        <div class="grid-container overflow-auto">
          <div class="data-none" *ngIf="!editingJoin.right">
            {{ "msg.board.ui.please.choose.dsource" | translate }}
          </div>

          <div
            grid-component
            #rightGrid
            style="width: 100%; height: 100%"
            [hidden]="editingJoin.viewModeForRight === 'table'"
          ></div>

          <div
            class="ddp-wrap-scroll"
            *ngIf="editingJoin.viewModeForRight === 'table'"
          >
            <table class="ddp-table-grid-list">
              <colgroup>
                <col width="60px" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr
                  *ngFor="let field of getGridFields(editingJoin.right?.uiFields)"
                >
                  <td class="ddp-txt-center">
                    <selfai-bi-shared-field-icon
                      [type]="field.logicalType?.toString()"
                      [view]="'FIELD'"
                    ></selfai-bi-shared-field-icon>
                  </td>
                  <td>{{ field.name }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="ddp-view-joinkeys">
        <div class="flex align-items-center">
          <p-dropdown
            [autoDisplayFirst]="false"
            class="left-dropdown"
            appendTo="body"
            [filter]="true"
            filterBy="name"
            optionLabel="name"
            optionValue="name"
            [disabled]="joinCandidateKeysOptionsLeft.length === 0"
            [options]="joinCandidateKeysOptionsLeft"
            (onChange)="selectJoinColumn($event.value, 'left')"
          ></p-dropdown>

          <span class="equal-delimetr">=</span>

          <p-dropdown
            [autoDisplayFirst]="false"
            class="right-dropdown"
            appendTo="body"
            [filter]="true"
            filterBy="name"
            optionLabel="name"
            optionValue="name"
            [disabled]="joinCandidateKeysOptionsRight.length === 0"
            [options]="joinCandidateKeysOptionsRight"
            (onChange)="selectJoinColumn($event.value, 'right')"
          ></p-dropdown>
        </div>

        <a
          (click)="addToJoinKeys()"
          [class.ddp-disabled]="!this.isValidJoinKeys()"
          href="javascript:"
          class="ddp-btn-solid"
        >
          {{ "msg.board.ui.add.join.keys" | translate }}
        </a>
      </div>

      <span class="ddp-data-info" style="display: none">
        The selection has matched 400,000 out of the first
        <strong>400,000</strong>
        rows
      </span>
      <div class="ddp-ui-error">
        <div *ngIf="editingJoin.joinChooseColumnErrorFl">
          <span class="ddp-txt-error">
            <em class="ddp-icon-error"></em>
            {{ "msg.board.ui.join.column.error" | translate }}
          </span>
        </div>
        <span
          class="ddp-txt-error"
          *ngIf="editingJoin.joinChooseColumnErrorFl === false"
          >&nbsp;</span
        >
      </div>
    </div>
  </div>

  <div *ngIf="editingJoin.joinInfoList.length !== 0" class="ddp-ui-join-type">
    <div class="ddp-ui-data-join">
      <div class="ddp-data-join">
        <span class="ddp-ui-label">{{
          "msg.board.th.join.type" | translate
        }}</span>
        <ul class="ddp-list-join">
          <li
            [class.ddp-selected]="editingJoin.selectedJoinType === 'inner'"
            (click)="changeJoinType('inner')"
          >
            <a href="javascript:">
              <em class="ddp-icon-joininner"></em>
              {{ "msg.board.ui.join.type.inner" | translate }}
            </a>
          </li>
          <li
            [class.ddp-selected]="editingJoin.selectedJoinType === 'left'"
            (click)="changeJoinType('left')"
          >
            <a href="javascript:">
              <em class="ddp-icon-joinleft"></em>
              {{ "msg.board.ui.join.type.left" | translate }}
            </a>
          </li>
          <li class="ddp-disabled">
            <a href="javascript:">
              <em class="ddp-icon-joinright"></em>
              {{ "msg.board.ui.join.type.right" | translate }}
            </a>
          </li>
          <li class="ddp-disabled">
            <a href="javascript:">
              <em class="ddp-icon-joinouter"></em>
              {{ "msg.board.ui.join.type.fullouter" | translate }}
            </a>
          </li>
        </ul>
      </div>

      <div class="ddp-data-join">
        <span class="ddp-ui-label">
          {{
            "msg.board.ui.join.keys"
              | translate: { value: editingJoin.joinInfoList.length }
          }}
        </span>

        <div
          class="ddp-ui-nodata"
          *ngIf="editingJoin.joinInfoList.length === 0"
        >
          {{ "msg.board.ui.join.key.none" | translate }}
        </div>

        <ul
          class="ddp-list-form-join"
          *ngIf="editingJoin.joinInfoList.length !== 0"
        >
          <li *ngFor="let join of editingJoin.joinInfoList; let idx = index">
            <div class="ddp-form-join">
              <span class="ddp-data-column">{{ join.leftJoinKey }}</span>
              <span class="ddp-data-default">=</span>
              <span class="ddp-data-column">{{ join.rightJoinKey }}</span>
            </div>
            <em
              class="ddp-icon-control-cut"
              (click)="removeJoinInfoList(idx)"
            ></em>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div
    *ngIf="editingJoin.joinInfoList.length !== 0"
    class="ddp-box-resultpreview"
  >
    <div class="ddp-ui-pop-top">
      <span class="ddp-txt-title">{{
        "msg.board.ui.join.preview.rslt" | translate
      }}</span>
      <div class="ddp-wrap-grid-option">
        <div class="ddp-part-right">
          <div class="ddp-data-form">
            <strong>{{ editingJoin.columnCnt }}</strong>
            {{ "msg.comm.detail.columns" | translate }}
          </div>
          <div class="ddp-data-form">
            <component-input
              [value]="editingJoin.rowNum"
              [valueType]="'number'"
              (changeValue)="setRowPreviewGrid($event)"
            ></component-input>
            {{ "msg.comm.detail.rows" | translate }}
          </div>
          <div
            (click)="isShowTypes = !isShowTypes"
            (clickOutside)="isShowTypes = false"
            class="ddp-data-form"
          >
            <strong class="ddp-cursor">{{
              objKeyList(logicalTypeMap).length
            }}</strong>
            Types

            <div *ngIf="isShowTypes" class="ddp-wrap-popup2 ddp-types">
              <ul class="ddp-list-popup">
                <li *ngFor="let type of objKeyList(logicalTypeMap)">
                  <a href="javascript:">
                    <selfai-bi-shared-field-icon
                      [type]="type"
                      [view]="'FIELD'"
                    ></selfai-bi-shared-field-icon>
                    {{ logicalTypeMap[type] }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="grid-container">
      <div class="ddp-data-empty" *ngIf="isEmptyPreviewGrid">
        <span class="ddp-data-contents">{{
          "msg.space.ui.no.data" | translate
        }}</span>
      </div>

      <div grid-component #joinPreview style="width: 100%; height: 100%"></div>
    </div>
  </div>
</div>
