import { Injectable } from '@angular/core';
import { PermissionService, PipelinePermission } from '@selfai-platform/shared';
import { concatAll, map, Observable, of, take } from 'rxjs';
import { KeCurrentUserStore } from '../../current-user';
import { EntitySelectorsFactory } from '@ngrx/data';
import { Profile } from '@selfai-platform/pipeline-common';

@Injectable({
  providedIn: 'root',
})
export class DataSourcePermissionService {
  private currentUser$ = this.currentUserStore
    .store
    .select((new EntitySelectorsFactory().create<Profile>('keCurrentUser')).selectEntities)
    .pipe(
      concatAll(),
      take(1)
    );

  constructor(
    private readonly permissionService: PermissionService,
    private readonly currentUserStore: KeCurrentUserStore,
  ) {
    this.currentUserStore.getByKey('id');
  }

  public hasCurrentPermission(permission: PipelinePermission): boolean {
    return this.getDatasourcePermissions().includes(permission);
  }

  public canCreateSource(): boolean {
    return this.hasCurrentPermission(PipelinePermission.DatasourcesCreate);
  }

  public canViewSource(sourceOwnerId: string): Observable<boolean> {
    if (this.hasCurrentPermission(PipelinePermission.DatasourcesGetAny)) {
      return of(true);
    }

    return this.equalCurrentUser(sourceOwnerId).pipe(
      map((equalCurrentUser) =>
        equalCurrentUser && this.hasCurrentPermission(PipelinePermission.DatasourcesGetOwn))
    );
  }

  public canUpdateSource(sourceOwnerId: string): Observable<boolean> {
    if (this.hasCurrentPermission(PipelinePermission.DatasourcesUpdateAny)) {
      return of(true);
    }

    return this.equalCurrentUser(sourceOwnerId).pipe(
      map((equalCurrentUser) => equalCurrentUser && this.hasCurrentPermission(PipelinePermission.DatasourcesUpdateOwn)),
    );
  }

  public canDeleteSource(sourceOwnerId: string): Observable<boolean> {
    if (this.hasCurrentPermission(PipelinePermission.DatasourcesDeleteAny)) {
      return of(true);
    }

    return this.equalCurrentUser(sourceOwnerId).pipe(
      map((equalCurrentUser) => equalCurrentUser && this.hasCurrentPermission(PipelinePermission.DatasourcesDeleteOwn)),
    );
  }

  private getDatasourcePermissions(): PipelinePermission[] {
    return this.permissionService.getPipelinePermissions().filter((t) => t.startsWith('datasources'));
  }

  private equalCurrentUser(userId: string): Observable<boolean> {
    return (<Observable<Profile>>this.currentUser$).pipe(map((data) => data.id === userId));
  }
}
