import { Injectable } from '@angular/core';
import {
  GraphNodeUpdatePropertyOptions,
  ThirdPartyDataGuiNode,
  WorkflowApi,
  WorkflowSerialized,
} from '@selfai-platform/pipeline-common';
import { AlertService } from '@selfai-platform/shared';
import { Observable, catchError, map, throwError } from 'rxjs';
import { WorkflowEditorFacadeService } from '../../../workflow-editor/services/workflow-editor-facade.service';
import { WorkflowApiService } from './workflow-api.service';

@Injectable({
  providedIn: 'root',
})
export class WorkflowDetailService {
  constructor(
    private readonly workflowApiService: WorkflowApiService,
    private readonly alertService: AlertService,
    private readonly workflowEditorFacadeService: WorkflowEditorFacadeService,
  ) {}

  loadWorkflow(workflowId: string): Observable<WorkflowSerialized & Omit<WorkflowApi, 'wofkflow'>> {
    return this.workflowApiService.loadWorkflow(workflowId).pipe(
      map((workflow) => ({
        ...workflow,
        workflow: {
          ...workflow.workflow,
          nodes: workflow.workflow.nodes.map((node) => {
            const guiData: ThirdPartyDataGuiNode = (workflow.thirdPartyData.gui.nodes[node.id] ||
              {}) as ThirdPartyDataGuiNode;

            return {
              id: node.id,
              operation: node.operation,
              parameters: node.parameters,
              uiName: guiData.uiName || '',
              color: guiData.color || '',
            };
          }),
        },
      })),
      catchError((e: unknown) => {
        this.alertService.error('Error loading workflow');

        return throwError(() => e);
      }),
    );
  }

  updateCubeUiName(data: GraphNodeUpdatePropertyOptions): void {
    this.workflowEditorFacadeService.updateNodeProperties(data);
  }
}
