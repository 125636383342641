import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
  LocalStorageKey,
  PREFIX_SETTINGS_LOCAL_STORAGE_KEY,
  getDataFromLocalStorage,
  saveDataToLocalStorage,
} from '@selfai-platform/shared';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LayoutType } from '../models';

@Injectable()
export class LayoutTypeService implements OnDestroy {
  readonly selectedLayoutType$ = new BehaviorSubject<LayoutType>(
    getDataFromLocalStorage<LayoutType>(this.getLocalStorageKey(), LayoutType.Table),
  );

  private subscription = new Subscription();

  constructor(
    @Inject(PREFIX_SETTINGS_LOCAL_STORAGE_KEY) private readonly prefixSettingsLocalStorageKey: LocalStorageKey,
  ) {
    this.subscription.add(
      this.selectedLayoutType$.subscribe((layoutType) => {
        saveDataToLocalStorage(this.getLocalStorageKey(), layoutType);
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  changeLayoutType(valueType: 'table' | 'grid'): void {
    this.selectedLayoutType$.next(valueType);
  }

  private getLocalStorageKey(): `${LocalStorageKey}_layoutType` {
    return `${this.prefixSettingsLocalStorageKey}_layoutType`;
  }
}
