import { FormArray, FormControl, FormGroup } from '@angular/forms';

export interface CreateDataFrameParameters {
  dataSourceId: string | null;
  dataOfFrame: Record<string, string | number | boolean>[] | null;
  columnMapping: CreateDataFrameColumnMapping[] | null;
}

export interface CreateDataFrameFormGroup {
  dataSourceId: FormControl<string | null>;
  dataOfFrame: FormControl<Record<string, string | number | boolean>[] | null>;
  columnMapping: FormArray<FormGroup<CreateDataFrameColumnMappingFormGroup>>;
}

export interface CreateDataFrameColumnMappingFormGroup {
  id: FormControl<string>;
  columnName: FormControl<string>;
  columnType: FormControl<CreateDataColumnType>;
}

export interface CreateDataFrameColumnMapping {
  id: string;
  columnName: string;
  columnType: CreateDataColumnType | null;
}

export interface CreateDataFrameRawParameters {
  // Data source uuid
  'data source': string | null;
  'Column mappings of dataframe': CreateDataFrameColumnMappingRawParameterValues[] | null;
  'Data of dataframe': string | null;
}

export interface CreateDataFrameColumnMappingRawParameterValues {
  'Column name': string;
  'Column type': Partial<Record<CreateDataColumnApiType, {}>> | null;
}

export const CreateDataColumnApiType = {
  Boolean: 'boolean',
  String: 'string',
  Integer: 'int',
  Float: 'float',
  BigInteger: 'bigint',
  Numeric: 'numeric',
  Double: 'double',
  Date: 'date',
  Timestamp: 'timestamp',
} as const;

export type CreateDataColumnApiType = typeof CreateDataColumnApiType[keyof typeof CreateDataColumnApiType];

export const CreateDataColumnType = {
  Boolean: 'boolean',
  String: 'string',
  Integer: 'int',
  Float: 'float',
  BigInteger: 'bigint',
  Double: 'double',
  Date: 'date',
  Timestamp: 'timestamp',
} as const;

export type CreateDataColumnType = typeof CreateDataColumnType[keyof typeof CreateDataColumnType];
