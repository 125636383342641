import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { FunctionTag, UserFunctionsList, UserFunctionsListItem } from '../models';
import { UserFunctionsListStore } from '../store';

@Injectable({ providedIn: 'root' })
export class UserFunctionsListDomainService {
  constructor(private userFunctionsListStore: UserFunctionsListStore) {}

  getFilteredFunctionsList(): Observable<UserFunctionsListItem[]> {
    return this.userFunctionsListStore.filteredEntities$.pipe() as Observable<UserFunctionsListItem[]>;
  }

  loadUserFunctionsList(): Observable<UserFunctionsList> {
    return this.userFunctionsListStore.getAll().pipe(map((userFunctions) => ({ userFunctions })));
  }

  filterFunctionList(tags: FunctionTag[]) {
    return this.userFunctionsListStore.setFilter(tags);
  }

  getUserFunctionsListLoading(): Observable<boolean> {
    return this.userFunctionsListStore.loading$ as Observable<boolean>;
  }

  getUserFunctionsListError(): Observable<unknown> {
    return this.userFunctionsListStore.errors$ as Observable<unknown>;
  }

  getUserFunctionsListLoaded(): Observable<boolean> {
    return this.userFunctionsListStore.loaded$ as Observable<boolean>;
  }

  updateUserFunction(data: Partial<UserFunctionsListItem>): Observable<UserFunctionsListItem> {
    return this.userFunctionsListStore.update(data);
  }

  deleteUserFunction(id: string): void {
    this.userFunctionsListStore.delete(id);
  }
}
