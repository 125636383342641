import { BoardLayoutType } from './board-layout-type';

export interface BoardLayoutOptions {
  layoutType: BoardLayoutType;
  layoutHeight?: number;
  widgetPadding: number;
}

export function createBoardLayoutOptions(
  boardLayoutOptionsParams: Partial<BoardLayoutOptions> = {},
): BoardLayoutOptions {
  return {
    layoutType: BoardLayoutType.FIT_TO_SCREEN,
    widgetPadding: 5,
    ...boardLayoutOptionsParams,
  };
}
