import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityDataService } from '@ngrx/data';
import { KE_DATA_SOURCES_ADDITIONS_PARAM } from '@selfai-platform/store';
import { DataSourcesAdditions } from '../model/data-sources-additions.model';
import { DataSourcesAdditionsDataService } from './data-sources-additions.service';

@Injectable({ providedIn: 'root' })
export class DataSourcesAdditionsStore extends EntityCollectionServiceBase<DataSourcesAdditions> {
  constructor(
    public entityDataService: EntityDataService,
    public dataSourcesAdditionsParamsService: DataSourcesAdditionsDataService,
    public serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    entityDataService.registerService(KE_DATA_SOURCES_ADDITIONS_PARAM, dataSourcesAdditionsParamsService);
    super(KE_DATA_SOURCES_ADDITIONS_PARAM, serviceElementsFactory);
  }
}
