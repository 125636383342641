import { HttpClient } from '@angular/common/http';
import { PipelineConfigService } from '@selfai-platform/shared';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Observable } from 'rxjs';
import { KE_CURRENT_USER } from '@selfai-platform/store';
import { Profile } from '@selfai-platform/pipeline-common';

@Injectable({
  providedIn: 'root',
})
export class KeCurrentUserService extends DefaultDataService<Profile> {
  config = this.pipelineConfigService.getConfig();
  apiUrl = `${this.config.hosts.api}/${this.config.versions.url}`;

  constructor(
    public override http: HttpClient,
    public override httpUrlGenerator: HttpUrlGenerator,
    private pipelineConfigService: PipelineConfigService,
  ) {
    super(KE_CURRENT_USER, http, httpUrlGenerator);
  }

  override getById(): Observable<Profile> {
    return this.http.get<Profile>(`${this.apiUrl}/profile`);
  }
}
