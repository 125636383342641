<div class="ddp-box-popupcontents3 ddp-type-multidata page-multidata" [class.ddp-edit]="isRelationEditMode">
  <div *ngIf="!isRenderedNetwork" class="ddp-ui-multidata">
    <div class="ddp-view-multidata">
      <div class="ddp-wrap-add">
        <div class="ddp-ui-add">
          <a (click)="showPopupAddDataSource()" href="javascript:" class="ddp-add-datasource">
            <em class="ddp-icon-add"></em>
            {{ 'msg.board.create.add-ds' | translate }}
          </a>
          <div class="ddp-txt-det">{{ 'msg.board.create.add-ds.desc' | translate }}</div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isRelationEditMode" class="ddp-multidata-option">
    <span style="cursor: pointer" (click)="toggleGuide()" class="ddp-btn-guide">
      {{ 'msg.board.create.guide' | translate }}
    </span>
    <span class="ddp-edit-title">{{ 'msg.board.create.edit-associations' | translate }}</span>
    <a (click)="offEditRelationMode()" href="javascript:" class="ddp-btn-line ddp-type">
      <em class="ddp-icon-btn-done"></em>
      {{ 'msg.comm.btn.done' | translate }}
    </a>
  </div>

  <div *ngIf="isRenderedNetwork" class="sys-create-board-top-panel ddp-ui-multidata">
    <div *ngIf="isRelationEditMode && isShowMultiDsGuide" class="ddp-view-guide">
      <div class="ddp-wrap-guide">
        <div class="ddp-ui-guide">
          <span class="ddp-txt-det">{{ 'msg.board.create.guide-msg1' | translate }}</span>
          <span class="ddp-sub-det">{{ 'msg.board.create.guide-msg2' | translate }}</span>

          <div class="ddp-guide-motion">
            <div class="ddp-motion">
              <span class="ddp-box-nodes ddp-first">Datasource 1</span>
              <div #guideLine class="ddp-line-bar" style="padding-left: 27.2978px">
                <em class="ddp-icon-handler"></em>
              </div>
              <span class="ddp-box-nodes ddp-last">Datasource 2</span>
            </div>
          </div>

          <div (click)="toggleGuide()" class="ddp-guide-link">
            <a href="javascript:" class="ddp-link-txt">got it!</a>
          </div>
        </div>
      </div>
    </div>

    <div class="ddp-view-multidata">
      <span *ngIf="isRelationEditMode" class="ddp-txt-message">{{ 'msg.board.create.rel-msg2' | translate }}</span>
      <div *ngIf="!isRelationEditMode" class="ddp-option-button">
        <a (click)="showPopupAddDataSource()" href="javascript:" class="ddp-btn-add-r"></a>
        <a *ngIf="isPossibleSettingRel" (click)="onEditRelationMode()" href="javascript:" class="ddp-btn-edit-r">
          <em class="ddp-icon-btn-edit"></em>
          {{ 'msg.board.create.edit-association' | translate }}
        </a>
        <span *ngIf="isPossibleSettingRel" class="ddp-txt-info">{{ 'msg.board.create.rel-msg1' | translate }}</span>
      </div>

      <div class="ddp-size-button">
        <a (click)="zoomInNetwork()" href="javascript:" class="ddp-btn-sizeup"></a>
        <a (click)="zoomOutNetwork()" href="javascript:" class="ddp-btn-sizedown"></a>
        <a (click)="fitNetwork()" href="javascript:" class="ddp-btn-fit"></a>
      </div>

      <div class="sys-ds-board" style="width: 100%; height: 100%"></div>
    </div>
  </div>

  <div
    *ngIf="selectedDataSource"
    class="sys-create-board-bottom-panel ddp-box-resultdata"
    style="height: calc(54.2334% - 5px)"
  >
    <create-board-ds-info [workspaceId]="workspaceId" [dataSource]="selectedDataSource"></create-board-ds-info>
  </div>

  <create-board-ds-relation
    *ngIf="selectedRelation && !isRelationEditMode"
    [relation]="selectedRelation"
  ></create-board-ds-relation>
</div>

<create-board-pop-relation (addCancel)="cancelAddRelation($event)"></create-board-pop-relation>

<create-board-pop-ds-select (done)="changeDataSources($event)"></create-board-pop-ds-select>

<app-essential-filter
  *ngIf="isShowDataIngestion"
  [datasource]="ingestionTargetDatasource"
  (close)="closeEssentialFilterPopup()"
  (done)="finishDataIngestion($event)"
></app-essential-filter>
