import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Observable, map } from 'rxjs';
import { WORKBOOK_PINS_ENTITY_NAME } from '../const';
import { WorkbookPinsState } from '../models';
import { WorkbookApiToDomainService } from './workbook-domain-api.service';

@Injectable({ providedIn: 'root' })
export class WorkbookPinsDataService extends DefaultDataService<WorkbookPinsState> {
  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private readonly workbookApiToDomainService: WorkbookApiToDomainService,
  ) {
    super(WORKBOOK_PINS_ENTITY_NAME, http, httpUrlGenerator);
  }

  override getById(workbookId: string): Observable<WorkbookPinsState> {
    return this.workbookApiToDomainService.loadPins(workbookId).pipe(
      map((pins) => {
        return { workbookId, pins: pins.map(({ name, id }) => ({ name, dashboardId: id })) };
      }),
    );
  }

  override delete(id: string): Observable<string> {
    return this.workbookApiToDomainService.deleteAllPins(id).pipe(map(() => id));
  }

  override add(entity: WorkbookPinsState): Observable<WorkbookPinsState> {
    return this.workbookApiToDomainService
      .addAllPins(
        entity.workbookId,
        entity.pins.map(({ dashboardId }) => dashboardId),
      )
      .pipe(map(() => void 0));
  }

  override upsert(entity: WorkbookPinsState): Observable<WorkbookPinsState> {
    return this.workbookApiToDomainService
      .addAllPins(
        entity.workbookId,
        entity.pins.map(({ dashboardId }) => dashboardId),
      )
      .pipe(map(() => void 0));
  }
}
