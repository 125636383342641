import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, filter, map, Observable, of, throwError } from 'rxjs';
import { KdBackendApiService } from '../../common';
import { DashboardDetailViewApiModel } from '../models';
import { DashboardApiService } from './dashboard-api.service';

@Injectable({ providedIn: 'root' })
export class MainDashboardApiService {
  constructor(
    private readonly kdBackendApiService: KdBackendApiService,
    private readonly dashboardApiService: DashboardApiService,
  ) {}

  setMainDashboard(dashboardId: string): Observable<void> {
    const url = this.kdBackendApiService.buildPath(`/dashboards/${dashboardId}/main`);

    return this.kdBackendApiService.put<void>(url, null).pipe(
      catchError((e) => {
        // backend can return 200 status code on success with wrong response body
        if (e instanceof HttpErrorResponse && e.status === 200) {
          return of(void 0);
        }

        return throwError(() => e);
      }),
    );
  }

  getMainDashboard(): Observable<DashboardDetailViewApiModel> {
    return this.dashboardApiService.getList({ page: 0, size: 1, projection: 'forDetailView', main: true }).pipe(
      map((response) => response._embedded.dashboards?.[0]),
      filter(Boolean),
    );
  }
}
