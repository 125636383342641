<p-confirmPopup></p-confirmPopup>
<div class="toolbar">
  <div class="toolbar-left-side flex gap-2"></div>
  <div class="toolbar-right-side flex gap-2">
    <div class="p-input-icon-left">
      <i class="pi pi-search"></i>
      <input
        class="search-input"
        style="width: 230px"
        pAutoFocus
        [autofocus]="true"
        [ngModel]="querySearch"
        [ngModelOptions]="{ standalone: true }"
        pInputText
        type="text"
        (input)="onSearch($event)"
        placeholder="{{ 'data-list-view.search.input-placeholder' | translate }}"
      />
    </div>
    <p-dropdown [options]="pageSizes" [(ngModel)]="pageSize"></p-dropdown>
    <p-fileUpload
      #file
      mode="basic"
      name="demo[]"
      chooseIcon="pi pi-upload"
      [customUpload]="true"
      (uploadHandler)="onUpload($event)"
      [auto]="true"
      chooseLabel=""
    ></p-fileUpload>
  </div>
</div>

<p-treeTable
  #table
  styleClass="p-treetable-sm"
  [value]="normalizeLibraryFiles"
  [scrollable]="true"
  [paginator]="true"
  [first]="first"
  [rows]="pageSize"
  [totalRecords]="totalRecords"
  [lazy]="false"
  [customSort]="false"
  [globalFilterFields]="['name']"
  dataKey="name"
>
  <ng-template pTemplate="header">
    <tr class="tr-head">
      <th style="width: calc(100% - 100px)" ttSortableColumn="name">
        Name
        <p-treeTableSortIcon field="name" />
      </th>
      <th style="width: 100px"></th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
    <tr [ttRow]="rowNode">
      <td style="width: calc(100% - 100px)">
        <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
        <a (click)="onSelect(rowData)">{{ rowData.name }}</a>
      </td>
      <td style="width: 100px">
        <div class="button-cell flex align-items-center gap-2">
          <button
            *ngIf="rowData.kind === 'file'"
            pButton
            type="button"
            icon="pi pi-download"
            class="p-button-text p-button-rounded"
            pTooltip="Download"
            tooltipPosition="left"
            (click)="onDownload(rowNode)"
          ></button>
          <button
            pButton
            type="button"
            icon="pi pi-trash"
            class="p-button-text p-button-rounded p-button-danger delete-button"
            pTooltip="Delete"
            tooltipPosition="left"
            (click)="onConfirmDelete($event, rowData)"
          ></button>
        </div>
      </td>
    </tr>
  </ng-template>
</p-treeTable>
<selfai-platform-data-list-view-pagination
  [pageNumber]="pageNumber"
  [pageSize]="pageSize"
  [totalRecords]="normalizeLibraryFiles.length"
  (pageChange)="onPageChange($event)"
></selfai-platform-data-list-view-pagination>
