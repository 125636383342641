import { EntityMetadataMap } from '@ngrx/data';
import { BREADCRUMBS_MENU_ENTITY_NAME } from './breadcrumbs-menu.store';

const entityMetadata: EntityMetadataMap = {
  [BREADCRUMBS_MENU_ENTITY_NAME]: {},
};

export const breadcrumbsMenuEntityConfig = {
  entityMetadata,
};
