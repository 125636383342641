import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AggregatorProcessApi, AggregatorProcessListApi } from '../../models/api/aggregator-process.model';

@Injectable({
  providedIn: 'root',
})
export abstract class AggregatorAdapter {
  abstract loadProcessWithRoutes(processUuid: string): Observable<AggregatorProcessApi>;
  abstract saveRoutes(processWithRoutes: AggregatorProcessApi): Observable<void>;
  abstract loadProcessList(): Observable<AggregatorProcessListApi>;
  abstract loadProcessListFull(): Observable<AggregatorProcessApi[]>;
}
