import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { BiPermissionService } from '@selfai-platform/bi-domain';
import { BiPermission } from '@selfai-platform/shared';

import { UserService } from '../../user/service/user.service';

@Injectable()
export class DatasetManagementGuard {
  constructor(private readonly userService: UserService, private readonly biPermissionService: BiPermissionService) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise<boolean>((resolve, reject) => {
      this.userService
        .isLoggedIn()
        .then((loggedIn) => {
          const canActivate = loggedIn && this.biPermissionService.hasPermission(BiPermission.KD_DATAFLOW_VIEW_ALL);
          resolve(canActivate);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
