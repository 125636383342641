<p-toast></p-toast>
<div class="workflow-presets">
  <p-card>
    <selfai-platform-cluster-configuration-form
      [clusterConfig]="currentWorkflowPreset"
      (submitForm)="onSubmit($event)"
      (backFromForm)="onBackEvent()"
    ></selfai-platform-cluster-configuration-form>
  </p-card>
</div>
