import { ElementRef, Injector, OnDestroy, OnInit, Directive } from '@angular/core';

import { DatasourceField as Field, CustomField } from '@selfai-platform/bi-domain';

import { AbstractPopupComponent } from '../../common/component/abstract-popup.component';

@Directive()
export class AbstractFilterPopupComponent extends AbstractPopupComponent implements OnInit, OnDestroy {
  public aggregationTypeList = [
    { name: this.translateService.instant('msg.board.filter.ui.aggregation.sum'), value: 'SUM' },
    { name: this.translateService.instant('msg.board.filter.ui.aggregation.avg'), value: 'AVG' },
    { name: this.translateService.instant('msg.board.filter.ui.aggregation.count'), value: 'COUNT' },
    { name: this.translateService.instant('msg.board.filter.ui.aggregation.min'), value: 'MIN' },
    { name: this.translateService.instant('msg.board.filter.ui.aggregation.max'), value: 'MAX' },
  ];

  public limitTypeList = [
    {
      name: this.translateService.instant('msg.board.filter.ui.limit.top'),
      value: 'TOP',
      description: this.translateService.instant('msg.board.filter.ui.limit.top.des'),
    },
    {
      name: this.translateService.instant('msg.board.filter.ui.limit.bottom'),
      value: 'BOTTOM',
      description: this.translateService.instant('msg.board.filter.ui.limit.bottom.des'),
    },
  ];

  public conditionTypeList = [
    {
      name: '=',
      value: 'EQUAL_TO',
      description: this.translateService.instant('msg.board.filter.ui.condition.equal.to.des'),
    },
    {
      name: '>',
      value: 'GREATER_THAN',
      description: this.translateService.instant('msg.board.filter.ui.condition.greater.than.des'),
    },
    {
      name: '<',
      value: 'LESS_THAN',
      description: this.translateService.instant('msg.board.filter.ui.condition.less.than.des'),
    },
    {
      name: '>=',
      value: 'EQUAL_GREATER_THAN',
      description: this.translateService.instant('msg.board.filter.ui.condition.equal.greater.than.des'),
    },
    {
      name: '<=',
      value: 'EQUAL_LESS_THAN',
      description: this.translateService.instant('msg.board.filter.ui.condition.equal.less.than.des'),
    },
  ];

  public wildCardTypeList = [
    {
      name: this.translateService.instant('msg.board.filter.ui.wildcard.after'),
      value: 'AFTER',
      description: this.translateService.instant('msg.board.filter.ui.wildcard.after.des'),
    },
    {
      name: this.translateService.instant('msg.board.filter.ui.wildcard.before'),
      value: 'BEFORE',
      description: this.translateService.instant('msg.board.filter.ui.wildcard.before.des'),
    },
    {
      name: this.translateService.instant('msg.board.filter.ui.wildcard.both'),
      value: 'BOTH',
      description: this.translateService.instant('msg.board.filter.ui.wildcard.both.des'),
    },
  ];

  public fields: Field[] = [];

  public summaryFields: (Field | CustomField)[] = [];

  public customFields: CustomField[] = [];

  public summaryMeasureFields: (Field | CustomField)[] = [];

  public uiData: any;

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    this.uiData = {
      aggregationTypeList: this.aggregationTypeList,
      limitTypeList: this.limitTypeList,
      conditionTypeList: this.conditionTypeList,
      wildCardTypeList: this.wildCardTypeList,
    };

    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public getField(fieldName: string): any {
    let field: Field | CustomField;

    for (let i = 0; i < this.summaryFields.length; i += 1) {
      if (fieldName === this.summaryFields[i].name) {
        field = this.summaryFields[i];
        break;
      }
    }
    return field;
  }
}
