import {
  BoardDataSource,
  SeriesType,
  Filter,
  Pivot,
  TimeUnit,
  Limit,
  UserDefinedField,
} from '@selfai-platform/bi-domain';
export class Analysis {
  dataSource: BoardDataSource;

  filters: Filter[];

  pivot: Pivot;

  userFields: UserDefinedField[] = [];

  limits: Limit;

  resultFormat?: any;

  metaQuery: false;

  preview: false;

  analysis: PredictionAnalysis;

  type?: string;

  mainLayer?: string;

  compareLayer?: string;

  operation?: Operation;
}

export class PredictionAnalysis {
  type = 'prediction';

  seriesTypes: SeriesType[] = [];

  interval: number | string;

  timeUnit: TimeUnit;

  forecast: Forecast;

  confidence: Confidence;
}

export class Forecast {
  parameters: HyperParameter[] = [];

  style: Style;
}

export class HyperParameter {
  field: string;

  alpha: number | string = '';

  beta: number | string = '';

  gamma: number | string = '';

  showTrend: boolean;

  showSeasonal: boolean;

  multiple = false;

  period: number;

  isAuto = true;

  isAlphaDisabled = true;

  isAlphaSelected = false;

  isBetaDisabled = true;

  isBetaSelected = false;

  isGammaDisabled = true;

  isGammaSelected = false;

  isPeriodSelected = false;
}

export class Confidence {
  confidenceInterval: number;

  style: Style;
}

export class Style {
  color = '';

  lineStyle: LineStyle;

  lineThickness: number;

  transparency: number;

  lineType: string;

  predictColorUseFl: boolean;
}

export class LineStyle {
  field: string;

  alpha: number;

  beta: number;

  gamma: number;

  showTrend: boolean;

  showSeasonal: boolean;

  multiple: boolean;
}

export class Operation {
  type: string;
  distance: number;
}
