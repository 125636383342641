import { SelfaiAppModules } from '@selfai-platform/shared';
import { HomePageWidgets } from '../pages';
import { ShellConfig } from './shell-config';

export function shellConfigFactory(widgets?: HomePageWidgets[], selfaiAppModelules?: SelfaiAppModules[]) {
  return new ShellConfig({
    startPage: {
      widgets: widgets || [],
    },
    modules: selfaiAppModelules || [],
  });
}
