import { Component, EventEmitter, Output, inject } from '@angular/core';

import * as _ from 'lodash';

import {
  Axis,
  AxisType,
  BarMarkType,
  BaseOption,
  CHART_STRING_DELIMITER,
  ChartType,
  DataLabelPosition,
  DataZoomType,
  LineMarkType,
  MarkLineSetting,
  Pivot,
  Position,
  PositionLabel,
  SeriesType,
  ShelveFieldType,
  ShelveType,
  SymbolType,
  UIChartAxis,
  UIChartAxisGrid,
  UIChartAxisLabelValue,
  UICombineChart,
  UIOption,
  createPivotTableInfo,
} from '@selfai-platform/bi-domain';
import { BarSeriesOption, LineSeriesOption, SeriesOption } from 'echarts';
import { DataZoomComponentOption } from 'echarts/components';
import { MarkLineOption } from 'echarts/types/dist/shared';
import { AxisOptionConverter } from '../../converters';
import { CombineChartMarkLineService, provideBaseChartServices } from '../../services';
import { OptionGenerator } from '../../utils';
import { BaseChart } from '../base-chart';

type CombineSeriesOption = BarSeriesOption | LineSeriesOption;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'combine-chart',
  template: '',
  styleUrls: ['./chart-host.component.scss'],
  providers: [...provideBaseChartServices()],
})
export class CombineChartComponent extends BaseChart {
  private prevPivot: Pivot;

  private readonly combineChartMarkLineService = inject(CombineChartMarkLineService);

  @Output()
  histogramUpdate = new EventEmitter();

  override isValid(pivot: Pivot): boolean {
    return (
      this.getFieldTypeCount(pivot, ShelveType.COLUMNS, ShelveFieldType.DIMENSION) +
        this.getFieldTypeCount(pivot, ShelveType.COLUMNS, ShelveFieldType.TIMESTAMP) >
        0 &&
      this.getFieldTypeCount(pivot, ShelveType.AGGREGATIONS, ShelveFieldType.MEASURE) +
        this.getFieldTypeCount(pivot, ShelveType.AGGREGATIONS, ShelveFieldType.CALCULATED) >=
        2 &&
      this.getFieldTypeCount(pivot, ShelveType.AGGREGATIONS, ShelveFieldType.MEASURE) +
        this.getFieldTypeCount(pivot, ShelveType.AGGREGATIONS, ShelveFieldType.CALCULATED) <=
        4 &&
      this.getFieldTypeCount(pivot, ShelveType.COLUMNS, ShelveFieldType.MEASURE) == 0 &&
      this.getFieldTypeCount(pivot, ShelveType.COLUMNS, ShelveFieldType.CALCULATED) == 0 &&
      this.getFieldTypeCount(pivot, ShelveType.AGGREGATIONS, ShelveFieldType.DIMENSION) == 0 &&
      this.getFieldTypeCount(pivot, ShelveType.AGGREGATIONS, ShelveFieldType.TIMESTAMP) == 0
    );
  }

  protected override initOption(): BaseOption {
    return {
      type: ChartType.COMBINE,
      grid: [OptionGenerator.Grid.verticalMode(10, 0, 0, 10, false, true, false)],
      xAxis: [OptionGenerator.Axis.categoryAxis(Position.INSIDE, null, false, true, true, true)],
      yAxis: [
        OptionGenerator.Axis.valueAxis(Position.INSIDE, null, false, false, true, true, true),
        OptionGenerator.Axis.valueAxis(Position.INSIDE, null, false, false, true, true, true),
      ],
      legend: OptionGenerator.Legend.custom(false, false, Position.LEFT, SymbolType.CIRCLE, '100%', 20),
      dataZoom: [OptionGenerator.DataZoom.horizontalDataZoom(), OptionGenerator.DataZoom.horizontalInsideDataZoom()],
      tooltip: OptionGenerator.Tooltip.itemTooltip(),
      toolbox: OptionGenerator.Toolbox.hiddenToolbox(),
      brush: OptionGenerator.Brush.selectBrush(),
      series: [],
    };
  }

  protected override additionalBasic(): BaseOption {
    if (!this.uiOption.secondaryAxis) {
      this.uiOption.secondaryAxis = _.cloneDeep(this.uiOption.yAxis);
    }

    this.setAxisNameInfo();

    this.setMeasureList();

    return this.chartOption;
  }

  protected override convertXAxisData(): BaseOption {
    const xAxisName = _.join(this.fieldInfo.cols, CHART_STRING_DELIMITER);
    this.chartOption.xAxis[0].name = xAxisName;
    this.chartOption.xAxis[0].axisName = xAxisName;
    this.chartOption.xAxis[0].data = this.data.rows;

    return this.chartOption;
  }

  protected override convertYAxisData(): BaseOption {
    const yAxis: string[] = this.fieldInfo.aggs;
    _.each(yAxis, (axis, idx) => {
      const axisIdx = idx % 2;
      if (idx < 2) {
        this.chartOption.yAxis[axisIdx].name = yAxis[idx];
        this.chartOption.yAxis[axisIdx].axisName = yAxis[idx];
      } else {
        this.chartOption.yAxis[axisIdx].name += CHART_STRING_DELIMITER + yAxis[idx];
        this.chartOption.yAxis[axisIdx].axisName += CHART_STRING_DELIMITER + yAxis[idx];
      }
    });

    return this.chartOption;
  }

  protected override additionalYAxis(): BaseOption {
    const axisOption: UIChartAxis[] = AxisOptionConverter.getAxisOption(this.uiOption, AxisType.Y);

    const axis: Axis[] = this.chartOption[AxisType.Y] as Axis[];

    _.each(axis, (option: Axis, index) => {
      if (
        <UIChartAxisLabelValue>axisOption[index].label &&
        _.eq((<UIChartAxisLabelValue>axisOption[index].label).type, AxisType.VALUE) &&
        axisOption[index].grid
      ) {
        if (index % 2 != 0) {
          let min = null;
          let max = null;
          this.data.columns.map((column, index) => {
            if (index % 2 != 0) {
              column.value.map((value) => {
                if (min == null || value < min) {
                  min = value;
                }
                if (max == null || value > max) {
                  max = value;
                }
              });
            }
          });

          AxisOptionConverter.axisMinMax[AxisType.SUB].min = min;
          AxisOptionConverter.axisMinMax[AxisType.SUB].max = max;

          if (axisOption[index].grid.autoScaled) {
            delete option.min;
            delete option.max;
            option.scale = true;
          } else {
            delete option.scale;
          }
        } else {
          let min = null;
          let max = null;
          this.data.columns.map((column, index) => {
            if (index % 2 == 0) {
              column.value.map((value) => {
                if (min == null || value < min) {
                  min = value;
                }
                if (max == null || value > max) {
                  max = value;
                }
              });
            }
          });

          AxisOptionConverter.axisMinMax[AxisType.Y].min = min;
          AxisOptionConverter.axisMinMax[AxisType.Y].max = max;

          let baseline = 0;
          if (axisOption[index].baseline && axisOption[index].baseline != 0) {
            baseline = <number>axisOption[index].baseline;
          }

          if (baseline == 0 && axisOption[index].grid.autoScaled) {
            delete option.min;
            delete option.max;
            option.scale = true;
          } else {
            delete option.scale;
          }
        }
      }
    });

    return this.chartOption;
  }

  protected override convertSeriesData(): BaseOption {
    let typeValue: SeriesType;
    this.chartOption.series = this.data.columns.map((column, idx) => {
      typeValue = null;

      if (this.pivot.aggregations[idx] && this.pivot.aggregations[idx].options) {
        if (-1 !== this.pivot.aggregations[idx].options.indexOf(SeriesType.BAR.toString())) {
          typeValue = SeriesType.BAR;
        } else {
          typeValue = SeriesType.LINE;
        }
      }

      const series: SeriesOption = {
        type: typeValue ? typeValue : _.eq(idx % 2, 0) ? SeriesType.BAR : SeriesType.LINE,
        name: column.name,
        data: column.value.map((val, idx) => {
          return {
            name: column.seriesName[idx],
            value: val,
            selected: false,
            itemStyle: OptionGenerator.ItemStyle.opacity1(),
          };
        }),
        yAxisIndex:
          _.isUndefined(this.uiOption.secondaryAxis.disabled) || !this.uiOption.secondaryAxis.disabled
            ? _.eq(idx % 2, 0)
              ? 0
              : 1
            : 0,
        itemStyle: OptionGenerator.ItemStyle.auto(),
        label: OptionGenerator.LabelStyle.defaultLabelStyle(false, PositionLabel.TOP),
      };

      if (this.isLineChart(series)) {
        series.symbol = SymbolType.CIRCLE;
        series.connectNulls = true;
        series.showAllSymbol = true;
        series.symbol = SymbolType.CIRCLE;
        series.sampling = 'max';

        this.addMarkLine(series);
      }

      return series;
    });

    return this.chartOption;
  }

  protected override additionalSeries(): BaseOption {
    this.chartOption = this.convertBarViewType();

    this.chartOption = this.convertLineViewType();

    this.chartOption = this.convertCombinePosition();

    return this.chartOption;
  }

  protected override setPivotInfo(): void {
    const cols: string[] = this.data.rows;
    const rows: string[] = [];

    this.pivotInfo = createPivotTableInfo(cols, _.uniq(rows), this.fieldInfo.aggs);
  }

  protected override selection(): void {
    this.addChartSelectEventListener();
    this.addChartMultiSelectEventListener();
    this.addLegendSelectEventListener();
  }

  override addChartDatazoomEventListener(): void {
    this.chart.off('datazoom');
    this.chart.on('datazoom', (param: any) => {
      (this.chartOption.dataZoom as DataZoomComponentOption[]).map((zoom, index) => {
        if (_.eq(zoom.type, DataZoomType.SLIDER)) {
          this.uiOption.chartZooms[index].start = param.start;
          this.uiOption.chartZooms[index].end = param.end;
        }
      });
    });
  }

  protected override selectSeriesData(seriesData) {
    (this.chartOption.series as CombineSeriesOption[]).forEach((seriesItem) => {
      seriesItem.data.some((dataItem) => {
        if (dataItem.name === seriesData.name) {
          dataItem.symbolSize = 10;
          dataItem.itemStyle.opacity = 1;
          dataItem.selected = true;

          return true;
        }

        return false;
      });
    });
  }

  protected override unselectSeriesData(seriesData) {
    (this.chartOption.series as CombineSeriesOption[]).forEach((seriesItem) => {
      seriesItem.data.some((dataItem) => {
        if (dataItem.name === seriesData.name) {
          dataItem.symbolSize = 4;
          dataItem.itemStyle.opacity = 0.2;
          dataItem.selected = false;

          return true;
        }

        return false;
      });
    });
  }

  protected override clearSelectSeriesData(seriesData) {
    seriesData.itemStyle.opacity = 1;
    seriesData.symbolSize = 4;
    seriesData.selected = false;
  }

  private convertBarViewType(): BaseOption {
    const type = (<UICombineChart>this.uiOption).barMarkType;

    const series = this.chartOption.series as CombineSeriesOption[];
    series.map((obj) => {
      if (_.eq(obj.type, SeriesType.BAR)) {
        let stackName = '';

        if (_.eq(type, BarMarkType.STACKED)) {
          stackName = _.last(_.split(obj.name as string, CHART_STRING_DELIMITER));
          obj.stack = _.isEmpty(this.fieldInfo.rows) ? 'measureStack' : stackName;
        } else {
          delete obj.stack;
        }
      }
    });

    return this.chartOption;
  }

  private convertLineViewType(): BaseOption {
    const type = (<UICombineChart>this.uiOption).lineMarkType;

    const series = this.chartOption.series as LineSeriesOption[];
    series.map((obj) => {
      if (_.eq(obj.type, SeriesType.LINE)) {
        obj.areaStyle = _.eq(type, LineMarkType.AREA) ? OptionGenerator.AreaStyle.customAreaStyle(0.5) : undefined;
      }
    });

    return this.chartOption;
  }

  private convertCombinePosition() {
    const getPosition = (pos: DataLabelPosition): Position => {
      let position: Position = null;

      switch (pos) {
        case DataLabelPosition.OUTSIDE_TOP:
        case DataLabelPosition.TOP:
          position = Position.TOP;
          break;
        case DataLabelPosition.OUTSIDE_RIGHT:
          position = Position.RIGHT;
          break;
        case DataLabelPosition.INSIDE_TOP:
          position = Position.INSIDETOP;
          break;
        case DataLabelPosition.INSIDE_BOTTOM:
          position = Position.INSIDEBOTTOM;
          break;
        case DataLabelPosition.INSIDE_RIGHT:
          position = Position.INSIDERIGHT;
          break;
        case DataLabelPosition.INSIDE_LEFT:
          position = Position.INSIDELEFT;
          break;
        case DataLabelPosition.CENTER:
          position = Position.INSIDE;
          break;
        case DataLabelPosition.BOTTOM:
          position = Position.BOTTOM;
          break;
        default:
          position = Position.TOP;
          break;
      }

      return position;
    };

    _.each(this.chartOption.series as CombineSeriesOption[], (option, index: number) => {
      if (!option.label) option.label = {};

      if (index % 2 == 0) {
        option.label.position = getPosition(this.uiOption.dataLabel.pos);
      } else {
        option.label.position = getPosition(this.uiOption.dataLabel.secondaryPos);
      }
    });

    return this.chartOption;
  }

  protected override calculateMinMax(grid: UIChartAxisGrid, result: any, isYAsis: boolean): void {
    if (grid.autoScaled) {
      let min = null;
      let max = null;
      result.data.columns.map((column, index) => {
        if (index % 2 == 0) {
          column.value.map((value) => {
            if (min == null || value < min) {
              min = value;
            }
            if (max == null || value > max) {
              max = value;
            }
          });
        }
      });
      grid.min = min > 0 ? Math.ceil(min - (max - min) * 0.05) : min;
      grid.max = max;
    }

    if ((_.isUndefined(grid.min) || grid.min == 0) && (_.isUndefined(grid.max) || grid.max == 0)) {
      return;
    }

    const seriesList = [];
    result.data.columns.map((column) => {
      const nameArr = _.split(column.name, CHART_STRING_DELIMITER);
      let name = '';
      if (nameArr.length > 1) {
        nameArr.map((temp, index) => {
          if (index < nameArr.length - 1) {
            if (index > 0) {
              name += CHART_STRING_DELIMITER;
            }
            name += temp;
          }
        });
      } else {
        name = nameArr[0];
      }

      let isAlready = false;
      seriesList.map((series) => {
        if (series == name) {
          isAlready = true;

          return false;
        }
      });

      if (!isAlready) {
        seriesList.push(name);
      }
    });

    if (!result.data.categories || result.data.categories.length == 0) {
      result.data.columns.map((column, index) => {
        if (index % 2 == 0) {
          column.value.map((value, index) => {
            if (value < grid.min) {
              column.value[index] = grid.min;
            } else if (value > grid.max) {
              column.value[index] = grid.max;
            }
          });
        }
      });
    } else {
      _.each(result.data.categories, (category) => {
        const totalValue = [];
        const seriesValue = [];
        result.data.columns.map((column) => {
          if (column.name.indexOf(category.name) == -1) {
            return true;
          }

          column.value.map((value, index) => {
            if (_.isUndefined(totalValue[index]) || isNaN(totalValue[index])) {
              totalValue[index] = 0;
              seriesValue[index] = 0;
            }

            if (totalValue[index] > grid.max) {
              column.value[index] = 0;
            } else if (totalValue[index] + value > grid.max) {
              if (seriesValue[index] <= 0) {
                column.value[index] = grid.max;
              } else {
                column.value[index] = <number>grid.max - totalValue[index];
              }
            } else if (totalValue[index] + value < grid.min) {
              column.value[index] = 0;
            } else if (totalValue[index] < grid.min && totalValue[index] + value > grid.min) {
              column.value[index] = totalValue[index] + value;
            } else {
              column.value[index] = value;
            }
            seriesValue[index] += column.value[index];
            totalValue[index] += value;
          });
        });

        _.each(totalValue, (value, valueIndex) => {
          if (value < grid.min) {
            result.data.columns.map((column) => {
              column.value.map((value, index) => {
                if (index == valueIndex) {
                  column.value[index] = 0;
                }
              });
            });
          }
        });
      });
    }
  }

  protected override setDataLabel(): UIOption {
    const checkChangeSeries = (): boolean => {
      if (!this.prevPivot) return true;

      const prevSeriesMulti: boolean = this.prevPivot.aggregations.length > 1;

      const currentSeriesMulti: boolean = this.pivot.aggregations.length > 1;

      if (prevSeriesMulti !== currentSeriesMulti) {
        return true;
      }

      return false;
    };

    this.uiOption = this.setAxisDataLabel(this.prevPivot, checkChangeSeries());

    this.prevPivot = this.pivot;

    return this.uiOption;
  }

  private isBarChart(series: SeriesOption): series is BarSeriesOption {
    return series.type === SeriesType.BAR;
  }

  private isLineChart(series: SeriesOption): series is LineSeriesOption {
    return series.type === SeriesType.LINE;
  }

  private addMarkLine(series: LineSeriesOption) {
    const markLines: MarkLineOption['data'] = [];
    const markLinesSetting = this.uiOption.xAxis.markLines || [];
    const markLineValues = this.combineChartMarkLineService.extractMarkValues(markLinesSetting, this.data);

    if (markLinesSetting.length && markLineValues.length) {
      markLinesSetting.forEach((markLine, index) => {
        const markLineValue = markLineValues[index];
        markLines.push({
          lineStyle: {
            color: markLine.lineColor,
            type: markLine.lineStyle,
          },
          label: {
            formatter: this.getMarkLineName(markLine),
            rotate: markLine.labelRotate !== 'horizontal' ? 0 : 90,
            position: 'middle',
          },
          name: this.getMarkLineName(markLine),
          yAxis: markLineValue,
        });
      });
    }

    if (markLines.length) {
      series.markLine = {
        symbol: 'none',
        silent: true,
        data: markLines,
      };
    }
  }

  private getMarkLineName(markLine: MarkLineSetting): string {
    return markLine.markLineType === 'percentile' ? `Percentile ${markLine.percentileAmount}` : 'Average';
  }
}
