import { ChartType } from '@selfai-platform/bi-domain';

export function getChartIconClass(chartType: string | undefined): string {
  let iconClass = '';
  switch (chartType) {
    case ChartType.BAR:
      iconClass = 'ddp-chart-bar';
      break;
    case ChartType.LINE:
      iconClass = 'ddp-chart-linegraph';
      break;
    case ChartType.GRID:
      iconClass = 'ddp-chart-table';
      break;
    case ChartType.SCATTER:
      iconClass = 'ddp-chart-scatter';
      break;
    case ChartType.HEATMAP:
      iconClass = 'ddp-chart-heatmap';
      break;
    case ChartType.PIE:
      iconClass = 'ddp-chart-pie';
      break;
    case ChartType.MAP:
      iconClass = 'ddp-chart-map';
      break;
    case ChartType.CONTROL:
      iconClass = 'ddp-chart-cont';
      break;
    case ChartType.LABEL:
      iconClass = 'ddp-chart-kpi';
      break;
    case ChartType.LABEL2:
      iconClass = 'ddp-chart-kpi';
      break;
    case ChartType.BOXPLOT:
      iconClass = 'ddp-chart-boxplot';
      break;
    case ChartType.WATERFALL:
      iconClass = 'ddp-chart-waterfall';
      break;
    case ChartType.WORDCLOUD:
      iconClass = 'ddp-chart-wordcloud';
      break;
    case ChartType.COMBINE:
      iconClass = 'ddp-chart-combo';
      break;
    case ChartType.TREEMAP:
      iconClass = 'ddp-chart-treemap';
      break;
    case ChartType.RADAR:
      iconClass = 'ddp-chart-radar';
      break;
    case ChartType.GRAPH:
    case ChartType.GRAPHV2:
      iconClass = 'ddp-chart-network';
      break;
    case ChartType.SANKEY:
      iconClass = 'ddp-chart-sankey';
      break;
    case ChartType.GAUGE:
      iconClass = 'ddp-chart-bar';
      break;
  }
  return iconClass;
}
