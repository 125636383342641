import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WorkflowEditorElementService {
  private elementForPasteCube$ = new BehaviorSubject<HTMLElement | null>(null);

  get pasteCubeElement$(): Observable<HTMLElement | null> {
    return this.elementForPasteCube$.asObservable();
  }

  initService(element: HTMLElement): void {
    this.elementForPasteCube$.next(element);
  }
}
