import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { JsonPathFieldComponentModule } from '../../../../json-path/components/json-path-field/json-path-field.module';
import { ConstantValueFieldComponentModule } from '../constant-value-field/constant-value-field.module';
import { SpellValueFieldComponentModule } from '../spel-value-field/spel-value-field.module';
import { TypeFieldSwitchComponent } from './type-field-switch.component';

@NgModule({
  imports: [
    CommonModule,
    SpellValueFieldComponentModule,
    JsonPathFieldComponentModule,
    ConstantValueFieldComponentModule,
  ],
  declarations: [TypeFieldSwitchComponent],
  exports: [
    TypeFieldSwitchComponent,
    ConstantValueFieldComponentModule,
    SpellValueFieldComponentModule,
    JsonPathFieldComponentModule,
  ],
})
export class TypeFieldSwitchComponentModule {}
