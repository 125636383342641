import { Injectable } from '@angular/core';
import { ConfigurationDataSourceApiModel } from '@selfai-platform/bi-api';
import { DashboardDomainService } from '@selfai-platform/bi-domain';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { filter, take } from 'rxjs';
import { WidgetNode } from '../models';
import { getDatasourceEngineName } from '../utils';
import { FilterSelectionService } from './filters';

@Injectable({ providedIn: 'root' })
export class EChartEventsService {
  constructor(
    private readonly filterSelectionService: FilterSelectionService,
    private readonly dashboardDomainService: DashboardDomainService,
  ) {}

  handleClickEvent(event: CallbackDataParams, widgetId: string, contextId?: string): void {
    if (!event) {
      this.filterSelectionService.resetAllSelection(widgetId, contextId);
    }

    if (event.componentType === 'series') {
      if (event.dataType === 'node') {
        this.dashboardDomainService
          .getDashboardByWidgetId(widgetId)
          .pipe(filter(Boolean), take(1))
          .subscribe((dashboard) => {
            const data = event.data as WidgetNode;
            const dataSourceEngineName = getDatasourceEngineName(
              dashboard.configuration.dataSource as ConfigurationDataSourceApiModel,
            );

            this.filterSelectionService.toggleSelection(
              widgetId,
              this.filterSelectionService.mapNodeToSelection(widgetId, data, dataSourceEngineName),
              contextId,
            );
          });
      }
    }
  }
}
