import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { UpdateStr } from '@ngrx/entity/src/models';
import { UserProfileService } from '@selfai-platform/shared';
import { KE_USER_FUNCTIONS_LIST_ENTITY_NAME } from '@selfai-platform/store';
import { combineLatest, map, Observable, tap } from 'rxjs';
import { API_RESPONSE_MESSAGES } from '../constants';
import { UserFunctionsListItem } from '../models';
import { FunctionsResponseCallbacksService, UserFunctionsApiService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class UserFunctionsDataService extends DefaultDataService<UserFunctionsListItem> {
  constructor(
    public override http: HttpClient,
    public override httpUrlGenerator: HttpUrlGenerator,
    private readonly userFunctionsApiService: UserFunctionsApiService,
    private readonly responseCallbacksService: FunctionsResponseCallbacksService,
    private readonly userProfileService: UserProfileService,
  ) {
    super(KE_USER_FUNCTIONS_LIST_ENTITY_NAME, http, httpUrlGenerator);
  }

  override getAll(): Observable<UserFunctionsListItem[]> {
    return combineLatest({
      functionsList: this.userFunctionsApiService.loadList(),
      userProfile: this.userProfileService.getUserProfile(),
    }).pipe(
      map(({ functionsList, userProfile }) =>
        functionsList.map((item) => ({
          ...item,
          name: item.name,
          isOwner: userProfile.id === item.ownerId,
        })),
      ),
    );
  }

  override getById(id: string): Observable<UserFunctionsListItem> {
    return this.userFunctionsApiService.loadUserFunction(id).pipe(map((item) => ({ ...item, name: item.name })));
  }

  override add(userFunctionParams: UserFunctionsListItem): Observable<UserFunctionsListItem> {
    return this.userFunctionsApiService.addUserFunction(userFunctionParams).pipe(
      map((newFunction) => newFunction as UserFunctionsListItem),
      tap({
        // TODO: it should not be handled in store
        next: () => this.responseCallbacksService.handleNextCallback(API_RESPONSE_MESSAGES.CREATE_SUCCESS),
        error: ({ error }) =>
          this.responseCallbacksService.handleErrorCallback(API_RESPONSE_MESSAGES.CREATE_ERROR, JSON.stringify(error)),
      }),
    );
  }

  override update(data: UpdateStr<UserFunctionsListItem>): Observable<UserFunctionsListItem> {
    return this.userFunctionsApiService
      .updateUserFunction({ id: data.id, ...data.changes } as UserFunctionsListItem)
      .pipe(
        // For why?
        map((item) => ({ ...item, name: item.name })),
        tap({
          error: ({ error }) =>
            this.responseCallbacksService.handleErrorCallback(API_RESPONSE_MESSAGES.UPDATE_ERROR, error),
        }),
      );
  }

  override delete(id: string): Observable<string> {
    return this.userFunctionsApiService.deleteUserFunction(id).pipe(
      tap({
        // TODO: it should not be handled in store
        next: () => this.responseCallbacksService.handleNextCallback(API_RESPONSE_MESSAGES.DELETE_SUCCESS, false),
        error: ({ error }) =>
          this.responseCallbacksService.handleErrorCallback(API_RESPONSE_MESSAGES.DELETE_ERROR, error),
      }),
    );
  }

  public clone(id: string, newName: string): Observable<string> {
    return this.userFunctionsApiService.cloneUserFunction(id, newName);
  }
}
