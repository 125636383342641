<div class="chart-wrapper-section-content">
  <div class="ddp-ui-chart-list">
    <ul class="ddp-list-chart-img">
      <ng-container *ngFor="let chart of charts">
        <ng-container
          *ngTemplateOutlet="chartItem; context: { type: chart.name }"
        ></ng-container>
      </ng-container>
    </ul>

    <ng-container *ngFor="let chart of charts">
      <ng-container *ngIf="(isShowChartInfo$ | async) === chart.name">
        <ng-container
          *ngTemplateOutlet="
            chartInfo;
            context: { title: chart.title, data: chart.data }
          "
        ></ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #chartInfo let-title="title" let-data="data">
  <div class="ddp-ui-chart-info">
    <span class="ddp-ui-name">{{ title | translate }}</span>

    <div class="ddp-data-det">
      <ng-container *ngFor="let item of data">
        {{ item.title | translate }} :
        <ng-container *ngFor="let value of item.values">
          {{ value | translate }},
        </ng-container>
        <br />
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #chartItem let-type="type">
  <li
    [class.ddp-recommend]="recommendCharts.indexOf(type) > -1"
    [class.ddp-selected]="selectChart === type"
    (click)="selectChartChange.emit(type)"
    (mouseenter)="selectChartInfo(type)"
    (mouseleave)="clearChartInfo()"
  >
    <a href="javascript:">
      <em class="ddp-s-graph-{{ type }}"></em>
    </a>
  </li>
</ng-template>
