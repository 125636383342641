<p-tree *ngIf="tree$ | async as tree" [value]="tree" [filter]="true">
  <ng-template let-node [pTemplate]="operationTreeType.OPERATION">
    <div cdkDropList cdkDropListConnectedTo="workflowEditor">
      <div
        cdkDrag
        cdkDragPreviewContainer="global"
        [cdkDragData]="getCubeObservableByOperationId(node.data.id)"
        tabindex="0"
        (copy)="copyToClipboard(node.data.id)"
        class="p-1 flex align-items-center node-item"
      >
        <span *cdkDragPlaceholder></span>
        <selfai-platform-cube-dnd-placeholder
          *cdkDragPreview
          [cubeUiName]="node.label"
        ></selfai-platform-cube-dnd-placeholder>
        <span>{{ node.label }}</span>
        <i class="pi pi-question-circle ml-2" [pTooltip]="node.description"></i>
        <button
          type="button"
          pButton
          class="p-button-text p-button-link p-0 w-1rem ml-2"
          appearance="icon"
          icon="pi pi-copy"
          (click)="copyToClipboard(node.data.id)"
          pTooltip="{{ 'workflow.actions.copy-to-clipboard.tooltip' | translate }}"
        ></button>
      </div>
    </div>
  </ng-template>

  <ng-template let-node [pTemplate]="operationTreeType.CATEGORY">
    <button
      [icon]="node.expanded ? 'pi pi-folder-open' : 'pi pi-folder'"
      pButton
      class="p-button-text p-1"
      type="button"
      (click)="toggle($event, node)"
      [label]="node.label"
    ></button>
  </ng-template>
</p-tree>
