import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PipelineConfigService } from '@selfai-platform/shared';
import { Observable, map } from 'rxjs';
import { RbacApiResponse } from '../models/rbac-api-response';

@Injectable({
  providedIn: 'root',
})
export class RbacApiService {
  private apiurl: string;
  private readonly pathRbac = '/rbac';

  constructor(private readonly http: HttpClient, private readonly pipelineConfigService: PipelineConfigService) {
    pipelineConfigService.configLoaded$.subscribe((loaded) => {
      if (!loaded) return;
      const config = this.pipelineConfigService.getConfig();
      this.apiurl = `${config.hosts.api}/${config.versions.url}`;
    });
  }

  getRbac(): Observable<Record<string, string[]>> {
    return this.http
      .get<RbacApiResponse>(`${this.apiurl}${this.pathRbac}`)
      .pipe(map((rbacResponse) => rbacResponse?.rbac?.roles || {}));
  }
}
