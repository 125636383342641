import _ from 'lodash';

import { FieldRole, Pivot, Widget, CustomField } from '@selfai-platform/bi-domain';

export function syncCustomFieldInWidgetPivot(widget: Widget, customFields: CustomField[]): Pivot {
  const pivot: Pivot = widget.configuration['pivot'];
  if (customFields) {
    customFields.forEach((field: CustomField) => {
      if (FieldRole.DIMENSION === field.role) {
        pivot.columns.some((col) => {
          if (col.name === field['oriColumnName']) {
            col.field = _.merge(col.field, field);
            col['expr'] = field['expr'];
            col['name'] = field['name'];
            return true;
          }
        });
        pivot.rows.some((row) => {
          if (row.name === field['oriColumnName']) {
            row.field = _.merge(row.field, field);
            row['expr'] = field['expr'];
            row['name'] = field['name'];
            return true;
          }
        });
      } else if (FieldRole.MEASURE === field.role) {
        const customFieldPivotIdxs: number[] = [];
        pivot.aggregations.forEach((agg, idx: number) => {
          if (agg.name === field['oriColumnName']) {
            customFieldPivotIdxs.push(idx);
          }
        });
        if (1 < customFieldPivotIdxs.length) {
          customFieldPivotIdxs.splice(0, 1);
          customFieldPivotIdxs.reverse().forEach((idx) => {
            pivot.aggregations.splice(idx, 1);
          });
        }
        pivot.aggregations.forEach((agg) => {
          if (agg.name === field['oriColumnName']) {
            agg.field = _.merge(agg.field, field);
            agg['expr'] = field['expr'];
            agg['name'] = field['name'];
            agg['aggregated'] = field['aggregated'];
            agg['aggregationType'] = field['aggregated'] ? null : 'SUM';
            return true;
          }
        });
      }
    });
  }
  return pivot;
}
