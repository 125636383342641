<div class="p-breadcrumb p-component d-inline-flex" *ngIf="breadcrumbItems$ | async as breadcrumbItems">
  <ul>
    <ng-container *ngFor="let breadcrumbItem of breadcrumbItems; let i = index">
      <li class="p-element flex align-items-center overflow-hidden">
        <i *ngIf="breadcrumbItem.icon" [ngClass]="['mr-2', breadcrumbItem.icon]"></i>
        <a
          *ngIf="breadcrumbItem.routerLink || !breadcrumbItem.command; else buttonItem"
          class="p-menuitem-link short-text"
          [routerLink]="breadcrumbItem.routerLink"
          [queryParams]="breadcrumbItem.queryParams"
        >
          <span class="p-menuitem-text short-text">{{ breadcrumbItem.label }}</span>
        </a>
        <ng-template #buttonItem>
          <button
            *ngIf="breadcrumbItem.command"
            pButton
            type="button"
            class="p-button-text p-button-link p-menuitem-link"
            tabindex="0"
            (click)="breadcrumbItem.command({ originalEvent: $event, item: breadcrumbItem })"
          >
            <span class="p-menuitem-text">{{ breadcrumbItem.label }}</span>
          </button>
        </ng-template>
      </li>

      <ng-container *ngIf="i < breadcrumbItems.length - 1">
        <ng-container *ngIf="breadcrumbItem.items as subMenuItems; else noSubMenu">
          <li class="p-breadcrumb-chevron pi pi-chevron-right" (click)="menu.toggle($event)"></li>
          <p-tieredMenu #menu [model]="subMenuItems" appendTo="body" [popup]="true"></p-tieredMenu>
        </ng-container>
      </ng-container>
    </ng-container>
  </ul>
</div>

<ng-template #noSubMenu>
  <li class="p-breadcrumb-chevron pi pi-chevron-right"></li>
</ng-template>
