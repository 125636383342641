<div class="ddp-ui-option-down ddp-selected">
  <div class="ddp-ui-down-title">
    <div class="ddp-ui-down-title-in">
      <em class="ddp-icon-menumove"></em>
      Parameter name
      <em class="ddp-icon-use2"></em>

      <div class="ddp-ui-buttons">
        <em class="ddp-icon-global-s"></em>

        <div
          class="ddp-wrap-morebutton"
          [ngClass]="{ 'ddp-selected': isShowDetailMenu }"
          (click)="isShowDetailMenu = !isShowDetailMenu"
          (clickOutside)="isShowDetailMenu = false"
        >
          <em class="ddp-icon-more"></em>

          <div class="ddp-wrap-popup2 ddp-types">
            <ul class="ddp-list-popup">
              <li>
                <a href="javascript:">
                  <em class="ddp-icon-drop-global"></em>
                  글로벌필터로 변경
                </a>
              </li>
              <li>
                <a href="javascript:">
                  <em class="ddp-icon-drop-refresh"></em>
                  초기화
                </a>
              </li>
              <li>
                <a href="javascript:">
                  <em class="ddp-icon-drop-editmodify"></em>
                  편집
                </a>
              </li>
              <li>
                <a href="javascript:">
                  <em class="ddp-icon-drop-editdel3"></em>
                  삭제
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="ddp-ui-down-contents">
    <div class="ddp-wrap-slider-type">
      <div class="ddp-ui-slider-type">
        <input type="text" id="range2" value="" name="range" />
      </div>

      <script>
        $(function () {
          $('#range2').ionRangeSlider({
            hide_from_to: false,
            hide_min_max: true,
            keyboard: false,
            min: 0,
            max: 200,
            from: 9.5,
            to: 50.2,
            type: 'double',
            step: 0.01,
            grid: false,
          });
        });
      </script>

      <div class="ddp-form-input-multy ddp-clear">
        <input type="text" class="ddp-input-left" value="0.000" />
        <span class="ddp-text-bar">~</span>
        <input type="text" class="ddp-input-right" value="0.000" />
      </div>
    </div>
  </div>
</div>
