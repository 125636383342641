import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AggregatorProcessListState } from '../models/aggregator-process.model';
import { AggregatorProcessListStore } from '../stores';

@Injectable({ providedIn: 'root' })
export class AggregatorProcessListService {
  constructor(private readonly aggregatorProcessListStore: AggregatorProcessListStore) {}

  loadProcessList(): Observable<AggregatorProcessListState> {
    return this.aggregatorProcessListStore.getAll();
  }

  getProcessList(): Observable<AggregatorProcessListState> {
    return this.aggregatorProcessListStore.entities$;
  }

  isLoading(): Observable<boolean> {
    return this.aggregatorProcessListStore.loading$;
  }

  isLoaded(): Observable<boolean> {
    return this.aggregatorProcessListStore.loaded$;
  }

  hasError(): Observable<boolean> {
    return this.aggregatorProcessListStore.errors$.pipe(map(Boolean));
  }
}
