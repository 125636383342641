export interface Sort {
  field?: string;
  direction?: DIRECTION;
  lastDirection?: boolean;
}

export function createSort(params: Partial<Sort> = {}): Sort {
  return {
    ...params,
  }
}

export enum DIRECTION {
  ASC = 'ASC',
  DESC = 'DESC',
  NONE = 'NONE',
}
