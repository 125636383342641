import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApplicationRef, CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import moment from 'moment';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { CookieService } from 'ng2-cookies';
import { ClipboardModule } from 'ngx-clipboard';
import { filter, switchMap, take } from 'rxjs';

import { BackendInfoService, BiDomainModule } from '@selfai-platform/bi-domain';
import { BiSharedModule } from '@selfai-platform/bi-shared';
import { AppInitService, KdBuildVersionService } from '@selfai-platform/shared';
import { SelfaiTranslateService, ShellModule } from '@selfai-platform/shell';

import { AppComponent } from './app.component';
import { AuthService } from './auth/auth.service';
import {
  CanDeactivateGuard,
  ConnectionListGuard,
  DatasourceManagementGuard,
  MetadataManagementGuard,
  RecyclebinAdminGuard,
  StagedbEnabledGuard,
  WorkspacesCreateGuard,
  WorkspacesEditGuard,
  WorkspacesViewGuard,
} from './common';
import { EventBroadcaster } from './common/event/event.broadcaster';
import { FileModule } from './common/file.module';
import { LoaderInterceptor } from './common/interceptor/loader-interceptor';
import { AbstractService } from './common/service/abstract.service';
import { PopupService } from './common/service/popup.service';
import { UnloadConfirmService } from './common/service/unload.confirm.service';
import { DashboardModule } from './dashboard/dashboard.module';
import { UpdateDashboardComponent } from './dashboard/update-dashboard.component';
import { StorageService } from './data-storage/service/storage.service';
import { LayoutComponent } from './layout/layout.component';
import { BiRoutes } from './routes';
import { UserService } from './user/service/user.service';
import { UserWorkspaceService } from './workspace/service/user-workspace.service';
import { WorkspaceService } from './workspace/service/workspace.service';
import { WorkspaceShareModule } from './workspace/workspace-share.module';

library.add(fas, far);

@NgModule({
  declarations: [AppComponent, LayoutComponent],
  imports: [
    RouterModule.forChild(BiRoutes),
    CommonModule,
    BiSharedModule,
    BiDomainModule,
    NgClickOutsideDirective,
    WorkspaceShareModule,
    FileModule,
    ClipboardModule,
    FontAwesomeModule,
    DashboardModule,
    TranslateModule,
    ShellModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    PopupService,
    UserService,
    AuthService,
    CookieService,
    AbstractService,
    CanDeactivateGuard,
    UnloadConfirmService,
    EventBroadcaster,
    UpdateDashboardComponent,
    UserWorkspaceService,
    CookieService,
    WorkspaceService,
    MetadataManagementGuard,
    DatasourceManagementGuard,
    StorageService,
    StagedbEnabledGuard,
    ConnectionListGuard,
    RecyclebinAdminGuard,
    WorkspacesViewGuard,
    WorkspacesEditGuard,
    WorkspacesCreateGuard,
  ],
})
export class BiModule {
  constructor(
    public appRef: ApplicationRef,
    selfaiTranslateService: SelfaiTranslateService,
    injector: Injector,
    appInitService: AppInitService,
  ) {
    const currentLanguage = selfaiTranslateService.getCurrentLanguage();
    moment.locale(currentLanguage);
    selfaiTranslateService.$onLanguageChange.subscribe((language) => {
      moment.locale(language);
    });

    appInitService.appInitialiazed$
      .pipe(
        filter((loaded) => loaded),
        switchMap(() => {
          const kdBackendService = injector.get(BackendInfoService);
          return kdBackendService.getVersion();
        }),
        take(1),
      )
      .subscribe((info) => {
        const versionSvc = injector.get(KdBuildVersionService);
        versionSvc.next(info.version);
        appInitService.setKdLoaded();
      });
  }
}
