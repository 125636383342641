<div
  class="ddp-popup-side ddp-pop-colorpicker"
  id="ddpPopColorPicker"
  (click)="clickedInside($event)"
  [ngStyle]="{ display: isShow ? 'block' : 'none', top: offsetY, left: offsetX }"
>
  <div class="ddp-pop-side-top">
    {{ title }}
    <a href="javascript:" (click)="hide()" class="ddp-btn-close"></a>
  </div>

  <div class="ddp-pop-side-contents">
    <div *ngFor="let color of colorGroup" class="ddp-color-picker">
      <span
        *ngFor="let item of color[0]"
        class="ddp-ui-color {{ item[0] }}"
        id="{{ item[0] }}"
        (click)="$event.preventDefault(); colorSelected(item[1])"
      ></span>
    </div>
  </div>
</div>
