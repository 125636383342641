import { Component, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';

import * as _ from 'lodash';

import { Format, PivotField, Shelf, UIMapOption, createShelf } from '@selfai-platform/bi-domain';

import { FormatOptionComponent } from '../format-option.component';

@Component({
  selector: 'map-format-option',
  templateUrl: './map-format-option.component.html',
})
export class MapFormatOptionComponent extends FormatOptionComponent {
  public shelf: Shelf;

  @Output('changeEach')
  public changeEachEvent: EventEmitter<any> = new EventEmitter();

  @Input('shelf')
  public set setShelf(shelf: Shelf) {
    if (shelf === undefined) {
      this.shelf = createShelf();
    } else {
      this.shelf = shelf;
    }

    const fieldList: PivotField[] = _.cloneDeep(this.shelf.layers[(<UIMapOption>this.uiOption).layerNum].fields);
    for (let num: number = fieldList.length - 1; num >= 0; num--) {
      if ('measure' != fieldList[num].type.toLowerCase()) {
        fieldList.splice(num, 1);
      }
    }

    for (const afterField of fieldList) {
      if (
        afterField.name == 'count' &&
        !_.isUndefined(afterField['isCustomField']) &&
        afterField['isCustomField'] == true
      ) {
        continue;
      }
      let isBeforeFormat = false;
      for (const beforeField of this.fieldList) {
        if (afterField.name == beforeField.name && afterField.aggregationType == beforeField.aggregationType) {
          afterField.format == beforeField.format;
          isBeforeFormat = true;
          break;
        }
      }
      if (!isBeforeFormat && !afterField.format) {
        afterField.format = this.format;

        _.concat(this.pivot.columns, this.pivot.rows, this.pivot.aggregations).forEach((field) => {
          if (
            field.type == 'measure' &&
            field.name == afterField.name &&
            field.aggregationType == afterField.aggregationType
          ) {
            field.format = this.format;
          }
        });

        this.changeEachEvent.emit(this.shelf);
      }
    }

    this.fieldList = fieldList;

    if (this.format) {
      this.apply();
    }
  }

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public onChange(target: Object): void {
    this.format = target as Format;

    this.shelf.layers[(<UIMapOption>this.uiOption).layerNum].fields.forEach((field) => {
      if (field.type == 'measure') {
        field.format = this.format;
      }
    });

    this.changeEachEvent.emit(this.shelf);
    this.changeCommonEvent.emit(this.format);

    this.apply();
  }
}
