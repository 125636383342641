<form [formGroup]="form">
  <selfai-platform-form-field label="Other process">
    <p-dropdown
      [options]="(toItems$ | async) || []"
      formControlName="to"
      placeholder="Choose other process"
      optionLabel="name"
      optionValue="uuid"
    ></p-dropdown>
  </selfai-platform-form-field>
</form>
