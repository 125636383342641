/* eslint-disable max-lines */
export enum ChartType {
  BAR = 'bar',
  GRID = 'grid',
  INPUT = 'input',
  LINE = 'line',
  SCATTER = 'scatter',
  HEATMAP = 'heatmap',
  PIE = 'pie',
  DPIE = 'dpie',
  DDONAT = 'ddonat',
  MAP = 'map',
  CONTROL = 'control',
  LABEL = 'label',
  LABEL2 = 'label2',
  BOXPLOT = 'boxplot',
  WATERFALL = 'waterfall',
  WORDCLOUD = 'wordcloud',
  COMBINE = 'combine',
  TREEMAP = 'treemap',
  RADAR = 'radar',
  GRAPH = 'graph',
  GRAPHV2 = 'graphv2',
  SANKEY = 'sankey',
  GAUGE = 'gauge',
}

export enum SeriesType {
  BAR = 'bar',
  LINE = 'line',
  SCATTER = 'scatter',
  HEATMAP = 'heatmap',
  PIE = 'pie',
  BOXPLOT = 'boxplot',
  WORDCLOUD = 'wordCloud',
  TREEMAP = 'treemap',
  RADAR = 'radar',
  GRAPH = 'graph',
  SANKEY = 'sankey',
}

export enum Position {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom',
  INSIDE = 'inside',
  INSIDETOP = 'insideTop',
  INSIDELEFT = 'insideLeft',
  INSIDERIGHT = 'insideRight',
  INSIDEBOTTOM = 'insideBottom',
  OUTSIDE = 'outside',
}

export enum PositionLabel {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom',
  INSIDE = 'inside',
  INSIDETOP = 'insideTop',
  INSIDELEFT = 'insideLeft',
  INSIDERIGHT = 'insideRight',
  INSIDEBOTTOM = 'insideBottom',
}

export enum AxisType {
  CATEGORY = 'category',
  VALUE = 'value',
  LOG = 'log',
  X = 'xAxis',
  Y = 'yAxis',
  SUB = 'subAxis',
}

export enum LineType {
  SOLID = 'solid',
  DASHED = 'dashed',
  DOTTED = 'dotted',
}

export enum SymbolType {
  POLYGON = 'polygon',
  CIRCLE = 'circle',
  RECT = 'rect',
  ROUNDRECT = 'roundRect',
  TRIANGLE = 'triangle',
  DIAMOND = 'diamond',
  PIN = 'pin',
  ARROW = 'arrow',
}

export enum SymbolFill {
  SINGLE = 'single',
  TRANSPARENT = 'transparent',
}

export enum FontStyle {
  NORMAL = 'normal',
  ITALIC = 'italic',
  OBLIQUE = 'oblique',
}

export enum FontWeight {
  NORMAL = 'normal',
  BOLD = 'bold',
  BOLDER = 'bolder',
  LIGHTER = 'lighter',
}

export enum Orient {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
}

export enum AxisLabelRotate {
  HORIZONTAL = 0,
  VERTICAL = 90,
  SLOPE = 45,
}

export enum TriggerType {
  ITEM = 'item',
  AXIS = 'axis',
  NONE = 'none',
}

export enum TriggerAction {
  MOUSEMOVE = 'mousemove',
  CLICK = 'click',
  NONE = 'none',
}

export enum ImageFormat {
  PNG = 'png',
  JPEG = 'jpeg',
}

export enum ToolboxMagicType {
  LINE = 'line',
  BAR = 'bar',
  STACK = 'stack',
  TILED = 'tiled',
}

export enum ThrottleType {
  DEBOUNCE = 'debounce',
  fixrate = 'fixRate',
}

export enum BrushType {
  RECT = 'rect',
  POLYGON = 'polygon',
  LINEX = 'lineX',
  LINEY = 'lineY',
  KEEP = 'keep',
  CLEAR = 'clear',
}

export enum BrushMode {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
}

export enum GraphicType {
  IMAGE = 'image',
  TEXT = 'text',
  CIRCLE = 'circle',
  SECTOR = 'sector',
  RING = 'ring',
  POLYGON = 'polygon',
  POLYLINE = 'polyline',
  RECT = 'rect',
  LINE = 'line',
  BEZIERCURVE = 'bezierCurve',
  ARC = 'arc',
  GROUP = 'group',
}

export enum GraphicAction {
  MERGE = 'merge',
  REPLACE = 'replace',
  REMOVE = 'remove',
}

export enum VisualMapDimension {
  X = 0,
  Y = 1,
}

export enum AxisIndexType {
  X = 'xAxisIndex',
  Y = 'yAxisIndex',
}

export enum GraphLayoutType {
  NONE = 'none',
  FORCE = 'force',
  CIRCULAR = 'circular',
}

export enum LabelLayoutType {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export enum LabelTextLocation {
  HIDDEN = 'HIDDEN',
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
}

export enum LabelSecondaryIndicatorType {
  PERIOD = 'PERIOD',
  STANDARD = 'STANDARD',
}

export enum LabelSecondaryIndicatorPeriod {
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
}

export enum LabelSecondaryIndicatorMarkType {
  PERCENTAGE = 'PERCENTAGE',
  INCREMENTAL = 'INCREMENTAL',
}

export enum LabelStyleType {
  LINE = 'LINE',
  SOLID = 'SOLID',
}

export enum FontSize {
  SMALL = 'SMALL',
  NORMAL = 'NORMAL',
  LARGE = 'LARGE',
}

export enum UIFontStyle {
  BOLD = 'BOLD',
  ITALIC = 'ITALIC',
}

export enum ShelveType {
  COLUMNS = 'columns',
  ROWS = 'rows',
  AGGREGATIONS = 'aggregations',
}

export enum ShelveFieldType {
  DIMENSION = 'dimension',
  MEASURE = 'measure',
  CALCULATED = 'calculated',
  TIMESTAMP = 'timestamp',
  NONE = 'none',
}

export enum ChartPivotType {
  COLS = 'cols',
  ROWS = 'rows',
  AGGS = 'aggs',
}

export enum ChartColorType {
  DIMENSION = 'dimension',
  SERIES = 'series',
  MEASURE = 'measure',
  SINGLE = 'single',
}

export const ChartColorListMain = {
  SC1: ['#054B66', '#00BBE0', '#0A9396', '#94D2BD', '#E9D8A6', '#EE9B00', '#CA6702', '#9B2226', '#888344', '#148731'],
  SC2: ['#83944C', '#445128', '#283618', '#93987C', '#FEFAE0', '#EECE9F', '#DDA15E', '#CD8742', '#AA6122', '#977B37'],
  SC3: ['#D9ED92', '#B5E48C', '#99D98C', '#76C893', '#52B69A', '#34A0A4', '#168AAD', '#1A759F', '#1E6091', '#184E77'],
  SC4: ['#8ECAE6', '#58B4D1', '#219EBC', '#126782', '#023047', '#817425', '#FFB703', '#FD9E02', '#FB8500', '#B56203'],
  SC5: ['#FEC5BB', '#FCD5CE', '#FAE1DD', '#F8EDEB', '#E8E8E4', '#D8E2DC', '#ECE4DB', '#FFE5D9', '#FFD7BA', '#FEC89A'],
  SC6: ['#991E8D', '#B927AB', '#BF1EC2', '#DC5DEA', '#8234A3', '#7851B5', '#7775C7', '#8DB3D9', '#8AD1E3', '#93EDE3'],
  SC7: ['#E5B893', '#512E3F', '#B88651', '#916F65', '#BFAC8D', '#683F50', '#A69781', '#A1687D', '#7E6D63', '#756062'],
  SC8: ['#22223B', '#E4AB25', '#5D6283', '#C9ADA7', '#55D536', '#4A4A82', '#F2D592', '#A0A3BB', '#EFE7E6', '#9CE689'],
  SC9: ['#218380', '#8F2D56', '#FFBC42', '#EA4F34', '#73D2DE', '#D81159', '#535365', '#F8A363', '#B41F58', '#4AB067'],

  VC1: ['#ffcaba', '#fb7661', '#f23a2c', '#d73631', '#9a0b2c'],
  VC2: ['#f6f4b7', '#fee330', '#fbb700', '#f28a00', '#fb6e2c'],
  VC3: ['#d1e5c2', '#97cb63', '#72b235', '#4b8a21', '#2d681a'],
  VC4: ['#b5e0e1', '#75c4be', '#2b9a9e', '#026e7f', '#064059'],
  VC5: ['#c4eeed', '#8adfe9', '#58b5da', '#3f72c1', '#23399f'],
  VC6: ['#efdffd', '#b099f0', '#8d6dd2', '#6344ad', '#391f8a'],
  VC7: ['#fcc9dd', '#fc79ac', '#e03c8f', '#ad037c', '#4c006a'],
  VC8: ['#f4fafe', '#cae6fc', '#a0d2fa', '#75bef8', '#4baaf5', '#2196f3', '#1c80cf', '#1769aa', '#125386', '#0d3c61'],
  VC9: ['#f6fbf6', '#d4ecd5', '#b2ddb4', '#90cd93', '#6ebe71', '#4caf50', '#419544', '#357b38', '#2a602c', '#1e4620'],
  VC10: ['#fffcf5', '#fef0cd', '#fde4a5', '#fdd87d', '#fccc55', '#fbc02d', '#d5a326', '#b08620', '#8a6a19', '#644d12'],
  VC11: ['#f2fcfd', '#c2eff5', '#91e2ed', '#61d5e4', '#30c9dc', '#00bcd4', '#00a0b4', '#008494', '#006775', '#004b55'],
  VC12: ['#fef4f7', '#fac9da', '#f69ebc', '#f1749e', '#ed4981', '#e91e63', '#c61a54', '#a31545', '#801136', '#5d0c28'],
  VC13: ['#f5f6fb', '#d1d5ed', '#acb4df', '#8893d1', '#6372c3', '#3f51b5', '#36459a', '#2c397f', '#232d64', '#192048'],
  VC14: ['#f2faf9', '#c2e6e2', '#91d2cc', '#61beb5', '#30aa9f', '#009688', '#008074', '#00695f', '#00534b', '#003c36'],
  VC15: ['#fff8f2', '#fde0c2', '#fbc791', '#f9ae61', '#f79530', '#f57c00', '#d06900', '#ac5700', '#874400', '#623200'],
  VC16: ['#f7f9f9', '#d9e0e3', '#bbc7cd', '#9caeb7', '#7e96a1', '#607d8b', '#526a76', '#435861', '#35454c', '#263238'],
  VC17: ['#faf4fb', '#e7cbec', '#d4a2dd', '#c279ce', '#af50bf', '#9c27b0', '#852196', '#6d1b7b', '#561561', '#3e1046'],
  VC18: ['#FAFAFA', '#F5F5F5', '#EEEEEE', '#E0E0E0', '#BDBDBD', '#9E9E9E', '#757575', '#616161', '#424242', '#212121'],
  VC19: ['#f7f9fb', '#d6e0eb', '#b6c7da', '#96aeca', '#7596ba', '#557daa', '#486a91', '#3c5877', '#2f455e', '#223244'],
  VC20: ['#FBF8CC', '#FDE4CF', '#FFCFD2', '#F1C0E8', '#CFBAF0', '#A3C4F3', '#90DBF4', '#8EECF5', '#B9FBC0'],
  VC21: ['#582F0E', '#7F4F24', '#936639', '#A68A64', '#B6AD90', '#C2C5AA', '#A4AC86', '#656D4A', '#333D29'],
  VC22: ['#FFBA08', '#F48C06', '#E85D04', '#DC2F02', '#D00000', '#9D0208', '#6A040F', '#370617', '#03071E'],
  VC23: ['#F65353', '#EE7C71', '#E5A68E', '#DDCFAC', '#D4F8C9', '#A2CCBA', '#70A0AB', '#3D749C', '#0B488D'],
  VC24: ['#E7F4BA', '#DEE2B5', '#D4D0B0', '#CABEAD', '#C0ACA7', '#B998A1', '#BA7DA0', '#C555A9', '#E015C5'],
  VC25: ['#C60B0B', '#D34535', '#E0805F', '#EDBA88', '#FAF4B2', '#BEEB8C', '#81E366', '#45DA3F', '#08D119'],
  VC26: ['#EC6E1A', '#E88D52', '#E5AC8A', '#E1CAC1', '#DDE9F9', '#A9DFF2', '#74D6EA', '#40CCE3', '#0BC2DB'],
  VC27: ['#FDB0DD', '#EABDE5', '#D7CBED', '#C3D8F4', '#B0E5FC', '#A3ADE0', '#9674C5', '#883CA9', '#7B038D'],
};

export const ChartColorList = {
  ...ChartColorListMain,
  RVC1: Object.values(ChartColorListMain.VC1).reverse(),
  RVC2: Object.values(ChartColorListMain.VC2).reverse(),
  RVC3: Object.values(ChartColorListMain.VC3).reverse(),
  RVC4: Object.values(ChartColorListMain.VC4).reverse(),
  RVC5: Object.values(ChartColorListMain.VC5).reverse(),
  RVC6: Object.values(ChartColorListMain.VC6).reverse(),
  RVC7: Object.values(ChartColorListMain.VC7).reverse(),
  RVC8: Object.values(ChartColorListMain.VC8).reverse(),
  RVC9: Object.values(ChartColorListMain.VC9).reverse(),
  RVC10: Object.values(ChartColorListMain.VC10).reverse(),
  RVC11: Object.values(ChartColorListMain.VC11).reverse(),
  RVC12: Object.values(ChartColorListMain.VC12).reverse(),
  RVC13: Object.values(ChartColorListMain.VC13).reverse(),
  RVC14: Object.values(ChartColorListMain.VC14).reverse(),
  RVC15: Object.values(ChartColorListMain.VC15).reverse(),
  RVC16: Object.values(ChartColorListMain.VC16).reverse(),
  RVC17: Object.values(ChartColorListMain.VC17).reverse(),
  RVC18: Object.values(ChartColorListMain.VC18).reverse(),
  RVC19: Object.values(ChartColorListMain.VC19).reverse(),
  RVC20: Object.values(ChartColorListMain.VC20).reverse(),
  RVC21: Object.values(ChartColorListMain.VC21).reverse(),
  RVC22: Object.values(ChartColorListMain.VC22).reverse(),
  RVC23: Object.values(ChartColorListMain.VC23).reverse(),
  RVC24: Object.values(ChartColorListMain.VC24).reverse(),
  RVC25: Object.values(ChartColorListMain.VC25).reverse(),
  RVC26: Object.values(ChartColorListMain.VC26).reverse(),
  RVC27: Object.values(ChartColorListMain.VC27).reverse(),
};

export enum WaterfallBarColor {
  POSITIVE = '#c23531',

  NEGATIVE = '#304554',
}

export enum MeasureColorRange {
  OUTOF_RANGE = '#3c4950',

  DEFAULT = '#c94819',
}

export enum AxisDefaultColor {
  AXIS_LINE_COLOR = '#bfbfbf',

  LABEL_COLOR = '#8f96a0',

  LINE_COLOR = '#f2f2f2',
}

export enum ColorCustomMode {
  NONE = 'NONE',
  SECTION = 'SECTION',
  GRADIENT = 'GRADIENT',
}

export enum ColorRangeType {
  SECTION = 'section',
  GRADIENT = 'gradient',
}

export enum EventType {
  CUMULATIVE = 'cumulativeMode',

  CHANGE_PIVOT = 'changePivot',

  MAP_CHANGE_OPTION = 'mapChangeOption',

  MAP_SPATIAL_ANALYSIS = 'spatialAnalysis',
  MAP_SPATIAL_REANALYSIS = 'spatialReAnalysis',

  GRID_ORIGINAL = 'gridViewType',

  SERIES_VIEW = 'barSeriesViewType',

  GRANULARITY = 'onChangeGranularity',

  AGGREGATION = 'onChangeAggregationType',

  CHART_TYPE = 'chartType',

  FILTER = 'filter',

  PIVOT_ALIAS = 'pivotAlias',

  DASHBOARD_ALIAS = 'dashboardAlias',
}

export const GridCellColorList = {
  LINE1: [
    ['#a1e1f8', '#89cdeb', '#59a4d2', '#418fc5', '#297bb8', '#246ea5', '#1e6191', '#19537e', '#13466b', '|'],
    ['#777', '#777', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff'],
  ],
  CONT1: [
    [
      '#253ba2',
      '#374daf',
      '#4668b5',
      '#567dbd',
      '#638fc0',
      '#85a6cc',
      '#a0bad7',
      '#cbd8e6',
      '#f9f9f9',
      '#f6d3d3',
      '#f1b8b8',
      '#eb9999',
      '#dc6e6e',
      '#cc4d4d',
      '#cc3333',
      '#b71414',
      '#990a00',
    ],
    [
      '#fff',
      '#fff',
      '#fff',
      '#fff',
      '#fff',
      '#fff',
      '#fff',
      '#777',
      '#777',
      '#777',
      '#fff',
      '#fff',
      '#fff',
      '#fff',
      '#fff',
      '#fff',
      '#fff',
    ],
  ],
};

export enum ChartGradientType {
  LINEAR = 'LINEAR',
  CONTRAST = 'CONTRAST',
  CUSTOM = 'CUSTOM',
}

export enum CellColorTarget {
  BACKGROUND = 'BACKGROUND',
  TEXT = 'TEXT',
}

export enum Operator {
  SUM = 'SUM',
  AVERAGE = 'AVERAGE',
  MAX = 'MAX',
  MIN = 'MIN',
  COUNT = 'COUNT',
}

export enum AxisOrientType {
  X = 'X',
  Y = 'Y',
}

export enum AxisLabelType {
  ROW = 'row',
  COLUMN = 'column',
  SUBROW = 'sub_row',
  SUBCOLUMN = 'sub_column',
  SIMPLE = 'simple',
  AGGREGATION = 'aggregation',
}

export enum LabelConvertType {
  NAME = 'name',
  SHOWNAME = 'showName',
  SHOWMARK = 'showMark',
  MARK = 'mark',
  SCALED = 'scaled',
  SHOWVALUE = 'showValue',
  SHOWLABEL = 'showLabel',
  ALIGN = 'align',
  AXISCONFIG = 'axisConfig',
}

export enum AxisLabelMark {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
  SLOPE = 'SLOPE',
}

export enum LegendConvertType {
  SHOW = 'show',
  COUNT = 'count',
}

export enum DataZoomConverType {
  SHOW = 'show',
  RANGE = 'range',
}

export enum SeriesConvertType {
  MARK = 'mark',
  SHOW = 'show',
  UNITTYPE = 'unitType',
  ACCUMULATE = 'isAccumulate',
  SHAPE = 'shape',
  ALIGN = 'align',
  VALIGN = 'valign',
  LAYOUT = 'layout',
  FORMAT = 'format',
  DECIMAL = 'decimal',
  BAR = 'bar',
  LINE = 'line',
  LABEL = 'label',
  ROTATE = 'rotate',
}

export enum BarMarkType {
  MULTIPLE = 'MULTIPLE',
  STACKED = 'STACKED',
}

export enum DataUnit {
  NONE = 'NONE',
  PERCENT = 'PERCENT',
}

export enum LineMarkType {
  LINE = 'LINE',
  AREA = 'AREA',
}

export enum LineCornerType {
  STRAIGHT = 'STRAIGHT',
  SMOOTH = 'SMOOTH',
}

export enum LineStyleType {
  POINT_LINE = 'POINT_LINE',
  POINT = 'POINT',
  LINE = 'LINE',
}

export enum LineMode {
  NORMAL = 'NORMAL',
  CUMULATIVE = 'CUMULATIVE',
}

export enum PointSize {
  NORMAL = 'NORMAL',
  SMALL = 'SMALL',
  LARGE = 'LARGE',
  XLARGE = 'XLARGE',
}

export enum GridViewType {
  PIVOT = 'PIVOT',
  MASTER = 'MASTER',
}

export enum AnnotationPosition {
  TOP_RIGHT = 'TOP_RIGHT',
  TOP_LEFT = 'TOP_LEFT',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
}

export enum PieSeriesViewType {
  SECTOR = 'SECTOR',
  DONUT = 'DONUT',
}

export enum ValueFormat {
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
}

export enum DataZoomRangeType {
  COUNT = 'COUNT',
  PERCENT = 'PERCENT',
}

export enum AxisOrientType {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export enum ChartMouseMode {
  SINGLE = 'single',
  MULTI = 'multi',
  DRAGZOOMIN = 'dragzoomin',
  ZOOMIN = 'zoomin',
  ZOOMOUT = 'zoomout',
  REVERT = 'revert',
}

export enum ChartSelectMode {
  ADD = 'add',
  SUBTRACT = 'subtract',
  CLEAR = 'clear',
}

export enum UIPosition {
  AUTO = 'AUTO',
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
  CENTER = 'CENTER',
  TOP = 'TOP',
  MIDDLE = 'MIDDLE',
  BOTTOM = 'BOTTOM',
  RIGHT_BOTTOM = 'RIGHT_BOTTOM',
  LEFT_BOTTOM = 'LEFT_BOTTOM',
  RIGHT_TOP = 'RIGHT_TOP',
  LEFT_TOP = 'LEFT_TOP',
}

export enum UIOrient {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
  BOTH = 'BOTH',
}

export enum PointShape {
  CIRCLE = 'CIRCLE',
  RECT = 'RECT',
  TRIANGLE = 'TRIANGLE',
  DIAMOND = 'DIAMOND',
  PIN = 'PIN',
  ARROW = 'ARROW',
}

export enum UIFormatType {
  NUMBER = 'number',
  CURRENCY = 'currency',
  PERCENT = 'percent',
  EXPONENT10 = 'exponent10',
  TIME_CONTINUOUS = 'time_continuous',
  TIME_CUSTOM = 'time_custom',
}

export enum UIFormatCurrencyType {
  KRW = 'KRW',
  USD = 'USD',
  USCENT = 'USCENT',
  GBP = 'GBP',
  JPY = 'JPY',
  EUR = 'EUR',
  CNY = 'CNY',
  RUB = 'RUB',
}

export enum UIFormatNumericAliasType {
  NONE = 'NONE',
  AUTO = 'AUTO',
  KILO = 'KILO',
  MEGA = 'MEGA',
  GIGA = 'GIGA',
}

export enum UIFormatSymbolPosition {
  BEFORE = 'BEFORE',
  AFTER = 'AFTER',
}

export enum UIFontSize {
  DEFAULT = 'default',
  SMALLER = 'smaller',
  LARGER = 'larger',
}

export enum ChartAxisGridType {
  TEXT = 'text',
  NUMERIC = 'numeric',
  DATETIME = 'datetime',
}

export enum ChartAxisLabelType {
  VALUE = 'value',
  CATEGORY = 'category',
}

export enum DataLabelPosition {
  OUTSIDE_TOP = 'OUTSIDE_TOP',
  INSIDE_TOP = 'INSIDE_TOP',
  INSIDE_BOTTOM = 'INSIDE_BOTTOM',
  CENTER = 'CENTER',
  OUTSIDE_RIGHT = 'OUTSIDE_RIGHT',
  INSIDE_RIGHT = 'INSIDE_RIGHT',
  INSIDE_LEFT = 'INSIDE_LEFT',
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
}

export enum TextAlign {
  DEFAULT = 'DEFAULT',
  LEFT = 'LEFT',
  CENTER = 'CENTER',
  RIGHT = 'RIGHT',
}

export enum ShelfType {
  PIVOT = 'pivot',
  GRAPH = 'graph',
  GEO = 'geo',
}

export enum LayerViewType {
  ORIGINAL = 'original',
  HASH = 'hash',
  CLUSTERING = 'clustering',
}

export enum FormatType {
  DEFAULT = 'default',
  GEO = 'geo',
  GEO_HASH = 'geo_hash',
  GEO_BOUNDARY = 'geo_boundary',
  GEO_JOIN = 'geo_join',
}
