<div class="ddp-ui-chart-side ddp-show">
  <div class="ddp-ui-side-title">
    {{ 'msg.page.common.kpi.indocator.setting' | translate }}
  </div>
  <div class="ddp-wrap-downmenu" *ngIf="dashboard">
    <div class="ddp-box-down">
      <div class="ddp-wrap-divide">
        <div class="ddp-divide2">
          <div class="ddp-wrap-option-slider">
            <span class="ddp-label-slider">{{ 'msg.page.common.kpi.indocator.use' | translate }}</span>

            <div class="ddp-checkbox-slide ddp-checkbox-automatic2">
              <input type="checkbox" (click)="$event.stopPropagation(); changeTargetUse()" [ngModel]="isShow()" />
              <label><span class="ddp-slide"></span></label>
            </div>
          </div>

          <div [ngClass]="{ 'ddp-wrap-disabled ddp-disabled': !isShow() }" class="ddp-list-sub2">
            <div class="ddp-divide2">
              <div class="ddp-list-label">{{ 'msg.page.common.kpi.indocator.target' | translate }}</div>
              <component-select
                #targetListComp
                [array]="targetList"
                [viewKey]="'name'"
                (onSelected)="changeTarget($event)"
              ></component-select>
            </div>

            <div class="ddp-divide2">
              <div class="ddp-list-label">{{ 'msg.page.common.kpi.indocator.type' | translate }}</div>
              <component-select #indicatorListComp [array]="indicatorList" [viewKey]="'name'"
                (onSelected)="changeIndicatorType($event['value'])"></component-select>
            </div>

            <div class="ddp-list-sub3">
              <div class="ddp-divide2">
                <div *ngIf="!isPeriod" class="ddp-wrap-option-multy ddp-clear">
                  <div class="ddp-col-4">
                    <span class="ddp-label-txt">{{ 'msg.page.common.kpi.indocator.standard' | translate }}</span>
                  </div>
                  <div class="ddp-col-8">
                    <input type="text" class="ddp-input-typebasic" [(ngModel)]="standardValueTemp"
                      (keyup.enter)="applyStandardValue()" (clickOutside)="revokeTargetValue()" maxlength="50" />
                  </div>
                </div>
              
                <div *ngIf="isPeriod" class="ddp-wrap-option-multy ddp-clear">
                  <div class="ddp-col-4">
                    <span class="ddp-label-txt">{{ 'msg.page.common.kpi.indocator.period' | translate }}</span>
                  </div>
                  <div class="ddp-col-8">
                    <component-select 
                      #periodListComp 
                      [array]="periodList" 
                      [viewKey]="'name'"
                      (onSelected)="changePeriodType($event['value'])">
                    </component-select>
                  </div>
                </div>
              </div>
              <div class="ddp-divide2">
                <div class="ddp-list-label">{{ 'msg.page.common.kpi.indocator.display' | translate }}</div>
                <ul class="ddp-list-express ddp-type2">
                  <li [class.ddp-selected]="getMarkSelected('INCREMENTAL')" (click)="changeMarkType('INCREMENTAL')">
                    <em class="ddp-icon-updown"></em>
                    {{ 'msg.page.common.kpi.indocator.incremental' | translate }}
                  </li>
                  <li [class.ddp-selected]="getMarkSelected('PERCENTAGE')" (click)="changeMarkType('PERCENTAGE')">
                    <em class="ddp-icon-percent-type"></em>
                    {{ 'msg.page.common.kpi.indocator.percentage' | translate }}
                  </li>
                </ul>
              </div>

              <div class="ddp-divide2">
                <div class="ddp-part-check-b">
                  <label class="ddp-label-checkbox ddp-position">
                    <input type="checkbox" [ngModel]="getEmphasizedSelected()" (click)="changeEmphasized()" />
                    <i class="ddp-icon-checkbox"></i>
                    <span class="ddp-txt-checkbox">{{ 'msg.page.common.kpi.indocator.emphasized' | translate }}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
