import { Widget, WidgetConfiguration } from './widget.model';
import { generateUuid } from '@selfai-platform/shared';

export interface TextWidget extends Widget {
  type: 'text';
  configuration: TextWidgetConfiguration;
}

export function createTextWidget(contents: string, dashboardId: string, params: Partial<TextWidget> = {}): TextWidget {
  return {
    id: generateUuid(),
    name: 'Text Widget',
    type: 'text',
    description: '',
    dashBoardId: dashboardId,
    configuration: createTextWidgetConfiguration(contents),
    ...params,
  };
}

export interface TextWidgetConfiguration extends WidgetConfiguration {
  contents: string;
}

export function createTextWidgetConfiguration(
  contents: string,
  params: Partial<TextWidgetConfiguration> = {},
): TextWidgetConfiguration {
  return {
    type: 'text',
    contents,
    ...params,
  };
}
