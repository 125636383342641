import { cloneDeep } from 'lodash';

import { Filter } from '@selfai-platform/bi-domain';

import { FilterUtil } from '../util/filter.util';

export interface ConvertSpecToServerParams {
  [key: string]: any;
}

interface ConvertSpecToServerParamsConfig {
  filter?: Filter;
  filters?: Filter[];
  pivot?: Pivot;
  dataSource?: DataSource;
}

interface Pivot {
  columns: FieldFilter[];
  rows: FieldFilter[];
  aggregations: FieldFilter[];
}

interface FieldFilter {
  field?: FilterSpec;
}

interface FilterSpec {
  filter?: unknown;
}

interface DataSource {
  engineName?: string;
  name?: string;
}

/**
 * @deprecated
 */
export function convertSpecToServer(spec: unknown | null | undefined) {
  const param = cloneDeep(spec) as ConvertSpecToServerParams;

  if (!param || !param.configuration) {
    return param;
  }

  const conf = <ConvertSpecToServerParamsConfig>param.configuration;
  if (conf && conf.filter) {
    param.configuration.filter = FilterUtil.convertToServerSpecForDashboard(conf.filter);
  }
  if (conf && conf.filters) {
    param.configuration.filters = conf.filters.map((item) => FilterUtil.convertToServerSpecForDashboard(item));
  }
  if (conf && conf.pivot) {
    if (conf.pivot.columns) {
      conf.pivot.columns.forEach((item) => {
        delete item.field.filter;
      });
    }
    if (conf.pivot.rows) {
      conf.pivot.rows.forEach((item) => {
        delete item.field.filter;
      });
    }
    if (conf.pivot.aggregations) {
      conf.pivot.aggregations.forEach((item) => {
        delete item.field?.filter;
      });
    }
  }
  if (conf && conf.dataSource && conf.dataSource.engineName) {
    param.configuration.dataSource.name = conf.dataSource.engineName;
    delete conf.dataSource.engineName;
  }
  return param;
}
