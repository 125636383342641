import { Inject, Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { MAIN_MENU_CHUNK } from './main-menu-chunk.token';
import { PipelineMenuItemService } from './pipeline-menu-item.service';
import { KdMenuItemService } from './kd-menu-item.service';

@Injectable({ providedIn: 'root' })
export class MenuItemsService extends BehaviorSubject<MenuItem[]> {
  constructor(
    @Inject(MAIN_MENU_CHUNK) private readonly mainMenuChunks: (MenuItem | null)[],
    private readonly pipelineMenuItemService: PipelineMenuItemService,
    private readonly kdMenuItemService: KdMenuItemService,
  ) {
    super([]);
    combineLatest([this.pipelineMenuItemService.pipelineMenuItems$, this.kdMenuItemService.kdMenuItems$])
      .pipe(map((items) => items.flat(1)))
      .subscribe((menuItems) => {
        this.initMenu(menuItems);
      });
  }

  get menu$() {
    return this.asObservable();
  }

  initMenu(initailMenuITems: MenuItem[] = []) {
    const menu = [...initailMenuITems];

    if (this.mainMenuChunks?.length) {
      const menuItems: MenuItem[] = this.mainMenuChunks.filter(
        (rootItem) => rootItem && (!this.menuIsEmpty(rootItem) || rootItem.routerLink || rootItem.url),
      ) as MenuItem[];
      menu.push(...menuItems);
    }

    this.next(this.mergeRootItemsById(this.sortRootItems(menu)));
  }

  private menuIsEmpty(menu: MenuItem): boolean {
    return !menu.items?.length;
  }

  private sortRootItems(menuItems: MenuItem[]): MenuItem[] {
    return menuItems.sort((a, b) => {
      const sortA: number = a.state?.['sort'] || Infinity;
      const sortB: number = b.state?.['sort'] || Infinity;

      return sortA - sortB;
    });
  }

  private mergeRootItemsById(menuItems: MenuItem[]): MenuItem[] {
    const mergedItems = new Map<string | MenuItem, MenuItem>();

    menuItems.forEach((item) => {
      if (item.id) {
        const prevItems = mergedItems.get(item.id)?.items || [];
        mergedItems.set(item.id, { ...item, items: [...prevItems, ...(item.items || [])] });
      } else {
        mergedItems.set(item, item);
      }
    });

    return Array.from(mergedItems.values());
  }
}
