import { Component, ElementRef, EventEmitter, HostListener, Injector, Output, ViewChild } from '@angular/core';

import * as _ from 'lodash';

import {
  BoardDataSource,
  createFieldValueAlias,
  DatasourceField as Field,
  FieldValueAlias,
} from '@selfai-platform/bi-domain';

import { AbstractComponent } from '../../common/component/abstract.component';
import { GridComponent } from '../../common/component/grid/grid.component';
import { GRID_EDIT_TYPE, header, SlickGridHeader } from '../../common/component/grid/grid.header';
import { GridOption } from '../../common/component/grid/grid.option';
import { DashboardUtil } from '../../dashboard/util/dashboard.util';
import { DatasourceAliasService } from '../../datasource/service/datasource-alias.service';
import { DatasourceService } from '../../datasource/service/datasource.service';

@Component({
  selector: 'popup-value-alias',
  templateUrl: './popup-value-alias.component.html',
})
export class PopupValueAliasComponent extends AbstractComponent {
  @ViewChild('gridArea')
  private gridComponent: GridComponent;

  private _isEdit = false;

  private _gridData: { field: string; alias: string }[] = [];

  private _fieldValueAlias: FieldValueAlias;

  public showFl: boolean;

  public selectedField: Field;

  @Output('changeAlias')
  public changeAliasEvent: EventEmitter<FieldValueAlias> = new EventEmitter();

  constructor(
    private aliasService: DatasourceAliasService,
    private datasourceService: DatasourceService,
    protected elementRef: ElementRef,
    protected injector: Injector,
  ) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public init(field: Field, dataSource: BoardDataSource, fieldValueAlias: FieldValueAlias) {
    this.selectedField = field;

    if (fieldValueAlias) {
      this._isEdit = true;
      this._fieldValueAlias = fieldValueAlias;
      this._setGrid(fieldValueAlias);
    } else {
      this._isEdit = false;
      const valueAlias: FieldValueAlias = createFieldValueAlias({
        dashBoardId: field.boardId,
        dataSourceId: field.dsId,
        fieldName: field.name,
      });
      this._fieldValueAlias = valueAlias;
      const param = {
        dataSource: DashboardUtil.getDataSourceForApi(_.cloneDeep(dataSource), field.dsId),
        targetField: { alias: field.alias, type: 'dimension', name: field.name },
      };
      this.pageLoaderService.show();
      this.datasourceService
        .getCandidate(param)
        .then((result) => {
          const valueAlias = {};
          if (result && 0 < result.length) {
            result.forEach((item) => {
              valueAlias[item.field] = item.field;
            });
          }
          this._fieldValueAlias.valueAlias = valueAlias;

          this._setGrid(this._fieldValueAlias);
          this.pageLoaderService.hide();
        })
        .catch(() => {
          this.pageLoaderService.hide();
        });
    }
  }

  public done() {
    if (this.gridComponent) {
      $(this.gridComponent.grid.getActiveCellNode()).removeClass('ddp-selected');
      this.gridComponent.grid.getEditorLock().commitCurrentEdit();
    }

    this._gridData.forEach((item) => {
      this._fieldValueAlias.valueAlias[item.field] = item.alias;
    });

    this.pageLoaderService.show();
    if (this._isEdit) {
      this.aliasService.updateAliases(this._fieldValueAlias.id, this._fieldValueAlias).then((item) => {
        this.selectedField.valueAlias = <FieldValueAlias>item;
        this.changeAliasEvent.emit(<FieldValueAlias>item);
        this.showFl = false;
        this.pageLoaderService.hide();
      });
    } else {
      this.aliasService.createAliases(this._fieldValueAlias).then((item) => {
        this.selectedField.valueAlias = <FieldValueAlias>item;
        this.changeAliasEvent.emit(<FieldValueAlias>item);
        this.showFl = false;
        this.pageLoaderService.hide();
      });
    }
  }

  public resetAll() {
    this._setGrid(this._fieldValueAlias);
  }

  @HostListener('click', ['$event.target'])
  public clickOther(target) {
    if (this.gridComponent) {
      const $eventTarget: JQuery = $(target);
      if (!$eventTarget.hasClass('ui-widget-content') && 0 === $eventTarget.closest('.ui-widget-content').length) {
        this.gridComponent.resetActiveCell();
      }
    }
  }

  private _setGrid(aliasData: FieldValueAlias) {
    this.showFl = true;

    this.changeDetect.detectChanges();

    this.gridComponent.destroy();

    const data = [];
    for (const key in aliasData.valueAlias) {
      const value = {};
      value['field'] = key;
      value['alias'] = aliasData.valueAlias[key];
      data.push(value);
    }
    this._gridData = data;

    const valueMsg = this.translateService.instant('msg.board.datasource.alias.for.value.header.value');
    const forValueMsg = this.translateService.instant('msg.board.datasource.alias.for.value.title');
    const headers: header[] = [];
    headers.push(
      new SlickGridHeader()
        .Id('field')
        .Name(valueMsg)
        .Field('field')
        .CssClass('ddp-tleft')

        .Width(429)
        .CannotTriggerInsert(true)
        .Focusable(false)
        .Sortable(true)
        .build(),
    );
    headers.push(
      new SlickGridHeader()
        .Id('alias')
        .Name(forValueMsg)
        .Field('alias')
        .CssClass('ddp-tleft')

        .Editor(GRID_EDIT_TYPE.TEXT)
        .Width(429)
        .CannotTriggerInsert(true)
        .Sortable(true)
        .build(),
    );

    this.gridComponent.create(
      headers,
      data,
      new GridOption()
        .SyncColumnCellResize(true)
        .MultiColumnSort(true)
        .RowHeight(32)
        .CellExternalCopyManagerActivate(true)
        .SelectedCellCssClass('ddp-selected')
        .Editable(true)
        .build(),
    );
  }
}
