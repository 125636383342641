import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { GraphNodeOptionSerialized } from '@selfai-platform/pipeline-common';
import { DestroyService } from '@selfai-platform/shared';
import { MenuItem } from 'primeng/api';
import { map, Observable } from 'rxjs';
import { FavoriteCubeListService } from '../../services/favorite-cubes/favorite-cube-list.service';

interface FavoriteCubeMenuItem extends MenuItem {
  node: GraphNodeOptionSerialized;
}

@Component({
  selector: 'selfai-platform-favorite-cube-list',
  templateUrl: './favorite-cube-list.component.html',
  styleUrls: ['./favorite-cube-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class FavoriteCubeListComponent implements OnInit {
  favoriteCubeList$!: Observable<FavoriteCubeMenuItem[]>;

  constructor(private readonly favoriteCubeListService: FavoriteCubeListService) {}

  ngOnInit(): void {
    this.favoriteCubeListService.loadList();

    this.favoriteCubeList$ = this.favoriteCubeListService.pipe(
      map((cubes) => {
        return cubes.map((cube) => ({
          id: cube.id,
          label: `${cube.label || cube.node.uiName}`,
          title: `${cube.node.operation.name}`,
          node: cube.node,
        }));
      }),
    );
  }

  removeItem(item: MenuItem): void {
    if (item.id) {
      this.favoriteCubeListService.removeItem(item.id);
    }
  }
}
