import { TreeNode } from 'primeng/api';
import { GraphNodeOptionSerialized } from '../node';
import { ModelBodyNodeOperation } from './ModelBody';

export enum TreeNodeType {
  WORKFLOW = 'workflow',
  CUBE = 'cube',
  OPERATION = 'operation',
  LOADING = 'loading',
  EMPTY = 'emptyNode',
  RECYCLEBIN = 'recycleBin',
  REMOVEDCUBE = 'removedCube',
}

export type WorkflowTreeModel = TreeNodeWorkflow | TreeNodeCube | TreeNodeOperation | TreeNodeEmpty;

export interface TreeNodeWorkflow
  extends Omit<
    TreeNode<{
      workflowId: string;
    }>,
    'children'
  > {
  type: TreeNodeType.WORKFLOW;
  children?: (TreeNodeOperation | TreeNodeEmpty | TreeNodeRecycleBin)[];
}

export interface TreeNodeCube
  extends Omit<
    TreeNode<{
      node: GraphNodeOptionSerialized;
      jsonParameters: string;
      isInCurrentWorkflow: boolean;
    }>,
    'children' | 'parent'
  > {
  type: TreeNodeType.CUBE;
  parent?: TreeNodeOperation;
}

export interface TreeNodeOperation
  extends Omit<
    TreeNode<{
      operation: ModelBodyNodeOperation;
    }>,
    'children' | 'parent'
  > {
  type: TreeNodeType.OPERATION;
  children: TreeNodeCube[];
  parent?: TreeNodeWorkflow;
}

export interface TreeNodeRecycleBin extends Omit<TreeNode, 'children'> {
  type: TreeNodeType.RECYCLEBIN;
  children: TreeNodeRemovedCube[];
}

export interface TreeNodeRemovedCube
  extends Omit<
    TreeNode<{
      node: GraphNodeOptionSerialized;
      jsonParameters: string;
      isInCurrentWorkflow: boolean;
    }>,
    'children'
  > {
  type: TreeNodeType.REMOVEDCUBE;
}

export interface TreeNodeEmpty extends Omit<TreeNode, 'children'> {
  type: TreeNodeType.EMPTY | TreeNodeType.LOADING;
}
