import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AggregatorRouteApiModel } from '@selfai-platform/pipeline-common';
import { LoadingService, UrlPageParamsService } from '@selfai-platform/shared';
import {
  DataListViewComponentService,
  DataListViewItem,
  SelectedItemsService,
  TableColumn,
} from '@selfai-platform/shell';
import { ConfirmationService } from 'primeng/api';
import { Observable, map, switchMap } from 'rxjs';
import { AggregatorRouteListService } from '../../services';
import { LOADING_ROUTES_SAVE } from '../../tokens';

export interface AggregatorRouteListItem
  extends DataListViewItem,
    Pick<AggregatorRouteApiModel, 'name' | 'uuid' | 'isActive'> {}

@Injectable()
export class AggregatorRouteDataListViewService extends DataListViewComponentService<AggregatorRouteListItem> {
  processUuid$: Observable<string | undefined> = this.route.paramMap.pipe(map((params) => params.get('processUuid')));
  saving$ = this.loadingRoutesSave.isLoading();
  isSaved$ = this.aggregatorRouteListService.isSaved$;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly translate: TranslateService,
    private readonly confirmationService: ConfirmationService,
    private readonly aggregatorRouteListService: AggregatorRouteListService,
    @Inject(LOADING_ROUTES_SAVE) private readonly loadingRoutesSave: LoadingService,
    urlPageParamsService: UrlPageParamsService,
    selectedItemsService: SelectedItemsService<AggregatorRouteListItem>,
  ) {
    super(urlPageParamsService, selectedItemsService);

    this.subscription.add(
      this.processUuid$
        .pipe(switchMap((processUuid) => this.aggregatorRouteListService.loadRouteList(processUuid)))
        .subscribe(),
    );
  }

  override loadData(): Observable<{ items: AggregatorRouteListItem[]; totalItems: number }> {
    return this.processUuid$.pipe(
      switchMap((processUuid) => this.aggregatorRouteListService.loadRouteList(processUuid)),
      map((routes) => routes.map((route) => ({ ...route, id: route.uuid }))),
      map((routes) => ({ items: routes, totalItems: routes.length })),
    );
  }

  override getData(): Observable<AggregatorRouteListItem[]> {
    return this.aggregatorRouteListService
      .getRoutes()
      .pipe(
        map((aggregatorRoutes) => aggregatorRoutes.map((route) => this.addRouterLink({ ...route, id: route.uuid }))),
      );
  }

  override isLoading(): Observable<boolean> {
    return this.aggregatorRouteListService.loading$;
  }

  override isLoaded(): Observable<boolean> {
    return this.aggregatorRouteListService.loaded$;
  }

  override hasError(): Observable<boolean> {
    return this.aggregatorRouteListService.error$.pipe(map(Boolean));
  }

  getColumns(): TableColumn<AggregatorRouteListItem>[] {
    return [
      {
        labelTranslate: 'aggregator-route-list.header.name',
        fieldCode: 'name',
        fieldType: 'text',
        classStyle: 'no-breaks short-text',
        width: 'calc(100% - 45rem)',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'aggregator-route-list.header.route',
        fieldCode: 'uuid',
        fieldType: 'text',
        classStyle: 'no-breaks short-text',
        width: '15rem',
        resizable: true,
        sortable: true,
      },
      {
        labelTranslate: 'aggregator-route-list.header.active',
        fieldCode: 'isActive',
        fieldType: 'boolean',
        classStyle: 'text-center',
        width: '10rem',
        resizable: true,
        sortable: true,
      },
    ];
  }

  private addRouterLink(
    aggregatorRoute: AggregatorRouteListItem,
  ): AggregatorRouteListItem & Pick<DataListViewItem, 'routerLinkToItem'> {
    return {
      ...aggregatorRoute,
      routerLinkToItem: {
        routerLink: ['/', 'aggregators', aggregatorRoute.id],
      },
    };
  }
}
