import { Component, ElementRef, EventEmitter, Injector, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import remove from 'lodash/remove';

import {
  AggregationType,
  Candidate,
  ContainsType,
  CustomField,
  DIRECTION,
  Dashboard,
  DatasourceField as Field,
  FieldRole,
  Filter,
  InclusionFilter,
  InclusionSelectorType,
  InclusionSortBy,
  InequalityType,
  MeasureInequalityFilter,
  MeasurePositionFilter,
  PositionType,
  RegExprFilter,
  WildCardFilter,
  createCandidate,
} from '@selfai-platform/bi-domain';
import { isNullOrUndefined } from '@selfai-platform/shared';

import { SelectComponent } from '../../../common/component/select/select.component';
import { StringUtil } from '../../../common/util/string.util';
import { DatasourceService } from '../../../datasource/service/datasource.service';
import { DashboardUtil } from '../../util/dashboard.util';
import { FilterUtil } from '../../util/filter.util';
import { AbstractFilterPopupComponent } from '../abstract-filter-popup.component';

enum MatcherType {
  WILDCARD = 'WILDCARD',
  REGULAR_EXPRESSION = 'REGULAR_EXPRESSION',
}

@Component({
  selector: 'app-config-filter-inclusion',
  templateUrl: './configure-filters-inclusion.component.html',
  styleUrls: ['./configure-filters-inclusion.component.scss'],
})
export class ConfigureFiltersInclusionComponent extends AbstractFilterPopupComponent implements OnInit, OnDestroy {
  @ViewChild('inputSearch')
  private _inputSearch: ElementRef;

  @ViewChild('wildCardContains')
  private _wildCardContainsCombo: SelectComponent;

  @ViewChild('conditionField')
  private _condFieldCombo: SelectComponent;

  @ViewChild('conditionAggregation')
  private _condAggrCombo: SelectComponent;

  @ViewChild('conditionInequality')
  private _condInequalityCombo: SelectComponent;

  @ViewChild('limitPosition')
  private _limitPositionCombo: SelectComponent;

  @ViewChild('limitField')
  private _limitFieldCombo: SelectComponent;

  @ViewChild('limitAggregation')
  private _limitAggrCombo: SelectComponent;

  @Output()
  public goToSelectField: EventEmitter<any> = new EventEmitter();

  public selectedValues: Candidate[] = [];
  public isShow = false;
  public isOnlyShowCandidateValues = false;
  public useAll = false;
  public isNoData = false;
  public targetFilter: InclusionFilter;
  public pageCandidateList: Candidate[] = [];
  public currentPage = 1;
  public lastPage = 1;
  public pageSize = 15;
  public totalCount = 0;
  public totalItemCnt = 0;
  public searchText = '';
  public newCandidateName = '';
  public condition: MeasureInequalityFilter;
  public limitation: MeasurePositionFilter;
  public wildcard: WildCardFilter;
  public regExpr: RegExprFilter;
  public measureFields: Field[] = [];
  public useDefineValue = true;
  public usePaging = true;
  public matcherTypeList: any[];
  public sortBy = InclusionSortBy;
  public sortDirection = DIRECTION;

  private _candidateList: Candidate[] = [];
  private _board: Dashboard;
  private _candidateValues: Candidate[] = [];
  private _targetField: Field | CustomField;
  private _condition: MeasureInequalityFilter;
  private _limitation: MeasurePositionFilter;
  private _wildcard: WildCardFilter;
  private _regExpr: RegExprFilter;
  private selectedMatcherType: any;

  constructor(
    private datasourceService: DatasourceService,

    protected elementRef: ElementRef,
    protected injector: Injector,
  ) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();

    this.matcherTypeList = [
      { label: this.translateService.instant('msg.board.th.filter.wildcard'), value: MatcherType.WILDCARD },
      {
        label: this.translateService.instant('msg.board.th.filter.regular-expression'),
        value: MatcherType.REGULAR_EXPRESSION,
      },
    ];

    this.selectedMatcherType = this.matcherTypeList[0];
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public get isOverCandidateWarning(): boolean {
    return FilterUtil.CANDIDATE_LIMIT <= this._candidateList.length;
  }

  public get isPageCandidateListEmpty(): boolean {
    return this.pageCandidateList == null || this.pageCandidateList.length == 0;
  }

  public get candidateListSize(): number {
    return this._candidateList.length;
  }

  public showComponent(
    board: Dashboard,
    targetFilter: InclusionFilter,
    targetField: Field | CustomField,
    useDefineValue: boolean = true,
  ) {
    this.useDefineValue = useDefineValue;

    const preFilterData = {
      contains: this.wildCardTypeList[0].value,
      aggregation: this.aggregationTypeList[0].value,
      inequality: this.conditionTypeList[0].value,
      position: this.limitTypeList[0].value,
    };

    const dsFields: Field[] = DashboardUtil.getFieldsForMainDataSource(board.configuration, targetFilter.dataSource);
    this.measureFields = dsFields.filter((item) => {
      return item.role === FieldRole.MEASURE && 'user_expr' !== item.type;
    });

    const defaultData: InclusionFilter = FilterUtil.getBasicInclusionFilter(
      <Field>targetField,
      targetFilter.ui.importanceType,
      preFilterData,
    );

    if (targetFilter.preFilters) {
      targetFilter.preFilters = targetFilter.preFilters.map((data: Filter) => {
        const defaultPreFilter: Filter = defaultData.preFilters.find(
          (defaultPreFilter: Filter) => data.type === defaultPreFilter.type,
        );
        return merge(defaultPreFilter, data);
      });
    } else {
      targetFilter.preFilters = defaultData.preFilters;
    }
    targetFilter = merge({}, defaultData, targetFilter);

    targetFilter.preFilters.forEach((preFilter: Filter) => {
      if (preFilter.type === 'measure_inequality') {
        this._condition = <MeasureInequalityFilter>preFilter;
        this.condition = cloneDeep(this._condition);
      } else if (preFilter.type === 'measure_position') {
        this._limitation = <MeasurePositionFilter>preFilter;
        this.limitation = cloneDeep(this._limitation);
      } else if (preFilter.type === 'wildcard') {
        this._wildcard = <WildCardFilter>preFilter;
        this.wildcard = cloneDeep(this._wildcard);
      } else if (preFilter.type === 'regexpr') {
        this._regExpr = <RegExprFilter>preFilter;
        this.regExpr = cloneDeep(this._regExpr);
      }
    });

    if (StringUtil.isNotEmpty(this.wildcard.value)) {
      this.selectedMatcherType = this.matcherTypeList[0];
    } else if (StringUtil.isNotEmpty(this.regExpr.expr)) {
      this.selectedMatcherType = this.matcherTypeList[1];
    }

    if (targetFilter.valueList && 0 < targetFilter.valueList.length) {
      this.selectedValues = targetFilter.valueList.map((item) => this._stringToCandidate(item));
      1 < this.selectedValues.length && (targetFilter.selector = InclusionSelectorType.MULTI_LIST);
    }
    if (targetFilter.candidateValues && 0 < targetFilter.candidateValues.length) {
      this._candidateValues = targetFilter.candidateValues.map((item) => this._stringToCandidate(item));
    }
    if (targetFilter.definedValues && 0 < targetFilter.definedValues.length) {
      this._candidateList = targetFilter.definedValues.map((item) => this._stringToCandidate(item, true));
    }

    this.pageLoaderService.show();
    this.datasourceService
      .getCandidateForFilter(targetFilter, board, [], targetField, 'COUNT', this.searchText)
      .then((result) => {
        this.targetFilter = targetFilter;
        this._targetField = targetField;
        this._board = board;
        this._setCandidateResult(result, targetFilter, targetField);
        if (0 === this._candidateValues.length) {
          this._candidateValues = this._candidateList.slice(0, 100);
        }

        this.useAll = !(-1 < targetField.filteringSeq);
        if (false === this.useAll && 0 === this.selectedValues.length) {
          this.selectedValues.push(this._candidateList[0]);
        }

        this.isShow = true;
        this.safelyDetectChanges();
        this.pageLoaderService.hide();
      })
      .catch((err) => this.commonExceptionHandler(err));
  }

  public getData(): InclusionFilter {
    const filter: InclusionFilter = this.targetFilter;
    filter.valueList = this.selectedValues.map((item) => item.name);
    filter.candidateValues = this._candidateValues.map((item) => item.name);
    filter.definedValues = this._candidateList.filter((item) => item.isDefinedValue).map((item) => item.name);
    return filter;
  }

  public isSingleSelect(targetFilter: InclusionFilter): boolean {
    return (
      InclusionSelectorType.SINGLE_LIST === targetFilter.selector ||
      InclusionSelectorType.SINGLE_COMBO === targetFilter.selector
    );
  }

  public setSelectorType(targetFilter: InclusionFilter, type: string) {
    if ('SINGLE' === type) {
      if (this._isListSelector(targetFilter)) {
        targetFilter.selector = InclusionSelectorType.SINGLE_LIST;
      } else {
        targetFilter.selector = InclusionSelectorType.SINGLE_COMBO;
      }
      if (1 < this.selectedValues.length) {
        this.selectedValues = [this.selectedValues[0]];
        this.safelyDetectChanges();
      }
    } else {
      if (this._isListSelector(targetFilter)) {
        targetFilter.selector = InclusionSelectorType.MULTI_LIST;
      } else {
        targetFilter.selector = InclusionSelectorType.MULTI_COMBO;
      }
    }
  }

  public inactiveSearchInput() {
    const inputElm = this._inputSearch.nativeElement;
    '' === inputElm.value.trim() && (this.targetFilter['isShowSearch'] = false);
    inputElm.blur();
  }

  public candidateFromSearchText() {
    this.pageLoaderService.show();
    const sortBy: string =
      this.targetFilter.sort && this.targetFilter.sort.by ? this.targetFilter.sort.by.toString() : 'COUNT';
    this.datasourceService
      .getCandidateForFilter(this.targetFilter, this._board, [], this._targetField, sortBy, this.searchText)
      .then((result) => {
        this._setCandidateResult(result, this.targetFilter, this._targetField);
        this.safelyDetectChanges();
        this.pageLoaderService.hide();
      });
  }

  public getWildCardDesc(filter: WildCardFilter): string {
    const data = this.wildCardTypeList.find((item) => ContainsType[item.value] === filter.contains);
    return data ? data.description : '';
  }

  public getConditionDesc(filter: MeasureInequalityFilter): string {
    const aggData = this.aggregationTypeList.find((item) => AggregationType[item.value] === filter.aggregation);
    const inequalityData = this.conditionTypeList.find((item) => InequalityType[item.value] === filter.inequality);
    return aggData && inequalityData ? aggData.name + ' of values ' + inequalityData.description : '';
  }

  public getLimitDesc1(filter: MeasurePositionFilter): string {
    const data = this.limitTypeList.find((item) => PositionType[item.value] === filter.position);
    return data ? data.description : '';
  }

  public getLimitDesc2(filter: MeasurePositionFilter): string {
    const aggData = this.aggregationTypeList.find((item) => AggregationType[item.value] === filter.aggregation);
    return aggData ? aggData.name : '';
  }

  public isSetWildCard(filter: WildCardFilter) {
    return filter.contains && filter.value;
  }

  public isSetCondition(filter: MeasureInequalityFilter) {
    return filter.field && filter.aggregation && filter.inequality && filter.value;
  }

  public isSetLimit(filter: MeasurePositionFilter) {
    return filter.field && filter.aggregation && filter.position && filter.value;
  }

  public resetWildcard(filter: WildCardFilter) {
    filter.value = '';
    if (this.isWildCardMatcher()) {
      this._wildCardContainsCombo.selected(this.wildCardTypeList[0]);
    }
    this.safelyDetectChanges();
  }

  public resetRegExpr(filter: RegExprFilter) {
    filter.expr = '';
    this.safelyDetectChanges();
  }

  public resetCondition(filter: MeasureInequalityFilter) {
    filter.value = 10;
    filter.field = null;
    this._condFieldCombo.clearSelect();
    this._condAggrCombo.selected(this.aggregationTypeList[0]);
    this._condInequalityCombo.selected(this.conditionTypeList[0]);
    this.safelyDetectChanges();
  }

  public resetLimitation(filter: MeasurePositionFilter) {
    filter.value = 10;
    filter.field = null;
    this._limitFieldCombo.clearSelect();
    this._limitAggrCombo.selected(this.aggregationTypeList[0]);
    this._limitPositionCombo.selected(this.limitTypeList[0]);
    this.safelyDetectChanges();
  }

  public resetAll() {
    this.resetWildcard(this.wildcard);
    this.resetRegExpr(this.regExpr);
    this.resetCondition(this.condition);
    this.resetLimitation(this.limitation);
    this.candidateWithValidation();
  }

  public candidateWithValidation() {
    if (this._isInvalidFiltering()) {
      return;
    }

    if (this.isWildCardMatcher()) {
      this.resetRegExpr(this.regExpr);
    } else {
      this.resetWildcard(this.wildcard);
    }

    this._wildcard = cloneDeep(this.wildcard);
    this._regExpr = cloneDeep(this.regExpr);
    this._condition = cloneDeep(this.condition);
    this._limitation = cloneDeep(this.limitation);
    this.targetFilter.preFilters = [this._wildcard, this._regExpr, this._condition, this._limitation];
    this.datasourceService
      .getCandidateForFilter(this.targetFilter, this._board, [], this._targetField)
      .then((result) => {
        this._candidateList = this._candidateList.filter((item) => item.isDefinedValue);
        this._setCandidateResult(result, this.targetFilter, this._targetField);
        this.safelyDetectChanges();
        this.pageLoaderService.hide();
      });
  }

  public toggleConfigFilteringLayer(filter: InclusionFilter) {
    if (!filter['isShowCandidateFilter']) {
      this.wildcard = cloneDeep(this._wildcard);
      this.condition = cloneDeep(this._condition);
      this.limitation = cloneDeep(this._limitation);

      if (this.isWildCardMatcher()) {
        this._wildCardContainsCombo.selected(
          this.wildCardTypeList.find((item) => ContainsType[item.value] === this.wildcard.contains),
        );
      }
      this._condFieldCombo.selected(this.measureFields.find((item) => item.name === this.condition.field));
      this._condAggrCombo.selected(
        this.aggregationTypeList.find((item) => AggregationType[item.value] === this.condition.aggregation),
      );
      this._condInequalityCombo.selected(
        this.conditionTypeList.find((item) => InequalityType[item.value] === this.condition.inequality),
      );
      this._limitFieldCombo.selected(this.measureFields.find((item) => item.name === this.limitation.field));
      this._limitAggrCombo.selected(
        this.aggregationTypeList.find((item) => AggregationType[item.value] === this.limitation.aggregation),
      );
      this._limitPositionCombo.selected(
        this.limitTypeList.find((item) => PositionType[item.value] === this.limitation.position),
      );
    }
    filter['isShowCandidateFilter'] = !filter['isShowCandidateFilter'];
  }

  public sortCandidateValues(filter: InclusionFilter, sortBy?: InclusionSortBy, direction?: DIRECTION) {
    sortBy && (filter.sort.by = sortBy);
    direction && (filter.sort.direction = direction);

    const allCandidates: Candidate[] = cloneDeep(this._candidateList);
    if (InclusionSortBy.COUNT === filter.sort.by) {
      allCandidates.sort((val1: Candidate, val2: Candidate) => {
        return DIRECTION.ASC === filter.sort.direction ? val1.count - val2.count : val2.count - val1.count;
      });
    } else {
      allCandidates.sort((val1: Candidate, val2: Candidate) => {
        const name1: string = val1.name ? val1.name.toUpperCase() : '';
        const name2: string = val2.name ? val2.name.toUpperCase() : '';
        if (name1 < name2) {
          return DIRECTION.ASC === filter.sort.direction ? -1 : 1;
        }
        if (name1 > name2) {
          return DIRECTION.ASC === filter.sort.direction ? 1 : -1;
        }
        return 0;
      });
    }
    this._candidateList = allCandidates;

    this.setCandidatePage(1);
  }

  public setCandidatePage(page: number, isInitial: boolean = false): void {
    if (isInitial) {
      this.initialize();
    }

    if (!this._candidateList?.length) {
      return;
    }

    let pagedList: Candidate[] = cloneDeep(this._candidateList);

    if (this.targetFilter && this.targetFilter.showSelectedItem) {
      pagedList = pagedList.filter((item) => {
        return -1 < this.selectedValues.findIndex((val) => val.name === item.name);
      });
    }

    if ('' !== this.searchText) {
      pagedList = pagedList.filter((item) => {
        return item.name ? -1 < item.name.toLowerCase().indexOf(this.searchText.toLowerCase()) : false;
      });
    }

    if (this.isOnlyShowCandidateValues) {
      pagedList = pagedList.filter((item) => this.isShowItem(item));
    }

    if (this.usePaging) {
      if (page <= 0) return;
      if (this.lastPage < page) return;

      this.currentPage = page;

      this.totalCount = pagedList.length;

      this.lastPage =
        this.totalCount % this.pageSize === 0
          ? this.totalCount / this.pageSize
          : Math.floor(this.totalCount / this.pageSize) + 1;

      const start = page * this.pageSize - this.pageSize;
      let end = page * this.pageSize;
      if (end > this.totalCount) {
        end = this.totalCount;
      }

      this.pageCandidateList = pagedList.slice(start, end);
    } else {
      this.pageCandidateList = pagedList;
    }
  }

  private initialize() {
    this.pageCandidateList = [];
    this.currentPage = 1;
    this.lastPage = 1;
    this.totalCount = 0;
    this.safelyDetectChanges();
  }

  public candidateSelectAll() {
    if (this.isSingleSelect(this.targetFilter)) {
      this.selectedValues = [];
    } else {
      this.selectedValues = [].concat(this._candidateList);
      this.toggleAllCandidateValues(true);
    }
  }

  public candidateDeselectAll() {
    this.selectedValues = [];
  }

  public isCheckedAllItem(): boolean {
    if (this.useAll && this.isSingleSelect(this.targetFilter)) {
      return 0 === this.selectedValues.length;
    } else {
      return this._candidateList.length === this.selectedValues.length;
    }
  }

  public isCheckedItem(listItem: Candidate): boolean {
    return -1 < this.selectedValues.findIndex((item) => item.name === listItem.name);
  }

  public isShowItem(listItem: Candidate): boolean {
    return -1 < this._candidateValues.findIndex((item) => item.name === listItem.name);
  }

  public candidateSelect(item: Candidate, $event?: any) {
    const filter: InclusionFilter = this.targetFilter;
    if (this.isSingleSelect(filter)) {
      this.selectedValues = [item];
    } else {
      const checked = $event.target ? $event.target.checked : $event.currentTarget.checked;
      if (checked) {
        this.selectedValues.push(item);
      } else {
        remove(this.selectedValues, { name: item.name });
      }
    }
    if (!this.isShowItem(item)) {
      this._candidateValues.push(item);
    }
  }

  public setOnlyShowCandidateValues() {
    this.isOnlyShowCandidateValues = !this.isOnlyShowCandidateValues;
    this.setCandidatePage(1);
  }

  public candidateShowToggle(item: Candidate) {
    if (this.isShowItem(item)) {
      remove(this._candidateValues, { name: item.name });

      remove(this.selectedValues, { name: item.name });
      if (this.isSingleSelect(this.targetFilter) && 0 < this._candidateValues.length) {
        this.selectedValues = [this._candidateValues[0]];
      }
    } else {
      this._candidateValues.push(item);
    }

    if (this.isOnlyShowCandidateValues) {
      this.setCandidatePage(1);
    }

    this.safelyDetectChanges();
  }

  public deleteDefinedValue(candidate: Candidate) {
    const { name } = candidate;

    remove(this._candidateList, { name });
    this.setCandidatePage(1);
  }

  public addNewCandidateValue() {
    if (this.newCandidateName === null || this.newCandidateName.trim().length === 0) {
      this.alertPrimeService.warn(this.translateService.instant('msg.board.filter.alert.defined.empty'));
      return;
    }

    this._candidateList.push(this._stringToCandidate(this.newCandidateName, true));

    this.setCandidatePage(1);

    this.newCandidateName = '';
  }

  public toggleAllCandidateValues(isShowAll: boolean) {
    if (isShowAll) {
      this._candidateValues = [].concat(this._candidateList);
    } else {
      this._candidateValues = [];
      this.selectedValues = [];
    }
  }

  public onChangeMatcherType(type: any): void {
    this.selectedMatcherType = type;
  }

  public isWildCardMatcher(): boolean {
    return this.selectedMatcherType.value == MatcherType.WILDCARD;
  }

  public isRegularExpressionMatcher(): boolean {
    return this.selectedMatcherType.value == MatcherType.REGULAR_EXPRESSION;
  }

  public isNoFiltering() {
    return this.selectedValues.length == 0;
  }

  private _isInvalidFiltering(): boolean {
    if (this.wildcard.value && this.wildcard.value.length > 50) {
      this.alertPrimeService.info(
        this.translateService.instant('msg.board.general.filter.common.maxlength', { value: 50 }),
      );
      return true;
    }

    if (isEmpty(this.condition.value)) {
      this.condition.value = 10;
    }

    if (this.condition.value && this.condition.value.toString().length > 19) {
      this.alertPrimeService.info(
        this.translateService.instant('msg.board.general.filter.common.maxlength', { value: 19 }),
      );
      return true;
    }

    if (isEmpty(this.limitation.value)) {
      this.limitation.value = 10;
    }

    if (this.limitation.value && this.limitation.value.toString().length > 10) {
      this.alertPrimeService.info(
        this.translateService.instant('msg.board.general.filter.common.maxlength', { value: 10 }),
      );
      return true;
    }

    return false;
  }

  private _setCandidateResult(result: any[], targetFilter: InclusionFilter, targetField: Field | CustomField) {
    const defineValues = this._candidateList.filter((item) => item.isDefinedValue);

    if (0 === this.selectedValues.length && targetFilter.valueList && 0 < targetFilter.valueList.length) {
      this.selectedValues = targetFilter.valueList.map((item) => this._stringToCandidate(item));
      this._candidateValues = this.selectedValues;
      1 < this.selectedValues.length && (targetFilter.selector = InclusionSelectorType.MULTI_LIST);
    }
    if (0 === this._candidateValues.length && targetFilter.candidateValues && 0 < targetFilter.candidateValues.length) {
      this._candidateValues = this._candidateValues.concat(
        targetFilter.candidateValues
          .filter((item) => -1 === this._candidateValues.findIndex((can) => can.name === item))
          .map((item) => this._stringToCandidate(item)),
      );
      this._candidateList = this._candidateValues;
    }
    if (0 === defineValues.length && targetFilter.definedValues && 0 < targetFilter.definedValues.length) {
      this._candidateList = this._candidateList.concat(
        targetFilter.definedValues
          .filter((item) => -1 === this._candidateList.findIndex((can) => can.name === item))
          .map((item) => this._stringToCandidate(item, true)),
      );
    }

    this._candidateList = this._candidateList.concat(
      result
        .map((item) => this._objToCandidate(item, targetField))
        .filter((item) => -1 === this._candidateList.findIndex((can) => can.name === item.name)),
    );

    if (targetFilter.candidateValues && 0 < targetFilter.candidateValues.length) {
      this._candidateList = this._candidateList.concat(
        targetFilter.candidateValues
          .map((item) => this._stringToCandidate(item))
          .filter((item) => -1 === this._candidateList.findIndex((can) => can.name === item.name)),
      );
    }

    if (this.selectedValues && 0 < this.selectedValues.length) {
      this.selectedValues.forEach((selectedItem) => {
        const item = this._candidateList.find((item) => item.name === selectedItem.name);
        if (isNullOrUndefined(item)) {
          this._candidateList.push(selectedItem);
        }
      });
    }

    this.totalItemCnt = this._candidateList.length;
    targetFilter.candidateValues || (targetFilter.candidateValues = []);
    this.isNoData = 0 === this.totalItemCnt;

    this.sortCandidateValues(targetFilter);
  }

  private _isListSelector(targetFilter: InclusionFilter): boolean {
    return (
      InclusionSelectorType.SINGLE_LIST === targetFilter.selector ||
      InclusionSelectorType.MULTI_LIST === targetFilter.selector
    );
  }

  private _objToCandidate(item: { field?: string; count: number }, field: Field | CustomField): Candidate {
    const name = 'field' in item ? item.field : field.name;
    return createCandidate({
      name,
      count: item.count,
    });
  }

  private _stringToCandidate(item: string, isDefine: boolean = false): Candidate {
    return createCandidate({
      name: item,
      count: 0,
      isDefinedValue: isDefine,
    });
  }
}
