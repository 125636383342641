import { Injectable, Injector } from '@angular/core';

import * as _ from 'lodash';

import { predictionLineTypeAdditional } from '@selfai-platform/bi-chart-engine';
import { UIOption, Filter, PageWidgetConfiguration, SearchQueryRequest } from '@selfai-platform/bi-domain';

import { AbstractService } from '../../../../common/service/abstract.service';
import { CommonUtil } from '../../../../common/util/common.util';
import { Analysis } from '../../value/analysis';

@Injectable()
export class AnalysisPredictionService extends AbstractService {
  private predictionLineType: string[] = ['Lower Data', 'Observations Data', 'Upper Data'];
  private predictionLineTypePrefix = ` - `;
  private predictionLineTypeSuffix = ` Prediction Line`;

  constructor(protected injector: Injector) {
    super(injector);
    predictionLineTypeAdditional.concat(`${this.predictionLineTypeSuffix}`);
  }

  public getAnalysisPredictionLineFromPage(
    widgetConfiguration: PageWidgetConfiguration,
    filters: Filter[],
    chart: any,
    resultData?: { data: any; config: SearchQueryRequest; uiOption: UIOption },
  ): Promise<any> {
    return this.getAnalysis(this.createGetAnalysisParameter(widgetConfiguration, filters))
      .then((result) => {
        this.createPredictionLineSeriesList(result, widgetConfiguration);
        return result;
      })
      .then((result) => {
        chart.analysis = _.cloneDeep(widgetConfiguration.analysis);
        resultData.data.columns = result.columns;
        resultData.data.rows = result.rows;
        chart.resultData = resultData;
        return result;
      })
      .catch((error) => {
        if (chart) chart.analysis = null;
        throw new Error('getAnalysis API error');
      });
  }

  public changeAnalysisPredictionLine(
    widgetConfiguration: PageWidgetConfiguration,
    filters: Filter[],
    chart: any,
    resultData?: { data: any; config: SearchQueryRequest; uiOption: UIOption },
  ): Promise<any> {
    return this.getAnalysis(this.createGetAnalysisParameter(widgetConfiguration, filters))
      .then((result) => {
        this.createPredictionLineSeriesList(result, widgetConfiguration);
        return result;
      })
      .then((result) => {
        chart.analysis = _.cloneDeep(widgetConfiguration.analysis);
        chart['data']['columns'] = result.columns;
        chart['data']['rows'] = result.rows;
        chart.predictionDraw();
        return result;
      })
      .catch((error) => {
        if (chart) chart.analysis = null;
        throw new Error('getAnalysis API error');
      });
  }

  public getAnalysisPredictionLineFromDashBoard(
    widgetConfiguration: PageWidgetConfiguration,
    chart: any,
    resultData: { data: any; config: SearchQueryRequest; uiOption: UIOption },
    filters?: Filter[],
  ): Promise<any> {
    const analysisParam: Analysis = this.createGetAnalysisParameter(widgetConfiguration, filters);
    analysisParam.filters = filters;
    return this.getAnalysis(analysisParam)
      .then((result) => {
        this.createPredictionLineSeriesList(result, widgetConfiguration);
        return result;
      })
      .then((result) => {
        chart.analysis = _.cloneDeep(widgetConfiguration.analysis);
        resultData.data.columns = result.columns;
        resultData.data.rows = result.rows;
        chart.resultData = resultData;
      })
      .catch((error) => {
        chart.analysis = null;
        throw error;
      });
  }

  private getAnalysis(analysis: Analysis): Promise<any> {
    return this.post(this.API_URL + `datasources/query/search`, analysis);
  }

  private createGetAnalysisParameter(widgetConf: PageWidgetConfiguration, filters: Filter[]) {
    const param: Analysis = new Analysis();
    param.dataSource = _.cloneDeep(widgetConf.dataSource) as any;
    param.limits = _.cloneDeep(widgetConf.limit);
    param.resultFormat = { type: 'chart', mode: 'line', columnDelimeter: '―' };
    param.pivot = _.cloneDeep(widgetConf.pivot);
    param.userFields = _.cloneDeep(
      CommonUtil.objectToArray(widgetConf.customFields).filter(
        (item) => item.dataSource === widgetConf.dataSource.engineName,
      ),
    );

    param.filters = filters;

    param.analysis = _.cloneDeep(widgetConf.analysis);

    delete param.analysis.forecast.parameters;
    return param;
  }

  private createPredictionLineSeriesList(result, widgetConfiguration: PageWidgetConfiguration): void {
    const columnName: string = widgetConfiguration.pivot.columns[0].alias
      ? widgetConfiguration.pivot.columns[0].alias
      : widgetConfiguration.pivot.columns[0].name;
    result.info.analysis[columnName].forEach((row) => {
      result.rows.push(row);
    });

    const series = [];
    result.columns.forEach((column) => {
      const targetSeries = _.cloneDeep(column);

      const defSeriesData = targetSeries.value.map(() => {
        return null;
      });

      let isAdditionalData = false;

      series.push(targetSeries);

      const upper = result.info.analysis[`${column.name}`][2];
      const observations = result.info.analysis[`${column.name}`][1];
      const lower = result.info.analysis[`${column.name}`][0];

      const observationsSeriesData = observations.map((value) => {
        return Number(parseFloat(value).toFixed(2));
      });

      const observationsSeries = this.createObservationsSeries(targetSeries, defSeriesData, observationsSeriesData);

      const { lowerSeries, lowerSeriesData } = this.createLowerSeries(targetSeries, lower);

      const upperSeries = this.createUpperSeries(targetSeries);

      const upperSeriesData = upper.map((value, dataIdx) => {
        if (value > 0 && lowerSeriesData[dataIdx] > 0) {
          return Number(parseFloat((value - lowerSeriesData[dataIdx]).toString()).toFixed(2));
        } else if (value < 0 && lowerSeriesData[dataIdx] < 0) {
          const returnValue = Number(parseFloat((lowerSeriesData[dataIdx] - value).toString()).toFixed(2));
          lowerSeriesData[dataIdx] = Number(parseFloat(value).toFixed(2));
          return returnValue;
        } else if (value > 0 && lowerSeriesData[dataIdx] < 0) {
          isAdditionalData = true;
          lowerSeriesData[dataIdx] = null;

          return Number(parseFloat(value).toFixed(2));
        }
      });

      upperSeries.value = defSeriesData.concat(upperSeriesData);
      lowerSeries.value = defSeriesData.concat(lowerSeriesData);

      upperSeries.value[defSeriesData.length - 1] = 0;
      lowerSeries.value[defSeriesData.length - 1] = targetSeries.value[defSeriesData.length - 1];

      series.push(lowerSeries, observationsSeries, upperSeries);

      if (isAdditionalData) {
        const additionLowerSeries = this.createAdditionLowerSeries(
          upperSeries,
          targetSeries,
          lowerSeriesData,
          lower,
          defSeriesData,
        );

        series.push(additionLowerSeries);
      }
    });

    result.columns = series;
  }

  private createLowerSeries(targetSeries: any, lower: any) {
    const lowerSeries = _.cloneDeep(targetSeries);
    lowerSeries.name = `${targetSeries.name}${this.predictionLineTypePrefix}${this.predictionLineType[0]}${this.predictionLineTypeSuffix}`;
    const lowerSeriesData = lower.map((value) => {
      return Number(parseFloat(value).toFixed(2));
    });
    return { lowerSeries, lowerSeriesData };
  }

  private createObservationsSeries(targetSeries: any, defSeriesData: any, observationsSeriesData: any) {
    const observationsSeries = _.cloneDeep(targetSeries);
    observationsSeries.name = `${targetSeries.name}${this.predictionLineTypePrefix}${this.predictionLineType[1]}${this.predictionLineTypeSuffix}`;
    observationsSeries.value = defSeriesData.concat(observationsSeriesData);
    observationsSeries.value[defSeriesData.length - 1] = targetSeries.value[defSeriesData.length - 1];
    return observationsSeries;
  }

  private createUpperSeries(targetSeries: any) {
    const upperSeries = _.cloneDeep(targetSeries);
    upperSeries.name = `${targetSeries.name}${this.predictionLineTypePrefix}${this.predictionLineType[2]}${this.predictionLineTypeSuffix}`;
    return upperSeries;
  }

  private createAdditionLowerSeries(
    upperSeries: any,
    targetSeries: any,
    lowerSeriesData: any,
    lower: any,
    defSeriesData: any,
  ) {
    const additionLowerSeries = _.cloneDeep(upperSeries);
    additionLowerSeries.name = `${targetSeries.name}${this.predictionLineTypePrefix}${predictionLineTypeAdditional}`;
    const additionLowerSeriesData = lowerSeriesData.map((value, dataIdx) => {
      const returnValue = value == null ? lower[dataIdx] : null;
      return returnValue;
    });
    additionLowerSeries.value = defSeriesData.concat(additionLowerSeriesData);
    return additionLowerSeries;
  }
}
