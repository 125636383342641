import { DIRECTION } from '../sort.model';

import { Filter, createFilter } from './filter.model';

export interface InclusionFilter extends Filter {
  valueList: any[];
  selector?: InclusionSelectorType;
  candidateValues?: any[];
  definedValues?: string[];
  preFilters?: Filter[];
  sort?: InclusionItemSort;
  showSelectedItem?: boolean;
  selectionRange: string;
  selectionComponent: string;
  customValue: string;
  searchText: string;
  pageNum: number;
}

export function createInclusionFilter(
  field: string,
  valueList: string[] = [],
  params: Partial<InclusionFilter> = {},
): InclusionFilter {
  return {
    ...createFilter(),
    type: 'include',
    field,
    valueList,
    selectionRange: 'SINGLE',
    selectionComponent: 'COMBO',
    customValue: '',
    searchText: '',
    pageNum: 0,
    ...params,
  } as InclusionFilter;
}

export interface InclusionItemSort {
  by: InclusionSortBy;
  direction: DIRECTION;
}

export function createInclusionItemSort(by: InclusionSortBy, direction: DIRECTION): InclusionItemSort {
  return {
    by,
    direction,
  };
}

export interface Candidate {
  name?: string;
  count?: number;
  isDefinedValue?: boolean;
  isShow?: boolean;
  isTemporary?: boolean;
}

export function createCandidate(params: Partial<Candidate> = {}): Candidate {
  return {
    ...params,
  };
}

export enum InclusionSortBy {
  COUNT = 'COUNT',
  TEXT = 'TEXT',
  NUMERIC = 'NUMERIC',
  DATE = 'DATE',
}

export enum InclusionSelectorType {
  SINGLE_LIST = 'SINGLE_LIST',
  SINGLE_COMBO = 'SINGLE_COMBO',
  MULTI_LIST = 'MULTI_LIST',
  MULTI_COMBO = 'MULTI_COMBO',
  USER_DEFINED = 'USER_DEFINED',
}
