import { Datasource } from '../../../datasource';
import { JoinMapping } from '../../../workbook';

export interface JoinMappingDataSource {
  joinMappings: JoinMapping[];
  candidateDataSources: Datasource[];
}

export function createJoinMappingDataSource(joinMappings: JoinMapping[], candidateDataSources: Datasource[]) {
  return {
    joinMappings: joinMappings,
    candidateDataSources: candidateDataSources,
  };
}
