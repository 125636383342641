import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LoggingFormGroup } from '@selfai-platform/pipeline-common';

@Component({
  selector: 'selfai-platform-logging',
  templateUrl: './logging.component.html',
  styleUrls: ['./logging.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoggingComponent {
  @Input() form!: LoggingFormGroup;
}
