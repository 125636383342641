<p-panel header="{{ 'user-functions.panel.header' | translate }}">
  <div *ngIf="userFunctionForm" class="user-function-form" [formGroup]="userFunctionForm">
    <div class="grid">
      <div class="col">
        <div class="mb-2 flex align-items-center">
          <label for="name" class="mr-2 block w-2">{{ 'user-functions.form.name' | translate }}</label>
          <input id="name" class="w-8" pInputText formControlName="name" [disabled]="true">
        </div>

        <div class="mb-2 flex align-items-center">
          <label for="scopes" class="mr-2 block w-2">{{ 'user-functions.form.scopes' | translate }}</label>
          <p-treeSelect
            id="scopes"
            class="w-8"
            containerStyleClass="w-full"
            formControlName="scopes"
            display="chip"
            selectionMode="checkbox"
            placeholder="{{ 'user-functions.form.placeholder.scopes' | translate }}"
            [showClear]="true"
            [metaKeySelection]="false"
            [filter]="true"
            [filterInputAutoFocus]="true"
            [options]="scopeList"
          />
        </div>
      </div>

      <div class="col">
        <div class="mb-2 flex align-items-center">
          <label for="functionType" class="mr-2 block w-2">{{ 'user-functions.form.function-type' | translate }}</label>
          <p-dropdown
            id="functionType"
            class="function-type w-10"
            formControlName="functionType"
            optionLabel="name"
            optionValue="value"
            placeholder="{{ 'user-functions.form.placeholder.function-type' | translate }}"
            [options]="functionTypes"
          ></p-dropdown>
        </div>

        <div class="mb-2 flex align-items-center">
          <label for="description" class="mr-2 block w-2">{{ 'user-functions.form.description' | translate }}</label>
          <textarea
            id="description"
            class="w-10"
            pInputTextarea
            formControlName="description"
            [rows]="5"
            [autoResize]="true"
          ></textarea>
        </div>

        <div class="mb-3 flex align-items-center">
          <label for="tags" class="mr-2 block w-2">{{ 'user-functions.form.tags' | translate }}</label>
          <p-autoComplete
            id="tags"
            class="w-10"
            formControlName="tags"
            optionLabel="name"
            variant="filled"
            [multiple]="true"
            [suggestions]="selectedTags"
            (completeMethod)="complete($event)"
            (keydown.enter)="addTag($event)"
          />
        </div>
      </div>
    </div>

    <div class="mb-3">
      <selfai-platform-code-editor
        formControlName="body"
        [language]="codeLanguage.SCALA"
      ></selfai-platform-code-editor>
    </div>

    <div class="flex">
      <p-button
        class="mr-2"
        label="{{'user-functions.form.actions.back' | translate }}"
        severity="secondary"
        [outlined]="true"
        (click)="onBackEvent()"
      ></p-button>

      <p-button
        label="{{'user-functions.form.actions.save' | translate }}"
        [disabled]="userFunctionForm.invalid || !canUpdate"
        (click)="onSubmitEvent()"
      ></p-button>
    </div>
  </div>
</p-panel>

