import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Session, SessionDetailApi, SessionListApi } from '@selfai-platform/pipeline-common';
import { normalizeApiEndpoint, PipelineConfigService } from '@selfai-platform/shared';
import { EMPTY, map, Observable, switchMap } from 'rxjs';
import { SessionsManagerAdapter } from './sessions-manager.adapter';

@Injectable({
  providedIn: 'root',
})
export class SessionManagerApiService implements SessionsManagerAdapter {
  private apiUrl: string;

  constructor(private readonly http: HttpClient, private readonly pipelineConfigService: PipelineConfigService) {
    const config = this.pipelineConfigService.getConfig();
    this.apiUrl = `${config.hosts.api}/${config.versions.url}`;
  }

  loadList(): Observable<Session[]> {
    return this.http.get<SessionListApi>(this.buildUrl('/sessions')).pipe(map(({ sessions }) => sessions));
  }

  loadByWorkflowId(workflowId: string): Observable<Session> {
    return this.http.get<SessionDetailApi>(this.buildUrl(`/sessions/${workflowId}`)).pipe(map((response) => response));
  }

  stopByWorkflowId(workflowId: string): Observable<void> {
    return this.http
      .delete(this.buildUrl(`/sessions/${workflowId}`), { responseType: 'text' })
      .pipe(switchMap(() => EMPTY));
  }

  start(config: unknown): Observable<unknown> {
    return this.http.post(this.buildUrl('/sessions'), config);
  }

  private buildUrl(endpoint: string): string {
    return `${this.apiUrl}/${normalizeApiEndpoint(endpoint)}`;
  }
}
