import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  HostListener,
  Injector,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import $ from 'jquery';
import moment from 'moment';
import { CookieService } from 'ng2-cookies';
import { Observable, Subscription } from 'rxjs';

import { ImplementorType, LogicalType } from '@selfai-platform/bi-domain';
import { AlertService } from '@selfai-platform/shared';

import { PageLoaderService } from '@selfai-platform/shell';
import { Modal } from '../../common/domain/modal';
import { UserDetail } from '../../domain/common/abstract-history-entity';
import { Page, PageResult } from '../../domain/common/page';
import { Group } from '../../domain/user/group';
import { User } from '../../domain/user/user';
import { LocalStorageConstant } from '../constant/local-storage.constant';
import { CanComponentDeactivate } from '../gaurd/can.deactivate.guard';
import { PopupService } from '../service/popup.service';
import { UnloadConfirmService } from '../service/unload.confirm.service';
import { CommonUtil } from '../util/common.util';
import { Loading } from '../util/loading.util';
import { UsedCriteria } from '../value/used-criteria.data.value';

@Directive()
export class AbstractComponent implements OnInit, AfterViewInit, OnDestroy, CanComponentDeactivate {
  private _webSocketReConnectCnt = 0;

  protected router: Router;

  protected location: Location;

  protected subscriptions: Subscription[] = [];

  protected defaultPhotoSrc = 'assets/bi/images/img_photo.png';

  public changeDetect: ChangeDetectorRef;

  public alertPrimeService: AlertService;

  public $element: JQuery;

  public translateService: TranslateService;
  public cookieService: CookieService;

  public imagePath: string;

  public isLoaded = false;

  public pageResult: PageResult = new PageResult();

  public page: Page = new Page();

  public $window: any = $(window);

  public unloadConfirmSvc: UnloadConfirmService;
  public useUnloadConfirm = false;

  public loginUserId: string;

  public compUUID: string;
  /**
   * @deprecated
   */
  public loading = false;

  /**
   * @deprecated
   */
  public _popupService;

  protected pageLoaderService = inject(PageLoaderService);

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    this._popupService = injector.get(PopupService);
    this.router = injector.get(Router);
    this.location = injector.get(Location);
    this.changeDetect = injector.get(ChangeDetectorRef);
    this.alertPrimeService = injector.get(AlertService);

    this.translateService = injector.get(TranslateService);
    this.imagePath = 'assets/images/';
    this.unloadConfirmSvc = injector.get(UnloadConfirmService);
    this.cookieService = injector.get(CookieService);
  }

  ngOnInit() {
    this.$element = $(this.elementRef.nativeElement);

    this.loginUserId = CommonUtil.getLoginUserId();

    this.compUUID = CommonUtil.getUUID();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.isLoaded = true;
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });

    if (this.$element) {
      this.$element.find('*').off();

      this.$element.remove();
    }
  }

  public execBeforeUnload() {}

  public canDeactivate(): Observable<boolean> | boolean {
    this.execBeforeUnload();
    if (this.useUnloadConfirm) {
      const obConfirm: Observable<boolean> = this.unloadConfirmSvc.confirm();
      obConfirm.subscribe((data) => {
        if (!data) {
          history.pushState(null, null, this.router.url);
        }
      });
      return obConfirm;
    }
    return true;
  }

  @HostListener('window:beforeunload', ['$event'])
  public beforeUnloadHandler(event) {
    this.execBeforeUnload();
    if (this.useUnloadConfirm) {
      const confirmationMessage: string = this.translateService.instant('msg.comm.ui.beforeunload');
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    }
  }

  public addBodyScrollHidden() {
    $('body').removeClass('body-hidden').addClass('body-hidden');
  }

  public removeBodyScrollHidden() {
    $('body').removeClass('body-hidden');
  }

  /**
   * @deprecated
   */
  public loadingShow() {
    this._popupService.ptLoading = true;
    this.loading = this._popupService.ptLoading;
    Loading.show();
  }

  /**
   * @deprecated
   */
  public loadingHide() {
    this._popupService.ptLoading = false;
    this.loading = this._popupService.ptLoading;
    Loading.hide();
  }

  public safelyDetectChanges() {
    if (!this.changeDetect['destroyed']) {
      this.changeDetect.detectChanges();
    }
  }

  public isPermissionManager() {
    return true;
  }

  public customMoment(date: Date | string) {
    if (date.constructor === String) {
      return moment((<string>date).replace('.000Z', ''));
    } else {
      return moment(date);
    }
  }

  public getFieldTypeIconClass(itemType: string): string {
    let result = '';
    if (itemType) {
      switch (itemType.toUpperCase()) {
        case 'TIMESTAMP':
          result = 'ddp-icon-type-calen';
          break;
        case 'BOOLEAN':
          result = 'ddp-icon-type-tf';
          break;
        case 'TEXT':
        case 'DIMENSION':
        case 'STRING':
        case 'USER_DEFINED':
          result = 'ddp-icon-type-ab';
          break;
        case 'INT':
        case 'INTEGER':
        case 'LONG':
          result = 'ddp-icon-type-int';
          break;
        case 'DOUBLE':
        case 'FLOAT':
          result = 'ddp-icon-type-float';
          break;
        case 'MAP':
          result = 'ddp-icon-type-map';
          break;
        case 'ARRAY':
          result = 'ddp-icon-type-array';
          break;
        case 'CALCULATED':
          result = 'ddp-icon-type-sharp';
          break;
        case 'LNG':
        case 'LONGITUDE':
          result = 'ddp-icon-type-longitude';
          break;
        case 'LNT':
        case 'LATITUDE':
          result = 'ddp-icon-type-latitude';
          break;
        case 'ETC':
          result = 'ddp-icon-type-etc';
          break;
        case 'IMAGE':
          result = 'ddp-icon-type-image';
          break;
        case 'BINARY':
          result = 'ddp-icon-type-binary';
          break;
        case 'SPATIAL':
          result = 'ddp-icon-type-spatial';
          break;
        case 'PRIVATE':
          result = 'ddp-icon-type-private';
          break;
        case 'PHONE':
          result = 'ddp-icon-type-phone';
          break;
        case 'EMAIL':
          result = 'ddp-icon-type-email';
          break;
        case 'GENDER':
          result = 'ddp-icon-type-gender';
          break;
        case 'URL':
          result = 'ddp-icon-type-url';
          break;
        case 'POST':
          result = 'ddp-icon-type-zipcode';
          break;
        case 'COUNTRY':
        case 'STATE':
        case 'CITY':
        case 'GU':
        case 'DONG':
          result = 'ddp-icon-type-local';
          break;
        case 'GEO_POINT':
          result = 'ddp-icon-type-point';
          break;
        case 'GEO_LINE':
          result = 'ddp-icon-type-line';
          break;
        case 'GEO_POLYGON':
          result = 'ddp-icon-type-polygon';
          break;
      }
    }
    return result;
  }

  public getFieldRoleTypeIconClass(roleType: string): string {
    return roleType === 'MEASURE' ? 'ddp-measure' : 'ddp-dimension';
  }

  public getFieldDimensionTypeIconClass(type: string): string {
    if (type) {
      switch (type) {
        case 'TIMESTAMP':
          return 'ddp-icon-dimension-calen';
        case 'BOOLEAN':
          return 'ddp-icon-dimension-tf';
        case 'TEXT':
        case 'DIMENSION':
        case 'STRING':
        case 'USER_DEFINED':
          return 'ddp-icon-dimension-ab';
        case 'INT':
        case 'INTEGER':
        case 'LONG':
          return 'ddp-icon-dimension-int';
        case 'DOUBLE':
        case 'FLOAT':
          return 'ddp-icon-dimension-float';
        case 'MAP':
          return 'ddp-icon-dimension-maplink';
        case 'ARRAY':
          return 'ddp-icon-dimension-array';
        case 'CALCULATED':
          return 'ddp-icon-dimension-sharp';
        case 'LNG':
        case 'LONGITUDE':
          return 'ddp-icon-dimension-longitude';
        case 'LNT':
        case 'LATITUDE':
          return 'ddp-icon-dimension-latitude';
        case 'ACCOUNT':
          return 'ddp-icon-dimension-account';
        case 'COUNTRY':
        case 'STATE':
        case 'CITY':
        case 'GU':
        case 'DONG':
          return 'ddp-icon-dimension-local';
        case 'GEO_POINT':
          return 'ddp-icon-dimension-point';
        case 'GEO_LINE':
          return 'ddp-icon-dimension-line';
        case 'GEO_POLYGON':
          return 'ddp-icon-dimension-polygon';
        default:
          return 'ddp-icon-dimension-ab';
      }
    } else {
      return '';
    }
  }

  public getFieldMeasureTypeIconClass(type: string): string {
    if (type) {
      switch (type) {
        case 'TIMESTAMP':
          return 'ddp-icon-measure-calen';
        case 'BOOLEAN':
          return 'ddp-icon-measure-tf';
        case 'TEXT':
        case 'DIMENSION':
        case 'STRING':
        case 'USER_DEFINED':
          return 'ddp-icon-measure-ab';
        case 'INT':
        case 'INTEGER':
        case 'LONG':
          return 'ddp-icon-measure-int';
        case 'DOUBLE':
        case 'FLOAT':
          return 'ddp-icon-measure-float';
        case 'MAP':
          return 'ddp-icon-measure-maplink';
        case 'ARRAY':
          return 'ddp-icon-measure-array';
        case 'CALCULATED':
          return 'ddp-icon-measure-sharp';
        case 'LNG':
        case 'LONGITUDE':
          return 'ddp-icon-measure-longitude';
        case 'LNT':
        case 'LATITUDE':
          return 'ddp-icon-measure-latitude';
        case 'ACCOUNT':
          return 'ddp-icon-measure-account';
        case 'COUNTRY':
        case 'STATE':
        case 'CITY':
        case 'GU':
        case 'DONG':
          return 'ddp-icon-measure-local';
        default:
          return 'ddp-icon-measure-ab';
      }
    } else {
      return '';
    }
  }

  public getEnabledConnectionTypes(isDeleteAll: boolean = false) {
    const types = [
      {
        label: this.translateService.instant('msg.storage.ui.list.all'),
        value: 'all',
      },

      { label: 'MySQL', value: 'MYSQL', icon: 'type-mysql' },
      { label: 'PostgreSQL', value: 'POSTGRESQL', icon: 'type-postgre' },
      { label: 'Hive', value: 'HIVE', icon: 'type-hive' },
      { label: 'Presto', value: 'PRESTO', icon: 'type-presto' },
      { label: 'Existed Storage', value: 'DRUID', icon: 'type-druid' },
    ];

    if (isDeleteAll) {
      types.shift();
    }

    return types;
  }

  public getMetaDataLogicalTypeList(): any[] {
    return [
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.string'),
        value: 'STRING',
        icon: 'ddp-icon-type-ab',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.boolean'),
        value: 'BOOLEAN',
        icon: 'ddp-icon-type-tf',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.integer'),
        value: 'INTEGER',
        icon: 'ddp-icon-type-int',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.double'),
        value: 'DOUBLE',
        icon: 'ddp-icon-type-float',
      },
      {
        label: this.translateService.instant('msg.storage.ui.list.date'),
        value: 'TIMESTAMP',
        icon: 'ddp-icon-type-calen',
      },
      {
        label: this.translateService.instant('msg.storage.ui.list.array'),
        value: 'ARRAY',
        icon: 'ddp-icon-type-array',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.latitude'),
        value: 'LNT',
        icon: 'ddp-icon-type-latitude',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.longitude'),
        value: 'LNG',
        icon: 'ddp-icon-type-longitude',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.image'),
        value: 'IMAGE',
        icon: '',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.binary'),
        value: 'BINARY',
        icon: '',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.spatial'),
        value: 'SPATIAL',
        icon: '',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.private'),
        value: 'PRIVATE',
        icon: '',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.phone'),
        value: 'PHONE_NUMBER',
        icon: '',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.email'),
        value: 'EMAIL',
        icon: '',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.gender'),
        value: 'SEX',
        icon: '',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.url'),
        value: 'URL',
        icon: '',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.post'),
        value: 'POSTAL_CODE',
        icon: '',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.country'),
        value: 'COUNTRY',
        icon: '',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.state'),
        value: 'STATE',
        icon: '',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.city'),
        value: 'CITY',
        icon: '',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.gu'),
        value: 'GU',
        icon: '',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.dong'),
        value: 'DONG',
        icon: '',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.etc'),
        value: 'ETC',
        icon: '',
      },
      {
        label: this.translateService.instant('msg.storage.ui.list.geo.point'),
        value: LogicalType.GEO_POINT,
        icon: 'ddp-icon-type-point',
      },
      {
        label: this.translateService.instant('msg.storage.ui.list.geo.polygon'),
        value: LogicalType.GEO_POLYGON,
        icon: 'ddp-icon-type-polygon',
      },
      {
        label: this.translateService.instant('msg.storage.ui.list.geo.line'),
        value: LogicalType.GEO_LINE,
        icon: 'ddp-icon-type-line',
      },
    ];
  }

  public getMetaDataLogicalTypeEtcList(): any[] {
    return [
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.image'),
        value: 'IMAGE',
        icon: '',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.binary'),
        value: 'BINARY',
        icon: '',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.spatial'),
        value: 'SPATIAL',
        icon: '',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.private'),
        value: 'PRIVATE',
        icon: '',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.phone'),
        value: 'PHONE_NUMBER',
        icon: '',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.email'),
        value: 'EMAIL',
        icon: '',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.gender'),
        value: 'SEX',
        icon: '',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.url'),
        value: 'URL',
        icon: '',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.post'),
        value: 'POSTAL_CODE',
        icon: '',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.country'),
        value: 'COUNTRY',
        icon: '',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.state'),
        value: 'STATE',
        icon: '',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.city'),
        value: 'CITY',
        icon: '',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.gu'),
        value: 'GU',
        icon: '',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.dong'),
        value: 'DONG',
        icon: '',
      },
      {
        label: this.translateService.instant('msg.metadata.ui.dictionary.type.etc'),
        value: 'ETC',
        icon: '',
      },
    ];
  }

  public openAccessDeniedConfirm() {
    const modal = new Modal();
    modal.isShowCancel = false;
    modal.name = this.translateService.instant('msg.comm.alert.access-denied.title');
    modal.description = this.translateService.instant('msg.comm.alert.access-denied.desc');
    modal.btnName = this.translateService.instant('msg.comm.ui.ok');
    CommonUtil.confirm(modal);
  }

  public getUserFullName(user: User | UserDetail) {
    return user && 'Unknown user' !== user.fullName ? user.fullName : '';
  }

  public getGroupName(group: Group) {
    return group && 'Unknown group' !== group.name ? group.name : '';
  }

  public getConnImplementorImgUrl(impType: ImplementorType, imgResource?: string): string {
    let connImgUrl = '';
    switch (impType) {
      case ImplementorType.MYSQL:
        connImgUrl = location.origin + '/assets/bi/images/img_db/ic_db_mysql.png';
        break;
      case ImplementorType.HIVE:
        connImgUrl = location.origin + '/assets/bi/images/img_db/ic_db_hive.png';
        break;
      case ImplementorType.DRUID:
        connImgUrl = location.origin + '/assets/bi/images/img_db/ic_db_druid.png';
        break;
      case ImplementorType.POSTGRESQL:
        connImgUrl = location.origin + '/assets/bi/images/img_db/ic_db_post.png';
        break;
      case ImplementorType.PRESTO:
        connImgUrl = location.origin + '/assets/bi/images/img_db/ic_db_presto.png';
        break;
      case ImplementorType.MSSQL:
        connImgUrl = location.origin + '/assets/bi/images/img_db/ic_db_mssql.png';
        break;
      default:
        connImgUrl = imgResource ? imgResource : location.origin + '/assets/bi/images/img_db/ic_DB.png';
        break;
    }
    return connImgUrl;
  }

  public getConnImplementorWhiteImgUrl(impType: ImplementorType, imgResource?: string): string {
    let connImgUrl = '';
    switch (impType) {
      case ImplementorType.MYSQL:
        connImgUrl = location.origin + '/assets/bi/images/img_db/ic_db_mysql_w.png';
        break;
      case ImplementorType.HIVE:
        connImgUrl = location.origin + '/assets/bi/images/img_db/ic_db_hive_w.png';
        break;
      case ImplementorType.DRUID:
        connImgUrl = location.origin + '/assets/bi/images/img_db/ic_db_druid_w.png';
        break;
      case ImplementorType.POSTGRESQL:
        connImgUrl = location.origin + '/assets/bi/images/img_db/ic_db_post_w.png';
        break;
      case ImplementorType.PRESTO:
        connImgUrl = location.origin + '/assets/bi/images/img_db/ic_db_presto_w.png';
        break;
      case ImplementorType.MSSQL:
        connImgUrl = location.origin + '/assets/bi/images/img_db/ic_db_mssql_w.png';
        break;
      default:
        connImgUrl = imgResource ? imgResource : location.origin + '/assets/bi/images/img_db/ic_db_w.png';
        break;
    }
    return connImgUrl;
  }

  public getConnImplementorGrayImgUrl(impType: ImplementorType, imgResource?: string): string {
    let connImgUrl = '';
    switch (impType) {
      case ImplementorType.MYSQL:
        connImgUrl = location.origin + '/assets/bi/images/img_db/ic_db_mysql_b.png';
        break;
      case ImplementorType.HIVE:
        connImgUrl = location.origin + '/assets/bi/images/img_db/ic_db_hive_b.png';
        break;
      case ImplementorType.DRUID:
        connImgUrl = location.origin + '/assets/bi/images/img_db/ic_db_druid_b.png';
        break;
      case ImplementorType.POSTGRESQL:
        connImgUrl = location.origin + '/assets/bi/images/img_db/ic_db_post_b.png';
        break;
      case ImplementorType.PRESTO:
        connImgUrl = location.origin + '/assets/bi/images/img_db/ic_db_presto_b.png';
        break;
      case ImplementorType.MSSQL:
        connImgUrl = location.origin + '/assets/bi/images/img_db/ic_db_mssql_b.png';
        break;
      default:
        connImgUrl = imgResource ? imgResource : location.origin + '/assets/bi/images/img_db/ic_db_b.png';
        break;
    }
    return connImgUrl;
  }

  public getLanguage(): string {
    return this.translateService.currentLang;
  }

  public setLanguage(lang: string) {
    this.translateService.use(lang);
  }

  protected commonExceptionHandler(err: any, errMessage?: string) {
    if (err?.message) {
      this.alertPrimeService.error(err.message, { summary: this.translateService.instant('msg.alert.retrieve.fail') });
    } else if (errMessage) {
      this.alertPrimeService.error(errMessage, { summary: this.translateService.instant('msg.alert.retrieve.fail') });
    } else {
      this.alertPrimeService.error(this.translateService.instant('msg.alert.retrieve.fail'));
    }
    console.error(err);
    this.pageLoaderService.hide();
  }

  protected dataprepExceptionHandler(err) {
    console.error(err);

    if (err.code && err.code.startsWith('PR') && err.message) {
      return err;
    } else if (err.message && err.message.startsWith('msg.dp.alert.')) {
      return err;
    } else {
      return {
        message: 'msg.dp.alert.unknown.error',
        details: JSON.stringify(err),
      };
    }
  }

  protected getUsedCriteriaFromLocalStorage(): UsedCriteria {
    if (localStorage) {
      const usedCriteria = CommonUtil.getLocalStorage(LocalStorageConstant.KEY.USED_CRITERIA);
      let criteria: UsedCriteria;
      if (usedCriteria) {
        criteria = JSON.parse(usedCriteria);
      } else {
        criteria = new UsedCriteria();
        this.setUsedCriteriaToLocalStorage(criteria);
      }
      return criteria;
    } else {
      return new UsedCriteria();
    }
  }

  protected setUsedCriteriaToLocalStorage(criteria: UsedCriteria): void {
    CommonUtil.setLocalStorage(LocalStorageConstant.KEY.USED_CRITERIA, JSON.stringify(criteria));
  }
}
