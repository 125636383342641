import { Component, ElementRef, EventEmitter, Injector, OnDestroy, OnInit, Output } from '@angular/core';

import { Modal } from '../../../domain/modal';
import { AbstractComponent } from '../../abstract.component';

@Component({
  selector: 'component-confirm-ref',
  templateUrl: 'confirm-ref.component.html',
})
export class ConfirmRefModalComponent extends AbstractComponent {
  modal: Modal;

  @Output() readonly cancelEvent = new EventEmitter();
  @Output() readonly confirmEvent = new EventEmitter();

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  init(modal: Modal) {
    this.modal = modal;
  }

  closePopup() {
    this.cancelEvent.emit();
  }

  done() {
    this.confirmEvent.emit(this.modal);
  }
}
