import { Component, ElementRef, EventEmitter, Injector, OnDestroy, OnInit, Output } from '@angular/core';

import * as _ from 'lodash';

import { DashboardPageRelation, DashboardWidgetRelation, FunctionValidator, Widget } from '@selfai-platform/bi-domain';

import { AbstractComponent } from '../../../common/component/abstract.component';

declare let $;

@Component({
  selector: 'app-page-relation',
  templateUrl: './page-relation.component.html',
})
export class PageRelationComponent extends AbstractComponent implements OnInit, OnDestroy {
  private _chartFuncValidator: FunctionValidator = new FunctionValidator();

  private _widgets: Widget[] = [];

  private _$tree;

  public isShow = false;

  public nodes: DashboardWidgetRelation[] = [];

  public cntWidgets = 0;

  @Output()
  public changeRelation: EventEmitter<DashboardWidgetRelation[]> = new EventEmitter();

  constructor(protected element: ElementRef, protected injector: Injector) {
    super(element, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public run(nodes: DashboardWidgetRelation[], widgets: Widget[]) {
    this.pageLoaderService.show();
    this.isShow = true;
    this._widgets = widgets;
    this.nodes = _.cloneDeep(nodes);
    this.cntWidgets = this._getCntNodes(nodes);
    const data = this._getTreeData(nodes);
    this.safelyDetectChanges();
    setTimeout(() => {
      this._$tree = $('.sys-tree-container');
      this._$tree.tree({
        data: data,
        autoOpen: true,
        dragAndDrop: true,
        onCanMoveTo: (moved_node, target_node, position) => {
          $('.ddp-drag-enable').removeClass('ddp-drag-enable');
          $('.ddp-drag-disable').removeClass('ddp-drag-disable');
          if ('inside' === position && target_node.widgetData.chartType) {
            if (this._chartFuncValidator.checkUseSelectionByTypeString(target_node.widgetData.chartType)) {
              $(target_node.element).addClass('ddp-drag-enable');
              return true;
            } else {
              $(target_node.element).addClass('ddp-drag-disable');
              return false;
            }
          } else {
            return true;
          }
        },
        onDragStop: () => {
          $('li.jqtree-element').removeClass('ddp-drag-enable').removeClass('ddp-drag-disable');
        },
        onCreateLi: (node, $li) => {
          $li
            .find('.jqtree-element')
            .html(
              '<div class="jqtree-title jqtree_common node-content-wrapper" role="treeitem" aria-selected="false" aria-expanded="false">' +
                '<em class="ddp-data-num" style="left:' +
                -12 * this._getNodeDepth(node.parent) +
                'px;" >' +
                ($('.node-content-wrapper').length + 1) +
                '</em>' +
                '<em class="ddp-node-depth" ></em>' +
                '<em class="ddp-img-chart-' +
                node.widgetData.chartType +
                '"></em>' +
                '<span>' +
                node.name +
                '</span>' +
                '</div>',
            );
        },
      });
      this.pageLoaderService.hide();
    }, 500);
  }

  public close() {
    this.isShow = false;
  }

  public savePageRelation() {
    const treeNodes = JSON.parse(this._$tree.tree('toJson'));
    const rels: DashboardPageRelation[] = treeNodes.map((node) => this._toBoardPageRelation(node));
    const pageWidgetRels: DashboardWidgetRelation[] = rels.map(
      (rel) => new DashboardWidgetRelation(rel, this._widgets),
    );
    this.changeRelation.emit(pageWidgetRels);
    this.close();
  }

  public treeMouseDown(event: MouseEvent) {
    const $target = $(event.target);
    if ($target.hasClass('node-content-wrapper')) {
      $target.addClass('ddp-tree-drag-start');
    } else if (0 < $target.closest('.node-content-wrapper').length) {
      $target.closest('.node-content-wrapper').addClass('ddp-tree-drag-start');
    }
  }

  public treeMouseUp(event: MouseEvent) {
    this._$tree.find('.ddp-tree-drag-start').removeClass('ddp-tree-drag-start');
  }

  private _toBoardPageRelation(nodeData) {
    const pageRel: DashboardPageRelation = {
      ref: nodeData.id,
    };
    if (nodeData.children && 0 < nodeData.children.length) {
      pageRel.children = nodeData.children.map((item) => this._toBoardPageRelation(item));
    }
    return pageRel;
  }

  private _getNodeDepth(node): number {
    return node.parent ? 1 + this._getNodeDepth(node.parent) : 0;
  }

  private _getTreeData(nodes: DashboardWidgetRelation[]) {
    const nodeList: any = [];
    nodes.forEach((item) => {
      const widgetData: any = _.cloneDeep(item);
      delete widgetData['children'];
      delete widgetData['_widgets'];
      const nodeData: any = { id: item.id, label: item.name, widgetData: widgetData };
      if (item.children && 0 < item.children.length) {
        nodeData.children = this._getTreeData(item.children);
      }
      nodeList.push(nodeData);
    });
    return nodeList;
  }

  private _getCntNodes(nodes: DashboardWidgetRelation[]) {
    let cntNodes = 0;
    nodes.forEach((item) => {
      cntNodes++;
      if (item.children && 0 < item.children.length) {
        cntNodes += this._getCntNodes(item.children);
      }
    });
    return cntNodes;
  }
}
