import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[selfaiPlatformTemplateName]',
  standalone: true,
})
export class TemplateNameDirective<TName extends string, TData = undefined> {
  @Input({ required: true }) selfaiPlatformTemplateName: TName;
  // any data what you want to pass to the template
  @Input() data?: TData;

  get name(): TName {
    return this.selfaiPlatformTemplateName;
  }

  constructor(public readonly template: TemplateRef<unknown>) {}
}
