import {
  PageWidgetConfiguration,
  DashboardWidgetRelation,
  DashboardPageRelation,
  Dashboard,
  Widget,
} from '@selfai-platform/bi-domain';

import { HierarchyCallback } from './hierarchy-callback';

export class Hierarchy {
  private _pageWidgetRels: DashboardWidgetRelation[] = [];

  constructor(pageWidgets: Widget[], dashboard: Dashboard) {
    if (dashboard.configuration.relations) {
      const rels: DashboardPageRelation[] = dashboard.configuration.relations;
      this._pageWidgetRels = rels.map((rel) => new DashboardWidgetRelation(rel, pageWidgets));

      const missingWidgets: Widget[] = pageWidgets.filter((widget) => {
        return !this._findRelationAndRunProc(widget.id, this._pageWidgetRels, () => true);
      });

      missingWidgets.forEach((widget) => this.add(widget));
    } else {
      this._pageWidgetRels = pageWidgets.map((item) => this._widgetToRelation(item));
    }
  }

  public add(widget: Widget) {
    widget && this._pageWidgetRels.push(this._widgetToRelation(widget));
  }

  public modify(widget: Widget) {
    this._findRelationAndRunProc(widget.id, this._pageWidgetRels, (target: DashboardWidgetRelation) => {
      target.name = widget.name;
      target.chartType = (<PageWidgetConfiguration>widget.configuration).chart.type.toString();
      return true;
    });
  }

  public del(widgetId: string) {
    this._findRelationAndRunProc(
      widgetId,
      this._pageWidgetRels,
      (target: DashboardWidgetRelation, list: DashboardWidgetRelation[]) => {
        const delIdx: number = list.findIndex((item) => item.id === target.id);
        if (-1 < delIdx) {
          list.splice(delIdx, 1);
        }
        return true;
      },
    );
  }

  public isLeaf(widgetId: string): boolean {
    return this._findRelationAndRunProc(widgetId, this._pageWidgetRels, (target: DashboardWidgetRelation) => {
      return !(target.children && 0 < target.children.length);
    });
  }

  public get(): DashboardWidgetRelation[] {
    return this._pageWidgetRels;
  }

  public set(data: DashboardWidgetRelation[]) {
    this._pageWidgetRels = data;
  }

  private _findRelationAndRunProc(
    widgetId: string,
    rels: DashboardWidgetRelation[],
    callback: HierarchyCallback,
  ): boolean {
    return rels.some((rel: DashboardWidgetRelation) => {
      if (widgetId === rel.id) {
        return callback(rel, rels);
      } else {
        if (rel.children) {
          return this._findRelationAndRunProc(widgetId, rel.children, callback);
        } else {
          return false;
        }
      }
    });
  }

  private _widgetToRelation(widget: Widget): DashboardWidgetRelation {
    const rel = new DashboardWidgetRelation();
    rel.id = widget.id;
    rel.name = widget.name;
    rel.type = 'page';
    rel.chartType = (<PageWidgetConfiguration>widget.configuration).chart.type.toString();
    rel.children = [];
    return rel;
  }
}
