import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AutoFocusModule } from 'primeng/autofocus';
import { ButtonModule } from 'primeng/button';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { ContextMenuModule } from 'primeng/contextmenu';
import { InputTextModule } from 'primeng/inputtext';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { RxjsOfPipeModule } from '../../pipes/rxjs-of/rxjs-of.module';
import { CubeDndPlaceholderComponentModule } from '../cube-dnd-placeholder/cube-dnd-placeholder.module';
import { CubeListComponent } from './cube-list.component';
import { ConfirmPopupModule } from 'primeng/confirmpopup';

@NgModule({
  imports: [
    CommonModule,
    ContextMenuModule,
    InputTextModule,
    TreeModule,
    TagModule,
    ButtonModule,
    TooltipModule,
    DragDropModule,
    FormsModule,
    AutoFocusModule,
    CubeDndPlaceholderComponentModule,
    RxjsOfPipeModule,
    ConfirmPopupModule
  ],
  declarations: [CubeListComponent],
  exports: [CubeListComponent],
})
export class CubeListComponentModule {}
