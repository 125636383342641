import { Datasource, DatasourceField } from '../../../datasource';
import { Filter, JoinMapping } from '../../../workbook';

import { BoardDataSourceRelation } from './board-datasource-relation';

export interface BoardDataSource {
  id?: string;
  type?: 'default' | 'multi' | 'mapping';
  name?: string;
  engineName?: string;
  connType?: string;
  joins: JoinMapping[];
  metaDataSource?: Datasource;
  temporary: boolean;
  dataSources: BoardDataSource[];
  associations?: BoardDataSourceRelation[];

  uiFields?: DatasourceField[];
  uiFilters?: Filter[];
  uiDescription?: string;
}

export function createBoardSource(boardSourceOptions: Partial<BoardDataSource> = {}): BoardDataSource {
  return {
    temporary: false,
    joins: [],
    dataSources: [],
    ...boardSourceOptions,
  };
}

export function convertDsToMetaDs(ds: Datasource): BoardDataSource {
  return {
    ...createBoardSource({
      id: ds.id,
      name: ds.name,
      uiDescription: ds.description,
      engineName: ds.engineName,
      uiFields: ds.fields,
      connType: ds.connType?.toString(),
      temporary: !!ds.temporary,
    }),
  };
}
