import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityDataService } from '@ngrx/data';
import { WORKBOOK_PINS_ENTITY_NAME } from '../const';
import { WorkbookPinsState } from '../models';
import { WorkbookPinsDataService } from '../services';

@Injectable({ providedIn: 'root' })
export class WorkbookPinsStore extends EntityCollectionServiceBase<WorkbookPinsState> {
  constructor(
    entityDataService: EntityDataService,
    workbookPinsDataService: WorkbookPinsDataService,
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    entityDataService.registerService(WORKBOOK_PINS_ENTITY_NAME, workbookPinsDataService);
    super(WORKBOOK_PINS_ENTITY_NAME, serviceElementsFactory);
  }
}
