export const LocalStorageKey = {
  USED_CRITERIA: 'USED_CRITERIA',
  USER_SETTING: 'USER_SETTING',
  ENGINE_MONITORING: 'ENGINE_MONITORING',
};

export class LocalStorageConstant {
  public static KEY: Record<keyof typeof LocalStorageKey, any> = {
    USED_CRITERIA: undefined,
    USER_SETTING: undefined,
    ENGINE_MONITORING: undefined,
  };
}
