import { Inject, Injectable, Injector } from '@angular/core';

import * as _ from 'lodash';

import { IMPLEMENTOR_TYPE_LIST, ImplementorType, InputMandatory, JdbcDialect } from '@selfai-platform/bi-domain';

import { AbstractService } from '../../common/service/abstract.service';

@Injectable()
export class StorageService extends AbstractService {
  public static isEnableStageDB: boolean;

  public static connectionTypeList: JdbcDialect[];

  constructor(
    protected injector: Injector,
    @Inject(IMPLEMENTOR_TYPE_LIST) private readonly implementorTypeList: ImplementorType[],
  ) {
    super(injector);
  }

  public checkEnableStageDB() {
    return new Promise((resolve, reject) => {
      this.get(this.API_URL + `storage/stagedb`)
        .then((result) => {
          StorageService.isEnableStageDB = result ? true : false;
          resolve(result);
        })
        .catch((error) => {
          StorageService.isEnableStageDB = false;
          reject(error);
        });
    });
  }

  public setConnectionTypeList() {
    return new Promise((resolve, reject) => {
      this.get(this.API_URL + 'extensions/connection')
        .then((result: JdbcDialect[]) => {
          StorageService.connectionTypeList = result.filter((impl) =>
            this.implementorTypeList.includes(impl.implementor),
          );
          resolve(result);
        })
        .catch((error) => {
          StorageService.connectionTypeList = [];
          reject(error);
        });
    });
  }

  public getConnectionTypeList(): JdbcDialect[] {
    return _.cloneDeep(StorageService.connectionTypeList);
  }

  public findConnectionType(implementorType): JdbcDialect {
    return _.cloneDeep(StorageService.connectionTypeList.find((type) => type.implementor === implementorType));
  }

  public isRequireSid(connectionType: JdbcDialect): boolean {
    return connectionType.inputSpec.sid === InputMandatory.MANDATORY;
  }

  public isRequireDatabase(connectionType: JdbcDialect): boolean {
    return connectionType.inputSpec.database === InputMandatory.MANDATORY;
  }

  public isRequireCatalog(connectionType: JdbcDialect): boolean {
    return connectionType.inputSpec.catalog === InputMandatory.MANDATORY;
  }
}
