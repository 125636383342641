export enum FilterCondition {
  BEGINS_FROM = 'begins_from',
  NOT_BEGINS_FROM = 'not_begins_from',
  ENDS_WITH = 'ends_with',
  NOT_ENDS_WITH = 'not_ends_with',
  EQUALS_TO = 'equals_to',
  NOT_EQUALS_TO = 'not_equals_to',
  IS_NULL = 'is_null',
  NON_NULL = 'non_null',
  CONTAINS = 'contains',
  BOOLEAN = 'bool',
  MORE_THAN = 'more_than',
  LESS_THAN = 'less_than',
  MORE_EQUALS_THAN = 'more_equals_than',
  LESS_EQUALS_THAN = 'less_equals_than',
  REGEXP_LIKE = 'regexp_like',
  REGEXP_NOT_LIKE = 'regexp_not_like',
  DATE_MORE_EQUALS_THAN = 'date_more_equals_than',
  DATE_LESS_EQUALS_THAN = 'date_less_equals_than',
  DAYS_DIFF_MORE_EQUALS_THAN = 'days_diff_more_equals_than',
  DAYS_DIFF_LESS_EQUALS_THAN = 'days_diff_less_equals_than',
}
