import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory } from '@ngrx/data';
import { GLOBAL_FILTERS_ENTITY_NAME } from '../constants';
import { GlobalFiltersState } from '../models';

@Injectable({ providedIn: 'root' })
export class GlobalFiltersStore extends EntityCollectionServiceBase<GlobalFiltersState> {
  constructor(serviceElementsFactory: EntityCollectionServiceElementsFactory) {
    super(GLOBAL_FILTERS_ENTITY_NAME, serviceElementsFactory);
  }
}
