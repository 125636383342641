import { Injectable } from '@angular/core';

import { BiEntityPermission, BiPermission, PermissionService } from '@selfai-platform/shared';
import { PermissionForEntityFactory } from '../models';

@Injectable({
  providedIn: 'root',
})
export class BiPermissionService {
  biPermissionsLoaded$ = this.permissionService.biPermessionsLoadedObs$;

  constructor(private readonly permissionService: PermissionService) {}

  hasPermission(permission: BiPermission) {
    return this.permissionService.hasBiPermission(permission);
  }

  hasOneOfPermission(permissions: BiPermission[]) {
    return this.permissionService.hasOneOfBiPermission(permissions);
  }

  getPermissionsForEntityFactory(entityPermission: BiEntityPermission[]): PermissionForEntityFactory {
    return {
      hasPermission: (permission: BiEntityPermission) => entityPermission.includes(permission),
      hasOneOfPermission: (permissions: BiEntityPermission[]) =>
        permissions.some((permission) => entityPermission.includes(permission)),
    };
  }

  get permissions(): BiPermission[] {
    return this.permissionService.getBiPermissions();
  }
}
