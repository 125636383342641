import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { map, Observable } from 'rxjs';
import { KE_SCHEDULES_LIST_ENTITY_NAME } from '@selfai-platform/store';
import { SchedulesListItem } from '../models';
import { SchedulesApiService } from '../services';
import { Update } from '@ngrx/entity/src/models';

@Injectable({
  providedIn: 'root',
})
export class SchedulesListDataService extends DefaultDataService<SchedulesListItem> {
  constructor(
    public override http: HttpClient,
    public override httpUrlGenerator: HttpUrlGenerator,
    private readonly schedulesApiService: SchedulesApiService,
  ) {
    super(KE_SCHEDULES_LIST_ENTITY_NAME, http, httpUrlGenerator);
  }

  override getAll(): Observable<SchedulesListItem[]> {
    return this.schedulesApiService.loadList().pipe(
      map((items) => items.map((item) => ({
        ...item,
        name: item.id,
      })))
    );
  }

  override getById(id: string): Observable<SchedulesListItem> {
    return this.schedulesApiService
      .getSchedule(id).pipe(map((item) => ({ ...item, name: item.id }))
    );
  }

  override add(data: SchedulesListItem): Observable<SchedulesListItem> {
    return this.schedulesApiService.addSchedule(data).pipe(
      map((item) => ({
        ...item,
        name: item.id,
      }))
    );
  }

  override update(data: Update<SchedulesListItem>): Observable<SchedulesListItem> {
    return this.schedulesApiService.updateSchedule(data).pipe(
      map((item) => ({
        ...item,
        name: item.id,
      }))
    );
  }

  override delete(id: string): Observable<string> {
    return this.schedulesApiService.deleteSchedule(id);
  }
}
