import _ from 'lodash';

import { Pivot, Widget, Shelf, ShelfLayers, createShelf, createShelfLayers } from '@selfai-platform/bi-domain';

export function getChartFieldsFromWidget(widget: Widget): string {
  if (!widget) {
    return undefined;
  }
  let arrFields: string[] = [];

  const pivot: Pivot = widget.configuration['pivot'];

  let shelf: any = widget.configuration['shelf'];
  const layerNum: number = widget.configuration['chart'].layerNum;
  if (
    shelf &&
    undefined !== layerNum &&
    shelf.layers[layerNum] &&
    (shelf.layers[layerNum].length > 0 || shelf.layers[layerNum].fields.length > 0)
  ) {
    if (_.isUndefined(shelf.layers[layerNum].fields)) {
      const tempShelf: Shelf = createShelf();
      for (let idx = 0; idx < shelf.layers.length; idx++) {
        const tempLayer: any = _.cloneDeep(shelf.layers[idx]);
        if (_.isUndefined(tempShelf.layers[idx])) {
          const shelfLayers: ShelfLayers = createShelfLayers();
          tempShelf.layers.push(shelfLayers);
        }
        tempShelf.layers[idx].fields = tempLayer;
      }
      widget.configuration['shelf'] = tempShelf;
      shelf = widget.configuration['shelf'];
    }

    arrFields = arrFields.concat(
      shelf.layers[layerNum].fields.map((item) => {
        if (item.alias) {
          return item.alias;
        } else {
          return item.fieldAlias ? item.fieldAlias : item.name;
        }
      }),
    );
  } else if (pivot) {
    if (pivot.columns) {
      arrFields = arrFields.concat(
        pivot.columns.map((item) => {
          if (item.alias) {
            return item.alias;
          } else {
            return item.fieldAlias ? item.fieldAlias : item.name;
          }
        }),
      );
    }
    if (pivot.rows) {
      arrFields = arrFields.concat(
        pivot.rows.map((item) => {
          if (item.alias) {
            return item.alias;
          } else {
            return item.fieldAlias ? item.fieldAlias : item.name;
          }
        }),
      );
    }
    if (pivot.aggregations) {
      arrFields = arrFields.concat(
        pivot.aggregations.map((item) => {
          if (item.alias) {
            return item.alias;
          } else {
            return item.fieldAlias ? item.fieldAlias : item.name;
          }
        }),
      );
    }
  }
  return arrFields.join(',');
}
