<form [formGroup]="form">
  <selfai-platform-form-field label="Partition keys:">
    <ng-container *ngIf="partitionKeyControls">
      <ng-container *ngFor="let partitionKeyControl of partitionKeyControls; index as i">
        <p-card styleClass="mb-1">
          <div class="wrapper-multiple-controls">
            <div class="multiple-controls">
              <selfai-platform-form-field label="Key:">
                <selfai-platform-json-path-field
                  [formControl]="partitionKeyControl.controls.value"
                ></selfai-platform-json-path-field>
              </selfai-platform-form-field>
            </div>
            <div class="actions-wrapper">
              <ng-container *ngIf="i > 0 || i < partitionKeyControls.length - 1">
                <button
                  type="button"
                  appearance="icon"
                  pButton
                  class="p-button-secondary p-button-text p-button-rounded p-button-link mb-1 btn-arrow"
                  icon="pi pi-arrow-up"
                  [disabled]="i === 0"
                  (click)="orderUp(i)"
                ></button>
                <button
                  type="button"
                  appearance="icon"
                  pButton
                  class="p-button-secondary p-button-text p-button-rounded p-button-link mb-1 btn-arrow"
                  icon="pi pi-arrow-down"
                  [disabled]="i === partitionKeyControls.length - 1"
                  (click)="orderDown(i)"
                ></button>
              </ng-container>
              <button
                (click)="removePartitionKeyControl(i)"
                pButton
                class="p-button-text p-button-rounded p-button-link mb-1 p-button-danger"
                appearance="icon"
                icon="pi pi-trash"
                type="button"
              ></button>
            </div>
          </div>
        </p-card>
      </ng-container>
    </ng-container>
    <button
      label="Add group"
      pButton
      pRipple
      icon="pi pi-plus"
      type="button"
      class="mr-3 mt-3 p-button-sm"
      (click)="addPartitionKeyControl()"
    ></button>
  </selfai-platform-form-field>
  <selfai-platform-form-field label="Partitions">
    <p-inputNumber formControlName="partitions" mode="decimal"></p-inputNumber>
  </selfai-platform-form-field>
  <selfai-platform-form-field label="To">
    <p-dropdown
      [options]="(toItems$ | async) || []"
      formControlName="to"
      placeholder="Choose aggregation route"
      optionLabel="name"
      optionValue="uuid"
    ></p-dropdown>
  </selfai-platform-form-field>
</form>
