import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  AggregatorRouteApiModel,
  AggregatorRouteFormGroup,
  FormGroupNode,
  StepType,
  StepsFormArray,
} from '@selfai-platform/pipeline-common';
import { AlertService, DestroyService, LoadingService, confirmSaveWhenLeaveSite } from '@selfai-platform/shared';
import { DialogService, provideDialogService } from '@selfai-platform/shell';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Observable, map, of, switchMap, takeUntil, withLatestFrom } from 'rxjs';
import { AggregatorActiveItemService } from '../../services/aggregator-active-item.service';
import { AggregatorRouteListService } from '../../services/aggregator-camel-list.service';
import { AggregatorDictionariesService } from '../../services/aggregator-dictionaries.service';
import { AggregatorExportService } from '../../services/aggregator-export.service';
import { AggregatorFormBuilderService } from '../../services/aggregator-form-builder.service';
import { AggregatorImportService } from '../../services/aggregator-import.service';
import { AggregatorItemService } from '../../services/aggregator-item.service';
import { LOADING_ROUTES_LOAD, LOADING_ROUTES_SAVE } from '../../tokens';
import { AggregatorHazelCastMonitorComponent } from '../aggregator-hazel-cast-monitor/aggregator-hazel-cast-monitor.component';
import { AggregatorImportFormComponent } from '../aggregator-import-form';

@Component({
  selector: 'selfai-platform-aggregator-camel-editor',
  templateUrl: './aggregator-camel-editor.component.html',
  styleUrls: ['./aggregator-camel-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideDialogService(), ConfirmationService, AggregatorActiveItemService, DestroyService],
})
export class AggregatorCamelEditorComponent implements OnInit {
  loading$!: Observable<boolean>;
  saving$!: Observable<boolean>;
  isSaved$!: Observable<boolean>;
  stepsMenu$!: Observable<MenuItem[]>;

  routeForm?: FormGroup<AggregatorRouteFormGroup>;
  stepType = StepType;
  nameIsEditing = false;

  actionItems$!: Observable<MenuItem[]>;
  updateAggregatorRoute$!: Observable<Pick<AggregatorRouteApiModel, 'input' | 'name' | 'steps'>>;

  get stepsFormArray(): StepsFormArray | undefined {
    return this.routeForm?.controls.steps;
  }

  get controls(): FormGroup<FormGroupNode>[] | undefined {
    return this.stepsFormArray?.controls;
  }

  constructor(
    private readonly aggregatorRouteListService: AggregatorRouteListService,
    private readonly aggregatorFormBuilderService: AggregatorFormBuilderService,
    private readonly aggregatorDictionariesService: AggregatorDictionariesService,
    private readonly aggregatorActiveItemService: AggregatorActiveItemService,
    private readonly aggregatorItemService: AggregatorItemService,
    private readonly alertService: AlertService,
    private readonly destroy$: DestroyService,
    private readonly aggregatorExportService: AggregatorExportService,
    private readonly aggregatorImportService: AggregatorImportService,
    private readonly dialogImportService: DialogService<AggregatorRouteApiModel, { showReplaceWarn?: boolean }>,
    private readonly route: ActivatedRoute,
    @Inject(LOADING_ROUTES_LOAD) private readonly loadingRoutesLoad: LoadingService,
    @Inject(LOADING_ROUTES_SAVE) private readonly loadingRoutesSave: LoadingService,
  ) {}

  ngOnInit(): void {
    this.loading$ = this.loadingRoutesLoad.isLoading();
    this.saving$ = this.loadingRoutesSave.isLoading();
    this.isSaved$ = this.aggregatorRouteListService.isSaved$;

    this.route.params
      .pipe(
        withLatestFrom(this.aggregatorRouteListService.isSaved$),
        switchMap(([{ processUuid }, isSaved]) => {
          // skip loading if routes are not saved 'cause we lose is added route
          if (!isSaved) {
            return of(null);
          }

          return this.aggregatorRouteListService.loadRouteList(processUuid);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.routeForm = this.aggregatorActiveItemService.getActiveItem();

        this.loadingRoutesSave
          .isLoading()
          .pipe(takeUntil(this.destroy$))
          .subscribe((isLoading) => {
            if (this.routeForm) {
              if (isLoading) {
                this.routeForm.disable();
              } else {
                this.routeForm.enable();
              }
            }
          });
      });

    this.stepsMenu$ = this.aggregatorDictionariesService.getDictionary('stepType').pipe(
      map((steps) =>
        steps.map(({ name, value }) => ({
          label: name,
          command: () => this.addStep(value),
        })),
      ),
    );

    confirmSaveWhenLeaveSite(this.isSaved$).pipe(takeUntil(this.destroy$)).subscribe();
  }

  addStep(stepType: StepType): void {
    if (this.stepsFormArray) {
      this.aggregatorFormBuilderService.addStep(stepType, this.stepsFormArray);
    }
  }

  save(): void {
    if (this.routeForm?.invalid) {
      this.alertService.error('Required fields to be filled');

      return;
    }

    this.aggregatorRouteListService.saveRoutes().subscribe();
  }

  stopEditingRouteName(): void {
    this.nameIsEditing = false;
  }

  startEditingRouteName(): void {
    this.nameIsEditing = true;
  }

  exportToFile(): void {
    const uuid = this.aggregatorActiveItemService.getActiveItemId();
    if (uuid) {
      this.aggregatorExportService.exportRoute(uuid);
    }
  }

  openImportForm(): void {
    const uuid = this.aggregatorActiveItemService.getActiveItemId();
    if (uuid) {
      this.dialogImportService
        .showDialog(AggregatorImportFormComponent, {
          header: 'Import aggregate',
          width: '50%',
        })
        .subscribe((data?: AggregatorRouteApiModel) => {
          if (data) {
            this.aggregatorImportService.replaceRoute(uuid, data as AggregatorRouteApiModel);
            this.routeForm = this.aggregatorActiveItemService.getActiveItem();
          }
        });
    }
  }

  openHazelCast(): void {
    this.dialogImportService
      .showDialog(AggregatorHazelCastMonitorComponent, {
        header: 'Hazel Cast Monitor',
        width: '50%',
      })
      .subscribe(() => {
        this.routeForm = this.aggregatorActiveItemService.getActiveItem();
      });
  }

  toggleActiveStatus(): void {
    const uuid = this.aggregatorActiveItemService.getActiveItemId();
    if (uuid) {
      this.aggregatorItemService.toggleActiveStatusRoute(uuid);
    }
  }
}
