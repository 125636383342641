import { Component, ElementRef, Injector, Input } from '@angular/core';

import * as _ from 'lodash';

import { MapType, UIMapOption } from '@selfai-platform/bi-domain';

import { CommonConstant } from '../../../common/constant/common.constant';
import { BaseOptionComponent } from '../base-option.component';

@Component({
  selector: 'map-common-option',
  templateUrl: './map-common-option.component.html',
})
export class MapCommonOptionComponent extends BaseOptionComponent {
  @Input()
  public uiOption: UIMapOption;

  public mapServiceList = [
    { name: this.translateService.instant('msg.page.common.map.layer.service.openstreet'), value: MapType.OSM },
  ];

  public mapStyleList = [
    { name: this.translateService.instant('msg.page.common.map.layer.map.style.light'), value: 'Light' },
    { name: this.translateService.instant('msg.page.common.map.layer.map.style.dark'), value: 'Dark' },
    { name: this.translateService.instant('msg.page.common.map.layer.map.style.colored'), value: 'Colored' },
  ];

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();

    const propMapConf = sessionStorage.getItem(CommonConstant.PROP_MAP_CONFIG);
    if (propMapConf) {
      const objConf = JSON.parse(propMapConf);
      if (objConf.baseMaps) {
        this.mapStyleList = this.mapStyleList.concat(
          objConf.baseMaps.map((item) => {
            return { name: item.name, value: item.name };
          }),
        );
      }
      if (objConf.defaultBaseMap) {
        this.setMapStyle(this.mapStyleList.find((item) => this.uiOption.style === item.name));
      }
    }
  }

  public setLicense() {
    this.uiOption = <UIMapOption>_.extend({}, this.uiOption, {
      licenseNotation: this.uiOption.licenseNotation,
    });

    this.update();
  }

  public setMapStyle(data: Object) {
    this.uiOption = <UIMapOption>_.extend({}, this.uiOption, {
      style: data['value'],
    });

    this.update();
  }

  public setMapService(data: Object) {
    this.uiOption = <UIMapOption>_.extend({}, this.uiOption, {
      map: data['value'],
    });

    this.update();
  }

  public findServiceDefaultIndex() {
    return _.findIndex(this.mapServiceList, { value: this.uiOption.map });
  }

  public findStyleDefaultIndex() {
    return _.findIndex(this.mapStyleList, { value: this.uiOption.style });
  }
}
