import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Optional, Output } from '@angular/core';
import { CubeState } from '@selfai-platform/pipeline-common';
import { DialogHelperService } from '../../../dialog';

import { AlertService, DestroyService } from '@selfai-platform/shared';
import { EditorStateService } from '@selfai-platform/storage';
import { Observable } from 'rxjs';
import { CubeDialogStateService } from '../../services/cube-dialog-state.service';

@Component({
  selector: 'selfai-platform-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class DialogHeaderComponent {
  @HostListener('mousedown') setFocusEvent() {
    this.setFocus.emit(this.nodeId);
  }
  @Input() header: string;
  @Input() nodeId: string;
  @Input() saveButtonDisabled = false;
  @Input() minimizeButtonShow = true;

  @Output() closeDialog = new EventEmitter<never>();
  @Output() clickSave = new EventEmitter<never>();
  @Output() setFocus = new EventEmitter<string>();
  @Output() minimize = new EventEmitter<string>();

  cubeState$: Observable<CubeState> = this.cubeDialogStateService.getCubeState();

  constructor(
    private readonly cubeDialogStateService: CubeDialogStateService,
    private readonly alertService: AlertService,
    private readonly editorStateService: EditorStateService,
    @Optional() public readonly dialogHelperService?: DialogHelperService,
  ) {}

  maximizeDialog(): void {
    this.dialogHelperService?.maximizeDialog();
  }

  copyIdToClipboard() {
    navigator.clipboard.writeText(this.nodeId);
    this.alertService.success('Node ID copied to clipboard');
  }

  get workflowIsInEditMode(): Observable<boolean> {
    return this.editorStateService.getIsEditMode();
  }
}
