import { Directive, ElementRef, Input } from '@angular/core';

declare const Inputmask: any;

@Directive({
  selector: '[input-mask]',
})
export class InputMaskDirective {
  constructor(private el: ElementRef) {}

  private regexMap = {
    number: '^[0-9]*$',
    float: '^[+-]?([0-9]*[.])?[0-9]+$',
    words: '([A-z]*\\s)*',
    calendar: '^[0-9:-\\s]*$',
  };

  @Input('input-mask')
  public set defineInputType(regexType: string) {
    if (regexType === 'number') {
      Inputmask({
        regex: this.regexMap[regexType],
        placeholder: '',
        onBeforeMask: (value) => {
          if (value) {
            return value.toString();
          }
          return value;
        },
      }).mask(this.el.nativeElement);
    } else {
      Inputmask({ regex: this.regexMap[regexType], placeholder: '' }).mask(this.el.nativeElement);
    }
  }
}
