import { Component, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';

import * as _ from 'lodash';

import { OptionGenerator } from '@selfai-platform/bi-chart-engine';
import {
  AxisLabelType,
  ChartAxisLabelType,
  ChartType,
  Format,
  LogicalType,
  Orient,
  UIChartAxis,
  UIChartAxisLabelValue,
  UIFormatCurrencyType,
  UIFormatType,
  UIOption,
} from '@selfai-platform/bi-domain';
import { DestroyService } from '@selfai-platform/shared';

import { YAxisOptionsService } from '../service/y-axis-options.service';

import { take, takeUntil } from 'rxjs';
import { FormatOptionComponent } from './format-option.component';

import UI = OptionGenerator.UI;

@Component({
  selector: 'yaxis-option',
  templateUrl: './yaxis-option.component.html',
  styleUrls: ['./yaxis-option.component.scss'],
  providers: [DestroyService],
})
export class YAxisOptionComponent extends FormatOptionComponent {
  public xAxisRotateFlag = false;

  public yAxisRotateFlag = false;

  public nameUiOption: UIOption;

  @Output('changeAxisName')
  public changeAxisNameEvent: EventEmitter<any> = new EventEmitter();

  public isSecondaryAxis = false;

  constructor(
    protected elementRef: ElementRef,
    protected injector: Injector,
    protected yAxisOptionsService: YAxisOptionsService,
    protected readonly destroy$: DestroyService,
  ) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  @Input('uiOption')
  public set setUiOption(uiOption: UIOption) {
    this.uiOption = uiOption;

    if (!this.uiOption.xAxis.label) {
      if (_.eq(ChartType.SCATTER, this.uiOption.type)) {
        this.uiOption.xAxis.label = UI.AxisLabel.axisLabelForValue(ChartAxisLabelType.VALUE);
      } else {
        this.uiOption.xAxis.label =
          this.uiOption['align'] && Orient.HORIZONTAL == this.uiOption['align']
            ? UI.AxisLabel.axisLabelForValue(ChartAxisLabelType.VALUE)
            : UI.AxisLabel.axisLabelForCategory(ChartAxisLabelType.CATEGORY);
      }
    }

    if (!this.uiOption.yAxis.label) {
      if (_.eq(ChartType.HEATMAP, this.uiOption.type)) {
        this.uiOption.yAxis.label = UI.AxisLabel.axisLabelForCategory(ChartAxisLabelType.CATEGORY);
      } else {
        this.uiOption.yAxis.label =
          this.uiOption['align'] && Orient.HORIZONTAL == this.uiOption['align']
            ? UI.AxisLabel.axisLabelForCategory(ChartAxisLabelType.CATEGORY)
            : UI.AxisLabel.axisLabelForValue(ChartAxisLabelType.VALUE);
      }
    }

    if (this.isSecondaryAxis && !this.uiOption.secondaryAxis) {
      this.uiOption.secondaryAxis = _.cloneDeep(this.uiOption.yAxis);
      this.uiOption.secondaryAxis.label = UI.AxisLabel.axisLabelForValue(ChartAxisLabelType.VALUE);
    }

    this.nameUiOption = _.cloneDeep(this.uiOption);
  }

  public axisName(axisLabelType: any, idx: number, name: string): void {
    if (!this.uiOption.xAxis.showName) return;

    const value = name
      ? name
      : AxisLabelType.ROW === axisLabelType
      ? this.nameUiOption.xAxis.customName
      : this.nameUiOption.yAxis.customName;

    if (value && value.length > 20) {
      this.alertPrimeService.info(this.translateService.instant('msg.page.alert.axis.label.warn'));
      return;
    }

    if (_.eq(this.uiOption.yAxis.mode, axisLabelType)) {
      this.uiOption.yAxis.name = value;
    }

    if (_.isEmpty(value) || (value && _.isEmpty(value.trim()))) {
      delete this.uiOption.yAxis.customName;
    } else {
      this.uiOption.yAxis.customName = value.trim();
    }

    this.uiOption = <UIOption>_.extend({}, this.uiOption, { yAxis: this.uiOption.yAxis });
    this.update();
    this.changeAxisNameEvent.emit();
  }

  public showAxisName(axisLabelType: any, show: boolean): void {
    if (_.eq(this.uiOption.yAxis.mode, axisLabelType)) {
      this.uiOption.yAxis.showName = show;
    }
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { yAxis: this.uiOption.yAxis });

    this.update();
  }

  public showAxisLabel(axisLabelType: any, show: boolean): void {
    if (_.eq(this.uiOption.yAxis.mode, axisLabelType)) {
      this.uiOption.yAxis.showLabel = show;
    }
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { yAxis: this.uiOption.yAxis });

    this.update();
  }

  public rotateAxisLabel(axisLabelType: any, rotate: any): void {
    this.xAxisRotateFlag = false;
    this.yAxisRotateFlag = false;

    if (_.eq(this.uiOption.yAxis.mode, axisLabelType)) {
      this.uiOption.yAxis.label = this.uiOption.yAxis.label ? this.uiOption.yAxis.label : {};
      this.uiOption.yAxis.label['rotation'] = rotate;
    }
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { yAxis: this.uiOption.yAxis });

    this.update();
  }

  public showAxisConfig() {
    this.uiOption.yAxis.axisOption.showFl = !this.uiOption.yAxis.axisOption.showFl;
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { yAxis: this.uiOption.yAxis });
    this.update();
  }

  public changeAxisConfig(axisValue: number, type: string) {
    const axisConfig = this.uiOption.yAxis.axisOption;

    if (!axisConfig) return;

    switch (type) {
      case 'min':
        if (!axisValue) axisValue = axisConfig.originMin;
        else if (axisValue >= axisConfig.max) {
          this.alertPrimeService.info(
            this.translateService.instant('msg.page.chart.color.axis.config.start.limit.alert'),
          );
          axisValue = axisConfig.originMin;
          return;
        }
        axisConfig.min = axisValue;
        break;
      case 'max':
        if (!axisValue) axisValue = axisConfig.originMax;
        else if (axisValue <= axisConfig.min) {
          this.alertPrimeService.info(
            this.translateService.instant('msg.page.chart.color.axis.config.end.limit.alert'),
          );
          axisValue = axisConfig.originMax;
          return;
        }

        axisConfig.max = axisValue;
        break;
      case 'interval': {
        axisConfig.max = !axisConfig.max ? axisConfig.originMax : axisConfig.max;
        axisConfig.min = !axisConfig.min ? axisConfig.originMin : axisConfig.min;

        const diffVal = axisConfig.max - axisConfig.min;

        if (diffVal < axisValue) {
          this.alertPrimeService.info(
            this.translateService.instant('msg.page.chart.color.axis.config.unit.limit.alert', {
              value: diffVal.toFixed(0),
            }),
          );

          axisValue = null;
          return;
        }

        axisConfig.interval = axisValue;
        break;
      }
    }

    axisConfig.changeType = type;
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { yAxis: this.uiOption.yAxis });
    this.update();
  }

  public numericDimensionCheck(): boolean {
    const dimensionList = this.uiOption.fielDimensionList;

    if (!dimensionList) return false;

    const checkDimensionList = dimensionList.filter((item: any) => {
      if (
        (item.type == 'dimension' && item.field.logicalType == LogicalType.INTEGER) ||
        item.field.logicalType == LogicalType.DOUBLE
      ) {
        return item;
      }
    });

    return checkDimensionList.length == dimensionList.length;
  }

  public showLabel() {
    if (!(<UIChartAxisLabelValue>this.uiOption.yAxis.label).format) {
      (<UIChartAxisLabelValue>this.uiOption.yAxis.label).useDefault = false;

      (<UIChartAxisLabelValue>this.uiOption.yAxis.label).format = UI.Format.custom(
        true,
        null,
        String(UIFormatType.NUMBER),
        String(UIFormatCurrencyType.KRW),
        2,
        true,
      );
    } else {
      (<UIChartAxisLabelValue>this.uiOption.yAxis.label).useDefault = true;

      delete (<UIChartAxisLabelValue>this.uiOption.yAxis.label).format;
    }

    this.uiOption = <UIOption>_.extend({}, this.uiOption, { yAxis: this.uiOption.yAxis });

    this.update();
  }

  public onChange(target: Object): void {
    (<UIChartAxisLabelValue>this.uiOption.yAxis.label).format = target as Format;

    this.uiOption = <UIOption>_.extend({}, this.uiOption, { yAxis: this.uiOption.yAxis });

    this.update();
  }

  public changeYAxisValue(axis: UIChartAxis): void {
    this.yAxisOptionsService
      .getAxisConfig()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe((axisConfig) => {
        this.uiOption = <UIOption>_.extend({}, this.uiOption, { yAxis: { ...axisConfig, ...axis } });
        this.update();
      });
  }

  public changeBaseline(axis: UIChartAxis): void {
    this.uiOption = <UIOption>_.extend({}, this.uiOption, { yAxis: axis });

    this.update({});
  }
}
