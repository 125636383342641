<div class="ddp-ui-chart-side ddp-show">
  <div class="ddp-ui-side-title">
    {{ 'msg.page.ui.legend.title' | translate }}
  </div>

  <div class="ddp-wrap-downmenu">
    <div class="ddp-box-down">
      <div class="ddp-wrap-divide">
        <div class="ddp-wrap-option-slider">
          <span class="ddp-label-slider">{{ 'msg.page.ui.legend.show.legend' | translate }}</span>

          <div class="ddp-checkbox-slide ddp-checkbox-automatic2">
            <input
              type="checkbox"
              id="check2"
              [ngModel]="uiOption.legend.auto && uiOption.legend.showName"
              [disabled]="false === uiOption.legend.auto"
              (click)="toggleLegend()"
            />
            <label for="check2"><span class="ddp-slide"></span></label>
          </div>
        </div>

        <div class="ddp-wrap-option-multy ddp-clear" *ngIf="uiOption.legend.auto && uiOption.legend.showName">
          <div class="ddp-col-5">
            <span class="ddp-label-txt">{{ 'msg.page.ui.legend.legend.position' | translate }}</span>
          </div>
          <div class="ddp-col-7">
            <component-select
              [array]="legendPositionList"
              [viewKey]="'name'"
              [defaultIndex]="getLegendPosIndex()"
              (onSelected)="changeLegendPos($event)"
            ></component-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
