import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AutoFocusModule } from 'primeng/autofocus';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';
import { AggregatorHazelCastMonitorComponent } from './aggregator-hazel-cast-monitor.component';

@NgModule({
  imports: [CommonModule, MessageModule, ReactiveFormsModule, ButtonModule, InputTextModule, AutoFocusModule],
  declarations: [AggregatorHazelCastMonitorComponent],
  exports: [AggregatorHazelCastMonitorComponent],
})
export class AggregatorHazelCastMonitorComponentModule {}
