import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { DashboardFiltersState } from '../../models';
import { DashboardFiltersStore } from '../../stores/dashboard-filters.store';

@Injectable({ providedIn: 'root' })
export class DashboardFiltersService {
  constructor(private readonly dashboardFiltersStore: DashboardFiltersStore) {}

  setDashboardFilters(filterState: DashboardFiltersState): void {
    this.dashboardFiltersStore.upsertOneInCache(filterState);
  }

  getDashboardFilters(dashboardId: string): Observable<DashboardFiltersState> {
    return this.dashboardFiltersStore.entityMap$.pipe(map((entityMap) => entityMap[dashboardId]));
  }
}
