import { Injectable } from '@angular/core';
import { MergeStrategy } from '@ngrx/data';
import { Observable, filter, map, tap, withLatestFrom } from 'rxjs';
import { DashboardPin } from '../models';
import { WorkbookPinsStore } from '../stores';
import { WorkbookApiToDomainService } from './workbook-domain-api.service';

@Injectable({ providedIn: 'root' })
export class WorkbookPinsDomainService {
  constructor(
    private readonly workbookApiToDomainService: WorkbookApiToDomainService,
    private readonly workbookPinsStore: WorkbookPinsStore,
  ) {}

  addAllPins(workbookId: string, pinsDashboard: DashboardPin[]): Observable<void> {
    return this.workbookPinsStore
      .upsert(
        { workbookId, pins: pinsDashboard },
        { isOptimistic: true, mergeStrategy: MergeStrategy.OverwriteChanges },
      )
      .pipe(
        tap(() => {
          this.workbookPinsStore.upsertOneInCache(
            { workbookId, pins: pinsDashboard },
            { mergeStrategy: MergeStrategy.OverwriteChanges },
          );
        }),
        map(() => void 0),
      );
  }

  getPins(workbookId: string): Observable<DashboardPin[]> {
    return this.workbookPinsStore.entityMap$.pipe(
      filter((res) => Boolean(res[workbookId])),
      map((res) => res[workbookId].pins || []),
    );
  }

  loadPins(workbookId: string): Observable<DashboardPin[]> {
    return this.workbookPinsStore
      .getByKey(workbookId, { mergeStrategy: MergeStrategy.OverwriteChanges })
      .pipe(map((res) => res.pins));
  }

  deleteDashboardFromPins(workbookId: string, dashboardId: string): Observable<void> {
    return this.workbookApiToDomainService.deleteDashboardFromPins(workbookId, dashboardId).pipe(
      withLatestFrom(this.getPins(workbookId)),
      map(([, pins]) => {
        this.workbookPinsStore.updateOneInCache(
          {
            workbookId,
            pins: pins.filter((pin) => pin.dashboardId !== dashboardId),
          },
          { mergeStrategy: MergeStrategy.OverwriteChanges },
        );
      }),
      map(() => void 0),
    );
  }

  addDashboardToPins(workbookId: string, pin: DashboardPin): Observable<void> {
    return this.workbookApiToDomainService.addDashboardToPins(workbookId, pin.dashboardId).pipe(
      withLatestFrom(this.getPins(workbookId)),
      map(([, pins]) => {
        this.workbookPinsStore.upsertOneInCache({
          workbookId,
          pins: [...pins, pin],
        });
      }),
      map(() => void 0),
    );
  }

  deleteAllPins(workbookId: string): Observable<void> {
    return this.workbookPinsStore.delete(workbookId).pipe(map(() => void 0));
  }

  isPinned(workbookId: string, dashboardId: string): Observable<boolean> {
    return this.getPins(workbookId).pipe(map((pins) => pins.some((pin) => pin.dashboardId === dashboardId)));
  }
}
