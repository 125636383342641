export enum DataType {
  STRING = 'string',
  BOOLEAN = 'boolean',
  TIMESTAMP = 'timestamp',
  DOUBLE = 'double',
  FLOAT = 'float',
  BIGINT = 'bigint',
  INT = 'int',
  DATE = 'date',
}
