import { Injectable } from '@angular/core';
import { PageParams, UrlPageParamsService } from '@selfai-platform/shared';
import { TableLazyLoadEvent } from 'primeng/table';
import { calcPageNumber } from '../utils';

@Injectable()
export class DataListViewLazyLoadService {
  constructor(private readonly urlPageParamsService: UrlPageParamsService) {}

  onLazyLoad(event: TableLazyLoadEvent): void {
    const pageParams: Pick<PageParams, 'pageNumber' | 'pageSize' | 'sortField' | 'sortOrder' | 'filters'> = {
      pageNumber: calcPageNumber(event.first, event.rows),
      pageSize: event.rows,
      sortField: event.sortField as string,
      sortOrder: event.sortOrder > 0 ? 'asc' : 'desc',
    };
    this.urlPageParamsService.setPageParams(pageParams);
  }
}
