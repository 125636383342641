import { HeaderMenu } from './grid.header.menu';

declare const Slick: any;

export class SlickGridHeader {
  private _asyncPostRender: string = null;
  private _behavior: string = null;
  private _cannotTriggerInsert: boolean = null;
  private _cssClass = '';
  private _defaultSortAsc = true;
  private _editor: GRID_EDIT_TYPE = null;
  private _field = '';
  private _focusable = true;
  private _formatter: any = null;
  private _headerCssClass: string = null;
  private _id = '';
  private _maxWidth: string = null;
  private _minWidth = 30;
  private _name = '';
  private _rerenderOnResize = false;
  private _resizable = true;
  private _selectable = true;
  private _sortable = false;
  private _toolTip = '';
  private _width: number;
  private _columnType: string;

  private _header: HeaderMenu;

  private _validator: any;
  private _unselectable: boolean;

  constructor() {}

  get columnType(): string {
    return this._columnType;
  }

  ColumnType(value: string): SlickGridHeader {
    this._columnType = value;
    return this;
  }

  get asyncPostRender(): string {
    return this._asyncPostRender;
  }

  AsyncPostRender(value: string): SlickGridHeader {
    this._asyncPostRender = value;
    return this;
  }

  get behavior(): string {
    return this._behavior;
  }

  Behavior(value: string): SlickGridHeader {
    this._behavior = value;
    return this;
  }

  get cannotTriggerInsert(): boolean {
    return this._cannotTriggerInsert;
  }

  CannotTriggerInsert(value: boolean): SlickGridHeader {
    this._cannotTriggerInsert = value;
    return this;
  }

  get cssClass(): string {
    return this._cssClass;
  }

  CssClass(value: string): SlickGridHeader {
    this._cssClass = value;
    return this;
  }

  get defaultSortAsc(): boolean {
    return this._defaultSortAsc;
  }

  DefaultSortAsc(value: boolean): SlickGridHeader {
    this._defaultSortAsc = value;
    return this;
  }

  get editor(): GRID_EDIT_TYPE {
    return this._editor;
  }

  Editor(value: GRID_EDIT_TYPE): SlickGridHeader {
    switch (value) {
      case GRID_EDIT_TYPE.PERCENT:
        this._editor = Slick.Editors.PercentComplete;
        break;
      case GRID_EDIT_TYPE.CHECK:
        this._editor = Slick.Editors.Checkbox;
        break;
      case GRID_EDIT_TYPE.TEXT:
      default:
        this._editor = Slick.Editors.Text;
        break;
    }
    return this;
  }

  get field(): string {
    return this._field;
  }

  Field(value: string): SlickGridHeader {
    this._field = value;
    return this;
  }

  get focusable(): boolean {
    return this._focusable;
  }

  Focusable(value: boolean): SlickGridHeader {
    this._focusable = value;
    return this;
  }

  get formatter(): any {
    return this._formatter;
  }

  Formatter(value: any): SlickGridHeader {
    this._formatter = value;
    return this;
  }

  get headerCssClass(): string {
    return this._headerCssClass;
  }

  HeaderCssClass(value: string): SlickGridHeader {
    this._headerCssClass = value;
    return this;
  }

  get id(): string {
    return this._id;
  }

  Id(value: string): SlickGridHeader {
    this._id = value;
    return this;
  }

  get maxWidth(): string {
    return this._maxWidth;
  }

  MaxWidth(value: string): SlickGridHeader {
    this._maxWidth = value;
    return this;
  }

  get minWidth(): number {
    return this._minWidth;
  }

  MinWidth(value: number): SlickGridHeader {
    this._minWidth = value;
    return this;
  }

  get name(): string {
    return this._name;
  }

  Name(value: string): SlickGridHeader {
    this._name = value;
    return this;
  }

  get rerenderOnResize(): boolean {
    return this._rerenderOnResize;
  }

  RerenderOnResize(value: boolean): SlickGridHeader {
    this._rerenderOnResize = value;
    return this;
  }

  get resizable(): boolean {
    return this._resizable;
  }

  Resizable(value: boolean): SlickGridHeader {
    this._resizable = value;
    return this;
  }

  get selectable(): boolean {
    return this._selectable;
  }

  Selectable(value: boolean): SlickGridHeader {
    this._selectable = value;
    return this;
  }

  get sortable(): boolean {
    return this._sortable;
  }

  Sortable(value: boolean): SlickGridHeader {
    this._sortable = value;
    return this;
  }

  get toolTip(): string {
    return this._toolTip;
  }

  ToolTip(value: string): SlickGridHeader {
    this._toolTip = value;
    return this;
  }

  get width(): number {
    return this._width;
  }

  Width(value: number): SlickGridHeader {
    this._width = value;
    return this;
  }

  get validator(): any {
    return this._validator;
  }

  Validator(value: any): SlickGridHeader {
    this._validator = value;
    return this;
  }

  get unselectable(): boolean {
    return this._unselectable;
  }

  Unselectable(value: boolean): SlickGridHeader {
    this._unselectable = value;
    return this;
  }

  get header(): HeaderMenu {
    return this._header;
  }

  Header(value: HeaderMenu): SlickGridHeader {
    this._header = value;
    return this;
  }

  build(): header {
    return new header(this);
  }
}

export enum GRID_EDIT_TYPE {
  TEXT = <any>'REJECTED',
  PERCENT = <any>'EXPIRED',
  CHECK = <any>'LOCKED',
}

export class header {
  private asyncPostRender: string;
  private behavior: string;
  private cannotTriggerInsert: boolean;
  private cssClass: string;
  private defaultSortAsc: boolean;
  private editor: any;
  private field: string;
  private focusable: boolean;
  private formatter: any;
  private headerCssClass: string;
  private id: string;
  private maxWidth: string;
  private minWidth: number;
  private name: string;
  private rerenderOnResize: boolean;
  private resizable: boolean;
  private selectable: boolean;
  private sortable: boolean;
  private toolTip: string;
  private width: number;
  private validator: any;
  private unselectable: boolean;
  private header: HeaderMenu;
  private columnType: string;

  public setFormatter(formatter: any) {
    this.formatter = formatter;
  }

  constructor(builder: SlickGridHeader) {
    if (typeof builder.asyncPostRender !== 'undefined') {
      this.asyncPostRender = builder.asyncPostRender;
    }
    if (typeof builder.behavior !== 'undefined') {
      this.behavior = builder.behavior;
    }
    if (typeof builder.cannotTriggerInsert !== 'undefined') {
      this.cannotTriggerInsert = builder.cannotTriggerInsert;
    }
    if (typeof builder.cssClass !== 'undefined') {
      this.cssClass = builder.cssClass;
    }
    if (typeof builder.defaultSortAsc !== 'undefined') {
      this.defaultSortAsc = builder.defaultSortAsc;
    }
    if (typeof builder.editor !== 'undefined') {
      switch (builder.editor) {
        case GRID_EDIT_TYPE.PERCENT:
          this.editor = Slick.Editors.PercentComplete;
          break;
        case GRID_EDIT_TYPE.CHECK:
          this.editor = Slick.Editors.Checkbox;
          break;
        case GRID_EDIT_TYPE.TEXT:
        default:
          this.editor = Slick.Editors.Text;
          break;
      }
    }
    if (typeof builder.field !== 'undefined') {
      this.field = builder.field;
    }
    if (typeof builder.focusable !== 'undefined') {
      this.focusable = builder.focusable;
    }
    if (typeof builder.formatter !== 'undefined') {
      this.formatter = builder.formatter;
    }
    if (typeof builder.headerCssClass !== 'undefined') {
      this.headerCssClass = builder.headerCssClass;
    }
    if (typeof builder.id !== 'undefined') {
      this.id = builder.id;
    }
    if (typeof builder.maxWidth !== 'undefined') {
      this.maxWidth = builder.maxWidth;
    }
    if (typeof builder.minWidth !== 'undefined') {
      this.minWidth = builder.minWidth;
    }
    if (typeof builder.name !== 'undefined') {
      this.name = builder.name;
    }
    if (typeof builder.rerenderOnResize !== 'undefined') {
      this.rerenderOnResize = builder.rerenderOnResize;
    }
    if (typeof builder.resizable !== 'undefined') {
      this.resizable = builder.resizable;
    }
    if (typeof builder.selectable !== 'undefined') {
      this.selectable = builder.selectable;
    }
    if (typeof builder.sortable !== 'undefined') {
      this.sortable = builder.sortable;
    }
    if (typeof builder.toolTip !== 'undefined') {
      this.toolTip = builder.toolTip;
    }
    if (typeof builder.width !== 'undefined') {
      this.width = builder.width;
    }
    if (typeof builder.validator !== 'undefined') {
      this.validator = builder.validator;
    }
    if (typeof builder.unselectable !== 'undefined') {
      this.unselectable = builder.unselectable;
    }
    if (typeof builder.header !== 'undefined') {
      this.header = builder.header;
    }
    if (typeof builder.columnType !== 'undefined') {
      this.columnType = builder.columnType;
    }
  }
}
