import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatasourceType } from '@selfai-platform/pipeline-common';
import { DataSourcesVisibilityEnum } from '../../enums/data-sources-visibility.enum';
import { DataSourceForm, DataSourceFormValues } from '../../model/data-source-form.model';

@Injectable()
export class DataSourceFormService {
  form: FormGroup<DataSourceForm> = new FormGroup<DataSourceForm>({
    name: new FormControl('', [Validators.required]),
    visibility: new FormControl(DataSourcesVisibilityEnum.PRIVATE, [Validators.required]),
    datasourceType: new FormControl(null, [Validators.required]),
    properties: new FormControl(''),
  });

  initForm(formValues: DataSourceFormValues): void {
    this.form.patchValue(formValues);
  }

  addParamsForm(paramsForm: FormGroup): void {
    this.form.addControl('params', paramsForm);
  }

  setDataSourceType(type: DatasourceType): void {
    this.form.get('datasourceType').setValue(type);
  }
}
