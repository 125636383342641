import { Observable, map } from 'rxjs';
import { KeMqInfoApiService } from './ke-mq-info-api.service';
import { extractCredentialsForMq } from '../functions/extract-credentials-for-mq';
import { KeMqInfo } from '@selfai-platform/pipeline-common';
import { Injectable } from '@angular/core';

@Injectable()
export class KeMqInfoService {
  constructor(private readonly keMqInfoApiService: KeMqInfoApiService) {}

  getMqInfo(): Observable<KeMqInfo> {
    return this.keMqInfoApiService.getMqInfo().pipe(map((resp) => extractCredentialsForMq(resp)));
  }
}
