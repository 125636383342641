import $ from 'jquery';

export class Loading {
  public static $loading: any = $('.ddp-loading');

  public static show() {
    0 === this.$loading.length && (this.$loading = $('.ddp-loading'));
    this.$loading.show();
  }

  public static hide() {
    this.$loading.hide();
  }
}
