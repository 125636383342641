import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  CreateDataColumnApiType,
  CreateDataColumnType,
  CreateDataFrameColumnMapping,
  CreateDataFrameColumnMappingFormGroup,
  CreateDataFrameFormGroup,
  CreateDataFrameParameters,
  CreateDataFrameRawParameters,
  CubeState,
  CubeWorkflowData,
  ExtractFormValueType,
  GraphNode,
} from '@selfai-platform/pipeline-common';
import { AlertService } from '@selfai-platform/shared';
import { DialogService } from '@selfai-platform/shell';
import { WorkflowStateService } from '@selfai-platform/storage';
import { WorkflowReportDomainService } from 'libs/pipeline-module/src/lib/wokflow-list';
import { debounceTime, distinctUntilKeyChanged, filter, Observable, skip, startWith, Subject, take } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { extractDataSampleFromExecutionReport } from '../../../../utils/extract-table-from-execution-report';
import { WorkflowEditorFacadeService } from '../../../../workflow-editor';
import { CubeDialogStateService } from '../../../services/cube-dialog-state.service';

const DATA_ROWS_COUNT = 20;

@Injectable()
export class CreateDataFrameComponentService {
  get node(): GraphNode<CreateDataFrameRawParameters> {
    return this.dialogService.data.selectedNode;
  }

  get nodeId(): string {
    return this.node.id;
  }

  get hasParameters(): boolean {
    return Boolean(this.node.parameters);
  }

  get nodeParameters(): CreateDataFrameParameters {
    return this.normalizeRawCreateDataFrameParameters(this.node.parameters.serialize());
  }

  get columns(): CreateDataFrameColumnMapping[] {
    return this.form.controls.columnMapping.value as CreateDataFrameColumnMapping[];
  }

  nodeState$: Observable<CubeState> = this.cubeDialogStateService.getCubeState();

  form = new FormGroup<CreateDataFrameFormGroup>({
    dataSourceId: new FormControl(null),
    dataOfFrame: new FormControl(null),
    columnMapping: new FormArray<FormGroup<CreateDataFrameColumnMappingFormGroup>>([]),
  });

  private applying = new Subject<boolean>();

  constructor(
    private readonly workflowEditorFacadeService: WorkflowEditorFacadeService,
    private readonly workflowReportDomainService: WorkflowReportDomainService,
    private readonly workflowStateService: WorkflowStateService,
    private readonly dialogService: DialogService<undefined, CubeWorkflowData<CreateDataFrameRawParameters>>,
    private readonly cubeDialogStateService: CubeDialogStateService,
    private readonly alertService: AlertService,
    private readonly translate: TranslateService,
    private readonly destroyRef: DestroyRef,
  ) {}

  onSubmit(): void {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.workflowEditorFacadeService.updateNodeParamterValues({
        id: this.nodeId,
        parameters: this.normalizeFormValuesToApiModel(this.form.value as CreateDataFrameParameters),
      });
      this.dialogService.close();
    }
  }

  onCloseDialog(): void {
    this.dialogService.close();
  }

  addColumnFormGroup(item?: Omit<CreateDataFrameColumnMapping, 'id'>): void {
    this.form.controls.columnMapping.push(
      new FormGroup<CreateDataFrameColumnMappingFormGroup>({
        id: new FormControl(uuidv4()),
        columnName: new FormControl(item?.columnName ?? null, [Validators.required]),
        columnType: new FormControl(item?.columnType ?? null, [Validators.required]),
      }),
    );
  }

  applyAndRun(): void {
    if (this.form.valid) {
      this.applying.next(true);
      this.form.markAsPristine();

      if (this.form.controls.dataSourceId.value) {
        this.tryToUpdateDataSource(this.form.controls.dataSourceId.value);
      } else {
        this.updateNodeParameters();
      }

      this.waitUntilStatusChangeTo(['status_draft'])
        .pipe(
          // we need to be sure the workflow have already been updated
          debounceTime(1000),
          take(1),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(() => {
          this.workflowEditorFacadeService.runWorkflow([this.nodeId]);
        });

      this.waitUntilStatusChangeTo(['status_completed', 'status_failed'])
        .pipe(take(1), takeUntilDestroyed(this.destroyRef))
        .subscribe((state) => {
          this.applying.next(false);

          switch (state.status) {
            case 'status_completed':
              this.alertService.success(
                this.translate.instant('workflow.cubes.create-data-frame.apply-and-run.success'),
              );

              this.form.controls.dataOfFrame.setValue([]);
              this.form.controls.columnMapping.clear();

              this.loadColumnsAndDataFromExcecutionReport();

              break;
            case 'status_failed':
              this.alertService.error(
                this.translate.instant('workflow.cubes.create-data-frame.apply-and-run.error', {
                  error: state.error.message,
                }),
              );
              break;
          }
        });
    }
  }

  getObservableFormControlValue<K extends keyof CreateDataFrameFormGroup>(
    controlName: K,
  ): Observable<ExtractFormValueType<CreateDataFrameFormGroup[K]>> {
    const formControl = this.form.controls[controlName] as FormControl<
      ExtractFormValueType<CreateDataFrameFormGroup[K]>
    >;

    return formControl.valueChanges.pipe(startWith(formControl.value));
  }

  isApplying(): Observable<boolean> {
    return this.applying.asObservable();
  }

  loadColumnsAndDataFromExcecutionReport(): void {
    this.workflowReportDomainService
      .loadExecutionReportForCurrentWorkflow()
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe((report) => {
        const table = extractDataSampleFromExecutionReport(this.nodeId, report);

        if (table) {
          const { columnNames, columnTypes, values } = table;

          columnNames.forEach((columnName, index) => {
            this.form.controls.columnMapping.push(
              new FormGroup<CreateDataFrameColumnMappingFormGroup>({
                id: new FormControl(uuidv4()),
                columnName: new FormControl(columnName, [Validators.required]),
                columnType: new FormControl(
                  this.normalizeRawColumnType(columnTypes[index] as CreateDataColumnApiType),
                  [Validators.required],
                ),
              }),
            );
          });

          // take only first 20 rows, it is requirement of business
          const createData: CreateDataFrameParameters['dataOfFrame'] =
            values?.slice(0, DATA_ROWS_COUNT).map((valueByColumn, rowIndex) => {
              const row: Record<string, string | number | boolean> = {};

              columnNames.forEach((columnName, columnIndex) => {
                row[columnName] = valueByColumn[columnIndex];
              });

              return row;
            }) ?? [];

          this.form.controls.dataOfFrame.setValue(createData);
        }
      });
  }

  private waitUntilStatusChangeTo(statuses: CubeState['status'][]): Observable<CubeState> {
    return this.nodeState$.pipe(
      // skip last status
      skip(1),
      distinctUntilKeyChanged('status'),
      filter((state) => statuses.includes(state.status)),
    );
  }

  private updateNodeParameters(): void {
    const graphNodeUpdateOptions = {
      id: this.nodeId,
      parameters: this.normalizeFormValuesToApiModel(this.form.value as CreateDataFrameParameters),
    };

    this.workflowEditorFacadeService.updateNodeParamterValues(graphNodeUpdateOptions);
  }

  private tryToUpdateDataSource(dataSourceId: string): void {
    const graphNodeUpdateOptions = {
      id: this.nodeId,
      parameters: this.normalizeFormValuesToApiModel({ dataSourceId, columnMapping: null, dataOfFrame: null }),
    };

    this.workflowEditorFacadeService.updateNodeParamterValues(graphNodeUpdateOptions);
  }

  private normalizeRawCreateDataFrameParameters(
    rawParameters: CreateDataFrameRawParameters,
  ): CreateDataFrameParameters {
    let dataOfFrame = [];

    try {
      dataOfFrame = JSON.parse(rawParameters['Data of dataframe']);
    } catch (e) {
      console.error('Failed to parse data of frame', e);
    }

    return {
      dataSourceId: rawParameters['data source'],
      dataOfFrame,
      columnMapping: (rawParameters['Column mappings of dataframe'] || []).map((c) => ({
        id: uuidv4(),
        columnName: c['Column name'],
        columnType:
          c['Column type'] !== null && typeof c['Column type'] === 'object'
            ? (Object.keys(c['Column type'])[0] as CreateDataColumnType)
            : null,
      })),
    };
  }

  private normalizeFormValuesToApiModel(params: CreateDataFrameParameters): CreateDataFrameRawParameters {
    return {
      'data source': params.dataSourceId,
      'Data of dataframe': JSON.stringify(params.dataOfFrame),
      'Column mappings of dataframe': params.columnMapping?.map(({ columnName, columnType }) => ({
        'Column name': columnName,
        'Column type': { [columnType]: {} },
      })),
    };
  }

  private normalizeRawColumnType(columnType: CreateDataColumnApiType): CreateDataColumnType {
    if (columnType === 'numeric') {
      return 'double';
    }

    return columnType as CreateDataColumnType;
  }
}
