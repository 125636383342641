import { DashboardPageRelation } from '@selfai-platform/bi-domain';

export function findChildWidgetIds(
  widgetId: string,
  relations: DashboardPageRelation[],
  isCollect: boolean = false,
): string[] {
  let childIds: string[] = [];

  relations.forEach((item) => {
    if (item.children) {
      if (item.ref === widgetId || isCollect) {
        childIds = item.children.map((child) => child.ref);
        childIds = childIds.concat(findChildWidgetIds(widgetId, item.children, true));
      } else {
        childIds = childIds.concat(findChildWidgetIds(widgetId, item.children, false));
      }
    }
  });

  return childIds;
}
