import { NgModule } from '@angular/core';
import { WorkflowRunnerComponent } from './workflow-runner.component';
import { WorkflowRunnerComponentService } from './workflow-runner-component.service';
import { TabViewModule } from 'primeng/tabview';
import { AsyncPipe, JsonPipe, NgIf, NgTemplateOutlet } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { TableCellSizingDirective } from '@selfai-platform/shared';
import { Button, ButtonDirective } from 'primeng/button';
import { ConditionEditComponentModule } from '../../../../condition-edit';
import { MessagesModule } from 'primeng/messages';
import { Ripple } from 'primeng/ripple';
import { TooltipModule } from 'primeng/tooltip';
import { AdditionalParamEditComponent } from './additional-param-edit/additional-param-edit.component';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { WorkflowPresetsComponent } from '../../../../workflow-presets';
import { ClusterConfigurationFormComponent } from '../../../../workflow-presets/components/cluster-configuration-form';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [WorkflowRunnerComponent],
  exports: [WorkflowRunnerComponent],
  imports: [
    TabViewModule,
    NgTemplateOutlet,
    ReactiveFormsModule,
    CheckboxModule,
    InputTextModule,
    TableModule,
    TableCellSizingDirective,
    ButtonDirective,
    ConditionEditComponentModule,
    MessagesModule,
    Ripple,
    TooltipModule,
    AdditionalParamEditComponent,
    DropdownModule,
    AsyncPipe,
    Button,
    NgIf,
    JsonPipe,
    OverlayPanelModule,
    WorkflowPresetsComponent,
    ClusterConfigurationFormComponent,
    TranslateModule,
  ],
  providers: [
    WorkflowRunnerComponentService,
  ],
})
export class WorkflowRunnerModule {}
