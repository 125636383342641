import { EntityMetadataMap } from '@ngrx/data';

export const KE_DATA_SOURCES_ADDITIONS_PARAM = 'keDataSourceAdditions';

export function SelectId(el: { key: string; type: string }): string {
  return el.key;
}

const entityMetadata: EntityMetadataMap = {
  [KE_DATA_SOURCES_ADDITIONS_PARAM]: {
    entityDispatcherOptions: { optimisticAdd: false, optimisticUpdate: false },
    selectId: SelectId,
  },
};

export const keDataSourceAdditionsEntityConfig = {
  entityMetadata,
};
