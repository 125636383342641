import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { DateValueFieldComponent } from './date-value-field.component';
@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, CalendarModule],
  declarations: [DateValueFieldComponent],
  exports: [DateValueFieldComponent],
})
export class DateValueFieldComponentModule {}
