export type SlotSelector = `[slot='${DataListViewTemplateSlots}']`;

export type SlotSelectors = {
  [key in DataListViewTemplateSlots]: SlotSelector;
};

export const DataListViewTemplateSlots = {
  ToolbarActions: 'toolbarActions',
} as const;

export type DataListViewTemplateSlots = typeof DataListViewTemplateSlots[keyof typeof DataListViewTemplateSlots];
