import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { FileSizePipe } from "@selfai-platform/shared";

import { CommonModule } from '../common/common.module';
import { DataPreviewModule } from '../common/data.preview.module';

import { DatasourceSummaryComponent } from './component/datasource-summary/datasource-summary.component';

@NgModule({
  imports: [CommonModule, TranslateModule, DataPreviewModule, FileSizePipe],
  declarations: [DatasourceSummaryComponent],
  exports: [DatasourceSummaryComponent],
})
export class DatasourceShareModule {}
