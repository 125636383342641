<div [class.ddp-selected]="isOpenOpts" class="ddp-dropdown-filter">
  <div
    (click)="isOpenOpts = !isOpenOpts"
    [ngClass]="{ CHANGE: 'ddp-top-summary', PANEL: 'ddp-none-bar ddp-select-bar' }[mode]"
  >
    {{ timeUnitLabel }}
    <em class="ddp-icon-view"></em>
  </div>

  <div class="ddp-wrap-popup2">
    <div class="ddp-label-type">{{ 'msg.page.ui.formula' | translate }}</div>
    <ul class="ddp-list-popup">
      <li
        *ngFor="let unit of dpContinuousList"
        (click)="selectTimeUnit(false, unit.value.toUpperCase())"
        [class.ddp-selected]="isSelectedContinuous(unit.value)"
      >
        <a href="javascript:">
          {{ unit.name | translate }}
          <em class="ddp-icon-check"></em>
        </a>
      </li>
    </ul>
    <div class="ddp-label-type">{{ 'msg.page.ui.discon.type' | translate }}</div>
    <ul class="ddp-list-popup">
      <li
        *ngFor="let byUnit of dpDiscontinuousList"
        (click)="selectTimeUnit(true, byUnit['unit'], byUnit['byUnit'])"
        [class.ddp-selected]="isSelectedDiscontinuous(byUnit)"
      >
        <a href="javascript:">
          {{ byUnit['name'] | translate }}
          <em class="ddp-icon-check"></em>
        </a>
      </li>
    </ul>
  </div>
</div>
