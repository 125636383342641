import {
  FieldType,
  FormControls,
  FormFieldsValueNode,
  StepApiModel,
  TypeValuesApiModel,
} from '@selfai-platform/pipeline-common';

export function mapApiToField(apiData: StepApiModel): FormFieldsValueNode {
  return Object.fromEntries(
    Object.entries(apiData).map<[string, FormControls]>(([key, field]) => {
      if (Array.isArray(field)) {
        return [
          key,
          field.map((fieldValue: FormControls) => {
            if (isFormFieldTyped(fieldValue)) {
              const [type, value] = Object.entries(fieldValue)[0];

              return { type, value };
            }

            return fieldValue;
          }),
        ];
      }

      if (isFormFieldTyped(field)) {
        const [type, value] = Object.entries(field)[0];

        return [key, { type, value }];
      }

      return [key, field];
    }),
  );
}

function isFormFieldTyped(formFieldValue: unknown | TypeValuesApiModel): formFieldValue is TypeValuesApiModel {
  return (
    formFieldValue instanceof Object &&
    !Array.isArray(formFieldValue) &&
    Object.values(FieldType).includes(Object.keys(formFieldValue)[0] as FieldType)
  );
}
