@if (hasParameters) {
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <selfai-platform-form-field label="{{ 'workflow.cubes.external-sql-query.field-label.column' | translate }}">
      <selfai-platform-edit-selection-field [formControl]="form.controls.column"></selfai-platform-edit-selection-field>
    </selfai-platform-form-field>

    <selfai-platform-form-field label="{{ 'workflow.cubes.external-sql-query.field-label.data-source' | translate }}">
      <selfai-platform-data-source-selector
        [control]="form.controls.dataSourceId"
        class="h-15rem"
      ></selfai-platform-data-source-selector>
    </selfai-platform-form-field>

    <selfai-platform-form-field label="{{ 'workflow.cubes.external-sql-query.field-label.sql-query' | translate }}">
      <selfai-platform-code-editor
        class="p-inputtext"
        language="sparksql"
        [formControl]="form.controls.sqlQuery"
      ></selfai-platform-code-editor>
    </selfai-platform-form-field>
  </form>
} @else {
  <p-message text="{{ 'workflow.cubes.external-sql-query.no-parameters' | translate }}" severity="error"></p-message>
}
