<div class="ddp-wrap-divide" [ngClass]="{ 'ddp-first': firstFl }">
  <div class="ddp-wrap-option-slider">
    <span class="ddp-label-slider">{{ 'msg.page.chart.axis.show.axis.label' | translate }}</span>

    <div class="ddp-checkbox-slide ddp-checkbox-automatic2">
      <input type="checkbox" (click)="showAxisLabel(axis.mode, !axis.showLabel)" [checked]="axis.showLabel" />
      <label><span class="ddp-slide"></span></label>
    </div>
  </div>

  <div *ngIf="axis.showLabel" class="ddp-list-sub2">
    <div class="ddp-divide2">
      <div class="ddp-list-label">{{ 'msg.page.chart.axis.label.rotation' | translate }}</div>

      <div
        class="ddp-type-selectbox"
        (click)="axis.showLabel ? (xAxisRotateFlag = !xAxisRotateFlag) : null"
        (clickOutside)="xAxisRotateFlag = false"
        [excludeBeforeClick]="true"
      >
        <span
          *ngIf="!axis.label || !axis.label['rotation'] || 0 == axis.label['rotation']"
          class="ddp-txt-selectbox"
          translate
          [translateParams]="{ value: 0 }"
        >
          {{ 'msg.page.format.xaxis.rotation' }}
        </span>
        <span
          *ngIf="axis.label && axis.label['rotation'] && 90 == axis.label['rotation']"
          class="ddp-txt-selectbox"
          translate
          [translateParams]="{ value: 90 }"
        >
          {{ 'msg.page.format.xaxis.rotation' }}
        </span>
        <span
          *ngIf="axis.label && 45 == axis.label['rotation']"
          class="ddp-txt-selectbox"
          translate
          [translateParams]="{ value: 45 }"
        >
          {{ 'msg.page.format.xaxis.rotation' }}
        </span>
        <ul class="ddp-list-selectbox ddp-selectdown" style="display: block" *ngIf="xAxisRotateFlag">
          <li (click)="rotateAxisLabel(axis.mode, 0)">
            <a href="javascript:" translate [translateParams]="{ value: 0 }">
              {{ 'msg.page.format.xaxis.rotation' }}
            </a>
          </li>
          <li (click)="rotateAxisLabel(axis.mode, 45)">
            <a href="javascript:" translate [translateParams]="{ value: 45 }">
              {{ 'msg.page.format.xaxis.rotation' }}
            </a>
          </li>
          <li (click)="rotateAxisLabel(axis.mode, 90)">
            <a href="javascript:" translate [translateParams]="{ value: 90 }">
              {{ 'msg.page.format.xaxis.rotation' }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="ddp-divide2">
      <div class="ddp-list-label">{{ 'msg.page.chart.axis.label.max.length' | translate }}</div>
      <input
        type="number"
        input-mask="number"
        (keyup.enter)="setMaxLength()"
        (focusout)="setMaxLength()"
        class="ddp-input-typebasic"
        [(ngModel)]="axis.label['maxLength']"
      />
    </div>
  </div>
</div>

<div class="ddp-wrap-divide">
  <div class="ddp-wrap-option-slider">
    <span class="ddp-label-slider">{{ 'msg.page.xaxis.show.background' | translate }}</span>

    <div class="ddp-checkbox-slide ddp-checkbox-automatic2">
      <input type="checkbox" [checked]="axis['background']" (click)="toggleBackground()" />
      <label><span class="ddp-slide"></span></label>
    </div>
  </div>

  <div *ngIf="axis['background']" class="ddp-list-sub2">
    <div class="ddp-wrap-option-multy ddp-clear">
      <div class="ddp-col-7">
        <span class="ddp-label-txt">{{ 'msg.page.xaxis.background.color' | translate }}</span>
      </div>
      <div class="ddp-col-5 ddp-txt-right">
        <div class="ddp-type-selectbox ddp-color-selectbox2">
          <color-picker
            [showAlpha]="true"
            [color]="axis['background']['color']"
            [showInitial]="true"
            [showInput]="true"
            [showUserColor]="true"
            (selected)="changeBackgroundColor($event)"
          ></color-picker>
        </div>
      </div>
    </div>
    <div class="ddp-wrap-option-multy ddp-clear">
      <div class="ddp-col-5">
        <span class="ddp-label-txt">{{ 'msg.page.xaxis.background.transparency' | translate }}</span>
      </div>
      <div class="ddp-col-7">
        <component-select
          [array]="transparencyList"
          [defaultIndex]="getTransparencyIndex()"
          [viewKey]="'name'"
          (onSelected)="changeTransprency($event)"
        ></component-select>
      </div>
    </div>
  </div>
</div>
