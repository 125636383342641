import { Injectable } from '@angular/core';
import {
  DatasetComponent,
  GridComponent,
  LegendComponentOption,
  TitleComponent,
  TooltipComponent,
  TransformComponent,
} from 'echarts/components';

import { LabelLayout, UniversalTransition } from 'echarts/features';

import { CanvasRenderer } from 'echarts/renderers';

import * as echarts from 'echarts/core';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { Observable, filter, fromEvent, switchMap } from 'rxjs';
import { EChartStore } from '../stores';

@Injectable({
  providedIn: 'root',
})
export class EChartService {
  constructor(private readonly eChartStore: EChartStore) {
    echarts.use([
      TitleComponent,
      TooltipComponent,
      GridComponent,
      DatasetComponent,
      TransformComponent,
      LabelLayout,
      UniversalTransition,
      CanvasRenderer,
    ]);
  }

  initChart(
    widgetId: string,
    chartElement: HTMLElement,
    contextId?: string,
    options?: echarts.EChartsCoreOption,
  ): Observable<echarts.ECharts> {
    const chartInstance = echarts.init(chartElement, 'exntu');

    if (options) {
      chartInstance.setOption(options);
    }

    this.eChartStore.setInstance(widgetId, chartInstance, contextId);

    return this.getInstance(widgetId, contextId);
  }

  getInstance(widgetId: string, contextId?: string): Observable<echarts.ECharts> {
    return this.eChartStore.selectInstance(widgetId, contextId).pipe(filter(Boolean));
  }

  getEventFactory(
    widgetId: string,
    eventName: 'legendselectchanged',
    contextId?: string,
  ): Observable<LegendComponentOption>;

  getEventFactory(widgetId: string, eventName: 'click', contextId?: string): Observable<CallbackDataParams>;

  getEventFactory(widgetId: string, eventName: string, contextId?: string): Observable<unknown>;

  getEventFactory(widgetId: string, eventName: string, contextId?: string): Observable<unknown> {
    return this.getInstance(widgetId, contextId).pipe(
      switchMap((instance) => {
        return fromEvent(
          {
            addEventListener: (_e: string, handler: (params: unknown) => void): void => {
              instance.on(eventName, handler);
            },
            removeEventListener: (_e: string, handler: (params: unknown) => void): void => {
              instance.off(eventName, handler);
            },
          },
          eventName,
        );
      }),
    );
  }
}
