import { Injectable, Renderer2, ViewContainerRef } from '@angular/core';
import { DialogHeaderComponent } from './dialog-header.component';
import { takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DestroyService } from '@selfai-platform/shared';
import { CubeDialogManagementService } from '../../services/cube-dialog-management.service';

@Injectable()
export class DialogHeaderService {
  constructor(
    private readonly translateService: TranslateService,
    private readonly destroy$: DestroyService,
    private readonly cubeDialogManagementService: CubeDialogManagementService,
  ) {}

  public initCustomHeaderComponent(nodeId: string, nodeHeader: string, containerRef: ViewContainerRef, renderer: Renderer2, onCloseCb?: () => void, onSubmitCb?: () => void): void {
    // Init instance of DialogHeaderComponent
    const componentRef = containerRef.createComponent(DialogHeaderComponent);
    componentRef.instance.header = nodeHeader;
    componentRef.instance.nodeId = nodeId;
    componentRef.instance.closeDialog.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (onCloseCb) {
        onCloseCb();
      } else {
        this.cubeDialogManagementService.closeCollapsed(nodeId);
      }
    });
    componentRef.instance.clickSave.pipe(takeUntil(this.destroy$)).subscribe(() => {
      if (onSubmitCb) {
        onSubmitCb();
      }
    });
    componentRef.instance.setFocus.pipe(takeUntil(this.destroy$)).subscribe((nodeId) => {
      this.cubeDialogManagementService.setFocus(nodeId);
    });
    componentRef.instance.minimize.pipe(takeUntil(this.destroy$)).subscribe((nodeId) => {
      this.cubeDialogManagementService.collapse(nodeId);
    });
    // Search rendered PrimeDialog header element
    const primeDialogHeader = document.getElementsByClassName('p-dialog-header')[0];
    // Clear all child elements of PrimeDialog
    while (primeDialogHeader.firstChild) {
      primeDialogHeader.removeChild(primeDialogHeader.lastChild);
    }
    // Connect DialogHeaderComponent to PrimeDialog
    renderer.appendChild(primeDialogHeader, componentRef.location.nativeElement);
    // Drop class for ability to find and append new rendered PrimeDialogs
    primeDialogHeader.classList.remove('p-dialog-header');
  }
}
