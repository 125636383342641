import { UntypedFormGroup } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { AbstractCubeDialogFormComponent } from './abstract-cube-dialog-form.component';

export abstract class AbstractCubeDialogFormWithTableComponent extends AbstractCubeDialogFormComponent {
  itemsMap = new Map<string, UntypedFormGroup>();

  get items(): UntypedFormGroup[] {
    return Array.from(this.itemsMap.values());
  }

  set items(items: UntypedFormGroup[]) {
    const entries = items.map((v) => [v.controls['id'].value, v]) as [string, UntypedFormGroup][];
    this.itemsMap = new Map<string, UntypedFormGroup>(entries);
  }

  removeItem(id: string): void {
    this.itemsMap.delete(id);
  }

  copyItem(id: string): void {
    const data = this.itemsMap.get(id)?.value;
    if (data) {
      const id = uuidv4();
      const item = { ...data, id };
      const form = this.mapItemToFormGroup(item);

      this.itemsMap.set(id, form);
    }
  }

  addItem(): void {
    const id = uuidv4();
    const item = { ...this.initialItem, id };
    const form = this.mapItemToFormGroup(item);

    this.itemsMap.set(id, form);
  }

  abstract mapItemToFormGroup(item: object): UntypedFormGroup;

  protected override validation(): boolean {
    let valid = true;
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      valid = false;
    }

    this.itemsMap.forEach((itemForm) => {
      if (itemForm.invalid) {
        itemForm.markAllAsTouched();
        valid = false;
      }
    });

    return valid;
  }
}
