import { DatasourceField } from '../../../../datasource';
import { TimeFilter, createTimeFilter } from './time-filter.model';

export interface TimeAllFilter extends TimeFilter {
  type: 'time_all';
}

export function createTimeAllFilter(field: DatasourceField): TimeAllFilter {
  return {
    ...createTimeFilter(field),
    type: 'time_all',
  };
}
