<div class="chart-filter-wrapper">
  <div class="chart-filter-wrapper-title">
    <div class="chart-filter-title-content">
      <p-button type="button" class="chart-filter-wrapper-title__a" (click)="openUpdateFilterPopup.emit()">
        {{ 'msg.board.ui.filter.add-edit.filter' | translate }} ({{ chartFilters ? chartFilters.length : '0' }})
      </p-button>
    </div>
  </div>

  <div class="chart-filter-wrapper-content">
    <div class="chart-filter-title-content--composition" *ngIf="boardFilters && boardFilters.length > 0">
      <div class="composition--filter-title">
        <div (click)="isOpenBoardFilterList = !isOpenBoardFilterList" class="composition--filter-title__p">
          {{
            (isOpenBoardFilterList
              ? 'msg.board.filter.title.global-filter-hide'
              : 'msg.board.filter.title.global-filter-show'
            ) | translate
          }}
          ({{ boardFilters ? boardFilters.length : 0 }})
        </div>
        <div *ngIf="isOpenBoardFilterList" class="ddp-dropdown-data" style="display: initial; margin: 0 !important;">
          <div *ngFor="let filter of boardFilters" class="ddp-list-data" style="padding: 10px !important;">
            <div class="ddp-data-name">
              <selfai-bi-shared-field-icon
                *ngIf="filter?.type !== 'bound'"
                [view]="'DIMENSION'"
                [type]="filter?.fieldObj?.type"
                [logicalType]="filter?.fieldObj?.logicalType"
              ></selfai-bi-shared-field-icon>

              <selfai-bi-shared-field-icon
                *ngIf="filter?.type === 'bound'"
                [view]="'MEASURE'"
                [type]="filter?.fieldObj?.type"
                [logicalType]="filter?.fieldObj?.logicalType"
              ></selfai-bi-shared-field-icon>
              {{ filter?.field }}
            </div>
            <div class="ddp-data-det" [innerHTML]="filter['panelContents']"></div>
          </div>
        </div>
      </div>
    </div>

    <div 
      *ngFor="let filter of chartFilters; trackBy: trackByFn" 
      class="chart-filter-title-content--composition" 
      style="padding: 0 10px;">
      <time-filter-panel
        *ngIf="filterUtil.isTimeFilter(filter)"
        [filter]="filter"
        [dashboard]="dashboard"
        [chartFilters]="chartFilters"
        [isDashboardMode]="false"
        (changeGlobalToChartEvent)="changeGlobalToChartEvent.emit($event)"
        (openUpdateFilterPopupEvent)="openUpdateFilterPopup.emit($event)"
        (updateFilterEvent)="updateFilterEvent.emit($event)"
        (deleteFilterEvent)="deleteFilterEvent.emit($event)"
      ></time-filter-panel>
      <inclusion-filter-panel
        *ngIf="filter?.type === 'include'"
        [filter]="filter"
        [dashboard]="dashboard"
        [chartFilters]="chartFilters"
        [isDashboardMode]="false"
        (changeGlobalToChartEvent)="changeGlobalToChartEvent.emit($event)"
        (openUpdateFilterPopupEvent)="openUpdateFilterPopup.emit($event)"
        (updateFilterEvent)="updateFilterEvent.emit($event)"
        (deleteFilterEvent)="deleteFilterEvent.emit($event)"
      ></inclusion-filter-panel>
      <bound-filter-panel
        *ngIf="filter?.type === 'bound'"
        [filter]="filter"
        [dashboard]="dashboard"
        [chartFilters]="chartFilters"
        [isDashboardMode]="false"
        (changeGlobalToChartEvent)="changeGlobalToChartEvent.emit($event)"
        (openUpdateFilterPopupEvent)="openUpdateFilterPopup.emit($event)"
        (updateFilterEvent)="updateFilterEvent.emit($event)"
        (deleteFilterEvent)="deleteFilterEvent.emit($event)"
      ></bound-filter-panel>
    </div>
  </div>
</div>
