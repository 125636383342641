import { GranularityType } from '../../../../datasource';
import { ByTimeUnit, TimeUnit } from '../field';
import { Filter, createFilter } from './filter.model';

export interface IntervalFilter extends Filter {
  selector: IntervalSelectorType;

  intervals?: string[];

  startDate?: string;

  endDate?: string;

  rrule?: string;

  relInputType?: IntervalRelativeInputType;

  timeType?: IntervalRelativeTimeType;

  timeUnit?: TimeUnit;
  byTimeUnit?: ByTimeUnit;

  relValue?: number;

  format?: string;

  locale?: any;

  valueList?: any[];

  candidateValues?: any[];

  discontinuous?: boolean;

  granularity?: GranularityType;

  maxTime?: Date;
  minTime?: Date;
  timeUnitUI?: any;
  intervalsUI?: any;
  isCurrentTime: boolean;
}

export function createIntervalFilter(field: string, params: Partial<IntervalFilter> = {}): IntervalFilter {
  return {
    ...createFilter(),
    field: field,
    type: 'interval',
    selector: IntervalSelectorType.RANGE,
    isCurrentTime: false,
    ...params,
  } as IntervalFilter;
}

export enum IntervalSelectorType {
  ALL = 'ALL',
  RANGE = 'RANGE',
  RELATIVE = 'RELATIVE',
}

export enum IntervalTimeUnit {
  YEARS = 'YEARS',
  QUARTERS = 'QUARTERS',
  MONTHS = 'MONTHS',
  WEEKS = 'WEEKS',
  DAYS = 'DAYS',
  HOURS = 'HOURS',
  MINUTES = 'MINUTES',
  SECONDS = 'SECONDS',
}

export enum IntervalRelativeInputType {
  BUTTON = 'BUTTON',
  INPUT = 'INPUT',
}

export enum IntervalRelativeTimeType {
  LAST = 'LAST',
  CURRENT = 'CURRENT',
  NEXT = 'NEXT',
}
