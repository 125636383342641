import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, debounceTime, distinctUntilChanged, skip, tap } from 'rxjs';
import { UrlPageParamsService } from './url-page-params.service';

@Injectable()
export class SearchQueryService implements OnDestroy {
  readonly querySearch$ = new BehaviorSubject<string>('');
  // we don't use destroy service 'cause in service we can't provide destroy service. Subscription more safer for services
  private readonly subscription = new Subscription();

  constructor(private readonly urlPageParamsService: UrlPageParamsService) {
    this.subscription.add(
      urlPageParamsService
        .getPageParams()
        .pipe(distinctUntilChanged((a, b) => a?.query === b?.query))
        .subscribe((pageParams) => {
          this.querySearch$.next(pageParams?.query || '');
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  listenQuerySearchWithDebounce(time: number): Observable<string> {
    return this.querySearch$.pipe(
      skip(1),
      debounceTime(time),
      tap((query) => {
        this.urlPageParamsService.setPageParams({ query });
      }),
    );
  }

  setQuerySearch(query: string): void {
    this.querySearch$.next(query);
  }
}
