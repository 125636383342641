import { Injectable } from '@angular/core';
import { AlertService } from '../../alert/alert.service';
import { ConfigService } from '../config.service';
import { PipelineConfig } from './pipeline-config.model';
import { PIPELINE_DEFAULT_CONFIG } from './pipeline-defualt-config.const';
import { cloneDeep, merge } from 'lodash';

@Injectable()
export class PipelineConfigService {
  constructor(private readonly configService: ConfigService, private readonly alertService: AlertService) {}

  configLoaded$ = this.configService.configLoaded$;

  public getConfig(): PipelineConfig {
    try {
      const defaultConfig = cloneDeep(PIPELINE_DEFAULT_CONFIG);
      const currentPipelineConfig = this.configService.getConfig().pipeline;

      return merge(defaultConfig, currentPipelineConfig);
    } catch (e: unknown) {
      this.alertService.error('Configuration for Pipeline is not available. See config.json section pipeline');
      throw e;
    }
  }
}
