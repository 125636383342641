<textarea [rows]="5" [cols]="50" pInputTextarea [autoResize]="true" [(ngModel)]="comment"></textarea>
<div class="mt-3">
  <button
    type="button"
    class="mr-3 p-button-success"
    (click)="onClickSave()"
    (keydown.enter)="onClickSave()"
    tabindex="-1"
    pRipple
    pButton
    label="Save"
    icon="pi pi-save"
    [disabled]="showLoader$ | async"
  ></button>
  <i class="pi pi-spin pi-spinner" style="font-size: 2rem" *ngIf="showLoader$ | async"></i>
</div>
