import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { CommonModule } from '../common/common.module';

import { WorkspaceListComponent } from './component/management/workspace-list.component';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [WorkspaceListComponent],
  exports: [WorkspaceListComponent],
})
export class WorkspaceShareModule {}
