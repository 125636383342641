import { EntityMetadataMap } from '@ngrx/data';

export const KE_DATA_SOURCES_LIBRARY_ENTITY_NAME = 'keDataSourceLibrary';

const entityMetadata: EntityMetadataMap = {
  [KE_DATA_SOURCES_LIBRARY_ENTITY_NAME]: {
    entityDispatcherOptions: { optimisticAdd: false, optimisticUpdate: false },
    selectId: (el: { name: string }): string => {
      return el.name;
    },
  },
};

export const keDataSourceLibraryEntityConfig = {
  entityMetadata,
};
