import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { CommonModule } from '../../../common/common.module';

import { AnalysisComponent } from './analysis.component';
import { AnalysisClusterComponent } from './cluster/analysis-cluster.component';
import { ColorPickerLayerComponent } from './color.picker/color.picker.layer.component';
import { MapSpatialComponent } from './map-spatial/map-spatial.component';
import { AnalysisPredictionModule } from './prediction/analysis-prediction.module';
import { RangeSliderComponent } from './slider/range-slider.component';
import { TrendLineModule } from './trend.line/trend.line.module';

@NgModule({
  imports: [CommonModule, AnalysisPredictionModule, TranslateModule, TrendLineModule],
  declarations: [AnalysisComponent, AnalysisClusterComponent, MapSpatialComponent],
  exports: [AnalysisComponent, ColorPickerLayerComponent, RangeSliderComponent, MapSpatialComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AnalysisModule {}
