import { isNil } from 'lodash';
import { FieldRole, Logical, LogicalType } from '../enums';
import { DatasourceField, FieldFormat } from '../models';
import { PivotField } from '../../workbook';

export function removeUIproperties(field: DatasourceField) {
  delete field.isShowTypeList;
  delete field.isValidType;
  // delete field.useFilter;
  // delete field.useChartFilter;
  // delete field.useChart;
}

export function removeIngestionRule(field: DatasourceField) {
  delete field.ingestionRule;
}

export function setLogicalType(field: DatasourceField, type: LogicalType) {
  field.logicalType = type;
}

export function removeNameValidProperty(field: DatasourceField) {
  delete field.isInvalidName;
  delete field.invalidNameMessage;
}

export function removeOriginalNameProperty(field: DatasourceField) {
  delete field.originalName;
}

export function setRemoveField(field: DatasourceField): void {
  field.unloaded = true;
}

export function setUndoRemoveField(field: DatasourceField): void {
  field.unloaded = false;
}

export function setCheckField(field: DatasourceField): void {
  field.checked = true;
}

export function setUndoCheckField(field: DatasourceField): void {
  field.checked = false;
}

export function isEnableFieldEditNameLength(field: DatasourceField): boolean {
  const editName = field.editName || '';

  return editName.length < 50;
}

export function isEmptyFieldEditName(field: DatasourceField): boolean {
  const param = field.editName;

  return param === undefined || param === null || (typeof param !== 'number' && param.trim() === '');
}

export function isNameEqualEditName(field: DatasourceField): boolean {
  return field.name === field.editName?.trim();
}

export function isDisableFieldEditNameCharacter(field: DatasourceField): boolean {
  const editName = field.editName || '';

  return /^[!@#$%^*+=()~`{}[]-_;:'",\.\/\?<>\|&\\]+$/gi.test(editName) || /[\s\r\n]/gi.test(editName);
}

export function isDisableOriginalName(field: DatasourceField): boolean {
  return isCreatedField(field) || field.name === field.originalName;
}

export function isTimestampTypeField(field: DatasourceField): boolean {
  return field.logicalType === LogicalType.TIMESTAMP;
}

export function isDimensionField(field: DatasourceField): boolean {
  return field.role === FieldRole.DIMENSION;
}

export function isMeasureField(field: DatasourceField): boolean {
  return field.role === FieldRole.MEASURE;
}

export function isTimestampField(field: DatasourceField): boolean {
  return field.role === FieldRole.TIMESTAMP;
}

export function isCheckedField(field: DatasourceField): boolean {
  return field.checked === true;
}

export function isRemovedField(field: DatasourceField): boolean {
  return field.unloaded === true;
}

export function isCreatedField(field: DatasourceField): boolean {
  return field.derived === true;
}

export function isGeoType(field: DatasourceField): boolean {
  return (
    field.logicalType === LogicalType.GEO_LINE ||
    field.logicalType === LogicalType.GEO_POINT ||
    field.logicalType === LogicalType.GEO_POLYGON
  );
}

export function isStringBaseType(field: DatasourceField): boolean {
  return field.type === Logical.STRING;
}

export function isExpressionField(field: DatasourceField): boolean {
  return isCreatedField(field) && field.logicalType === LogicalType.STRING;
}

export function isEmptyFormat(field: { format?: FieldFormat }): boolean {
  return isNil(field.format);
}

export function isEmptyIngestionRule(field: DatasourceField): boolean {
  return isNil(field.ingestionRule);
}

export function getSlicedColumnName(field: DatasourceField): string {
  const columnName: string = field.name || '';
  if (columnName.length > 50) {
    return columnName.slice(0, 50);
  }

  return columnName;
}

export function getFieldName(field: DatasourceField, alias?: string): string {
  if (!field) {
    return alias || '';
  }
  const fieldName = alias || field.alias || field.name || '';
  if (field.join) {
    return `${fieldName} (${field.join.joinAlias})`;
  }

  return fieldName as string;
}
