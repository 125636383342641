import { Injectable } from '@angular/core';
import { IDatasourcePermissionService } from '@selfai-platform/pipeline-common';
import { PipelinePermission, PermissionService, UserProfileService } from '@selfai-platform/shared';

@Injectable({
  providedIn: 'root',
})
/**
 * @deprecated Use for legacy part of ke (pipeline-angularjs library)
 */
export class LegacyDatasourcePermissionService implements IDatasourcePermissionService {
  constructor(
    private readonly permissionService: PermissionService,
    private readonly userProfileService: UserProfileService,
  ) {}

  getDatasourcePermissions(): PipelinePermission[] {
    return this.permissionService.getPipelinePermissions().filter((t) => t.startsWith('datasources'));
  }

  equalCurrentUser(userId: string, pipelineUserId: string) {
    return pipelineUserId === userId;
  }

  hasCurrentPermission(permission: PipelinePermission) {
    return this.getDatasourcePermissions().includes(permission);
  }

  canDeleteSource(sourceOwnerId: string, pipelineUserId: string) {
    if (this.hasCurrentPermission(PipelinePermission.DatasourcesDeleteAny)) {
      return true;
    }

    return this.equalCurrentUser(sourceOwnerId, pipelineUserId) && this.hasCurrentPermission(PipelinePermission.DatasourcesDeleteOwn);
  }

  canUpdateSource(sourceOwnerId: string, pipelineUserId: string) {
    if (this.hasCurrentPermission(PipelinePermission.DatasourcesUpdateAny)) {
      return true;
    }

    return this.equalCurrentUser(sourceOwnerId, pipelineUserId) && this.hasCurrentPermission(PipelinePermission.DatasourcesUpdateOwn);
  }

  canCreateSource() {
    return this.hasCurrentPermission(PipelinePermission.DatasourcesCreate);
  }
}
