export function mapPaginationPrimeToBi(paginationPrime: { first?: number; rows?: number }): {
  pageSize: number;
  pageNumber: number;
} {
  return {
    pageNumber: Math.round(paginationPrime.first / paginationPrime.rows) + 1,
    pageSize: paginationPrime.rows,
  };
}

export function mapPaginationBiToPrime(paginationBi: { pageSize: number; pageNumber: number }): {
  first: number;
  rows: number;
} {
  return {
    first: (paginationBi.pageNumber - 1) * paginationBi.pageSize,
    rows: paginationBi.pageSize,
  };
}
