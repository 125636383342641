import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';

import { StringUtil } from '../../util/string.util';
import { AbstractComponent } from '../abstract.component';

@Component({
  selector: 'component-paging-search-select',
  templateUrl: './paging-search-select.component.html',
  host: {
    '(document:click)': 'onClickHost($event)',
  },
})
export class PagingSearchSelectComponent extends AbstractComponent implements OnInit {
  private array: any[];

  private selectedItem: any;

  private defaultIndex = -1;

  @Input()
  public isFirstOpenItemList = false;

  @Input()
  public isConnection = false;

  @Input()
  public isSearchOptions = false;

  @Input()
  public isEnableObjectKey = false;

  @Input()
  public isDisabledOptions = false;

  @Input()
  public isEnableInternalPaging = false;

  @Input()
  public isOptionToLeft = false;

  @Input()
  public isUpSelect = false;

  public isShowOptions = false;

  @Input()
  public searchText: string;

  @Input()
  public searchPlaceHolderText: string;

  @Input()
  public usePlaceholder = false;

  @Input()
  public unselectedMessage = this.translateService.instant('msg.comm.ui.please.select');

  @Input()
  public objectKey: string;

  @Input()
  public pageNum = 0;

  @Input()
  public internalSize = 20;

  @Input('array')
  set setArray(array: any) {
    this.array = array;

    this.setSelectedItem();
  }

  @Input('defaultIndex')
  set setDefaultIndex(index: number) {
    this.defaultIndex = index;

    this.setSelectedItem();
  }

  @Output() public onSelected = new EventEmitter();

  @Output() public onSearchText = new EventEmitter();

  @Output() public onLoadPage = new EventEmitter<number>();

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public ngAfterViewInit() {
    if (this.isFirstOpenItemList && !this.selectedItem) {
      setTimeout(() => {
        this.isShowOptions = true;
      });
    }
  }

  public get getArrayList() {
    let arrayList = this.array;

    if (this.isSearchOptions) {
      if (StringUtil.isNotEmpty(this.searchText)) {
        arrayList = this.array.filter((item) => item.toUpperCase().includes(this.searchText.toUpperCase()));
      }
    }

    if (this.isEnableInternalPaging) {
      arrayList = arrayList.slice(0, (this.pageNum + 1) * this.internalSize);
    }

    return arrayList;
  }

  public get getSelectedItem() {
    if (this.selectedItem) {
      if (this.isEnableObjectKey) {
        return this.selectedItem[this.objectKey];
      }
      return this.selectedItem;
    }
    return this.unselectedMessage;
  }

  public getItem(item) {
    if (this.isEnableObjectKey) {
      return item[this.objectKey];
    }
    return item;
  }

  public get getResultOption() {
    if (this.isSearchOptions && this.selectedItem) {
      return true;
    }
    return false;
  }

  public onSelectItem(item: any) {
    this.selectedItem = item;

    this.onSelected.emit(item);
  }

  public onSearchItem() {
    this.onSearchText.emit(this.searchText);
  }

  public onClickHost(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isShowOptions = false;
    }
  }

  public onOffShowOptions() {
    if (!this.isDisabledOptions) {
      this.isShowOptions = !this.isShowOptions;
    }
  }

  public onScroll() {
    if (this.isEnableInternalPaging && this.getMoreInternalPage) {
      this.pageNum++;
    } else {
      this.onLoadPage.emit(++this.pageNum);
    }
  }

  private get getMoreInternalPage(): boolean {
    if (this.array.length > (this.pageNum + 1) * this.internalSize) {
      return true;
    }
    return false;
  }

  private setSelectedItem() {
    if (this.array?.length > 0) {
      if (this.defaultIndex > -1) {
        this.selectedItem = this.array[this.defaultIndex];
      } else {
        this.selectedItem = this.array[0];
      }
    }
  }
}
