<div class="user-functions-import-dialog">
  <div class="mb-2 flex align-items-center">
    <label for="scopes" class="mr-2 block w-2">{{ 'user-functions.import-dialog.scopes.label' | translate }}</label>
    <p-treeSelect
      id="scopes"
      class="w-full"
      containerStyleClass="w-full"
      display="chip"
      selectionMode="checkbox"
      placeholder="{{ 'user-functions.import-dialog.scopes.placeholder' | translate }}"
      [(ngModel)]="selectedScopes"
      [showClear]="true"
      [metaKeySelection]="false"
      [filter]="true"
      [filterInputAutoFocus]="true"
      [options]="scopeList | async"
    />
  </div>

  <div class="mt-3 mb-3">
    <p-checkbox
      label="{{ 'user-functions.import-dialog.user-functions-overriding' | translate }}"
      [binary]="true"
      [(ngModel)]="overrideUserFunctions"
    ></p-checkbox>
  </div>

  <p-fileUpload
    #fileUpload
    name="functions"
    accept="application/json"
    [customUpload]="true"
    (uploadHandler)="importUserFunction($event)"
    (onError)="onError()"
    (onClear)="clearStatuses()"
  >
    <ng-template let-file pTemplate="file">
      <div class="p-fileupload-row">
        <div class="p-fileupload-filename">{{ file.name }}</div>
        <div>{{ fileUpload.formatSize(file.size) }}</div>
      </div>
    </ng-template>

    <ng-template pTemplate="content">
      <ng-container>
        <p-messages *ngFor="let item of uploadStatuses$ | async" [severity]="item.success ? 'success' : 'error'">
          <ng-template pTemplate>
            <div>
              <p *ngIf="item.success; else errorMsg">
                <i class="pi pi-check mr-2"></i>
                <span class="font-bold">{{ item.id }}</span>
              </p>
              <ng-template #errorMsg>
                <p>
                  <i class="pi pi-times-circle mr-2"></i>
                  <span class="font-bold">{{ item.reason }}</span>
                </p>
              </ng-template>
            </div>
          </ng-template>
        </p-messages>
      </ng-container>
    </ng-template>
  </p-fileUpload>
</div>
