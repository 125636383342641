<div *ngIf="'value' == axis?.label?.type.toString()" class="ddp-wrap-divide">
  <div class="ddp-wrap-option-slider">
    <span class="ddp-label-slider">{{ 'msg.page.chart.axis.show.axis.label' | translate }}</span>

    <div *ngIf="axis.mode.toString() != 'sub_column'" class="ddp-checkbox-slide ddp-checkbox-automatic2">
      <input type="checkbox" (click)="showAxisLabel(axis.mode, !axis.showLabel)" [checked]="axis.showLabel" />
      <label><span class="ddp-slide"></span></label>
    </div>
  </div>

  <div *ngIf="axis.showLabel">
    <div class="ddp-wrap-option-slider">
      <span class="ddp-label-slider">{{ 'msg.page.yaxis.label.setting' | translate }}</span>

      <div class="ddp-checkbox-slide ddp-checkbox-automatic3 ddp-en ddp-change">
        <input type="checkbox" (click)="showLabel()" [ngModel]="axis['label']['format']" />
        <label><span class="ddp-slide"></span></label>
      </div>
    </div>

    <div
      format-item
      *ngIf="axis?.label['format']"
      #commonFormat
      (changeFormat)="onChange($event)"
      [format]="axis?.label['format']"
    ></div>
  </div>
</div>

<div *ngIf="possibleChartCheck('yAxisGrid', uiOption.type)" class="ddp-wrap-divide">
  <div class="ddp-wrap-option-slider">
    <span class="ddp-label-slider" style="width: 100px; display: block">
      {{ 'msg.page.yaxis.grid.setting' | translate }}
    </span>

    <div class="ddp-checkbox-slide ddp-checkbox-automatic2" [class.ddp-disabled]="undefined != axisTemp.baseline">
      <input type="checkbox" (click)="$event.stopPropagation(); changeGrid()" [ngModel]="axis.grid" />
      <label><span class="ddp-slide"></span></label>
    </div>
  </div>

  <div *ngIf="axis.grid" class="ddp-list-sub2">
    <ng-container *ngIf="possibleChartCheck('yAxisMinMax', uiOption.type)">
      <div class="ddp-wrap-option-multy ddp-clear">
        <div class="ddp-col-5">
          <span class="ddp-label-txt">{{ 'msg.page.yaxis.grid.max' | translate }}</span>
        </div>
        <div class="ddp-col-7">
          <input
            [(ngModel)]="axisTemp.grid.max"
            (keyup.enter)="changeMax()"
            (focusout)="changeMax()"
            type="text"
            class="ddp-input-typebasic"
            [attr.placeholder]="
              getDecimalRoundNumber(
                AxisOptionConverter.axisMinMax[
                  axis.mode == 'sub_column' ? 'subAxis' : axis.mode == 'row' ? 'xAxis' : 'yAxis'
                ].max
              )
            "
            [disabled]="axis?.grid?.autoScaled"
          />
        </div>
      </div>
      <div class="ddp-wrap-option-multy ddp-clear">
        <div class="ddp-col-5">
          <span class="ddp-label-txt">{{ 'msg.page.yaxis.grid.min' | translate }}</span>
        </div>
        <div class="ddp-col-7">
          <input
            [(ngModel)]="axisTemp.grid.min"
            (keyup.enter)="changeMin()"
            (focusout)="changeMin()"
            type="text"
            class="ddp-input-typebasic"
            [attr.placeholder]="
              getDecimalRoundNumber(
                AxisOptionConverter.axisMinMax[
                  axis.mode == 'sub_column' ? 'subAxis' : axis.mode == 'row' ? 'xAxis' : 'yAxis'
                ].min
              )
            "
            [disabled]="axis?.grid?.autoScaled"
          />
        </div>
      </div>
    </ng-container>
    <div class="ddp-divide2">
      <div class="ddp-part-check">
        <label (click)="$event.preventDefault(); changeAutoScale()" class="ddp-label-checkbox ddp-position">
          <input type="checkbox" [ngModel]="axis.grid.autoScaled" />
          <i class="ddp-icon-checkbox"></i>
          <span class="ddp-txt-checkbox">{{ 'msg.page.yaxis.grid.auto' | translate }}</span>
        </label>
      </div>
    </div>
  </div>
</div>
<form [formGroup]="form">
  <div *ngIf="possibleChartCheck('yAxisLog', uiOption.type) && uiOption.align === 'VERTICAL'" class="ddp-wrap-divide">
    <bi-shared-settings-switch
      formControlName="type"
      label="{{ 'msg.page.yaxis.log.label' | translate }}"
      [value]="AxisType.LOG"
    ></bi-shared-settings-switch>

    <ng-container *ngIf="form.controls.type.value === AxisType.LOG">
      <div class="ddp-list-label">{{ 'msg.page.th.log.logBase' | translate }}</div>
      <bi-shared-settings-number-counter
        formControlName="logBase"
        [min]="2"
        [step]="1"
      ></bi-shared-settings-number-counter>
    </ng-container>
  </div>
</form>

<div
  *ngIf="possibleChartCheck('yAxisBaseline', uiOption.type) && axis.mode.toString() != 'sub_column'"
  class="ddp-wrap-divide"
>
  <div class="ddp-wrap-option-slider">
    <span class="ddp-label-slider">{{ 'msg.page.yaxis.baseline' | translate }}</span>

    <div class="ddp-checkbox-slide ddp-checkbox-automatic2" [class.ddp-disabled]="axis.grid">
      <input type="checkbox" (click)="showBaseLine()" [ngModel]="undefined != axisTemp.baseline" />
      <label><span class="ddp-slide"></span></label>
    </div>
  </div>

  <input
    *ngIf="axisTemp.baseline != undefined"
    [(ngModel)]="axisTemp.baseline"
    (keyup.enter)="changeBaseLine()"
    (focusout)="changeBaseLine()"
    type="text"
    class="ddp-input-typebasic"
    placeholder="0"
  />
</div>

<div *ngIf="false" class="ddp-wrap-divide">
  <div class="ddp-wrap-option-slider">
    <span class="ddp-label-slider">{{ 'msg.page.yaxis.show.grid.lines' | translate }}</span>

    <div class="ddp-checkbox-slide ddp-checkbox-automatic2">
      <input type="checkbox" value="None" id="check2" name="check" />
      <label for="check2"><span class="ddp-slide"></span></label>
    </div>
  </div>

  <div class="ddp-list-sub2">
    <div class="ddp-wrap-option-multy ddp-clear">
      <div class="ddp-col-7">
        <span class="ddp-label-txt">{{ 'msg.page.yaxis.show.grid.lines.color' | translate }}</span>
      </div>
      <div class="ddp-col-5 ddp-txt-right">
        <div class="ddp-type-selectbox ddp-color-selectbox2">
          <color-picker (selected)="changeGridLineColor($event)"></color-picker>
        </div>
      </div>
    </div>
    <div class="ddp-wrap-option-multy ddp-clear">
      <div class="ddp-col-5">
        <span class="ddp-label-txt">{{ 'msg.page.yaxis.show.grid.lines.thickness' | translate }}</span>
      </div>
      <div class="ddp-col-7">
        <component-select
          #bodyHalign
          [array]="lineThickList"
          [defaultIndex]="0"
          [viewKey]="'name'"
          (onSelected)="changeLineThick()"
        ></component-select>
      </div>
    </div>
    <div class="ddp-wrap-option-multy ddp-clear">
      <div class="ddp-col-5">
        <span class="ddp-label-txt">{{ 'msg.page.yaxis.show.grid.lines.type' | translate }}</span>
      </div>
      <div class="ddp-col-7">
        <component-select
          #bodyHalign
          [array]="lineTypeList"
          [defaultIndex]="0"
          [viewKey]="'name'"
          (onSelected)="changeLineType()"
        ></component-select>
      </div>
    </div>
    <div class="ddp-wrap-option-multy ddp-clear">
      <div class="ddp-col-5">
        <span class="ddp-label-txt">{{ 'msg.page.yaxis.show.grid.lines.split' | translate }}</span>
      </div>
      <div class="ddp-col-7">
        <bi-shared-settings-number-counter
          [min]="1"
          [max]="9"
          [step]="1"
          [value]="1"
        ></bi-shared-settings-number-counter>
      </div>
    </div>
    <div class="ddp-divide2">
      <span class="ddp-txt-message">{{ 'msg.page.yaxis.show.grid.lines.split.description' | translate }}</span>
    </div>
  </div>
</div>
