<p-card class="card-{{ deepLevel % 2 === 0 ? 'even' : 'odd' }}" styleClass="mb-2" [formGroup]="form">
  <ng-container *ngFor="let field of fields">
    <ng-container [ngSwitch]="field.type">
      <ng-container *ngSwitchCase="operationType.CODE_SNIPPET">
        <ng-container *ngIf="field.language">
          <selfai-platform-form-field [label]="field.name">
            <selfai-platform-code-editor
              [language]="field.language.name === 'sql' ? 'sparksql' : field.language.name"
              [fields]="fieldsFromExecutionReport$ | async"
              [canMaximized]="true"
              [formControlName]="field.name"
            ></selfai-platform-code-editor>
          </selfai-platform-form-field>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="operationType.CHOICE">
        <ng-container *ngIf="field.values && field.values.length">
          <selfai-platform-form-field
            *ngIf="getChoiceFormGroup(field.name) as formGroup"
            [formGroup]="formGroup"
            [label]="field.name"
          >
            <p-dropdown
              styleClass="mb-3"
              formControlName="choiceValue"
              [options]="mapChoiceItems(field.values)"
            ></p-dropdown>

            <ng-container *ngFor="let value of field.values">
              <ng-container *ngIf="value.name === formGroup.get('choiceValue')?.value">
                <ng-container *ngIf="formGroup.get('fields') as fieldsFormGroup">
                  <selfai-platform-cms-form
                    [fields]="value.schema"
                    [form]="getFormGroupFields(fieldsFormGroup)"
                    [deepLevel]="deepLevel + 1"
                  ></selfai-platform-cms-form>
                </ng-container>
              </ng-container>
            </ng-container>
          </selfai-platform-form-field>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="operationType.NUMERIC">
        <selfai-platform-form-field [label]="field.name">
          <input type="number" pInputText [formControlName]="field.name" />
        </selfai-platform-form-field>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <selfai-platform-form-field [label]="field.name">
          <input type="text" pInputText [formControlName]="field.name" />
        </selfai-platform-form-field>
      </ng-container>
    </ng-container>
  </ng-container>
</p-card>
