/* eslint-disable max-lines */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, ElementRef, Injector } from '@angular/core';
import {
  BaseOption,
  ChartColorList,
  ChartSelectMode,
  ChartType,
  GraphLayoutType,
  Pivot,
  PivotField,
  Position,
  SeriesType,
  ShelveFieldType,
  ShelveType,
  SymbolType,
  TriggerType,
  UIChartColorByDimension,
  UIChartFormat,
  UIOption,
  UiChartDataLabelDisplayType,
  createChartSelectInfo,
  createPivotTableInfo,
} from '@selfai-platform/bi-domain';

import { DestroyService } from '@selfai-platform/shared';
import { GraphSeriesOption, LegendComponentOption } from 'echarts';
import * as _ from 'lodash';
import { take } from 'rxjs';
import {
  ColorOptionConverter,
  FormatOptionConverter,
  LabelOptionConverter,
  LegendOptionConverter,
} from '../../converters';
import { ChartDataService, EChartService, GraphChartDataService, provideBaseChartServices } from '../../services';
import { OptionGenerator } from '../../utils';
import { BaseChart } from '../base-chart';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'network-chart',
  template: '',
  styleUrls: ['./chart-host.component.scss'],
  providers: [
    ...provideBaseChartServices(),
    {
      provide: ChartDataService,
      useExisting: GraphChartDataService,
    },
  ],
})
export class GraphChartLegacyComponent extends BaseChart {
  constructor(elementRef: ElementRef, destroy$: DestroyService, eChartService: EChartService, injector: Injector) {
    super(elementRef, destroy$, eChartService, injector);
  }

  public override draw(isKeepRange?: boolean): void {
    if (!this.isValid(this.pivot)) {
      this.noData.emit();

      return;
    }

    this.data.nodes.forEach((node) => {
      node.name = node.name || 'null';
      node.originalName = node.originalName || 'null';
    });

    this.chartOption = this.initOption();

    this.chartOption = this.convertSeries();

    this.chartOption = this.convertLegend();

    this.chartOption = this.convertEtc();

    this.apply();

    this.drawFinish();

    if (!this.isPage) {
      this.selection();
    }
  }

  public override isValid(pivot: Pivot): boolean {
    const result =
      this.getFieldTypeCount(pivot, ShelveType.COLUMNS, ShelveFieldType.DIMENSION) == 1 &&
      this.getFieldTypeCount(pivot, ShelveType.COLUMNS, ShelveFieldType.TIMESTAMP) == 0 &&
      this.getFieldTypeCount(pivot, ShelveType.ROWS, ShelveFieldType.DIMENSION) == 1 &&
      this.getFieldTypeCount(pivot, ShelveType.ROWS, ShelveFieldType.TIMESTAMP) == 0 &&
      this.getFieldTypeCount(pivot, ShelveType.AGGREGATIONS, ShelveFieldType.MEASURE) +
        this.getFieldTypeCount(pivot, ShelveType.AGGREGATIONS, ShelveFieldType.CALCULATED) ==
        1 &&
      this.getFieldTypeCount(pivot, ShelveType.COLUMNS, ShelveFieldType.MEASURE) == 0 &&
      this.getFieldTypeCount(pivot, ShelveType.COLUMNS, ShelveFieldType.CALCULATED) == 0 &&
      this.getFieldTypeCount(pivot, ShelveType.ROWS, ShelveFieldType.MEASURE) == 0 &&
      this.getFieldTypeCount(pivot, ShelveType.ROWS, ShelveFieldType.CALCULATED) == 0 &&
      this.getFieldTypeCount(pivot, ShelveType.AGGREGATIONS, ShelveFieldType.DIMENSION) == 0 &&
      this.getFieldTypeCount(pivot, ShelveType.AGGREGATIONS, ShelveFieldType.TIMESTAMP) == 0;

    return result;
  }

  public override addLegendSelectEventListener(): void {
    this.chart.off('legendselectchanged');

    this.chart.on('legendselectchanged', (params: LegendComponentOption) => {
      const legend = this.chartOption.legend as LegendComponentOption;
      legend.selected = params.selected;

      this.apply(false);
    });
  }

  protected override initOption(): BaseOption {
    return {
      type: ChartType.GRAPH,
      legend: OptionGenerator.Legend.custom(false, false, Position.LEFT, SymbolType.CIRCLE, '100%', 20),
      tooltip: OptionGenerator.Tooltip.itemTooltip(),
      series: [],
    };
  }

  protected override setPivotInfo(): void {}

  protected override convertSeries(): BaseOption {
    this.chartOption = this.convertSeriesData();

    this.chartOption = ColorOptionConverter.convertColor(
      this.chartOption,
      this.uiOption,
      this.fieldOriginInfo,
      this.fieldInfo,
      this.pivotInfo,
      this.drawByType,
    );

    this.chartOption = FormatOptionConverter.convertFormatSeries(this.chartOption, this.uiOption, this.pivot);

    if (!this.uiOption.dataLabel) {
      this.uiOption.dataLabel = { showValue: true };
    }
    if (_.eq(typeof this.uiOption.dataLabel.showValue, 'undefined')) {
      this.uiOption.dataLabel.showValue = true;
    }

    this.chartOption = LabelOptionConverter.convertLabel(this.chartOption, this.uiOption);

    this.chartOption = this.additionalSeries();

    return this.chartOption;
  }

  protected override additionalSeries(): BaseOption {
    if (!this.uiOption.dataLabel || !this.uiOption.dataLabel.displayTypes) return this.chartOption;

    let showFl: boolean;

    if (-1 !== this.uiOption.dataLabel.displayTypes.indexOf(UiChartDataLabelDisplayType.LINK_VALUE)) {
      showFl = true;
    } else {
      showFl = false;
    }

    (this.chartOption.series as GraphSeriesOption[]).forEach((item) => {
      if (item.edgeLabel && item.edgeLabel) {
        if (showFl) {
          item.edgeLabel.show = showFl;
          item.edgeLabel.formatter = (params) => {
            return FormatOptionConverter.getFormatValue((params.data as any).value, this.uiOption.valueFormat);
          };
        } else {
          item.edgeLabel.show = showFl;

          item.edgeLabel.formatter = '';
        }
      }
    });

    return this.chartOption;
  }

  protected override convertSeriesData(): BaseOption {
    let sourceField = '';
    let sourceColorField = '';
    let sourceSizeField = '';

    let targetField = '';
    let targetColorField = '';
    let targetSizeField = '';
    const aggs: string[] = [];

    this.pivot.columns.map((column, idx) => {
      const fieldName: string = column.alias ? column.alias : column.fieldAlias ? column.fieldAlias : column.name;

      if (idx === 0) {
        sourceField = fieldName;
      }

      if (idx === 1 && (column.type === ShelveFieldType.DIMENSION || column.type === ShelveFieldType.TIMESTAMP)) {
        // TODO: unused variable
        sourceColorField = fieldName;
      } else if (idx === 1 && (column.type === ShelveFieldType.MEASURE || column.type === ShelveFieldType.CALCULATED)) {
        // TODO: unused variable
        sourceSizeField = fieldName;
      }

      aggs.push(fieldName);
    });

    // TODO: unused cycle
    this.pivot.rows.map((row, idx) => {
      const fieldName: string = row.alias ? row.alias : row.fieldAlias ? row.fieldAlias : row.name;

      if (idx === 0) {
        targetField = fieldName;
      }

      if (idx === 1 && (row.type === ShelveFieldType.DIMENSION || row.type === ShelveFieldType.TIMESTAMP)) {
        targetColorField = fieldName;
      } else if (idx === 1 && (row.type === ShelveFieldType.MEASURE || row.type === ShelveFieldType.CALCULATED)) {
        targetSizeField = fieldName;
      }
    });

    const agg: PivotField = this.pivot.aggregations[0];
    const linkField: string = agg.alias ? agg.alias : agg.fieldAlias ? agg.fieldAlias : agg.name;

    const categories: string[] = [];
    const nodeNameList: string[] = [];
    _.each(this.data.nodes, (node) => {
      categories.push(node.originalName);
      nodeNameList.push(node.originalName);
    });

    this.chartOption.series = [
      {
        type: SeriesType.GRAPH,
        layout: GraphLayoutType.FORCE,
        roam: true,
        data: this.data.nodes,
        links: this.data.links,
        symbolSize: _.isUndefined(linkField) ? undefined : 40,
        force: {
          layoutAnimation: false,
          repulsion: this.data.links.length < 70 ? 100 : this.data.links.length * 1.5,
          edgeLength: this.data.links.length < 50 ? 150 : 40,
          initLayout: 'circular',
        },
        focusNodeAdjacency: true,
        itemStyle: {
          opacity: 0.7,
        },
        lineStyle: {
          curveness: 0.3,
          width: 1,
          color: '#aaa',
          opacity: 0.7,
        },
        animationDuration: 1000,
        animationEasingUpdate: 'quinticInOut',
        edgeSymbol: ['none', 'arrow'],
        edgeLabel: {
          show: true,
          formatter: (params) => {
            return FormatOptionConverter.getFormatValue((params.data as any).value, this.uiOption.valueFormat);
          },
        },
        tooltip: {
          trigger: TriggerType.ITEM,
          formatter: (params) => {
            return this.getFormatNetworkValueSeriesTooltip(params, this.uiOption.valueFormat, this.uiOption);
          },
        },
      },
    ];

    const legend = this.chartOption.legend as LegendComponentOption;
    const colorList: any = ChartColorList.SC1;
    (this.chartOption.series[0] as GraphSeriesOption).categories = categories.map((category, idx) => {
      const obj: any = {};
      obj.name = category;

      const colorIdx = idx >= colorList.length ? idx % colorList.length : idx;
      obj.itemStyle = {
        color: colorList[colorIdx],
      };

      return obj;
    });

    legend.data = categories;
    legend.lineStyle = { ...(legend.lineStyle || {}), color: colorList };

    this.uiOption.fieldList = [sourceField];
    (<UIChartColorByDimension>this.uiOption.color).targetField = _.last(this.uiOption.fieldList);

    this.pivotInfo = createPivotTableInfo(nodeNameList, [], aggs);

    return this.chartOption;
  }

  protected override convertLegend(): BaseOption {
    this.chartOption = LegendOptionConverter.convertLegend(this.chartOption, this.uiOption);

    return this.chartOption;
  }

  private getFormatNetworkValueSeriesTooltip(
    params: any,
    format: UIChartFormat,
    uiOption?: UIOption,
    uiData?: any,
  ): string {
    if (params.data.value) {
      if (!uiOption.toolTip) uiOption.toolTip = {};
      if (!uiOption.toolTip.displayTypes)
        uiOption.toolTip.displayTypes = FormatOptionConverter.setDisplayTypes(uiOption.type);

      let result: string[] = [];

      if (
        undefined !== params.data.target &&
        -1 !== uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.NODE_NAME)
      ) {
        result = FormatOptionConverter.getTooltipName(
          [params.data.originalSource],
          this.pivot.columns,
          result,
          true,
          this.pivot,
        );

        result = FormatOptionConverter.getTooltipName(
          [params.data.originalTarget],
          this.pivot.rows,
          result,
          true,
          this.pivot,
        );
      } else if (
        undefined == params.data.target &&
        -1 !== uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.NODE_NAME)
      ) {
        if (params.data.fields && params.data.fields.length > 0) {
          let columnField;
          for (const field of params.data.fields) {
            columnField = _.find(this.pivot.columns, { alias: field });
            result = FormatOptionConverter.getTooltipName(
              [params.data.originalName],
              !columnField ? this.pivot.rows : this.pivot.columns,
              result,
              true,
              this.pivot,
            );
          }
        }
      }
      if (-1 !== uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.LINK_VALUE)) {
        const value = FormatOptionConverter.getTooltipValue(
          this.pivot.aggregations[0].alias,
          this.pivot.aggregations,
          format,
          params.data.value,
        );

        result.push(value);
      }

      return result.join('<br/>');
    }
  }

  protected override selection(): void {
    this.addChartSelectEventListener();
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public override addChartSelectEventListener(): void {
    this.chart.off('click');
    this.chart.on('click', (params) => {
      if (this.userCustomFunction && '' !== this.userCustomFunction && -1 < this.userCustomFunction.indexOf('main')) {
        const strScript = '(' + this.userCustomFunction + ')';

        try {
          // eslint-disable-next-line no-eval
          if (eval(strScript)({ name: 'SelectionEvent', data: params ? params.name : '' })) {
            return;
          }
        } catch (e) {
          console.error(e);
        }
      }

      let selectMode: ChartSelectMode;
      let selectedColValues: string[];
      let selectedRowValues: string[];

      const series = this.chartOption.series;

      if (this.isSelected && _.isNull(params)) {
        selectMode = ChartSelectMode.CLEAR;
        this.chartOption = this.selectionClear(this.chartOption);

        this.isSelected = false;
      } else if (params != null) {
        const seriesIndex = params.seriesIndex;
        const dataIndex = params.dataIndex;
        const selectedData = series[seriesIndex].data[dataIndex];

        const isSelectMode = params.data['sourceField']
          ? _.isUndefined(series[seriesIndex].links[dataIndex].lineStyle)
          : _.isUndefined((params.data as any).itemStyle);

        if (isSelectMode) {
          selectMode = ChartSelectMode.ADD;
          this.chartOption = this.selectionAdd(this.chartOption, params);
        } else {
          selectMode = ChartSelectMode.SUBTRACT;
          this.chartOption = this.selectionSubstract(this.chartOption, params);
        }

        this.isSelected = isSelectMode;

        const sourceTarget = this.getSourceTarget(series[params.seriesIndex].data, params);

        if (params.data['originalSource'] && params.data['originalTarget']) {
          const sourceLinkList = series[seriesIndex].links.filter((item) => {
            if (item.originalSource === sourceTarget['source'].name && item.linkCnt > 0) return item;
          });

          const targetLinkList = series[seriesIndex].links.filter((item) => {
            if (item.originalTarget === sourceTarget['target'].name && item.linkCnt > 0) return item;
          });

          const sourceCon =
            (undefined === sourceTarget['source'].selectCnt || 0 === sourceTarget['source'].selectCnt) &&
            0 == sourceLinkList.length;
          const targetCon =
            (undefined === sourceTarget['target'].selectCnt || 0 === sourceTarget['target'].selectCnt) &&
            0 == targetLinkList.length;

          if ((ChartSelectMode.SUBTRACT == selectMode && sourceCon) || ChartSelectMode.SUBTRACT !== selectMode) {
            selectedColValues = [params.data['originalSource']];

            if (sourceTarget['target'].fields.length > 1 && !sourceTarget['target'].itemStyle) {
              if (undefined == selectedColValues) selectedColValues = [];
              selectedColValues.push(params.data['originalTarget']);
            }
          }
          if ((ChartSelectMode.SUBTRACT == selectMode && targetCon) || ChartSelectMode.SUBTRACT !== selectMode) {
            selectedRowValues = [params.data['originalTarget']];

            if (sourceTarget['source'].fields.length > 1 && !sourceTarget['source'].itemStyle) {
              if (undefined == selectedColValues) selectedColValues = [];
              selectedColValues.push(params.data['originalSource']);
            }
          }
        } else {
          if (!isNaN(selectedData.selectCnt)) {
            const sourceLinkList = series[seriesIndex].links.filter((item) => {
              if (item.originalSource === (params.data as any).originalName && item.linkCnt > 0) return item;
            });

            const targetLinkList = series[seriesIndex].links.filter((item) => {
              if (item.originalTarget === (params.data as any).originalName && item.linkCnt > 0) return item;
            });

            const colCondition =
              (ChartSelectMode.SUBTRACT == selectMode && selectedData.selectCnt == 0 && sourceLinkList.length == 0) ||
              ChartSelectMode.SUBTRACT !== selectMode;
            const rowCondition =
              (ChartSelectMode.SUBTRACT == selectMode && selectedData.selectCnt == 0 && targetLinkList.length == 0) ||
              ChartSelectMode.SUBTRACT !== selectMode;

            const colMatchPivot = colCondition
              ? this.pivot.columns.filter((item) => {
                  return -1 !== (params.data as any).fields.indexOf(item.name);
                })
              : [];
            selectedColValues = colMatchPivot.length > 0 ? [(params.data as any).originalName] : [];

            const rowMatchPivot = rowCondition
              ? this.pivot.rows.filter((item) => {
                  return -1 !== (params.data as any).fields.indexOf(item.name);
                })
              : [];
            selectedRowValues = rowMatchPivot.length > 0 ? [(params.data as any).originalName] : [];
          }
        }
      } else {
        return;
      }

      if (this.params.externalFilters) this.params.externalFilters = false;

      const selectData = this.setSelectData(params, selectedColValues, selectedRowValues);

      this.isUpdateRedraw = true;

      this.networkFilterApply();

      if (!this.isPage) {
        this.selection();
      }

      this.lastDrawSeries = _.cloneDeep(this.chartOption['series'] as GraphSeriesOption[]);

      this.chartSelectInfo.emit(createChartSelectInfo(selectMode, selectData, this.params));
    });
  }

  protected override selectionAdd(option: BaseOption, params: any): BaseOption {
    const series = option.series;
    const selectedSeries = series[params.seriesIndex];

    if (params.data['sourceField']) {
      const seriesLink = selectedSeries.links[params.dataIndex];

      if (!seriesLink.lineStyle) seriesLink.lineStyle = { normal: {} };
      seriesLink.lineStyle.normal['opacity'] = 0.7;
      seriesLink.linkCnt = undefined == seriesLink.linkCnt ? 1 : seriesLink.linkCnt + 1;

      if (selectedSeries.lineStyle && selectedSeries.lineStyle.normal) selectedSeries.lineStyle.normal.opacity = 0.2;

      seriesLink.existSelectData = true;

      const sourceTarget = this.getSourceTarget(selectedSeries.data, params);

      const source = sourceTarget['source'];
      const target = sourceTarget['target'];

      if (!source.itemStyle) source.itemStyle = {};
      source.itemStyle['opacity'] = 0.7;

      if (!target.itemStyle) target.itemStyle = {};
      target.itemStyle['opacity'] = 0.7;

      if (selectedSeries.itemStyle) selectedSeries.itemStyle.opacity = 0.2;
    } else {
      const seriesData = selectedSeries.data[params.dataIndex];

      if (!seriesData.itemStyle) seriesData.itemStyle = {};
      seriesData.itemStyle['opacity'] = 0.7;

      if (selectedSeries.itemStyle) selectedSeries.itemStyle.opacity = 0.2;
      if (selectedSeries.lineStyle) selectedSeries.lineStyle.opacity = 0.2;

      seriesData.selectCnt = undefined == seriesData.selectCnt ? 1 : seriesData.selectCnt + 1;
    }

    return option;
  }

  private getSourceTarget(data: any[], params: any) {
    const sourceIndex = _.findIndex(data, (item) => {
      return item.name === params.data.source;
    });

    const targetIndex = _.findIndex(data, (item) => {
      return item.name === params.data.target;
    });

    return { source: data[sourceIndex], target: data[targetIndex] };
  }

  protected override selectionClear(option: BaseOption): BaseOption {
    const series = option.series as GraphSeriesOption[];

    series.map((obj) => {
      obj.data.map((item: any) => {
        delete item.itemStyle;
        delete item.selectCnt;
      });

      obj.links.map((item: any) => {
        delete item.itemStyle;
        delete item.existSelectData;
        delete item.linkCnt;
      });

      if (obj.lineStyle) obj.lineStyle.opacity = 0.7;
      if (obj.itemStyle) obj.itemStyle.opacity = 0.7;
    });

    return option;
  }

  protected override selectionSubstract(option: BaseOption, params: any): BaseOption {
    const series = option.series;
    const selectedSeries = series[params.seriesIndex];
    const selectedData = selectedSeries.data[params.dataIndex];

    if (params.data['sourceField']) {
      const link = selectedSeries.links[params.dataIndex];

      link.linkCnt -= 1;

      const sourceTarget = this.getSourceTarget(selectedSeries.data, params);

      const source = sourceTarget['source'];
      const target = sourceTarget['target'];

      if (0 === link.linkCnt) {
        delete link.lineStyle;

        const sourceLinkList = selectedSeries.links.filter((item) => {
          if ((item.originalSource === source.name || item.originalTarget === source.name) && item.linkCnt > 0)
            return item;
        });

        const targetLinkList = selectedSeries.links.filter((item) => {
          if ((item.originalSource === target.name || item.originalTarget === target.name) && item.linkCnt > 0)
            return item;
        });

        if ((undefined === source.selectCnt || 0 === source.selectCnt) && 0 == sourceLinkList.length)
          delete source.itemStyle;
        if ((undefined === target.selectCnt || 0 === target.selectCnt) && 0 == targetLinkList.length)
          delete target.itemStyle;
      }

      link.existSelectData = false;

      const selectedList = selectedSeries.links.filter((item) => {
        return item.linkCnt && 0 !== item.linkCnt;
      });

      const selectedDataList = selectedSeries.data.filter((item) => {
        return item.selectCnt && 0 !== item.selectCnt;
      });

      if ((!selectedList || 0 == selectedList.length) && (!selectedDataList || 0 == selectedDataList.length)) {
        selectedSeries.lineStyle.normal['opacity'] = 0.7;
      }
    } else {
      if (isNaN(selectedData.selectCnt - 1) || selectedData.selectCnt <= 0) return option;

      selectedData.selectCnt -= 1;

      const filterLink = selectedSeries.links.filter((item) => {
        if ((item.originalSource === selectedData.name || item.originalTarget === selectedData.name) && item.linkCnt)
          return item;
      });

      if (0 === selectedData.selectCnt && 0 === filterLink.length) {
        delete selectedData.itemStyle;
      }
    }

    const selectedList = selectedSeries.data.filter((item) => {
      return item.selectCnt && 0 !== item.selectCnt;
    });

    const selectedLinkList = selectedSeries.links.filter((item) => {
      return item.linkCnt && 0 !== item.linkCnt;
    });

    if (!selectedList || (0 == selectedList.length && (!selectedLinkList || 0 == selectedLinkList.length))) {
      selectedSeries.itemStyle['opacity'] = 0.7;
      selectedSeries.lineStyle['opacity'] = 0.7;
    }

    return option;
  }

  private networkFilterApply(initFl = true): void {
    if ((this.isUpdateRedraw && initFl) || (this.params && this.params.externalFilters)) {
      this.chart.dispose();

      this.eChartService
        .initChart(this.widgetId, this.elementRef.nativeElement)
        .pipe(take(1))
        .subscribe((eChartInstance) => {
          this.chart = eChartInstance;
        });
    }

    this.chart.setOption(this.chartOption, true, false);
  }
}
