import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Session, SessionStatus, WorkflowClonePayload, WorkflowInfo } from '@selfai-platform/pipeline-common';
import { AlertService, UserProfileService } from '@selfai-platform/shared';
import { BreadcrumbsMenuItem, DataListViewItem, KE_ROOT_ROUTE, KE_WORKFLOW_PATH } from '@selfai-platform/shell';
import { BehaviorSubject, Observable, catchError, combineLatest, filter, map, of, tap, withLatestFrom } from 'rxjs';
import { WorkflowApiService } from '../../services';
import { SessionsManagerService } from '../session-manager/sessions-manager.service';

export interface WorkflowListItem extends WorkflowInfo, DataListViewItem {
  id: string;
  status?: SessionStatus;
  isOwner?: boolean;
  running: boolean;
  stopInProgress?: boolean;
}

export type WorkflowUpdateOptions = Pick<WorkflowListItem, 'id'> & Partial<WorkflowListItem>;

@Injectable()
export class WorkflowListComponentService extends BehaviorSubject<WorkflowListItem[]> {
  breadcrumbItems$: Observable<BreadcrumbsMenuItem[]> = this.translate.stream('shell.dp.ui.flows').pipe(
    map((label) => [
      {
        label,
        routerLink: ['/', KE_ROOT_ROUTE],
      },
    ]),
  );

  // TODO: Move logic for Workflow List to separate service and use stores
  readonly loading$ = new BehaviorSubject<boolean>(false);
  readonly loaded$ = new BehaviorSubject<boolean>(false);
  readonly error$ = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly workflowApiService: WorkflowApiService,
    private readonly alertService: AlertService,
    private readonly router: Router,
    private readonly sessionsManagerService: SessionsManagerService,
    private readonly userProfileService: UserProfileService,
    private readonly translate: TranslateService,
  ) {
    super([]);
  }

  loadWorkflowList(): Observable<WorkflowListItem[]> {
    this.sessionsManagerService.loadSessionList();
    this.loading$.next(true);

    return this.workflowApiService.getAllWorkflows().pipe(
      map((workflowList) => workflowList.map((workflow) => ({ ...workflow, running: false }))),
      tap((workflowList) => this.next(workflowList)),
      tap(() => this.loading$.next(false)),
      tap(() => this.loaded$.next(true)),
      catchError(() => {
        this.error$.next(true);
        this.alertService.error('Error of loading workflow list');

        return of([]);
      }),
    );
  }

  getWorkflowList(): Observable<WorkflowListItem[]> {
    return combineLatest([
      this.asObservable(),
      this.sessionsManagerService.getSessionList().pipe(filter(Boolean)),
      this.userProfileService.getUserProfile(),
    ]).pipe(
      map(([worflows, sessions, userProfile]) => {
        const sessionMap = new Map<string, Session>();
        sessions.forEach((session) => sessionMap.set(session.workflowId, session));

        return worflows.map((worflow) => ({
          ...worflow,
          status: sessionMap.get(worflow.id)?.status,
          isOwner: userProfile.id === worflow.ownerId,
          running: sessionMap.get(worflow.id)?.status === SessionStatus.RUNNING,
        }));
      }),
    );
  }

  getWorkflowById(id: string): Observable<WorkflowListItem | undefined> {
    return this.getWorkflowList().pipe(map((workflowList) => workflowList.find((workflow) => workflow.id === id)));
  }

  deleteWorkflow(id: string): void {
    this.workflowApiService
      .deleteWorkflow(id)
      .pipe(withLatestFrom(this.getWorkflowList()))
      .subscribe({
        next: ([, workflowList]) => {
          this.next(workflowList.filter((workflow) => workflow.id !== id));
        },
        error: () => {
          this.alertService.error('Error of deleting workflow');
        },
      });
  }

  cloneWorkflow(payload: WorkflowClonePayload): Observable<unknown> {
    return this.workflowApiService.cloneWorkflow(payload);
  }

  addWorkflow(payload: WorkflowClonePayload): void {
    this.workflowApiService.createWorkflow(payload).subscribe({
      next: ({ workflowId }) => {
        this.router.navigate([KE_ROOT_ROUTE, KE_WORKFLOW_PATH, workflowId]);
      },
      error: () => {
        this.alertService.error('Error of adding workflow');
      },
    });
  }

  updateWorkflow(payload: WorkflowUpdateOptions): void {
    this.updateWorkflowState(payload);

    this.workflowApiService.updateWorkflow(payload as any).subscribe({
      error: () => {
        this.alertService.error('Error of updating workflow');
      },
    });
  }

  getWorkflowFileUploadUrl(): string {
    return this.workflowApiService.getUploadWorkflowMethodUrl();
  }

  updateWorkflowState(updateOptions: WorkflowUpdateOptions): void {
    const updateWorkflow = this.getValue().map((workflow) => {
      if (workflow.id === updateOptions.id) {
        return { ...workflow, ...updateOptions };
      }

      return workflow;
    });
    this.next(updateWorkflow);
  }

  stopSessionWorkflow(workflowId: string): void {
    this.updateWorkflowState({ id: workflowId, stopInProgress: true });
    this.sessionsManagerService.stopSession(workflowId);
  }
}
