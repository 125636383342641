import { DatasourceField, FieldPivot, GranularityType } from '../../../../datasource';
import { DashobardFieldType } from '../../enums/field-type.enum';
import { Format } from '../format.model';
import { DIRECTION } from '../sort.model';

export interface DashboardField {
  type?: DashobardFieldType;
  name: string;
  alias: string;
  fieldAlias?: string;
  pivotAlias?: string;
  ref?: string;
  subType?: string;
  subRole?: string;
  aggregationType?: any;
  aggregationTypeList?: any;
  options?: string;
  granularity?: GranularityType;
  segGranularity?: GranularityType;
  granularityList?: any;
  timeExprUnit?: GranularityType;
  direction?: DIRECTION;
  lastDirection?: boolean;
  showValue?: boolean;
  field?: DatasourceField;
  pivot?: FieldPivot[];
  format?: Format;
  currentPivot?: FieldPivot;
  aggregated?: boolean;
  isCustomField?: boolean;
}

export function createDashboardField(params: Partial<DashboardField> = {}): DashboardField {
  return {
    name: '',
    alias: params.alias || params.name || '',
    ...params,
  };
}
