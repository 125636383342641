import { NgModule } from '@angular/core';
import { LoadingService } from '@selfai-platform/shared';

import {
  AggregatorActiveItemService,
  AggregatorRouteListService,
  AggregatorCamelValidationService,
  AggregatorDictionariesService,
  AggregatorExportService,
  AggregatorImportService,
  AggregatorItemService,
} from './services';
import { LOADING_ROUTES_LOAD, LOADING_ROUTES_SAVE } from './tokens';

@NgModule({
  providers: [
    AggregatorActiveItemService,
    AggregatorRouteListService,
    AggregatorItemService,
    AggregatorCamelValidationService,
    AggregatorExportService,
    AggregatorImportService,
    AggregatorDictionariesService,
    {
      provide: LOADING_ROUTES_LOAD,
      useClass: LoadingService,
    },
    {
      provide: LOADING_ROUTES_SAVE,
      useClass: LoadingService,
    },
  ],
})
export class AggregatorCamelModule {}
