<div class="ddp-ui-chart-side ddp-show">
  <div class="ddp-filter">
    <div class="ddp-ui-side-title">
      {{ 'msg.page.ui.common' | translate }}
    </div>

    <div class="ddp-wrap-downmenu">
      <div class="ddp-box-down">
        <div class="ddp-wrap-divide">
          <div class="ddp-divide2">
            <div class="ddp-wrap-option-slider">
              <span class="ddp-label-slider">{{ 'msg.page.common.map.layer' | translate }}</span>
            </div>

            <div class="ddp-list-sub2">
              <div class="ddp-divide2">
                <div class="ddp-list-label">{{ 'msg.page.common.map.layer.map.style' | translate }}</div>

                <component-select
                  [array]="mapStyleList"
                  [defaultIndex]="findStyleDefaultIndex()"
                  [viewKey]="'name'"
                  (onSelected)="setMapStyle($event)"
                ></component-select>
              </div>

              <div class="ddp-divide2">
                <div class="ddp-list-label">{{ 'msg.page.common.map.layer.license' | translate }}</div>
                <input
                  type="text"
                  class="ddp-input-typebasic"
                  placeholder=""
                  [(ngModel)]="uiOption.licenseNotation"
                  (keyup.enter)="setLicense()"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
