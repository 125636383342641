import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  BoardDataSource,
  DashboardDomainService,
  DataLabelPosition,
  PageWidget,
  PageWidgetConfiguration,
  UIOption,
  UiSettings,
  Widget,
  WidgetDomainService,
  createDatasourceField,
  createPageWidget,
} from '@selfai-platform/bi-domain';
import * as echarts from 'echarts/core';
import { Observable, combineLatest, map, take, tap } from 'rxjs';
import { DataSettingsService } from './data-settings.service';
import { EChartService } from './echart.service';
import { UiSettingsService } from './ui-settings.service';

@Injectable({ providedIn: 'root' })
export class WidgetConfigService {
  constructor(
    private readonly dataSettingsService: DataSettingsService,
    private readonly uiSettingsService: UiSettingsService,
    private readonly dashboardDomainService: DashboardDomainService,
    private readonly widgetDomainService: WidgetDomainService,
    private readonly eChartService: EChartService,
    private readonly translateService: TranslateService,
  ) {}

  prepareConfigForSave(widgetId: string, contextId?: string): Observable<PageWidgetConfiguration> {
    return combineLatest({
      uiSettings: this.uiSettingsService.getSettings(widgetId, contextId),
      dataSettings: this.dataSettingsService.getSettings(widgetId, contextId),
      dashboard: this.dashboardDomainService.getDashboardByWidgetId(widgetId),
      eChartInstance: this.eChartService.getInstance(widgetId, contextId),
    }).pipe(
      map(({ uiSettings, dataSettings, dashboard, eChartInstance }) => {
        return {
          type: 'page',
          chart: {
            ...this.mapUiSettingsToApi(uiSettings, eChartInstance),
            fieldList: dataSettings.pivot.columns.map(({ name, alias }) => alias || name),
            fielDimensionList: [...dataSettings.pivot.columns, dataSettings.pivot.rows] as any[],
            fieldMeasureList: dataSettings.pivot.aggregations as any[],
          },
          dataSource: dashboard.configuration.dataSource,
          fields:
            dashboard.configuration.customFields.map((customField) =>
              createDatasourceField({
                name: customField.name,
                type: customField.type,
                role: customField.role,
                expr: customField.expr,
                ref: customField.ref,
                alias: customField.name,
                join: customField.join,
                aggregated: customField.aggregated,
              }),
            ) || [],
          filters: [], // TODO: add filters
          pivot: dataSettings.pivot,
          limit: {
            limit: uiSettings.common.limit,
            sort: [],
          },
        };
      }),
    );
  }

  initWidgetSettings({
    widgetId,
    isNew,
    contextId,
  }: {
    widgetId: string;
    isNew?: boolean;
    contextId?: string;
  }): Observable<void> {
    let widgetSource$: Observable<Widget>;
    if (isNew) {
      widgetSource$ = combineLatest({
        uiSettings: this.uiSettingsService.getSettings(widgetId, contextId),
        dataSettings: this.dataSettingsService.getSettings(widgetId, contextId),
        dashboard: this.dashboardDomainService.getDashboardByWidgetId(widgetId),
      })
        .pipe(
          map(({ uiSettings, dataSettings, dashboard }) => {
            const boardDataSource: BoardDataSource = dashboard.configuration.dataSource;

            return createPageWidget({
              id: widgetId,
              name: this.translateService.instant('msg.board.chart.newDiagram'),
              dashBoardId: dashboard.id,
              configuration: {
                chart: {
                  type: uiSettings.type,
                  fieldList: dataSettings.pivot.columns.map(({ name, alias }) => alias || name),
                  fielDimensionList: [...dataSettings.pivot.columns, dataSettings.pivot.rows] as any[],
                  fieldMeasureList: dataSettings.pivot.aggregations as any[],
                },
                dataSource: boardDataSource.type === 'multi' ? boardDataSource.dataSources[0] : boardDataSource,
                customFields: dashboard.configuration.customFields,
                pivot: dataSettings.pivot,
              } as PageWidgetConfiguration,
            });
          }),
        )
        .pipe(
          tap((widget) => {
            this.widgetDomainService.upsertWidgetCache(widget, contextId);
          }),
        );
    } else {
      widgetSource$ = this.widgetDomainService.loadWidget(widgetId, contextId);
    }

    return widgetSource$.pipe(
      take(1),
      map((widget) => {
        if (this.isPageWidget(widget)) {
          this.uiSettingsService.setSettingsFromLegacy(widgetId, widget.configuration.chart, contextId);
          this.dataSettingsService.setSettings(widgetId, { pivot: widget.configuration.pivot }, contextId);
        }
      }),
    );
  }

  private mapUiSettingsToApi(uiSettings: UiSettings, eChartInstance: echarts.ECharts): UIOption {
    return {
      type: uiSettings.type,
      fontSize: uiSettings.common.fontSize,
      legend: {
        auto: uiSettings.showLegend || false,
      },
      color: uiSettings.color,
      dataLabel: {
        showValue: uiSettings.dataLabel.showValue || false,
        pos: uiSettings.dataLabel?.position as DataLabelPosition,
        displayTypes: uiSettings.dataLabel?.displayTypes,
      },
      toolTip: uiSettings.tooltip,
      version: 2,
      // TODO: save dataZooms for sliders
      // chartZooms: eChartInstance.getOption().dataZoom.map((zoom) => omit(zoom, ['id'])),
    };
  }

  private isPageWidget(widget: Widget): widget is PageWidget {
    return widget.type === 'page';
  }
}
