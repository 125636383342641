import { Injectable, OnDestroy } from '@angular/core';
import { WorkflowSerialized } from '@selfai-platform/pipeline-common';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WorkflowStateService extends ReplaySubject<WorkflowSerialized | undefined> implements OnDestroy {
  constructor() {
    super(1);
  }

  getWorkflowState(): Observable<WorkflowSerialized | undefined> {
    return this.asObservable();
  }

  setWorkflowState(state: WorkflowSerialized): void {
    this.next(state);
  }

  clearWorkflowState(): void {
    this.next(undefined);
  }

  ngOnDestroy(): void {
    this.complete();
  }
}
