<form [formGroup]="form" class="ddp-wrap-dateinfo ddp-filter-dateinfo">
  <ng-container
    *ngTemplateOutlet="
      widgetTemplate;
      context: {
        dateLabel: 'msg.from',
        romeLabel: 'msg.board.filter.ui.time-range.earliest',
        showMessage: isEarliestDateTime,
        defaultIndex: fromComboIdx,
        selectItem: true,
        control: form.get('from'),
        isStart: true
      }
    "
  ></ng-container>

  <ng-container
    *ngTemplateOutlet="
      widgetTemplate;
      context: {
        dateLabel: 'msg.to',
        showMessage: isLatestDateTime,
        defaultIndex: toComboIdx,
        selectItem: false,
        control: form.get('to'),
        isStart: false
      }
    "
  ></ng-container>
</form>

<ng-template
  #widgetTemplate
  let-dateLabel="dateLabel"
  let-romeLabel="romeLabel"
  let-showMessage="showMessage"
  let-defaultIndex="defaultIndex"
  let-selectItem="selectItem"
  let-control="control"
  let-isStart="isStart"
>
  <div class="ddp-ui-dateinfo" [style.width]="isWidget ? '200px' : ''">
    <div class="ddp-dateinfo-in">
      <span class="ddp-txt-date">{{ dateLabel | translate }}</span>

      <div class="ddp-box-rome">
        <div class="ddp-box-dateinfo ddp-clear ddp-box-radius ddp-width-auto">
          <p-calendar
            appendTo="body"
            [formControl]="control"
            [showTime]="true"
            [showIcon]="true"
            [showSeconds]="true"
            [disabled]="initialDate.mockup"
            (onClose)="onClose(isStart)"
          ></p-calendar>
        </div>

        <ng-container *ngIf="isComboList">
          <div class="ddp-box-dateinfo ddp-clear">
            <component-select
              [array]="comboList"
              [defaultIndex]="defaultIndex"
              [viewKey]="'label'"
              (onSelected)="onSelectComboItem($event, selectItem)"
            ></component-select>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
