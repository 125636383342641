<div class="chart-wrapper">
  <div class="chart-wrapper__element">
    <div
      class="chart-wrapper__panel"
      (click)="selectElement(EElementType.Data)"
    >
      <div class="chart-wrapper__panel--title">
        {{ "msg.page.ui.panel.title" | translate }}
      </div>

      <div class="chart-wrapper__panel--desc">
        {{ "msg.page.ui.num.dimensions.measures" | translate: countData }}
      </div>
    </div>

    <ng-container *ngIf="(selectPanel$ | async) === EElementType.Data">
      <div class="chart-wrapper__content">
        <ng-content select="[data]"></ng-content>
      </div>
    </ng-container>
  </div>

  <div class="chart-wrapper__element">
    <div
      class="chart-wrapper__panel"
      (click)="selectElement(EElementType.Chart)"
    >
      <div class="chart-wrapper__panel--title">
        {{ "msg.page.ui.elements" | translate }}
      </div>
      <div class="chart-wrapper__panel--desc">{{ selectChartTranslate }}</div>
    </div>

    <ng-container *ngIf="(selectPanel$ | async) === EElementType.Chart">
      <div class="chart-wrapper__content">
        <ng-content select="[chart]"></ng-content>
      </div>
    </ng-container>
  </div>
</div>
