import _ from 'lodash';

import {
  Dashboard,
  BoardConfiguration,
  Datasource,
  DatasourceField as Field,
  LogicalType,
  FieldRole,
  Filter,
  TimeUnit,
  createTimeFilter,
  CustomField,
} from '@selfai-platform/bi-domain';

import { DashboardUtil } from '../../../../dashboard/util/dashboard.util';
import { FilterUtil } from '../../../../dashboard/util/filter.util';
import { getMainDataSources } from '../../../util';

export function initializeBoardFilters(dashboard: Dashboard): Dashboard {
  const boardConf: BoardConfiguration = dashboard.configuration;
  const savedFilters: Filter[] = _.cloneDeep(boardConf.filters);

  let genFilters: Filter[] = [];
  {
    const dsList: Datasource[] = getMainDataSources(dashboard);
    dsList.forEach((dsInfo: Datasource) => {
      const fields = DashboardUtil.getFieldsForMainDataSource(boardConf, dsInfo.engineName);

      let recommendFilters: Filter[] = [];

      fields.forEach((field: Field) => {
        if (field.filtering) {
          let rcmdFilter: Filter = undefined;
          if (field.logicalType === LogicalType.TIMESTAMP) {
            rcmdFilter = FilterUtil.getTimeRangeFilter(field, TimeUnit.NONE, 'recommended', dsInfo);
          } else {
            rcmdFilter = FilterUtil.getBasicInclusionFilter(field, 'recommended');
          }

          if (rcmdFilter) {
            if (savedFilters && 0 < savedFilters.length) {
              const savedIdx: number = savedFilters.findIndex((item: Filter) => {
                return item.field === field.name && item.dataSource === dsInfo.engineName;
              });
              if (-1 < savedIdx) {
                const savedItem: Filter = savedFilters[savedIdx];
                savedFilters.splice(savedIdx, 1);
                rcmdFilter = _.merge(rcmdFilter, savedItem);
              }
            }

            recommendFilters.push(rcmdFilter);
          }
        }
      });

      recommendFilters = _.orderBy(recommendFilters, (item) => (item.ui ? item.ui.filteringSeq : ''), 'asc');
      genFilters = genFilters.concat(recommendFilters);
    });
  }

  if (savedFilters && 0 < savedFilters.length) {
    let totalFields: (Field | CustomField)[] = _.cloneDeep(boardConf.fields);
    boardConf.customFields && (totalFields = totalFields.concat(_.cloneDeep(boardConf.customFields)));

    boardConf.filters = genFilters.concat(
      savedFilters.map((savedItem: Filter) => {
        const filterField: Field = totalFields.find((field) => field.name === savedItem.field) as Field;
        if (filterField) {
          if (filterField.role === FieldRole.MEASURE) {
            return _.merge(FilterUtil.getBasicBoundFilter(filterField), savedItem);
          } else if (filterField.logicalType === LogicalType.TIMESTAMP) {
            return _.merge(createTimeFilter(filterField), savedItem);
          } else {
            return _.merge(FilterUtil.getBasicInclusionFilter(filterField), savedItem);
          }
        } else {
          return savedItem;
        }
      }),
    );
  } else {
    boardConf.filters = genFilters;
  }

  return dashboard;
}
