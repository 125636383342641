import { filter, fromEvent, map, Observable, tap, withLatestFrom } from 'rxjs';

const confirmSaveWhenLeaveSite$ = fromEvent(window, 'beforeunload');

export function confirmSaveWhenLeaveSite(isSaved$: Observable<boolean>): Observable<Event> {
  return confirmSaveWhenLeaveSite$.pipe(
    withLatestFrom(isSaved$),
    filter(([, isSaved]) => !isSaved),
    map(([event]) => event),
    tap((event) => (event.returnValue = true)),
  );
}
