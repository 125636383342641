import { Injectable } from '@angular/core';
import { WidgetApiModel, WidgetApiService, WidgetUpdateApiModel } from '@selfai-platform/bi-api';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { CreateWidget, FilterWidget, PageWidget, TextWidget, Widget } from '../models';
import {
  widgetApiResponseToFilterWidgetNormalizer,
  widgetApiResponseToPageWidgetNormalizer,
  widgetApiResponseToSpecWidgetsNormalizer,
  widgetApiResponseToTextWidgetNormalizer,
  widgetsApiResponseArrayToWidgetsNormalizer,
} from '../normalizer';

@Injectable({ providedIn: 'root' })
export class WidgetApiToDomainService {
  private dashboardWidgetsSubject: BehaviorSubject<Widget[]> = new BehaviorSubject<Widget[]>([]);
  dashboardWidgets$ = this.dashboardWidgetsSubject.asObservable();

  constructor(private readonly widgetApiService: WidgetApiService) {}

  initDasboardWidgets(widgets: WidgetApiModel[]): void {
    this.dashboardWidgetsSubject.next(widgetsApiResponseArrayToWidgetsNormalizer(widgets));
  }

  getWidget(widgetId: string): Observable<PageWidget | TextWidget | FilterWidget> {
    return this.widgetApiService.getWidget(widgetId).pipe(map(widgetApiResponseToSpecWidgetsNormalizer));
  }

  getPageWidget(widgetId: string): Observable<PageWidget> {
    return this.widgetApiService.getWidget(widgetId).pipe(map(widgetApiResponseToPageWidgetNormalizer));
  }

  getTextWidget(widgetId: string): Observable<TextWidget> {
    return this.widgetApiService.getWidget(widgetId).pipe(map(widgetApiResponseToTextWidgetNormalizer));
  }

  getFilterWidget(widgetId: string): Observable<FilterWidget> {
    return this.widgetApiService.getWidget(widgetId).pipe(map(widgetApiResponseToFilterWidgetNormalizer));
  }

  deleteWidget(widgetId: string): Observable<void> {
    return this.widgetApiService.deleteWidget(widgetId);
  }

  addWidget(widget: CreateWidget, dashboardId: string): Observable<Widget> {
    return this.widgetApiService.createWidget(widget, dashboardId).pipe(map(widgetApiResponseToSpecWidgetsNormalizer));
  }

  updateWidget(widgetId: string, widget: WidgetUpdateApiModel): Observable<Widget> {
    return this.widgetApiService.updateWidget(widgetId, widget).pipe(map(widgetApiResponseToSpecWidgetsNormalizer));
  }
}
