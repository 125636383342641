import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AutoFocusModule } from 'primeng/autofocus';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { SkeletonModule } from 'primeng/skeleton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TooltipModule } from 'primeng/tooltip';
import { JsonSchemaTreeViewComponentModule } from '../../../json-path/components/json-schema-tree-view/json-schema-tree-view.module';
import { AggregatorCamelStepComponentModule } from '../aggregator-camel-step/aggregator-camel-step.module';
import { AggregatorHazelCastMonitorComponentModule } from '../aggregator-hazel-cast-monitor';
import { AggregatorImportFormComponentModule } from '../aggregator-import-form';
import { AggregatorCamelEditorComponent } from './aggregator-camel-editor.component';

@NgModule({
  imports: [
    CommonModule,
    JsonSchemaTreeViewComponentModule,
    AggregatorCamelStepComponentModule,
    AggregatorImportFormComponentModule,
    AggregatorHazelCastMonitorComponentModule,
    DialogModule,
    TooltipModule,
    InputTextModule,
    ButtonModule,
    CheckboxModule,
    AutoFocusModule,
    ReactiveFormsModule,
    SplitButtonModule,
    SkeletonModule,
    RouterModule.forChild([
      {
        path: '',
        component: AggregatorCamelEditorComponent,
      },
    ]),
  ],
  declarations: [AggregatorCamelEditorComponent],
  exports: [AggregatorCamelEditorComponent],
})
export class AggregatorCamelEditorComponentModule {}
