<label class="cursor-pointer">
  <p-checkbox
    label="public"
    [trueValue]="visibilityEnum.PUBLIC"
    [falseValue]="visibilityEnum.PRIVATE"
    [binary]="true"
    [formControl]="formControl"
  ></p-checkbox>
  (Public data sources are accessible by all users of this instance of Pipeline)
</label>
