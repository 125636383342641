import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { UserService } from '../../user/service/user.service';

@Injectable()
export class MetadataManagementGuard {
  constructor(private userService: UserService) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.userService.isLoggedIn();
  }
}
