import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WorkflowSettingsSchedules } from '../interface/workflow-settings-schedules.interface';
import { WorkflowSettingsSchedulesStore } from '../store/workflow-settings-schedules.store';

@Injectable()
export class WorkflowSettingsSchedulesDomainService {
  workflowSettingsSchedules$: Observable<WorkflowSettingsSchedules[]>;
  constructor(private workflowSettingsSchedulesStore: WorkflowSettingsSchedulesStore) {
    this.workflowSettingsSchedules$ = workflowSettingsSchedulesStore.entities$;
  }

  getList(id: string): void {
    this.workflowSettingsSchedulesStore.clearCache();
    this.workflowSettingsSchedulesStore.getWithQuery(id);
  }

  add(data: WorkflowSettingsSchedules): void {
    this.workflowSettingsSchedulesStore.add(data);
  }

  update(data: WorkflowSettingsSchedules): void {
    this.workflowSettingsSchedulesStore.update(data);
  }

  delete(id: string): void {
    this.workflowSettingsSchedulesStore.delete(id);
  }
}
