import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { UiSettings } from '@selfai-platform/bi-domain';
import { getIdContextKey } from '@selfai-platform/shared';
import { Observable, map } from 'rxjs';
import { UiSettingsState } from '../../models';
import { UiSettingsActions } from '../actions';
import { UiSettingsSelectors } from '../selectors';

@Injectable({ providedIn: 'root' })
export class UiSettingsFacade {
  constructor(private readonly store: Store<UiSettingsState>) {}

  selectUiSettings(widgetId: string, contextId?: string): Observable<UiSettings | undefined> {
    return this.store
      .select(UiSettingsSelectors.selectUiSettingsEntity(getIdContextKey(widgetId, contextId)))
      .pipe(map((state) => state?.settings));
  }

  setUiSettings(widgetId: string, widgetData: UiSettings, contextId?: string): void {
    this.store.dispatch(
      UiSettingsActions.setUiSettings({ widgetId: getIdContextKey(widgetId, contextId), settings: widgetData }),
    );
  }

  resetUiSettings(widgetId: string, contextId?: string): void {
    this.store.dispatch(UiSettingsActions.clearUiSettings({ widgetId: getIdContextKey(widgetId, contextId) }));
  }
}
