import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WidgetEmeddedApiConfigService {
  private apiUrl$ = new ReplaySubject<string>(1);

  constructor() {}

  setApiUrl(apiUrl: string): void {
    this.apiUrl$.next(apiUrl);
  }

  getApiUrl(): Observable<string> {
    return this.apiUrl$.asObservable();
  }
}
