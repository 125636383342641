import { Pivot, ShelveFieldType, ShelveType } from '@selfai-platform/bi-domain';

export function getCountByPivotType(pivot: Pivot, pivotType: ShelveType, fieldType: ShelveFieldType) {
  return pivot?.[pivotType]?.filter((field) => {
    if (field.type === ShelveFieldType.DIMENSION && field.format && field.format.unit) {
      field.type = ShelveFieldType.TIMESTAMP;
    }

    return field.type === fieldType;
  }).length;
}

export function getCountRowsByFieldType(pivot: Pivot, fieldType: ShelveFieldType) {
  return getCountByPivotType(pivot, ShelveType.ROWS, fieldType);
}

export function getCountColumnsByFieldType(pivot: Pivot, fieldType: ShelveFieldType) {
  return getCountByPivotType(pivot, ShelveType.COLUMNS, fieldType);
}

export function getCountAggregationsByFieldType(pivot: Pivot, fieldType: ShelveFieldType) {
  return getCountByPivotType(pivot, ShelveType.AGGREGATIONS, fieldType);
}
