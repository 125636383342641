<ng-container *ngIf="errorMessage; else resultTemplate">
  <p-message class="block" severity="error" text="Error. Try again later"></p-message>
</ng-container>

<ng-template #resultTemplate>
  <ng-container *ngIf="operationLoading; else loadedResultTemplate">
    <p-skeleton
      *ngFor="let _ of [].constructor(5)"
      styleClass="mb-3"
      width="50%"
      height="30px"
      shape="rectangle"
    ></p-skeleton>
  </ng-container>
  <ng-template #loadedResultTemplate>
    <form *ngIf="!dataIsEmpty">
      <selfai-platform-cms-form [fields]="fields" [form]="form"></selfai-platform-cms-form>
    </form>
  </ng-template>
</ng-template>
