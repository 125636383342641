import { Injectable } from '@angular/core';
import { getIdContextKey } from '@selfai-platform/shared';
import { Observable, filter, map, tap } from 'rxjs';
import { CreateWidget, FilterWidget, PageWidget, TextWidget, Widget, WidgetState } from '../models';
import { WidgetStore } from '../stores';
import { WidgetApiToDomainService } from './widget-api-to-domain.service';

@Injectable({ providedIn: 'root' })
export class WidgetDomainService {
  dashboardWidgets$ = this.widgetApiToDomainService.dashboardWidgets$;

  constructor(
    private readonly widgetApiToDomainService: WidgetApiToDomainService,
    private readonly widgetStore: WidgetStore,
  ) {}

  getDashboardWidget<T extends PageWidget | TextWidget | FilterWidget>(widgetId: string): Observable<T> {
    return this.dashboardWidgets$.pipe(map((t) => t.filter((w) => w.id === widgetId)[0] as T));
  }

  getDashboardPageWidgets(): Observable<PageWidget[]> {
    return this.dashboardWidgets$.pipe(map((t) => t.filter((w) => w.type === 'page') as PageWidget[]));
  }

  getDashboardTextWidgets(): Observable<TextWidget[]> {
    return this.dashboardWidgets$.pipe(map((t) => t.filter((w) => w.type === 'text') as TextWidget[]));
  }

  getDashboardFilterWidgets(): Observable<FilterWidget[]> {
    return this.dashboardWidgets$.pipe(map((t) => t.filter((w) => w.type === 'filter') as FilterWidget[]));
  }

  loadWidget(widgetId: string, contextId?: string): Observable<Widget> {
    return this.widgetStore.getByKey(widgetId).pipe(
      tap((widget) => {
        this.widgetStore.upsertOneInCache({ id: widgetId, ...widget, contextId });
      }),
    );
  }

  getWidget(widgetId: string, contextId?: string): Observable<Widget> {
    return this.widgetStore.entityMap$.pipe(
      map((entityMap) => {
        return entityMap[getIdContextKey(widgetId, contextId)];
      }),
      filter(Boolean),
    );
  }

  getPageWidget(widgetId: string, contextId?: string): Observable<PageWidget> {
    return this.getWidget(widgetId, contextId).pipe(map((widget) => widget as PageWidget));
  }

  getTextWidget(widgetId: string, contextId?: string): Observable<TextWidget> {
    return this.getWidget(widgetId, contextId).pipe(map((widget) => widget as TextWidget));
  }

  getFilterWidget(widgetId: string, contextId?: string): Observable<FilterWidget> {
    return this.getWidget(widgetId, contextId).pipe(map((widget) => widget as FilterWidget));
  }

  deleteWidget(widgetId: string, contextId?: string): Observable<void> {
    return this.widgetStore.delete({ id: widgetId, contextId } as WidgetState).pipe(map(() => void 0));
  }

  addWidget(widget: CreateWidget, dashboardId: string, contextId?: string): Observable<Widget> {
    return this.widgetStore.add({ dashBoardId: dashboardId, id: widget.id, ...widget, contextId });
  }

  updateWidget(widgetId: string, widget: Partial<Widget>, contextId?: string): Observable<Widget> {
    return this.widgetStore.update({ id: widgetId, ...widget, contextId });
  }

  upsertWidgetCache(widget: Widget, contextId?: string): void {
    this.widgetStore.upsertOneInCache({ id: widget.id, ...widget, contextId });
  }
}
