import { Injectable } from '@angular/core';

import _ from 'lodash';

import { Workspace } from '@selfai-platform/bi-domain';

import { WorkspaceService } from './workspace.service';

@Injectable()
export class UserWorkspaceService {
  private myWorkspace: Workspace;

  constructor(private readonly workspaceService: WorkspaceService) {}

  async getMyWorkspace() {
    if (!this.myWorkspace) {
      await this.setMyWorkspace();
    }
    return _.cloneDeep(this.myWorkspace);
  }

  async setMyWorkspace() {
    const myWorkspace = await this.workspaceService.getMyWorkspace();
    this.myWorkspace = myWorkspace;
  }
}
