import { Injectable } from '@angular/core';
import { getIdContextKey } from '@selfai-platform/shared';
import * as echarts from 'echarts/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EChartStore {
  private eChartInstanceMap = new Map<string, BehaviorSubject<echarts.ECharts | undefined>>();

  setInstance(widgetId: string, eChartInstance: echarts.ECharts, contextId?: string) {
    this.createInitialEntity(widgetId, contextId);
    this.eChartInstanceMap.get(getIdContextKey(widgetId, contextId)).next(eChartInstance);
  }

  selectInstance(widgetId: string, contextId?: string): Observable<echarts.ECharts> {
    this.createInitialEntity(widgetId, contextId);

    return this.eChartInstanceMap.get(getIdContextKey(widgetId, contextId));
  }

  removeInstance(widgetId: string, contextId?: string): void {
    this.eChartInstanceMap.delete(getIdContextKey(widgetId, contextId));
  }

  private createInitialEntity(widgetId: string, contextId?: string): void {
    if (!this.eChartInstanceMap.has(getIdContextKey(widgetId, contextId))) {
      this.eChartInstanceMap.set(getIdContextKey(widgetId, contextId), new BehaviorSubject(undefined));
    }
  }
}
