import { Injectable } from '@angular/core';

import { Dashboard } from '@selfai-platform/bi-domain';

@Injectable()
export class DashboardService {
  private _dashboard: Dashboard;

  public setCurrentDashboard(board: Dashboard) {
    this._dashboard = board;
  }

  public getCurrentDashboard() {
    return this._dashboard;
  }
}
