import { Injectable } from '@angular/core';
import { IIdentityProvider } from './identity-provider.interface';

@Injectable({ providedIn: 'root' })
export class SelfaiAuthService {
  private identityProvider: IIdentityProvider;

  getProvider<T extends IIdentityProvider>(): T {
    return this.identityProvider as T;
  }

  setProvider(provider: IIdentityProvider) {
    this.identityProvider = provider;
  }
}
