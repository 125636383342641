export class AbstractHistoryEntity {
  public version?: number;
  public createdBy?: UserDetail;
  public createdTime?: Date | string;
  public modifiedBy?: UserDetail;
  public modifiedTime?: Date | string;
  public lastAccessTime?: Date | string;
}

export class UserDetail {
  public email: string;
  public fullName: string;
  public type: string;
  public username: string;
}
