import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { FormFieldComponentModule } from '../../../../form-field/form-field.module';
import { JsonPathFieldComponentModule } from '../../../../json-path/components/json-path-field/json-path-field.module';
import { ConstantValueFieldComponentModule } from '../../fields/constant-value-field/constant-value-field.module';
import { SpellValueFieldComponentModule } from '../../fields/spel-value-field/spel-value-field.module';
import { TypeFieldSwitchComponentModule } from '../../fields/type-field-switch/type-field-switch.module';
import { StepVariableComponent } from './step-variable.component';

@NgModule({
  imports: [
    DropdownModule,
    InputTextModule,
    ButtonModule,
    CommonModule,
    ReactiveFormsModule,
    JsonPathFieldComponentModule,
    SpellValueFieldComponentModule,
    ConstantValueFieldComponentModule,
    CardModule,
    FormFieldComponentModule,
    TypeFieldSwitchComponentModule,
  ],
  declarations: [StepVariableComponent],
  exports: [StepVariableComponent],
})
export class StepVariableComponentModule {}
