import { Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { BI_ROOT_ROUTE } from '@selfai-platform/shell';
import { BehaviorSubject } from 'rxjs';
import { DashboardConfig } from '../models/dashboard-config.model';

@Injectable({
  providedIn: 'root',
})
export class WorkbookActionsService {
  private activeMode$ = new BehaviorSubject<boolean>(false);
  private updateDashboardConfig$ = new BehaviorSubject<DashboardConfig>({
    view: false,
    startupCmd: { cmd: 'NONE' },
  });

  isActiveMode$ = this.activeMode$.asObservable();
  getUpdateDashboardConfig$ = this.updateDashboardConfig$.asObservable();

  constructor(private readonly router: Router) {}

  public setActiveMode(value: boolean) {
    this.activeMode$.next(value);
  }

  public setUpdateDashboardConfig(dashboardConfig: DashboardConfig) {
    if (!dashboardConfig.startupCmd) dashboardConfig.startupCmd = { cmd: 'NONE' };

    this.updateDashboardConfig$.next(dashboardConfig);
  }

  goToDashboardView(workbookId: string, dashboardId: string): Promise<boolean> {
    this.setUpdateDashboardConfig({ view: false });

    return this.router.navigate(['/', BI_ROOT_ROUTE, 'workbook', workbookId], { queryParams: { dashboardId } });
  }
}
