import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, switchMap, take } from 'rxjs';
import { ConfigService } from '../config';

@Injectable({ providedIn: 'root' })
export class AppInitService {
  private readonly appInitialazedSubject$ = new BehaviorSubject<boolean>(false);
  readonly appInitialiazed$ = this.appInitialazedSubject$.asObservable();

  private readonly identityProviderLoadedSubject$ = new BehaviorSubject<boolean>(false);
  readonly identityProviderLoaded$ = this.identityProviderLoadedSubject$.asObservable();

  private readonly pipelineLoadedSubject$ = new BehaviorSubject<boolean>(false);
  readonly pipelineLoaded$ = this.pipelineLoadedSubject$.asObservable();

  private readonly kdLoadedSubject$ = new BehaviorSubject<boolean>(false);
  readonly kdLoaded$ = this.pipelineLoadedSubject$.asObservable();

  constructor(private readonly configService: ConfigService) {
    this.checkLoaded();
  }

  private setAppInitialazed(): void {
    this.appInitialazedSubject$.next(true);
  }

  public setIdentityProviderLoaded(): void {
    this.identityProviderLoadedSubject$.next(true);
  }

  public setPipelineLoaded(): void {
    this.pipelineLoadedSubject$.next(true);
  }

  public setKdLoaded(): void {
    this.kdLoadedSubject$.next(true);
  }

  private checkLoaded(): void {
    this.configService.configLoaded$
      .pipe(
        filter(Boolean),
        switchMap(() => {
          return this.identityProviderLoadedSubject$;
        }),
        filter(Boolean),
        take(1),
      )
      .subscribe(() => {
        this.setAppInitialazed();
      });
  }
}
