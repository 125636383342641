import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { Button } from 'primeng/button';
import { FloatLabelModule } from 'primeng/floatlabel';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'selfai-platform-user-function-clone-dialog',
  templateUrl: './user-function-clone-dialog.component.html',
  styleUrls: ['./user-function-clone-dialog.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    InputTextModule,
    FormsModule,
    Button,
    FloatLabelModule,
  ],
  providers: [DialogService],
})
export class UserFunctionCloneDialogComponent implements OnInit {
  public newFunctionName: string;

  constructor(private readonly dialogConfig: DynamicDialogConfig, private readonly ref: DynamicDialogRef) {}

  public cloneFunction(): void {
    this.ref.close(this.newFunctionName);
  }

  public cancel(): void {
    this.ref.close();
  }

  public ngOnInit(): void {
    this.newFunctionName = 'copy_' + this.dialogConfig.data.name;
  }
}
