import { Injectable } from '@angular/core';
import { WidgetApiLink, WidgetApiNode } from '@selfai-platform/bi-api';
import {
  ChartColorList,
  DashboardDomainService,
  Filter,
  FontSize,
  GlobalActiveFiltersService,
  PivotField,
  UiChartDataLabelDisplayType,
  UiChartDataLabelSettings,
  UiChartGraphSettings,
  UiChartTooltipSettings,
  UiSettings,
} from '@selfai-platform/bi-domain';
import { GraphSeriesOption, GridComponentOption, TitleComponentOption, TooltipComponentOption } from 'echarts';
import { DatasetComponentOption, LegendComponentOption } from 'echarts/components';
import { ComposeOption } from 'echarts/core';
import { Observable, combineLatest, filter, map, take } from 'rxjs';
import { CHART_DEFAULT_FAMILY_FONT } from '../../../const';
import { mapGraphChartDataLabelPosition } from '../../../converters';
import { DataSettings, WidgetData, WidgetNode } from '../../../models';
import {
  ChartDataService,
  DataSettingsService,
  UiSettingsService,
  WidgetActiveFiltersService,
} from '../../../services';
import { formatGraphChartLabel, formatGraphChartTooltip, formatValue } from '../../../utils';

type EChartOption = ComposeOption<
  GraphSeriesOption | TitleComponentOption | TooltipComponentOption | GridComponentOption | DatasetComponentOption
>;

@Injectable({ providedIn: 'root' })
export class GraphChartConfigBuilderService {
  constructor(
    private readonly globalActiveFiltersService: GlobalActiveFiltersService,
    private readonly uiSettingsService: UiSettingsService,
    private readonly chartActiveFiltersService: WidgetActiveFiltersService,
    private readonly dataSettingsService: DataSettingsService,
    private readonly chartDataService: ChartDataService,
    private readonly dashboardDomainService: DashboardDomainService,
  ) {}

  buildConfig(widgetId: string, contextId?: string): Observable<EChartOption> {
    this.dashboardDomainService.loadDashboardByWidgetIdIfNotLoaded(widgetId, contextId).pipe(take(1)).subscribe();

    return combineLatest({
      chartActiveFilters: this.chartActiveFiltersService.getEffectiveFilters(widgetId, contextId),
      uiSettings: this.uiSettingsService.getSettings(widgetId, contextId).pipe(
        filter(Boolean),
        map((settings) => this.fillDefaultSettings(settings)),
      ),
      dataSettings: this.dataSettingsService.getSettings(widgetId, contextId).pipe(filter(Boolean)),
      widgetData: this.chartDataService.getWidgetData(widgetId, contextId).pipe(filter(Boolean)),
    }).pipe(
      map(({ chartActiveFilters, uiSettings, dataSettings, widgetData }) => {
        return this.mapToEChartOption({
          chartActiveFilters,
          uiSettings,
          dataSettings,
          widgetData,
        });
      }),
    );
  }

  private mapToEChartOption({
    chartActiveFilters,
    uiSettings,
    dataSettings,
    widgetData,
  }: {
    chartActiveFilters: Filter[];
    uiSettings: UiChartGraphSettings;
    dataSettings: DataSettings;
    widgetData: WidgetData;
  }): EChartOption {
    const nodes = widgetData.nodes.map((node) => ({
      ...node,
      name: node.name || 'null',
      originalName: node.originalName || 'null',
    }));

    return {
      type: 'graph',
      series: this.buildSeries({ dataSettings, links: widgetData.links, nodes, uiSettings }),
      legend: this.buildLegend({ nodes, uiSettings }),
      label: { show: true, position: 'top' },
      tooltip: { trigger: 'item' },
    };
  }

  private buildCategories({
    nodes,
    uiSettings,
  }: {
    nodes: WidgetApiNode[];
    uiSettings: UiChartGraphSettings;
  }): GraphSeriesOption['categories'] {
    return nodes.map((node, index) => {
      return {
        name: node.originalName,
        itemStyle: {
          color: this.getColorFromSchema(uiSettings, index),
        },
      };
    });
  }

  private buildSeries({
    dataSettings,
    links,
    nodes,
    uiSettings,
  }: {
    dataSettings: DataSettings;
    links: WidgetApiLink[];
    nodes: WidgetApiNode[];
    uiSettings: UiChartGraphSettings;
  }): GraphSeriesOption[] {
    return [
      {
        type: 'graph',
        layout: 'force',
        roam: true,
        data: nodes.map((node, index) => ({
          ...node,
          category: index,
        })),
        links: links.map((link) => ({
          ...link,
          value: Number(link.value),
        })),
        symbolSize: this.buildSymbolSize(dataSettings.pivot.aggregations[0]),
        force: {
          layoutAnimation: false,
          repulsion: links.length < 70 ? 100 : links.length * 1.5,
          edgeLength: links.length < 100 ? 150 : 40,
          initLayout: 'circular',
        },
        emphasis: {
          focus: 'adjacency',
          lineStyle: {
            width: 10,
          },
        },
        itemStyle: {
          color: (params) => {
            return this.getColorFromSchema(uiSettings, params.dataIndex);
          },
        },
        lineStyle: {
          curveness: 0.3,
          width: 1,
          color: '#aaa',
          opacity: 0.7,
        },
        label: {
          show:
            uiSettings.dataLabel.showValue &&
            uiSettings.dataLabel.displayTypes.includes(UiChartDataLabelDisplayType.NODE_NAME),
          position: mapGraphChartDataLabelPosition(uiSettings.dataLabel.position),
          formatter: (params) => formatGraphChartLabel(params as any, uiSettings),
          fontSize: this.mapFontSizeToPx(uiSettings.common.fontSize),
          fontFamily: CHART_DEFAULT_FAMILY_FONT,
          ...this.buildLabelColors(uiSettings.dataLabel),
        },
        animationDuration: 1500,
        animationEasingUpdate: 'quinticInOut',
        edgeSymbol: ['none', 'arrow'],
        edgeLabel: {
          show:
            uiSettings.dataLabel.showValue &&
            uiSettings.dataLabel.displayTypes.includes(UiChartDataLabelDisplayType.LINK_VALUE),
          formatter: (params) => {
            if (typeof params?.data === 'object') {
              return formatValue((params.data as WidgetNode).value, uiSettings.format);
            }

            return formatValue(params.data, uiSettings.format);
          },
          fontSize: this.mapFontSizeToPx(uiSettings.common.fontSize),
          fontFamily: CHART_DEFAULT_FAMILY_FONT,
        },
        tooltip: {
          trigger: 'item',
          formatter: (tooltipParams) => {
            return formatGraphChartTooltip({
              tooltipParams,
              format: uiSettings.format,
              tooltipSettings: uiSettings.tooltip,
              dataSettings,
            });
          },
          textStyle: {
            fontSize: this.mapFontSizeToPx(uiSettings.common.fontSize),
            fontFamily: CHART_DEFAULT_FAMILY_FONT,
          },
        },
        categories: this.buildCategories({ nodes, uiSettings }),
      },
    ];
  }

  private buildLegend({
    nodes,
    uiSettings,
  }: {
    nodes: WidgetApiNode[];
    uiSettings: UiChartGraphSettings;
  }): LegendComponentOption {
    return {
      show: uiSettings.showLegend,
      left: 'auto',
      right: 110,
      width: 'auto',
      itemGap: 20,
      type: 'scroll',
      orient: 'horizontal',
      textStyle: {
        fontSize: this.mapFontSizeToPx(uiSettings.common.fontSize),
        fontFamily: CHART_DEFAULT_FAMILY_FONT,
      },
      // TODO: toggle by event of `legendselectchanged`
      // selected: ,
      data: nodes.map((node) => node.originalName),
    };
  }

  private buildSymbolSize(firstAggregationField: PivotField): number | undefined {
    const linkField: string | undefined = firstAggregationField.alias
      ? firstAggregationField.alias
      : firstAggregationField.fieldAlias
      ? firstAggregationField.fieldAlias
      : firstAggregationField.name;

    return linkField ? 40 : undefined;
  }

  private fillDefaultSettings(uiSettings: UiSettings): UiChartGraphSettings {
    const tooltipSettings = uiSettings.tooltip || ({} as UiChartTooltipSettings);
    const defaultDisplayTypesSettings = [];
    defaultDisplayTypesSettings[9] = UiChartDataLabelDisplayType.NODE_NAME;
    defaultDisplayTypesSettings[10] = UiChartDataLabelDisplayType.LINK_VALUE;

    return {
      ...uiSettings,
      tooltip: {
        ...tooltipSettings,
        displayTypes: tooltipSettings.displayTypes || defaultDisplayTypesSettings,
      },
    } as UiChartGraphSettings;
  }

  private getColorSchema(uiSettings: UiChartGraphSettings): string[] {
    return (uiSettings.color?.schema && ChartColorList[uiSettings.color.schema]) || ChartColorList.SC1;
  }

  private getColorFromSchema(uiSettings: UiChartGraphSettings, index: number): string {
    return this.getColorSchema(uiSettings)[index % this.getColorSchema(uiSettings).length];
  }

  mapFontSizeToPx(fontSize: FontSize): number {
    switch (fontSize) {
      case FontSize.SMALL:
        return 12;
      case FontSize.NORMAL:
        return 14;
      case FontSize.LARGE:
        return 16;
      default:
        return 14;
    }
  }

  private buildLabelColors(uiDataLabelSettings: UiChartDataLabelSettings): GraphSeriesOption['label'] {
    return {
      color: uiDataLabelSettings.textStyle?.color,
      backgroundColor: uiDataLabelSettings.textStyle?.backgroundColor,
      textBorderColor: uiDataLabelSettings.textStyle?.borderColor,
      textBorderWidth: uiDataLabelSettings.textStyle?.borderColor ? 2 : undefined,
    };
  }
}
