export enum LogicalType {
  TEXT = 'STRING',
  STRING = 'STRING',
  INTEGER = 'INTEGER',
  FLOAT = 'DOUBLE',
  DOUBLE = 'DOUBLE',
  TIMESTAMP = 'TIMESTAMP',
  BOOLEAN = 'BOOLEAN',
  MAP = 'STRUCT',
  STRUCT = 'STRUCT',
  ARRAY = 'ARRAY',
  LNT = 'LNT',
  LNG = 'LNG',
  POSTAL_CODE = 'POSTAL_CODE',
  PHONE_NUMBER = 'PHONE_NUMBER',
  ETC = 'ETC',
  GEO_POINT = 'GEO_POINT',
  GEO_LINE = 'GEO_LINE',
  GEO_POLYGON = 'GEO_POLYGON',
  USER_DEFINED = 'user_defined',
}
