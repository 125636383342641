import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { DashboardApiService } from '@selfai-platform/bi-api';
import { Observable, map, tap } from 'rxjs';
import { WidgetApiToDomainService } from '../../widget';
import { WIDGET_DASHBOARD_ENTITY_NAME } from '../const';
import { Dashboard, DashboardState } from '../models';
import { normalizeDashboardApiModelToDashboard } from '../normalizers';

@Injectable({ providedIn: 'root' })
export class WidgetDashboardDataService extends DefaultDataService<DashboardState> {
  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private readonly dashboardApiService: DashboardApiService,
    private readonly widgetApiToDomainService: WidgetApiToDomainService,
  ) {
    super(WIDGET_DASHBOARD_ENTITY_NAME, http, httpUrlGenerator);
  }

  override getById(widgetId: string): Observable<Dashboard> {
    return this.dashboardApiService.getDashboardByWidgetId(widgetId).pipe(
      tap((dashboard) => {
        this.widgetApiToDomainService.initDasboardWidgets(dashboard.widgets);
      }),
      map(normalizeDashboardApiModelToDashboard),
      map((dashboard) => ({ ...dashboard, widgetId })),
    );
  }
}
