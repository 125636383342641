import { ShelfType } from '../../../../chart';
import { GeoField } from '../field';

export interface Shelf {
  type: ShelfType;
  layers: ShelfLayers[];
}

export function createShelf(params: Partial<Shelf> = {}): Shelf {
  return {
    type: ShelfType.GEO,
    layers: [createShelfLayers()],
    ...params,
  };
}

export interface ShelfLayers {
  name: string;
  ref: string;
  view?: any;
  fields: GeoField[];
}

export function createShelfLayers(params: Partial<ShelfLayers> = {}): ShelfLayers {
  return {
    name: '',
    ref: '',
    fields: [],
    ...params,
  };
}
