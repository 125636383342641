export enum AggregationType {
  SUM = 'SUM',
  AVG = 'AVG',
  COUNT = 'COUNT',
  MIN = 'MIN',
  MAX = 'MAX',
  PERCENTILE = 'PERCENTILE',

  NONE = 'NONE',

  STDDEV = 'STDDEV',
  MEDIAN = 'MEDIAN',
  AREA = 'AREA',
  RANGE = 'RANGE',
  QUANTILE = 'QUANTILE',

  SLOPE = 'SLOPE',

  VARIATION = 'VARIATION',
  APPROX = 'APPROX',

  COMPLEX = 'COMPLEX',
}
