import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { EditSelectionFieldComponentModule } from '../../../edit-selection-field/edit-selection-field.module';
import { FormFieldComponentModule } from '../../../form-field/form-field.module';
import { LoggingComponent } from './logging.component';

@NgModule({
  imports: [
    CommonModule,
    EditSelectionFieldComponentModule,
    FormFieldComponentModule,
    CheckboxModule,
    ReactiveFormsModule,
  ],
  declarations: [LoggingComponent],
  exports: [LoggingComponent],
})
export class LoggingComponentModule {}
