import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityDataService } from '@ngrx/data';
import { WidgetDataSettingsDataService } from '@selfai-platform/bi-api';
import { WIDGET_DATA_SETTINGS_ENTITY_NAME } from '../const';
import { DataSettingsState } from '../models';

@Injectable({ providedIn: 'root' })
export class WidgetDataSettingsStore extends EntityCollectionServiceBase<DataSettingsState> {
  constructor(
    entityDataService: EntityDataService,
    widgetDataSettingsDataService: WidgetDataSettingsDataService,
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    entityDataService.registerService(WIDGET_DATA_SETTINGS_ENTITY_NAME, widgetDataSettingsDataService);

    super(WIDGET_DATA_SETTINGS_ENTITY_NAME, serviceElementsFactory);
  }
}
