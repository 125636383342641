import { Injectable, Optional } from '@angular/core';
import {
  CHART_STRING_DELIMITER,
  ChartColorList,
  ChartColorType,
  ChartPivotType,
  PivotTableInfo,
  UIChartColorByDimension,
  UIChartColorBySeries,
} from '@selfai-platform/bi-domain';
import { LegendComponentOption } from 'echarts';
import _, { merge } from 'lodash';
import { LegendOptionConverter } from '../converters';
import { ChartOptionsService } from './chart-options.service';

@Injectable()
export class ChartLegendService {
  // TODO: Temprory Optional have to inject ChartOptionsService on all chart components
  constructor(@Optional() protected chartOptionsService: ChartOptionsService) {}

  setLegendOptions(fieldInfo: PivotTableInfo, pivotInfo: PivotTableInfo, fieldOriginInfo: PivotTableInfo): void {
    if (!this.chartOptionsService) {
      return;
    }

    const uiOption = this.chartOptionsService.getUiOptions();
    const chartOption = this.chartOptionsService.getEChartOptions();

    if (uiOption.color?.type == ChartColorType.SERIES) {
      (chartOption.legend as LegendComponentOption).data = fieldInfo.aggs;

      const schema = (<UIChartColorBySeries>uiOption.color).schema;
      const colorCodes = _.cloneDeep(ChartColorList[schema as keyof typeof ChartColorList]);

      const colorMapping = (<UIChartColorBySeries>uiOption.color).mapping;

      if (colorMapping) {
        Object.values(colorMapping).forEach((value: string, index: number) => {
          colorCodes[index] = value;
        });
      }
      chartOption.legend = merge(chartOption.legend as LegendComponentOption, {
        lineStyle: { color: colorCodes[0] },
      });
    } else {
      if (chartOption.legend) {
        let legendData: string[] = [];

        let fieldIdx: number;

        let pivotType: ChartPivotType | null = null;

        _.forEach(fieldInfo, (value, key) => {
          if (_.indexOf(value, uiOption.color['targetField']) > -1) {
            fieldIdx = _.indexOf(value, uiOption.color['targetField']);
            pivotType = _.eq(key, ChartPivotType.COLS)
              ? ChartPivotType.COLS
              : _.eq(key, ChartPivotType.ROWS)
                ? ChartPivotType.ROWS
                : ChartPivotType.AGGS;
          }
        });

        if (pivotType && fieldInfo[pivotType as ChartPivotType].length > 1) {
          legendData = pivotInfo[pivotType as keyof PivotTableInfo].map((value) => {
            return !_.split(value, CHART_STRING_DELIMITER)[fieldIdx]
              ? value
              : _.split(value, CHART_STRING_DELIMITER)[fieldIdx];
          });

          legendData = _.uniq(legendData);
        } else {
          legendData = pivotInfo[pivotType as keyof PivotTableInfo];
        }

        (chartOption.legend as LegendComponentOption).data = legendData;
        chartOption.legend = merge(chartOption.legend as LegendComponentOption, {
          lineStyle: {
            color: ChartColorList[(uiOption.color as UIChartColorByDimension).schema as keyof typeof ChartColorList],
          },
        });
      }
    }

    this.chartOptionsService.setEChartOptions(LegendOptionConverter.convertLegend(chartOption, uiOption));
  }
}
