import _ from 'lodash';

import {
  ConnectionType,
  Datasource,
  DatasourceField,
  FieldNameAlias,
  FieldRole,
  FieldValueAlias,
} from '../../../datasource';
import { JoinMapping } from '../../../workbook';
import { BoardDataSource, Dashboard } from '../../models';
import { getDataSourceFromBoardDataSource } from './get-datasource-from-board-datasource';
import { setFieldAttribute } from './set-field-attribute';

export function setSingleDataSource(
  dataSource: BoardDataSource,
  boardInfo: Dashboard,
  nameAliasList: FieldNameAlias[],
  valueAliasList: FieldValueAlias[],
): { reorderDsList: Datasource[]; totalFields: DatasourceField[] } {
  let totalFields: DatasourceField[] = [];
  const reorderDsList: Datasource[] = [];

  const masterDsInfo: Datasource | null = getDataSourceFromBoardDataSource(boardInfo, dataSource);

  if (!masterDsInfo) {
    return { reorderDsList: [], totalFields: [] };
  }

  dataSource = _.merge(dataSource, {
    name: masterDsInfo.name,
  });

  if (ConnectionType.ENGINE === masterDsInfo.connType) {
    dataSource = _.merge(dataSource, {
      connType: masterDsInfo.connType,
      engineName: masterDsInfo.engineName,
    });
  } else if (ConnectionType.LINK === masterDsInfo.connType && dataSource.metaDataSource) {
    dataSource = _.merge(dataSource, {
      connType: dataSource.metaDataSource.connType,
      engineName: dataSource.metaDataSource.engineName,
    });
  }
  reorderDsList.push(masterDsInfo);

  const masterFields: DatasourceField[] = masterDsInfo.fields.map((fieldItem: DatasourceField) => {
    return setFieldAttribute(fieldItem, masterDsInfo, masterDsInfo, boardInfo, nameAliasList, valueAliasList);
  });
  if (dataSource.joins && 0 < dataSource.joins.length) {
    masterDsInfo.fields.forEach((fieldItem: DatasourceField) => (fieldItem.ref = masterDsInfo.engineName));
  }
  totalFields = totalFields.concat(masterFields);

  const objJoinValueKey: any = {};
  if (dataSource.joins && 0 < dataSource.joins.length) {
    dataSource.joins.forEach((joinItem: JoinMapping) => {
      {
        const oneDepthDsInfo: Datasource | undefined = boardInfo.dataSources.find(
          (dsItem) => dsItem.id === joinItem.id,
        );

        if (oneDepthDsInfo) {
          joinItem = _.merge(joinItem, { engineName: oneDepthDsInfo.engineName });

          reorderDsList.push(oneDepthDsInfo);

          objJoinValueKey[joinItem.id] = Object.keys(joinItem.keyPair).map(
            (keyItem: string) => joinItem.keyPair[keyItem],
          );

          totalFields = totalFields.concat(
            oneDepthDsInfo.fields
              .map((fieldItem) => {
                if (
                  fieldItem.role !== FieldRole.TIMESTAMP &&
                  fieldItem.name !== 'count' &&
                  -1 === objJoinValueKey[joinItem.id].indexOf(fieldItem.name)
                ) {
                  fieldItem = setFieldAttribute(fieldItem, masterDsInfo, oneDepthDsInfo);
                  fieldItem.ref = joinItem.joinAlias || joinItem.engineName;
                  fieldItem.join = joinItem;

                  return fieldItem;
                } else {
                  return null as any;
                }
              })
              .filter((fieldItem) => fieldItem !== null),
          );
        }
      }

      const secondLevelJoin = joinItem.join;

      if (secondLevelJoin) {
        const twoDepthDsInfo: Datasource | undefined = boardInfo.dataSources.find(
          (dsItem) => dsItem.id === secondLevelJoin.id,
        );

        if (twoDepthDsInfo) {
          joinItem.join = _.merge(secondLevelJoin, { engineName: twoDepthDsInfo.engineName });

          reorderDsList.push(twoDepthDsInfo);

          objJoinValueKey[secondLevelJoin.id] = Object.keys(secondLevelJoin.keyPair).map(
            (keyItem: string) => secondLevelJoin.keyPair[keyItem],
          );

          totalFields = totalFields.concat(
            twoDepthDsInfo.fields
              .map((fieldItem) => {
                if (
                  fieldItem.role !== FieldRole.TIMESTAMP &&
                  fieldItem.name !== 'count' &&
                  -1 === objJoinValueKey[secondLevelJoin.id].indexOf(fieldItem.name)
                ) {
                  fieldItem = setFieldAttribute(fieldItem, masterDsInfo, twoDepthDsInfo);
                  fieldItem.ref = joinItem.joinAlias + '.' + secondLevelJoin.engineName;

                  return fieldItem;
                } else {
                  return null as any;
                }
              })
              .filter((fieldItem) => fieldItem !== null),
          );
        }
      }
    });
  }

  const joins = dataSource.joins;
  if (joins && joins.length > 0) {
    dataSource.type = 'mapping';
  }

  return {
    reorderDsList: reorderDsList,
    totalFields: totalFields,
  };
}
