import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';

import { Dashboard, DatasourceField as Field, Filter, InclusionFilter } from '@selfai-platform/bi-domain';

import { AbstractComponent } from '../../common/component/abstract.component';
import { FilterUtil } from '../../dashboard/util/filter.util';
import { DatasourceService } from '../../datasource/service/datasource.service';

@Component({
  selector: 'page-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.scss'],
})
export class PageFilterPanelComponent extends AbstractComponent implements OnInit, OnChanges, OnDestroy {
  public filterUtil = FilterUtil;

  public boardFilters: Filter[] = [];
  public chartFilters: Filter[] = [];
  public isOpenBoardFilterList = false;

  @Input('boardFilters')
  public inputBoardFilters: Filter[];

  @Input('chartFilters')
  public inputChartFilters: Filter[];

  @Input()
  public dashboard: Dashboard;

  @Output()
  public openUpdateFilterPopup = new EventEmitter();

  @Output('updateFilter')
  public updateFilterEvent: EventEmitter<Filter> = new EventEmitter();

  @Output('deleteFilter')
  public deleteFilterEvent: EventEmitter<Filter> = new EventEmitter();

  @Output('changeGlobalToChart')
  public changeGlobalToChartEvent: EventEmitter<Filter> = new EventEmitter();

  constructor(
    private datasourceService: DatasourceService,
    protected elementRef: ElementRef,
    protected injector: Injector,
  ) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnChanges(changes: SimpleChanges) {
    const chartFilterChanges: SimpleChange = changes.inputChartFilters;
    const boardFilterChanges: SimpleChange = changes.inputBoardFilters;
    if (chartFilterChanges) {
      this.chartFilters = chartFilterChanges.currentValue;
    }
    if (boardFilterChanges && this.dashboard) {
      this.boardFilters = FilterUtil.getPanelContentsList(
        boardFilterChanges.currentValue,
        this.dashboard,
        (filter: InclusionFilter, field: Field) => {
          this._setInclusionFilter(filter, field);
        },
      );
    }
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public setFilters(boardFilters: Filter[], chartFilters: Filter[]) {
    this.boardFilters = [];
    this.chartFilters = [];
    this.safelyDetectChanges();

    this.chartFilters = chartFilters;
    this.boardFilters = FilterUtil.getPanelContentsList(
      boardFilters,
      this.dashboard,
      (filter: InclusionFilter, field: Field) => {
        this._setInclusionFilter(filter, field);
      },
    );
    this.safelyDetectChanges();
  }

  public trackByFn(index, filter: Filter) {
    return filter.field;
  }

  private _setInclusionFilter(filter: InclusionFilter, field: Field) {
    this.pageLoaderService.show();
    this.datasourceService
      .getCandidateForFilter(filter, this.dashboard, [], field)
      .then((result) => {
        const valueList: string[] = filter.valueList;
        if (valueList && 0 < valueList.length && valueList.length !== result.length) {
          filter['panelContents'] = valueList.join(' , ');
        } else {
          filter['panelContents'] = '(' + this.translateService.instant('msg.comm.ui.list.all') + ')';
        }

        this.safelyDetectChanges();

        this.pageLoaderService.hide();
      })
      .catch((error) => console.error(error));
  }
}
