import { Pivot, PivotField } from '@selfai-platform/bi-domain';

export function pivotNormalizer(source: Pivot): Pivot {
  return {
    columns: source.columns.map((col) => ({
      ...col,
      alias: getAlias(col),
    })),
    rows: source.rows.map((row) => ({
      ...row,
      alias: getAlias(row),
    })),
    aggregations: source.aggregations.map((aggregation) => ({
      ...aggregation,
      alias: getAlias(aggregation),
    })),
  };
}

function getAlias(field: PivotField): string {
  const baseName = field['name'];
  let alias = field['pivotAlias'] || field['fieldAlias'] || baseName;

  const modifier = field.aggregationType || field.format?.unit;
  if (modifier) {
    alias = `${modifier}(${alias})`;
  }

  return alias;
}
