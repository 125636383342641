<ng-container *ngIf="!dataIsEmpty; else workflowEmptyData">
  <p-card class="mb-3 block field-columm-wrapper" [formGroup]="form">
    <div class="field-columm-wrapper">
      <div class="field-columm">
        <selfai-platform-form-field label="Source column with JSON string">
          <selfai-platform-edit-selection-field formControlName="sourceColumn"></selfai-platform-edit-selection-field>
        </selfai-platform-form-field>

        <selfai-platform-form-field label="Just type transformation">
          <p-checkbox name="input" [binary]="true" formControlName="justTypeTransformation"></p-checkbox>
        </selfai-platform-form-field>
      </div>
      <div class="field-columm">
        <selfai-platform-form-field label="Datetime format">
          <input
            class="w-20rem"
            type="text"
            formControlName="dateTimeFormat"
            pInputText
            placeholder="yyyy-MM-ddTHH:mm:ss.SSSS"
          />
        </selfai-platform-form-field>

        <selfai-platform-form-field label="Date format">
          <input class="w-20rem" type="text" formControlName="dateFormat" pInputText placeholder="yyyy-MM-dd" />
        </selfai-platform-form-field>
      </div>
    </div>
  </p-card>
  <p-table
    class="table"
    dataKey="controls.id.value"
    #tableRef
    responsiveLayout="scroll"
    [value]="items"
    (onRowReorder)="items = tableRef.value"
    [globalFilterFields]="['controls.columnName.value', 'controls.jsonPath.value']"
  >
    <ng-template pTemplate="caption">
      <div class="flex">
        <span class="p-input-icon-left">
          <i class="pi pi-search"></i>
          <input pInputText type="text" (input)="filter($event)" placeholder="Search" />
        </span>
      </div>
    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 3rem"></th>
        <th style="width: 55%">Json path to column value</th>
        <th>Column DataType</th>
        <th>Column name</th>
        <th>Actions</th>
        <th>Used</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item let-index="rowIndex">
      <tr [formGroup]="item" [pReorderableRow]="index">
        <td>
          <span class="pi pi-bars" pReorderableRowHandle></span>
        </td>
        <td>
          <selfai-platform-json-path-field formControlName="jsonPath"></selfai-platform-json-path-field>
        </td>
        <td>
          <p-dropdown
            appendTo="body"
            [options]="dataTypeItems"
            formControlName="dataType"
            placeholder="Choose data type"
          ></p-dropdown>
        </td>
        <td>
          <input
            type="text"
            pInputText
            class="p-inputtext-sm w-full"
            formControlName="columnName"
            placeholder="Optional, if different from the column name"
          />
        </td>
        <td>
          <button
            type="button"
            pButton
            class="p-button-text p-button-link p-button-danger p-0"
            appearance="icon"
            (click)="removeItem(item.controls.id.value)"
            icon="pi pi-trash"
            pTooltip="Remove"
          ></button>
          <button
            type="button"
            pButton
            class="p-button-text p-button-link p-0"
            appearance="icon"
            (click)="copyItem(item.controls.id.value)"
            icon="pi pi-copy"
            pTooltip="Copy"
          ></button>
        </td>
        <td>
          <selfai-platform-used-in-panel
            [usedInNodes]="item.controls.columnName.value | selfaiPlatformUsedIn: nodeId | async"
          ></selfai-platform-used-in-panel>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="5">No data</td>
      </tr>
    </ng-template>
  </p-table>

  <button
    label="add group"
    pButton
    pRipple
    icon="pi pi-plus"
    type="button"
    class="mr-3 mt-3 p-button-sm"
    (click)="addItem()"
  ></button>
</ng-container>

<ng-template #workflowEmptyData>
  <p-messages severity="error">
    <ng-template pTemplate>Data of workflow isn't available yet. Try again later</ng-template>
  </p-messages>
</ng-template>
