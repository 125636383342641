import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MainMenuComponent } from './main-menu.component';
import { RecycleMenuComponent } from './recycle-menu/recycle-menu.component';
import { RouteIsActivePipe } from './route-is-active.pipe';

@NgModule({
  imports: [CommonModule, RouterModule, RouteIsActivePipe, TranslateModule],
  declarations: [MainMenuComponent, RecycleMenuComponent],
  exports: [MainMenuComponent],
})
export class MainMenuComponentModule {}
