<div class="pt-4" [formGroup]="form">
  <ng-container>
    <div class="p-float-label mb-5">
      <input class="w-full" pInputText [formControl]="paramsForm.controls.endpoint" />
      <label>Endpoint</label>
    </div>
    <div class="p-float-label mb-5">
      <input class="w-full" pInputText [formControl]="paramsForm.controls.accessKey" />
      <label>Access Key</label>
    </div>
    <div class="p-float-label mb-5">
      <input class="w-full" pInputText [formControl]="paramsForm.controls.secretKey" />
      <label>Secret Key</label>
    </div>
    <div class="p-float-label mb-5">
      <input class="w-full" pInputText [formControl]="paramsForm.controls.bucket" />
      <label>Bucket</label>
    </div>
    <div class="mb-5">
      <label class="cursor-pointer">
        <p-checkbox class="mr-2" [formControl]="paramsForm.controls.withoutSSL" [binary]="true"></p-checkbox>
        Disable SSL requirement
      </label>
    </div>
    <div class="p-float-label mb-5">
      <input class="w-full" pInputText [formControl]="paramsForm.controls.pattern" />
      <label>Pattern</label>
    </div>
  </ng-container>

  <div class="mb-5">
    <selfai-platform-data-source-visibility
      [control]="form.controls.visibility"
    ></selfai-platform-data-source-visibility>
  </div>
  <div class="mb-5">
    <selfai-platform-data-source-unique-name [control]="form.controls.name"></selfai-platform-data-source-unique-name>
  </div>

  <selfai-platform-data-sources-additionals
    [properties]="dataSource?.params?.properties"
    [additionalList]="additionalList"
    (changeEvent)="onPropertiesValue($event)"
  ></selfai-platform-data-sources-additionals>
</div>
