import {
  Condition,
  ConditionItem,
  ConditionKeyLegacy,
  ConditionLegacy,
  ConditionLegacyValue,
  CreateTriggerData,
  GraphNode,
  MultiplierParameter,
  Parameter,
  SelectionColumnItem,
  SelectorParameter,
  SelectorParameterField,
  SingleColumnSelectorItem,
  TriggerRule,
  WorkflowTriggerData,
  WorkflowTriggerDataCondition,
} from '@selfai-platform/pipeline-common';
import { v4 as uuidv4 } from 'uuid';

export function normalizeDataCreateTrigger(data: WorkflowTriggerData): CreateTriggerData {
  return {
    resultColumn: data['Result column'] || '',
    debugMode: data['Debug mode'] || false,
    conditions: data['Conditions'].map(normalizeConditionData),
  };
}

function normalizeConditionData(workflowConditionData: WorkflowTriggerDataCondition): TriggerRule {
  const conditionLegacyKey = workflowConditionData['Condition']
    ? (Object.keys(workflowConditionData['Condition'])[0] as keyof typeof ConditionLegacy)
    : undefined;
  const condition: Condition | null = conditionLegacyKey ? (ConditionLegacy[conditionLegacyKey] as Condition) : null;

  return {
    attrColumnName: (workflowConditionData['Attribute column name']?.value as string) || '',
    condition,
    id: uuidv4(),
    primary: workflowConditionData['Is primary condition'] || false,
    resultColumn: workflowConditionData['Result column (Optional)'] || '',
    ...normalizeCondition(
      condition,
      conditionLegacyKey ? workflowConditionData['Condition']?.[conditionLegacyKey] || null : null,
    ),
  };
}

function normalizeCondition(
  condition: Condition | null,
  value: ConditionLegacyValue | null,
): Omit<ConditionItem, 'condition'> {
  if (!value) {
    return { value: null };
  }
  switch (condition) {
    case Condition.BEGINS_FROM:
    case Condition.NOT_BEGINS_FROM:
      return {
        startPosition: value['Start positions'] ? Number(value['Start positions']) : null,
        value: value['Value collection'] || null,
      };

    case Condition.ENDS_WITH:
    case Condition.NOT_ENDS_WITH:
      return {
        value: value['Value collection'] || null,
      };

    case Condition.EQUALS_TO:
    case Condition.NOT_EQUALS_TO:
      return {
        ignoreCase: !value['Case sensitive'],
        value: value['Value collection'] || null,
      };

    case Condition.CONTAINS:
    case Condition.LESS_THAN:
    case Condition.MORE_THAN:
    case Condition.LESS_EQUALS_THAN:
    case Condition.MORE_EQUALS_THAN:
      return {
        value: value['Compare value'] || null,
      };

    case Condition.REGEXP:
      return {
        value: value['RegExp pattern'] || null,
      };

    case Condition.EXPRESSION:
      return {
        value: value['SQL expression'] || null,
      };

    case Condition.BETWEEN:
      return {
        value: null,
        startRange: value['Start of range'] || null,
        includeStartRange: value['Include start of range'] || null,
        endRange: value['End of range'] || null,
        includeEndRange: value['Include end of range'] || null,
      };

    default:
      return {
        value: null,
      };
  }
}

export function normalizeToLegacyDataCreateTrigger(data: CreateTriggerData): WorkflowTriggerData {
  return {
    'Result column': data.resultColumn,
    'Debug mode': data.debugMode,
    Conditions: data.conditions.map(normalizeToLegacyItem),
  };
}

function normalizeToLegacyItem(conditionItem: TriggerRule): WorkflowTriggerDataCondition {
  const conditionKeyLegacy: ConditionKeyLegacy | undefined = Object.entries(ConditionLegacy).find(
    ([, value]) => value === conditionItem.condition,
  )?.[0] as ConditionKeyLegacy;

  let conditions: ConditionLegacyValue | null = null;

  if (conditionKeyLegacy) {
    switch (conditionItem.condition) {
      case Condition.BEGINS_FROM:
      case Condition.NOT_BEGINS_FROM:
        conditions = {
          'Start positions': conditionItem.startPosition ? conditionItem.startPosition.toString() : undefined,
          'Value collection': conditionItem.value || undefined,
        };
        break;

      case Condition.ENDS_WITH:
      case Condition.NOT_ENDS_WITH:
        conditions = {
          'Value collection': conditionItem.value || undefined,
        };
        break;

      case Condition.EQUALS_TO:
      case Condition.NOT_EQUALS_TO:
        conditions = {
          'Case sensitive': !conditionItem.ignoreCase,
          'Value collection': conditionItem.value || undefined,
        };
        break;
      case Condition.CONTAINS:
      case Condition.LESS_THAN:
      case Condition.MORE_THAN:
      case Condition.LESS_EQUALS_THAN:
      case Condition.MORE_EQUALS_THAN:
        conditions = {
          'Compare value': conditionItem.value || undefined,
        };

        break;

      case Condition.REGEXP:
        conditions = {
          'RegExp pattern': conditionItem.value || undefined,
        };
        break;

      case Condition.EXPRESSION:
        conditions = {
          'SQL expression': conditionItem.value || undefined,
        };
        break;

      case Condition.BETWEEN:
        conditions = {
          'Start of range': conditionItem.startRange || undefined,
          'Include start of range': conditionItem.includeStartRange || undefined,
          'End of range': conditionItem.endRange || undefined,
          'Include end of range': conditionItem.includeEndRange || undefined,
        };
        break;

      default:
        conditions = {};
        break;
    }
  }

  return {
    'Attribute column name': {
      type: 'column',
      value: conditionItem.attrColumnName,
    },
    'Is primary condition': conditionItem.primary,
    'Result column (Optional)': conditionItem.resultColumn,
    Condition:
      conditions && conditionKeyLegacy
        ? {
            [conditionKeyLegacy]: conditions,
          }
        : null,
  };
}

export function extractSelectionItemsCreateTrigger(
  node: GraphNode<WorkflowTriggerData>
): SelectionColumnItem[] | undefined {
  if (!node?.['parameters']) {
    return undefined;
  }

  const conditionData = findConditionsData(node['parameters'].parameters);
  if (conditionData?.parametersLists[0]) {
    const attributeColumnNameData = findAttributeColumnNameData(conditionData.parametersLists[0].parameters);

    if (attributeColumnNameData) {
      return attributeColumnNameData.dataFrameSchema?.fields.map(({ name }, index) => ({ name, index }));
    }
  } else {
    const params = (conditionData as MultiplierParameter & { emptyItem: { parameters: Parameter[] } })?.['emptyItem']
      .parameters;
    const emptyAttributeColumnNameData = findAttributeColumnNameData(params as Parameter[]);
    setDataFrameSchema(emptyAttributeColumnNameData as SelectorParameter, node);
    return emptyAttributeColumnNameData.dataFrameSchema?.fields.map(({ name }, index) => ({ name, index }));
  }

  return undefined;
}

export function setDataFrameSchema(attribute: SelectorParameter, node: GraphNode<WorkflowTriggerData>) {
  attribute.dataFrameSchema = undefined;
  let dataFrameInputPort;
  if (attribute.isDynamic) {
    dataFrameInputPort = 1;
  } else {
    dataFrameInputPort = attribute.schema.portIndex;
  }
  const inputKnowledge = node.getIncomingKnowledge(dataFrameInputPort);
  if (inputKnowledge) {
    const inferredResultDetails = inputKnowledge.result as { schema: {
      fields: SelectorParameterField[];
      items: [SingleColumnSelectorItem];
    }};
    if (inferredResultDetails) {
      attribute.dataFrameSchema = inferredResultDetails.schema;
    }
  }
}

function findConditionsData(parameters: Parameter[]): MultiplierParameter | undefined {
  return parameters.find(isConditionsData);
}

function findAttributeColumnNameData(parameters: Parameter[]): SelectorParameter | undefined {
  return parameters.find(isAttributeColumnNameData);
}

function isConditionsData(parameter: Parameter): parameter is MultiplierParameter {
  return parameter.name === 'Conditions';
}

function isAttributeColumnNameData(parameter: Parameter): parameter is SelectorParameter {
  return parameter.name === 'Attribute column name';
}
