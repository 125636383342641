export const PATH_RECYCLEBIN_ADMIN = 'recyclebin-admin';
export const PATH_RECYCLEBIN_USER = 'recyclebin-user';
export const PATH_MAIN_DASHBOARD = 'main-dashboard';
export const KNOWLEDGE_DESIGNER_ROOT_ROUTE = 'kd';
export const BI_ROOT_ROUTE = 'bi';
export const GUEST_WORKSPACE_ROUTE = 'ws-10';
export const KE_ROOT_ROUTE = 'pipeline';
export const KE_DATASOURCES_PATH = 'datasources';
export const KE_WORKFLOW_PATH = 'workflow';
export const KE_PRESETS_PATH = 'presets';
export const KE_USER_FUNCTIONS_PATH = 'functions';
export const KE_SCHEDULES_PATH = 'scheduling';
