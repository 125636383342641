import { GranularityType } from '../../../../datasource';
import { DashobardFieldType } from '../../enums/field-type.enum';
import { DashboardField, createDashboardField } from './field.model';

export interface TimestampField extends DashboardField {
  granularity?: GranularityType;
  timeExprUnit?: GranularityType;
  timeZone?: string;
  locale?: string;
}

export function createTimestampField(params: Partial<TimestampField> = {}): TimestampField {
  return {
    ...createDashboardField(),
    type: DashobardFieldType.TIMESTAMP,
    ...params,
  };
}

export enum TimeUnit {
  SECOND = 'SECOND',
  MINUTE = 'MINUTE',
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
  NONE = 'NONE',
}

export enum ByTimeUnit {
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR',
}

export enum FilteringType {
  LIST = 'LIST',
  RANGE = 'RANGE',
}
