import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogService } from '../dialog';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { ErrorOptions } from './error-options';

@Injectable()
export class ErrorService {
  constructor(private readonly dialogService: DialogService<void, ErrorOptions>) {}

  showError(title: string, data: ErrorOptions): Observable<void> {
    return this.dialogService.showDialog(ErrorDialogComponent, {
      closable: false,
      dismissableMask: false,
      closeOnEscape: false,
      header: title,
      width: '70%',
      data: data,
    });
  }
}
