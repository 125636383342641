import { Injectable } from '@angular/core';
import {
  NoneIdentityProviderService,
  PermissionService,
  SelfaiAuthService,
  getRolesForNoneProvider,
} from '@selfai-platform/shared';

@Injectable({
  providedIn: 'root',
})
export class WidgetNoneIdentityProviderService extends NoneIdentityProviderService {
  private authorizationToken = '';

  constructor(selfaiAuthService: SelfaiAuthService, permissionService: PermissionService) {
    super();

    const noneProviderRoles = getRolesForNoneProvider();
    selfaiAuthService.setProvider(this);
    permissionService.setRoles(noneProviderRoles);
  }

  setToken(token: string): void {
    this.authorizationToken = token;
  }

  getToken(): Promise<string> {
    return Promise.resolve(this.authorizationToken);
  }
}
