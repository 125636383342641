import { createSelector } from '@ngrx/store';
import { UiSettingsEntity } from '../../models';
import * as fromUiSettings from '../reducers/ui-settings.reducer';
import { biChartEngineFeatureSelector } from './bi-chart-engine-feature.selector';

export const selectUiSettingsState = createSelector(biChartEngineFeatureSelector, (state) => state.uiSettings);

export const selectWidgetIds = createSelector(selectUiSettingsState, fromUiSettings.selectWidgetIds);
export const selectUiSettingsEntities = createSelector(selectUiSettingsState, fromUiSettings.selectUiSettingsEntities);
export const selectAllWidgetsUiSettings = createSelector(
  selectUiSettingsState,
  fromUiSettings.selectAllWidgetsUiSettings,
);

export const selectUiSettingsEntity = (widgetId: string) =>
  createSelector(selectUiSettingsEntities, (uiSettingsEntities): UiSettingsEntity | undefined =>
    widgetId ? uiSettingsEntities[widgetId] : undefined,
  );
