import { normalizeApiEndpoint } from '@selfai-platform/shared';
import { AggrtConfigService } from './aggrt-config.service';

export abstract class AggregatorCoreApiService {
  private get apiUrl(): string {
    const { host } = this.aggrtConfigService.getConfig();

    return host.endsWith('/') ? host.slice(-1) : host;
  }

  constructor(private readonly aggrtConfigService: AggrtConfigService) {}

  protected buildUrl(endpoint: string): string {
    return `${this.apiUrl}/${normalizeApiEndpoint(endpoint)}`;
  }
}
