import { LayoutMode, Dashboard } from '@selfai-platform/bi-domain';

export function setBoardPropertiesByMode(mode: LayoutMode, board: Dashboard) {
  switch (mode) {
    case LayoutMode.EDIT:
      board.configuration.layout.settings.hasHeaders = true;
      board.configuration.layout.settings.showMaximiseIcon = false;
      board.configuration.layout.settings.constrainDragToContainer = true;
      board.configuration.layout.settings.reorderEnabled = true;
      board.configuration.layout.settings.enableComponentResize = true;
      break;
    default:
      board.configuration.layout.settings.hasHeaders = true;
      board.configuration.layout.settings.showMaximiseIcon = false;
      board.configuration.layout.settings.constrainDragToContainer = true;
      board.configuration.layout.settings.reorderEnabled = true;
      board.configuration.layout.settings.enableComponentResize = true;
      break;
  }

  board.configuration.layout.dimensions.borderWidth = board.configuration.options.layout.widgetPadding;
}
