<div *ngIf="'CHANGE' === mode" class="ddp-option">
  <span class="ddp-data-num">
    <strong>{{ totalItemCnt }}</strong>
    default value
  </span>
  <div *ngIf="targetFilter" class="ddp-option-btn">
    <div [class.ddp-selected]="targetFilter['isShowCandidateSort']" class="ddp-ui-sort ddp-btn-box">
      <a
        (click)="targetFilter['isShowCandidateSort'] = !targetFilter['isShowCandidateSort']"
        href="javascript:"
        class="ddp-icon-sort"
      ></a>

      <div class="ddp-ui-tooltip-info">
        <em class="ddp-icon-view-top"></em>
        {{ 'msg.comm.ui.soring.order' | translate }}
      </div>

      <div class="ddp-wrap-popup2">
        <ul class="ddp-list-popup">
          <li [class.ddp-selected]="targetFilter['sortTarget'] === 'FREQUENCY' && targetFilter['sortType'] === 'ASC'">
            <a href="javascript:" (click)="sortCandidateValues(targetFilter, 'FREQUENCY', 'ASC')">
              {{ 'msg.comm.ui.soring.frequency.asc' | translate }}
              <em class="ddp-icon-check"></em>
            </a>
          </li>

          <li [class.ddp-selected]="targetFilter['sortTarget'] === 'ALPHNUMERIC' && targetFilter['sortType'] === 'ASC'">
            <a href="javascript:" (click)="sortCandidateValues(targetFilter, 'ALPHNUMERIC', 'ASC')">
              {{ 'msg.comm.ui.soring.alphnumeric.asc' | translate }}
              <em class="ddp-icon-check"></em>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <a
      (click)="setOnlyShowCandidateValues()"
      [class.ddp-selected]="isOnlyShowCandidateValues"
      href="javascript:"
      class="ddp-btn-eyes ddp-btn-box"
    >
      <em class="ddp-icon-eyes2"></em>
    </a>
  </div>
</div>

<div [ngClass]="{ 'ddp-box-item-search ddp-edit-none': 'CHANGE' === mode }">
  <ul class="ddp-list-checktype" [ngClass]="{ 'ddp-type-list': 'CHANGE' === mode, 'ddp-list-padd': 'CHANGE' !== mode }">
    <li *ngIf="useAll" class="ddp-list-all">
      <label class="ddp-label-checkbox">
        <input (click)="candidateSelectAll($event)" [checked]="isCheckedAllItem()" type="checkbox" />
        <i class="ddp-icon-checkbox"></i>
        <span class="ddp-txt-checkbox">{{ 'msg.comm.ui.list.all' | translate }}</span>
      </label>
    </li>

    <li *ngFor="let item of pageCandidateList">
      <label class="ddp-label-checkbox">
        <input (click)="candidateSelect(item, $event)" [checked]="isCheckedItem(item)" type="checkbox" />
        <i class="ddp-icon-checkbox"></i>
        <span class="ddp-txt-checkbox">
          {{
            null === item.name || 'null' === item.name
              ? '(Null)'
              : '' === item.name || undefined === item.name
              ? '(Empty)'
              : item.name
          }}
          <span class="ddp-data-valuenum">{{ item.count }}</span>
        </span>
      </label>
      <em
        *ngIf="'CHANGE' === mode"
        (click)="candidateShowToggle(item)"
        [ngClass]="{ true: 'ddp-icon-eyes2-on', false: 'ddp-icon-eyes2-none' }[isShowItem(item)]"
      ></em>
    </li>

    <div *ngIf="'PANEL' === mode" class="ddp-ui-page ddp-center">
      <a
        (click)="setCandidatePage(currentPage - 1)"
        [class.ddp-disabled]="1 === currentPage"
        href="javascript:"
        class="ddp-btn-prev"
      ></a>
      <span class="ddp-data-page">
        <em>{{ currentPage }}</em>
        /
        <em>{{ lastPage }}</em>
      </span>
      <a
        (click)="setCandidatePage(currentPage + 1)"
        [class.ddp-disabled]="lastPage === currentPage"
        href="javascript:"
        class="ddp-btn-next"
      ></a>
    </div>
  </ul>

  <div *ngIf="'CHANGE' === mode" class="ddp-data-result">
    <div class="ddp-ui-page">
      <a
        (click)="setCandidatePage(currentPage - 1)"
        [class.ddp-disabled]="1 === currentPage"
        href="javascript:"
        class="ddp-btn-prev"
      ></a>
      <span class="ddp-data-page">
        <em>{{ currentPage }}</em>
        /
        <em>{{ lastPage }}</em>
      </span>
      <a
        (click)="setCandidatePage(currentPage + 1)"
        [class.ddp-disabled]="lastPage === currentPage"
        href="javascript:"
        class="ddp-btn-next"
      ></a>
    </div>
  </div>
</div>
