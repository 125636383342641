import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { UpdateStr } from '@ngrx/entity/src/models';
import { DashboardApiService } from '@selfai-platform/bi-api';
import { Observable, map, tap } from 'rxjs';
import { WidgetApiToDomainService } from '../../widget';
import { DASHBOARD_ENTITY_NAME } from '../const';
import { Dashboard, DashboardState } from '../models';
import {
  normalizeAddDashboardBody,
  normalizeDashboardApiModelToDashboard,
  normalizeUpdateDashboardBody,
} from '../normalizers';

@Injectable({ providedIn: 'root' })
export class DashboardDataService extends DefaultDataService<DashboardState> {
  constructor(
    http: HttpClient,
    httpUrlGenerator: HttpUrlGenerator,
    private readonly dashboardApiService: DashboardApiService,
    private readonly widgetApiToDomainService: WidgetApiToDomainService,
  ) {
    super(DASHBOARD_ENTITY_NAME, http, httpUrlGenerator);
  }

  override getById(dashboardId: string): Observable<Dashboard> {
    return this.dashboardApiService.getDashboardDetailView(dashboardId).pipe(
      tap((dashboard) => {
        this.widgetApiToDomainService.initDasboardWidgets(dashboard.widgets);
      }),
      map(normalizeDashboardApiModelToDashboard),
    );
  }

  override delete(id: string): Observable<string> {
    return this.dashboardApiService.deleteDashboard(id).pipe(map(() => id));
  }

  override update(update: UpdateStr<DashboardState>): Observable<DashboardState> {
    return this.dashboardApiService
      .updateDashboard(
        update.id,
        normalizeUpdateDashboardBody(update.changes as Partial<Dashboard> & Pick<Dashboard, 'configuration'>),
      )
      .pipe(map(normalizeDashboardApiModelToDashboard));
  }

  override add(entity: DashboardState): Observable<DashboardState> {
    return this.dashboardApiService
      .createDashboard(normalizeAddDashboardBody({ workbookId: entity.workbookId, dashboard: entity }))
      .pipe(map(normalizeDashboardApiModelToDashboard));
  }
}
