import { Component, ElementRef, Injector, Input, OnDestroy, OnInit } from '@angular/core';

import * as _ from 'lodash';
import moment from 'moment';

import {
  ChartSelectInfo,
  ChartSelectMode,
  Dashboard,
  DatasourceField as Field,
  Filter,
  InclusionFilter,
  InclusionSelectorType,
  SelectionFilter,
  SelectionInfoData,
  TimeUnit,
  createSelectionFilter,
} from '@selfai-platform/bi-domain';

import { AbstractComponent } from '../../../common/component/abstract.component';
import { EventBroadcaster } from '../../../common/event/event.broadcaster';
import { DatasourceService } from '../../../datasource/service/datasource.service';
import { FilterUtil } from '../../util/filter.util';

@Component({
  selector: 'selection-filter',
  templateUrl: './selection-filter.component.html',
  styleUrls: ['./selection-filter.component.scss'],
})
export class SelectionFilterComponent extends AbstractComponent implements OnInit, OnDestroy {
  private _chartSelectionList: ChartSelectInfo[] = [];

  public selectionFilterList: SelectionFilter[];

  @Input()
  public dashboard: Dashboard;

  @Input()
  public showBtnWidget = false;

  public scrollFreezing = false;

  public boardFilters: Filter[] = [];

  constructor(
    private datasourceService: DatasourceService,

    protected broadCaster: EventBroadcaster,
    protected elementRef: ElementRef,
    protected injector: Injector,
  ) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();

    this.subscriptions.push(
      this.broadCaster.on<any>('CHART_SELECTION_FILTER').subscribe((data) => {
        this.changeFilter(data.select);
      }),
    );

    this.init();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public getChartSelectionList(): ChartSelectInfo[] {
    return this._chartSelectionList;
  }

  public changeFilter(select: ChartSelectInfo) {
    if (select.mode === ChartSelectMode.ADD) {
      this._addChartSelectionInfo(select);
      if (Array.isArray(select.data)) {
        select.data.forEach((data) => this._addSelectionFilter(this.selectionFilterList, data, select.params));
      }
    } else if (select.mode === ChartSelectMode.SUBTRACT) {
      this._removeChartSelectionInfo(select);

      if (Array.isArray(select.data)) {
        select.data.forEach((data) => {
          let valueList = data.data;
          if (typeof valueList === 'string') {
            valueList = [valueList];
          }
          this.removeFilter(data, valueList);
        });
      }
    } else if (select.mode === ChartSelectMode.CLEAR) {
      this.init();
    }

    this._broadcastSelection({ filters: this._getApiFilters(), chartSelectInfo: select });
    this.safelyDetectChanges();
  }

  public resetFilter(shouldEmit: boolean = true) {
    this.init();
    if (shouldEmit) {
      this._broadcastSelection(this._getApiFilters());
    }
  }

  public init() {
    this.selectionFilterList = [];
    this._chartSelectionList = [];

    this.boardFilters = FilterUtil.getPanelContentsList(
      this.dashboard.configuration.filters,
      this.dashboard,
      (filter: InclusionFilter, field: Field) => {
        this._setInclusionFilter(filter, field);
      },
    );

    this.safelyDetectChanges();
  }

  public getDisplayName(filter: SelectionFilter) {
    if (filter.pivotAlias) {
      return filter.pivotAlias;
    } else {
      if (filter.fieldAlias !== filter.alias) {
        if (filter.alias !== filter.name) {
          return filter.alias;
        } else {
          return filter.fieldAlias ? filter.fieldAlias : filter.name;
        }
      } else {
        return filter.name;
      }
    }
  }

  public getLabel(filter: SelectionFilter) {
    if (filter.valueList.length === 0) {
      return '';
    } else {
      return filter.valueList[0];
    }
  }

  public getValueCnt(filter: SelectionFilter) {
    return filter.valueList.length;
  }

  public remove(selectionFilter: SelectionFilter, changeFlag: boolean = true) {
    _.remove(this.selectionFilterList, (filter) => {
      if ('timestamp' === selectionFilter.type) {
        return filter.field === selectionFilter.field && filter.format.unit === selectionFilter.format.unit;
      } else {
        return filter.field === selectionFilter.field;
      }
    });

    this._removeFieldInChartSelections(selectionFilter);

    if (changeFlag) {
      this._broadcastSelection(this._getApiFilters());
    }
  }

  public toggleLayer(selectionFilter: SelectionFilter, event) {
    event.stopPropagation();
    const change = !selectionFilter.selected;
    this.closeFilter();
    selectionFilter.selected = change;
    this.scrollFreezing = change;
  }

  public closeFilter() {
    this.scrollFreezing = false;
    this.selectionFilterList.forEach((selectFilter) => {
      selectFilter.selected = false;
    });
  }

  public isTimeRangeFilter(filter: SelectionFilter): boolean {
    return false;
  }

  private _getApiFilters() {
    return this.selectionFilterList.map((item) => {
      if ('dimension' === item.type) {
        return {
          type: 'include',
          field: item.field,
          dataSource: item.dataSource,
          selector: InclusionSelectorType.MULTI_LIST,
          valueList: item.valueList,
          selectedWidgetId: item.selectedWidgetId,
          ref: item.ref,
        };
      }

      if ('measure' === item.type) {
        return item;
      }

      if (this.isTimeRangeFilter(item)) {
        return {
          type: 'time_range',
          field: item.field,
          dataSource: item.dataSource,
          timeUnit: TimeUnit[item.format.unit],
          discontinuous: item.format.discontinuous,
          intervals: [item.minTime + '/' + item.maxTime],
          selectedWidgetId: item.selectedWidgetId,
          ref: item.ref,
        };
      } else {
        return {
          type: 'time_list',
          field: item.field,
          dataSource: item.dataSource,
          timeUnit: TimeUnit[item.format.unit],
          discontinuous: item.format.discontinuous,
          valueList: item.valueList,
          selectedWidgetId: item.selectedWidgetId,
          ref: item.ref,
        };
      }
    });
  }

  private _addSelectionFilter(
    filters: SelectionFilter[],
    selectInfoData: SelectionInfoData,
    params: { engineName: string; widgetId: string; selectType: string },
  ) {
    const filter: SelectionFilter = filters.find((filter: SelectionFilter) => filter.field === selectInfoData.name);

    let newValues = selectInfoData.data;
    typeof newValues === 'string' && (newValues = [newValues]);

    if (filter) {
      'MULTI' === params.selectType && (filter.valueList = []);

      filter.valueList = _.uniq(filter.valueList.concat(newValues));
      this._setTimeRange(filter);
    } else {
      const selectionFilterOpts: Parameters<typeof createSelectionFilter>[0] = {
        field: selectInfoData.name,
        dataSource: params.engineName,
        type: selectInfoData.type,
        valueList: newValues,
        selectedWidgetId: params.widgetId,
        selectionInfoData: selectInfoData,
        ref: selectInfoData.ref,
      };
      if (selectInfoData.format) {
        selectionFilterOpts.format = selectInfoData.format;
      }
      const selectionFilter = createSelectionFilter(selectionFilterOpts);
      this._setTimeRange(selectionFilter);
      filters.push(selectionFilter);
    }
  }

  private removeFilter(data: any, valueList: string[]) {
    const idx = this.selectionFilterList.findIndex((filter) => {
      if ('timestamp' === data.type) {
        return filter.alias === data.alias && filter.format.unit === data.format.unit;
      } else {
        return filter.alias === data.alias;
      }
    });

    if (idx > -1) {
      valueList.forEach((value) => {
        _.remove(this.selectionFilterList[idx].valueList, (val) => {
          return val === value;
        });
      });

      if (this.selectionFilterList[idx].valueList.length === 0) {
        this.remove(this.selectionFilterList[idx], false);
      }
    }
  }

  private _setTimeRange(filter: SelectionFilter) {
    if (this.isTimeRangeFilter(filter)) {
      if (-1 === filter.valueList[0].indexOf('Q')) {
        const timeValList = filter.valueList.map((item) => {
          return { timestamp: moment(item).unix(), val: item };
        });

        timeValList.sort((a, b) => a.timestamp - b.timestamp);

        filter.minTime = timeValList[0].val;
        filter.maxTime = timeValList[timeValList.length - 1].val;
      } else {
        const timeValList = filter.valueList.map((item) => {
          const splitDate: string[] = item.split(/\s|-/);
          let strYear = '';
          let strQuarter = '';
          if (-1 < splitDate[0].indexOf('Q')) {
            strYear = splitDate[1];
            strQuarter = splitDate[0];
          } else {
            strYear = splitDate[0];
            strQuarter = splitDate[1];
          }
          return { orderStr: strYear + '-' + strQuarter, val: item };
        });

        timeValList.sort((a, b) => {
          if (a.orderStr < b.orderStr) return -1;
          if (a.orderStr > b.orderStr) return 1;
          return 0;
        });

        filter.minTime = timeValList[0].val;
        filter.maxTime = timeValList[timeValList.length - 1].val;
      }
    }
  }

  private _broadcastSelection(data: any) {
    if (_.isArray(data)) {
      const selectionFilters: SelectionFilter[] = <SelectionFilter[]>data;

      this.broadCaster.broadcast('SET_SELECTION_FILTER', { filters: selectionFilters });
    } else {
      const externalFilterData: any = {};

      if (data.chartSelectInfo.params && data.chartSelectInfo.params.widgetId) {
        externalFilterData.excludeWidgetId = data.chartSelectInfo.params.widgetId;
      }

      externalFilterData.filters = data.filters.map((filter: SelectionFilter) => {
        filter.valueList = _.uniq(_.flattenDeep(filter.valueList));

        return filter;
      });

      this.broadCaster.broadcast('SET_SELECTION_FILTER', externalFilterData);
    }
  }

  private _addChartSelectionInfo(newItem: ChartSelectInfo): void {
    let savedList: ChartSelectInfo[] = this._chartSelectionList;
    if ('MULTI' === newItem.params.selectType) {
      savedList = savedList.filter((savedItem) => savedItem.params.widgetId !== newItem.params.widgetId);
      savedList.push(newItem);
    } else {
      const isMergedWidget = savedList.some((savedItem) => {
        if (savedItem.params.widgetId === newItem.params.widgetId) {
          newItem.data.forEach((newItemData) => {
            const isMerged = savedItem.data.some((savedItemData) => {
              if (savedItemData.alias === newItemData.alias) {
                savedItemData.data = savedItemData.data
                  .concat(newItemData.data)
                  .filter((elem, pos, arr) => arr.indexOf(elem) == pos);
                return true;
              }
            });

            isMerged || savedItem.data.push(newItemData);
          });
          return true;
        }
      });

      isMergedWidget || savedList.push(newItem);
    }
  }

  private _removeChartSelectionInfo(removeItem: ChartSelectInfo): void {
    let nEmptyFieldIndex = -1;
    this._chartSelectionList.some((savedItem: ChartSelectInfo, fieldIdx: number) => {
      if (savedItem.params.widgetId === removeItem.params.widgetId) {
        removeItem.data.forEach((removeItemData) => {
          let nEmptyDataIndex = -1;
          savedItem.data.some((savedItemData, dataIdx: number) => {
            if (savedItemData.alias === removeItemData.alias && savedItemData.data) {
              savedItemData.data = savedItemData.data.filter((item) => -1 === removeItemData.data.indexOf(item));
              0 === savedItemData.data.length && (nEmptyDataIndex = dataIdx);
              return true;
            }
          });

          -1 < nEmptyDataIndex && savedItem.data.splice(nEmptyDataIndex, 1);
        });

        0 === savedItem.data.length && (nEmptyFieldIndex = fieldIdx);

        return true;
      }
    });

    if (-1 < nEmptyFieldIndex) {
      this._chartSelectionList.splice(nEmptyFieldIndex, 1);
    }
  }

  private _removeFieldInChartSelections(filter: SelectionFilter): void {
    let nEmptyFieldIndex = -1;
    this._chartSelectionList.some((savedItem: ChartSelectInfo, fieldIdx: number) => {
      const nEmptyDataIndex = savedItem.data.findIndex((savedItemData) => savedItemData.alias === filter.alias);

      if (-1 < nEmptyDataIndex) {
        savedItem.data.splice(nEmptyDataIndex, 1);
        0 === savedItem.data.length && (nEmptyFieldIndex = fieldIdx);
        return true;
      }
    });

    if (-1 < nEmptyFieldIndex) {
      this._chartSelectionList.splice(nEmptyFieldIndex, 1);
    }
  }

  private _setInclusionFilter(filter: InclusionFilter, field: Field) {
    this.pageLoaderService.show();
    this.datasourceService
      .getCandidateForFilter(filter, this.dashboard, [], field)
      .then((result) => {
        const valueList: string[] = filter.valueList;
        if (valueList && 0 < valueList.length && valueList.length !== result.length) {
          filter['panelContents'] = valueList.join(' , ');
        } else {
          filter['panelContents'] = '(' + this.translateService.instant('msg.comm.ui.list.all') + ')';
        }

        this.safelyDetectChanges();

        this.pageLoaderService.hide();
      })
      .catch((error) => console.error(error));
  }
}
