import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { KdBackendApiService } from '../../common';
import { WidgetQuery } from '../../widgets';
import {
  DataSourceModel,
  DataSourceResponseModel,
  DataSourceSearchResponseModel,
  DatasourceListQueryParams,
  DatasourceOneQueryParams,
} from '../models';

@Injectable()
export class DatasourceApiService {
  constructor(private readonly kdBackendApiService: KdBackendApiService) {}

  getDatasourceList(workspaceId: string, params: DatasourceListQueryParams = {}): Observable<DataSourceResponseModel> {
    return this.kdBackendApiService.get<DataSourceResponseModel>(
      this.kdBackendApiService.buildPath<DatasourceListQueryParams>(`workspaces/${workspaceId}/datasources`, {
        projection: 'forDetailView',
        ...params,
      }),
    );
  }

  getDatasource(datasourceId: string, queryParams: DatasourceOneQueryParams): Observable<DataSourceModel> {
    return this.kdBackendApiService.get<DataSourceModel>(
      this.kdBackendApiService.buildPath<DatasourceOneQueryParams>(`datasources/${datasourceId}`, queryParams),
    );
  }

  getQuerySearch(query: WidgetQuery): Observable<DataSourceSearchResponseModel> {
    const url = this.kdBackendApiService.buildPath('datasources/query/search');

    return this.kdBackendApiService.post<DataSourceSearchResponseModel>(url, query);
  }
}
