import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import $ from 'jquery';
import * as _ from 'lodash';
import { Subject, Subscription } from 'rxjs';

import {
  ChartColorList,
  GraphicType,
  PageWidgetConfiguration,
  Pivot,
  PivotField,
  ShelveFieldType,
  ShelveType,
  UIChartColorBySeries,
  UIOption,
} from '@selfai-platform/bi-domain';

import { AbstractComponent } from '../../../../common/component/abstract.component';
import { SelectComponent } from '../../../../common/component/select/select.component';
import { Analysis, Confidence, Forecast, HyperParameter, PredictionAnalysis, Style } from '../../value/analysis';
import { ColorPickerLayerComponent } from '../color.picker/color.picker.layer.component';
import { RangeSliderComponent } from '../slider/range-slider.component';

@Component({
  selector: 'analysis-prediction',
  templateUrl: './analysis-prediction.component.html',
})
export class AnalysisPredictionComponent
  extends AbstractComponent
  implements OnInit, OnDestroy, AfterViewInit, OnChanges
{
  private DEFAULT_PREDICTION_LINE_SLIDER_FROM = 95;

  private DEFAULT_HYPER_PARAMETER: HyperParameter = new HyperParameter();

  public LABEL: {
    ANALYSIS: string;
    SUB_MENU: string;
    ACTIVE: string;
    ACTIVE_FIRST_DESCRIPTION: string;
    ACTIVE_LAST_DESCRIPTION: string;
    ALGORITHM_NAME: string;
    INTERVAL: string;
    SLIDER_TITLE: string;
    ANALYSIS_SETTING: string;
    ALPHA: string;
    BETA: string;
    TREND: string;
    GAMMA: string;
    GAMMA_ADDITIVE: string;
    GAMMA_MULTIPLICATIVE: string;
    SEASONAL: string;
    FORECAST: string;
    CONFIDENCE: string;
    OPACITY: string;
    PROCENT: string;
    HYPHEN: string;
  } = {
    ANALYSIS: 'Analysis',

    SUB_MENU: '예측선',

    ACTIVE: '활성화',

    ACTIVE_FIRST_DESCRIPTION: '예측선을 사용할 수 있는 차트',

    ACTIVE_LAST_DESCRIPTION: '날짜/시간 차원값이 있는 선형차트',

    ALGORITHM_NAME: 'Holt-Winters',

    INTERVAL: '예측기간',

    SLIDER_TITLE: '신뢰구간',

    ANALYSIS_SETTING: '고급설정',

    ALPHA: 'alpha',

    BETA: 'beta',

    TREND: 'trend',

    GAMMA: 'gamma',

    GAMMA_ADDITIVE: 'additive',

    GAMMA_MULTIPLICATIVE: 'multiplicative',

    SEASONAL: 'seasonal',

    FORECAST: 'Forecast',

    CONFIDENCE: 'Confidence',

    OPACITY: '투명도',

    PROCENT: '%',

    HYPHEN: '-',
  };

  private MESSAGE: {
    ALPHA: string;
    BETA: string;
    GAMMA: string;
    PLEASE_VALUE: string;
    VALUES_ONLY_NUMERIC: string;
    PLEASE_FORECAST_PERIOD: string;
    PLEASE_TRANSPARENCY: string;
    VALUES_LESS_THAN_0_CAN_NOT_BE_ENTERED: string;
    VALUES_GREATER_THAN_100_CAN_NOT_BE_ENTERED: string;
  } = {
    ALPHA: '알파',
    BETA: '베타',
    GAMMA: '감마',
    PLEASE_VALUE: '값을 입력해주세요.',
    VALUES_ONLY_NUMERIC: '값은 숫자만 입력할 수 있습니다.',
    PLEASE_FORECAST_PERIOD: '예측기간을 입력해주세요.',
    PLEASE_TRANSPARENCY: '투명도를 입력해주세요.',
    VALUES_LESS_THAN_0_CAN_NOT_BE_ENTERED: '0 보다 작은 값은 입력할 수 없습니다.',
    VALUES_GREATER_THAN_100_CAN_NOT_BE_ENTERED: '100 보다 큰 값은 입력할 수 없습니다.',
  };

  public LINE_TYPE_LIST: string[] = ['SOLID', 'DASHED', 'DOTTED'];

  public LINE_WIDTH_LIST: string[] = ['2.0', '2.5', '3.0', '3.5', '4', '4.5', '5'];

  public currentLang = '';

  private changeSubject$ = new Subject<{
    isPredictionLineDisabled: boolean;
    isPredictionLineActive: boolean;
    isAnalysisSettingsActive: boolean;
    isSelectedForecast: boolean;
    isSelectedConfidence: boolean;
    analysis: Analysis;
  }>();

  private changeSubjectSubscription: Subscription;

  private changeForecastSubject$ = new Subject<{
    isPredictionLineDisabled: boolean;
    isPredictionLineActive: boolean;
    isAnalysisSettingsActive: boolean;
    isSelectedForecast: boolean;
    isSelectedConfidence: boolean;
    analysis: Analysis;
  }>();

  private changeForecastSubscription: Subscription;

  private changeConfidenceSubject$ = new Subject<{
    isPredictionLineDisabled: boolean;
    isPredictionLineActive: boolean;
    isAnalysisSettingsActive: boolean;
    isSelectedForecast: boolean;
    isSelectedConfidence: boolean;
    analysis: Analysis;
  }>();

  private changeConfidenceSubscription: Subscription;

  @ViewChild(RangeSliderComponent)
  private predictionSlider: RangeSliderComponent;

  @ViewChild('panel')
  private panel: ElementRef;

  @ViewChild(ColorPickerLayerComponent, { static: true })
  private colorPickerLayerComponent: ColorPickerLayerComponent;

  @ViewChild('selectComponent')
  private selectComponent: SelectComponent;

  @ViewChild('selectLineTypeComponent')
  private selectLineTypeComponent: SelectComponent;

  @ViewChild('selectLineComponent')
  private selectLineComponent: SelectComponent;

  @Input()
  private isChartShow: boolean;

  @Input()
  private selectChart: string;

  @Input()
  private widgetConfiguration: PageWidgetConfiguration;

  @Input()
  public dataSubLayerKey = '';

  @Output()
  private clickDataPanelNoti = new EventEmitter();

  @Output('changeAnalysisPredictionNoti')
  private changeAnalysisPrediction = new EventEmitter();

  @Output('changeForecastNoti')
  private changeForecast = new EventEmitter();

  @Output('changeConfidenceNoti')
  private changeConfidence = new EventEmitter();

  private initialized = false;

  private originWidgetConfigurationAnalysis: Analysis;

  private uiOption: UIOption;

  public data: {
    isPredictionLineDisabled: boolean;
    isPredictionLineActive: boolean;
    isAnalysisSettingsActive: boolean;
    isSelectedForecast: boolean;
    isSelectedConfidence: boolean;
    analysis: Analysis;
  } = {
    isPredictionLineDisabled: false,

    isPredictionLineActive: false,

    isAnalysisSettingsActive: true,

    isSelectedForecast: true,

    isSelectedConfidence: true,

    analysis: null,
  };

  public forecastParameters: string[] = [];

  public selectedForecastParameter: HyperParameter = new HyperParameter();

  public pivot: Pivot;

  @Input()
  public dataLayerKey: string;

  public colorPickerLayerOffsetX: string;

  public colorPickerLayerOffsetY: string;

  public predictionLineSliderHideFromTo = false;
  public predictionLineSliderHideMinMax = true;
  public predictionLineSliderKeyboard = false;
  public predictionLineSliderMin = 50;
  public predictionLineSliderMax = 99;
  public predictionLineSliderFrom = 95;
  public predictionLineSliderType = 'single';
  public predictionLineSliderStep = 5;
  public predictionLineSliderGrid = false;
  public predictionLineSliderObject: object = {
    name: 'Page Analysis Slider',
    onUpdate: undefined,
    onFinish: undefined,
  };

  public forecastParametersSelectedIndex = 0;

  public selectLineTypeComponentDefaultIndex = 0;

  public selectLineComponentDefaultIndex = 0;

  constructor(private element: ElementRef, protected injector: Injector) {
    super(element, injector);
  }

  public ngOnInit(): void {
    this.currentLang = this.getLanguage();

    this.LABEL.ANALYSIS = this.translateService.instant('msg.page.prediction.analysis');

    this.LABEL.SUB_MENU = this.translateService.instant('msg.page.prediction.sub.menu');

    this.LABEL.ACTIVE = this.translateService.instant('msg.page.prediction.span.active');

    this.LABEL.ACTIVE_FIRST_DESCRIPTION = this.translateService.instant('msg.page.prediction.active.first.description');

    this.LABEL.ACTIVE_LAST_DESCRIPTION = this.translateService.instant('msg.page.prediction.active.last.description');

    this.LABEL.ALGORITHM_NAME = this.translateService.instant('msg.page.prediction.algorithm.name');

    this.LABEL.INTERVAL = this.translateService.instant('msg.page.prediction.span.interval');

    this.LABEL.SLIDER_TITLE = this.translateService.instant('msg.page.prediction.span.slider.title');

    this.LABEL.ANALYSIS_SETTING = this.translateService.instant('msg.page.prediction.a.advanced.settings');

    this.LABEL.ALPHA = this.translateService.instant('msg.page.prediction.alpha');

    this.LABEL.BETA = this.translateService.instant('msg.page.prediction.beta');

    this.LABEL.TREND = this.translateService.instant('msg.page.prediction.trend');

    this.LABEL.GAMMA = this.translateService.instant('msg.page.prediction.gamma');

    this.LABEL.GAMMA_ADDITIVE = this.translateService.instant('msg.page.prediction.gamma.additive');

    this.LABEL.GAMMA_MULTIPLICATIVE = this.translateService.instant('msg.page.prediction.gamma.multiplicative');

    this.LABEL.SEASONAL = this.translateService.instant('msg.page.prediction.seasonal');

    this.LABEL.FORECAST = this.translateService.instant('msg.page.prediction.forecast');

    this.LABEL.CONFIDENCE = this.translateService.instant('msg.page.prediction.confidence');

    this.LABEL.OPACITY = this.translateService.instant('msg.page.prediction.opacity');

    this.MESSAGE.ALPHA = this.translateService.instant('msg.page.prediction.alert.alpha');

    this.MESSAGE.BETA = this.translateService.instant('msg.page.prediction.alert.beta');

    this.MESSAGE.GAMMA = this.translateService.instant('msg.page.prediction.alert.gamma');

    this.MESSAGE.PLEASE_VALUE = this.translateService.instant('msg.page.prediction.alert.please.value');

    this.MESSAGE.VALUES_ONLY_NUMERIC = this.translateService.instant('msg.page.prediction.alert.values.numeric');

    this.MESSAGE.PLEASE_FORECAST_PERIOD = this.translateService.instant('msg.page.prediction.alert.forecast.period');

    this.MESSAGE.PLEASE_TRANSPARENCY = this.translateService.instant('msg.page.prediction.alert.please.transparency');

    this.MESSAGE.VALUES_LESS_THAN_0_CAN_NOT_BE_ENTERED = this.translateService.instant(
      'msg.page.prediction.alert.0.not',
    );

    this.MESSAGE.VALUES_GREATER_THAN_100_CAN_NOT_BE_ENTERED = this.translateService.instant(
      'msg.page.prediction.alert.100.not',
    );

    this.changeSubjectSubscription = this.changeSubject$.subscribe((data) => {
      if (this.isChartTypeLine()) {
        if (_.isUndefined(data.analysis)) {
          this.removeAnalysisPredictionLine();
        } else {
          this.widgetConfiguration.analysis = data.analysis.analysis;

          if (typeof this.originWidgetConfigurationAnalysis !== 'undefined') {
            this.originWidgetConfigurationAnalysis = _.cloneDeep(this.widgetConfiguration.analysis);
          }
        }

        this.changeAnalysisPrediction.emit();
      }
    });

    this.changeForecastSubscription = this.changeForecastSubject$.subscribe((data) => {
      if (this.isChartTypeLine()) {
        if (_.isUndefined(data.analysis)) {
          this.removeAnalysisPredictionLine();
        } else {
          this.widgetConfiguration.analysis = data.analysis.analysis;
        }

        this.changeForecast.emit();
      }
    });

    this.changeConfidenceSubscription = this.changeConfidenceSubject$.subscribe((data) => {
      if (this.isChartTypeLine()) {
        if (_.isUndefined(data.analysis)) {
          this.removeAnalysisPredictionLine();
        } else {
          this.widgetConfiguration.analysis = data.analysis.analysis;
        }

        this.changeConfidence.emit();
      }
    });
  }

  public ngAfterViewInit(): void {
    this.initialized = true;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.initialized) {
      if (!_.isUndefined(changes['isChartShow'])) {
        const isChartShow: SimpleChange = changes['isChartShow'];
        if (isChartShow.currentValue === false) {
          this.data.isPredictionLineDisabled = false;

          this.data.isPredictionLineActive = false;

          this.removeAnalysisPredictionLine();

          return;
        }
      }

      if (!_.isUndefined(changes['selectChart'])) {
        const isChartType: SimpleChange = changes['selectChart'];
        if (isChartType.currentValue !== 'line') {
          this.data.isPredictionLineDisabled = false;

          this.data.isPredictionLineActive = false;

          this.removeAnalysisPredictionLine();

          this.predictionLineDataChangeNotification();

          return;
        }
      }

      if (!_.isUndefined(changes['dataLayerKey'])) {
        const dataLayerKey: SimpleChange = changes['dataLayerKey'];
        if (dataLayerKey.currentValue !== 'analysis') {
          this.dataSubLayerKey = '';
        }
      }

      if (!_.isUndefined(changes['dataSubLayerKey'])) {
        const dataSubLayerKey: SimpleChange = changes['dataSubLayerKey'];
        if (dataSubLayerKey.currentValue === 'prediction') {
          if (_.isUndefined(this.widgetConfiguration.analysis)) {
            return;
          }
          if (_.isEmpty(this.widgetConfiguration.analysis)) {
            return;
          }
          this.data.isPredictionLineActive = true;
          return;
        } else {
          this.data.isPredictionLineActive = false;
        }
      }
    }
  }

  public ngOnDestroy(): void {
    if (this.changeSubjectSubscription) {
      this.changeSubjectSubscription.unsubscribe();
    }

    if (this.changeForecastSubscription) {
      this.changeForecastSubscription.unsubscribe();
    }

    if (this.changeConfidenceSubscription) {
      this.changeConfidenceSubscription.unsubscribe();
    }
  }

  public isValid(): boolean {
    let result = false;

    if (this.isChartDrawed() && this.isChartTypeLine()) {
      const shelve: Pivot = this.widgetConfiguration.pivot;

      const getFieldTypeCount = (shelve: Pivot, shelveType: ShelveType, fieldType: ShelveFieldType) => {
        return shelve[shelveType].filter((field) => {
          return _.eq(field.type, fieldType);
        }).length;
      };

      if (
        getFieldTypeCount(shelve, ShelveType.COLUMNS, ShelveFieldType.TIMESTAMP) === 1 &&
        getFieldTypeCount(shelve, ShelveType.COLUMNS, ShelveFieldType.DIMENSION) === 0
      ) {
        result = true;
      }

      if (this.widgetConfiguration.pivot.columns.length > 1) {
        return (result = false);
      }

      if (getFieldTypeCount(shelve, ShelveType.COLUMNS, ShelveFieldType.TIMESTAMP) !== 1) {
        return (result = false);
      }

      if (
        shelve.aggregations.length > 0 &&
        (getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.MEASURE) > 1 ||
          getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.CALCULATED) > 1)
      ) {
        result = true;
      }

      if (getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.DIMENSION) !== 0) {
        return (result = false);
      }

      if (getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.TIMESTAMP) !== 0) {
        return (result = false);
      }
    }

    return result;
  }

  public synchronize(uiOption: UIOption, param?: object, resultData?: any): void {
    if (param) param['isPredictionLineDisabled'] = this.setPredictionLineDisabled(param);

    this.uiOption = uiOption;

    if (_.isUndefined(this.widgetConfiguration.analysis) || _.isEmpty(this.widgetConfiguration.analysis)) {
      if (param && false == param['isPredictionLineDisabled']) {
        this.data.isPredictionLineDisabled = param['isPredictionLineDisabled'];
        this.data.isPredictionLineActive = param['isPredictionLineDisabled'];
        this.removeAnalysisPredictionLine();
      }
      return;
    }

    if (this.isValid()) {
      if (resultData) {
        this.widgetConfiguration.pivot.aggregations.forEach((agg) => {
          const hyperParameter = new HyperParameter();

          if (agg.alias) {
            hyperParameter.field = agg.alias;
          } else {
            if (agg.ref) {
              hyperParameter.field = `${agg.aggregationType + '(' + agg.ref + '.' + agg.name + ')'}`;
            } else {
              hyperParameter.field = `${agg.aggregationType + '(' + agg.name + ')'}`;
            }
          }

          hyperParameter.alpha = resultData[`${hyperParameter.field}.params`][0];
          hyperParameter.beta = resultData[`${hyperParameter.field}.params`][1];
          hyperParameter.gamma = resultData[`${hyperParameter.field}.params`][2];

          if (-1 == _.findIndex(this.data.analysis.analysis.forecast.parameters, { field: hyperParameter.field })) {
            this.data.analysis.analysis.forecast.parameters.push(_.cloneDeep(hyperParameter));
          }
        });
      }

      const selectedForecastParameterName: string = this.forecastParameters[this.forecastParametersSelectedIndex];

      this.data.analysis = null;
      this.data.analysis = new Analysis();
      this.data.analysis.analysis = new PredictionAnalysis();
      this.data.analysis.analysis = this.widgetConfiguration.analysis;

      this.forecastParameters = [];

      const widgetConfigurationPivotAggregations = [];
      this.widgetConfiguration.pivot.aggregations.forEach((agg, index) => {
        let alias: string;
        if (agg.alias) {
          alias = agg.alias;
        } else {
          if (agg.ref) {
            alias = `${agg.aggregationType + '(' + agg.ref + '.' + agg.name + ')'}`;
          } else {
            alias = `${agg.aggregationType + '(' + agg.name + ')'}`;
          }
        }

        const parameter = this.data.analysis.analysis.forecast.parameters.filter((param) => {
          return param.field === alias;
        });

        if (parameter.length === 0) {
          const hyperParameter = new HyperParameter();
          hyperParameter.field = alias;

          widgetConfigurationPivotAggregations.push(_.cloneDeep(hyperParameter));
        } else {
          widgetConfigurationPivotAggregations.push(parameter[0]);
        }
      });

      this.data.analysis.analysis.forecast.parameters = [];
      this.data.analysis.analysis.forecast.parameters = widgetConfigurationPivotAggregations;

      this.data.analysis.analysis.forecast.parameters.forEach((param) => {
        this.forecastParameters.push(param.field);
      });

      const forecastParameterIndex = this.forecastParameters.findIndex((item) => {
        return selectedForecastParameterName === item;
      });

      if (forecastParameterIndex === -1) {
        this.forecastParametersSelectedIndex = 0;
      } else {
        this.forecastParametersSelectedIndex = forecastParameterIndex;
      }

      this.selectedForecastParameter =
        this.data.analysis.analysis.forecast.parameters[this.forecastParametersSelectedIndex];

      this.selectLineTypeComponentDefaultIndex = this.getLineTypeFromLineTypeListToLineType(
        this.data.analysis.analysis.forecast.style.lineType,
      );
      this.selectLineComponentDefaultIndex = this.getLineThicknessFromLineWidthListToLineThickness(
        this.data.analysis.analysis.forecast.style.lineThickness.toString(),
      );

      this.predictionLineSliderFrom = this.data.analysis.analysis.confidence.confidenceInterval;

      this.data.isPredictionLineDisabled = true;
      this.data.isPredictionLineActive = true;

      this.widgetConfiguration.analysis.forecast.parameters.map((parameter) => {
        if (parameter.isAuto) {
          if (parameter.alpha !== '') {
            parameter.alpha = Math.round(parameter.alpha.toFixed(3) * 100) / 100;
          }
          if (parameter.beta !== '') {
            parameter.beta = Math.round(parameter.beta.toFixed(3) * 100) / 100;
          }
          if (parameter.gamma !== '') {
            parameter.gamma = Math.round(parameter.gamma.toFixed(3) * 100) / 100;
          }
        }
        return parameter;
      });

      this.originWidgetConfigurationAnalysis = _.cloneDeep(this.widgetConfiguration.analysis);

      const color = this.uiOption.color;
      const schema = this.uiOption.color['schema'];
      const codes: any = _.cloneDeep(ChartColorList[schema]);

      if ((<UIChartColorBySeries>color).mapping) {
        Object.keys((<UIChartColorBySeries>color).mapping).forEach((key, index) => {
          codes[index] = (<UIChartColorBySeries>color).mapping[key];
        });
      }

      if (!this.data.analysis.analysis.forecast.style.predictColorUseFl)
        this.data.analysis.analysis.forecast.style.color = codes[0];
      if (!this.data.analysis.analysis.confidence.style.predictColorUseFl)
        this.data.analysis.analysis.confidence.style.color = codes[0];
    } else {
      this.data.isPredictionLineDisabled = false;
      this.data.isPredictionLineActive = false;
      this.removeAnalysisPredictionLine();
    }

    if (param && false == param['isPredictionLineDisabled']) {
      this.data.isPredictionLineDisabled = param['isPredictionLineDisabled'];
      this.data.isPredictionLineActive = param['isPredictionLineDisabled'];
      this.removeAnalysisPredictionLine();
    }

    this.pageLoaderService.hide();
  }

  public changePredictionLineDisabled(): void {
    this.data.isPredictionLineDisabled = true;
  }

  public changeOption(): void {
    if (_.isUndefined(this.widgetConfiguration.analysis)) {
      return;
    }

    if (_.isEmpty(this.widgetConfiguration.analysis)) {
      return;
    }

    if (this.isValid() === false) {
      this.data.isPredictionLineDisabled = false;
      this.data.isPredictionLineActive = false;
      this.removeAnalysisPredictionLine();
      this.predictionLineDataChangeNotification();
      return;
    }

    if (this.data.isPredictionLineDisabled && this.data.isPredictionLineActive) {
      this.data.analysis.analysis.timeUnit = this.widgetConfiguration.pivot.columns[0].format.unit;
    }
  }

  public predictionLineClick(): void {
    this.data.isPredictionLineActive = !this.data.isPredictionLineActive;

    if (this.data.isPredictionLineActive) {
      this.initializeAnalysisPredictionLine();

      this.predictionLineDataChangeNotification();
    } else {
      this.removeAnalysisPredictionLine();

      this.predictionLineDataChangeNotification();
    }
  }

  public keyupEnterPredictionInterval(predictionInterval: number | string): void {
    if (this.validationPredictionInterval(predictionInterval)) {
      this.data.analysis.analysis.interval = predictionInterval;

      this.predictionLineDataChangeNotification();
    }
  }

  public sliderCallBack(slider, $event: Event): void {
    slider.onUpdate = $event;

    this.data.analysis.analysis.confidence.confidenceInterval = this.predictionSlider.from;

    this.predictionLineDataChangeNotification();
  }

  public changeForecastParametersAreaDisplayNoneBlockFlag(): void {
    this.data.isAnalysisSettingsActive = !this.data.isAnalysisSettingsActive;
  }

  public selectedForecastCheckBoxFlag(): void {
    if (this.selectedForecastParameter.isAuto === false) {
      this.selectedForecastParameter.isAlphaDisabled = true;
      this.selectedForecastParameter.isAlphaSelected = true;
      this.selectedForecastParameter.isBetaDisabled = true;
      this.selectedForecastParameter.isBetaSelected = true;
      this.selectedForecastParameter.isGammaDisabled = true;
      this.selectedForecastParameter.isGammaSelected = true;
      this.selectedForecastParameter.isPeriodSelected = true;

      this.selectedForecastParameter.alpha = '';

      this.selectedForecastParameter.beta = '';

      this.selectedForecastParameter.gamma = '';

      this.selectedForecastParameter.multiple = null;

      this.updateSelectedForecastParameter(
        this.selectComponent.array.findIndex((item) => item === this.selectComponent.selectedItem),
      );

      this.predictionLineDataChangeNotification();
    } else {
      this.selectedForecastParameter.isAlphaDisabled = false;
      this.selectedForecastParameter.isAlphaSelected = false;
      this.selectedForecastParameter.isBetaDisabled = false;
      this.selectedForecastParameter.isBetaSelected = false;
      this.selectedForecastParameter.isGammaDisabled = false;
      this.selectedForecastParameter.isGammaSelected = false;
    }

    this.selectedForecastParameter.isAuto = !this.selectedForecastParameter.isAuto;
  }

  public changeSelectedForecastParameter(): void {
    this.updateSelectedForecastParameter(
      this.selectComponent.array.findIndex((item) => item === this.selectComponent.selectedItem),
    );
  }

  public validateForecastParameterAlpha(): Function {
    const messages = this.MESSAGE;
    const isKorLang = this.currentLang === 'ko';
    return (alphaValue: string) => {
      if (_.isUndefined(alphaValue)) {
        let msg = '';
        if (isKorLang) {
          msg = `${messages.ALPHA} ${messages.PLEASE_VALUE}`;
        } else {
          msg = `${messages.PLEASE_VALUE.replace('xxx', messages.ALPHA.toLowerCase())}`;
        }
        this.alertPrimeService.warn(msg);
        return false;
      }

      if ('' === alphaValue) {
        let msg = '';
        if (isKorLang) {
          msg = `${messages.ALPHA} ${messages.PLEASE_VALUE}`;
        } else {
          msg = `${messages.PLEASE_VALUE.replace('xxx', messages.ALPHA.toLowerCase())}`;
        }
        this.alertPrimeService.warn(msg);
        return false;
      }

      const alpha = Number(alphaValue);

      if (_.isNaN(alpha)) {
        this.alertPrimeService.warn(`${messages.ALPHA} ${messages.VALUES_ONLY_NUMERIC}`);
        return false;
      }

      if (_.isFinite(alpha) === false) {
        this.alertPrimeService.warn(`${messages.ALPHA} ${messages.VALUES_ONLY_NUMERIC}`);
        return false;
      }

      return true;
    };
  }

  public setForecastParameterAlpha(alphaValue: number): void {
    this.selectedForecastParameter.alpha = alphaValue;

    this.updateSelectedForecastParameter(
      this.selectComponent.array.findIndex((item) => item === this.selectComponent.selectedItem),
    );

    this.predictionLineDataChangeNotification();
  }

  public validateForecastParameterBeta(): Function {
    const messages = this.MESSAGE;
    const isKorLang = this.currentLang === 'ko';
    return (betaValue: string) => {
      if (_.isUndefined(betaValue)) {
        let msg = '';
        if (isKorLang) {
          msg = `${messages.BETA} ${messages.PLEASE_VALUE}`;
        } else {
          msg = `${messages.PLEASE_VALUE.replace('xxx', messages.BETA.toLowerCase())}`;
        }
        this.alertPrimeService.warn(msg);
        return false;
      }

      if (betaValue === '') {
        let msg = '';
        if (isKorLang) {
          msg = `${messages.BETA} ${messages.PLEASE_VALUE}`;
        } else {
          msg = `${messages.PLEASE_VALUE.replace('xxx', messages.BETA.toLowerCase())}`;
        }
        this.alertPrimeService.warn(msg);
        return false;
      }

      const beta = Number(betaValue);

      if (_.isNaN(beta)) {
        this.alertPrimeService.warn(`${messages.BETA} ${messages.VALUES_ONLY_NUMERIC}`);
        return false;
      }

      if (_.isFinite(beta) === false) {
        this.alertPrimeService.warn(`${messages.BETA} ${messages.VALUES_ONLY_NUMERIC}`);
        return false;
      }
      return true;
    };
  }

  public setForecastParameterBeta(betaValue: number): void {
    this.selectedForecastParameter.beta = betaValue;

    this.updateSelectedForecastParameter(
      this.selectComponent.array.findIndex((item) => item === this.selectComponent.selectedItem),
    );

    this.predictionLineDataChangeNotification();
  }

  public validateForecastParameterGamma(): Function {
    const messages = this.MESSAGE;
    const isKorLang = this.currentLang === 'ko';
    return (gammaValue: string) => {
      if (_.isUndefined(gammaValue)) {
        let msg = '';
        if (isKorLang) {
          msg = `${messages.GAMMA} ${messages.PLEASE_VALUE}`;
        } else {
          msg = `${messages.PLEASE_VALUE.replace('xxx', messages.GAMMA.toLowerCase())}`;
        }
        this.alertPrimeService.warn(msg);
        return false;
      }

      if (gammaValue === '') {
        let msg = '';
        if (isKorLang) {
          msg = `${messages.GAMMA} ${messages.PLEASE_VALUE}`;
        } else {
          msg = `${messages.PLEASE_VALUE.replace('xxx', messages.GAMMA.toLowerCase())}`;
        }
        this.alertPrimeService.warn(msg);
        return false;
      }

      const gamma = Number(gammaValue);

      if (_.isNaN(gamma)) {
        this.alertPrimeService.warn(`${messages.GAMMA} ${messages.VALUES_ONLY_NUMERIC}`);
        return false;
      }

      if (_.isFinite(gamma) === false) {
        this.alertPrimeService.warn(`${messages.GAMMA} ${messages.VALUES_ONLY_NUMERIC}`);
        return false;
      }
      return true;
    };
  }

  public setForecastParameterGamma(gammaValue: number): void {
    this.selectedForecastParameter.gamma = gammaValue;

    this.updateSelectedForecastParameter(
      this.selectComponent.array.findIndex((item) => item === this.selectComponent.selectedItem),
    );

    this.predictionLineDataChangeNotification();
  }

  public changeAdvancedPeriod(periodValue: string) {
    if (_.isEmpty(periodValue)) return;
    this.selectedForecastParameter.period = parseInt(periodValue);

    this.predictionLineDataChangeNotification();
  }

  public changeSelectedForecastParameterGamma(): void {
    if (this.selectedForecastParameter.isGammaSelected === false) {
      this.selectedForecastParameter.gamma = this.DEFAULT_HYPER_PARAMETER.gamma;

      this.selectedForecastParameter.multiple = undefined;
    } else {
      this.selectedForecastParameter.multiple = false;
    }
  }

  public changeSelectedForecastParameterPeriod(): void {
    this.selectedForecastParameter.isPeriodSelected = !this.selectedForecastParameter.isPeriodSelected;

    if (this.selectedForecastParameter.isPeriodSelected) this.selectedForecastParameter.period = null;
  }

  public changeSelectedForecastParameterGammaInMultipleValue(multiple: boolean): void {
    this.selectedForecastParameter.multiple = multiple;

    this.updateSelectedForecastParameter(
      this.selectComponent.array.findIndex((item) => item === this.selectComponent.selectedItem),
    );

    this.predictionLineDataChangeNotification();
  }

  public forecastColorPickerShow(element: HTMLElement, color: string): void {
    this.colorPickerLayerShow(element, color, (colorHex: any): void => {
      const isForecastChanged: boolean = this.data.analysis.analysis.forecast.style.color !== colorHex;

      this.data.analysis.analysis.forecast.style.color = colorHex;

      this.data.analysis.analysis.forecast.style.predictColorUseFl = true;

      if (isForecastChanged) {
        this.predictionLineForecastDataChangeNotification();
      }
    });
  }

  public changeUseForecast() {
    if (this.data.isSelectedForecast) {
      this.data.analysis.analysis.forecast.style.lineType = 'SOLID';
      this.data.analysis.analysis.forecast.style.lineThickness = 2.0;
      this.predictionLineForecastDataChangeNotification();
    } else {
      this.data.analysis.analysis.forecast.style.lineType = 'SOLID';
      this.data.analysis.analysis.forecast.style.lineThickness = 0;
      this.predictionLineForecastDataChangeNotification();
    }
  }

  public confidenceColorPickerShow(element: HTMLElement, color: string): void {
    this.colorPickerLayerShow(element, color, (colorHex: any): void => {
      const isChangedConfidenceColor: boolean = this.data.analysis.analysis.confidence.style.color !== colorHex;

      this.data.analysis.analysis.confidence.style.color = colorHex;

      this.data.analysis.analysis.confidence.style.predictColorUseFl = true;

      if (isChangedConfidenceColor) {
        this.predictionLineConfidenceDataChangeNotification();
      }
    });
  }

  public validateConfidenceTransparency(): (transparencyValue: number) => boolean {
    const messages = this.MESSAGE;
    return (transparencyValue: number) => {
      if (_.isUndefined(transparencyValue)) {
        this.alertPrimeService.warn(messages.PLEASE_TRANSPARENCY);
        return false;
      }

      if (Number(transparencyValue) < 0) {
        this.alertPrimeService.warn(messages.VALUES_LESS_THAN_0_CAN_NOT_BE_ENTERED);
        return false;
      }

      if (Number(transparencyValue) > 100) {
        this.alertPrimeService.warn(messages.VALUES_GREATER_THAN_100_CAN_NOT_BE_ENTERED);
        return false;
      }

      return true;
    };
  }

  public setConfidenceTransparency(transparencyValue: number) {
    this.data.analysis.analysis.confidence.style.transparency = Number(transparencyValue);

    this.predictionLineConfidenceDataChangeNotification();
  }

  public changeUseConfidence() {
    this.setConfidenceTransparency(this.data.isSelectedConfidence ? 10 : 0);
  }

  public onChangeForecastParameter(series: string): void {
    const forecastParameterIndex = this.forecastParameters.findIndex((item) => series === item);
    this.forecastParametersSelectedIndex = forecastParameterIndex;
    this.selectedForecastParameter = this.data.analysis.analysis.forecast.parameters[forecastParameterIndex];
  }

  public onChangeLineType(lineType: string): void {
    this.data.analysis.analysis.forecast.style.lineType = lineType;
    this.predictionLineForecastDataChangeNotification();
  }

  public onChangeLineWidth(lineWidth: string): void {
    this.data.analysis.analysis.forecast.style.lineThickness = Number(lineWidth);
    this.predictionLineForecastDataChangeNotification();
  }

  public clickDataSubPanel(dataSubLayerKey: string, event?: Event): void {
    event.stopPropagation();
    event.preventDefault();

    if (this.dataSubLayerKey === dataSubLayerKey) {
      this.data.isPredictionLineActive = false;
      this.dataSubLayerKey = '';
    } else {
      this.dataSubLayerKey = dataSubLayerKey;

      if (this.isValid()) {
        if (!_.isUndefined(this.widgetConfiguration.analysis) && !_.isEmpty(this.widgetConfiguration.analysis)) {
          this.data.isPredictionLineDisabled = true;
          this.data.isPredictionLineActive = true;
        } else {
          this.data.isPredictionLineDisabled = true;
          this.data.isPredictionLineActive = false;
        }
      }
    }
  }

  public colorPickerLayerShow(element: HTMLElement, color: string, callBackFn: Function): void {
    this.colorPickerLayerOffsetCalculator(element);

    this.colorPickerLayerComponent.show(color, callBackFn);
  }

  public colorPickerLayerOffsetCalculator(element: HTMLElement): void {
    const boundingClientRect = element.getBoundingClientRect();

    this.colorPickerLayerOffsetX = `${boundingClientRect.left}px`;

    const colorPickerLayerHeight = this.colorPickerLayerComponent.getHeight();

    const offsetTopValueCorrection = 6;

    const elementHeight: number = element.offsetWidth;

    const top = boundingClientRect.top + elementHeight + colorPickerLayerHeight;

    if (top >= $(window).outerHeight()) {
      this.colorPickerLayerOffsetY = `${boundingClientRect.top - colorPickerLayerHeight - offsetTopValueCorrection}px`;
    } else {
      this.colorPickerLayerOffsetY = `${boundingClientRect.top + elementHeight + offsetTopValueCorrection}px`;
    }
  }

  public colorPickerLayerSelected(event: { data: { className: string; colorHex: string }; fn: Function }): void {
    event.fn(event.data.colorHex);
  }

  private setPredictionLineDisabled(param: Object): Object {
    const pivot: Pivot = param['pivot'];
    const columns: PivotField[] = pivot['columns'];

    let disabled: Object;
    if (columns && columns.length > 0) {
      const field = columns[0];

      if (field && field.format && field.format.discontinuous) {
        disabled = false;
      } else if (
        field &&
        field.format &&
        !field.format.discontinuous &&
        field.format.unit &&
        field.format.unit.toString() == 'NONE'
      ) {
        disabled = false;
      } else if (columns.length > 1) {
        disabled = false;
      } else if (pivot.aggregations && pivot.aggregations.length > 0) {
        loop: for (const item of pivot.aggregations) {
          if (item.type == 'dimension') {
            disabled = false;
            break loop;
          }
        }
      }
    }
    return disabled;
  }

  private initializeAnalysisPredictionLine(): void {
    this.data.isAnalysisSettingsActive = true;

    this.data.isSelectedForecast = true;

    this.data.isSelectedConfidence = true;

    const _analysis: Analysis = new Analysis();
    _analysis.pivot = this.widgetConfiguration.pivot;
    this.data.analysis = _analysis;

    const analysisInAnalysis = new PredictionAnalysis();

    analysisInAnalysis.timeUnit = this.widgetConfiguration.pivot.columns[0].format.unit;
    analysisInAnalysis.interval = 12;

    analysisInAnalysis.confidence = new Confidence();
    analysisInAnalysis.confidence.confidenceInterval = this.DEFAULT_PREDICTION_LINE_SLIDER_FROM;
    analysisInAnalysis.confidence.style = new Style();

    const schema = this.uiOption.color['schema'];
    const colorCodes = _.cloneDeep(ChartColorList[schema]);

    analysisInAnalysis.confidence.style.color = colorCodes[0];

    analysisInAnalysis.confidence.style.transparency = 10;

    analysisInAnalysis.forecast = new Forecast();
    analysisInAnalysis.forecast.style = new Style();
    analysisInAnalysis.forecast.style.color = colorCodes[0];
    analysisInAnalysis.forecast.style.lineType = this.LINE_TYPE_LIST[0];
    analysisInAnalysis.forecast.style.lineThickness = Number(this.LINE_WIDTH_LIST[0]);

    this.widgetConfiguration.pivot.aggregations.forEach((agg) => {
      const hyperParameter = new HyperParameter();

      if (agg.alias) {
        hyperParameter.field = agg.alias;
      } else {
        if (agg.ref) {
          hyperParameter.field = `${agg.aggregationType + '(' + agg.ref + '.' + agg.name + ')'}`;
        } else {
          hyperParameter.field = `${agg.aggregationType + '(' + agg.name + ')'}`;
        }
      }

      analysisInAnalysis.forecast.parameters.push(_.cloneDeep(hyperParameter));
    });

    this.forecastParameters = [];

    analysisInAnalysis.forecast.parameters.forEach((param) => {
      this.forecastParameters.push(param.field);
    });

    this.forecastParametersSelectedIndex = 0;
    this.selectedForecastParameter = analysisInAnalysis.forecast.parameters[this.forecastParametersSelectedIndex];

    _analysis.analysis = analysisInAnalysis;

    this.data.analysis = _analysis;

    this.widgetConfiguration.analysis = new PredictionAnalysis();
    this.widgetConfiguration.analysis = this.data.analysis.analysis;
  }

  private isChartDrawed(): boolean {
    return this.isChartShow;
  }

  private isChartTypeLine(): boolean {
    return this.selectChart === GraphicType.LINE.toString().toLowerCase();
  }

  private predictionLineDataChangeNotification(): void {
    this.changeSubject$.next(this.data);
  }

  private predictionLineForecastDataChangeNotification(): void {
    this.changeForecastSubject$.next(this.data);
  }

  private predictionLineConfidenceDataChangeNotification(): void {
    this.changeConfidenceSubject$.next(this.data);
  }

  private validationPredictionInterval(predictionInterval: number | string): boolean {
    const message: string = this.MESSAGE.PLEASE_FORECAST_PERIOD;

    if (_.isUndefined(predictionInterval)) {
      this.alertPrimeService.warn(message);
      return false;
    }

    if (predictionInterval === '') {
      this.alertPrimeService.warn(message);
      return false;
    }

    if (_.isNumber(Number(predictionInterval)) === false) {
      this.alertPrimeService.warn(message);
      return false;
    }

    return true;
  }

  private getLineTypeFromLineTypeListToLineType(lineType: string): number {
    return this.LINE_TYPE_LIST.findIndex((item) => item === lineType);
  }

  private getLineThicknessFromLineWidthListToLineThickness(lineThickness: string): number {
    return this.LINE_WIDTH_LIST.findIndex((item) => Number(item) === Number(lineThickness));
  }

  private updateSelectedForecastParameter(index: number): void {
    this.data.analysis.analysis.forecast.parameters[index].field = this.selectedForecastParameter.field;

    this.data.analysis.analysis.forecast.parameters[index].alpha = this.selectedForecastParameter.alpha;

    this.data.analysis.analysis.forecast.parameters[index].beta = this.selectedForecastParameter.beta;

    this.data.analysis.analysis.forecast.parameters[index].gamma = this.selectedForecastParameter.gamma;

    this.data.analysis.analysis.forecast.parameters[index].period = this.selectedForecastParameter.period;

    this.data.analysis.analysis.forecast.parameters[index].multiple = this.selectedForecastParameter.multiple;
  }

  private removeAnalysisPredictionLine(): void {
    delete this.widgetConfiguration.analysis;
    this.data.analysis = this.widgetConfiguration.analysis;
    this.originWidgetConfigurationAnalysis = undefined;
  }
}
