import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'baseFilter',
  pure: false,
})
@Injectable()
export class BaseFilter implements PipeTransform {
  transform(items: any[], args: any[]): any[] {
    if (items == null || items.length === 0) {
      return items;
    }

    if (args.length > 1 && args[1] === 'all') {
      return items;
    }

    const filterText = args[1];

    let filterKey = null;

    let isExcept = false;

    if (args.length === 1) {
      if (typeof args[0] === 'string') {
        return items.filter((item) => {
          return item.toLowerCase ? item.toLowerCase().includes(args[0].toLowerCase()) : false;
        });
      }
      return items;
    } else if (args.length === 2) {
      filterKey = args[0];

      if (typeof filterText === 'string') {
        return items.filter((item) => item[filterKey].toLowerCase().indexOf(filterText.toLowerCase()) !== -1);
      } else if (typeof filterText === 'boolean') {
        return items.filter((item) => item[filterKey] === filterText);
      }
    } else if (args.length === 3) {
      filterKey = args[0];
      isExcept = args[2];

      if (isExcept) {
        if (filterKey) {
          return items.filter((item) => item[filterKey].indexOf(filterText) === -1);
        } else {
          return items.filter((item) => item.indexOf(filterText) === -1);
        }
      } else {
        if (filterKey) {
          return items.filter((item) => item[filterKey].indexOf(filterText) !== -1);
        } else {
          return items.filter((item) => item.indexOf(filterText) !== -1);
        }
      }
    } else {
      return items;
    }
  }
}
