import { isNullOrUndefined, isString } from 'util';

import { Component, ElementRef, EventEmitter, HostListener, Injector, Output, ViewChild } from '@angular/core';

import * as _ from 'lodash';

import {
  BoardDataSource,
  DatasourceField,
  FieldNameAlias,
  FieldRole,
  FieldValueAlias,
  Logical,
  LogicalType,
  createFieldNameAlias,
} from '@selfai-platform/bi-domain';

import { AbstractComponent } from '../../common/component/abstract.component';
import { StringUtil } from '../../common/util/string.util';
import { DatasourceAliasService } from '../../datasource/service/datasource-alias.service';
import { PopupValueAliasComponent } from '../page-pivot/popup-value-alias.component';

@Component({
  selector: 'page-data-context',
  templateUrl: './page-data-context.component.html',
  styleUrls: ['./page-data-context.component.scss'],
})
export class PageDataContextComponent extends AbstractComponent {
  @ViewChild(PopupValueAliasComponent, { static: true })
  private popupValueAlias: PopupValueAliasComponent;

  @ViewChild('fieldDetailLayer', { static: true })
  private _contextLayer: ElementRef;

  private _metatDatasource: BoardDataSource;

  public selectedField: DatasourceField;

  public editingFieldAlias: string;

  public isShowLayer = false;
  public fix2DepthContext = false;

  @Output()
  public openColumnDetailEvent: EventEmitter<DatasourceField> = new EventEmitter();

  @Output()
  public openCustomFieldEvent: EventEmitter<DatasourceField> = new EventEmitter();

  @Output()
  public deleteCustomFieldEvent: EventEmitter<DatasourceField> = new EventEmitter();

  @Output('changeAlias')
  public changeAliasEvent: EventEmitter<DatasourceField> = new EventEmitter();

  constructor(
    private aliasService: DatasourceAliasService,
    protected elementRef: ElementRef,
    protected injector: Injector,
  ) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  @HostListener('click', ['$event'])
  public clickListener() {
    if (!this.fix2DepthContext) {
      event.stopPropagation();

      this.isShowLayer = false;
    }
  }

  public init(
    field: DatasourceField,
    dataSource: BoardDataSource,
    $targetElm: any,
    isRightSide: boolean = false,
  ): boolean {
    if (this.selectedField === field) {
      this.isShowLayer = !this.isShowLayer;
      if (!this.isShowLayer) {
        return false;
      }
    }

    this._metatDatasource = dataSource;

    if (field.nameAlias) {
      this.editingFieldAlias = field.nameAlias.nameAlias;
    } else {
      field = this._initializeFieldForNameAlias(field);
      this.editingFieldAlias = '';
    }

    this.isShowLayer = true;

    this.selectedField = field;

    this.changeDetect.detectChanges();

    const $fieldDetailLayer: JQuery = $(this._contextLayer.nativeElement);
    const $leftOffset = $targetElm.offset().left;
    const $topOffset = $targetElm.offset().top;
    const $morePopHeight = $fieldDetailLayer.prop('scrollHeight');

    let leftPosAdjustValue = 20;
    if (isRightSide) {
      leftPosAdjustValue = 240;
      $fieldDetailLayer.find('.ddp-ui-layer-sub').css('left', '-80%');
    }

    if (this.$window.height() / 2 > $topOffset) {
      $fieldDetailLayer.css({
        left: $leftOffset - leftPosAdjustValue - this.$window.scrollLeft(),
        top: $topOffset + 19,
      });
    }

    if (this.$window.height() / 2 <= $topOffset) {
      $fieldDetailLayer.css({
        left: $leftOffset - leftPosAdjustValue - this.$window.scrollLeft(),
        top: $topOffset - $morePopHeight - 10,
      });
    }

    return true;
  }

  public unescapeCustomColumnExpr(expr: string) {
    return StringUtil.unescapeCustomColumnExpr(expr);
  }

  public customFieldEmit(selectedField: DatasourceField) {
    this.openCustomFieldEvent.emit(selectedField);
  }

  public deleteCustomField() {
    this.deleteCustomFieldEvent.emit(this.selectedField);
  }

  public columDetail(field: DatasourceField): void {
    this.openColumnDetailEvent.emit(field);
  }

  public showAliasValue(field: DatasourceField) {
    this.popupValueAlias.init(field, this._metatDatasource, field.valueAlias);
  }

  public onAliasApply(event: Event): void {
    event.stopPropagation();

    this.fix2DepthContext = false;

    if (isNullOrUndefined(this.editingFieldAlias) || '' === this.editingFieldAlias.trim()) {
      this.alertPrimeService.info(this.translateService.instant('msg.page.alert.chart.alias.empty.warn'));
      this.editingFieldAlias = null;
      return;
    }

    if (this.editingFieldAlias && this.editingFieldAlias.trim().length > 50) {
      this.alertPrimeService.info(this.translateService.instant('msg.page.alert.chart.title.maxlength.warn'));
      this.editingFieldAlias = null;
      return;
    }

    if (this.editingFieldAlias.toLowerCase() === this.selectedField.name.toLowerCase()) {
      this.alertPrimeService.info(this.translateService.instant('msg.page.alert.chart.alias.dupfield.warn'));
      this.editingFieldAlias = null;
      return;
    }

    if (this.editingFieldAlias.trim() == '') {
      this.onAliasReset(null);
      return;
    }

    this.selectedField.nameAlias.nameAlias = this.editingFieldAlias;

    this._changeAlias(this.editingFieldAlias);
  }

  public onAliasReset(event: Event): void {
    event && event.stopPropagation();

    this.selectedField.nameAlias.nameAlias = this.selectedField.name;
    this.editingFieldAlias = '';
    this.fix2DepthContext = false;

    this._changeAlias(this.editingFieldAlias);
  }

  public changeValueAlias(valueAlias: FieldValueAlias) {
    this.selectedField.valueAlias = valueAlias;
    this.changeAliasEvent.emit(this.selectedField);
  }

  public isAllowValueAlias(field: DatasourceField): boolean {
    return (
      field.role === FieldRole.DIMENSION &&
      field.logicalType === LogicalType.STRING &&
      field.type !== 'user_expr' &&
      isString(this.selectedField.nameAlias.dashBoardId)
    );
  }

  public isAllowNameAlias(field: DatasourceField): boolean {
    return field.type !== 'user_expr' && isString(this.selectedField.nameAlias.dashBoardId);
  }

  public getMetaDataLogicalTypeName(): string {
    let name = '';
    const metaData = this.selectedField.uiMetaData || this.selectedField;
    if (metaData) {
      switch (metaData.type) {
        case Logical.LNT:
          name = 'LATITUDE';
          break;
        case Logical.LNG:
          name = 'LONGITUDE';
          break;
        default:
          if (metaData.type) {
            name = metaData.type.toString();
          }
      }
    }
    return name;
  }

  private _changeAlias(nameAlias: string) {
    const param: FieldNameAlias = _.cloneDeep(this.selectedField.nameAlias);
    param.nameAlias = nameAlias;
    param.dataSourceId = this.selectedField.dsId;

    this.pageLoaderService.show();
    if (param.id) {
      if ('' === param.nameAlias) {
        this.aliasService
          .deleteAliases(param.id)
          .then(() => this._setResponse())
          .finally(() => {
            this.pageLoaderService.hide();
          });
      } else {
        this.aliasService
          .updateAliases(param.id, param)
          .then((item) => this._setResponse(<FieldNameAlias>item))
          .finally(() => {
            this.pageLoaderService.hide();
          });
      }
    } else {
      this.aliasService
        .createAliases(param)
        .then((item) => this._setResponse(<FieldNameAlias>item))
        .finally(() => {
          this.pageLoaderService.hide();
        });
    }
  }

  private _setResponse(item?: FieldNameAlias) {
    const field: DatasourceField = _.cloneDeep(this.selectedField);
    if (item) {
      field.nameAlias = item;
      this.selectedField.nameAlias = item;
    } else {
      delete field.nameAlias;
      this.selectedField = this._initializeFieldForNameAlias(field);
    }
    this.changeAliasEvent.emit(field);
    this.changeDetect.markForCheck();
    this.pageLoaderService.hide();
  }

  private _initializeFieldForNameAlias(field: DatasourceField): DatasourceField {
    const nameAlias: FieldNameAlias = createFieldNameAlias({
      dataSourceId: field.dsId,
      dashBoardId: field.boardId,
      fieldName: field.name,
      nameAlias: field.name,
    });
    field.nameAlias = nameAlias;
    return field;
  }
}
