import { generateUuid } from '@selfai-platform/shared';
import { Filter } from '../../workbook';
import { Widget, WidgetConfiguration } from './widget.model';

export interface FilterWidget extends Widget {
  type: 'filter';
  configuration: FilterWidgetConfiguration;
}

export function createFilterWidget(
  filter: Filter,
  dasboardId: string,
  params: Partial<FilterWidget> = {},
): FilterWidget {
  return {
    id: generateUuid(),
    name: filter.field || '',
    description: filter.field || '',
    type: 'filter',
    dashBoardId: dasboardId,
    configuration: createFilterWidgetConfiguration(filter),
    ...params,
  };
}

export interface FilterWidgetConfiguration extends WidgetConfiguration {
  filter: Filter;
}

export function createFilterWidgetConfiguration(
  filter: Filter,
  params: Partial<FilterWidgetConfiguration> = {},
): FilterWidgetConfiguration {
  return {
    type: 'filter',
    filter,
    ...params,
  };
}
