<div class="page-dashboard" [class.fixed-write]="ifWorkbook" id="scrollbar_style">
  <div class="save-changes" *ngIf="showSaveButton && isManagementUser && !readonly">
    <button
      title="{{ 'msg.dashboard.view.save.button' | translate }}"
      pButton
      pRipple
      type="button"
      icon="pi pi-save"
      class="p-button-raised"
      [disabled]="saveStateInProgress"
      (click)="updateDashboardGridItemPositions()"
    ></button>
  </div>
  <div
    *ngIf="dashboard && datasourceStatus === enumStatus.ENABLE && layoutComponents.length === 0"
    class="ddp-ui-widget ddp-type"
  >
    <div class="loading-wrapper-body">
      <div *ngIf="this._popupService.ptLoading" class="ddp-loading-part" style="top: 150px">
        <div class="lds-wedges"></div>
      </div>

      <div class="ddp-box-data-none">
        <div class="ddp-wrap-data-none">
          <div class="ddp-ui-data-none"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="widget-wrapper" *ngIf="dashboard && datasourceStatus === enumStatus.ENABLE">
    <selection-filter class="widget-wrapper_filter" [dashboard]="dashboard" [showBtnWidget]="true"></selection-filter>

    <div
      #layoutContainer
      class="ddp-ui-widget"
      *ngIf="{ activeMode: workbookActionsService.isActiveMode$ | async } as obs"
    >
      <ktd-grid
        #gridComponent
        [gap]="gap"
        [cols]="cols"
        [rowHeight]="rowHeight"
        [layout]="ktGridLayout"
        [scrollableParent]="layoutContainer"
        (layoutUpdated)="onLayoutUpdated($event)"
        (gridItemResize)="onGridItemResized($event)"
      >
        <ktd-grid-item
          *ngFor="let item of ktGridLayout; trackBy: trackById"
          [id]="item.id"
          [draggable]="obs.activeMode"
          [resizable]="obs.activeMode"
        >
          <dashboard-widget
            [widget]="item.widget"
            [widgetOpts]="item.widgetOpts"
            [layoutMode]="item.layoutMode"
            [widgetInfo]="item.widgetInfo"
            [readonly]="!obs.activeMode || standalone || readonly"
            [isDashboardLoaded]="true"
          ></dashboard-widget>
          <ng-template ktdGridItemPlaceholder>
            <div class="grid-placeholder"></div>
          </ng-template>
        </ktd-grid-item>
      </ktd-grid>
    </div>
  </div>

  <div *ngIf="dashboard && datasourceStatus === enumStatus.PREPARING" class="ddp-div-table">
    <div class="ddp-div-tablecell">
      <div *ngIf="1 === ingestionStatus.step" class="ddp-box-loading">
        <span class="ddp-data-loading">
          {{ 'msg.board.filter.ui.waiting' | translate }}
        </span>

        <div class="ddp-type-progressbar">
          <em class="ddp-data-progressbar"></em>
        </div>
      </div>

      <div *ngIf="2 === ingestionStatus.step" class="ddp-box-loading">
        <span *ngIf="'PROGRESS_GET_DATA_FROM_LINK_DATASOURCE' === ingestionStatus.message" class="ddp-data-loading">
          {{ 'msg.board.filter.ui.ingestion-step-1' | translate }}
        </span>

        <span *ngIf="'PROGRESS_LOAD_TEMP_DATASOURCE' === ingestionStatus.message" class="ddp-data-loading">
          {{ 'msg.board.filter.ui.ingestion-step-2' | translate }}
        </span>

        <div class="ddp-type-progressbar2">
          <em class="ddp-data-progressbar" [style.right]="100 - ingestionStatus.progress + '%'"></em>
        </div>
      </div>

      <button *ngIf="-1 === ingestionStatus.step" (click)="reIngestion()" class="ddp-btn-line">
        <em class="ddp-icon-refresh"></em>
        {{ 'msg.board.ui.tryagain' | translate }}
      </button>
    </div>
  </div>

  <div *ngIf="dashboard && datasourceStatus === enumStatus.DISABLE" class="ddp-div-table">
    <div class="ddp-div-tablecell">
      <span class="ddp-txt-condition ddp-missing">{{ 'msg.board.ui.missing_datasource' | translate }}</span>
      <span class="ddp-detail" [innerHTML]="'msg.board.ui.missing_datasource.detail' | translate"></span>
      <button (click)="reIngestion()" class="ddp-btn-line">
        <em class="ddp-icon-refresh"></em>
        {{ 'msg.board.ui.tryagain_ds' | translate }}
      </button>
    </div>
  </div>

  <div *ngIf="isError" class="ddp-div-table">
    <div class="ddp-div-tablecell">
      <span class="ddp-txt-condition ddp-missing">{{ 'msg.board.unload.title' | translate }}</span>
      <span class="ddp-detail" [innerHTML]="errorMsg"></span>
      <button (click)="reloadBoard()" class="ddp-btn-line">
        <em class="ddp-icon-refresh"></em>
        {{ 'msg.board.unload.reload' | translate }}
      </button>
    </div>
  </div>

  <app-confirm-modal (confirm)="confirm($event)"></app-confirm-modal>
</div>
