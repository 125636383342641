import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import {
  CubeEditResult,
  CubeWorkflowData,
  OperationParameter,
  OperationParameterChoiceValue,
  OperationType,
} from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { Observable } from 'rxjs';
import { WorkflowReportDomainService } from '../../../../../wokflow-list';
import { mapFormGroupValueToSerializedParameters, mapParametersToFormGroup } from '../map-parameters-form-group';

@Component({
  selector: 'selfai-platform-cms-form',
  templateUrl: './cms-form.component.html',
  styleUrls: ['./cms-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CmsFormComponent implements OnChanges, OnInit {
  @Input() fields: OperationParameter[] = [];
  @Input() form!: UntypedFormGroup;
  @Input() deepLevel = 1;

  operationType = OperationType;

  fieldsFromExecutionReport$: Observable<string[]> = this.workflowReportDomainService.getFieldsFromExecutionReport(
    this.dialogService.data.selectedNode.id,
  );

  constructor(
    private readonly dialogService: DialogService<CubeEditResult, CubeWorkflowData>,
    private readonly workflowReportDomainService: WorkflowReportDomainService,
  ) {}

  ngOnInit(): void {
    mapParametersToFormGroup(this.fields, mapFormGroupValueToSerializedParameters(this.form.value), this.form);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['fields']?.firstChange) {
      const fields: OperationParameter[] = changes['fields']?.currentValue;

      mapParametersToFormGroup(fields, {}, this.form);
    }
  }

  mapChoiceItems(values: OperationParameterChoiceValue[]): string[] {
    return values.map(({ name }) => name);
  }

  getChoiceFormGroup(fieldName: string): UntypedFormGroup {
    return this.form.get(fieldName) as UntypedFormGroup;
  }

  getFormGroupFields(formGroup: AbstractControl): UntypedFormGroup {
    return formGroup as UntypedFormGroup;
  }
}
