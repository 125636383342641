import { Injectable, Type } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef, DialogService as PrimeDialogService } from 'primeng/dynamicdialog';
import { EMPTY, Observable, of, tap } from 'rxjs';

@Injectable()
export class DialogService<ClosingCallbackData = undefined, InputToDialogData = undefined> {
  dialogRefs: DynamicDialogRef[] = [];
  currentDialogRef?: DynamicDialogRef;

  constructor(private readonly dialogService: PrimeDialogService) {}

  get data(): InputToDialogData | undefined {
    return (
      this.currentDialogRef && this.dialogService.dialogComponentRefMap.get(this.currentDialogRef)?.instance.config.data
    );
  }

  showDialog<T extends object>(
    component: Type<T>,
    options?: DynamicDialogConfig<InputToDialogData> & { maximized?: boolean },
  ): Observable<ClosingCallbackData> {
    this.currentDialogRef = this.dialogService.open(component, options || {});

    this.dialogRefs.push(this.currentDialogRef);
    this.adjustBody();

    if (options?.maximized) {
      const dialogComponentRef = this.dialogService.dialogComponentRefMap.get(this.currentDialogRef);
      dialogComponentRef?.instance.maximize();
    }

    return this.currentDialogRef.onClose.pipe(
      tap(() => {
        if (this.currentDialogRef) {
          this.currentDialogRef.destroy();
        }
        this.dialogRefs = this.dialogRefs.filter((ref) => ref !== this.currentDialogRef);
        this.currentDialogRef = this.dialogRefs[this.dialogRefs.length - 1];
      }),
    );
  }

  close(result?: ClosingCallbackData): void {
    this.currentDialogRef?.close(result);
  }

  private adjustBody(): void {
    if (this.hasBodyScroll()) {
      const config = { attributes: true, childList: false, subtree: false };
      document.body.style.paddingRight = `${this.getBodyScrollWidth()}px`;

      const observer = new MutationObserver(this.resetAdjustBody);
      observer.observe(document.body, config);
    }
  }

  private resetAdjustBody(mutationList: MutationRecord[], observer: MutationObserver): void {
    for (const mutation of mutationList) {
      if (mutation.type === 'attributes') {
        if (!(mutation.target as HTMLBodyElement).classList.contains('p-overflow-hidden')) {
          document.body.style.paddingRight = '';
          observer.disconnect();
        }
      }
    }
  }

  private hasBodyScroll(): boolean {
    return this.getBodyScrollWidth() > 0;
  }

  private getBodyScrollWidth(): number {
    return Math.abs(window.innerWidth - document.documentElement.clientWidth);
  }
}
