import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PresetsPermissionAction } from '@selfai-platform/shared';
import { ActionsForItemService, convertMessageToHtml, DataListItemAction } from '@selfai-platform/shell';
import { ConfirmationService } from 'primeng/api';
import { combineLatest, map, Observable } from 'rxjs';
import { WorkflowPresets } from '../interfaces';
import { WorkflowPresetsDomainService } from './workflow-presets-domain.service';
import { WorkflowPresetsPermissionService } from './workflow-presets-permission.service';

@Injectable({
  providedIn: 'root',
})
export class WorkflowPresetsActionForItemService extends ActionsForItemService<any> {
  constructor(
    private readonly workflowPresetsDomainService: WorkflowPresetsDomainService,
    private readonly confirmationService: ConfirmationService,
    private readonly workflowPresetsPermissionService: WorkflowPresetsPermissionService,
    private readonly translate: TranslateService,
  ) {
    super();
  }

  override getActionsForItem(preset: WorkflowPresets): Observable<DataListItemAction[]> {
    return combineLatest({
      tooltipTranslate: this.translate.get('workflow-list.table.action.item.delete'),
      deletePermission: this.workflowPresetsPermissionService.checkPermission(PresetsPermissionAction.Delete),
    }).pipe(
      map(({ tooltipTranslate, deletePermission }) => {
        if (deletePermission) {
          return [
            {
              label: tooltipTranslate,
              icon: 'pi pi-trash',
              action: () => this.delete(preset.id),
            },
          ];
        }

        return [];
      }),
    );
  }

  private delete(id: string): void {
    // TODO: Move to the confirmation with key = dialog to data list view
    this.confirmationService.confirm({
      key: 'dialog',
      message: convertMessageToHtml(
        this.translate.instant('workflow.preset.toolbar.actions.delete-selected.confirmation'),
      ),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.workflowPresetsDomainService.delete(id);
      },
    });
  }
}
