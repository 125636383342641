import { Injectable } from '@angular/core';

import { CookieService } from 'ng2-cookies';

import { SelfaiAuthService } from '@selfai-platform/shared';

import { CookieConstant } from '../common/constant/cookie.constant';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private readonly cookieService: CookieService, private readonly selfaiAuthService: SelfaiAuthService) {}

  public async setCredentialsFromFromProvider() {
    const token = await this.selfaiAuthService.getProvider().getToken();
    this.setAuthCookies(token);
  }

  public async updateToken() {
    await this.selfaiAuthService.getProvider().updateToken();
    await this.setCredentialsFromFromProvider();
  }

  public async logout() {
    await this.selfaiAuthService.getProvider().logout();
  }

  public setCredentialsFromToken(token: string, refreshToken?: string) {
    this.setAuthCookies(token, refreshToken);
  }

  private setAuthCookies(token: string, refreshToken?: string) {
    const userName = this.selfaiAuthService.getProvider().getUsername();
    this.cookieService.set(CookieConstant.KEY.LOGIN_TOKEN, token, 0, '/');
    this.cookieService.set(CookieConstant.KEY.LOGIN_TOKEN_TYPE, 'Bearer', 0, '/');
    this.cookieService.set(CookieConstant.KEY.LOGIN_USER_ID, userName, 0, '/');
    if (refreshToken) {
      this.cookieService.set(CookieConstant.KEY.REFRESH_LOGIN_TOKEN, refreshToken, 0, '/');
    }
  }
}
