import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityDataService } from '@ngrx/data';
import { WorkflowApi, WorkflowSerialized } from '@selfai-platform/pipeline-common';
import { KE_WORKFLOW_ENTITY_NAME } from '@selfai-platform/store';
import { WorkflowDataService } from '../services/workflow/workflow-data.service';

export type WorkflowState = WorkflowSerialized & Omit<WorkflowApi, 'wofkflow'>;

@Injectable({ providedIn: 'root' })
export class WorkflowStore extends EntityCollectionServiceBase<WorkflowState> {
  constructor(
    entityDataService: EntityDataService,
    dataService: WorkflowDataService,
    serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    entityDataService.registerService(KE_WORKFLOW_ENTITY_NAME, dataService);
    super(KE_WORKFLOW_ENTITY_NAME, serviceElementsFactory);
  }
}
