export const DataSourcesIcon = {
  LIBRARY: 'pi pi-book text-purple-400',
  DATABASE: 'pi pi-database text-primary',
  EXTERNAL_FILE: 'pi pi-file text-orange-700',
  GOOGLE_SP: 'pi pi-table text-green-400',
  HD: 'pi pi-server text-blue-400',
  KAFKA: 'pi pi-arrow-right-arrow-left text-indigo-400',
  S3: 'pi pi-shopping-bag text-red-700',
} as const;

export type DataSourcesIcon = typeof DataSourcesIcon[keyof typeof DataSourcesIcon];
