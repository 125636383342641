<div class="field p-float-label p-input-icon-right w-full">
  <input class="w-full" type="text" pInputText [formControl]="formControl" />
  @if(formControl.dirty) {
  <i *ngIf="formControl.valid" class="pi pi-check"></i>
  <i
    *ngIf="formControl.invalid && formControl.errors?.['notUniq']"
    class="pi pi-exclamation-triangle"
    pTooltip="Name must be unique"
    tooltipPosition="left"
    tooltipStyleClass="p-tooltip-error"
  ></i>
  }
  <label>Unique Name</label>
</div>
