import { DashobardFieldType } from '../../enums/field-type.enum';
import { DashboardField, createDashboardField } from './field.model';

export interface UserDefinedField extends DashboardField {
  ref: 'user_defined';
}

export function createUserDefinedField(params: Partial<UserDefinedField> = {}): UserDefinedField {
  return {
    ...createDashboardField(),
    type: DashobardFieldType.USER_DEFINED,
    ref: 'user_defined',
    ...params,
  };
}
