import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';

import { isNullOrUndefined } from '@selfai-platform/shared';


import { AbstractComponent } from '../abstract.component';

@Component({
  selector: 'component-select',
  templateUrl: './select.component.html',
  host: {
    '(document:click)': 'onClickHost($event)',
  },
})
export class SelectComponent extends AbstractComponent implements OnInit {
  public array: any[];

  @Input('array')
  set setArray(array: any) {
    this.array = array;

    if (this.array != null && this.array.length > 0) {
      if (typeof this.array[0] === 'string') {
        this.isStringArray = true;
      }
    }

    this.selectedItem = null;

    this.setSelectedItem();
  }

  @Input() public viewKey: string;

  @Input() public isUpSelect = false;

  public defaultIndex = -1;

  @Input('defaultIndex')
  set setDefaultIndex(index: number) {
    this.defaultIndex = index;

    if (this.array && this.array.hasOwnProperty('length') && this.array.length > 0) {
      if (this.defaultIndex > -1) {
        this.selectedItem = this.array[this.defaultIndex];
      } else {
        this.selectedItem = null;
      }
    }
  }

  @Input() public className = '';

  @Input() public usePlaceholder = false;

  @Input() public unselectedMessage = this.translateService.instant('msg.comm.ui.please.select');

  @Input() public disabled = false;

  @Output() public onSelected = new EventEmitter();

  @Output() public beforeShowSelectedList = new EventEmitter();

  @Input() public inline = false;

  @Input() public noWrapFl: boolean;

  @Input() public isShowSelectListForOutSide = true;

  @Input() public isFull = false;

  @Input() public isOptionToLeft = false;

  @Input() public isDataprep = false;

  @Input() public isIconType = false;

  @Input() public isEllipsis?: boolean = true;

  public selectedItem: any;

  public isShowSelectList = false;

  public isStringArray = false;

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    if (this.array != null && this.array.length > 0) {
      if (typeof this.array[0] === 'string') {
        this.isStringArray = true;
      }
    }

    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public clearSelect() {
    this.selectedItem = null;
  }

  public selected(item: any) {
    this.selectedItem = item;

    this.onSelected.emit(item);
  }

  public getSelectedItem() {
    return this.selectedItem;
  }

  public onClickHost(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isShowSelectList = false;
    }
  }

  public setDisable() {
    this.disabled = true;
  }

  public setEnable() {
    this.disabled = false;
  }

  public toggleSelectList() {
    this.isShowSelectList = !this.isShowSelectList;
    this.beforeShowSelectedList.emit(this.isShowSelectList);
  }

  public getIconClass(item: any) {
    let iconClass = '';

    if (isNullOrUndefined(item.field)) {
      return iconClass;
    }

    if (!isNullOrUndefined(item.isCustomField) && item.isCustomField) {
      return iconClass;
    }

    if (item.field.role.toString() == 'DIMENSION') {
      switch (item.field.logicalType.toString()) {
        case 'STRING':
          iconClass = 'ddp-icon-dimension-ab';
          break;
        case 'LNG':
          iconClass = 'ddp-icon-dimension-local';
          break;
        case 'TIMESTAMP':
          iconClass = 'ddp-icon-dimension-calen';
          break;
        case 'INTEGER':
          iconClass = 'ddp-icon-dimension-float';
          break;
        case 'BOOLEAN':
          iconClass = 'ddp-icon-dimension-tf';
          break;
        case 'DOUBLE':
          iconClass = 'ddp-icon-dimension-float';
          break;
      }
    } else {
      switch (item.field.logicalType.toString()) {
        case 'INTEGER':
          iconClass = 'ddp-icon-measure-sharp';
          break;
        case 'DOUBLE':
          iconClass = 'ddp-icon-measure-sharp';
          break;
        default:
          iconClass = 'ddp-icon-measure-sharp';
          break;
      }
    }
    return iconClass;
  }

  private setSelectedItem() {
    if (
      !this.selectedItem &&
      this.usePlaceholder === false &&
      this.array &&
      this.array.hasOwnProperty('length') &&
      this.array.length > 0
    ) {
      this.selectedItem = this.array[0];
      if (this.defaultIndex > -1) {
        this.selectedItem = this.array[this.defaultIndex];
      }
    }
  }
}
