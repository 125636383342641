import { Injectable } from '@angular/core';
import { EntityCollectionServiceBase, EntityCollectionServiceElementsFactory, EntityDataService } from '@ngrx/data';
import { KE_DATA_SOURCES_LIBRARY_ENTITY_NAME } from '@selfai-platform/store';
import { DataSourcesLibraryFilesInterface } from '../model/data-sources-library-files.model';
import { KeDataSourcesLibraryService } from './data-sources-library.service';

@Injectable({ providedIn: 'root' })
export class KeDataSourcesLibraryStore extends EntityCollectionServiceBase<DataSourcesLibraryFilesInterface> {
  constructor(
    public entityDataService: EntityDataService,
    public keDataSourcesLibraryService: KeDataSourcesLibraryService,
    public serviceElementsFactory: EntityCollectionServiceElementsFactory,
  ) {
    entityDataService.registerService(KE_DATA_SOURCES_LIBRARY_ENTITY_NAME, keDataSourcesLibraryService);
    super(KE_DATA_SOURCES_LIBRARY_ENTITY_NAME, serviceElementsFactory);
  }
}
