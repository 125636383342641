import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { PipelineConfigService } from '@selfai-platform/shared';
import { KE_DATA_SOURCES_ADDITIONS_PARAM } from '@selfai-platform/store';
import { map, Observable } from 'rxjs';
import { DataSourcesAdditions, DataSourcesAdditionsOriginalTrim } from '../model/data-sources-additions.model';

@Injectable({
  providedIn: 'root',
})
export class DataSourcesAdditionsDataService extends DefaultDataService<DataSourcesAdditions> {
  config = this.pipelineConfigService.getConfig();
  apiUrl = `${this.config.hosts.api}/${this.config.versions.url}`;

  constructor(
    public override http: HttpClient,
    public override httpUrlGenerator: HttpUrlGenerator,
    private pipelineConfigService: PipelineConfigService,
  ) {
    super(KE_DATA_SOURCES_ADDITIONS_PARAM, http, httpUrlGenerator);
  }

  override getWithQuery(type: string): Observable<DataSourcesAdditions[]> {
    return this.http
      .get<DataSourcesAdditionsOriginalTrim[]>(`${this.apiUrl}/operations/metadata/schema/readdataframe`)
      .pipe(
        map((data) => {
          return data[0].values
            .find((el) => {
              return el.name === type;
            })
            ?.schema.filter((el) => {
              return el.isOptional;
            })
            .map((el) => {
              return { key: el.name, type: el.type };
            });
        }),
      );
  }
}
