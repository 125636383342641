import { Injectable } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Observable, map } from 'rxjs';
import { BreadcrumbsMenuItem } from './breadcrumbs-menu.model';
import { BreadcrumbsMenuStore } from './breadcrumbs-menu.store';

export interface BreadcrumbsMenu {
  getBreadcrumbsMenu(...args: unknown[]): Observable<BreadcrumbsMenuItem[]>;
}

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsMenuService {
  private overlayPanel: OverlayPanel | null = null;

  constructor(private readonly breadcrumbMenuStore: BreadcrumbsMenuStore) {}

  setOverlayPanel(overlayPanel: OverlayPanel) {
    this.overlayPanel = overlayPanel;
  }

  hideOverlayPanel() {
    if (this.overlayPanel) {
      this.overlayPanel.hide();
    }
  }

  setBreadcrumbsMenu(breadcrumbMenuItems: BreadcrumbsMenuItem[]): void {
    this.breadcrumbMenuStore.upsertOneInCache({ id: 'breadcrumb', breadcrumbMenuItems });
  }

  getBreadcrumbsMenu(): Observable<BreadcrumbsMenuItem[] | undefined> {
    return this.breadcrumbMenuStore.entityMap$.pipe(map((entityMap) => entityMap['breadcrumb']?.breadcrumbMenuItems));
  }

  clearBreadcrumbsMenu(): void {
    this.breadcrumbMenuStore.clearCache();
  }
}
