import { ChartType } from '@selfai-platform/bi-domain';

import { IPageChart } from './page-section-chart.component.interfaces';

export const CHARTS: IPageChart[] = [
  {
    name: ChartType.BAR,
    title: 'msg.page.ui.bar',
    data: [
      {
        title: 'msg.page.ui.row',
        values: ['msg.page.ui.more-than-one.dimension.value'],
      },
      {
        title: 'msg.page.ui.intersect',
        values: ['msg.page.ui.more-than-one.measured.value'],
      },
    ],
  },
  {
    name: ChartType.GRID,
    title: 'msg.page.ui.grid',
    data: [
      {
        title: 'msg.page.ui.row-or-col',
        values: ['msg.page.ui.more-than-one.dimension.value'],
      },
      {
        title: 'msg.page.ui.intersect',
        values: ['msg.page.ui.more-than-one.measured.value'],
      },
    ],
  },
  {
    name: ChartType.LINE,
    title: 'msg.page.ui.line',
    data: [
      {
        title: 'msg.page.ui.row',
        values: ['msg.page.ui.more-than-one.dimension.value'],
      },
      {
        title: 'msg.page.ui.intersect',
        values: ['msg.page.ui.more-than-one.measured.value'],
      },
    ],
  },
  {
    name: ChartType.SCATTER,
    title: 'msg.page.ui.scatter',
    data: [
      {
        title: 'msg.page.ui.row',
        values: ['msg.page.ui.one.measured.value'],
      },
      {
        title: 'msg.page.ui.col',
        values: ['msg.page.ui.one.measured.value'],
      },
    ],
  },
  {
    name: ChartType.HEATMAP,
    title: 'msg.page.ui.heatmap',
    data: [
      {
        title: 'msg.page.ui.row-or-col',
        values: ['msg.page.ui.more-than-one.dimension.value'],
      },
      {
        title: 'msg.page.ui.intersect',
        values: ['msg.page.ui.one.measured.value'],
      },
    ],
  },
  {
    name: ChartType.PIE,
    title: 'msg.page.ui.pie',
    data: [
      {
        title: 'msg.page.ui.intersect',
        values: ['msg.page.ui.one.dimension.value', 'msg.page.ui.one.measured.value'],
      },
    ],
  },
  {
    name: ChartType.LABEL,
    title: 'msg.page.ui.label',
    data: [
      {
        title: 'msg.page.ui.intersect',
        values: ['msg.page.ui.more-than-one.measured.value'],
      },
    ],
  },
  {
    name: ChartType.BOXPLOT,
    title: 'msg.page.ui.boxplot',
    data: [
      {
        title: 'msg.page.ui.row',
        values: ['msg.page.ui.more-than-one.dimension.value'],
      },
      {
        title: 'msg.page.ui.col',
        values: ['msg.page.ui.one.dimension.value'],
      },
      {
        title: 'msg.page.ui.intersect',
        values: ['msg.page.ui.one.measured.value'],
      },
    ],
  },
  {
    name: ChartType.WATERFALL,
    title: 'msg.page.ui.waterfall',
    data: [
      {
        title: 'msg.page.ui.row',
        values: ['msg.page.ui.one.time.dimension.value'],
      },
      {
        title: 'msg.page.ui.intersect',
        values: ['msg.page.ui.one.measured.value'],
      },
    ],
  },
  {
    name: ChartType.WORDCLOUD,
    title: 'msg.page.ui.wordcloud',
    data: [
      {
        title: 'msg.page.ui.intersect',
        values: ['msg.page.ui.more-than-one.dimension.value', 'msg.page.ui.one.measured.value'],
      },
    ],
  },
  {
    name: ChartType.COMBINE,
    title: 'msg.page.ui.combine',
    data: [
      {
        title: 'msg.page.ui.row',
        values: ['msg.page.ui.more-than-one.dimension.value'],
      },
      {
        title: 'msg.page.ui.intersect',
        values: ['msg.page.ui.two-to-four.measured.value'],
      },
    ],
  },
  {
    name: ChartType.TREEMAP,
    title: 'msg.page.ui.treemap',
    data: [
      {
        title: 'msg.page.ui.row',
        values: ['msg.page.ui.one.dimension.value'],
      },
    ],
  },
  {
    name: ChartType.RADAR,
    title: 'msg.page.ui.radar',
    data: [
      {
        title: 'msg.page.ui.intersect',
        values: ['msg.page.ui.one.dimension.value', 'msg.page.ui.more-than-one.measured.value'],
      },
    ],
  },
  {
    name: ChartType.GRAPH,
    title: 'msg.page.ui.graph',
    data: [
      {
        title: 'msg.page.ui.sub.shelf',
        values: ['msg.page.ui.one.dimension.value'],
      },
      {
        title: 'msg.page.ui.target.shelf',
        values: ['msg.page.ui.one.dimension.value'],
      },
      {
        title: 'msg.page.ui.conn.shelf',
        values: ['msg.page.ui.one.measured.value'],
      },
    ],
  },
  {
    name: ChartType.GAUGE,
    title: 'msg.page.ui.gauge',
    data: [
      {
        title: 'msg.page.ui.col',
        values: ['msg.page.ui.more-than-one.dimension.value'],
      },
      {
        title: 'msg.page.ui.intersect',
        values: ['msg.page.ui.one.measured.value'],
      },
    ],
  },
  {
    name: ChartType.SANKEY,
    title: 'msg.page.ui.sankey',
    data: [
      {
        title: 'msg.page.ui.row',
        values: ['msg.page.ui.more-than-two.dimension.value'],
      },
      {
        title: 'msg.page.ui.intersect',
        values: ['msg.page.ui.one.measured.value'],
      },
    ],
  },
];
