import { DatasourceField } from '../../../../datasource';
import { TimeFilter, createTimeFilter } from './time-filter.model';

export const TIME_RANGE_FILTER_EARLIEST_DATETIME = 'EARLIEST_DATETIME';
export const TIME_RANGE_FILTER_LATEST_DATETIME = 'LATEST_DATETIME';

export interface TimeRangeFilter extends TimeFilter {
  intervals: string[];
}

export function createTimeRangeFilter(field: DatasourceField): TimeRangeFilter {
  return {
    ...createTimeFilter(field),
    type: 'time_range',
    intervals: [],
  };
}
