import { Injectable } from '@angular/core';
import {
  CreateDataFrameRawParameters,
  CubeComment,
  CubeEditResult,
  CubeWorkflowData,
  EnrichmentToolBatchRawParameters,
  EnrichmentToolRawParameters,
  ExternalSqlQueryRawParameters,
  GraphNodeOptions,
  ReadDataFrameRawParameters,
  WorkflowExpressionData,
  WorkflowJsonToColumnTransformationData,
  WorkflowRunnerData, WorkflowRunnerLegacyData,
  WorkflowTriggerData,
  WriteDataFrameRawParameters,
  WriteDataFrameTransitRawParameters,
} from '@selfai-platform/pipeline-common';
import { DialogService } from '@selfai-platform/shell';
import { EMPTY, Observable } from 'rxjs';
import {
  CmsFormCreatorComponent,
  CodeEditorTransformationComponent,
  CommentComponent,
  CreateTriggerComponent,
  ExpressionToolComponent,
  FavoriteCubeAddComponent,
  JsonToColumnTransformationComponent,
  ReadDataFrameComponent,
  WriteDataFrameComponent,
  WriteDataFrameTransitComponent,
} from '../components';
import { CreateDataFrameContainerComponent } from '../components/dialogs/create-data-frame';
import { EnrichmentToolComponent } from '../components/dialogs/enrichment-tool';
import { EnrichmentToolBatchComponent } from '../components/dialogs/enrichment-tool-batch';
import { ExternalSqlQueryComponent } from '../components/dialogs/external-sql-query';
import { CubeDialogManagementService } from './cube-dialog-management.service';
import { WorkflowRunnerComponent } from '../components/dialogs/workflow-runner/workflow-runner.component';

@Injectable()
export class CubeDialogService {
  constructor(
    private readonly dialogService: DialogService<CubeEditResult, CubeWorkflowData | CubeComment>,
    private readonly favoriteDialogService: DialogService<never, GraphNodeOptions>,
    private readonly dialogManagementService: CubeDialogManagementService,
  ) {}

  showCreateTrigger(data: CubeWorkflowData<WorkflowTriggerData>): Observable<CubeEditResult> {
    if (this.dialogManagementService.isExist(data.selectedNode.id)) {
      this.dialogManagementService.expand(data.selectedNode.id);

      return EMPTY;
    }
    return this.dialogService.showDialog(CreateTriggerComponent, {
      data,
      modal: false,
      draggable: true,
      resizable: true,
      closeOnEscape: false,
      showHeader: true,
      width: '75%',
      height: '90vh',
      maximizable: true,
      duplicate: true,
    });
  }

  showReadDataFrame(data: CubeWorkflowData<ReadDataFrameRawParameters>): Observable<CubeEditResult> {
    if (this.dialogManagementService.isExist(data.selectedNode.id)) {
      this.dialogManagementService.expand(data.selectedNode.id);

      return EMPTY;
    }
    return this.dialogService.showDialog(ReadDataFrameComponent, {
      data,
      modal: false,
      draggable: true,
      resizable: true,
      maximizable: true,
      autoZIndex: true,
      closeOnEscape: false,
      showHeader: true,
      width: '50%',
      duplicate: true,
      contentStyle: { height: '50vh' },
    });
  }

  showWriteDataFrameTransit(data: CubeWorkflowData<WriteDataFrameTransitRawParameters>): Observable<CubeEditResult> {
    if (this.dialogManagementService.isExist(data.selectedNode.id)) {
      this.dialogManagementService.expand(data.selectedNode.id);

      return EMPTY;
    }
    return this.dialogService.showDialog(WriteDataFrameTransitComponent, {
      data,
      modal: false,
      draggable: true,
      maximizable: true,
      resizable: true,
      autoZIndex: true,
      closeOnEscape: false,
      showHeader: true,
      width: '50%',
      duplicate: true,
    });
  }

  showWriteDataFrame(data: CubeWorkflowData<WriteDataFrameRawParameters>): Observable<CubeEditResult> {
    if (this.dialogManagementService.isExist(data.selectedNode.id)) {
      this.dialogManagementService.expand(data.selectedNode.id);

      return EMPTY;
    }
    return this.dialogService.showDialog(WriteDataFrameComponent, {
      data,
      modal: false,
      draggable: true,
      resizable: true,
      maximizable: true,
      closeOnEscape: false,
      showHeader: true,
      width: '50%',
      duplicate: true,
    });
  }

  showCreateDataFrame(data: CubeWorkflowData<CreateDataFrameRawParameters>): Observable<CubeEditResult> {
    return this.dialogService.showDialog(CreateDataFrameContainerComponent, {
      data,
      modal: false,
      draggable: true,
      maximizable: true,
      closeOnEscape: false,
      showHeader: false,
      width: '90%',
    });
  }

  showExternalSqlQuery(data: CubeWorkflowData<ExternalSqlQueryRawParameters>): Observable<CubeEditResult> {
    if (this.dialogManagementService.isExist(data.selectedNode.id)) {
      this.dialogManagementService.expand(data.selectedNode.id);

      return EMPTY;
    }
    return this.dialogService.showDialog(ExternalSqlQueryComponent, {
      data,
      modal: false,
      draggable: true,
      maximizable: true,
      closeOnEscape: false,
      showHeader: true,
      width: '50%',
      resizable: true,
      duplicate: true,
    });
  }

  showEnrichmentTool(data: CubeWorkflowData<EnrichmentToolRawParameters>): Observable<CubeEditResult> {
    if (this.dialogManagementService.isExist(data.selectedNode.id)) {
      this.dialogManagementService.expand(data.selectedNode.id);

      return EMPTY;
    }
    return this.dialogService.showDialog(EnrichmentToolComponent, {
      data,
      maximizable: true,
      closeOnEscape: false,
      showHeader: true,
      width: '50%',
      modal: false,
      draggable: true,
      resizable: true,
      duplicate: true,
    });
  }

  showEnrichmentToolBatch(data: CubeWorkflowData<EnrichmentToolBatchRawParameters>): Observable<CubeEditResult> {
    if (this.dialogManagementService.isExist(data.selectedNode.id)) {
      this.dialogManagementService.expand(data.selectedNode.id);

      return EMPTY;
    }
    return this.dialogService.showDialog(EnrichmentToolBatchComponent, {
      data,
      modal: false,
      draggable: true,
      maximizable: true,
      closeOnEscape: false,
      showHeader: true,
      width: '50%',
      resizable: true,
      duplicate: true,
    });
  }

  showJsonToColumnTransformation(
    data: CubeWorkflowData<WorkflowJsonToColumnTransformationData>,
  ): Observable<CubeEditResult> {
    if (this.dialogManagementService.isExist(data.selectedNode.id)) {
      this.dialogManagementService.expand(data.selectedNode.id);

      return EMPTY;
    }
    return this.dialogService.showDialog(JsonToColumnTransformationComponent, {
      data,
      modal: false,
      draggable: true,
      closeOnEscape: false,
      showHeader: true,
      width: '75%',
      maximizable: true,
      resizable: true,
      duplicate: true,
    });
  }

  showWorkflowRunner(
    data: CubeWorkflowData<WorkflowRunnerLegacyData>
  ): Observable<CubeEditResult> {
    if (this.dialogManagementService.isExist(data.selectedNode.id)) {
      this.dialogManagementService.expand(data.selectedNode.id);

      return EMPTY;
    }
    return this.dialogService.showDialog(WorkflowRunnerComponent, {
      data,
      modal: false,
      draggable: true,
      closeOnEscape: false,
      showHeader: true,
      width: '75%',
      maximizable: true,
      resizable: true,
      duplicate: true,
      focusOnShow: false,
    });
  }

  showComment(data: CubeComment): Observable<CubeEditResult> {
    return this.dialogService.showDialog(CommentComponent, {
      header: 'Comment',
      data,
    });
  }

  showExpressionTool(data: CubeWorkflowData<WorkflowExpressionData>): Observable<CubeEditResult> {
    if (this.dialogManagementService.isExist(data.selectedNode.id)) {
      this.dialogManagementService.expand(data.selectedNode.id);

      return EMPTY;
    }
    return this.dialogService.showDialog(ExpressionToolComponent, {
      data,
      closeOnEscape: false,
      showHeader: true,
      width: '75%',
      maximizable: true,
      modal: false,
      draggable: true,
      resizable: true,
      duplicate: true,
    });
  }

  showCodeEditorTransformation(data: CubeWorkflowData): Observable<CubeEditResult> {
    if (this.dialogManagementService.isExist(data.selectedNode.id)) {
      this.dialogManagementService.expand(data.selectedNode.id);

      return EMPTY;
    }
    return this.dialogService.showDialog(CodeEditorTransformationComponent, {
      data,
      modal: false,
      closeOnEscape: false,
      showHeader: true,
      width: '75%',
      height: '90vh',
      maximizable: true,
      draggable: true,
      resizable: true,
      duplicate: true,
    });
  }

  showCmsFormCreator(data: CubeWorkflowData): Observable<CubeEditResult> {
    if (this.dialogManagementService.isExist(data.selectedNode.id)) {
      this.dialogManagementService.expand(data.selectedNode.id);

      return EMPTY;
    }
    return this.dialogService.showDialog(CmsFormCreatorComponent, {
      data,
      closeOnEscape: false,
      showHeader: true,
      width: '75%',
      height: '90vh',
      maximizable: true,
      draggable: true,
      resizable: true,
      duplicate: true,
      modal: false,
    });
  }

  showFavoriteAddForm(data: GraphNodeOptions): Observable<void> {
    return this.favoriteDialogService.showDialog(FavoriteCubeAddComponent, {
      data,
      width: '40%',
      header: 'Add to favorites',
    });
  }
}
