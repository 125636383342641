import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AggregatorProcessApi, AggregatorProcessListApi } from '../../models';
import { AggregatorCoreApiService } from './aggregator-core-api.service';
import { AggregatorAdapter } from './aggregator.adapter';
import { AggrtConfigService } from './aggrt-config.service';

@Injectable({
  providedIn: 'root',
})
export class AggregatorProcessApiService extends AggregatorCoreApiService implements AggregatorAdapter {
  loadProcessWithRoutes(processUuid: string): Observable<AggregatorProcessApi> {
    const url = this.buildUrl(`/routes/getCurrent/${processUuid}`);

    return this.http.get<AggregatorProcessApi>(url);
  }

  saveRoutes(processWithRoutes: AggregatorProcessApi): Observable<void> {
    const url = this.buildUrl('/routes/update');

    return this.http.post<void>(url, processWithRoutes);
  }

  loadProcessList(): Observable<AggregatorProcessListApi> {
    const url = this.buildUrl('/routes/getProcessesList');

    return this.http.get<AggregatorProcessListApi>(url);
  }

  loadProcessListFull(): Observable<AggregatorProcessApi[]> {
    const url = this.buildUrl('/routes/getAll');

    return this.http.get<AggregatorProcessApi[]>(url);
  }

  constructor(private readonly http: HttpClient, aggrtConfigService: AggrtConfigService) {
    super(aggrtConfigService);
  }
}
