import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CubeComment, CubeEditResult } from '@selfai-platform/pipeline-common';
import { DestroyService } from '@selfai-platform/shared';
import { DialogService } from '@selfai-platform/shell';
import { WorkflowStateService } from '@selfai-platform/storage';
import { BehaviorSubject, take, takeUntil } from 'rxjs';
import { WorkflowEditorFacadeService } from '../../../../workflow-editor';

@Component({
  selector: 'selfai-platform-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class CommentComponent implements OnInit {
  comment = '';
  readonly showLoader$ = new BehaviorSubject<boolean>(false);
  private nodeId: string | undefined;

  constructor(
    private readonly dialogService: DialogService<CubeEditResult, CubeComment>,
    private readonly workflowStateService: WorkflowStateService,
    private readonly destroy$: DestroyService,
    private readonly cdr: ChangeDetectorRef,
    private readonly workflowEditorFacadeService: WorkflowEditorFacadeService,
  ) {}

  ngOnInit(): void {
    this.nodeId = this.dialogService.data?.nodeId;
    if (this.nodeId) {
      this.workflowStateService
        .getWorkflowState()
        .pipe(take(1), takeUntil(this.destroy$))
        .subscribe((workflow) => {
          if (workflow) {
            const { thirdPartyData } = workflow;

            thirdPartyData.cubeComments?.forEach((cubeComment) => {
              if (this.nodeId === cubeComment.nodeId) {
                this.comment = cubeComment.comment;
                this.cdr.markForCheck();
              }
            });
          }
        });
    }
  }

  onClickSave(): void {
    if (!this.nodeId) {
      return;
    }
    this.showLoader$.next(true);
    this.workflowEditorFacadeService.saveNodeComment({
      nodeId: this.nodeId,
      comment: this.comment,
    });
    setTimeout(() => {
      this.showLoader$.next(false);
      this.dialogService.close();
    }, 1000); // fake timeout, need for load correct comment when reopen it after save
  }
}
