import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  booleanAttribute,
} from '@angular/core';
import { TemplateNameDirective, arrayAttribute } from '@selfai-platform/shared';
import { FilterService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { getUiSettingsDefaultPageSizes } from '../../ui-settings';
import { slotSelectors } from '../constants';
import { DataListViewItem, GroupAction, LayoutType, TableColumn, ToolbarAction } from '../models';
import { LayoutTypeService } from '../services';
import { DataListViewGridComponent } from './data-list-view-grid';
import { DataListViewTableComponent } from './data-list-view-table';
import { DataListViewTemplateNameDirective } from './data-list-view-template-name.directive';
import { DataListViewToolbarComponent } from './data-list-view-toolbar';

@Component({
  selector: 'selfai-platform-data-list-view',
  standalone: true,
  imports: [
    CommonModule,
    ConfirmPopupModule,
    ConfirmDialogModule,
    DataListViewToolbarComponent,
    DataListViewTableComponent,
    DataListViewGridComponent,
    TemplateNameDirective,
  ],
  templateUrl: './data-list-view.component.html',
  styleUrls: ['./data-list-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FilterService],
})
export class DataListViewComponent<T extends object & DataListViewItem> {
  @Input() layoutTypes: Array<LayoutType> = Object.values(LayoutType);
  @Input({ transform: arrayAttribute }) orderByOptions: Array<{ label: string; value: keyof T | string }> = [];
  /**
   * Actions for selected items
   */
  @Input({ transform: arrayAttribute }) groupActions: GroupAction[] = [];
  @Input({ transform: arrayAttribute }) actions: ToolbarAction[] = [];
  @Input() pageSizes: number[] = getUiSettingsDefaultPageSizes();
  @Input({ required: true }) columns: TableColumn<T>[];
  @Input({ transform: booleanAttribute }) showEntityIcon = false;
  @Input() emptyMessage?: string;
  @Input() dataIsLazy = false;
  @Input() globalFilterFields?: string[];

  @Output() renameItem = new EventEmitter<T>();
  @Output() toggleFavorite = new EventEmitter<{ itemId: string; isFavorite: boolean }>();

  @ContentChildren(DataListViewTemplateNameDirective)
  templates: QueryList<DataListViewTemplateNameDirective<T>>;

  slotSelectors = slotSelectors;

  selectedLayoutType$ = this.layoutTypeService.selectedLayoutType$;

  get isFavoriteSupported(): boolean {
    return this.toggleFavorite.observed;
  }

  get isNameEditable(): boolean {
    return this.renameItem.observed;
  }

  constructor(private readonly layoutTypeService: LayoutTypeService) {}
}
