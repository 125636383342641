import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DefaultDataService, HttpUrlGenerator } from '@ngrx/data';
import { Update } from '@ngrx/entity/src/models';
import { AlertService, PipelineConfigService } from '@selfai-platform/shared';
import { KE_WORKFLOW_SCHEDULES_ENTITY_NAME } from '@selfai-platform/store';
import { Observable, tap } from 'rxjs';
import { WorkflowSettingsSchedules } from '../interface/workflow-settings-schedules.interface';

@Injectable({
  providedIn: 'root',
})
export class WorkflowSettingsSchedulesService extends DefaultDataService<any> {
  config = this.pipelineConfigService.getConfig();
  apiUrl = `${this.config.hosts.api}/schedulingmanager/${this.config.versions.url}`;

  constructor(
    public override http: HttpClient,
    public override httpUrlGenerator: HttpUrlGenerator,
    private pipelineConfigService: PipelineConfigService,
    private alertService: AlertService,
  ) {
    super(KE_WORKFLOW_SCHEDULES_ENTITY_NAME, http, httpUrlGenerator);
  }

  override getWithQuery(id: string): Observable<WorkflowSettingsSchedules[]> {
    return this.http.get<WorkflowSettingsSchedules[]>(`${this.apiUrl}/workflow/${id}/schedules`);
  }

  override add(data: WorkflowSettingsSchedules): Observable<WorkflowSettingsSchedules> {
    return this.http.put<WorkflowSettingsSchedules>(`${this.apiUrl}/workflow-schedules/${data.id}`, data).pipe(
      tap({
        next: () => {
          this.alertService.success(`Workflow preset added`);
        },
        error: () => {
          this.alertService.error(`Creation Error`);
        },
      }),
    );
  }
  override update(data: Update<WorkflowSettingsSchedules>): Observable<WorkflowSettingsSchedules> {
    return this.http
      .put<WorkflowSettingsSchedules>(`${this.apiUrl}/workflow-schedules/${data.changes.id}`, data.changes)
      .pipe(
        tap({
          next: () => {
            this.alertService.success(`Workflow preset changed`);
          },
          error: () => {
            this.alertService.error(`Editing error`);
          },
        }),
      );
  }

  override delete(id: string): Observable<string> {
    return this.http.delete<string>(`${this.apiUrl}/workflow-schedules/${id}`).pipe(
      tap({
        next: () => {
          this.alertService.success(`Successful removal`);
        },
        error: () => {
          this.alertService.error(`Uninstall error`);
        },
      }),
    );
  }
}
