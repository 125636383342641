import { Injectable, OnDestroy } from '@angular/core';
import { PageParams, UrlPageParamsService } from '@selfai-platform/shared';
import pickBy from 'lodash/pickBy';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { PaginationData, StoreData } from '../interfaces';
import { DataListViewItem } from '../models/data-view-list-item.model';
import { SelectedItemsService } from './selected-items.service';

@Injectable()
export abstract class DataListViewBaseService<T extends DataListViewItem>
  implements StoreData<T>, PaginationData, OnDestroy
{
  readonly pageParams$: Observable<PageParams> = this.urlPageParamsService.getPageParams().pipe(
    filter(Boolean),
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
  );
  protected readonly totalItems$ = new BehaviorSubject<number | undefined>(undefined);
  protected readonly reloadTrigger$ = new BehaviorSubject<void>(undefined);
  protected subscription = new Subscription();

  constructor(
    protected readonly urlPageParamsService: UrlPageParamsService,
    protected readonly selectedItemsService: SelectedItemsService<T>,
  ) {
    this.init();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  abstract loadData(pageParams?: PageParams): Observable<{ items: T[]; totalItems: number }>;
  abstract getData(): Observable<T[]>;
  abstract isLoading(): Observable<boolean>;
  abstract isLoaded(): Observable<boolean>;
  abstract hasError(): Observable<boolean>;
  abstract onPageChange({ first, rows }: { first: number; rows: number }): void;

  init(): void {
    this.subscription.add(
      this.getContextUpdate()
        .pipe(
          tap(() => this.selectedItemsService.resetSelectedItems()),
          switchMap(() => this.pageParams$.pipe(take(1))),
          switchMap((pageParams) => {
            return this.loadData(pageParams);
          }),
        )
        .subscribe(({ items, totalItems }) => {
          this.setTotalItems(totalItems);

          return items;
        }),
    );
  }

  onSort({ sortField, sortOrder }: { sortField?: string; sortOrder?: 'asc' | 'desc' }): void {
    const sortParams = pickBy({ sortField, sortOrder }, (value) => value !== undefined);
    this.urlPageParamsService.setPageParams(sortParams);
  }

  getTotalItems(): Observable<number> {
    return this.totalItems$.asObservable().pipe(filter(Boolean));
  }

  setTotalItems(totalItems: number): void {
    this.totalItems$.next(totalItems);
  }

  triggerReloadData(): void {
    this.reloadTrigger$.next(undefined);
  }

  updateFilter(filterFieldName: string, filterValue: number | string | boolean): void {
    this.pageParams$.pipe(take(1)).subscribe((pageParams) => {
      const filters = pageParams.filters.filter((filter) => filter.fieldName !== filterFieldName);
      filters.push({ fieldName: filterFieldName, value: filterValue });
      this.urlPageParamsService.setPageParams({ ...pageParams, filters });
    });
  }

  getFilterValue(filterFieldName: string): Observable<number | string | boolean | undefined> {
    return this.pageParams$.pipe(
      map((pageParams) => {
        const filter = pageParams.filters.find((filter) => filter.fieldName === filterFieldName);

        return filter?.value;
      }),
    );
  }

  protected abstract getContextUpdate(): Observable<unknown>;
}
