<div
  #fieldDetailLayer
  [style.display]="isShowLayer ? 'block' : 'none'"
  class="ddp-ui-layer-box ddp-layer-more"
>
  <em
    *ngIf="isShowLayer && selectedField.type !== 'user_expr'"
    (click)="columDetail(selectedField)"
    class="ddp-btn-info ddp-type"
  ></em>

  <div *ngIf="isShowLayer" class="ddp-detail-more">
    <dl class="ddp-dl-more">
      <dt>{{ "msg.page.dt.column.name" | translate }}</dt>
      <dd title="{{ selectedField.uiMetaData?.name }}">
        {{ selectedField.uiMetaData?.name || selectedField.name }}
      </dd>
    </dl>
    <dl class="ddp-dl-more">
      <dt>{{ "msg.page.dt.type" | translate }}</dt>
      <dd>{{ getMetaDataLogicalTypeName() }}</dd>
    </dl>
  </div>

  <ul
    *ngIf="isShowLayer"
    (clickOutside)="isShowLayer = false"
    [excludeBeforeClick]="true"
    [exclude]="'.ddp-icon-control-more'"
    class="ddp-list-layer-option ddp-list-radio"
  >
    <li
      *ngIf="isAllowNameAlias(selectedField)"
      [class.ddp-hover]="fix2DepthContext"
    >
      <a href="javascript:">
        Alias
        <div class="ddp-data-result">
          <span
            class="ddp-txt-result"
            *ngIf="selectedField.nameAlias; else noAlias"
            title="{{ selectedField.nameAlias.nameAlias }}"
          >
            {{ selectedField.nameAlias.nameAlias }}
            <em class="ddp-icon-view"></em>
          </span>
          <ng-template #noAlias>
            <span class="ddp-txt-result">
              {{ "NONE" }}
              <em class="ddp-icon-view"></em>
            </span>
          </ng-template>
        </div>
      </a>

      <div class="ddp-ui-layer-sub">
        <div class="ddp-box-layer">
          <input
            [(ngModel)]="editingFieldAlias"
            type="text"
            class="ddp-input-typebasic"
            placeholder="{{ selectedField.name }}"
            (focus)="fix2DepthContext = true"
            (keyup.enter)="onAliasApply($event)"
          />
          <a
            *ngIf="
              selectedField.nameAlias &&
              selectedField.nameAlias.fieldName !==
                selectedField.nameAlias.nameAlias
            "
            (click)="onAliasReset($event)"
            href="javascript:"
            class="ddp-btn-line"
          >
            {{ "msg.page.btn.reset" | translate }}
          </a>
          <a
            href="javascript:"
            (click)="onAliasApply($event)"
            [class.ddp-full]="
              selectedField.nameAlias &&
              selectedField.nameAlias.fieldName ===
                selectedField.nameAlias.nameAlias
            "
            class="ddp-btn-solid"
          >
            {{ "msg.page.btn.apply" | translate }}
          </a>
        </div>
      </div>
    </li>

    <li *ngIf="isAllowValueAlias(selectedField)">
      <a (click)="showAliasValue(selectedField)" href="javascript:">
        {{ "msg.board.datasource.alias.for.value.title" | translate }}
      </a>
    </li>

    <li *ngIf="selectedField.type === 'user_expr'">
      <a href="javascript:">
        {{ "msg.page.th.edit-cond" | translate }}
        <div class="ddp-data-result">
          <span class="ddp-txt-result">{{
            unescapeCustomColumnExpr(selectedField.expr)
          }}</span>
          <em
            (click)="customFieldEmit(selectedField)"
            class="ddp-btn-option"
          ></em>
        </div>
      </a>
    </li>
    <li *ngIf="selectedField.type === 'user_expr'">
      <a (click)="deleteCustomField()" href="javascript:">
        {{ "msg.page.btn.del" | translate }}
      </a>
    </li>
  </ul>
</div>

<popup-value-alias (changeAlias)="changeValueAlias($event)"></popup-value-alias>
