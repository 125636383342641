
import {
  AfterViewInit,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

import {
  ChartType,
  Dashboard,
  DashboardDomainService,
  Datasource,
  Filter,
  FilterWidgetConfiguration,
  FunctionValidator,
  InclusionFilter,
  InclusionSelectorType,
  LayoutMode,
  PageWidget,
  PageWidgetConfiguration,
  Widget,
} from '@selfai-platform/bi-domain';
import { isNullOrUndefined } from '@selfai-platform/shared';

import { AbstractComponent } from '../../../common/component/abstract.component';
import { CommonConstant } from '../../../common/constant/common.constant';
import { EventBroadcaster } from '../../../common/event/event.broadcaster';
import { DashboardUtil } from '../../util/dashboard.util';

@Component({
  selector: 'dashboard-widget-header',
  templateUrl: './dashboard.widget.header.component.html',
  styleUrls: ['dashboard.widget.header.component.scss'],
})
export class DashboardWidgetHeaderComponent
  extends AbstractComponent
  implements OnInit, OnDestroy, AfterViewInit, DoCheck
{
  @ViewChild('controlContainer', { static: true })
  private controlContainer: ElementRef;

  @Input()
  public widget: Widget;

  @Input()
  public layoutMode: LayoutMode;

  @Input()
  public isShowTitle = true;

  @Output()
  public eventHeaderControls = new EventEmitter();

  private _chartFuncValidator: FunctionValidator = new FunctionValidator();
  private dashboard: Dashboard;

  public isEditTitle = false;

  public isMiniHeader = false;
  public isShowMore = false;
  public isMissingDataSource = false;
  public miniHeaderMenuItems = [
  {
    label: this.translateService.instant('msg.board.ui.tooltip.chart-name'),
    icon: 'pi pi-tag',
    command: () => {
      this.toggleTitle()
    }
  },
  {
    label: this.translateService.instant('msg.page.ui.legend'),
    icon: 'pi pi-list',
    command: () => {
      this.toggleLegend()
    },
    disabled: !this.isValidLegend(),
  },
  {
    label: this.translateService.instant('msg.page.ui.minimap'),
    icon: 'pi pi-map',
    command: () => {
      this.toggleMiniMap()
    },
    disabled: !this.isValidMiniMap(),
  },
  {
    label: this.translateService.instant('msg.comm.ui.edit'),
    icon: 'pi pi-pencil',
    command: () => {
      this.editWidget()
    }
  },
  {
    label: this.translateService.instant('msg.comm.ui.copy'),
    icon: 'pi pi-copy',
    command: () => {
      this.copyPageWidget();
    }
  },
  {
    label: this.translateService.instant('msg.comm.ui.del'),
    icon: 'pi pi-trash',
    command: () => {
      this.removeWidget();
    }
  },
];

  public limitInfo: { id: string; isShow: boolean; currentCnt: number; maxCnt: number } = {
    id: '',
    isShow: false,
    currentCnt: 0,
    maxCnt: 0,
  };

  constructor(
    private broadCaster: EventBroadcaster,
    protected elementRef: ElementRef,
    protected injector: Injector,
    private dashboardDomainService: DashboardDomainService,
  ) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
    this.dashboardDomainService.getDashboard(this.widget.dashBoardId).subscribe((dashboard) => {
      this.dashboard = dashboard;
    });
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      if (this.isPageWidget && this.widget && this.dashboard) {
        const pageWidgetConf: PageWidgetConfiguration = <PageWidgetConfiguration>this.widget.configuration;
        if (ChartType.MAP === pageWidgetConf.chart.type) {
          if (
            pageWidgetConf.shelf.layers
              .filter((layer) => layer.name !== CommonConstant.MAP_ANALYSIS_LAYER_NAME)
              .some((layer) => {
                return isNullOrUndefined(this.dashboard.dataSources.find((item) => item.engineName === layer.ref));
              })
          ) {
            this.isMissingDataSource = true;
          }
        } else {
          const widgetDataSource: Datasource = DashboardUtil.getDataSourceFromBoardDataSource(
            this.dashboard,
            pageWidgetConf.dataSource,
          );
          this.isMissingDataSource = !widgetDataSource;
        }
      } else {
        this.isMissingDataSource = false;
      }
      this.safelyDetectChanges();
    }, 200);

    this.subscriptions.push(
      this.broadCaster
        .on<any>('WIDGET_LIMIT_INFO')
        .subscribe((data: { id: string; isShow: boolean; currentCnt: number; maxCnt: number }) => {
          if (this.widget.id === data.id) {
            this.limitInfo = data;
            this.safelyDetectChanges();
          }
        }),
    );
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public ngDoCheck() {
    if (this.isEditMode()) {
      this.isMiniHeader = 300 > this.controlContainer.nativeElement.offsetWidth;
    }
  }

  public getDataSourceName(): string {
    let strName = '';

    if (this.isPageWidget && this.widget && this.dashboard) {
      const widgetConf: PageWidgetConfiguration = <PageWidgetConfiguration>this.widget.configuration;
      if (ChartType.MAP === widgetConf.chart.type && widgetConf.shelf.layers) {
        strName = widgetConf.shelf.layers.reduce((acc, currVal) => {
          const dsInfo: Datasource = this.dashboard.dataSources.find((item) => item.engineName === currVal.ref);
          if (dsInfo) {
            acc = '' === acc ? acc + dsInfo.name : acc + ',' + dsInfo.name;
          }
          return acc;
        }, '');
      } else if (widgetConf.dataSource) {
        const widgetDataSource: Datasource = DashboardUtil.getDataSourceFromBoardDataSource(
          this.dashboard,
          widgetConf.dataSource,
        );
        widgetDataSource && (strName = widgetDataSource.name);
      }
    }
    return strName;
  }

  public existPivot(category: string): boolean {
    return (
      this.widget &&
      this.widget.configuration['pivot'] &&
      this.widget.configuration['pivot'][category] &&
      0 < this.widget.configuration['pivot'][category].length
    );
  }

  public getPivotFieldsStr(category: string): string {
    let strFields = '';
    if (this.widget && this.widget.configuration['pivot']) {
      const pivotData = this.widget.configuration['pivot'][category];
      if (pivotData) {
        strFields = pivotData.map((item) => item.name).join(',');
      }
    }
    return strFields;
  }

  public isValidLegend(): boolean {
    if (this.isPageWidget && this.widget) {
      const chartConf = this.widget.configuration['chart'];
      return (
        this._chartFuncValidator.checkUseLegendByTypeString(chartConf.type) && 'grid' !== (<PageWidget>this.widget).mode
      );
    } else {
      return false;
    }
  }

  public existChartFilter(): boolean {
    return (
      this.isPageWidget &&
      this.widget &&
      (<PageWidgetConfiguration>this.widget.configuration).filters &&
      0 < (<PageWidgetConfiguration>this.widget.configuration).filters.length
    );
  }

  public getChartFilterStr(): string {
    let strFields = '';
    if (this.isPageWidget && this.widget && (<PageWidgetConfiguration>this.widget.configuration).filters) {
      strFields = (<PageWidgetConfiguration>this.widget.configuration).filters.map((item) => item.field).join(',');
    }
    return strFields;
  }

  public isValidMiniMap(): boolean {
    if (this.isPageWidget && this.widget) {
      const chartConf = this.widget.configuration['chart'];
      return (
        this._chartFuncValidator.checkUseMiniMapByTypeString(chartConf.type) &&
        'grid' !== (<PageWidget>this.widget).mode
      );
    } else {
      return false;
    }
  }

  public isRealTimeDashboard(): boolean {
    if (this.isPageWidget && this.widget && this.dashboard) {
      const boardConf = this.dashboard.configuration;
      return boardConf.options.sync && boardConf.options.sync.enabled;
    } else {
      return false;
    }
  }

  public isRealTimeWidget(): boolean {
    if (this.isPageWidget && this.widget) {
      return false !== (this.widget.configuration as PageWidgetConfiguration).sync;
    } else {
      return false;
    }
  }

  public isShowLegend(): boolean {
    return Boolean(this.widget.configuration.chart.legend?.auto);
  }

  public isShowMiniMap(): boolean {
    if (this.widget) {
      const chartConf = this.widget.configuration['chart'];
      return chartConf && chartConf.chartZooms && 'grid' !== chartConf.type && chartConf.chartZooms
        ? chartConf.chartZooms[0].auto
        : false;
    } else {
      return false;
    }
  }

  public editModeName($event: MouseEvent) {
    $event.stopImmediatePropagation();
    this.isEditTitle = true;
    this.changeDetect.detectChanges();
  }

  public changeWidgetName(inputName: string) {
    this.isEditTitle = false;
    inputName = inputName ? inputName.trim() : '';
    if (inputName && 0 < inputName.length) {
      this.widget.name = inputName;
      this.broadCaster.broadcast('WIDGET_CHANGE_TITLE', {
        widgetId: this.widget.id,
        value: inputName,
      });
    } else {
      this.alertPrimeService.warn(this.translateService.instant('msg.alert.edit.name.empty'));
    }
  }

  public get isGridModePageWidget() {
    return this.widget ? 'grid' === (<PageWidget>this.widget).mode : false;
  }

  public get isPageWidget(): boolean {
    return this.widget ? 'page' === this.widget.type : false;
  }

  public get isDimensionFilterWidget(): boolean {
    return (
      this.widget &&
      'filter' === this.widget.type &&
      'include' === (<FilterWidgetConfiguration>this.widget.configuration).filter.type
    );
  }

  public get isListDimensionFilter(): boolean {
    const filter: Filter = (<FilterWidgetConfiguration>this.widget.configuration).filter;
    if ('include' === filter.type) {
      const selector: InclusionSelectorType = (<InclusionFilter>filter).selector;
      return -1 < selector.toString().indexOf('LIST');
    } else {
      return false;
    }
  }

  public get isTextWidget(): boolean {
    return this.widget ? 'text' === this.widget.type : false;
  }

  public isEditMode(): boolean {
    return this.layoutMode === LayoutMode.EDIT;
  }

  public toggleTitle() {
    this.isShowTitle = !this.isShowTitle;
    this.broadCaster.broadcast('TOGGLE_TITLE', {
      widgetId: this.widget.id,
      widgetType: this.widget.type,
      mode: this.isShowTitle,
    });
  }

  public toggleLegend() {
    this.broadCaster.broadcast('TOGGLE_LEGEND', { widgetId: this.widget.id });
  }

  public toggleMiniMap() {
    this.broadCaster.broadcast('TOGGLE_MINIMAP', { widgetId: this.widget.id });
  }

  public toggleSync() {
    this.broadCaster.broadcast('TOGGLE_SYNC', { widgetId: this.widget.id });
  }

  public editWidget() {
    if (this.isMissingDataSource) {
      this.alertPrimeService.warn(this.translateService.instant('msg.board.alert.can-not-edit-missing-datasource'));
      return;
    }
    this.broadCaster.broadcast('EDIT_WIDGET', { widgetId: this.widget.id, widgetType: this.widget.type });
  }

  public copyPageWidget() {
    if (this.isMissingDataSource) {
      this.alertPrimeService.warn(this.translateService.instant('msg.board.alert.can-not-copy-missing-datasource'));
      return;
    }
    this.broadCaster.broadcast('COPY_WIDGET', { widgetId: this.widget.id });
  }

  public onChangeWidgetMode(mode: 'grid' | 'chart') {
    if (this.isPageWidget) {
      (<PageWidget>this.widget).mode = mode;
      this.broadCaster.broadcast('CHANGE_MODE', { widgetId: this.widget.id, mode: mode });
    }
  }

  public removeWidget() {
    this.broadCaster.broadcast('REMOVE', { widgetId: this.widget.id, widgetType: this.widget.type });
  }

  public isAvailableGrid(widget: PageWidget) {
    const chartType = (<PageWidgetConfiguration>widget.configuration).chart.type.toString();
    const invalidChart = ['grid', 'scatter', 'pie'];
    return -1 === invalidChart.indexOf(chartType);
  }

  public setSelectorTypeList() {
    const filter: Filter = (<FilterWidgetConfiguration>this.widget.configuration).filter;
    if ('include' === filter.type) {
      const selector: InclusionSelectorType = (<InclusionFilter>filter).selector;
      switch (selector) {
        case InclusionSelectorType.SINGLE_LIST:
        case InclusionSelectorType.SINGLE_COMBO:
          (<InclusionFilter>filter).selector = InclusionSelectorType.SINGLE_LIST;
          break;
        case InclusionSelectorType.MULTI_LIST:
        case InclusionSelectorType.MULTI_COMBO:
          (<InclusionFilter>filter).selector = InclusionSelectorType.MULTI_LIST;
          break;
      }
      (<FilterWidgetConfiguration>this.widget.configuration).filter = filter;
      this.broadCaster.broadcast('CHANGE_FILTER_SELECTOR', { widget: this.widget, filter: filter });
    }
  }

  public setSelectorTypeCombo() {
    const filter: Filter = (<FilterWidgetConfiguration>this.widget.configuration).filter;
    if ('include' === filter.type) {
      const selector: InclusionSelectorType = (<InclusionFilter>filter).selector;
      switch (selector) {
        case InclusionSelectorType.SINGLE_LIST:
        case InclusionSelectorType.SINGLE_COMBO:
          (<InclusionFilter>filter).selector = InclusionSelectorType.SINGLE_COMBO;
          break;
        case InclusionSelectorType.MULTI_LIST:
        case InclusionSelectorType.MULTI_COMBO:
          (<InclusionFilter>filter).selector = InclusionSelectorType.MULTI_COMBO;
          break;
      }
      (<FilterWidgetConfiguration>this.widget.configuration).filter = filter;
      this.broadCaster.broadcast('CHANGE_FILTER_SELECTOR', { widget: this.widget, filter: filter });
    }
  }

  public toggleDisplayMoreLayer() {
    this.isShowMore = !this.isShowMore;
    if (this.isShowMore) {
      this.mouseOverHeader();
    } else {
      this.mouseOutHeader(true);
    }
  }

  public mouseOverHeader() {
    if (0 === $('.sys-widget-header-layer:visible').length) {
      this.$element.closest('.lm_header').attr('style', 'display: block; height: 25px; z-index:21 !important;');
    }
  }

  public mouseOutHeader(isForce: boolean = false) {
    if (isForce || !this.isShowMore) {
      this.$element.closest('.lm_header').attr('style', 'display: block; height: 25px;');
      this.isShowMore = false;
    }
  }
}
