<div *ngIf="targetFilter" class="ddp-dateinfo-view" [class.ddp-inline]="'WIDGET' === mode">
  <div class="ddp-filter-calen">
    <div
      *ngFor="let item of timeRangeList; let idx = index"
      class="ddp-filter-calen-in"
      [ngStyle]="'WIDGET' === mode ? { float: 'left' } : {}"
    >
      <selfai-bi-time-range
        [mode]="mode"
        [initialDate]="getTimeRangeData(item)"
        (dateChange)="onDateChange($event, idx)"
      ></selfai-bi-time-range>

      <div *ngIf="'WIDGET' !== mode" class="ddp-wrap-control">
        <div class="ddp-dateinfo-control">
          <label class="ddp-label-checkbox">
            <input (click)="setEarliestTime($event, targetFilter)" [checked]="isEarliestTime" type="checkbox" />
            <i class="ddp-icon-checkbox"></i>
            <span class="ddp-txt-checkbox">{{ 'msg.board.filter.ui.earliest' | translate }}</span>
          </label>
        </div>

        <div class="ddp-dateinfo-control">
          <label class="ddp-label-checkbox">
            <input (click)="setLatestTime($event, targetFilter)" [checked]="isLatestTime" type="checkbox" />
            <i class="ddp-icon-checkbox"></i>
            <span class="ddp-txt-checkbox">{{ 'msg.board.filter.ui.latest' | translate }}</span>
          </label>

          <a (click)="deleteIntervalRange(targetFilter)" href="javascript:" class="ddp-icon-control-cut"></a>
        </div>
      </div>
    </div>
    <a
      *ngIf="'WIDGET' === mode"
      class="ddp-ui-button "
      href="javascript:"
      (click)="broadcastChange()"
      style="
        width: 83px;
        height: 30px;
        border-radius: 2px;
        border: solid 1px #4e5368;
        padding: 8px 10px;
        margin-left: 35px;
        position: relative;
        top: 8px;
      "
    >
      {{ 'msg.comm.ui.ok' | translate }}
    </a>
  </div>

  <a
    *ngIf="'WIDGET' !== mode"
    (click)="addIntervalRange(targetFilter)"
    [ngClass]="{ 'ddp-disabled': isLatestTime }"
    href="javascript:"
    class="ddp-btn-add"
  >
    {{ 'msg.add.period' | translate }}
  </a>
</div>
