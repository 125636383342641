import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WorkflowClonePayload, WorkflowInfo } from '@selfai-platform/pipeline-common';
import {
  AlertService,
  DestroyService,
  LocalStorageKey,
  PREFIX_SETTINGS_LOCAL_STORAGE_KEY,
  PermissionService,
  PipelinePermissionEntity,
  WorkflowPermissionAction,
} from '@selfai-platform/shared';
import {
  BreadcrumbsMenuService,
  DialogService,
  GroupAction,
  SelectedItemsService,
  ToolbarAction,
  convertMessageToHtml,
  provideDataListView,
  provideDialogService,
} from '@selfai-platform/shell';
import { ConfirmationService } from 'primeng/api';
import { Observable, catchError, forkJoin, mergeMap, of, take, takeUntil, throwError } from 'rxjs';
import {
  WorkflowDataListViewService,
  WorkflowListComponentService,
  WorkflowListItem,
  WorkspaceActionsForItemService,
} from '../../services';
import { SessionsManagerService } from '../../services/session-manager/sessions-manager.service';
import { WorkflowAddDialogComponent } from '../workflow-add-dialog';
import { WorkflowUploadDialogComponent } from '../workflow-upload-dialog';

@Component({
  selector: 'selfai-platform-workflow-list',
  templateUrl: './workflow-list.component.html',
  styleUrls: ['./workflow-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    WorkflowListComponentService,
    DestroyService,
    ...provideDataListView(WorkflowDataListViewService, WorkspaceActionsForItemService),
    provideDialogService(),
    {
      provide: PREFIX_SETTINGS_LOCAL_STORAGE_KEY,
      useValue: LocalStorageKey.WORKFLOW_LIST_PREFIX_SETTINGS,
    },
  ],
})
export class WorkflowListComponent implements OnInit, OnDestroy {
  columns = this.workflowDataListViewService.getColumns();

  actions$: Observable<ToolbarAction[]> = this.getActions();
  groupActions$: Observable<GroupAction[]> = this.getGroupActions();

  workflowPermissionAction = WorkflowPermissionAction;

  constructor(
    private readonly workflowListComponentService: WorkflowListComponentService,
    private readonly workflowDataListViewService: WorkflowDataListViewService,
    private readonly sessionsManagerService: SessionsManagerService,
    private readonly breadcrumbsMenuService: BreadcrumbsMenuService,
    private readonly destroy$: DestroyService,
    private readonly permissionService: PermissionService,
    private readonly dialogServiceForUpload: DialogService<{ success?: boolean }>,
    private readonly dialogService: DialogService<WorkflowClonePayload, WorkflowInfo>,
    private readonly selectedItemsService: SelectedItemsService<WorkflowListItem>,
    private readonly confirmationService: ConfirmationService,
    private readonly translate: TranslateService,
    private readonly alertService: AlertService,
  ) {}

  ngOnInit(): void {
    this.sessionsManagerService.startPollingSession();
    this.workflowListComponentService.loadWorkflowList();

    this.workflowListComponentService.breadcrumbItems$.pipe(takeUntil(this.destroy$)).subscribe((breadcrumbItems) => {
      this.breadcrumbsMenuService.setBreadcrumbsMenu(breadcrumbItems);
    });
  }

  ngOnDestroy(): void {
    this.sessionsManagerService.stopPollingSession();
  }

  checkPermission(permissionAction: WorkflowPermissionAction, isOwner?: boolean): Observable<boolean> {
    return this.permissionService.checkPermission({
      entity: PipelinePermissionEntity.Workflows,
      isOwner,
      action: permissionAction,
    });
  }

  stopSessionWorkflow(workflowId: string): void {
    this.workflowListComponentService.stopSessionWorkflow(workflowId);
  }

  saveName(item: WorkflowListItem): void {
    this.workflowListComponentService.updateWorkflow(item);
  }

  private addWorkflow(): void {
    this.dialogService
      .showDialog(WorkflowAddDialogComponent, {
        header: this.translate.instant('workflow-list.dialog.header.new-workflow'),
        width: '50%',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((workflow) => {
        if (workflow) {
          this.workflowListComponentService.addWorkflow(workflow);
        }
      });
  }

  private uploadWorkflow(): void {
    this.dialogServiceForUpload
      .showDialog(WorkflowUploadDialogComponent, {
        header: this.translate.instant('workflow-list.dialog.header.upload-workflow'),
        width: '50%',
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.workflowDataListViewService.triggerReloadData();
      });
  }

  private getActions(): Observable<ToolbarAction[]> {
    const actions: ToolbarAction[] = [];

    if (this.checkPermission(WorkflowPermissionAction.Create)) {
      actions.push({
        tooltipTranslate: 'workflow-list.toolbar.actions.add',
        icon: 'pi pi-plus-circle',
        buttonClass: 'p-button-success',
        action: () => this.addWorkflow(),
      });
    }

    if (this.checkPermission(WorkflowPermissionAction.Upload)) {
      actions.push({
        tooltipTranslate: 'workflow-list.toolbar.actions.upload',
        icon: 'pi pi-upload',
        action: () => this.uploadWorkflow(),
      });
    }

    return of(actions);
  }

  private getGroupActions(): Observable<GroupAction[]> {
    const actions: GroupAction[] = [];

    if (this.checkPermission(WorkflowPermissionAction.Stop)) {
      actions.push({
        tooltipTranslate: 'workflow-list.toolbar.actions.stop-selected',
        icon: 'pi pi-power-off',
        action: () => this.stopSelected(),
      });
    }

    if (this.checkPermission(WorkflowPermissionAction.Clone)) {
      actions.push({
        tooltipTranslate: 'workflow-list.toolbar.actions.clone-selected',
        icon: 'pi pi-copy',
        action: () => this.cloneSelected(),
      });
    }

    if (this.checkPermission(WorkflowPermissionAction.Delete)) {
      actions.push({
        tooltipTranslate: 'workflow-list.toolbar.actions.delete-selected',
        icon: 'pi pi-trash',
        buttonClass: 'p-button-danger',
        action: () => this.deleteSelected(),
      });
    }

    return of(actions);
  }

  private deleteSelected(): void {
    this.confirmationService.confirm({
      key: 'dialog',
      message: convertMessageToHtml(
        this.translate.instant('workflow-list.toolbar.actions.delete-selected.confirmation'),
      ),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.selectedItemsService
          .getSelectedItemIds()
          .pipe(take(1), takeUntil(this.destroy$))
          .subscribe((ids) => {
            ids.forEach((workflowId) => {
              this.workflowListComponentService.deleteWorkflow(workflowId);
            });
          });
      },
    });
  }

  private cloneSelected(): void {
    this.selectedItemsService
      .getSelectedItems()
      .pipe(
        take(1),
        mergeMap((workflows) => {
          return forkJoin(
            workflows.map((workflow) => {
              return this.workflowListComponentService.cloneWorkflow({
                id: workflow.id,
                name: `Copy of ${workflow.name}`,
                description: workflow.description,
                isPublic: true,
              });
            }),
          );
        }),
        catchError((e: unknown) => {
          this.alertService.error(this.translate.instant('workflow-list.toolbar.actions.clone-selected.error'));

          return throwError(() => e);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        this.workflowDataListViewService.triggerReloadData();
      });
  }

  private stopSelected(): void {
    this.selectedItemsService
      .getSelectedItemIds()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe((ids) => {
        ids.forEach((workflowId) => {
          this.workflowListComponentService.stopSessionWorkflow(workflowId);
        });
      });
  }
}
