export interface LayoutSettings {
    hasHeaders: boolean;
    constrainDragToContainer?: boolean;
    reorderEnabled?: boolean;
    selectionEnabled: boolean;
    popoutWholeStack: boolean;
    blockedPopoutsThrowError: boolean;
    closePopoutsOnUnload: boolean;
    showPopoutIcon: boolean;
    showMaximiseIcon?: boolean;
    showCloseIcon: boolean;
    enableComponentResize: boolean;
  }
  
  export function createLayoutSettings(layoutSettingsOptions: Partial<LayoutSettings> = {}): LayoutSettings {
    return {
     hasHeaders: true,
     selectionEnabled: false,
     popoutWholeStack: false,
     blockedPopoutsThrowError: false,
     closePopoutsOnUnload: false,
     showPopoutIcon: false,
     showCloseIcon: false,
     enableComponentResize: false,
      ...layoutSettingsOptions
    }
  }