import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PaginatorModule } from 'primeng/paginator';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DataSourcesFileFormatList } from '../../lists/data-sources-file-format.list';
import { DataSourcesFileSeparatorList } from '../../lists/data-sources-file-separator.list';
import { DataSourcesAdditionsComponent } from '../data-source-additions/data-sources-additions.component';
import { DataSourceUniqueNameComponent, DataSourceVisibilityComponent } from '../data-source-form-inputs';
import { DataSourcesBasic } from '../data-sources-basic';

@Component({
  selector: 'selfai-platform-data-sources-file',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonModule,
    CheckboxModule,
    DropdownModule,
    InputTextModule,
    PaginatorModule,
    RadioButtonModule,
    DataSourcesAdditionsComponent,
    DataSourceUniqueNameComponent,
    DataSourceVisibilityComponent,
  ],
  templateUrl: './data-sources-file.component.html',
  styleUrls: ['./data-sources-file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataSourcesFileComponent extends DataSourcesBasic implements OnInit {
  format = DataSourcesFileFormatList;
  separators = DataSourcesFileSeparatorList;
  paramsForm = this.fb.group({
    url: ['', Validators.required],
    fileFormat: ['csv'],
    csvFileFormatParams: this.fb.group({
      convert01ToBoolean: [false],
      includeHeader: [false],
      separatorType: ['comma'],
      customSeparator: [''],
    }),
  });

  constructor() {
    super('externalFile');
  }
}
