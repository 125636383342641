import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { CommonModule } from '../../../../common/common.module';

import { ColorPickerLayerComponent } from './color.picker.layer.component';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [ColorPickerLayerComponent],
  exports: [ColorPickerLayerComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ColorPickerLayerModule {}
