<div class="ddp-layout-popuptype" *ngIf="isShow">
  <em class="ddp-bg-popup"></em>
  <div class="ddp-pop-filter">
    <div class="ddp-txt-title-name" style="position: relative; z-index: 100">
      <i class="pi pi-filter"></i>
      {{ widget ? ('msg.add.ui.chart.filter' | translate) : ('msg.add.ui.global.filter' | translate) }}

      <div class="ddp-wrap-source-name ddp-white">
        <dashboard-datasource-combo
          [dataSources]="dataSources"
          [initialValue]="selectedDataSource"
          (selectOption)="selectDataSource($event)"
        ></dashboard-datasource-combo>
      </div>
    </div>

    <div class="ddp-ui-popup-contents">
      <div class="ddp-wrap-tab">
        <ul class="ddp-ui-tab ddp-clear ddp-type2 ddp-tab2">
          <li (click)="isShowDimTab = true" [class.ddp-selected]="isShowDimTab">
            <a href="javascript:">{{ 'msg.comm.name.dim' | translate }}</a>
          </li>
          <li (click)="isShowDimTab = false" [class.ddp-selected]="!isShowDimTab">
            <a href="javascript:">{{ 'msg.comm.name.mea' | translate }}</a>
          </li>
        </ul>
      </div>

      <div class="ddp-wrap-tab-contents">
        <div class="ddp-ui-tab-contents" *ngIf="isShowDimTab">
          <div class="ddp-filter0">
            <component-input
              [compType]="'search'"
              [value]="searchText"
              [optionalClass]="'ddp-full'"
              [placeHolder]="'msg.board.filter.placeholder.search-field' | translate"
              (changeValue)="searchEvent($event)"
            ></component-input>

            <div class="ddp-add-filter">
              <ul class="ddp-list-filter">
                <li
                  *ngFor="let field of dimensionFields"
                  (click)="selectField(field)"
                  [class.ddp-selected]="isSelectedField(field)"
                >
                  <div class="ddp-data-filter">
                    <div *ngIf="field['isEditable']" class="ddp-ui-icons">
                      <span *ngIf="field['someChartFilter']" class="ddp-link-filter">
                        {{ 'msg.board.ui.filter.used-some-chart' | translate }}
                      </span>
                      <em *ngIf="field['useBoardFilter']" class="ddp-icon-global-s"></em>
                      <em *ngIf="'timestamp' === field['importanceType']" class="ddp-icon-time"></em>
                      <div *ngIf="'recommended' === field['importanceType']" class="ddp-wrap-datarecommend">
                        {{ field['filteringSeq'] }}
                        <em class="ddp-icon-recommend-s"></em>
                      </div>
                      <button class="ddp-btn-edit"></button>
                      <em *ngIf="field['isTimestamp']" class="ddp-icon-view"></em>
                    </div>

                    <div *ngIf="!field['isEditable']" class="ddp-ui-icons">
                      <button class="ddp-btn-add"></button>
                    </div>

                    <span class="ddp-txt-filter">
                      <selfai-bi-shared-field-icon
                        [view]="'DIMENSION'"
                        [type]="field.type"
                        [logicalType]="field.logicalType"
                      ></selfai-bi-shared-field-icon>
                      {{ field.name }}
                    </span>
                  </div>

                  <div *ngIf="false && field['isTimestamp']" class="ddp-sub-filter">
                    <span class="ddp-label-filter">Continuous type</span>
                    <ul class="ddp-list-subfilter">
                      <li
                        *ngFor="let unit of dpContinuousList"
                        (click)="selectTimestampGranularity(field, false, unit.toUpperCase())"
                      >
                        {{ unit }}
                        <div class="ddp-ui-icons">
                          <span *ngIf="field['someChartFilter']" class="ddp-link-filter">
                            {{ 'msg.board.ui.filter.used-some-chart' | translate }}
                          </span>

                          <a *ngIf="field['isEditable']" href="javascript:" class="ddp-btn-edit"></a>
                          <a *ngIf="!field['isEditable']" href="javascript:" class="ddp-btn-add"></a>
                        </div>
                      </li>
                    </ul>
                    <span class="ddp-label-filter">Discontinuous type</span>
                    <ul class="ddp-list-subfilter">
                      <li
                        *ngFor="let byUnit of dpDiscontinuousList"
                        (click)="selectTimestampGranularity(field, true, byUnit['unit'], byUnit['byUnit'])"
                      >
                        {{ byUnit['name'] }}
                        <div class="ddp-ui-icons">
                          <span *ngIf="field['someChartFilter']" class="ddp-link-filter">
                            {{ 'msg.board.ui.filter.used-some-chart' | translate }}
                          </span>

                          <a *ngIf="field['isEditable']" href="javascript:" class="ddp-btn-edit"></a>
                          <a *ngIf="!field['isEditable']" href="javascript:" class="ddp-btn-add"></a>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="ddp-ui-buttons">
            <a (click)="close()" href="javascript:" class="ddp-btn-type-popup">
              {{ 'msg.comm.btn.cancl' | translate }}
            </a>
          </div>
        </div>

        <div class="ddp-ui-tab-contents" *ngIf="!isShowDimTab">
          <div class="ddp-filter0">
            <component-input
              [compType]="'search'"
              [value]="searchText"
              [optionalClass]="'ddp-full'"
              [placeHolder]="'msg.board.filter.placeholder.search-field' | translate"
              (changeValue)="searchEvent($event)"
            ></component-input>

            <div class="ddp-add-filter">
              <ul class="ddp-list-filter">
                <li
                  *ngFor="let field of measureFields"
                  (click)="selectField(field)"
                  [class.ddp-selected]="isSelectedField(field)"
                  [class.ddp-disabled]="field['type'] === 'ARRAY'"
                >
                  <div class="ddp-data-filter">
                    <div class="ddp-ui-icons">
                      <span *ngIf="field['someChartFilter']" class="ddp-link-filter">
                        {{ 'msg.board.ui.filter.used-some-chart' | translate }}
                      </span>
                      <em *ngIf="field['useBoardFilter']" class="ddp-icon-global-s"></em>
                      <a
                        *ngIf="field['isEditable']"
                        (click)="editFilter(field)"
                        href="javascript:"
                        class="ddp-btn-edit"
                      ></a>
                      <a
                        *ngIf="!field['isEditable']"
                        (click)="addFilter(field)"
                        href="javascript:"
                        class="ddp-btn-add"
                      ></a>
                    </div>

                    <span class="ddp-txt-filter">
                      <selfai-bi-shared-field-icon
                        [view]="'MEASURE'"
                        [type]="field.type"
                        [logicalType]="field.logicalType"
                      ></selfai-bi-shared-field-icon>
                      {{ field.name }}
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="ddp-ui-buttons">
            <a (click)="close()" href="javascript:" class="ddp-btn-type-popup">
              {{ 'msg.comm.btn.cancl' | translate }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirm-modal (confirm)="confirm($event)"></app-confirm-modal>
