<p-card>
  <ng-template pTemplate="header">
    <div class="p-3 avatar-row">
      <p-avatar [label]="config.letter | translate" styleClass="p-mr-2" size="xlarge" shape="circle"> </p-avatar>
    </div>
    <h3 class="text-center">{{ config.title | translate}}</h3>
  </ng-template>
  <p class="text-center">
    {{ config.description | translate}}
  </p>
  <ng-template pTemplate="footer">
    <div class="flex justify-content-center">
      <button pRipple pButton class="p-button-primary" type="button" [routerLink]="config.link">
        {{ config.linkTitle | translate}}
      </button>
    </div>
  </ng-template>
</p-card>
