export interface LayoutDimensions {
    minItemHeight?: number;
    minItemWidth?: number;
    headerHeight: number;
    borderWidth: number;
  
    height: string | number;
    width: string | number;
    margin: number;
    confHeight: string | number;
  }
  
  export function createLayoutDimensions(layoutDimensionsOptions: Partial<LayoutDimensions> = {}): LayoutDimensions{
    return {
      minItemWidth: 50,
      minItemHeight: 50,
      headerHeight: 25,
      borderWidth: 5,
      confHeight: '100%',
      height: '100%',
      width: '100%',
      margin: 5,
      ...layoutDimensionsOptions
    }
  }