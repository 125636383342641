import { KE_PRESETS_PATH, KE_ROOT_ROUTE } from '@selfai-platform/shell';

export function getPresetsPath(): string[] {
  return ['/', KE_ROOT_ROUTE, KE_PRESETS_PATH];
}

export function getPresetsPathEditPath(datasourceId: string): string[] {
  return getPresetsPath().concat(['edit', datasourceId]);
}

export function getPresetsPathCreatePath(): string[] {
  return getPresetsPath().concat(['create']);
}
