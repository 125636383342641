import { Filter, createFilter } from './filter.model';

export interface BoundFilter extends Filter {
  min: number;
  max: number;
  minValue?: number;
  maxValue?: number;
}

export function createBoundFilter(field: string, params: Partial<BoundFilter> = {}): BoundFilter {
  return {
    ...createFilter(),
    type: 'bound',
    field,
    ...params,
  } as BoundFilter;
}
