<div
  class="ddp-wrap-chart-side"
  [ngClass]="{
    'ddp-selected': rnbMenu === 'filter' || (isChartShow && rnbMenu !== '' && possibleChartCheck(rnbMenu, selectChart)),
    'type-popup': panelZIndex
  }"
  *ngIf="rnbMenu === 'filter' || rnbMenu === 'common' || ('' !== rnbMenu && isChartShow)"
>
  <div class="ddp-area-boardside ddp-chart-side-in">
    <common-option
      #commonOption
      *ngIf="'common' === rnbMenu"
      [(uiOption)]="uiOptionValue"
      [(pivot)]="pivot"
      (drawChartParam)="onSetDrawChartParam.emit($event)"
      (changeAxisByStack)="changeAxisByStack.emit($event)"
      (showConfirmPopup)="onShowPopup.emit($event)"
    ></common-option>
    <color-option
      #colorOption
      *ngIf="isChartShow && possibleChartCheck('color', selectChart) && 'color' === rnbMenu"
      [(uiOption)]="uiOptionValue"
      [(resultData)]="resultDataValue"
      [pivot]="pivot"
      (drawChartParam)="onSetDrawChartParam.emit($event)"
    ></color-option>
    <legend-option
      #legendOption
      *ngIf="isChartShow && possibleChartCheck('legend', selectChart) && 'legend' === rnbMenu"
      [(uiOption)]="uiOptionValue"
    ></legend-option>
    <format-option
      #formatOption
      *ngIf="isChartShow && possibleChartCheck('format', selectChart) && 'format' === rnbMenu"
      (changeCommon)="onFormatCommonChange.emit($event)"
      (changeEach)="onFormatEachChange.emit($event)"
      (drawChartParam)="onSetDrawChartParam.emit($event)"
      [(pivot)]="pivot"
      [(uiOption)]="uiOptionValue"
    ></format-option>
    <yaxis-option
      #yAxisOption
      *ngIf="isChartShow && possibleChartCheck('yAxis', selectChart) && 'yAxis' === rnbMenu"
      [(uiOption)]="uiOptionValue"
      [(pivot)]="pivot"
      (drawChartParam)="onSetDrawChartParam.emit($event)"
    ></yaxis-option>
    <xaxis-option
      #xAxisOption
      *ngIf="isChartShow && possibleChartCheck('xAxis', selectChart) && 'xAxis' === rnbMenu"
      [(uiOption)]="uiOptionValue"
      [pivot]="pivot"
      (pivotChange)="pivotChange.emit($event)"
      (drawChartParam)="onSetDrawChartParam.emit($event)"
    ></xaxis-option>
    <secondary-axis-option
      #secondaryAxisOption
      *ngIf="isChartShow && possibleChartCheck('secondaryAxis', selectChart) && 'secondaryAxis' === rnbMenu"
      [(uiOption)]="uiOptionValue"
      [pivot]="pivot"
      (pivotChange)="pivotChange.emit($event)"
      (drawChartParam)="onSetDrawChartParam.emit($event)"
    ></secondary-axis-option>
    <datalabel-option
      #dataLabelOption
      *ngIf="isChartShow && possibleChartCheck('dataLabel', selectChart) && 'dataLabel' === rnbMenu"
      [(uiOption)]="uiOptionValue"
      [pivot]="pivot"
    ></datalabel-option>
    <tooltip-option
      #tooltipOption
      *ngIf="isChartShow && possibleChartCheck('tooltip', selectChart) && 'tooltip' === rnbMenu"
      [(uiOption)]="uiOptionValue"
      [pivot]="pivot"
    ></tooltip-option>
    <secondary-indicator-option
      #secondaryIndicatorOption
      *ngIf="isChartShow && possibleChartCheck('secondaryIndicator', selectChart) && 'secondaryIndicator' === rnbMenu"
      [(uiOption)]="uiOptionValue"
      [(pivot)]="pivot"
      [widget]="widget"
      [dashboard]="dashboard"
    ></secondary-indicator-option>
    <split-option
      #splitOption
      *ngIf="isChartShow && possibleChartCheck('split', selectChart) && 'split' === rnbMenu"
      (drawChartParam)="onSetDrawChartParam.emit($event)"
      [(uiOption)]="uiOptionValue"
    ></split-option>
    <page-filter-panel
      *ngIf="'filter' === rnbMenu"
      [dashboard]="dashboard"
      [boardFilters]="boardFilters"
      [chartFilters]="widgetConfiguration.filters"
      (updateFilter)="updateFilter.emit($event)"
      (deleteFilter)="deleteFilter.emit($event)"
      (openUpdateFilterPopup)="openUpdateFilterPopup.emit($event)"
      (changeGlobalToChart)="openConfirmPopup.emit([$event, 'toChartFilter'])"
    ></page-filter-panel>
    <calc-option
      #calculateOption
      *ngIf="isChartShow && possibleChartCheck('calculatedRow', selectChart) && 'calculatedRow' === rnbMenu"
      [(uiOption)]="uiOptionValue"
    ></calc-option>
    <map-common-option
      #mapCommonOption
      *ngIf="isChartShow && 'mapCommon' === rnbMenu"
      [(uiOption)]="uiOptionValue"
    ></map-common-option>
    <map-layer-option
      #mapLayerOption
      *ngIf="
        isChartShow &&
        ('mapLayer1' === rnbMenu || 'mapLayer2' === rnbMenu || 'mapLayer3' === rnbMenu) &&
        !setDisableMapLayer()
      "
      [(uiOption)]="uiOptionValue"
      [(shelf)]="shelfValue"
      [rnbMenu]="rnbMenu"
      (drawChartParam)="onSetDrawChartParam.emit($event)"
      [data]="resultData?.data"
      (setZIndex)="onSetZIndex($event)"
    ></map-layer-option>
    <map-legend-option
      #mapLegendOption
      *ngIf="isChartShow && 'mapLegend' === rnbMenu"
      [(uiOption)]="uiOptionValue"
      (drawChartParam)="onSetDrawChartParam.emit($event)"
    ></map-legend-option>
    <map-format-option
      #mapFormatOption
      *ngIf="isChartShow && 'mapFormat' === rnbMenu"
      (changeCommon)="onFormatCommonChange($event)"
      (changeEach)="onFormatEachChange($event)"
      (drawChartParam)="onSetDrawChartParam.emit($event)"
      [(shelf)]="shelfValue"
      [(uiOption)]="uiOptionValue"
    ></map-format-option>
    <map-tooltip-option
      #mapTooltipOption
      *ngIf="isChartShow && 'mapTooltip' === rnbMenu"
      [(uiOption)]="uiOptionValue"
      [(shelf)]="shelfValue"
      (drawChartParam)="onSetDrawChartParam.emit($event)"
    ></map-tooltip-option>
  </div>
</div>
