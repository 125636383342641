<ng-container *ngIf="updateActiveStatus$ | async"></ng-container>

<p-card class="card-{{ deepLevel % 2 === 0 ? 'even' : 'odd' }}" styleClass="mb-2">
  <div class="wrapper">
    <ng-container *ngIf="isUnknownStepType">
      <p-message class="block" severity="error" text="Unknown step type"></p-message>
    </ng-container>
    <ng-container *ngIf="type">
      <selfai-platform-step-filter
        *ngIf="type === stepType.FILTER"
        [form]="form"
        [index]="index"
      ></selfai-platform-step-filter>
      <selfai-platform-step-aggregator
        *ngIf="
          [
            stepType.AGGREGATOR,
            stepType.MAX_VALUE_AGGREGATION,
            stepType.UNIQ_VALUES_AGGREGATION,
            stepType.AGGREGATOR_IN_TIME,
            stepType.UNIQ_VALUES_AGGREGATION_IN_TIME,
            stepType.MAX_VALUE_AGGREGATION_IN_TIME
          ].includes(type)
        "
        [form]="form"
      ></selfai-platform-step-aggregator>

      <selfai-platform-step-variable *ngIf="type === stepType.VARIABLE" [form]="form"></selfai-platform-step-variable>
      <selfai-platform-step-parallel *ngIf="type === stepType.PARALLEL" [form]="form"></selfai-platform-step-parallel>
      <selfai-platform-step-output *ngIf="type === stepType.OUTPUT" [form]="form"></selfai-platform-step-output>
      <selfai-platform-step-to *ngIf="type === stepType.TO" [form]="form"></selfai-platform-step-to>
      <selfai-platform-step-other-process
        *ngIf="type === stepType.OTHER_PROCESS"
        [form]="form"
      ></selfai-platform-step-other-process>
    </ng-container>
    <div class="actions-wrapper">
      <ng-container *ngIf="index > 0 || index < routeStepsFormArray.length - 1">
        <button
          type="button"
          appearance="icon"
          pButton
          class="p-button-secondary p-button-text p-button-rounded p-button-link mb-1 btn-arrow"
          icon="pi pi-arrow-up"
          [disabled]="index === 0"
          (click)="orderUp()"
        ></button>
        <button
          type="button"
          appearance="icon"
          pButton
          class="p-button-secondary p-button-text p-button-rounded p-button-link mb-1 btn-arrow"
          icon="pi pi-arrow-down"
          [disabled]="index === routeStepsFormArray.length - 1"
          (click)="orderDown()"
        ></button>
      </ng-container>
      <button
        (click)="removeStep()"
        pButton
        class="p-button-text p-button-rounded p-button-link mb-1 p-button-danger"
        appearance="icon"
        icon="pi pi-trash"
        type="button"
      ></button>
    </div>
  </div>

  <ng-container *ngIf="childSteps.length > 0">
    <ng-container *ngFor="let childFormGroup of childSteps; index as i">
      <selfai-platform-aggregator-camel-step
        [form]="childFormGroup"
        [index]="i"
        [deepLevel]="deepLevel + 1"
      ></selfai-platform-aggregator-camel-step>
    </ng-container>
  </ng-container>

  <p class="mt-3" *ngIf="canHasChidren">
    <p-splitButton
      #splitButton
      *ngIf="stepsMenu$ | async as stepsMenu"
      (onClick)="splitButton.onDropdownButtonClick($event)"
      appendTo="body"
      class="p-button-sm"
      label="Add child block"
      icon="pi pi-plus"
      [model]="stepsMenu"
    ></p-splitButton>
  </p>
</p-card>
