import { AfterViewInit, Component, ElementRef, Injector, OnInit } from '@angular/core';

import {
  BaseOption,
  CHART_STRING_DELIMITER,
  ChartType,
  Pivot,
  Position,
  SeriesType,
  ShelveFieldType,
  ShelveType,
  UIChartFormat,
  UIWaterfallChart,
  UiChartDataLabelDisplayType,
  WaterfallBarColor,
  createPivotTableInfo,
} from '@selfai-platform/bi-domain';
import { DestroyService } from '@selfai-platform/shared';
import { BarSeriesOption } from 'echarts';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import * as _ from 'lodash';
import { FormatOptionConverter } from '../../converters';
import { provideBaseChartServices } from '../../services';
import { EChartService } from '../../services/echart.service';
import { OptionGenerator } from '../../utils';
import { BaseChart } from '../base-chart';

import optGen = OptionGenerator;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'waterfall-chart',
  template: '',
  styleUrls: ['./chart-host.component.scss'],
  providers: [...provideBaseChartServices()],
})
export class WaterFallChartComponent extends BaseChart implements OnInit, AfterViewInit {
  constructor(elementRef: ElementRef, destroy$: DestroyService, eChartService: EChartService, injector: Injector) {
    super(elementRef, destroy$, eChartService, injector);
  }

  public override isValid(shelve: Pivot): boolean {
    return (
      this.getFieldTypeCount(shelve, ShelveType.COLUMNS, ShelveFieldType.TIMESTAMP) == 1 &&
      ((this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.MEASURE) == 1 &&
        this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.CALCULATED) == 0) ||
        (this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.MEASURE) == 0 &&
          this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.CALCULATED) == 1)) &&
      this.getFieldTypeCount(shelve, ShelveType.COLUMNS, ShelveFieldType.DIMENSION) == 0 &&
      this.getFieldTypeCount(shelve, ShelveType.COLUMNS, ShelveFieldType.MEASURE) == 0 &&
      this.getFieldTypeCount(shelve, ShelveType.COLUMNS, ShelveFieldType.CALCULATED) == 0 &&
      this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.DIMENSION) == 0 &&
      this.getFieldTypeCount(shelve, ShelveType.AGGREGATIONS, ShelveFieldType.TIMESTAMP) == 0
    );
  }

  public override draw(isKeepRange?: boolean): void {
    this.pivotInfo = createPivotTableInfo(this.data.rows, [], this.fieldInfo.aggs);

    super.draw(isKeepRange);
  }

  protected override initOption(): BaseOption {
    return {
      grid: [OptionGenerator.Grid.verticalMode(10, 0, 0, 10, false, true, false)],
      xAxis: [OptionGenerator.Axis.categoryAxis(Position.INSIDE, null, false, true, true, true)],
      yAxis: [OptionGenerator.Axis.valueAxis(Position.INSIDE, null, false, false, true, true, true)],
      dataZoom: [OptionGenerator.DataZoom.horizontalDataZoom(), OptionGenerator.DataZoom.horizontalInsideDataZoom()],
      tooltip: OptionGenerator.Tooltip.axisTooltip(),
      toolbox: OptionGenerator.Toolbox.hiddenToolbox(),
      series: [],
    };
  }

  protected override convertSeriesData(): BaseOption {
    const column = this.data.columns[0];
    const sourceData = column.value;

    const barColor = (<UIWaterfallChart>this.uiOption).barColor;

    const positiveColor = barColor && barColor.positive ? barColor.positive : WaterfallBarColor.POSITIVE.toString();

    const negativeColor = barColor && barColor.negative ? barColor.negative : WaterfallBarColor.NEGATIVE.toString();

    const { placeholderData, realData } = this.generateWaterfallData(sourceData);

    const transparentSeria: BarSeriesOption = {
      type: SeriesType.BAR,
      name: this.fieldInfo.aggs[0],
      stack: ChartType.WATERFALL.toString(),
      label: optGen.LabelStyle.auto(),
      itemStyle: {
        borderColor: 'transparent',
        color: 'transparent',
      },
      emphasis: {
        itemStyle: {
          borderColor: 'transparent',
          color: 'transparent',
        },
      },
      data: placeholderData,
    };

    const visibleSeria: BarSeriesOption = {
      type: SeriesType.BAR,
      name: this.fieldInfo.aggs[0],
      stack: ChartType.WATERFALL.toString(),
      label: optGen.LabelStyle.auto(),
      itemStyle: {
        color: (params: CallbackDataParams) => {
          return sourceData[params.dataIndex] > 0 ? positiveColor : negativeColor;
        },
      },
      data: realData,
    };

    this.chartOption.series = [transparentSeria, visibleSeria];

    return this.chartOption;
  }

  protected override additionalTooltip(): BaseOption {
    const sourceData = this.data.columns[0].categoryValue;

    if (!_.isUndefined(this.chartOption.tooltip) && !Array.isArray(this.chartOption.tooltip)) {
      this.chartOption.tooltip.formatter = (params) => {
        return this.formatter(params, sourceData);
      };
    }

    return this.chartOption;
  }

  protected override selection(): void {
    this.addChartSelectEventListener();
    this.addChartMultiSelectEventListener();
  }

  protected override selectionClear(option: BaseOption): BaseOption {
    const series = (option as BaseOption).series;

    this.pivot.columns.forEach((item: any) => {
      delete item['data'];
    });

    this.pivot.aggregations.forEach((item: any) => {
      delete item['data'];
    });

    if (Array.isArray(series) && series.length > 0) {
      series.forEach((obj, idx) => {
        if (0 < idx) {
          (obj.data as any[])?.forEach((item) => {
            this.clearSelectSeriesData(item);
          });
        }
      });
    }

    return option;
  }

  protected override setUIData(): any {
    const sourceData = this.data.columns[0].value;
    const categoryValue = _.gt(sourceData[0], 0) ? [sourceData[0]] : [];

    sourceData.map((value: any, idx: number) => {
      if (idx > 0) {
        if (_.gt(value, sourceData[idx - 1])) {
          categoryValue.push(value - sourceData[idx - 1]);
        } else {
          categoryValue.push(sourceData[idx - 1] - value);
        }
      }
    });

    _.each(this.data.columns, (data) => {
      data.categoryName = _.cloneDeep(this.data.rows);
      data.categoryValue = _.cloneDeep(categoryValue);
    });

    return this.data.columns;
  }

  protected override apply(initFl = true): void {
    if (this.userCustomFunction && '' !== this.userCustomFunction && -1 < this.userCustomFunction.indexOf('main')) {
      const strScript = '(' + this.userCustomFunction + ')';

      try {
        // eslint-disable-next-line no-eval
        this.chartOption = eval(strScript)({ name: 'InitWidgetEvent', data: this.chartOption });
      } catch (e) {
        console.error(e);
      }
    }

    this.chart.setOption(this.chartOption, true, false);
  }

  private formatter(params: any, sourceData: any): any {
    if (!this.uiOption.toolTip) this.uiOption.toolTip = {};
    if (!this.uiOption.toolTip.displayTypes && this.uiOption.type) {
      this.uiOption.toolTip.displayTypes = FormatOptionConverter.setDisplayTypes(this.uiOption.type);
    }

    let format: UIChartFormat = this.uiOption.valueFormat as UIChartFormat;

    const axisFormat = FormatOptionConverter.getlabelAxisScaleFormatTooltip(this.uiOption);
    if (axisFormat) format = axisFormat;

    let status;
    if (!_.eq(params[1].value, '-')) {
      status = params[2];
    } else {
      status = params[1];
    }

    let result: string[] = [];
    if (!this.uiOption.toolTip.displayTypes) {
      const nameList = _.split(status.name, CHART_STRING_DELIMITER);
      result = FormatOptionConverter.getTooltipName(nameList, this.pivot.columns, result, true);

      const seriesValue = FormatOptionConverter.getTooltipValue(
        status.name,
        this.pivot.aggregations,
        format,
        sourceData[status.dataIndex],
      );
      result.push(seriesValue);
    } else {
      if (-1 !== this.uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.CATEGORY_NAME)) {
        const nameList = _.split(status.name, CHART_STRING_DELIMITER);
        result = FormatOptionConverter.getTooltipName(nameList, this.pivot.columns, result, true);
      }

      if (-1 !== this.uiOption.toolTip.displayTypes.indexOf(UiChartDataLabelDisplayType.CATEGORY_VALUE)) {
        const seriesValue = FormatOptionConverter.getTooltipValue(
          status.seriesName,
          this.pivot.aggregations,
          format,
          sourceData[status.dataIndex],
        );
        result.push(seriesValue);
      }
    }

    return result.join('<br/>');
  }

  private generateWaterfallData(data: number[]): { placeholderData: number[]; realData: number[] } {
    const placeholderData = [0];
    const realData = data.map(Math.abs);

    let currentSum = realData[0];
    for (let i = 1; i < data.length; i++) {
      if (data[i - 1] > 0 && i > 1) {
        currentSum += data[i - 1];
      }
      if (data[i] < 0) {
        currentSum += data[i];
      }

      placeholderData.push(currentSum);
    }

    return { placeholderData, realData };
  }
}
