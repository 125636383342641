<div class="ddp-box-widget" [class.readonly]="readonly">
  <div
    *ngIf="isAuthMgmtViewMode"
    class="ddp-top-control ddp-clear"
    [ngStyle]="{ 'margin-right': isVisibleScrollbar ? '20px' : '' }"
  >
    <div class="ddp-ui-buttons" *ngIf="!readonly">
      <div class="ddp-box-btn2" (click)="editWidget('MOVE_EDIT_WIDGET')">
        <em class="ddp-icon-widget-edit"></em>
        <div class="ddp-ui-tooltip-info">
          <em class="ddp-icon-view-down"></em>
          {{ 'msg.comm.ui.edit' | translate }}
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="filter" #filterWidget class="ddp-view-widget ddp-view-widget-filter">
    <div class="ddp-ui-top">
      <div *ngIf="isViewMode && isShowTitle" class="ddp-ui-title">
        <i class="pi pi-filter"></i>
        {{ getWidgetName() }}
        <span class="ddp-no-filter" *ngIf="isViewMode && isNoFiltering()">
          <em class="ddp-icon-info" [pTooltip]="'msg.comm.ui.no.filtering' | translate" tooltipPosition="bottom"></em>
        </span>
      </div>
    </div>
    <div
      *ngIf="filter.type === 'include'"
      [ngStyle]="{ top: isShowTitle ? '38px' : '0px' }"
      class="ddp-ui-widget-contents"
    >
      <selfai-bi-filter-select
        *ngIf="isListFilter()"
        [inputCandidateList]="filteredCandidateList"
        [selectedItems]="selectedItems"
        [viewKey]="'name'"
        [isMockup]="isEditMode"
        [filter]="filter"
        [dashboard]="dashboard"
        (afterSelected)="onSelectInclude($event)"
        (afterCheckAll)="checkAllInclude()"
        (changeDisplayOptions)="toggleOptionsSelectComp($event)"
      ></selfai-bi-filter-select>
      <div
        *ngIf="
          isViewMode &&
          isSearchingCandidateAvailability &&
          (convertToIncludeFilter(filter).selector === inclusionSelectorType.MULTI_LIST ||
            convertToIncludeFilter(filter).selector === inclusionSelectorType.SINGLE_LIST)
        "
        class="ddp-list-search-candidate"
      >
        <a (click)="candidateFromSearchText()" class="ddp-btn-line">{{ 'msg.comm.ui.search-all' | translate }}</a>
        <div class="ddp-search-text">
          <input type="text" class="ddp-input-search" [(ngModel)]="searchText" />
        </div>
      </div>
      <ul
        *ngIf="convertToIncludeFilter(filter).selector === inclusionSelectorType.MULTI_LIST"
        class="ddp-list-checktype"
      >
        <li>
          <label class="ddp-label-checkbox">
            <input
              type="checkbox"
              (click)="checkAllInclude($event)"
              [disabled]="isEditMode"
              [checked]="
                convertToIncludeFilter(filter).valueList.length > 0 &&
                convertToIncludeFilter(filter).valueList.length === candidateList.length
              "
            />
            <i class="ddp-icon-checkbox ddp-dark"></i>
            <span class="ddp-txt-checkbox">({{ 'msg.board.filter.ui.all' | translate }})</span>
          </label>
        </li>
        <li *ngFor="let item of filteredCandidateList">
          <label class="ddp-label-checkbox">
            <input
              type="checkbox"
              (click)="onSelectInclude(item)"
              [disabled]="isEditMode"
              [checked]="convertToIncludeFilter(filter).valueList.indexOf(item.name) > -1"
            />
            <i class="ddp-icon-checkbox ddp-dark"></i>
            <span class="ddp-txt-checkbox" [ngStyle]="item.isTemporary ? { color: '#9499c2' } : {}">
              {{ item.name }}
            </span>
          </label>
        </li>
      </ul>
      <ul
        *ngIf="convertToIncludeFilter(filter).selector === inclusionSelectorType.SINGLE_LIST"
        class="ddp-list-checktype"
      >
        <li>
          <label class="ddp-label-radio">
            <input
              type="radio"
              name="{{ filter.field + widget.id }}"
              (click)="checkAllInclude($event)"
              [disabled]="isEditMode"
              [checked]="convertToIncludeFilter(filter).valueList.length === 0"
            />
            <i class="ddp-icon-radio"></i>
            <span class="ddp-txt-radio">({{ 'msg.board.filter.ui.all' | translate }})</span>
          </label>
        </li>
        <li *ngFor="let item of candidateList">
          <label class="ddp-label-radio">
            <input
              type="radio"
              name="{{ filter.field + widget.id }}"
              (click)="onSelectInclude(item)"
              [disabled]="isEditMode"
              [checked]="convertToIncludeFilter(filter).valueList.indexOf(item.name) > -1"
            />
            <i class="ddp-icon-radio"></i>
            <span class="ddp-txt-radio" [ngStyle]="item.isTemporary ? { color: '#9499c2' } : {}">{{ item.name }}</span>
          </label>
        </li>
      </ul>
    </div>
    <div
      *ngIf="filter.type === 'bound'"
      [ngStyle]="{ top: isShowTitle ? '38px' : '0px' }"
      class="ddp-ui-widget-contents"
    >
      <div class="ddp-display-table">
        <div class="ddp-table-in">
          <bound-filter [filter]="filter" (changeFilterData)="applyValue()"></bound-filter>
        </div>
      </div>
    </div>
    <div
      *ngIf="isTimeFilter"
      [ngStyle]="{
        top: isShowTitle ? (isRelativeTypeTimeFilter ? '47px' : '39px') : '0px',
        'padding-top': isListTypeTimeFilter || isDiscontinuousFilter ? '0px' : ''
      }"
      class="ddp-ui-widget-contents"
    >
      <div class="ddp-filter-view ddp-full">
        <span *ngIf="isAllTypeTimeFilter" class="ddp-txt-preview">(No time filtering)</span>
      </div>
      <div *ngIf="isRelativeTypeTimeFilter" class="ddp-filter-view ddp-full">
        <selfai-bi-time-relative-filter
          [mode]="'WIDGET'"
          [filter]="filter"
          (changeFilter)="changeFilterEvent($event)"
        ></selfai-bi-time-relative-filter>
      </div>
      <selfai-bi-time-range-filter
        *ngIf="isRangeTypeTimeFilter"
        [mode]="'WIDGET'"
        [filter]="filter"
        [dashboard]="dashboard"
        (changeFilter)="changeFilterEvent($event)"
      ></selfai-bi-time-range-filter>
      <app-time-list-filter
        *ngIf="isListTypeTimeFilter || isDiscontinuousFilter"
        [mode]="'WIDGET'"
        [inputFilter]="filter"
        [dashboard]="dashboard"
        [field]="field"
        (changeFilter)="changeFilterEvent($event)"
      ></app-time-list-filter>
    </div>
  </div>
  <error-widget
    [isShow]="isError"
    [iconClass]="'ddp-chart-filter'"
    [widgetName]="getWidgetName()"
    [errorInfo]="errorInfo"
  ></error-widget>
  <div *ngIf="isEditMode" class="ddp-hover-edit">
    <div class="ddp-hover-edit-in">
      <button (click)="editWidget('MOVE_EDIT_WIDGET')" type="button" class="ddp-btn-edit"></button>
    </div>
  </div>
</div>
