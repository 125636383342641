import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'rxjsOf',
})
export class RxjsOfPipe implements PipeTransform {
  transform<T>(value: T): Observable<T> {
    return of(value);
  }
}
