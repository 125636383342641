import { EntityMetadataMap } from '@ngrx/data';
import { WORKSPACE_ENTITY_NAME, WORKSPACE_LIST_ENTITY_NAME } from '../constants';
import { WorkspaceState } from '../models';

const entityMetadata: EntityMetadataMap = {
  [WORKSPACE_LIST_ENTITY_NAME]: {},
  [WORKSPACE_ENTITY_NAME]: {
    selectId: (entity: WorkspaceState) => entity.workspaceId,
  },
};

export const biDomainWorkspaceEntityConfig = {
  entityMetadata,
};
