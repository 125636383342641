import { Injectable, OnDestroy } from '@angular/core';
import { NodeUsingColumn, WorkflowSerialized } from '@selfai-platform/pipeline-common';
import { WorkflowStateService } from '@selfai-platform/storage';
import { BehaviorSubject, combineLatest, filter, Observable } from 'rxjs';
import { collectAllValues } from '../../utils';
import { VariableItemsService } from './variable-item.service';

@Injectable()
export class VariablesUsedInWorkflowService
  extends BehaviorSubject<Map<string, NodeUsingColumn[]>>
  implements OnDestroy
{
  get usedInMap$(): Observable<Map<string, NodeUsingColumn[]>> {
    return this.asObservable();
  }

  constructor(
    private readonly workflowStateService: WorkflowStateService,
    private readonly varaiblesservices: VariableItemsService,
  ) {
    super(new Map());

    combineLatest([this.workflowStateService.getWorkflowState(), this.varaiblesservices])
      .pipe(filter(([workflow, varaibles]) => !!workflow && !!varaibles))
      .subscribe((data) => {
        const keys = data[1].map((t) => t.key);
        this.calculateVaraiblesMap(data[0] as WorkflowSerialized, keys);
      });
  }

  ngOnDestroy(): void {
    this.complete();
  }

  private calculateVaraiblesMap(workflow: WorkflowSerialized, variableKeys: string[]): void {
    const columnsMap = new Map<string, NodeUsingColumn[]>();
    const cubesForSearch = workflow.workflow.nodes.map((cube) => {
      const result = collectAllValues(cube.parameters);

      return {
        id: cube.id,
        name: workflow.thirdPartyData.gui.nodes[cube.id]?.uiName || cube.operation?.name || '',
        searchText: result,
      };
    });
    for (const variablekey of variableKeys) {
      const usedIn: NodeUsingColumn[] = [];
      cubesForSearch.forEach((cube) => {
        for (let i = 0; i < cube.searchText.length; i++) {
          if (cube.searchText[i].includes(`\${${variablekey}}`)) {
            usedIn.push({
              nodeId: cube.id,
              title: cube.name,
            });

            return;
          }
        }
      });

      if (usedIn.length > 0) {
        columnsMap.set(variablekey, usedIn);
      }
    }

    this.next(columnsMap);
  }
}
