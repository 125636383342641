<div *ngIf="isShow && !isDiscontinuousFilter && isContinuousByAll">
  <timeUnit-select
    *ngIf="isShowGranularitySelect && targetFilter"
    [mode]="'CHANGE'"
    [filter]="targetFilter"
    (change)="changeTimeUnit($event)"
  ></timeUnit-select>

  <div class="ddp-toggle">
    <ul class="ddp-list-buttons">
      <li (click)="setTimeRangeFilter()" [class.ddp-selected]="isRangeType">{{ 'msg.absolute' | translate }}</li>
      <li (click)="setTimeRelativeFilter()" [class.ddp-selected]="isRelativeType">{{ 'msg.relative' | translate }}</li>
    </ul>
  </div>

  <div class="ddp-wrap-toggle-contents">
    <div *ngIf="isAllType" class="ddp-ui-toggle-contents">
      <span class="ddp-txt-preview">(No time filtering)</span>
    </div>

    <div *ngIf="isRelativeType" class="ddp-ui-toggle-contents">
      <selfai-bi-time-relative-filter [filter]="targetFilter"></selfai-bi-time-relative-filter>
    </div>

    <div *ngIf="isRangeType" class="ddp-ui-toggle-contents">
      <selfai-bi-time-range-filter [filter]="targetFilter" [dashboard]="dashboard"></selfai-bi-time-range-filter>
    </div>
  </div>
</div>
<div *ngIf="isShow && !isDiscontinuousFilter && !isContinuousByAll">
  <timeUnit-select
    *ngIf="isShowGranularitySelect && targetFilter"
    [mode]="'CHANGE'"
    [filter]="targetFilter"
    (change)="changeTimeUnit($event)"
  ></timeUnit-select>

  <div class="ddp-toggle">
    <ul class="ddp-list-buttons">
      <li (click)="setTimeListFilter()" [class.ddp-selected]="isListType">
        {{ 'msg.all-time-list' | translate }}
      </li>
      <li (click)="setTimeRangeFilter()" [class.ddp-selected]="isRangeType">
        {{ 'msg.specific-time-list' | translate }}
      </li>
    </ul>
  </div>

  <div class="ddp-wrap-toggle-contents">
    <div *ngIf="isListType" class="ddp-ui-toggle-contents">
      <app-time-list-filter
        [inputFilter]="targetFilter"
        [dashboard]="dashboard"
        [field]="targetField"
      ></app-time-list-filter>
    </div>

    <div *ngIf="isRangeType" class="ddp-ui-toggle-contents">
      <selfai-bi-time-range-filter [filter]="targetFilter" [dashboard]="dashboard"></selfai-bi-time-range-filter>
    </div>
  </div>
</div>

<div *ngIf="isShow && isDiscontinuousFilter">
  <timeUnit-select
    *ngIf="isShowGranularitySelect && targetFilter"
    [mode]="'CHANGE'"
    [filter]="targetFilter"
    (change)="changeTimeUnit($event)"
  ></timeUnit-select>

  <div class="ddp-filter-form">
    <app-time-list-filter
      [inputFilter]="targetFilter"
      [dashboard]="dashboard"
      [field]="targetField"
    ></app-time-list-filter>
  </div>
</div>
