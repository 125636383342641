/* eslint-disable max-lines */
import {
  AfterViewInit,
  Component,
  ElementRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  BaseOption,
  CHART_STRING_DELIMITER,
  CellColorTarget,
  ChartColorType,
  ChartSelectMode,
  ColorCustomMode,
  FontSize,
  GridViewType,
  Pivot,
  ShelveFieldType,
  ShelveType,
  UIChartColorByCell,
  UIChartColorGradationByCell,
  UIGridChart,
  UIOption,
  UIOrient,
  UIPosition,
} from '@selfai-platform/bi-domain';

import { TranslateService } from '@ngx-translate/core';
import { DestroyService } from '@selfai-platform/shared';
import * as _ from 'lodash';
import { provideBaseChartServices } from '../../services';
import { EChartService } from '../../services/echart.service';
import { BaseChart } from '../base-chart';

declare let pivot: any;
declare let $: any;

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'grid-chart',
  template: '<div class="chartCanvas" style="width: 100%; height: 100%; display: block;"></div>',
  styleUrls: ['./chart-host.component.scss', './grid-chart.component.scss'],
  providers: [...provideBaseChartServices()],
})
export class GridChartComponent extends BaseChart implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() aggregation: string;
  @Input() setPivot: Pivot;

  $window = window;
  isLoaded = false;

  private gridModel: any;
  private originData: any;
  // Temprorary type. After replacing pivot grid, it will be changed
  pivotGrid: {
    arrange: () => void;
    DATA_COL_MODE: {
      TOP: string;
      LEFT: string;
    };
    initialize: (data: any, gridModel: any) => void;
  };

  @Input()
  viewMode = false;

  constructor(
    elementRef: ElementRef,
    destroy$: DestroyService,
    eChartService: EChartService,
    injector: Injector,
    private translate: TranslateService,
  ) {
    super(elementRef, destroy$, eChartService, injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes['setUIOption'] || changes['setPivot']) && this.isUpdateRedraw) {
      this.draw();
    }
  }

  override ngAfterViewInit(): void {
    this.isLoaded = true;
    pivot.ui.style.summaryLabel = {
      SUM: this.translate.instant('msg.page.calc.label.operator.sum'),
      AVERAGE: this.translate.instant('msg.page.calc.label.operator.average'),
      MAX: this.translate.instant('msg.page.calc.label.operator.max'),
      MIN: this.translate.instant('msg.page.calc.label.operator.min'),
      COUNT: this.translate.instant('msg.page.calc.label.operator.count'),
    };
    const gridChartElement = this.elementRef.nativeElement.querySelector<HTMLElement>('.chartCanvas');
    this.pivotGrid = new pivot.ui.pivot.Viewer(gridChartElement);
    if (this.data) {
      this.draw();
    }
  }

  override draw(): void {
    if (!this.pivotGrid) {
      return;
    }
    if (!this.isValid(this.pivot)) {
      this.noData.emit();

      return;
    }
    this.chartOption = this.convertBasic();
    this.chartOption = this.convertSeries();
    this.apply();
    this.drawFinish();
  }

  override isValid(pivot: Pivot): boolean {
    const hasColumnsDimension = this.getFieldTypeCount(pivot, ShelveType.COLUMNS, ShelveFieldType.DIMENSION) > 0;
    const hasColumnsTimestamp = this.getFieldTypeCount(pivot, ShelveType.COLUMNS, ShelveFieldType.TIMESTAMP) > 0;
    const hasRowsDimension = this.getFieldTypeCount(pivot, ShelveType.ROWS, ShelveFieldType.DIMENSION) > 0;
    const hasRowsTimestamp = this.getFieldTypeCount(pivot, ShelveType.ROWS, ShelveFieldType.TIMESTAMP) > 0;

    return hasColumnsDimension || hasColumnsTimestamp || hasRowsDimension || hasRowsTimestamp;
  }

  addGridSelectEventListener(params): void {
    const selectMode = params.isSelect ? ChartSelectMode.ADD : ChartSelectMode.SUBTRACT;
    const selectDataList = [];

    const pivot = $(this)[0]['shelve'];

    if (!pivot || !pivot.columns) return;

    const shelveList = _.cloneDeep(pivot.columns.concat(pivot.rows));

    if (!_.isUndefined(params.parentData)) {
      const data = _.cloneDeep(_.find(shelveList, { alias: params.key }));

      if (!data) return;

      data['data'] = [params.data];

      selectDataList.push(data);

      if (!_.isNull(params.parentData)) {
        _.forEach(params.parentData, (value, key) => {
          const data = _.cloneDeep(_.find(shelveList, { alias: key }));

          if (!data) return;

          data['data'] = [value];

          selectDataList.push(data);
        });
      }
    } else {
      let dimensionList = _.concat(this['xProperties'], this['yProperties']);
      dimensionList = dimensionList.map((dimension) => {
        return dimension.name;
      });

      _.map(params, (item: string) => {
        const splitItem = _.split(item, CHART_STRING_DELIMITER);

        if (-1 !== _.indexOf(dimensionList, splitItem[0])) {
          const data = _.cloneDeep(_.find(shelveList, { alias: splitItem[0] }));

          data['data'] = [splitItem[1]];

          selectDataList.push(data);
        }
      });
    }

    this['gridSelectInfo'].emit({ mode: selectMode, data: selectDataList, params: this['customParams'] });
  }

  protected override convertBasic(): BaseOption {
    if (!this.uiOption) return;
    this.uiOption.maxValue = this.data.info.maxValue;
    this.uiOption.minValue = this.data.info.minValue;
    this.uiOption = this.setGridData();

    return this.chartOption;
  }

  protected override convertSeries(): BaseOption {
    const minValue = this.data.info.minValue;
    const maxValue = this.data.info.maxValue;

    let cols: any = this.fieldInfo.cols.map((name) => {
      return { name };
    });
    let rows: any = this.fieldInfo.rows.map((name) => {
      return { name };
    });
    let aggregations: any;
    if (this.pivot && this.pivot.aggregations && this.pivot.aggregations.length > 0) {
      aggregations = this.pivot.aggregations.map((pivot) => {
        const name = _.isUndefined(pivot.alias) ? pivot.name : pivot.alias;
        if (pivot.field && pivot.field.logicalType) {
          return {
            name,
            digits: 2,
            type: pivot.field.logicalType,
          };
        } else {
          return { name, digits: 2 };
        }
      });
    } else {
      aggregations = this.fieldInfo.aggs.map((name) => {
        return { name, digits: 2 };
      });
    }

    this.originData = [];

    if ((<UIGridChart>this.uiOption).dataType == GridViewType.MASTER) {
      let originAggregations: any = this.fieldOriginInfo.aggs.map((name) => {
        return { name, digits: 2 };
      });

      const columns = this.data.columns;
      const removeAggregationType = function (field) {
        const regExp = /\((.*)\)/gi;
        const match = regExp.exec(field.name);
        if (match != null && match.length > 1) {
          field.name = match[1];
        } else field.name;
      };

      for (let i = 0; i < originAggregations.length; i++) {
        removeAggregationType(originAggregations[i]);
      }
      originAggregations = cols.concat(rows, originAggregations);

      originAggregations = _.uniqBy(originAggregations, 'name');

      originAggregations = originAggregations.filter((f) => f.name !== 'count');

      const newData = [];
      for (let i = 0; i < columns[0].value.length; i++) {
        for (let j = 0; j < originAggregations.length; j++) {
          const fieldPivot = this.pivot.aggregations.find((f) => f.name === originAggregations[j].name);
          const key = fieldPivot && fieldPivot.pivotAlias ? fieldPivot.pivotAlias : originAggregations[j].name;

          const json = {};
          json['&nbsp;'] = i + 1;
          json['COLUMNS'] = key;
          json['VALUE'] = columns[j].value[i];

          newData.push(json);
        }
      }

      cols = [{ name: 'COLUMNS' }];
      rows = [{ name: '&nbsp;' }];
      originAggregations = [{ name: 'VALUE' }];

      this.pivot.aggregations.forEach((pivot) => {
        if (pivot.field && pivot.field.logicalType) {
          originAggregations[0].type || (originAggregations[0].type = {});
          originAggregations[0].type[pivot.name] = pivot.field.logicalType;
        }
      });
      aggregations = originAggregations;

      this.originData = newData;
    }

    this.gridModel = {
      checkboxData: true,
      xProperties: cols,
      yProperties: rows,
      zProperties: aggregations,
      axisSelectMode: 'ONESIDE',
      onAxisXClick: !this.isPage ? this.addGridSelectEventListener : null,
      onAxisYClick: !this.isPage ? this.addGridSelectEventListener : null,
      onBodyCellClick:
        !this.isPage && this.pivot.columns.length > 1 && this.pivot.rows.length > 1
          ? this.addGridSelectEventListener
          : null,
      cellWidth: 120,
      cellHeight: 30,
      showAxisZ: false,
      customParams: this.params,
      gridSelectInfo: this.chartSelectInfo,
      shelve: this.pivot,
      min: minValue,
      max: maxValue,
      header: {
        font: {},
        align: {},
      },
      body: {
        font: {},
        color: {
          stepColors: [],
          stepTextColors: [],
        },
        align: {},
        showAxisZ: false,
      },
    };

    if (this.pivot.columns.length == 0 && this.pivot.rows.length > 1) {
      this.gridModel.checkboxData = true;
    } else {
      this.gridModel.checkboxData = false;
    }

    if (this.viewMode === true) {
      delete this.gridModel.onAxisXClick;
      delete this.gridModel.onAxisYClick;
      delete this.gridModel.onBodyCellClick;
    }

    if (this.uiOption && this.uiOption.color) {
      this.gridModel.useSelectStyle = this.uiOption && _.eq((<UIGridChart>this.uiOption).dataType, GridViewType.PIVOT);
      this.gridModel.leftAxisWidth =
        this.uiOption && _.eq((<UIGridChart>this.uiOption).dataType, GridViewType.PIVOT) ? 120 : 65;
      const schema = (<UIChartColorByCell>this.uiOption.color).schema;
      this.gridModel.showColorStep = !_.isEmpty(schema);

      const cellColor = <UIChartColorByCell>this.uiOption.color;

      if (_.eq((<UIGridChart>this.uiOption).dataType, GridViewType.MASTER)) this.gridModel.showDataColumn = false;

      this.gridModel.body.color.showColorStep = true;

      this.gridModel.body.color.colorTarget = cellColor.colorTarget;

      this.setRangeColor(cellColor, this.gridModel);

      if (
        (<UIGridChart>this.uiOption).contentStyle.fontColor &&
        '' !== (<UIGridChart>this.uiOption).contentStyle.fontColor
      ) {
        if (this.uiOption.color['visualGradations'] && this.uiOption.color['visualGradations'].length > 0) {
          this.gridModel.body.color.stepTextColors = this.uiOption.color['visualGradations'].map(() => {
            return (<UIGridChart>this.uiOption).contentStyle.fontColor;
          });
        } else {
          this.gridModel.body.color.stepTextColors = [(<UIGridChart>this.uiOption).contentStyle.fontColor];
        }
      }

      this.gridModel.body.showAxisZ = (<UIGridChart>this.uiOption).contentStyle
        ? (<UIGridChart>this.uiOption).contentStyle.showHeader
          ? (<UIGridChart>this.uiOption).contentStyle.showHeader
          : false
        : false;
      this.gridModel.dataColumnMode = _.eq((<UIGridChart>this.uiOption).measureLayout, UIOrient.HORIZONTAL)
        ? this.pivotGrid.DATA_COL_MODE.TOP
        : this.pivotGrid.DATA_COL_MODE.LEFT;

      if (UIPosition.AUTO == (<UIGridChart>this.uiOption).headerStyle.hAlign) {
        this.gridModel.header.align.hAlign = UIPosition.LEFT.toString().toLowerCase();
      } else {
        this.gridModel.header.align.hAlign = (<UIGridChart>this.uiOption).headerStyle.hAlign.toString().toLowerCase();
      }

      this.gridModel.body.align.hAlign = (<UIGridChart>this.uiOption).contentStyle.hAlign.toString().toLowerCase();

      this.gridModel.header.align.vAlign = (<UIGridChart>this.uiOption).headerStyle.vAlign.toString().toLowerCase();
      this.gridModel.body.align.vAlign = (<UIGridChart>this.uiOption).contentStyle.vAlign.toString().toLowerCase();

      this.gridModel.header.font.size = this.setFontSize((<UIGridChart>this.uiOption).headerStyle.fontSize);
      this.gridModel.body.font.size = this.setFontSize((<UIGridChart>this.uiOption).contentStyle.fontSize);

      this.gridModel.header.font.styles = (<UIGridChart>this.uiOption).headerStyle.fontStyles;
      this.gridModel.body.font.styles = (<UIGridChart>this.uiOption).contentStyle.fontStyles;

      this.gridModel.header.showHeader = (<UIGridChart>this.uiOption).headerStyle.showHeader;

      this.gridModel.remark = (<UIGridChart>this.uiOption).annotation;

      this.gridModel.header.font.color = (<UIGridChart>this.uiOption).headerStyle.fontColor;
      this.gridModel.header.backgroundColor = (<UIGridChart>this.uiOption).headerStyle.backgroundColor;

      if ((<UIGridChart>this.uiOption).totalValueStyle) {
        const totalValueStyle = (<UIGridChart>this.uiOption).totalValueStyle;

        this.gridModel.totalValueStyle = (<UIGridChart>this.uiOption).totalValueStyle;
        this.gridModel.totalValueStyle.font = {};
        this.gridModel.totalValueStyle.font.size = this.setFontSize(totalValueStyle.fontSize);
        this.gridModel.totalValueStyle.font.color = totalValueStyle.fontColor;
        this.gridModel.totalValueStyle.font.styles = totalValueStyle.fontStyles;

        this.gridModel.totalValueStyle.align = {};
        this.gridModel.totalValueStyle.align.hAlign = totalValueStyle.hAlign;
        this.gridModel.totalValueStyle.align.vAlign = totalValueStyle.vAlign;
      }

      if ((<UIGridChart>this.uiOption).showCalculatedColumnStyle) {
        const showCalculatedColumnStyle = (<UIGridChart>this.uiOption).showCalculatedColumnStyle;

        this.gridModel.showCalculatedColumnStyle = (<UIGridChart>this.uiOption).showCalculatedColumnStyle;
        this.gridModel.showCalculatedColumnStyle.font = {};
        this.gridModel.showCalculatedColumnStyle.font.size = this.setFontSize(showCalculatedColumnStyle.fontSize);
        this.gridModel.showCalculatedColumnStyle.font.color = showCalculatedColumnStyle.fontColor;
        this.gridModel.showCalculatedColumnStyle.font.styles = showCalculatedColumnStyle.fontStyles;

        this.gridModel.showCalculatedColumnStyle.align = {};
        this.gridModel.showCalculatedColumnStyle.align.hAlign = showCalculatedColumnStyle.hAlign;
        this.gridModel.showCalculatedColumnStyle.align.vAlign = showCalculatedColumnStyle.vAlign;
      }

      this.gridModel.measureFormat = this.uiOption.measureValueFormat;
      if (!this.uiOption.measureValueFormat) {
        this.gridModel.format = this.uiOption.valueFormat;
      }
    }

    return this.chartOption;
  }

  protected override apply(initFl = true): void {
    const data = (<UIGridChart>this.uiOption).dataType == GridViewType.MASTER ? this.originData : this.data;

    if (this.userCustomFunction && '' !== this.userCustomFunction && -1 < this.userCustomFunction.indexOf('main')) {
      const strScript = '(' + this.userCustomFunction + ')';

      try {
        // eslint-disable-next-line no-eval
        this.gridModel = eval(strScript)({ name: 'InitWidgetEvent', data: this.gridModel });
      } catch (e) {
        console.error(e);
      }
    }
    this.pivotGrid.initialize(data, this.gridModel);
  }

  protected override drawFinish(): void {
    this.drawFinished.emit();
  }

  private setRangeColor(cellColor: UIChartColorByCell, gridModel: any) {
    let rangeList = [];

    if (cellColor.ranges && cellColor.ranges.length > 0) {
      if (this.uiOption.color['customMode'] && ColorCustomMode.GRADIENT == this.uiOption.color['customMode']) {
        this.setGradationRangeColor(<UIChartColorGradationByCell>cellColor, gridModel);
      } else {
        rangeList = _.cloneDeep(cellColor.ranges);

        const rangeColors = [];

        rangeList.forEach((item) => {
          rangeColors.push({
            min: item.gt,
            max: item.lte,
            fixMin: item.fixMin,
            fixMax: item.fixMax,
            color: item.color,
          });
        });

        gridModel.body.color.stepRangeColors = rangeColors;
      }
    }
  }

  private setGradationRangeColor(cellColor: UIChartColorGradationByCell, gridModel: any) {
    const codes = cellColor.visualGradations.map((item) => {
      return item['color'];
    });

    if (CellColorTarget.BACKGROUND == (<UIChartColorByCell>this.uiOption.color).colorTarget) {
      gridModel.body.color.stepColors = codes;
    } else {
      gridModel.body.color.stepTextColors = codes;
    }
  }

  private setFontSize(fontSize: FontSize): number {
    let size: number;
    switch (fontSize) {
      case FontSize.NORMAL:
        size = 13;
        break;
      case FontSize.SMALL:
        size = 11;
        break;
      case FontSize.LARGE:
        size = 15;
        break;
    }

    return size;
  }

  private setGridData(): UIOption {
    const uiOption = <UIGridChart>this.uiOption;

    if (!uiOption.headerStyle) uiOption.headerStyle = {};
    if (!uiOption.headerStyle.fontSize) uiOption.headerStyle.fontSize = FontSize.NORMAL;
    if (_.isUndefined(uiOption.headerStyle.showHeader)) uiOption.headerStyle.showHeader = true;
    if (!uiOption.headerStyle.fontStyles) uiOption.headerStyle.fontStyles = [];
    if (!uiOption.headerStyle.hAlign) uiOption.headerStyle.hAlign = UIPosition.LEFT;
    if (!uiOption.headerStyle.vAlign) uiOption.headerStyle.vAlign = UIPosition.MIDDLE;
    if (!uiOption.headerStyle.backgroundColor) uiOption.headerStyle.backgroundColor = '#ffffff';
    if (!uiOption.headerStyle.fontColor) uiOption.headerStyle.fontColor = '#959595';

    if (!uiOption.contentStyle) uiOption.contentStyle = {};
    if (!uiOption.contentStyle.hAlign) uiOption.contentStyle.hAlign = UIPosition.LEFT;
    if (!uiOption.contentStyle.vAlign) uiOption.contentStyle.vAlign = UIPosition.MIDDLE;
    if (!uiOption.contentStyle.fontSize) uiOption.contentStyle.fontSize = FontSize.NORMAL;
    if (!uiOption.contentStyle.fontStyles) uiOption.contentStyle.fontStyles = [];
    if (!uiOption.contentStyle.fontColor) uiOption.contentStyle.fontColor = '';
    if (_.isUndefined(uiOption.headerStyle.showHeader)) uiOption.headerStyle.showHeader = false;

    if (ChartColorType.SINGLE == uiOption.color.type && !uiOption.color['code']) uiOption.color['code'] = '';

    return this.uiOption;
  }
}
