import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { KdBackendApiService } from '../../common';
import {
  AllMyWorkspaceApiParams,
  PublicWorkspaceApiParams,
  WorkspaceApiParams,
  WorkspaceDetailViewModel,
  WorkspaceListDetailViewModel,
} from '../models';

@Injectable()
export class WorkspaceApiService {
  constructor(private readonly kdBackendApiService: KdBackendApiService) {}

  getWorkspaceById(id: string, params: WorkspaceApiParams = {}): Observable<WorkspaceDetailViewModel> {
    return this.kdBackendApiService.get<WorkspaceDetailViewModel>(
      this.kdBackendApiService.buildPath<WorkspaceApiParams>(`workspaces/${id}`, {
        projection: 'forDetailView',
        ...params,
      }),
    );
  }

  getRoleGroups(params: WorkspaceApiParams = {}): Observable<any> {
    return this.kdBackendApiService.get<any>(
      this.kdBackendApiService.buildPath<WorkspaceApiParams>(`workspacerolegroups`, {
        projection: 'forDetailView',
        ...params,
      }),
    );
  }

  removeRoleFromGroup(roleGroup: string): Observable<any> {
    return this.kdBackendApiService.delete<any>(`workspaceroles?roleGroup=${roleGroup}`);
  }

  deleteWorkspaceMembers(id: string): Observable<any> {
    return this.kdBackendApiService.delete<any>(`workspaces/${id}/members`);
  }

  updateWorkspaceById(id: string, workspace: Partial<WorkspaceDetailViewModel>): Observable<WorkspaceDetailViewModel> {
    return this.kdBackendApiService.patch<WorkspaceDetailViewModel>(`workspaces/${id}`, workspace);
  }

  createWorkspace(workspace: Partial<WorkspaceDetailViewModel>): Observable<WorkspaceDetailViewModel> {
    return this.kdBackendApiService.post<WorkspaceDetailViewModel>(`workspaces`, workspace);
  }

  getUsers(params: WorkspaceApiParams = {}): Observable<any> {
    return this.kdBackendApiService.get<any>(
      this.kdBackendApiService.buildPath<WorkspaceApiParams>(`users`, {
        ...params,
      }),
    );
  }

  deleteWorkspaceRoleGroup(roleGroup: number): Observable<any> {
    return this.kdBackendApiService.delete(`workspacerolegroups/${roleGroup}`);
  }

  createWorkspaceRoleGroup(roleGroup: string): Observable<any> {
    return this.kdBackendApiService.post(`workspacerolegroups`, {
      name: roleGroup,
    });
  }

  createRole(role: any): Observable<any> {
    return this.kdBackendApiService.post(`workspaceroles`, role);
  }

  updateWorkspaceRoleGroup(workspaceId: string, roleGroup: string): Observable<any> {
    return this.kdBackendApiService.patch(`workspaces/${workspaceId}/rolegroup?name=${roleGroup}`, {});
  }

  setOwners(workspaceId: string, owners: string[]): Observable<any> {
    return this.kdBackendApiService.post(`workspaces/${workspaceId}/setOwners`, owners);
  }

  getMembers(workspaceId: string): Observable<any> {
    return this.kdBackendApiService.get(`workspaces/${workspaceId}/members`);
  }

  setWorkspaceMembers(workspaceId: string, members: string[]): Observable<any> {
    return this.kdBackendApiService.put(`workspaces/${workspaceId}/members`, members);
  }

  removeRole(roleId: number) {
    return this.kdBackendApiService.delete(`workspaceroles/${roleId}`);
  }

  getWorkspaceRoleGroups(params: WorkspaceApiParams = {}): Observable<any> {
    return this.kdBackendApiService.get<any>(
      this.kdBackendApiService.buildPath<WorkspaceApiParams>(`workspacerolegroups`, {
        projection: 'forDetailView',
        ...params,
      }),
    );
  }

  getWorkspaceRoles(params: WorkspaceApiParams = {}): Observable<any> {
    return this.kdBackendApiService.get<any>(
      this.kdBackendApiService.buildPath<WorkspaceApiParams>(`workspaceroles`, {
        projection: 'forDetailView',
        ...params,
      }),
    );
  }

  getWorkspaceForListWithPermission(id: string, params: WorkspaceApiParams = {}): Observable<WorkspaceDetailViewModel> {
    return this.kdBackendApiService.get<WorkspaceDetailViewModel>(
      this.kdBackendApiService.buildPath<WorkspaceApiParams>(`workspaces/${id}`, {
        projection: 'forListWithPermissions',
        ...params,
      }),
    );
  }

  /**
   * @deprecated use getAllWorkspaces instead
   */
  getPublicWorkspaces(params: PublicWorkspaceApiParams = {}): Observable<WorkspaceListDetailViewModel> {
    return this.kdBackendApiService.get<WorkspaceListDetailViewModel>(
      this.kdBackendApiService.buildPath<PublicWorkspaceApiParams>('workspaces/my/public', {
        projection: 'forListView',
        ...params,
      }),
    );
  }

  getAllWorkspaces(params: AllMyWorkspaceApiParams = {}): Observable<WorkspaceListDetailViewModel> {
    return this.kdBackendApiService.get<WorkspaceListDetailViewModel>(
      this.kdBackendApiService.buildPath<PublicWorkspaceApiParams>('workspaces/my/all', {
        projection: 'forListView',
        ...params,
      }),
    );
  }

  updateWorkspace(id: string, workspace: Partial<WorkspaceDetailViewModel>): Observable<WorkspaceDetailViewModel> {
    const url = this.kdBackendApiService.buildPath(`workspaces/${id}`, {
      projection: 'forDetailView',
    });

    return this.kdBackendApiService.patch<WorkspaceDetailViewModel>(url, workspace);
  }

  addToFavorite(id: string): Observable<void> {
    const url = this.kdBackendApiService.buildPath(`workspaces/${id}/favorite`);

    return this.kdBackendApiService.post(url, id);
  }

  deleteFromFavorite(id: string): Observable<void> {
    const url = this.kdBackendApiService.buildPath(`workspaces/${id}/favorite`);

    return this.kdBackendApiService.delete(url);
  }
}
