<div class="ddp-layout-popuptype">
  <em class="ddp-bg-popup"></em>
  <div
    *ngIf="0 < essentialFilters.length"
    class="ddp-ui-popup ddp-ui-popup-join"
  >
    <div class="ddp-ui-popup-title">
      <span class="ddp-txt-title-name">
        {{ "msg.board.ui.essential.title" | translate }}
        <span class="ddp-txt-det">{{
          "msg.board.ui.essential.desc" | translate
        }}</span>
      </span>
      <div class="ddp-ui-pop-buttons">
        <a href="javascript:" class="ddp-btn-pop" (click)="closeEvent.emit()">{{
          "msg.comm.btn.cancl" | translate
        }}</a>
        <a
          href="javascript:"
          class="ddp-btn-pop ddp-bg-black"
          (click)="ingest()"
        >
          {{ "msg.comm.btn.done" | translate }}
        </a>
      </div>
    </div>

    <div class="ddp-ui-eessential" style="text-align: initial">
      <div class="ddp-data-filter">
        {{ "msg.board.ui.essential" | translate }} (
        {{ essentialFilters.length }} )
      </div>

      <div class="ddp-type-setup" *ngFor="let filter of essentialFilters">
        <div class="ddp-ui-top">
          <div class="ddp-ui-tags">
            <span
              *ngIf="'essential' === filter.ui.importanceType"
              class="ddp-tag-type ddp-type"
            >
              <div class="ddp-wrap-datalock">
                {{ filter.ui.filteringSeq }}
                <em class="ddp-icon-lock-s2"></em>
              </div>
              {{ "msg.board.ui.essential" | translate }}
            </span>
          </div>
          <div class="ddp-tag-name">
            <span
              [class.ddp-measure]="'bound' === filter.type"
              class="ddp-icon-box"
            >
              <ng-container *ngIf="'bound' !== filter.type">
                <selfai-bi-shared-field-icon
                  *ngIf="filter.type !== 'bound'"
                  style="top: -3px"
                  [view]="'DIMENSION'"
                  [type]="getIconClass(filter, 'bound' !== filter.type).type"
                  [logicalType]="
                    getIconClass(filter, 'bound' !== filter.type).logicalType
                  "
                ></selfai-bi-shared-field-icon>
              </ng-container>
              <ng-container *ngIf="'bound' !== filter.type">
                <selfai-bi-shared-field-icon
                  *ngIf="filter.type !== 'bound'"
                  style="top: -3px"
                  [view]="'MEASURE'"
                  [type]="getIconClass(filter, 'bound' !== filter.type).type"
                  [logicalType]="
                    getIconClass(filter, 'bound' !== filter.type).logicalType
                  "
                ></selfai-bi-shared-field-icon>
              </ng-container>
            </span>
            {{ filter.field }}
          </div>
        </div>

        <div class="ddp-setup-contents">
          <div class="ddp-filter1">
            <app-config-filter-time
              *ngIf="filterUtil.isTimeFilter(filter)"
              (invoke)="startComponent($event, filter)"
            ></app-config-filter-time>
            <app-config-filter-inclusion
              *ngIf="filter.type === 'include'"
              (invoke)="startComponent($event, filter)"
            ></app-config-filter-inclusion>
            <app-config-filter-bound
              *ngIf="filter.type === 'bound'"
              (invoke)="startComponent($event, filter)"
            ></app-config-filter-bound>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="isShowProgress" class="ddp-wrap-loading" style="z-index: 140">
  <div *ngIf="1 === ingestionStatus.step" class="ddp-box-loading ddp-pop">
    <span class="ddp-data-loading">
      {{ "msg.board.filter.ui.waiting" | translate }}
    </span>

    <div class="ddp-type-progressbar">
      <em class="ddp-data-progressbar"></em>
    </div>
  </div>

  <div *ngIf="2 === ingestionStatus.step" class="ddp-box-loading ddp-pop">
    <span
      *ngIf="
        'PROGRESS_GET_DATA_FROM_LINK_DATASOURCE' === ingestionStatus.message
      "
      class="ddp-data-loading"
    >
      {{ "msg.board.filter.ui.ingestion-step-1" | translate }}
    </span>
    <span
      *ngIf="'PROGRESS_LOAD_TEMP_DATASOURCE' === ingestionStatus.message"
      class="ddp-data-loading"
    >
      {{ "msg.board.filter.ui.ingestion-step-2" | translate }}
    </span>

    <div class="ddp-type-progressbar2">
      <em
        class="ddp-data-progressbar"
        [style.right]="100 - ingestionStatus.progress + '%'"
      ></em>
    </div>
  </div>

  <div
    *ngIf="10 == ingestionStatus.step"
    class="ddp-box-loading ddp-box-s ddp-pop"
  >
    <div class="ddp-box-in">
      <a (click)="closeProgress()" href="javascript:" class="ddp-btn-close"></a>
      <em class="ddp-icon-success"></em>
      <span class="ddp-txt-success">{{
        "msg.board.filter.ui.success" | translate
      }}</span>
    </div>
  </div>

  <div
    *ngIf="-1 === ingestionStatus.step"
    class="ddp-box-loading ddp-box-s ddp-pop"
  >
    <div class="ddp-box-in">
      <a (click)="closeProgress()" href="javascript:" class="ddp-btn-close"></a>
      <a (click)="ingest()" href="javascript:" class="ddp-btn-again"></a>
      <span class="ddp-txt-fail">{{
        "msg.board.filter.ui.fail-retry" | translate
      }}</span>
    </div>
  </div>
</div>
