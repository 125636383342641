import { WidgetShowType } from './widget-show-type';

export interface BoardWidgetOptions {
  showTitle: WidgetShowType;
  showLegend: WidgetShowType;
  showMinimap: WidgetShowType;
}

export function createBoardWidgetOptions(
  boardWidgetOptionsParams: Partial<BoardWidgetOptions> = {},
): BoardWidgetOptions {
  return {
    showTitle: WidgetShowType.BY_WIDGET,
    showLegend: WidgetShowType.BY_WIDGET,
    showMinimap: WidgetShowType.BY_WIDGET,
    ...boardWidgetOptionsParams,
  };
}
