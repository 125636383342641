import { Injectable, inject } from '@angular/core';
import { UrlPageParamsService } from '@selfai-platform/shared';
import { Observable, combineLatest, take } from 'rxjs';
import { PaginationData, StoreData } from '../interfaces';
import { DataListViewItem } from '../models/data-view-list-item.model';
import { DataListViewBaseService } from './data-list-view-base.service';
import { DataListViewLazyLoadService } from './data-list-view-lazy-load.service';
import { SelectedItemsService } from './selected-items.service';

// Service for lazy loading data for data list view
@Injectable()
export abstract class DataListViewLazyComponentService<T extends DataListViewItem>
  extends DataListViewBaseService<T>
  implements StoreData<T>, PaginationData
{
  private readonly dataListViewLazyLoadService: DataListViewLazyLoadService = inject(DataListViewLazyLoadService);

  constructor(urlPageParamsService: UrlPageParamsService, selectedItemsService: SelectedItemsService<T>) {
    super(urlPageParamsService, selectedItemsService);
  }

  onPageChange({ first, rows }: { first: number; rows: number }): void {
    this.pageParams$.pipe(take(1)).subscribe(({ sortField, sortOrder }) => {
      this.dataListViewLazyLoadService.onLazyLoad({
        first,
        rows,
        sortField: sortField,
        sortOrder: sortOrder === 'asc' ? 1 : -1,
      });
    });
  }

  protected getContextUpdate(): Observable<unknown> {
    return combineLatest({
      pageParams: this.pageParams$,
      reloadTrigger: this.reloadTrigger$,
    });
  }
}
