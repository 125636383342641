import * as _ from 'lodash';

import { AxisType, BaseOption, Orient, UIOption, UIOrient } from '@selfai-platform/bi-domain';
import {
  DataZoomComponentOption,
  SliderDataZoomComponentOption,
  ToolboxComponentOption,
  XAXisComponentOption,
  YAXisComponentOption,
} from 'echarts';
import { GridComponentOption, LegendComponentOption, VisualMapComponentOption } from 'echarts/components';
import { OptionGenerator } from '../utils/option-generator';

export class ToolOptionConverter {
  public static convertDataZoom(option: BaseOption, uiOption: UIOption): BaseOption {
    option = this.convertDataZoomShow(option, uiOption);

    option = this.convertDataZoomAxis(option, uiOption);

    option = this.convertDataZoomRotate(option, uiOption);

    return option;
  }

  public static convertDataZoomShow(option: BaseOption, uiOption: UIOption): BaseOption {
    _.each(uiOption.chartZooms, (zoom) => {
      _.each(option.dataZoom, (item: DataZoomComponentOption) => {
        (item as any).show = zoom.auto;
      });
    });

    return option;
  }

  public static convertDataZoomAxis(option: BaseOption, uiOption: UIOption): BaseOption {
    if (_.isUndefined(option.toolbox)) return option;
    if (_.eq(option.xAxis[0].type, AxisType.CATEGORY)) {
      (option.toolbox as ToolboxComponentOption).feature.dataZoom.yAxisIndex = 'none';
      delete (option.toolbox as ToolboxComponentOption).feature.dataZoom.xAxisIndex;
    } else if (_.eq(option.yAxis[0].type, AxisType.CATEGORY)) {
      (option.toolbox as ToolboxComponentOption).feature.dataZoom.xAxisIndex = 'none';
      delete (option.toolbox as ToolboxComponentOption).feature.dataZoom.yAxisIndex;
    }

    return option;
  }

  public static convertDataZoomRotate(option: BaseOption, uiOption: UIOption): BaseOption {
    if (!uiOption || _.isUndefined(uiOption['align'])) return option;

    const orient: Orient = uiOption['align'];

    if (
      _.eq(orient, Orient.VERTICAL) &&
      option.yAxis[0].type == AxisType.VALUE &&
      option.xAxis[0].type == AxisType.CATEGORY
    )
      return option;

    if (!option.dataZoom) return option;

    (option.dataZoom as DataZoomComponentOption[]).map((obj: SliderDataZoomComponentOption) => {
      if (_.eq(orient, UIOrient.VERTICAL)) {
        obj.orient = 'horizontal';

        if (obj.type === 'slider') {
          obj.bottom = 0;
          delete obj.left;
        }
      } else {
        obj.orient = 'vertical';
        if (obj.type === 'slider') {
          obj.left = 0;
          delete obj.bottom;
        }
      }
    });

    return option;
  }

  public static convertGrid(option: BaseOption, uiOption: UIOption): BaseOption {
    option = this.convertGridAxisRotate(option, uiOption);

    option = this.convertGridByLabelShow(option, uiOption);

    return option;
  }

  public static convertGridByLabelShow(option: BaseOption, uiOption: UIOption): BaseOption {
    if (!uiOption.chartZooms || uiOption.chartZooms.length == 0) return option;

    const orient: UIOrient = _.gt(uiOption.chartZooms.length, 1)
      ? UIOrient.BOTH
      : _.eq(uiOption.chartZooms[0].orient, UIOrient.HORIZONTAL)
      ? UIOrient.VERTICAL
      : UIOrient.HORIZONTAL;

    (option.grid as GridComponentOption[]).map((obj) => {
      switch (orient) {
        case UIOrient.BOTH:
          if (false == uiOption.xAxis.showLabel && true == uiOption.xAxis.showName) {
            obj.bottom = <any>obj.bottom + 15;
          }

          if (false == uiOption.yAxis.showLabel && true == uiOption.yAxis.showName) {
            obj.left = <any>obj.left + 15;
          }

          break;
        case UIOrient.VERTICAL:
          if (false == uiOption.xAxis.showLabel && true == uiOption.xAxis.showName) {
            obj.bottom = <any>obj.bottom + 15;
          }

          if (false == uiOption.yAxis.showLabel) {
            obj.left = <any>obj.left + 20;
          }
          break;
        case UIOrient.HORIZONTAL:
          if (false == uiOption.xAxis.showLabel) {
            obj.bottom = <any>obj.bottom + 20;
          }

          if (false == uiOption.yAxis.showLabel && true == uiOption.yAxis.showName) {
            obj.left = <any>obj.left + 15;
          }
          break;
      }
    });

    return option;
  }

  public static convertGridAxisRotate(option: BaseOption, uiOption: UIOption): BaseOption {
    if (!uiOption.chartZooms || uiOption.chartZooms.length == 0) return option;

    const orient: UIOrient = _.gt(uiOption.chartZooms.length, 1)
      ? UIOrient.BOTH
      : _.eq(uiOption.chartZooms[0].orient, UIOrient.HORIZONTAL)
      ? UIOrient.VERTICAL
      : UIOrient.HORIZONTAL;

    if (_.isUndefined(orient)) return option;

    const withLegend: boolean =
      (!_.isUndefined(option.legend) && (option.legend as LegendComponentOption).show) ||
      (!_.isUndefined(option.visualMap) && (option.visualMap as VisualMapComponentOption).show);

    const withDataZooom: boolean = !_.isUndefined(option.dataZoom) && option.dataZoom[0].show;
    const withSubAxis: boolean =
      (option.xAxis as XAXisComponentOption[]).length > 1 || (option.yAxis as YAXisComponentOption[]).length > 1;

    if (Array.isArray(option.grid)) {
      option.grid.map((obj, idx) => {
        option.grid[idx] = _.eq(orient, UIOrient.BOTH)
          ? OptionGenerator.Grid.bothMode(10, 10, 10, 20, withLegend, withDataZooom)
          : _.eq(orient, UIOrient.VERTICAL)
          ? OptionGenerator.Grid.verticalMode(20, 10, 0, 10, withLegend, withDataZooom, withSubAxis)
          : OptionGenerator.Grid.horizontalMode(10, 0, 0, 20, withLegend, withDataZooom);
      });
    }

    return option;
  }
}
