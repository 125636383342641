<ng-container *ngIf="updateAggregatorRoute$ | async"></ng-container>

<div class="card">
  <ng-container *ngIf="loading$ | async; else resultTemplate">
    <p-skeleton width="300px" height="2.1rem" styleClass="mb-3"></p-skeleton>
    <p-skeleton width="90px" height="20px" styleClass="mb-3"></p-skeleton>
    <p-skeleton width="100%" height="20rem" styleClass="mb-3"></p-skeleton>
    <p-skeleton width="100%" height="20rem" styleClass="mb-3"></p-skeleton>
    <p-skeleton width="100%" height="20rem" styleClass="mb-3"></p-skeleton>
  </ng-container>

  <ng-template #resultTemplate>
    <ng-container *ngIf="routeForm; else routeNotFound">
      <form [formGroup]="routeForm">
        <input
          type="text"
          pInputText
          class="p-inputtext-sm mb-3 w-full text-2xl"
          formControlName="name"
          pAutoFocus
          [autofocus]="true"
          (keydown.escape)="stopEditingRouteName()"
          (keydown.enter)="stopEditingRouteName()"
          (blur)="stopEditingRouteName()"
          placeholder="Type the new route name"
          *ngIf="nameIsEditing; else nameText"
        />
        <ng-template #nameText>
          <h1
            pTooltip="Click to edit the route name"
            tooltipPosition="left"
            class="flex text-2xl mb-3"
            (click)="startEditingRouteName()"
          >
            <span class="trim-text">
              {{ routeForm.controls.name.value }}
            </span>
            <button
              type="button"
              pButton
              class="p-button-text p-button-link p-0"
              appearance="icon"
              icon="pi pi-pencil"
              (click)="startEditingRouteName()"
            ></button>
          </h1>
        </ng-template>
        <div class="flex align-items-center justify-content-between py-3">
          <div class="flex align-items-center">
            <div class="field-checkbox">
              <p-checkbox name="input" [binary]="true" formControlName="input" inputId="input"></p-checkbox>
              <label for="input">Input route</label>
            </div>
            <div class="field-checkbox ml-3">
              <p-checkbox name="input" [binary]="true" formControlName="isActive" inputId="isActive"></p-checkbox>
              <label for="isActive">Route is active</label>
            </div>
          </div>
          <div class="flex align-items-center gap-3">
            <i
              pTooltip="The route is not saved yet"
              tooltipPosition="left"
              class="pi pi-exclamation-circle text-orange-700"
              *ngIf="(isSaved$ | async) !== true"
            ></i>
            <button label="Save" pButton type="button" (click)="save()" [disabled]="routeForm.disabled"></button>
            <button
              type="button"
              class="p-button-secondary p-button-outlined"
              (click)="exportToFile()"
              (keydown.enter)="exportToFile()"
              pRipple
              pButton
              icon="pi pi-download"
              label="Export"
            ></button>
            <button
              type="button"
              class="p-button-secondary p-button-outlined"
              (click)="openImportForm()"
              (keydown.enter)="openImportForm()"
              [disabled]="saving$ | async"
              pRipple
              pButton
              icon="pi pi-upload"
              label="Import"
            ></button>
            <button
              type="button"
              class="p-button-secondary p-button-outlined"
              (click)="openHazelCast()"
              (keydown.enter)="openHazelCast()"
              pRipple
              pButton
              label="HZ"
            ></button>
          </div>
        </div>
      </form>

      <ng-container *ngFor="let formGroup of controls; index as i">
        <p>
          <selfai-platform-aggregator-camel-step [form]="formGroup" [index]="i"></selfai-platform-aggregator-camel-step>
        </p>
      </ng-container>

      <p class="mt-3">
        <p-splitButton
          #splitButton
          *ngIf="stepsMenu$ | async as stepsMenu"
          (onClick)="splitButton.onDropdownButtonClick($event)"
          appendTo="body"
          label="Add new block"
          icon="pi pi-plus"
          [model]="stepsMenu"
        ></p-splitButton>
      </p>
      <p>
        <button
          label="Save"
          pButton
          type="button"
          class="mr-3"
          (click)="save()"
          [disabled]="routeForm.disabled"
        ></button>
        <button label="Cancel" pButton type="button" class="mr-3 p-button-secondary" routerLink="/aggregators"></button>
      </p>
    </ng-container>
    <ng-template #routeNotFound>
      <p>Route not found</p>
    </ng-template>
  </ng-template>
</div>
