<svg viewBox="0 0 24 24" *ngIf="name === 'HIVE' && !isBlackAndWhite" [attr.class]="getCss()">
  <g>
    <g transform="translate(-.53268 66.8)">
      <path
        class="st0"
        d="M14.2-63.1c-0.2-0.6-7.3-3.3-7.4-3c-1.3,0.3-1.8,1.8-2.7,2.8l-2-0.1c-0.9,1.1-1.3,2.3-1,3.5
                                            c1.1,1.5,2.4,2.8,3.2,4.7c0.1,0.9,3.9,0.1,4.7-0.1c-1.1,0.8-2.1,2-1.7,5.6c0.4,1.7,0.6,3.6,4,5.5c0.4,0.3,1.4,0.5,1.9,0.7
                                            c0.5,0.3,1.2,0.4,3-0.2c1-0.4,2.2-0.7,3.2-1.2l-1.2,0.1c-1.7,0-3,0.2-3.4-0.3l-1.6-2.7l0.9-1.8c1.3,0.1,1.7,1.1,2.8,1.5l1.3-1
                                            c3.8,2.2,5.3-1.4,5.7-4.3c0-1.2-2.5,0.4-2.4-0.2c0.1-1.2-1-2.9-1.6-4.1l0.8-2.9c0.3-0.3-1.6-3.6-3.2-4
                                            C16.3-64.9,14.2-63.1,14.2-63.1L14.2-63.1z"
      ></path>
    </g>
    <g>
      <g transform="translate(-.53268 66.8)">
        <path
          class="st1"
          d="M20.5-56.9c0,0.3,0,0.7,0,0.9s-0.1,0.2-0.3,0.2l0.7,0.3c0.1,0.2,0.3,0.5,0.4,0.7c0.1,0.6,0,0.6-0.1,0.7
                                                c-0.3,0-0.5,0-0.8,0c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.3-0.2,0.5c0.2,0.1,0.6,0.2,0.9,0.4l0.3-0.7
                                                C21.5-54.9,21.1-55.9,20.5-56.9L20.5-56.9z"
        ></path>
        <path
          class="st1"
          d="M21.8-53.2c0.4,0.2,1,0.1,1.8-0.3c0.1-0.1,0.2,0,0,0.1C22.6-52.4,22.1-52.9,21.8-53.2L21.8-53.2z"
        ></path>
        <path
          class="st1"
          d="M23.7-53.6c0.1,0.2,0,0.4,0,0.6c-0.1,1-0.4,1.8-1,2.6c-2,3.2-4,1.2-5.6-0.1l-0.7,1.7c0,0.1-0.2,0.3,0.9,0.7
                                                l1.1-0.7C22.3-45.8,25.1-53.1,23.7-53.6L23.7-53.6z"
        ></path>
        <path class="st1" d="M17.5-50.7c-0.3,0-1.1,0.3-1.1,0.5l0.4-0.6L17.5-50.7z"></path>
        <path
          class="st1"
          d="M17-54.1c0,0,0.3,0.1,0.3,0c0,0,0,0.3,0.1,0.5L17-52.9c0.6-0.6,1.4-0.6,2.3-0.7l-0.4-0.2
                                                c0.1-0.2,0-0.4,0-0.6L17-54.1z"
        ></path>
        <path
          class="st1"
          d="M18.4-56c-0.6,0.2-1.2,0.5-1.6,1.1c0.3-1.1,0.4-1,0.4-1.1C17.7-56.1,18-56,18.4-56L18.4-56z"
        ></path>
      </g>
      <g transform="translate(-.53268 66.8)">
        <path
          class="st2"
          d="M15.2-43.7c-0.7,0.8-2.3-0.1-3.7-0.5c-3.4-2.2-4.1-4.2-4.1-6.2c-0.2-2.9,0.7-4.5,1.8-4.9
                                                c-0.7,1.4-1.1,4-0.7,5.9c0.3,1.1,0.3,2.8,2,3.8c0.9,0.5,0.6,0.9,1.2,1.3C12.4-43.9,14.1-43.4,15.2-43.7L15.2-43.7z"
        ></path>
        <path
          class="st2"
          d="M16.2-56.9c-1.2-1.7-2.9-2.4-4.9-2.5c0.4-0.1,0.8-0.2,1.2-0.4c0.1-0.1,0.1-0.3,0-0.5c-1.9-0.5-3-1.1-4.5-1.6
                                                l4,1C14.8-60.8,14.5-60.1,16.2-56.9L16.2-56.9z"
        ></path>
        <path
          class="st2"
          d="M17.6-57.7c-0.9-1.3-1.8-2.9-3-3.9c-2.9-1.3-5.7-2.3-9-3.1l1.1-1.4c3.5,0.8,6.9,2.2,9.8,4.4
                                                C16.9-60.4,17.3-59.2,17.6-57.7L17.6-57.7z"
        ></path>
        <path
          class="st2"
          d="M19.4-60c0,0-0.5-1.1-0.8-1.6c-0.6-0.6-0.9-1.8-2-2.4c0.4,0.1,0.6,0,1.4,0.7l1.2,2.3L19.4-60z"
        ></path>
        <path
          class="st2"
          d="M20-58.1c0.1-1,0.3-2.6,0.1-3.2c-0.7-1-1.4-1.9-2.1-2.8c-0.1-0.1-0.2-0.3-0.3-0.4c1,0.3,2.1,1,3.5,3.7
                                                L20-58.1z"
        ></path>
      </g>
      <g transform="translate(-.53268 66.8)">
        <path
          d="M15.8-57.9c-0.4-0.3-0.8-1.4-1.4-1.7c-0.6,0-0.8-0.2-1.5-0.1c0.3-0.1,0.5-0.3,0.8-0.4c0.2,0,0.4,0,0.6,0
                                                c0,0,0.1,0,0.1-0.1c-0.7-0.5-2.3-0.5-3.3-0.7c1.2,0,2.5,0,3.4,0.3C15.2-60,15.4-58.8,15.8-57.9L15.8-57.9z"
        ></path>
        <path
          d="M18-63.3c0.2,0,1.1,1.4,1.1,1.7c0.1,0.5,0.3,1.1,0.3,1.6c-0.1-0.5-0.3-1-0.5-1.5c-0.1-0.1-0.2-0.5-0.6-0.9
                                                C18.1-62.8,18-63.1,18-63.3L18-63.3z"
        ></path>
        <path d="M21-55.6h-0.4H21L21-55.6z"></path>
        <path d="M18.4-56c-0.4,0-0.8-0.1-1.2,0C17-55.8,17-55.6,17-55.4C17.5-55.9,17.6-55.9,18.4-56L18.4-56z"></path>
        <path
          d="M23.6-53.4c-0.2,0.2-0.2,0.3-0.9,0.5c-0.5,0.1-0.7-0.1-0.9-0.3c0.3,0.1,0.4,0.3,1.3,0.1L23.6-53.4z"
        ></path>
        <path
          d="M21.7-53.3c-0.2,0.4-0.3,0.8-0.5,1.2c-0.4,0.5-0.2,0.2-0.8,0.9c0.2-0.3,0.4-0.6,0.6-0.9c0.1-0.2,0.2-0.4,0.3-0.6
                                                c-0.2-0.1-0.5-0.1-0.5-0.1c-0.5,0.3-0.6,0.6-0.9,1c0.2-0.4,0.4-0.8,0.7-1.1c0,0,0.2-0.1,0.3-0.1c-0.2,0-0.5-0.1-0.6-0.1
                                                c-0.3,0.1-0.5,0.4-0.8,0.6c0.2-0.3,0.4-0.5,0.6-0.8c0,0,0.7,0,1,0.2v0.1l0.2,0.1l0.1-0.4L21.7-53.3z"
        ></path>
        <path
          d="M17.5-50.7c-0.1,0-0.8-0.1-0.8-0.1c-0.3,0.2-0.3,0.4-0.3,0.6C16.8-50.7,16.8-50.7,17.5-50.7L17.5-50.7z"
        ></path>
        <path
          d="M16.9-54.1c0,0,0.5-0.1,0.5,0c0,0.2,0.1,0.6,0.1,0.6l-0.3,0.2c0.6-0.3,1.3-0.3,2-0.3c0,0-0.6-0.2-0.5-0.2s0-0.6-0.1-0.6
                                                c0.1,0,0.2-0.1,0.3-0.1C18.1-54.7,17.7-54.6,16.9-54.1L16.9-54.1z"
        ></path>
        <path
          d="M21.6-53.7l-0.1,0.1c0-0.4-0.4-0.3-0.7-0.3L20.5-54c0.1,0.1,0.3,0.1,0.2,0.2c-0.1,0-0.1,0.2-0.2,0.4h-0.1
                                                c0.3,0.1,0.5,0.2,0.8,0.3h0.2l0.4-0.2L21.6-53.7z"
        ></path>
      </g>
      <g transform="translate(-.53268 66.8)">
        <path
          class="st3"
          d="M18-53.9c-0.1-0.1-0.4-0.1-0.4,0c-0.1,0.1,0,0.5,0.1,0.4s0.2-0.1,0.4-0.1C18.1-53.8,18.1-53.8,18-53.9
                                                L18-53.9z"
        ></path>
        <path
          class="st3"
          d="M21-53.3c0,0,0.1-0.2,0.1-0.3s-0.3-0.1-0.3-0.1c-0.1,0-0.1,0.3-0.1,0.3C20.6-53.3,20.9-53.3,21-53.3L21-53.3
                                                z"
        ></path>
      </g>
      <g transform="translate(-.53268 66.8)">
        <path d="M8.8-46.3H9c1.1,0.5,2.8,1.3,5.4,0.9l0.4,0.6c-1.1,0.5-2.3,0.4-3.5,0.5L8.8-46.3z"></path>
        <path d="M7.2-50.4l0.5,2.2c1.4,0.9,3.9,1.9,5.9,1.8l-0.5-1C9-48.3,8.6-49.4,7.2-50.4L7.2-50.4z"></path>
        <path
          d="M8.6-55.1c0.3,1.7,0.6,3.1,2.1,4.2c0.9,0.7,1.9,1.3,3,1.9c0,0-0.1,0.5-0.2,0.5c-3.3-0.4-5.8-3.1-6.1-4.4
                                                C7.7-53.9,8.1-54.5,8.6-55.1L8.6-55.1z"
        ></path>
        <path
          d="M10.2-56c0.4,1.9,1.5,3.9,2.6,5.7c0.4,0.5,0.5,0.7,1.1,1c0.8,0.3,1.4,0.2,2,0.2c-0.2-0.3-0.3-0.6-0.5-0.9
                                                c-1.3-1-0.7-2-0.4-2.6c-0.7-0.2-1.7-0.6-1.8-1.1c-0.2-1.7-0.1-2.2,0.1-3C12.2-56.6,11.2-56.4,10.2-56L10.2-56z"
        ></path>
        <path
          class="st4"
          d="M6.6-66.3c-0.3,0.2-0.6,0.5-1,0.9c-0.6,0.6-0.9,1.1-1.5,1.6c-0.1,0.1-0.4,0.3-0.8,0.3c-0.2,0-0.3,0-0.7,0
                                                c-0.3-0.2-0.6-0.1-0.9,0.3C1.4-62.9,1-62,0.9-61.5c-0.3,1.4,0.5,2.5,1.3,3.3s1.2,1.2,1.5,1.9c0.2,0.4,0.4,1,0.6,1.4
                                                c0.1,0.1,0.1,0.1,0.2,0.2c0.4,0.1,0.9,0.1,1.4,0.1c0.2,0,0.5,0,0.8,0c0.4-0.1,0.8-0.1,1.2-0.3c0.4-0.1,0.7-0.2,0.9-0.3
                                                c0,0.1-0.3,0.2-0.4,0.4c-1,1.4-1.2,2.6-1.1,4.5c0.1,0.9,0.3,1.6,0.6,2.4c0.1,0.4,0.4,1,0.7,1.3c0.8,1.2,2.7,2.7,5.2,3.2
                                                 c0.4,0.1,1,0,1.4-0.1c1.3-0.4,3.8-1.3,3.8-1.3s-2.3,0.2-3.3,0.1c-0.2,0-0.5-0.1-0.7-0.2l-0.1-0.2c0.1,0,0.4-0.1,0.8-0.1
                                                c-0.9-0.1-0.8-0.1-0.9-0.2c-0.1-0.2-0.2-0.6-0.4-0.9c0.4,0,1.2,0.1,1.5-0.2c0,0-0.5,0.1-0.9,0c-0.1,0-0.4-0.1-0.5-0.1
                                                c-0.2-0.1-0.4-0.1-0.4-0.1c-0.1-0.2-0.1-0.2-0.2-0.5c-0.1-0.3-0.1-0.6-0.1-1c0.3,0.4,0.6,0.6,1.1,0.8c0,0,0.6,0.3,1.1,0.1h0.1
                                                c0,0-0.3,0-0.4,0c-0.9-0.4-1.1-0.7-1.2-0.9l-0.4-0.6c0.1-0.2,0.2-0.2,0.3-0.3c0.5,0.1,0.7,0.1,1,0c0.2,0.4,0.3,0.8,0.7,1.1
                                                c1.5,0.4,1.8-0.1,2.2-0.6c1.1,0.8,2.8,1,4.1,0c1.5-1.8,2-4.6,1.9-4.8c-0.2-0.3-0.4-0.7-0.7-0.6c-0.8,0.2-1.1,0.6-1.8,0.5
                                                c0.1,0,0.2,0,0.2,0c0.1-0.7,0-1,0-1c-0.2-0.5-0.5-1.1-0.8-1.5c-0.1-0.1-0.2-0.7-0.5-1c-0.1-0.1-0.4-0.4-0.4-0.4v0.3
                                                c0,0,0.1,0,0.2,0.2c0.2,0.7,1,2.2,1,2.3c0.3,0.5,0,1.1,0.2,1.4c0,0,0.4,0,0.8,0c0.5-0.1,0.5-0.4,1-0.4c0.3,0,0.3,0.6,0.3,0.6
                                                c-0.1,0.7-0.3,1.4-0.6,2.1c-0.6,1.2-1.4,2.3-2.4,2.5c-1.2,0.2-1.9-0.3-2.5-0.7L18-48.9c-0.9,0.9-1.9,0.8-2.3-0.3
                                                c-0.2-0.4-0.5-0.7-0.7-1.1l-1.2,0.9c-0.1,0.2-0.2,0.5-0.4,0.9c-0.1,0.3-0.2,0.7-0.2,1.1c-0.2,0.3,0.6,1.4,1,2
                                                c0.1,0.2,0.4,0.5,0.4,0.6c0.1,0.2,0.3,0.4,0.3,0.5c0.8,1.1-1.1,0.8-1.4,0.8c-0.8-0.1-1.5-0.4-2.2-0.9l-0.1-0.1
                                                c-0.8-0.5-1.6-1.2-2.3-1.9c-0.4-0.4-0.8-1.4-1-2c-0.4-1.6-1-4.2,0.6-6.3c0-0.1,0.1-0.3,0.2-0.3c0.5-0.3,1-0.5,1.6-0.7l-0.1-0.4
                                                C9.9-56,9-55.7,8.7-55.5c-0.6,0.2-1.1,0.4-1.9,0.6c-0.3,0-0.5,0-0.7,0C5.6-55,4.6-55,4.6-55c-0.4-0.5-0.5-1.5-0.8-2l0,0l0,0
                                                c-0.2-0.3-0.4-0.5-0.7-0.7c-0.8-0.8-1.5-1.6-1.7-2.4c0-0.2-0.2-0.5-0.1-1.2l0,0l0,0c0.2-0.7,0.5-1.2,1.1-1.8c0.6,0,1.1,0,1.6,0.1
                                                c0.2,0,0.6,0.1,1,0.2c1.1,0.4,2.5,1.1,2.5,1.1c-1.1-0.6-2.3-1.4-3.1-1.5c-0.1,0-0.2-0.1-0.2-0.2c1.1-0.7,1.4-1.5,2.1-2.2
                                                c0.3-0.2,0.5-0.2,0.8-0.3c2.7,0.4,4.4,1.5,5.8,2.2c0.5,0.3,1,0.5,1.5,0.8c0.4,0.1,1.7,1.1,2,1.6c0.4,0.8,0.6,1.6,0.9,2.5
                                                c0.2,0.8,0.3,1.2,0.3,1.2s-0.2-0.7-0.1-0.8c0.2,0.1,0.5,0.2,0.7,0.2c0,0-0.7-0.4-0.8-0.7c-0.3-1-0.6-2.6-0.7-2.7
                                                c-0.2-0.3-1.1-1-1.7-1.3c-0.2-0.1-0.3-0.2-0.3-0.3c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.1,0.4-0.3,0.6-0.4c1.2-0.5,1.8,0.2,2,0.1
                                                c0,0-0.3-0.3-0.1-0.2c0.1,0.1,0.5,0.1,0.5,0.2c0.4,0.3,1.5,1.6,2.2,2.8c0.2,0.3,0.2,0.5,0.2,0.9c-0.1,0.4-0.1,0.6-0.2,0.8
                                                S20-58.3,20-58.1c-0.1,0.6,0.3,1.4,0.3,1.4c0-0.2,0-0.3,0-0.5v-0.3v-0.1c0-0.2,0.1-0.4,0.1-0.5c0.1-0.8,0.4-1.4,0.6-2.2
                                                c0.1-0.2,0.2-0.3,0.2-0.4c0-0.2-0.2-0.6-0.4-0.9c-0.2-0.3-0.4-0.7-0.6-1.1c-0.6-0.8-1.1-1.5-2-1.9c-0.3-0.1-1.3-0.2-1.6-0.2
                                                c-0.4,0.1-0.8,0.2-1.1,0.4c-0.5,0.3-0.8,0.7-1.2,1c-0.9-0.5-1.4-0.8-1.5-0.9c-0.5-0.3-1.2-0.6-1.9-1C10.6-65.4,8.5-66.1,6.6-66.3
                                                L6.6-66.3z M18.4-49.1c-0.6-0.4-1-0.9-1.4-1.4c-0.1,0.6-0.5,0.9-0.8,1.3c-0.1,0.1-0.1,0.2,0.2,0.6c0.1,0.1,0.4,0.1,0.5,0.1
                                                c-0.2-0.1-0.5-0.3-0.5-0.4c0.3,0.2,0.6,0.3,0.9,0.3c0.1,0,0.1-0.1,0.2-0.2c0.1-0.3,0.2-0.3,0.3-0.4l0.2,0.3L18.4-49.1z"
        ></path>
      </g>
    </g>
  </g>
</svg>

<svg *ngIf="name === 'HIVE' && isBlackAndWhite" [attr.class]="getCss()">
  <g id="Layer_3">
    <g transform="translate(-.53268 66.8)">
      <path
        class="st0"
        d="M14.2-63.1c-0.2-0.6-7.3-3.3-7.4-3c-1.3,0.3-1.8,1.8-2.7,2.8l-2-0.1c-0.9,1.1-1.3,2.3-1,3.5
                                                c1.1,1.5,2.4,2.8,3.2,4.7c0.1,0.9,3.9,0.1,4.7-0.1c-1.1,0.8-2.1,2-1.7,5.6c0.4,1.7,0.6,3.6,4,5.5c0.4,0.3,1.4,0.5,1.9,0.7
                                                c0.5,0.3,1.2,0.4,3-0.2c1-0.4,2.2-0.7,3.2-1.2l-1.2,0.1c-1.7,0-3,0.2-3.4-0.3l-1.6-2.7l0.9-1.8c1.3,0.1,1.7,1.1,2.8,1.5l1.3-1
                                                c3.8,2.2,5.3-1.4,5.7-4.3c0-1.2-2.5,0.4-2.4-0.2c0.1-1.2-1-2.9-1.6-4.1l0.8-2.9c0.3-0.3-1.6-3.6-3.2-4
                                                C16.3-64.9,14.2-63.1,14.2-63.1L14.2-63.1z"
      />
    </g>
    <g>
      <g transform="translate(-.53268 66.8)">
        <path
          class="st1"
          d="M20.5-56.9c0,0.3,0,0.7,0,0.9s-0.1,0.2-0.3,0.2l0.7,0.3c0.1,0.2,0.3,0.5,0.4,0.7c0.1,0.6,0,0.6-0.1,0.7
                                                    c-0.3,0-0.5,0-0.8,0c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.3-0.2,0.5c0.2,0.1,0.6,0.2,0.9,0.4l0.3-0.7
                                                    C21.5-54.9,21.1-55.9,20.5-56.9L20.5-56.9z"
        />
        <path
          class="st1"
          d="M21.8-53.2c0.4,0.2,1,0.1,1.8-0.3c0.1-0.1,0.2,0,0,0.1C22.6-52.4,22.1-52.9,21.8-53.2L21.8-53.2z"
        />
        <path
          class="st1"
          d="M23.7-53.6c0.1,0.2,0,0.4,0,0.6c-0.1,1-0.4,1.8-1,2.6c-2,3.2-4,1.2-5.6-0.1l-0.7,1.7c0,0.1-0.2,0.3,0.9,0.7
                                                    l1.1-0.7C22.3-45.8,25.1-53.1,23.7-53.6L23.7-53.6z"
        />
        <path class="st1" d="M17.5-50.7c-0.3,0-1.1,0.3-1.1,0.5l0.4-0.6L17.5-50.7z" />
        <path
          class="st1"
          d="M17-54.1c0,0,0.3,0.1,0.3,0c0,0,0,0.3,0.1,0.5L17-52.9c0.6-0.6,1.4-0.6,2.3-0.7l-0.4-0.2
                                                    c0.1-0.2,0-0.4,0-0.6L17-54.1z"
        />
        <path
          class="st1"
          d="M18.4-56c-0.6,0.2-1.2,0.5-1.6,1.1c0.3-1.1,0.4-1,0.4-1.1C17.7-56.1,18-56,18.4-56L18.4-56z"
        />
      </g>
      <g transform="translate(-.53268 66.8)">
        <path
          class="st2"
          d="M15.2-43.7c-0.7,0.8-2.3-0.1-3.7-0.5c-3.4-2.2-4.1-4.2-4.1-6.2c-0.2-2.9,0.7-4.5,1.8-4.9
                                                    c-0.7,1.4-1.1,4-0.7,5.9c0.3,1.1,0.3,2.8,2,3.8c0.9,0.5,0.6,0.9,1.2,1.3C12.4-43.9,14.1-43.4,15.2-43.7L15.2-43.7z"
        />
        <path
          class="st2"
          d="M16.2-56.9c-1.2-1.7-2.9-2.4-4.9-2.5c0.4-0.1,0.8-0.2,1.2-0.4c0.1-0.1,0.1-0.3,0-0.5c-1.9-0.5-3-1.1-4.5-1.6
                                                    l4,1C14.8-60.8,14.5-60.1,16.2-56.9L16.2-56.9z"
        />
        <path
          class="st2"
          d="M17.6-57.7c-0.9-1.3-1.8-2.9-3-3.9c-2.9-1.3-5.7-2.3-9-3.1l1.1-1.4c3.5,0.8,6.9,2.2,9.8,4.4
                                                    C16.9-60.4,17.3-59.2,17.6-57.7L17.6-57.7z"
        />
        <path
          class="st2"
          d="M19.4-60c0,0-0.5-1.1-0.8-1.6c-0.6-0.6-0.9-1.8-2-2.4c0.4,0.1,0.6,0,1.4,0.7l1.2,2.3L19.4-60z"
        />
        <path
          class="st2"
          d="M20-58.1c0.1-1,0.3-2.6,0.1-3.2c-0.7-1-1.4-1.9-2.1-2.8c-0.1-0.1-0.2-0.3-0.3-0.4c1,0.3,2.1,1,3.5,3.7
                                                    L20-58.1z"
        />
      </g>
      <g transform="translate(-.53268 66.8)">
        <path
          d="M15.8-57.9c-0.4-0.3-0.8-1.4-1.4-1.7c-0.6,0-0.8-0.2-1.5-0.1c0.3-0.1,0.5-0.3,0.8-0.4c0.2,0,0.4,0,0.6,0
                                                    c0,0,0.1,0,0.1-0.1c-0.7-0.5-2.3-0.5-3.3-0.7c1.2,0,2.5,0,3.4,0.3C15.2-60,15.4-58.8,15.8-57.9L15.8-57.9z"
        />
        <path
          d="M18-63.3c0.2,0,1.1,1.4,1.1,1.7c0.1,0.5,0.3,1.1,0.3,1.6c-0.1-0.5-0.3-1-0.5-1.5c-0.1-0.1-0.2-0.5-0.6-0.9
                                                    C18.1-62.8,18-63.1,18-63.3L18-63.3z"
        />
        <path d="M21-55.6h-0.4H21L21-55.6z" />
        <path d="M18.4-56c-0.4,0-0.8-0.1-1.2,0C17-55.8,17-55.6,17-55.4C17.5-55.9,17.6-55.9,18.4-56L18.4-56z" />
        <path d="M23.6-53.4c-0.2,0.2-0.2,0.3-0.9,0.5c-0.5,0.1-0.7-0.1-0.9-0.3c0.3,0.1,0.4,0.3,1.3,0.1L23.6-53.4z" />
        <path
          d="M21.7-53.3c-0.2,0.4-0.3,0.8-0.5,1.2c-0.4,0.5-0.2,0.2-0.8,0.9c0.2-0.3,0.4-0.6,0.6-0.9c0.1-0.2,0.2-0.4,0.3-0.6
                                                    c-0.2-0.1-0.5-0.1-0.5-0.1c-0.5,0.3-0.6,0.6-0.9,1c0.2-0.4,0.4-0.8,0.7-1.1c0,0,0.2-0.1,0.3-0.1c-0.2,0-0.5-0.1-0.6-0.1
                                                    c-0.3,0.1-0.5,0.4-0.8,0.6c0.2-0.3,0.4-0.5,0.6-0.8c0,0,0.7,0,1,0.2v0.1l0.2,0.1l0.1-0.4L21.7-53.3z"
        />
        <path d="M17.5-50.7c-0.1,0-0.8-0.1-0.8-0.1c-0.3,0.2-0.3,0.4-0.3,0.6C16.8-50.7,16.8-50.7,17.5-50.7L17.5-50.7z" />
        <path
          d="M16.9-54.1c0,0,0.5-0.1,0.5,0c0,0.2,0.1,0.6,0.1,0.6l-0.3,0.2c0.6-0.3,1.3-0.3,2-0.3c0,0-0.6-0.2-0.5-0.2s0-0.6-0.1-0.6
                                                    c0.1,0,0.2-0.1,0.3-0.1C18.1-54.7,17.7-54.6,16.9-54.1L16.9-54.1z"
        />
        <path
          d="M21.6-53.7l-0.1,0.1c0-0.4-0.4-0.3-0.7-0.3L20.5-54c0.1,0.1,0.3,0.1,0.2,0.2c-0.1,0-0.1,0.2-0.2,0.4h-0.1
                                                    c0.3,0.1,0.5,0.2,0.8,0.3h0.2l0.4-0.2L21.6-53.7z"
        />
      </g>
      <g transform="translate(-.53268 66.8)">
        <path
          class="st3"
          d="M18-53.9c-0.1-0.1-0.4-0.1-0.4,0c-0.1,0.1,0,0.5,0.1,0.4s0.2-0.1,0.4-0.1C18.1-53.8,18.1-53.8,18-53.9
                                                    L18-53.9z"
        />
        <path
          class="st3"
          d="M21-53.3c0,0,0.1-0.2,0.1-0.3s-0.3-0.1-0.3-0.1c-0.1,0-0.1,0.3-0.1,0.3C20.6-53.3,20.9-53.3,21-53.3L21-53.3
                                                    z"
        />
      </g>
      <g transform="translate(-.53268 66.8)">
        <path d="M8.8-46.3H9c1.1,0.5,2.8,1.3,5.4,0.9l0.4,0.6c-1.1,0.5-2.3,0.4-3.5,0.5L8.8-46.3z" />
        <path d="M7.2-50.4l0.5,2.2c1.4,0.9,3.9,1.9,5.9,1.8l-0.5-1C9-48.3,8.6-49.4,7.2-50.4L7.2-50.4z" />
        <path
          d="M8.6-55.1c0.3,1.7,0.6,3.1,2.1,4.2c0.9,0.7,1.9,1.3,3,1.9c0,0-0.1,0.5-0.2,0.5c-3.3-0.4-5.8-3.1-6.1-4.4
                                                    C7.7-53.9,8.1-54.5,8.6-55.1L8.6-55.1z"
        />
        <path
          d="M10.2-56c0.4,1.9,1.5,3.9,2.6,5.7c0.4,0.5,0.5,0.7,1.1,1c0.8,0.3,1.4,0.2,2,0.2c-0.2-0.3-0.3-0.6-0.5-0.9
                                                    c-1.3-1-0.7-2-0.4-2.6c-0.7-0.2-1.7-0.6-1.8-1.1c-0.2-1.7-0.1-2.2,0.1-3C12.2-56.6,11.2-56.4,10.2-56L10.2-56z"
        />
        <path
          class="st4"
          d="M6.6-66.3c-0.3,0.2-0.6,0.5-1,0.9c-0.6,0.6-0.9,1.1-1.5,1.6c-0.1,0.1-0.4,0.3-0.8,0.3c-0.2,0-0.3,0-0.7,0
                                                    c-0.3-0.2-0.6-0.1-0.9,0.3C1.4-62.9,1-62,0.9-61.5c-0.3,1.4,0.5,2.5,1.3,3.3s1.2,1.2,1.5,1.9c0.2,0.4,0.4,1,0.6,1.4
                                                    c0.1,0.1,0.1,0.1,0.2,0.2c0.4,0.1,0.9,0.1,1.4,0.1c0.2,0,0.5,0,0.8,0c0.4-0.1,0.8-0.1,1.2-0.3c0.4-0.1,0.7-0.2,0.9-0.3
                                                    c0,0.1-0.3,0.2-0.4,0.4c-1,1.4-1.2,2.6-1.1,4.5c0.1,0.9,0.3,1.6,0.6,2.4c0.1,0.4,0.4,1,0.7,1.3c0.8,1.2,2.7,2.7,5.2,3.2
                                                    c0.4,0.1,1,0,1.4-0.1c1.3-0.4,3.8-1.3,3.8-1.3s-2.3,0.2-3.3,0.1c-0.2,0-0.5-0.1-0.7-0.2l-0.1-0.2c0.1,0,0.4-0.1,0.8-0.1
                                                    c-0.9-0.1-0.8-0.1-0.9-0.2c-0.1-0.2-0.2-0.6-0.4-0.9c0.4,0,1.2,0.1,1.5-0.2c0,0-0.5,0.1-0.9,0c-0.1,0-0.4-0.1-0.5-0.1
                                                    c-0.2-0.1-0.4-0.1-0.4-0.1c-0.1-0.2-0.1-0.2-0.2-0.5c-0.1-0.3-0.1-0.6-0.1-1c0.3,0.4,0.6,0.6,1.1,0.8c0,0,0.6,0.3,1.1,0.1h0.1
                                                    c0,0-0.3,0-0.4,0c-0.9-0.4-1.1-0.7-1.2-0.9l-0.4-0.6c0.1-0.2,0.2-0.2,0.3-0.3c0.5,0.1,0.7,0.1,1,0c0.2,0.4,0.3,0.8,0.7,1.1
                                                    c1.5,0.4,1.8-0.1,2.2-0.6c1.1,0.8,2.8,1,4.1,0c1.5-1.8,2-4.6,1.9-4.8c-0.2-0.3-0.4-0.7-0.7-0.6c-0.8,0.2-1.1,0.6-1.8,0.5
                                                    c0.1,0,0.2,0,0.2,0c0.1-0.7,0-1,0-1c-0.2-0.5-0.5-1.1-0.8-1.5c-0.1-0.1-0.2-0.7-0.5-1c-0.1-0.1-0.4-0.4-0.4-0.4v0.3
                                                    c0,0,0.1,0,0.2,0.2c0.2,0.7,1,2.2,1,2.3c0.3,0.5,0,1.1,0.2,1.4c0,0,0.4,0,0.8,0c0.5-0.1,0.5-0.4,1-0.4c0.3,0,0.3,0.6,0.3,0.6
                                                    c-0.1,0.7-0.3,1.4-0.6,2.1c-0.6,1.2-1.4,2.3-2.4,2.5c-1.2,0.2-1.9-0.3-2.5-0.7L18-48.9c-0.9,0.9-1.9,0.8-2.3-0.3
                                                    c-0.2-0.4-0.5-0.7-0.7-1.1l-1.2,0.9c-0.1,0.2-0.2,0.5-0.4,0.9c-0.1,0.3-0.2,0.7-0.2,1.1c-0.2,0.3,0.6,1.4,1,2
                                                    c0.1,0.2,0.4,0.5,0.4,0.6c0.1,0.2,0.3,0.4,0.3,0.5c0.8,1.1-1.1,0.8-1.4,0.8c-0.8-0.1-1.5-0.4-2.2-0.9l-0.1-0.1
                                                    c-0.8-0.5-1.6-1.2-2.3-1.9c-0.4-0.4-0.8-1.4-1-2c-0.4-1.6-1-4.2,0.6-6.3c0-0.1,0.1-0.3,0.2-0.3c0.5-0.3,1-0.5,1.6-0.7l-0.1-0.4
                                                    C9.9-56,9-55.7,8.7-55.5c-0.6,0.2-1.1,0.4-1.9,0.6c-0.3,0-0.5,0-0.7,0C5.6-55,4.6-55,4.6-55c-0.4-0.5-0.5-1.5-0.8-2l0,0l0,0
                                                    c-0.2-0.3-0.4-0.5-0.7-0.7c-0.8-0.8-1.5-1.6-1.7-2.4c0-0.2-0.2-0.5-0.1-1.2l0,0l0,0c0.2-0.7,0.5-1.2,1.1-1.8c0.6,0,1.1,0,1.6,0.1
                                                    c0.2,0,0.6,0.1,1,0.2c1.1,0.4,2.5,1.1,2.5,1.1c-1.1-0.6-2.3-1.4-3.1-1.5c-0.1,0-0.2-0.1-0.2-0.2c1.1-0.7,1.4-1.5,2.1-2.2
                                                    c0.3-0.2,0.5-0.2,0.8-0.3c2.7,0.4,4.4,1.5,5.8,2.2c0.5,0.3,1,0.5,1.5,0.8c0.4,0.1,1.7,1.1,2,1.6c0.4,0.8,0.6,1.6,0.9,2.5
                                                    c0.2,0.8,0.3,1.2,0.3,1.2s-0.2-0.7-0.1-0.8c0.2,0.1,0.5,0.2,0.7,0.2c0,0-0.7-0.4-0.8-0.7c-0.3-1-0.6-2.6-0.7-2.7
                                                    c-0.2-0.3-1.1-1-1.7-1.3c-0.2-0.1-0.3-0.2-0.3-0.3c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.1,0.4-0.3,0.6-0.4c1.2-0.5,1.8,0.2,2,0.1
                                                    c0,0-0.3-0.3-0.1-0.2c0.1,0.1,0.5,0.1,0.5,0.2c0.4,0.3,1.5,1.6,2.2,2.8c0.2,0.3,0.2,0.5,0.2,0.9c-0.1,0.4-0.1,0.6-0.2,0.8
                                                    S20-58.3,20-58.1c-0.1,0.6,0.3,1.4,0.3,1.4c0-0.2,0-0.3,0-0.5v-0.3v-0.1c0-0.2,0.1-0.4,0.1-0.5c0.1-0.8,0.4-1.4,0.6-2.2
                                                    c0.1-0.2,0.2-0.3,0.2-0.4c0-0.2-0.2-0.6-0.4-0.9c-0.2-0.3-0.4-0.7-0.6-1.1c-0.6-0.8-1.1-1.5-2-1.9c-0.3-0.1-1.3-0.2-1.6-0.2
                                                    c-0.4,0.1-0.8,0.2-1.1,0.4c-0.5,0.3-0.8,0.7-1.2,1c-0.9-0.5-1.4-0.8-1.5-0.9c-0.5-0.3-1.2-0.6-1.9-1C10.6-65.4,8.5-66.1,6.6-66.3
                                                    L6.6-66.3z M18.4-49.1c-0.6-0.4-1-0.9-1.4-1.4c-0.1,0.6-0.5,0.9-0.8,1.3c-0.1,0.1-0.1,0.2,0.2,0.6c0.1,0.1,0.4,0.1,0.5,0.1
                                                    c-0.2-0.1-0.5-0.3-0.5-0.4c0.3,0.2,0.6,0.3,0.9,0.3c0.1,0,0.1-0.1,0.2-0.2c0.1-0.3,0.2-0.3,0.3-0.4l0.2,0.3L18.4-49.1z"
        />
      </g>
    </g>
  </g>
</svg>

<svg viewBox="0 0 24 24" *ngIf="name === 'MYSQL'" [attr.class]="getCss()">
  <g>
    <g>
      <g>
        <path
          class="st0"
          d="M23.3,23c-0.2-0.2-0.3-0.3-0.5-0.5c-0.5-0.6-1-1.2-1.6-1.6c-0.5-0.4-1.6-0.8-1.8-1.4l0,0
        c0.3,0,0.8-0.2,1.1-0.3c0.5-0.1,1-0.1,1.6-0.3c0.2-0.1,0.5-0.1,0.7-0.2v-0.1c-0.3-0.3-0.5-0.7-0.8-0.9c-0.8-0.7-1.7-1.4-2.6-2
        c-0.5-0.3-1.1-0.5-1.6-0.8c-0.2-0.1-0.5-0.1-0.6-0.3c-0.3-0.4-0.4-0.8-0.6-1.2c-0.4-0.9-0.9-1.8-1.3-2.8C15,10,14.9,9.3,14.5,8.8
        C12.8,6,11,4.4,8.3,2.8C7.7,2.5,7,2.3,6.3,2.1c-0.4,0-0.8,0-1.2-0.1C4.9,1.9,4.7,1.6,4.5,1.5C3.6,0.9,1.3-0.3,0.6,1.3
        c-0.4,1,0.6,2.1,1,2.6c0.3,0.4,0.6,0.8,0.8,1.2c0.1,0.3,0.1,0.5,0.3,0.8C3,6.6,3.2,7.4,3.5,8c0.2,0.3,0.4,0.7,0.6,1
        c0.1,0.2,0.4,0.3,0.4,0.5c-0.2,0.3-0.2,0.8-0.4,1.2c-0.6,1.8-0.3,4,0.5,5.3c0.2,0.4,0.8,1.3,1.6,0.9c0.7-0.3,0.5-1.2,0.7-2
        c0-0.2,0-0.3,0.1-0.4l0,0c0.2,0.4,0.4,0.9,0.6,1.3c0.5,0.8,1.3,1.6,2,2.1c0.4,0.3,0.7,0.8,1.1,0.9l0,0l0,0
        c-0.1-0.1-0.2-0.2-0.4-0.3c-0.3-0.3-0.6-0.6-0.8-0.9c-0.6-0.9-1.2-1.9-1.7-2.9c-0.3-0.5-0.5-1-0.7-1.5C7,13,7,12.7,6.9,12.6
        c-0.2,0.4-0.6,0.7-0.7,1.1c-0.3,0.7-0.3,1.5-0.4,2.4c-0.1,0,0,0-0.1,0C5.2,16,5,15.5,4.8,15c-0.5-1.1-0.6-3-0.1-4.3
        C4.8,10.4,5.3,9.3,5.1,9C5,8.9,4.7,8.8,4.5,8.5S4.1,7.8,4,7.5C3.6,6.6,3.4,5.6,3,4.7C2.8,4.3,2.5,3.9,2.2,3.5S1.6,2.8,1.3,2.3
        c0-0.2-0.1-0.5,0-0.7c0-0.1,0.1-0.2,0.2-0.2c0.2-0.2,0.8,0.1,1,0.1C3.1,1.8,3.5,2,4,2.3c0.2,0.2,0.5,0.5,0.7,0.5H5
        c0.5,0.1,1.1,0,1.5,0.2c0.8,0.3,1.6,0.7,2.2,1.1c2.1,1.3,3.7,3.2,4.9,5.5c0.2,0.4,0.3,0.7,0.4,1.1c0.3,0.8,0.7,1.6,1.1,2.3
        c0.3,0.8,0.6,1.5,1.1,2.1c0.2,0.3,1.2,0.5,1.6,0.7c0.3,0.1,0.8,0.3,1.1,0.4c0.5,0.3,1.1,0.7,1.6,1.1c0.3,0.2,1,0.6,1.1,0.9
        c-1.3,0-2.2,0.1-3,0.4C18.4,18.7,18,18.7,18,19c0.1,0.1,0.1,0.3,0.2,0.5c0.2,0.3,0.5,0.8,0.8,1s0.7,0.5,1,0.7
        c0.6,0.4,1.3,0.6,1.9,1c0.3,0.2,0.7,0.5,1,0.8c0.2,0.1,0.3,0.3,0.5,0.4l0,0C23.4,23.3,23.4,23.1,23.3,23z M5.1,4.5L5.1,4.5
        L5.1,4.5C5.3,4.8,5.5,5,5.6,5.2C5.7,5.5,5.9,5.7,6,6l0,0c0.2-0.2,0.3-0.4,0.3-0.8c0-0.2-0.1-0.3-0.1-0.4C6.1,4.7,5.9,4.6,5.7,4.4
        C5.5,4.4,5.3,4.5,5.1,4.5z"
        ></path>
      </g>
    </g>
  </g>
</svg>

<svg viewBox="0 0 24 24" *ngIf="name === 'POSTGRESQL' && !isBlackAndWhite" [attr.class]="getCss()">
  <g>
    <g>
      <g>
        <path
          d="M17.4,17.2c0.1-1.2,0.1-1.4,1-1.2h0.2c0.7,0,1.6-0.1,2.1-0.4c1.1-0.5,1.8-1.4,0.7-1.2c-2.6,0.5-2.7-0.3-2.7-0.3
                                                c2.7-4,3.8-9.1,2.9-10.4c-2.7-3.4-7.3-1.8-7.3-1.8l0,0c-0.5-0.1-1.1-0.2-1.7-0.2c-1.2,0-2,0.3-2.7,0.8c0,0-8.2-3.4-7.8,4.3
                                                C2.1,8.5,4.3,19.3,7,16c1-1.2,1.9-2.2,1.9-2.2c0.5,0.3,1,0.5,1.6,0.4l0,0c0,0.1,0,0.3,0,0.5c-0.7,0.8-0.5,0.9-1.9,1.2
                                                c-1.4,0.3-0.6,0.8,0,0.9c0.6,0.2,2.2,0.4,3.2-1V16c0.3,0.2,0.3,1.6,0.3,2.5c0,1,0.1,1.9,0.3,2.4s0.4,1.9,2.2,1.5
                                                C16.2,22,17.3,21.6,17.4,17.2"
        ></path>
        <path
          class="st0"
          d="M14.1,23.4c-0.6,0-1.2-0.1-1.7-0.6s-0.7-1.1-0.8-1.4c0-0.1,0-0.1,0-0.1c-0.2-0.6-0.3-1.5-0.4-2.7
                                                c0-0.2,0-0.4,0-0.5s0-0.2,0-0.4c-0.8,0.3-1.7,0.4-2.7,0.2c-0.3-0.1-0.5-0.2-0.8-0.3c-0.2-0.1-0.3-0.2-0.4-0.3
                                                c-0.8,0.7-1.5,0.6-1.9,0.4c-0.7-0.2-1.4-0.9-2-2.1c-0.4-0.8-0.8-1.9-1.2-3.1C1.5,10.1,1.1,7.7,1,7C0.9,4.5,1.6,2.8,3,1.8
                                                C4.1,1,5.7,0.7,7.7,1.1c0.8,0.1,1.6,0.4,2,0.5c0.8-0.5,1.8-0.7,2.8-0.7c0.6,0,1.1,0.1,1.6,0.2c0.5-0.2,1.6-0.4,2.8-0.4
                                                c2.2,0,4.1,0.9,5.4,2.5c0.5,0.6,0.9,2,0.1,5C22,10,21.2,12,20.1,13.7c0.3,0,0.6,0,1.1-0.2c0.3-0.1,0.5-0.1,0.7,0
                                                c0.4,0.1,0.8,0.3,0.9,0.7c0.4,1.2-1.3,2-1.8,2.3c-0.6,0.3-1.7,0.5-2.6,0.4l0,0h-0.2V17c0,0.1,0,0.1,0,0.2c-0.1,2.2-0.4,3.6-1,4.6
                                                c-0.7,1-1.7,1.3-2.5,1.5C14.6,23.3,14.4,23.4,14.1,23.4z M12.4,15.2c0.5,0.4,0.6,1.2,0.6,2.7c0,0.2,0,0.4,0,0.5
                                                c0,0.5,0.1,1.6,0.2,2.1c0,0.1,0,0.1,0.1,0.2c0.2,0.6,0.3,0.9,1.1,0.7c0.7-0.2,1.1-0.3,1.3-0.7c0.4-0.6,0.6-1.8,0.7-3.6l0,0l0,0
                                                c0-0.1,0-0.2,0-0.2c0.1-0.7,0.1-1.3,0.7-1.6c0.3-0.2,0.7-0.3,1-0.2c-0.3-0.3-0.3-0.5-0.4-0.7L17.6,14l0.2-0.3
                                                c1.2-1.8,2.2-3.9,2.7-5.9c0.6-2.3,0.3-3.2,0.2-3.3c-2.1-2.7-5.8-1.6-6.2-1.5h-0.1h-0.2H14c-0.5-0.1-1-0.1-1.5-0.2
                                                c-0.9,0-1.5,0.2-2.1,0.6L10,3.7L9.5,3.5c-1-0.4-3.9-1.2-5.4-0.1C3.2,4,2.9,5.1,2.9,6.9c0,0.6,0.4,2.8,1.1,5
                                                c0.9,3,1.7,3.8,1.9,3.8c0,0,0.1-0.1,0.3-0.3c1-1.2,1.9-2.2,2-2.2l0.6-0.6L9.5,13c0.2,0.2,0.5,0.2,0.7,0.3l1.6-1.4l-0.2,2.4
                                                c0,0,0,0.1,0,0.2l0.1,0.4l-0.3,0.3l0,0l0.7,0.3L12.4,15.2z"
        ></path>
      </g>
      <path
        class="st0"
        d="M21.5,14.5c-2.6,0.5-2.7-0.3-2.7-0.3c2.7-4,3.8-9.1,2.9-10.4C19,0.4,14.4,2,14.4,2l0,0
                                            c-0.5-0.1-1.1-0.2-1.7-0.2c-1.2,0-2,0.3-2.7,0.8c0,0-8.2-3.4-7.8,4.3C2.1,8.5,4.3,19.3,7,16c1-1.2,1.9-2.2,1.9-2.2
                                            c0.5,0.3,1,0.5,1.6,0.4l0,0c0,0.1,0,0.3,0,0.5c-0.7,0.8-0.5,0.9-1.9,1.2c-1.4,0.3-0.6,0.8,0,0.9c0.6,0.2,2.2,0.4,3.2-1V16
                                            c0.3,0.2,0.5,1.4,0.4,2.5c0,1.1-0.1,1.8,0.2,2.4c0.2,0.6,0.4,1.9,2.2,1.5c1.5-0.3,2.3-1.2,2.4-2.6c0.1-1,0.3-0.9,0.3-1.8l0.1-0.4
                                            c0.2-1.4,0-1.8,1-1.6h0.2c0.7,0,1.6-0.1,2.1-0.4C21.9,15.1,22.6,14.2,21.5,14.5L21.5,14.5z"
      ></path>
      <path
        class="st1"
        d="M14.2,22.8c-1.5,0-2-1.2-2.2-1.7c-0.3-0.7-0.4-3.5-0.3-5.8c0-0.2,0.2-0.3,0.3-0.3c0.2,0,0.3,0.2,0.3,0.3
                                            c-0.1,2.6,0,5,0.2,5.6c0.3,0.8,0.8,1.5,2.2,1.2s1.8-0.8,2.1-2.1c0.2-1,0.5-3.9,0.6-4.4c0-0.2,0.2-0.3,0.3-0.3
                                            c0.2,0,0.3,0.2,0.3,0.3c0,0.6-0.4,3.4-0.6,4.5c-0.3,1.6-1,2.3-2.6,2.6C14.6,22.8,14.4,22.8,14.2,22.8z"
      ></path>
      <path
        class="st1"
        d="M6,16.9c-0.1,0-0.3,0-0.4-0.1c-1-0.3-1.9-1.9-2.8-4.8C2.1,9.9,1.7,7.6,1.7,6.9C1.5,4.7,2.1,3.2,3.3,2.3
                                            c1-0.7,2.4-0.9,4.2-0.7c1.3,0.2,2.3,0.6,2.4,0.6c0.2,0.1,0.2,0.3,0.2,0.4C10,2.9,9.8,2.9,9.7,2.9c0,0-3.9-1.6-6-0.1
                                            c-1,0.7-1.5,2.1-1.4,4c0,0.6,0.4,2.8,1.1,5C4.1,14.4,5,16,5.8,16.2c0.1,0,0.5,0.2,1-0.5c1-1.2,1.9-2.1,1.9-2.1
                                            c0.1-0.1,0.3-0.1,0.4,0c0.1,0.1,0.1,0.3,0,0.5c0,0-0.9,0.9-1.9,2.1C6.8,16.7,6.3,16.9,6,16.9z"
      ></path>
      <path
        class="st1"
        d="M18.7,14.4c-0.1,0-0.1,0-0.2-0.1s-0.2-0.3-0.1-0.4c2.7-4.1,3.7-9,2.9-10c-1-1.3-2.5-2-4.3-2.1
                                            c-1.4,0-2.4,0.3-2.6,0.4l0,0c-0.2,0.1-0.3,0-0.4-0.2c-0.1-0.1,0-0.3,0.1-0.4s1.4-0.5,2.9-0.5c2,0,3.7,0.8,4.9,2.3
                                            c0.3,0.4,0.4,1,0.4,1.8c0,0.7-0.2,1.7-0.4,2.6c-0.6,2.1-1.6,4.5-2.9,6.3C18.9,14.4,18.8,14.4,18.7,14.4z"
      ></path>
      <path
        class="st1"
        d="M18.9,16.3c-0.5,0-0.9-0.1-1.1-0.2c-0.3-0.2-0.3-0.4-0.3-0.5c-0.1-1.2,0.6-1.5,0.9-1.6c0-0.1-0.1-0.1-0.2-0.2
                                            c-0.2-0.3-0.5-0.6-0.7-1.1c0-0.1-0.1-0.3-0.3-0.5c-0.7-1.2-2-3.7-1.1-4.9c0.4-0.6,1.2-0.8,2.4-0.7c-0.2-0.5-0.6-1.3-1.3-2.2
                                            c-0.8-1-2.3-2.2-4.7-2.2c-1.2,0-2.2,0.3-2.9,1.1C8,4.8,8,7.9,8,7.9c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3
                                            c0-0.1-0.1-3.3,1.8-5.2c0.9-0.9,2-1.3,3.4-1.3c2.5,0,4.2,1.3,5.1,2.4c1,1.2,1.5,2.5,1.5,2.9c0,0.3-0.2,0.4-0.3,0.4h-0.1
                                            c-1.4-0.2-2,0.1-2.2,0.4c-0.7,0.9,0.6,3.3,1.2,4.3c0.1,0.3,0.2,0.4,0.3,0.6c0.2,0.4,0.4,0.7,0.6,1c0.2,0.2,0.3,0.4,0.4,0.6
                                            c0,0,0.4,0.5,2.4,0.1c0.5-0.1,0.8,0,0.9,0.3c0.2,0.6-0.9,1.2-1.4,1.5C20.4,16.2,19.6,16.3,18.9,16.3z M18,15.5
                                            c0.1,0.1,0.4,0.2,1,0.1c0.6,0,1.2-0.2,1.6-0.3c0.4-0.2,0.7-0.4,0.9-0.6l0,0c-1.3,0.3-2.2,0.2-2.7-0.1c0,0-0.1,0-0.1-0.1h-0.1
                                            C18.3,14.7,18,14.8,18,15.5z"
      ></path>
      <path
        class="st1"
        d="M9.6,17.2c-0.3,0-0.7,0-1-0.1c-0.1,0-1-0.3-1-0.8s0.7-0.7,1-0.7c1-0.2,1-0.3,1.3-0.7c0.1-0.1,0.2-0.3,0.4-0.4
                                            c0.5-0.6,1-0.4,1.2-0.3c0.3,0.1,0.6,0.5,0.7,0.8c0.1,0.3,0.1,0.7-0.1,0.9C11.5,16.8,10.6,17.2,9.6,17.2z M8.3,16.3
                                            c0.1,0.1,0.2,0.1,0.4,0.2c0.8,0.2,2,0.2,2.8-0.9c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.2-0.2-0.4-0.3-0.4c-0.2-0.1-0.3-0.1-0.5,0.1l0,0
                                            c-0.1,0.2-0.2,0.3-0.3,0.4C10,15.8,9.8,16,8.7,16.2C8.6,16.2,8.4,16.2,8.3,16.3z M10.6,14.6L10.6,14.6L10.6,14.6z"
      ></path>
      <path
        class="st1"
        d="M10.5,14.9c-0.2,0-0.3-0.1-0.3-0.3v-0.1c-0.7,0-1.5-0.3-2-0.9c-0.7-0.7-1-1.7-0.9-2.8
                                            c0.2-1.1,0.1-2.1,0.1-2.6c0-0.1,0-0.3,0-0.3c0-0.1,0-0.4,0.8-0.8C8.5,7,9.1,6.7,9.8,6.6c1.1-0.1,1.8,0.4,2,1.4
                                            c0.6,2.6,0,3.8-0.3,4.7c-0.1,0.2-0.1,0.3-0.2,0.5v0.1c-0.2,0.5-0.4,1-0.3,1.3C10.9,14.8,10.8,14.9,10.5,14.9
                                            C10.6,14.9,10.6,14.9,10.5,14.9z M8,8c0,0.1,0,0.1,0,0.2c0,0.5,0.1,1.6-0.1,2.7c-0.1,0.8,0.1,1.7,0.7,2.2c0.4,0.5,1,0.7,1.6,0.7
                                            c0.1-0.3,0.2-0.6,0.3-0.9v-0.1c0.1-0.2,0.1-0.3,0.2-0.5c0.4-0.8,0.8-1.8,0.3-4.3c-0.1-0.5-0.3-0.7-0.7-0.8C9.5,7.1,8.3,7.8,8,8z"
      ></path>
      <g>
        <path
          class="st1"
          d="M9.8,7.9c0,0.1,0.2,0.4,0.5,0.4s0.5-0.2,0.5-0.3c0-0.1-0.2-0.2-0.5-0.3C10,7.7,9.8,7.8,9.8,7.9L9.8,7.9z"
        ></path>
        <path
          class="st1"
          d="M10.3,8.4C10.3,8.4,10.2,8.4,10.3,8.4c-0.2,0-0.4-0.1-0.5-0.3c0-0.1-0.1-0.2-0.1-0.3l0,0c0,0,0-0.1,0.1-0.1
                                                c0.1-0.1,0.3-0.1,0.5-0.1c0.3,0,0.6,0.2,0.5,0.4C10.8,8.2,10.6,8.4,10.3,8.4z M9.9,7.9c0,0.1,0.2,0.3,0.4,0.3s0.4-0.1,0.4-0.2
                                                c0,0-0.1-0.1-0.4-0.1C10.1,7.8,9.9,7.9,9.9,7.9z"
        ></path>
      </g>
      <g>
        <path
          class="st1"
          d="M17.9,7.6c0,0.1-0.2,0.4-0.5,0.4s-0.5-0.2-0.5-0.3c0-0.1,0.2-0.2,0.5-0.3C17.6,7.5,17.9,7.5,17.9,7.6
                                                L17.9,7.6z"
        ></path>
        <path
          class="st1"
          d="M17.4,8.1c-0.3,0-0.5-0.2-0.5-0.3c0-0.2,0.2-0.3,0.5-0.3s0.5,0,0.6,0.2l0,0c0,0.1,0,0.2-0.1,0.3
                                                C17.7,8,17.6,8.1,17.4,8.1L17.4,8.1z M17.5,7.6c0,0-0.1,0-0.2,0c-0.3,0-0.4,0.2-0.4,0.2C17,7.8,17.2,8,17.4,8
                                                c0.1,0,0.3-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.1-0.2C17.8,7.6,17.7,7.6,17.5,7.6z"
        ></path>
      </g>
      <path
        class="st1"
        d="M18.3,13.7c-0.1,0-0.1,0-0.2-0.1s-0.2-0.3-0.1-0.4c0.6-0.9,0.5-1.9,0.4-2.8c0-0.4-0.1-0.8-0.1-1.2
                                            s0.1-0.7,0.1-1.1c0.1-0.4,0.1-0.7,0.1-1.2c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3c0,0.5,0,0.9-0.1,1.3c0,0.3-0.1,0.6-0.1,1
                                            c0,0.3,0,0.7,0.1,1.1c0.1,1,0.3,2.1-0.5,3.2C18.5,13.6,18.4,13.7,18.3,13.7z"
      ></path>
      <path class="st2" d="M1,3.7"></path>
    </g>
  </g>
</svg>

<svg class="icon-db-post-w" *ngIf="name === 'POSTGRESQL' && isBlackAndWhite">
  <g>
    <g id="Layer_x0020_3">
      <g>
        <path
          d="M17.4,17.2c0.1-1.2,0.1-1.4,1-1.2h0.2c0.7,0,1.6-0.1,2.1-0.4c1.1-0.5,1.8-1.4,0.7-1.2c-2.6,0.5-2.7-0.3-2.7-0.3
                                                                    c2.7-4,3.8-9.1,2.9-10.4c-2.7-3.4-7.3-1.8-7.3-1.8l0,0c-0.5-0.1-1.1-0.2-1.7-0.2c-1.2,0-2,0.3-2.7,0.8c0,0-8.2-3.4-7.8,4.3
                                                                    C2.1,8.5,4.3,19.3,7,16c1-1.2,1.9-2.2,1.9-2.2c0.5,0.3,1,0.5,1.6,0.4l0,0c0,0.1,0,0.3,0,0.5c-0.7,0.8-0.5,0.9-1.9,1.2
                                                                    c-1.4,0.3-0.6,0.8,0,0.9c0.6,0.2,2.2,0.4,3.2-1V16c0.3,0.2,0.3,1.6,0.3,2.5c0,1,0.1,1.9,0.3,2.4s0.4,1.9,2.2,1.5
                                                                    C16.2,22,17.3,21.6,17.4,17.2"
        ></path>
        <path
          class="st0"
          d="M14.1,23.4c-0.6,0-1.2-0.1-1.7-0.6s-0.7-1.1-0.8-1.4c0-0.1,0-0.1,0-0.1c-0.2-0.6-0.3-1.5-0.4-2.7
                                                                    c0-0.2,0-0.4,0-0.5s0-0.2,0-0.4c-0.8,0.3-1.7,0.4-2.7,0.2c-0.3-0.1-0.5-0.2-0.8-0.3c-0.2-0.1-0.3-0.2-0.4-0.3
                                                                    c-0.8,0.7-1.5,0.6-1.9,0.4c-0.7-0.2-1.4-0.9-2-2.1c-0.4-0.8-0.8-1.9-1.2-3.1C1.5,10.1,1.1,7.7,1,7C0.9,4.5,1.6,2.8,3,1.8
                                                                    C4.1,1,5.7,0.7,7.7,1.1c0.8,0.1,1.6,0.4,2,0.5c0.8-0.5,1.8-0.7,2.8-0.7c0.6,0,1.1,0.1,1.6,0.2c0.5-0.2,1.6-0.4,2.8-0.4
                                                                    c2.2,0,4.1,0.9,5.4,2.5c0.5,0.6,0.9,2,0.1,5C22,10,21.2,12,20.1,13.7c0.3,0,0.6,0,1.1-0.2c0.3-0.1,0.5-0.1,0.7,0
                                                                    c0.4,0.1,0.8,0.3,0.9,0.7c0.4,1.2-1.3,2-1.8,2.3c-0.6,0.3-1.7,0.5-2.6,0.4l0,0h-0.2V17c0,0.1,0,0.1,0,0.2c-0.1,2.2-0.4,3.6-1,4.6
                                                                    c-0.7,1-1.7,1.3-2.5,1.5C14.6,23.3,14.4,23.4,14.1,23.4z M12.4,15.2c0.5,0.4,0.6,1.2,0.6,2.7c0,0.2,0,0.4,0,0.5
                                                                    c0,0.5,0.1,1.6,0.2,2.1c0,0.1,0,0.1,0.1,0.2c0.2,0.6,0.3,0.9,1.1,0.7c0.7-0.2,1.1-0.3,1.3-0.7c0.4-0.6,0.6-1.8,0.7-3.6l0,0l0,0
                                                                    c0-0.1,0-0.2,0-0.2c0.1-0.7,0.1-1.3,0.7-1.6c0.3-0.2,0.7-0.3,1-0.2c-0.3-0.3-0.3-0.5-0.4-0.7L17.6,14l0.2-0.3
                                                                    c1.2-1.8,2.2-3.9,2.7-5.9c0.6-2.3,0.3-3.2,0.2-3.3c-2.1-2.7-5.8-1.6-6.2-1.5h-0.1h-0.2H14c-0.5-0.1-1-0.1-1.5-0.2
                                                                    c-0.9,0-1.5,0.2-2.1,0.6L10,3.7L9.5,3.5c-1-0.4-3.9-1.2-5.4-0.1C3.2,4,2.9,5.1,2.9,6.9c0,0.6,0.4,2.8,1.1,5
                                                                    c0.9,3,1.7,3.8,1.9,3.8c0,0,0.1-0.1,0.3-0.3c1-1.2,1.9-2.2,2-2.2l0.6-0.6L9.5,13c0.2,0.2,0.5,0.2,0.7,0.3l1.6-1.4l-0.2,2.4
                                                                    c0,0,0,0.1,0,0.2l0.1,0.4l-0.3,0.3l0,0l0.7,0.3L12.4,15.2z"
        ></path>
      </g>
      <path
        class="st0"
        d="M21.5,14.5c-2.6,0.5-2.7-0.3-2.7-0.3c2.7-4,3.8-9.1,2.9-10.4C19,0.4,14.4,2,14.4,2l0,0
                                                                c-0.5-0.1-1.1-0.2-1.7-0.2c-1.2,0-2,0.3-2.7,0.8c0,0-8.2-3.4-7.8,4.3C2.1,8.5,4.3,19.3,7,16c1-1.2,1.9-2.2,1.9-2.2
                                                                c0.5,0.3,1,0.5,1.6,0.4l0,0c0,0.1,0,0.3,0,0.5c-0.7,0.8-0.5,0.9-1.9,1.2c-1.4,0.3-0.6,0.8,0,0.9c0.6,0.2,2.2,0.4,3.2-1V16
                                                                c0.3,0.2,0.5,1.4,0.4,2.5c0,1.1-0.1,1.8,0.2,2.4c0.2,0.6,0.4,1.9,2.2,1.5c1.5-0.3,2.3-1.2,2.4-2.6c0.1-1,0.3-0.9,0.3-1.8l0.1-0.4
                                                                c0.2-1.4,0-1.8,1-1.6h0.2c0.7,0,1.6-0.1,2.1-0.4C21.9,15.1,22.6,14.2,21.5,14.5L21.5,14.5z"
      ></path>
      <path
        class="st1"
        d="M14.2,22.8c-1.5,0-2-1.2-2.2-1.7c-0.3-0.7-0.4-3.5-0.3-5.8c0-0.2,0.2-0.3,0.3-0.3c0.2,0,0.3,0.2,0.3,0.3
                                                                c-0.1,2.6,0,5,0.2,5.6c0.3,0.8,0.8,1.5,2.2,1.2s1.8-0.8,2.1-2.1c0.2-1,0.5-3.9,0.6-4.4c0-0.2,0.2-0.3,0.3-0.3
                                                                c0.2,0,0.3,0.2,0.3,0.3c0,0.6-0.4,3.4-0.6,4.5c-0.3,1.6-1,2.3-2.6,2.6C14.6,22.8,14.4,22.8,14.2,22.8z"
      ></path>
      <path
        class="st1"
        d="M6,16.9c-0.1,0-0.3,0-0.4-0.1c-1-0.3-1.9-1.9-2.8-4.8C2.1,9.9,1.7,7.6,1.7,6.9C1.5,4.7,2.1,3.2,3.3,2.3
                                                                c1-0.7,2.4-0.9,4.2-0.7c1.3,0.2,2.3,0.6,2.4,0.6c0.2,0.1,0.2,0.3,0.2,0.4C10,2.9,9.8,2.9,9.7,2.9c0,0-3.9-1.6-6-0.1
                                                                c-1,0.7-1.5,2.1-1.4,4c0,0.6,0.4,2.8,1.1,5C4.1,14.4,5,16,5.8,16.2c0.1,0,0.5,0.2,1-0.5c1-1.2,1.9-2.1,1.9-2.1
                                                                c0.1-0.1,0.3-0.1,0.4,0c0.1,0.1,0.1,0.3,0,0.5c0,0-0.9,0.9-1.9,2.1C6.8,16.7,6.3,16.9,6,16.9z"
      ></path>
      <path
        class="st1"
        d="M18.7,14.4c-0.1,0-0.1,0-0.2-0.1s-0.2-0.3-0.1-0.4c2.7-4.1,3.7-9,2.9-10c-1-1.3-2.5-2-4.3-2.1
                                                                c-1.4,0-2.4,0.3-2.6,0.4l0,0c-0.2,0.1-0.3,0-0.4-0.2c-0.1-0.1,0-0.3,0.1-0.4s1.4-0.5,2.9-0.5c2,0,3.7,0.8,4.9,2.3
                                                                c0.3,0.4,0.4,1,0.4,1.8c0,0.7-0.2,1.7-0.4,2.6c-0.6,2.1-1.6,4.5-2.9,6.3C18.9,14.4,18.8,14.4,18.7,14.4z"
      ></path>
      <path
        class="st1"
        d="M18.9,16.3c-0.5,0-0.9-0.1-1.1-0.2c-0.3-0.2-0.3-0.4-0.3-0.5c-0.1-1.2,0.6-1.5,0.9-1.6c0-0.1-0.1-0.1-0.2-0.2
                                                                c-0.2-0.3-0.5-0.6-0.7-1.1c0-0.1-0.1-0.3-0.3-0.5c-0.7-1.2-2-3.7-1.1-4.9c0.4-0.6,1.2-0.8,2.4-0.7c-0.2-0.5-0.6-1.3-1.3-2.2
                                                                c-0.8-1-2.3-2.2-4.7-2.2c-1.2,0-2.2,0.3-2.9,1.1C8,4.8,8,7.9,8,7.9c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3
                                                                c0-0.1-0.1-3.3,1.8-5.2c0.9-0.9,2-1.3,3.4-1.3c2.5,0,4.2,1.3,5.1,2.4c1,1.2,1.5,2.5,1.5,2.9c0,0.3-0.2,0.4-0.3,0.4h-0.1
                                                                c-1.4-0.2-2,0.1-2.2,0.4c-0.7,0.9,0.6,3.3,1.2,4.3c0.1,0.3,0.2,0.4,0.3,0.6c0.2,0.4,0.4,0.7,0.6,1c0.2,0.2,0.3,0.4,0.4,0.6
                                                                c0,0,0.4,0.5,2.4,0.1c0.5-0.1,0.8,0,0.9,0.3c0.2,0.6-0.9,1.2-1.4,1.5C20.4,16.2,19.6,16.3,18.9,16.3z M18,15.5
                                                                c0.1,0.1,0.4,0.2,1,0.1c0.6,0,1.2-0.2,1.6-0.3c0.4-0.2,0.7-0.4,0.9-0.6l0,0c-1.3,0.3-2.2,0.2-2.7-0.1c0,0-0.1,0-0.1-0.1h-0.1
                                                                C18.3,14.7,18,14.8,18,15.5z"
      ></path>
      <path
        class="st1"
        d="M9.6,17.2c-0.3,0-0.7,0-1-0.1c-0.1,0-1-0.3-1-0.8s0.7-0.7,1-0.7c1-0.2,1-0.3,1.3-0.7c0.1-0.1,0.2-0.3,0.4-0.4
                                                                c0.5-0.6,1-0.4,1.2-0.3c0.3,0.1,0.6,0.5,0.7,0.8c0.1,0.3,0.1,0.7-0.1,0.9C11.5,16.8,10.6,17.2,9.6,17.2z M8.3,16.3
                                                                c0.1,0.1,0.2,0.1,0.4,0.2c0.8,0.2,2,0.2,2.8-0.9c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.2-0.2-0.4-0.3-0.4c-0.2-0.1-0.3-0.1-0.5,0.1l0,0
                                                                c-0.1,0.2-0.2,0.3-0.3,0.4C10,15.8,9.8,16,8.7,16.2C8.6,16.2,8.4,16.2,8.3,16.3z M10.6,14.6L10.6,14.6L10.6,14.6z"
      ></path>
      <path
        class="st1"
        d="M10.5,14.9c-0.2,0-0.3-0.1-0.3-0.3v-0.1c-0.7,0-1.5-0.3-2-0.9c-0.7-0.7-1-1.7-0.9-2.8
                                                                c0.2-1.1,0.1-2.1,0.1-2.6c0-0.1,0-0.3,0-0.3c0-0.1,0-0.4,0.8-0.8C8.5,7,9.1,6.7,9.8,6.6c1.1-0.1,1.8,0.4,2,1.4
                                                                c0.6,2.6,0,3.8-0.3,4.7c-0.1,0.2-0.1,0.3-0.2,0.5v0.1c-0.2,0.5-0.4,1-0.3,1.3C10.9,14.8,10.8,14.9,10.5,14.9
                                                                C10.6,14.9,10.6,14.9,10.5,14.9z M8,8c0,0.1,0,0.1,0,0.2c0,0.5,0.1,1.6-0.1,2.7c-0.1,0.8,0.1,1.7,0.7,2.2c0.4,0.5,1,0.7,1.6,0.7
                                                                c0.1-0.3,0.2-0.6,0.3-0.9v-0.1c0.1-0.2,0.1-0.3,0.2-0.5c0.4-0.8,0.8-1.8,0.3-4.3c-0.1-0.5-0.3-0.7-0.7-0.8C9.5,7.1,8.3,7.8,8,8z"
      ></path>
      <g>
        <path
          class="st1"
          d="M9.8,7.9c0,0.1,0.2,0.4,0.5,0.4s0.5-0.2,0.5-0.3c0-0.1-0.2-0.2-0.5-0.3C10,7.7,9.8,7.8,9.8,7.9L9.8,7.9z"
        ></path>
        <path
          class="st1"
          d="M10.3,8.4C10.3,8.4,10.2,8.4,10.3,8.4c-0.2,0-0.4-0.1-0.5-0.3c0-0.1-0.1-0.2-0.1-0.3l0,0c0,0,0-0.1,0.1-0.1
                                                                    c0.1-0.1,0.3-0.1,0.5-0.1c0.3,0,0.6,0.2,0.5,0.4C10.8,8.2,10.6,8.4,10.3,8.4z M9.9,7.9c0,0.1,0.2,0.3,0.4,0.3s0.4-0.1,0.4-0.2
                                                                    c0,0-0.1-0.1-0.4-0.1C10.1,7.8,9.9,7.9,9.9,7.9z"
        ></path>
      </g>
      <g>
        <path
          class="st1"
          d="M17.9,7.6c0,0.1-0.2,0.4-0.5,0.4s-0.5-0.2-0.5-0.3c0-0.1,0.2-0.2,0.5-0.3C17.6,7.5,17.9,7.5,17.9,7.6
                                                                    L17.9,7.6z"
        ></path>
        <path
          class="st1"
          d="M17.4,8.1c-0.3,0-0.5-0.2-0.5-0.3c0-0.2,0.2-0.3,0.5-0.3s0.5,0,0.6,0.2l0,0c0,0.1,0,0.2-0.1,0.3
                                                                    C17.7,8,17.6,8.1,17.4,8.1L17.4,8.1z M17.5,7.6c0,0-0.1,0-0.2,0c-0.3,0-0.4,0.2-0.4,0.2C17,7.8,17.2,8,17.4,8
                                                                    c0.1,0,0.3-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.1-0.2C17.8,7.6,17.7,7.6,17.5,7.6z"
        ></path>
      </g>
      <path
        class="st1"
        d="M18.3,13.7c-0.1,0-0.1,0-0.2-0.1s-0.2-0.3-0.1-0.4c0.6-0.9,0.5-1.9,0.4-2.8c0-0.4-0.1-0.8-0.1-1.2
                                                                s0.1-0.7,0.1-1.1c0.1-0.4,0.1-0.7,0.1-1.2c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3c0,0.5,0,0.9-0.1,1.3c0,0.3-0.1,0.6-0.1,1
                                                                c0,0.3,0,0.7,0.1,1.1c0.1,1,0.3,2.1-0.5,3.2C18.5,13.6,18.4,13.7,18.3,13.7z"
      ></path>
      <path class="st1" d="M1,3.7"></path>
    </g>
  </g>
</svg>

<svg viewBox="0 0 24 24" *ngIf="name === 'PRESTO'" [attr.class]="getCss()">
  <g>
    <path
      class="st0"
      d="M23,12.1c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4C22.4,10.7,23,11.3,23,12.1"
    ></path>
    <path
      class="st1"
      d="M20.1,14.8c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4S20.1,14.1,20.1,14.8"
    ></path>
    <path
      class="st2"
      d="M17.3,17.7c0,0.8-0.6,1.4-1.4,1.4c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4C16.7,16.3,17.3,16.9,17.3,17.7"
    ></path>
    <path
      class="st0"
      d="M20.1,9.2c0,0.7-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.6-1.3-1.3S18.1,8,18.8,8C19.5,8,20.1,8.5,20.1,9.2"
    ></path>
    <path
      class="st1"
      d="M17.2,12c0,0.7-0.6,1.3-1.3,1.3s-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3C16.7,10.7,17.2,11.3,17.2,12"
    ></path>
    <path class="st2" d="M14.4,14.9c0,0.7-0.6,1.3-1.3,1.3s-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3S14.4,14.2,14.4,14.9"></path>
    <path
      class="st0"
      d="M17.1,6.4c0,0.6-0.5,1.2-1.2,1.2s-1.2-0.5-1.2-1.2c0-0.6,0.5-1.2,1.2-1.2S17.1,5.8,17.1,6.4"
    ></path>
    <path
      class="st1"
      d="M14.3,9.2c0,0.6-0.5,1.2-1.2,1.2c-0.6,0-1.2-0.5-1.2-1.2c0-0.6,0.5-1.2,1.2-1.2C13.8,8.1,14.3,8.6,14.3,9.2"
    ></path>
    <path
      class="st2"
      d="M11.5,12.1c0,0.6-0.5,1.2-1.2,1.2c-0.6,0-1.2-0.5-1.2-1.2c0-0.6,0.5-1.2,1.2-1.2C11,10.9,11.5,11.4,11.5,12.1"
    ></path>
    <path class="st0" d="M14.2,3.6c0,0.6-0.5,1-1,1s-1-0.5-1-1c0-0.6,0.5-1,1-1S14.2,3,14.2,3.6"></path>
    <path class="st1" d="M11.4,6.4c0,0.6-0.5,1-1,1c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1S11.4,5.8,11.4,6.4"></path>
    <path class="st2" d="M8.6,9.3c0,0.6-0.5,1-1,1s-1-0.5-1-1c0-0.6,0.5-1,1-1S8.6,8.7,8.6,9.3"></path>
    <path
      class="st3"
      d="M8.5,14.9c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9S7.1,14,7.6,14C8,13.9,8.5,14.4,8.5,14.9"
    ></path>
    <path
      class="st3"
      d="M11.3,17.7c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C10.8,16.7,11.3,17.2,11.3,17.7"
    ></path>
    <path class="st3" d="M14.1,20.5c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S14.1,20,14.1,20.5"></path>
    <path
      class="st3"
      d="M8.5,20.5c0,0.5-0.4,0.9-0.9,0.9S6.7,21,6.7,20.5s0.4-0.9,0.9-0.9C8.1,19.5,8.5,20,8.5,20.5"
    ></path>
    <path class="st3" d="M2.8,20.5c0,0.5-0.4,0.9-0.9,0.9S1,21,1,20.5s0.4-0.9,0.9-0.9C2.4,19.5,2.8,20,2.8,20.5"></path>
    <path
      class="st3"
      d="M5.6,17.7c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C5.2,16.7,5.6,17.2,5.6,17.7"
    ></path>
    <path class="st1" d="M8.5,3.6c0,0.5-0.4,1-1,1s-1-0.4-1-1c0-0.5,0.4-1,1-1S8.5,3.1,8.5,3.6"></path>
    <path class="st2" d="M5.6,6.5c0,0.5-0.4,1-1,1c-0.5,0-1-0.4-1-1c0-0.5,0.4-1,1-1S5.6,5.9,5.6,6.5"></path>
    <path class="st2" d="M2.7,3.6c0,0.4-0.4,0.8-0.8,0.8S1.1,4,1.1,3.6s0.4-0.8,0.8-0.8S2.7,3.2,2.7,3.6"></path>
  </g>
</svg>

<svg viewBox="0 0 24 24" *ngIf="name === 'TIBERO'" [attr.class]="getCss()">
  <g>
    <polygon class="st0" points="21,5.9 6,5.9 5,10.5 9.1,10.5 6.5,23 13.2,23 15.9,10.5 20,10.5 	"></polygon>
    <polygon class="st1" points="10.9,8.4 3,8.4 4.6,1 12.4,1 	"></polygon>
  </g>
</svg>

<svg viewBox="0 0 24 24" *ngIf="name === 'ORACLE'" [attr.class]="getCss()">
  <g>
    <g>
      <g>
        <path
          class="st0"
          d="M21.5,1.5h-19c-0.6,0-1,0.4-1,1v19c0,0.6,0.4,1,1,1h19c0.6,0,1-0.4,1-1v-19C22.5,1.9,22.1,1.5,21.5,1.5z
          M15.1,17.5H8.9c-3,0-5.4-2.5-5.4-5.5s2.4-5.5,5.4-5.5h6.2c3,0,5.4,2.5,5.4,5.5S18.1,17.5,15.1,17.5z M15,8.4H9
          C7.1,8.4,5.5,10,5.5,12s1.6,3.6,3.5,3.6h6c1.9,0,3.5-1.6,3.5-3.6S16.9,8.4,15,8.4z"
        ></path>
      </g>
    </g>
  </g>
</svg>

<svg viewBox="0 0 24 24" *ngIf="name === 'PHOENIX'" [attr.class]="getCss()">
  <g>
    <g>
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1="2804.7263"
        y1="11.3873"
        x2="2807.6731"
        y2="11.3873"
        gradientTransform="matrix(-0.999 4.430000e-02 -4.430000e-02 -0.999 2818.4199 -103.7887)"
      >
        <stop offset="0" style="stop-color: #f6921d"></stop>
        <stop offset="2.049476e-02" style="stop-color: #f68e1d"></stop>
        <stop offset="0.2346" style="stop-color: #f2651f"></stop>
        <stop offset="0.4443" style="stop-color: #ef4521"></stop>
        <stop offset="0.6456" style="stop-color: #ee2e22"></stop>
        <stop offset="0.8349" style="stop-color: #ec2023"></stop>
        <stop offset="1" style="stop-color: #ec1b23"></stop>
      </linearGradient>
      <path
        class="st0"
        d="M15.8,17c0,0,1-2.9-0.2-6.5c-0.9-2.7-2-3.1-0.1-9.4c0,0-3.7,3.3-2.3,7.1c0.3,0.7,0.2,0.8,1.1,2.2
                                            C15.2,11.9,16,13.7,15.8,17z"
      ></path>

      <linearGradient
        id="SVGID_2_"
        gradientUnits="userSpaceOnUse"
        x1="2805.4341"
        y1="10.2483"
        x2="2809.4353"
        y2="10.2483"
        gradientTransform="matrix(-0.999 4.430000e-02 -4.430000e-02 -0.999 2818.4199 -103.7887)"
      >
        <stop offset="0" style="stop-color: #f6921d"></stop>
        <stop offset="2.049476e-02" style="stop-color: #f68e1d"></stop>
        <stop offset="0.2346" style="stop-color: #f2651f"></stop>
        <stop offset="0.4443" style="stop-color: #ef4521"></stop>
        <stop offset="0.6456" style="stop-color: #ee2e22"></stop>
        <stop offset="0.8349" style="stop-color: #ec2023"></stop>
        <stop offset="1" style="stop-color: #ec1b23"></stop>
      </linearGradient>
      <path
        class="st1"
        d="M15.6,17.5c0,0,0.6-3.1-2.2-7.4c-1.1-1.7-1.6-4.6-0.4-7.1c0,0-3.6,5.2-0.5,8.7c0,0,0.4,0.4,0.7,0.8
                                            C13.3,12.8,15.8,16.2,15.6,17.5z"
      ></path>

      <linearGradient
        id="SVGID_3_"
        gradientUnits="userSpaceOnUse"
        x1="2805.678"
        y1="7.9559"
        x2="2810.354"
        y2="7.9559"
        gradientTransform="matrix(-0.999 4.430000e-02 -4.430000e-02 -0.999 2818.4199 -103.7887)"
      >
        <stop offset="0" style="stop-color: #f6921d"></stop>
        <stop offset="2.049476e-02" style="stop-color: #f68e1d"></stop>
        <stop offset="0.2346" style="stop-color: #f2651f"></stop>
        <stop offset="0.4443" style="stop-color: #ef4521"></stop>
        <stop offset="0.6456" style="stop-color: #ee2e22"></stop>
        <stop offset="0.8349" style="stop-color: #ec2023"></stop>
        <stop offset="1" style="stop-color: #ec1b23"></stop>
      </linearGradient>
      <path
        class="st2"
        d="M15.4,17.7c0,0,0-0.4-0.3-1c-0.4-0.9-1.4-3-2.8-4.3c-2.1-1.9-1.6-4.7-1.6-4.7c-0.8,4.7,0.5,5.9,1.3,6.4
                                            C13.9,15.4,14.9,16.3,15.4,17.7z"
      ></path>
    </g>

    <linearGradient
      id="SVGID_4_"
      gradientUnits="userSpaceOnUse"
      x1="4.5"
      y1="12.5"
      x2="19.5"
      y2="12.5"
      gradientTransform="matrix(1 0 0 -1 0 24)"
    >
      <stop offset="0" style="stop-color: #f6921d"></stop>
      <stop offset="2.049476e-02" style="stop-color: #f68e1d"></stop>
      <stop offset="0.2346" style="stop-color: #f2651f"></stop>
      <stop offset="0.4443" style="stop-color: #ef4521"></stop>
      <stop offset="0.6456" style="stop-color: #ee2e22"></stop>
      <stop offset="0.8349" style="stop-color: #ec2023"></stop>
      <stop offset="1" style="stop-color: #ec1b23"></stop>
    </linearGradient>
    <path
      class="st3"
      d="M19.2,12.6c-1-3.8-3.2-6.9-0.1-12.5c0,0-6,2.1-3.3,9.9c1.7,4.8,1.2,7.5-0.3,9c-0.6,0.6-1.7,1.4-3.5,1.2
                                        C10.2,20,9.2,19,8.4,18c-1.1-1.5-1.5-3.6,0-6.7c0.9-2,1.4-3.7,1.4-3.7C9.9,7.2,10,7,10.1,6.7c0.7,0.1,1-0.5,1-0.5
                                        c-0.3,0.1-0.4-0.1-0.4-0.1c0.6-0.3,0.4-0.8,0.4-0.8c-0.3,0.2-0.7,0.1-0.7,0.1s-0.7,0-0.8,0c-1.3-1-2.2-0.8-2.7-0.4
                                        C6.8,5.2,6.7,5.2,6.6,5.4C6.4,5.6,6.3,5.8,6.3,5.8S5,6,4.6,6.8c0,0,0.9-0.6,1.7-0.3c0.1,0.1,0.5,0.4,0.8,1.2c0.2,0.4,0,1.4-0.2,1.7
                                        c-1.1,2.1-2.4,3.6-2.4,5.7c0,1.9,0.7,3.7,1.8,5c2.3,2.9,5.9,2.9,6,2.9c4.3,0,7.3-3.5,7.3-7.9C19.5,14.2,19.4,13.2,19.2,12.6z"
    ></path>
  </g>
</svg>

<svg *ngIf="name === 'CSV' && !isBlackAndWhite" [attr.class]="getCss()">
  <g>
    <rect x="10" y="13" class="st0" width="3" height="1"></rect>
    <rect x="10" y="11" class="st0" width="4" height="1"></rect>
    <rect x="9" y="12" class="st0" width="1" height="1"></rect>
    <rect x="4" y="12" class="st0" width="1" height="4"></rect>
    <rect x="5" y="11" class="st0" width="3" height="1"></rect>
    <path class="st0" d="M19,7h-3.5C15.2,7,15,6.8,15,6.5V3H5.3C5.1,3,5,3.1,5,3.3V9h14V7z"></path>
    <polygon class="st0" points="16,3.4 16,6 18.5,6 	"></polygon>
    <rect x="13" y="14" class="st0" width="1" height="2"></rect>
    <rect x="9" y="16" class="st0" width="4" height="1"></rect>
    <rect x="19" y="11" class="st0" width="1" height="2"></rect>
    <rect x="18" y="13" class="st0" width="1" height="2"></rect>
    <rect x="5" y="16" class="st0" width="3" height="1"></rect>
    <rect x="15" y="11" class="st0" width="1" height="2"></rect>
    <rect x="17" y="15" class="st0" width="1" height="2"></rect>
    <rect x="16" y="13" class="st0" width="1" height="2"></rect>
    <path class="st0" d="M5,20.7C5,20.9,5.1,21,5.3,21h13.4c0.2,0,0.3-0.1,0.3-0.3V19H5V20.7z"></path>
    <path
      class="st1"
      d="M21.5,9H20V6.5c0-0.4-0.2-0.6-0.4-0.8l-3.2-3.3C16.2,2.2,15.8,2,15.5,2H5.3C4.6,2,4,2.6,4,3.3V9H2.6
                                                C1.7,9,1,9.7,1,10.5v7C1,18.4,1.7,19,2.6,19h1.5v1.7c0,0.7,0.6,1.3,1.3,1.3h13.4c0.7,0,1.3-0.6,1.3-1.3V19h1.4
                                                c0.9,0,1.5-0.7,1.5-1.5v-7C23,9.7,22.3,9,21.5,9z M16,3.4L18.5,6H16V3.4z M5,3.3C5,3.1,5.1,3,5.3,3H15v3.5C15,6.8,15.2,7,15.5,7H19
                                                v2H5V3.3z M13,13v1h1v2h-1v1H9v-1h4v-2h-3v-1H9v-1h1v-1h4v1h-4v1H13z M4,16v-4h1v-1h3v1H5v4h3v1H5v-1H4z M19,20.7
                                                c0,0.2-0.1,0.3-0.3,0.3H5.3C5.1,21,5,20.9,5,20.7V19h14V20.7z M20,13h-1v2h-1v2h-1v-2h-1v-2h-1v-2h1v2h1v2h1v-2h1v-2h1V13z"
    ></path>
  </g>
</svg>

<svg *ngIf="name === 'CSV' && isBlackAndWhite" [attr.class]="getCss()">
  <g>
    <rect x="10" y="13" class="st0" width="3" height="1" />
    <rect x="10" y="11" class="st0" width="4" height="1" />
    <rect x="9" y="12" class="st0" width="1" height="1" />
    <rect x="4" y="12" class="st0" width="1" height="4" />
    <rect x="5" y="11" class="st0" width="3" height="1" />
    <path class="st0" d="M19,7h-3.5C15.2,7,15,6.8,15,6.5V3H5.3C5.1,3,5,3.1,5,3.3V9h14V7z" />
    <polygon class="st0" points="16,3.4 16,6 18.5,6 	" />
    <rect x="13" y="14" class="st0" width="1" height="2" />
    <rect x="9" y="16" class="st0" width="4" height="1" />
    <rect x="19" y="11" class="st0" width="1" height="2" />
    <rect x="18" y="13" class="st0" width="1" height="2" />
    <rect x="5" y="16" class="st0" width="3" height="1" />
    <rect x="15" y="11" class="st0" width="1" height="2" />
    <rect x="17" y="15" class="st0" width="1" height="2" />
    <rect x="16" y="13" class="st0" width="1" height="2" />
    <path class="st0" d="M5,20.7C5,20.9,5.1,21,5.3,21h13.4c0.2,0,0.3-0.1,0.3-0.3V19H5V20.7z" />
    <path
      class="st1"
      d="M21.5,9H20V6.5c0-0.4-0.2-0.6-0.4-0.8l-3.2-3.3C16.2,2.2,15.8,2,15.5,2H5.3C4.6,2,4,2.6,4,3.3V9H2.6
                                            C1.7,9,1,9.7,1,10.5v7C1,18.4,1.7,19,2.6,19h1.5v1.7c0,0.7,0.6,1.3,1.3,1.3h13.4c0.7,0,1.3-0.6,1.3-1.3V19h1.4
                                            c0.9,0,1.5-0.7,1.5-1.5v-7C23,9.7,22.3,9,21.5,9z M16,3.4L18.5,6H16V3.4z M5,3.3C5,3.1,5.1,3,5.3,3H15v3.5C15,6.8,15.2,7,15.5,7H19
                                            v2H5V3.3z M13,13v1h1v2h-1v1H9v-1h4v-2h-3v-1H9v-1h1v-1h4v1h-4v1H13z M4,16v-4h1v-1h3v1H5v4h3v1H5v-1H4z M19,20.7
                                            c0,0.2-0.1,0.3-0.3,0.3H5.3C5.1,21,5,20.9,5,20.7V19h14V20.7z M20,13h-1v2h-1v2h-1v-2h-1v-2h-1v-2h1v2h1v2h1v-2h1v-2h1V13z"
    />
  </g>
</svg>

<svg *ngIf="name === 'JSON' && !isBlackAndWhite" [attr.class]="getCss()">
  <g>
    <rect x="10" y="14" class="st0" width="1" height="2"></rect>
    <rect x="15" y="12" class="st0" width="1" height="4"></rect>
    <rect x="13" y="11" class="st0" width="2" height="1"></rect>
    <rect x="13" y="16" class="st0" width="2" height="1"></rect>
    <polygon class="st0" points="16,3.4 16,6 18.5,6 	"></polygon>
    <path class="st0" d="M19,7h-3.5C15.2,7,15,6.8,15,6.5V3H5.3C5.1,3,5,3.1,5,3.3V9h14V7z"></path>
    <rect x="7" y="16" class="st0" width="3" height="1"></rect>
    <rect x="12" y="12" class="st0" width="1" height="4"></rect>
    <polygon class="st0" points="18,13 18,12 18,11 17,11 17,12 17,17 18,17 18,14 19,14 19,13 	"></polygon>
    <polygon class="st0" points="20,14 19,14 19,15 20,15 20,17 21,17 21,11 20,11 	"></polygon>
    <path class="st0" d="M5,20.7C5,20.9,5.1,21,5.3,21h13.4c0.2,0,0.3-0.1,0.3-0.3V19H5V20.7z"></path>
    <polygon class="st0" points="6,17 6,12 6,11 3,11 3,12 5,12 5,16 3,16 3,17 5,17 	"></polygon>
    <rect x="8" y="11" class="st0" width="3" height="1"></rect>
    <rect x="8" y="13" class="st0" width="2" height="1"></rect>
    <rect x="7" y="12" class="st0" width="1" height="1"></rect>
    <rect x="13" y="12" class="st1" width="2" height="4"></rect>
    <path
      class="st1"
      d="M21.5,9H20V6.5c0-0.4-0.2-0.6-0.4-0.8l-3.2-3.3C16.2,2.2,15.8,2,15.5,2H5.3C4.6,2,4,2.6,4,3.3V9H2.6
                                            C1.7,9,1,9.7,1,10.5v7C1,18.4,1.7,19,2.6,19h1.5v1.7c0,0.7,0.6,1.3,1.3,1.3h13.4c0.7,0,1.3-0.6,1.3-1.3V19h1.4
                                            c0.9,0,1.5-0.7,1.5-1.5v-7C23,9.7,22.3,9,21.5,9z M16,3.4L18.5,6H16V3.4z M5,3.3C5,3.1,5.1,3,5.3,3H15v3.5C15,6.8,15.2,7,15.5,7H19
                                            v2H5V3.3z M12,16v-4h1v-1h2v1h1v4h-1v1h-2v-1H12z M10,13v1h1v2h-1v1H7v-1h3v-2H8v-1H7v-1h1v-1h3v1H8v1H10z M3,17v-1h2v-4H3v-1h3v1
                                            v5H5H3z M19,20.7c0,0.2-0.1,0.3-0.3,0.3H5.3C5.1,21,5,20.9,5,20.7V19h14V20.7z M21,17h-1v-2h-1v-1h-1v3h-1v-5v-1h1v1v1h1v1h1v-3h1
                                            V17z"
    ></path>
  </g>
</svg>

<svg *ngIf="name === 'JSON' && isBlackAndWhite" [attr.class]="getCss()">
  <g>
    <rect x="10" y="14" class="st0" width="1" height="2"></rect>
    <rect x="15" y="12" class="st0" width="1" height="4"></rect>
    <rect x="13" y="11" class="st0" width="2" height="1"></rect>
    <rect x="13" y="16" class="st0" width="2" height="1"></rect>
    <polygon class="st0" points="16,3.4 16,6 18.5,6 	"></polygon>
    <path class="st0" d="M19,7h-3.5C15.2,7,15,6.8,15,6.5V3H5.3C5.1,3,5,3.1,5,3.3V9h14V7z"></path>
    <rect x="7" y="16" class="st0" width="3" height="1"></rect>
    <rect x="12" y="12" class="st0" width="1" height="4"></rect>
    <polygon class="st0" points="18,13 18,12 18,11 17,11 17,12 17,17 18,17 18,14 19,14 19,13 	"></polygon>
    <polygon class="st0" points="20,14 19,14 19,15 20,15 20,17 21,17 21,11 20,11 	"></polygon>
    <path class="st0" d="M5,20.7C5,20.9,5.1,21,5.3,21h13.4c0.2,0,0.3-0.1,0.3-0.3V19H5V20.7z"></path>
    <polygon class="st0" points="6,17 6,12 6,11 3,11 3,12 5,12 5,16 3,16 3,17 5,17 	"></polygon>
    <rect x="8" y="11" class="st0" width="3" height="1"></rect>
    <rect x="8" y="13" class="st0" width="2" height="1"></rect>
    <rect x="7" y="12" class="st0" width="1" height="1"></rect>
    <rect x="13" y="12" class="st1" width="2" height="4"></rect>
    <path
      class="st1"
      d="M21.5,9H20V6.5c0-0.4-0.2-0.6-0.4-0.8l-3.2-3.3C16.2,2.2,15.8,2,15.5,2H5.3C4.6,2,4,2.6,4,3.3V9H2.6
                                            C1.7,9,1,9.7,1,10.5v7C1,18.4,1.7,19,2.6,19h1.5v1.7c0,0.7,0.6,1.3,1.3,1.3h13.4c0.7,0,1.3-0.6,1.3-1.3V19h1.4
                                            c0.9,0,1.5-0.7,1.5-1.5v-7C23,9.7,22.3,9,21.5,9z M16,3.4L18.5,6H16V3.4z M5,3.3C5,3.1,5.1,3,5.3,3H15v3.5C15,6.8,15.2,7,15.5,7H19
                                            v2H5V3.3z M12,16v-4h1v-1h2v1h1v4h-1v1h-2v-1H12z M10,13v1h1v2h-1v1H7v-1h3v-2H8v-1H7v-1h1v-1h3v1H8v1H10z M3,17v-1h2v-4H3v-1h3v1
                                            v5H5H3z M19,20.7c0,0.2-0.1,0.3-0.3,0.3H5.3C5.1,21,5,20.9,5,20.7V19h14V20.7z M21,17h-1v-2h-1v-1h-1v3h-1v-5v-1h1v1v1h1v1h1v-3h1
                                            V17z"
    ></path>
  </g>
</svg>

<svg *ngIf="name === 'EXCEL'" [attr.class]="getCss()">
  <g>
    <g>
      <path
        class="st0"
        d="M21.5,9H20V6.5c0-0.4-0.2-0.6-0.4-0.8l-3.2-3.3C16.2,2.1,15.8,2,15.5,2H5.3C4.6,2,4,2.6,4,3.3V9H2.6
                                                C1.7,9,1,9.7,1,10.5v6.9C1,18.3,1.7,19,2.6,19H4v1.7C4,21.4,4.6,22,5.3,22h13.4c0.7,0,1.3-0.6,1.3-1.3V19h1.5
                                                c0.9,0,1.5-0.7,1.5-1.5v-6.9C23,9.7,22.3,9,21.5,9z M18.5,6H16V3.4L18.5,6z M5,3.3C5,3.1,5.1,3,5.3,3H15v3.5C15,6.8,15.2,7,15.5,7
                                                H19v2H5V3.3z M14,16v1h-4v-6h1v5H14z M5,17H4v-2h1v-2H4v-2h1v2h2v-2h1v2H7v2h1v2H7v-2H5V17z M19,20.7c0,0.2-0.1,0.3-0.3,0.3H5.3
                                                C5.1,21,5,20.9,5,20.7V19h14V20.7z M19,12h-3v1h2v1h1v2h-1v1h-3v-1h3v-2h-2v-1h-1v-1h1v-1h3V12z"
      />
    </g>
  </g>
</svg>

<svg *ngIf="name === 'icon-db-druid'" class="icon-db-druid">
  <g>
    <path class="st0" d="M7.5,5h8.3c0,0,5.7,0.2,5.7,6.4c0,5.2-4.3,7.6-6.5,7.6c-0.9,0-3.8,0-3.8,0" />
    <path class="st0" d="M6.4,8.1h7.8c0,0,3.8,0,3.8,3.5c0,3.9-3.8,4.4-5.4,4.4c-0.7,0-7.6,0-7.6,0" />
    <line class="st0" x1="8.8" y1="19" x2="7.4" y2="19" />
    <line class="st0" x1="4.2" y1="8.1" x2="2.7" y2="8.1" />
  </g>
</svg>

<svg *ngIf="name === 'icon-db-hive'" class="icon-db-hive">
  <g id="Layer_3">
    <g transform="translate(-.53268 66.8)">
      <path
        class="st0"
        d="M14.2-63.1c-0.2-0.6-7.3-3.3-7.4-3c-1.3,0.3-1.8,1.8-2.7,2.8l-2-0.1c-0.9,1.1-1.3,2.3-1,3.5
                                    c1.1,1.5,2.4,2.8,3.2,4.7c0.1,0.9,3.9,0.1,4.7-0.1c-1.1,0.8-2.1,2-1.7,5.6c0.4,1.7,0.6,3.6,4,5.5c0.4,0.3,1.4,0.5,1.9,0.7
                                    c0.5,0.3,1.2,0.4,3-0.2c1-0.4,2.2-0.7,3.2-1.2l-1.2,0.1c-1.7,0-3,0.2-3.4-0.3l-1.6-2.7l0.9-1.8c1.3,0.1,1.7,1.1,2.8,1.5l1.3-1
                                    c3.8,2.2,5.3-1.4,5.7-4.3c0-1.2-2.5,0.4-2.4-0.2c0.1-1.2-1-2.9-1.6-4.1l0.8-2.9c0.3-0.3-1.6-3.6-3.2-4
                                    C16.3-64.9,14.2-63.1,14.2-63.1L14.2-63.1z"
      />
    </g>
    <g>
      <g transform="translate(-.53268 66.8)">
        <path
          class="st1"
          d="M20.5-56.9c0,0.3,0,0.7,0,0.9s-0.1,0.2-0.3,0.2l0.7,0.3c0.1,0.2,0.3,0.5,0.4,0.7c0.1,0.6,0,0.6-0.1,0.7
                                        c-0.3,0-0.5,0-0.8,0c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.3-0.2,0.5c0.2,0.1,0.6,0.2,0.9,0.4l0.3-0.7
                                        C21.5-54.9,21.1-55.9,20.5-56.9L20.5-56.9z"
        />
        <path
          class="st1"
          d="M21.8-53.2c0.4,0.2,1,0.1,1.8-0.3c0.1-0.1,0.2,0,0,0.1C22.6-52.4,22.1-52.9,21.8-53.2L21.8-53.2z"
        />
        <path
          class="st1"
          d="M23.7-53.6c0.1,0.2,0,0.4,0,0.6c-0.1,1-0.4,1.8-1,2.6c-2,3.2-4,1.2-5.6-0.1l-0.7,1.7c0,0.1-0.2,0.3,0.9,0.7
                                        l1.1-0.7C22.3-45.8,25.1-53.1,23.7-53.6L23.7-53.6z"
        />
        <path class="st1" d="M17.5-50.7c-0.3,0-1.1,0.3-1.1,0.5l0.4-0.6L17.5-50.7z" />
        <path
          class="st1"
          d="M17-54.1c0,0,0.3,0.1,0.3,0c0,0,0,0.3,0.1,0.5L17-52.9c0.6-0.6,1.4-0.6,2.3-0.7l-0.4-0.2
                                        c0.1-0.2,0-0.4,0-0.6L17-54.1z"
        />
        <path
          class="st1"
          d="M18.4-56c-0.6,0.2-1.2,0.5-1.6,1.1c0.3-1.1,0.4-1,0.4-1.1C17.7-56.1,18-56,18.4-56L18.4-56z"
        />
      </g>
      <g transform="translate(-.53268 66.8)">
        <path
          class="st2"
          d="M15.2-43.7c-0.7,0.8-2.3-0.1-3.7-0.5c-3.4-2.2-4.1-4.2-4.1-6.2c-0.2-2.9,0.7-4.5,1.8-4.9
                                        c-0.7,1.4-1.1,4-0.7,5.9c0.3,1.1,0.3,2.8,2,3.8c0.9,0.5,0.6,0.9,1.2,1.3C12.4-43.9,14.1-43.4,15.2-43.7L15.2-43.7z"
        />
        <path
          class="st2"
          d="M16.2-56.9c-1.2-1.7-2.9-2.4-4.9-2.5c0.4-0.1,0.8-0.2,1.2-0.4c0.1-0.1,0.1-0.3,0-0.5c-1.9-0.5-3-1.1-4.5-1.6
                                        l4,1C14.8-60.8,14.5-60.1,16.2-56.9L16.2-56.9z"
        />
        <path
          class="st2"
          d="M17.6-57.7c-0.9-1.3-1.8-2.9-3-3.9c-2.9-1.3-5.7-2.3-9-3.1l1.1-1.4c3.5,0.8,6.9,2.2,9.8,4.4
                                        C16.9-60.4,17.3-59.2,17.6-57.7L17.6-57.7z"
        />
        <path
          class="st2"
          d="M19.4-60c0,0-0.5-1.1-0.8-1.6c-0.6-0.6-0.9-1.8-2-2.4c0.4,0.1,0.6,0,1.4,0.7l1.2,2.3L19.4-60z"
        />
        <path
          class="st2"
          d="M20-58.1c0.1-1,0.3-2.6,0.1-3.2c-0.7-1-1.4-1.9-2.1-2.8c-0.1-0.1-0.2-0.3-0.3-0.4c1,0.3,2.1,1,3.5,3.7
                                        L20-58.1z"
        />
      </g>
      <g transform="translate(-.53268 66.8)">
        <path
          d="M15.8-57.9c-0.4-0.3-0.8-1.4-1.4-1.7c-0.6,0-0.8-0.2-1.5-0.1c0.3-0.1,0.5-0.3,0.8-0.4c0.2,0,0.4,0,0.6,0
                                        c0,0,0.1,0,0.1-0.1c-0.7-0.5-2.3-0.5-3.3-0.7c1.2,0,2.5,0,3.4,0.3C15.2-60,15.4-58.8,15.8-57.9L15.8-57.9z"
        />
        <path
          d="M18-63.3c0.2,0,1.1,1.4,1.1,1.7c0.1,0.5,0.3,1.1,0.3,1.6c-0.1-0.5-0.3-1-0.5-1.5c-0.1-0.1-0.2-0.5-0.6-0.9
                                        C18.1-62.8,18-63.1,18-63.3L18-63.3z"
        />
        <path d="M21-55.6h-0.4H21L21-55.6z" />
        <path d="M18.4-56c-0.4,0-0.8-0.1-1.2,0C17-55.8,17-55.6,17-55.4C17.5-55.9,17.6-55.9,18.4-56L18.4-56z" />
        <path d="M23.6-53.4c-0.2,0.2-0.2,0.3-0.9,0.5c-0.5,0.1-0.7-0.1-0.9-0.3c0.3,0.1,0.4,0.3,1.3,0.1L23.6-53.4z" />
        <path
          d="M21.7-53.3c-0.2,0.4-0.3,0.8-0.5,1.2c-0.4,0.5-0.2,0.2-0.8,0.9c0.2-0.3,0.4-0.6,0.6-0.9c0.1-0.2,0.2-0.4,0.3-0.6
                                        c-0.2-0.1-0.5-0.1-0.5-0.1c-0.5,0.3-0.6,0.6-0.9,1c0.2-0.4,0.4-0.8,0.7-1.1c0,0,0.2-0.1,0.3-0.1c-0.2,0-0.5-0.1-0.6-0.1
                                        c-0.3,0.1-0.5,0.4-0.8,0.6c0.2-0.3,0.4-0.5,0.6-0.8c0,0,0.7,0,1,0.2v0.1l0.2,0.1l0.1-0.4L21.7-53.3z"
        />
        <path d="M17.5-50.7c-0.1,0-0.8-0.1-0.8-0.1c-0.3,0.2-0.3,0.4-0.3,0.6C16.8-50.7,16.8-50.7,17.5-50.7L17.5-50.7z" />
        <path
          d="M16.9-54.1c0,0,0.5-0.1,0.5,0c0,0.2,0.1,0.6,0.1,0.6l-0.3,0.2c0.6-0.3,1.3-0.3,2-0.3c0,0-0.6-0.2-0.5-0.2s0-0.6-0.1-0.6
                                        c0.1,0,0.2-0.1,0.3-0.1C18.1-54.7,17.7-54.6,16.9-54.1L16.9-54.1z"
        />
        <path
          d="M21.6-53.7l-0.1,0.1c0-0.4-0.4-0.3-0.7-0.3L20.5-54c0.1,0.1,0.3,0.1,0.2,0.2c-0.1,0-0.1,0.2-0.2,0.4h-0.1
                                        c0.3,0.1,0.5,0.2,0.8,0.3h0.2l0.4-0.2L21.6-53.7z"
        />
      </g>
      <g transform="translate(-.53268 66.8)">
        <path
          class="st3"
          d="M18-53.9c-0.1-0.1-0.4-0.1-0.4,0c-0.1,0.1,0,0.5,0.1,0.4s0.2-0.1,0.4-0.1C18.1-53.8,18.1-53.8,18-53.9
                                        L18-53.9z"
        />
        <path
          class="st3"
          d="M21-53.3c0,0,0.1-0.2,0.1-0.3s-0.3-0.1-0.3-0.1c-0.1,0-0.1,0.3-0.1,0.3C20.6-53.3,20.9-53.3,21-53.3L21-53.3
                                        z"
        />
      </g>
      <g transform="translate(-.53268 66.8)">
        <path d="M8.8-46.3H9c1.1,0.5,2.8,1.3,5.4,0.9l0.4,0.6c-1.1,0.5-2.3,0.4-3.5,0.5L8.8-46.3z" />
        <path d="M7.2-50.4l0.5,2.2c1.4,0.9,3.9,1.9,5.9,1.8l-0.5-1C9-48.3,8.6-49.4,7.2-50.4L7.2-50.4z" />
        <path
          d="M8.6-55.1c0.3,1.7,0.6,3.1,2.1,4.2c0.9,0.7,1.9,1.3,3,1.9c0,0-0.1,0.5-0.2,0.5c-3.3-0.4-5.8-3.1-6.1-4.4
                                        C7.7-53.9,8.1-54.5,8.6-55.1L8.6-55.1z"
        />
        <path
          d="M10.2-56c0.4,1.9,1.5,3.9,2.6,5.7c0.4,0.5,0.5,0.7,1.1,1c0.8,0.3,1.4,0.2,2,0.2c-0.2-0.3-0.3-0.6-0.5-0.9
                                        c-1.3-1-0.7-2-0.4-2.6c-0.7-0.2-1.7-0.6-1.8-1.1c-0.2-1.7-0.1-2.2,0.1-3C12.2-56.6,11.2-56.4,10.2-56L10.2-56z"
        />
        <path
          class="st4"
          d="M6.6-66.3c-0.3,0.2-0.6,0.5-1,0.9c-0.6,0.6-0.9,1.1-1.5,1.6c-0.1,0.1-0.4,0.3-0.8,0.3c-0.2,0-0.3,0-0.7,0
                                        c-0.3-0.2-0.6-0.1-0.9,0.3C1.4-62.9,1-62,0.9-61.5c-0.3,1.4,0.5,2.5,1.3,3.3s1.2,1.2,1.5,1.9c0.2,0.4,0.4,1,0.6,1.4
                                        c0.1,0.1,0.1,0.1,0.2,0.2c0.4,0.1,0.9,0.1,1.4,0.1c0.2,0,0.5,0,0.8,0c0.4-0.1,0.8-0.1,1.2-0.3c0.4-0.1,0.7-0.2,0.9-0.3
                                        c0,0.1-0.3,0.2-0.4,0.4c-1,1.4-1.2,2.6-1.1,4.5c0.1,0.9,0.3,1.6,0.6,2.4c0.1,0.4,0.4,1,0.7,1.3c0.8,1.2,2.7,2.7,5.2,3.2
                                        c0.4,0.1,1,0,1.4-0.1c1.3-0.4,3.8-1.3,3.8-1.3s-2.3,0.2-3.3,0.1c-0.2,0-0.5-0.1-0.7-0.2l-0.1-0.2c0.1,0,0.4-0.1,0.8-0.1
                                        c-0.9-0.1-0.8-0.1-0.9-0.2c-0.1-0.2-0.2-0.6-0.4-0.9c0.4,0,1.2,0.1,1.5-0.2c0,0-0.5,0.1-0.9,0c-0.1,0-0.4-0.1-0.5-0.1
                                        c-0.2-0.1-0.4-0.1-0.4-0.1c-0.1-0.2-0.1-0.2-0.2-0.5c-0.1-0.3-0.1-0.6-0.1-1c0.3,0.4,0.6,0.6,1.1,0.8c0,0,0.6,0.3,1.1,0.1h0.1
                                        c0,0-0.3,0-0.4,0c-0.9-0.4-1.1-0.7-1.2-0.9l-0.4-0.6c0.1-0.2,0.2-0.2,0.3-0.3c0.5,0.1,0.7,0.1,1,0c0.2,0.4,0.3,0.8,0.7,1.1
                                        c1.5,0.4,1.8-0.1,2.2-0.6c1.1,0.8,2.8,1,4.1,0c1.5-1.8,2-4.6,1.9-4.8c-0.2-0.3-0.4-0.7-0.7-0.6c-0.8,0.2-1.1,0.6-1.8,0.5
                                        c0.1,0,0.2,0,0.2,0c0.1-0.7,0-1,0-1c-0.2-0.5-0.5-1.1-0.8-1.5c-0.1-0.1-0.2-0.7-0.5-1c-0.1-0.1-0.4-0.4-0.4-0.4v0.3
                                        c0,0,0.1,0,0.2,0.2c0.2,0.7,1,2.2,1,2.3c0.3,0.5,0,1.1,0.2,1.4c0,0,0.4,0,0.8,0c0.5-0.1,0.5-0.4,1-0.4c0.3,0,0.3,0.6,0.3,0.6
                                        c-0.1,0.7-0.3,1.4-0.6,2.1c-0.6,1.2-1.4,2.3-2.4,2.5c-1.2,0.2-1.9-0.3-2.5-0.7L18-48.9c-0.9,0.9-1.9,0.8-2.3-0.3
                                        c-0.2-0.4-0.5-0.7-0.7-1.1l-1.2,0.9c-0.1,0.2-0.2,0.5-0.4,0.9c-0.1,0.3-0.2,0.7-0.2,1.1c-0.2,0.3,0.6,1.4,1,2
                                        c0.1,0.2,0.4,0.5,0.4,0.6c0.1,0.2,0.3,0.4,0.3,0.5c0.8,1.1-1.1,0.8-1.4,0.8c-0.8-0.1-1.5-0.4-2.2-0.9l-0.1-0.1
                                        c-0.8-0.5-1.6-1.2-2.3-1.9c-0.4-0.4-0.8-1.4-1-2c-0.4-1.6-1-4.2,0.6-6.3c0-0.1,0.1-0.3,0.2-0.3c0.5-0.3,1-0.5,1.6-0.7l-0.1-0.4
                                        C9.9-56,9-55.7,8.7-55.5c-0.6,0.2-1.1,0.4-1.9,0.6c-0.3,0-0.5,0-0.7,0C5.6-55,4.6-55,4.6-55c-0.4-0.5-0.5-1.5-0.8-2l0,0l0,0
                                        c-0.2-0.3-0.4-0.5-0.7-0.7c-0.8-0.8-1.5-1.6-1.7-2.4c0-0.2-0.2-0.5-0.1-1.2l0,0l0,0c0.2-0.7,0.5-1.2,1.1-1.8c0.6,0,1.1,0,1.6,0.1
                                        c0.2,0,0.6,0.1,1,0.2c1.1,0.4,2.5,1.1,2.5,1.1c-1.1-0.6-2.3-1.4-3.1-1.5c-0.1,0-0.2-0.1-0.2-0.2c1.1-0.7,1.4-1.5,2.1-2.2
                                        c0.3-0.2,0.5-0.2,0.8-0.3c2.7,0.4,4.4,1.5,5.8,2.2c0.5,0.3,1,0.5,1.5,0.8c0.4,0.1,1.7,1.1,2,1.6c0.4,0.8,0.6,1.6,0.9,2.5
                                        c0.2,0.8,0.3,1.2,0.3,1.2s-0.2-0.7-0.1-0.8c0.2,0.1,0.5,0.2,0.7,0.2c0,0-0.7-0.4-0.8-0.7c-0.3-1-0.6-2.6-0.7-2.7
                                        c-0.2-0.3-1.1-1-1.7-1.3c-0.2-0.1-0.3-0.2-0.3-0.3c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.1,0.4-0.3,0.6-0.4c1.2-0.5,1.8,0.2,2,0.1
                                        c0,0-0.3-0.3-0.1-0.2c0.1,0.1,0.5,0.1,0.5,0.2c0.4,0.3,1.5,1.6,2.2,2.8c0.2,0.3,0.2,0.5,0.2,0.9c-0.1,0.4-0.1,0.6-0.2,0.8
                                        S20-58.3,20-58.1c-0.1,0.6,0.3,1.4,0.3,1.4c0-0.2,0-0.3,0-0.5v-0.3v-0.1c0-0.2,0.1-0.4,0.1-0.5c0.1-0.8,0.4-1.4,0.6-2.2
                                        c0.1-0.2,0.2-0.3,0.2-0.4c0-0.2-0.2-0.6-0.4-0.9c-0.2-0.3-0.4-0.7-0.6-1.1c-0.6-0.8-1.1-1.5-2-1.9c-0.3-0.1-1.3-0.2-1.6-0.2
                                        c-0.4,0.1-0.8,0.2-1.1,0.4c-0.5,0.3-0.8,0.7-1.2,1c-0.9-0.5-1.4-0.8-1.5-0.9c-0.5-0.3-1.2-0.6-1.9-1C10.6-65.4,8.5-66.1,6.6-66.3
                                        L6.6-66.3z M18.4-49.1c-0.6-0.4-1-0.9-1.4-1.4c-0.1,0.6-0.5,0.9-0.8,1.3c-0.1,0.1-0.1,0.2,0.2,0.6c0.1,0.1,0.4,0.1,0.5,0.1
                                        c-0.2-0.1-0.5-0.3-0.5-0.4c0.3,0.2,0.6,0.3,0.9,0.3c0.1,0,0.1-0.1,0.2-0.2c0.1-0.3,0.2-0.3,0.3-0.4l0.2,0.3L18.4-49.1z"
        />
      </g>
    </g>
  </g>
</svg>

<svg *ngIf="name === 'icon-db-mysql'" class="icon-db-mysql" style="display: ">
  <g>
    <g>
      <g>
        <path
          class="st0"
          d="M23.3,23c-0.2-0.2-0.3-0.3-0.5-0.5c-0.5-0.6-1-1.2-1.6-1.6c-0.5-0.4-1.6-0.8-1.8-1.4l0,0
                                                                        c0.3,0,0.8-0.2,1.1-0.3c0.5-0.1,1-0.1,1.6-0.3c0.2-0.1,0.5-0.1,0.7-0.2v-0.1c-0.3-0.3-0.5-0.7-0.8-0.9c-0.8-0.7-1.7-1.4-2.6-2
                                                                        c-0.5-0.3-1.1-0.5-1.6-0.8c-0.2-0.1-0.5-0.1-0.6-0.3c-0.3-0.4-0.4-0.8-0.6-1.2c-0.4-0.9-0.9-1.8-1.3-2.8C15,10,14.9,9.3,14.5,8.8
                                                                        C12.8,6,11,4.4,8.3,2.8C7.7,2.5,7,2.3,6.3,2.1c-0.4,0-0.8,0-1.2-0.1C4.9,1.9,4.7,1.6,4.5,1.5C3.6,0.9,1.3-0.3,0.6,1.3
                                                                        c-0.4,1,0.6,2.1,1,2.6c0.3,0.4,0.6,0.8,0.8,1.2c0.1,0.3,0.1,0.5,0.3,0.8C3,6.6,3.2,7.4,3.5,8c0.2,0.3,0.4,0.7,0.6,1
                                                                        c0.1,0.2,0.4,0.3,0.4,0.5c-0.2,0.3-0.2,0.8-0.4,1.2c-0.6,1.8-0.3,4,0.5,5.3c0.2,0.4,0.8,1.3,1.6,0.9c0.7-0.3,0.5-1.2,0.7-2
                                                                        c0-0.2,0-0.3,0.1-0.4l0,0c0.2,0.4,0.4,0.9,0.6,1.3c0.5,0.8,1.3,1.6,2,2.1c0.4,0.3,0.7,0.8,1.1,0.9l0,0l0,0
                                                                        c-0.1-0.1-0.2-0.2-0.4-0.3c-0.3-0.3-0.6-0.6-0.8-0.9c-0.6-0.9-1.2-1.9-1.7-2.9c-0.3-0.5-0.5-1-0.7-1.5C7,13,7,12.7,6.9,12.6
                                                                        c-0.2,0.4-0.6,0.7-0.7,1.1c-0.3,0.7-0.3,1.5-0.4,2.4c-0.1,0,0,0-0.1,0C5.2,16,5,15.5,4.8,15c-0.5-1.1-0.6-3-0.1-4.3
                                                                        C4.8,10.4,5.3,9.3,5.1,9C5,8.9,4.7,8.8,4.5,8.5S4.1,7.8,4,7.5C3.6,6.6,3.4,5.6,3,4.7C2.8,4.3,2.5,3.9,2.2,3.5S1.6,2.8,1.3,2.3
                                                                        c0-0.2-0.1-0.5,0-0.7c0-0.1,0.1-0.2,0.2-0.2c0.2-0.2,0.8,0.1,1,0.1C3.1,1.8,3.5,2,4,2.3c0.2,0.2,0.5,0.5,0.7,0.5H5
                                                                        c0.5,0.1,1.1,0,1.5,0.2c0.8,0.3,1.6,0.7,2.2,1.1c2.1,1.3,3.7,3.2,4.9,5.5c0.2,0.4,0.3,0.7,0.4,1.1c0.3,0.8,0.7,1.6,1.1,2.3
                                                                        c0.3,0.8,0.6,1.5,1.1,2.1c0.2,0.3,1.2,0.5,1.6,0.7c0.3,0.1,0.8,0.3,1.1,0.4c0.5,0.3,1.1,0.7,1.6,1.1c0.3,0.2,1,0.6,1.1,0.9
                                                                        c-1.3,0-2.2,0.1-3,0.4C18.4,18.7,18,18.7,18,19c0.1,0.1,0.1,0.3,0.2,0.5c0.2,0.3,0.5,0.8,0.8,1s0.7,0.5,1,0.7
                                                                        c0.6,0.4,1.3,0.6,1.9,1c0.3,0.2,0.7,0.5,1,0.8c0.2,0.1,0.3,0.3,0.5,0.4l0,0C23.4,23.3,23.4,23.1,23.3,23z M5.1,4.5L5.1,4.5
                                                                        L5.1,4.5C5.3,4.8,5.5,5,5.6,5.2C5.7,5.5,5.9,5.7,6,6l0,0c0.2-0.2,0.3-0.4,0.3-0.8c0-0.2-0.1-0.3-0.1-0.4C6.1,4.7,5.9,4.6,5.7,4.4
                                                                        C5.5,4.4,5.3,4.5,5.1,4.5z"
        />
      </g>
    </g>
  </g>
</svg>

<svg *ngIf="name === 'icon-db-oracle'" class="icon-db-oracle" style="display: ">
  <g>
    <g>
      <g>
        <path
          class="st0"
          d="M21.5,1.5h-19c-0.6,0-1,0.4-1,1v19c0,0.6,0.4,1,1,1h19c0.6,0,1-0.4,1-1v-19C22.5,1.9,22.1,1.5,21.5,1.5z
                                                                         M15.1,17.5H8.9c-3,0-5.4-2.5-5.4-5.5s2.4-5.5,5.4-5.5h6.2c3,0,5.4,2.5,5.4,5.5S18.1,17.5,15.1,17.5z M15,8.4H9
                                                                        C7.1,8.4,5.5,10,5.5,12s1.6,3.6,3.5,3.6h6c1.9,0,3.5-1.6,3.5-3.6S16.9,8.4,15,8.4z"
        />
      </g>
    </g>
  </g>
</svg>

<svg *ngIf="name === 'icon-db-phoenix'" class="icon-db-phoenix" style="display: ">
  <g>
    <g>
      <g>
        <linearGradient
          id="SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1="12.9347"
          y1="14.95"
          x2="16.2085"
          y2="14.95"
          gradientTransform="matrix(1 0 0 -1 0 24)"
        >
          <stop offset="0" style="stop-color: #ebebeb" />
          <stop offset="0.1373" style="stop-color: #dedede" />
          <stop offset="0.449" style="stop-color: #c8c8c8" />
          <stop offset="0.7432" style="stop-color: #bababa" />
          <stop offset="1" style="stop-color: #b5b5b5" />
        </linearGradient>
        <path
          class="st0"
          d="M15.8,17c0,0,1-2.9-0.2-6.5c-0.9-2.7-2-3.1-0.1-9.4c0,0-3.7,3.3-2.3,7.1c0.3,0.7,0.2,0.8,1.1,2.2
                                                                        C15.2,11.9,16,13.6,15.8,17z"
        />

        <linearGradient
          id="SVGID_2_"
          gradientUnits="userSpaceOnUse"
          x1="11.2533"
          y1="13.75"
          x2="15.6162"
          y2="13.75"
          gradientTransform="matrix(1 0 0 -1 0 24)"
        >
          <stop offset="0" style="stop-color: #ebebeb" />
          <stop offset="0.1373" style="stop-color: #dedede" />
          <stop offset="0.449" style="stop-color: #c8c8c8" />
          <stop offset="0.7432" style="stop-color: #bababa" />
          <stop offset="1" style="stop-color: #b5b5b5" />
        </linearGradient>
        <path
          class="st1"
          d="M15.6,17.5c0,0,0.6-3.1-2.2-7.4C12.3,8.4,11.8,5.5,13,3c0,0-3.6,5.2-0.5,8.7c0,0,0.4,0.4,0.7,0.8
                                                                        C13.3,12.7,15.8,16.1,15.6,17.5z"
        />

        <linearGradient
          id="SVGID_3_"
          gradientUnits="userSpaceOnUse"
          x1="10.4247"
          y1="11.4"
          x2="15.4207"
          y2="11.4"
          gradientTransform="matrix(1 0 0 -1 0 24)"
        >
          <stop offset="0" style="stop-color: #ebebeb" />
          <stop offset="0.1373" style="stop-color: #dedede" />
          <stop offset="0.449" style="stop-color: #c8c8c8" />
          <stop offset="0.7432" style="stop-color: #bababa" />
          <stop offset="1" style="stop-color: #b5b5b5" />
        </linearGradient>
        <path
          class="st2"
          d="M15.4,17.6c0,0,0-0.4-0.3-1c-0.4-0.9-1.4-3-2.8-4.3c-2.1-1.9-1.6-4.7-1.6-4.7c-0.8,4.7,0.5,5.9,1.3,6.4
                                                                        C13.9,15.3,14.9,16.3,15.4,17.6z"
        />
      </g>

      <linearGradient
        id="SVGID_4_"
        gradientUnits="userSpaceOnUse"
        x1="4.5"
        y1="12.5"
        x2="19.5"
        y2="12.5"
        gradientTransform="matrix(1 0 0 -1 0 24)"
      >
        <stop offset="0" style="stop-color: #ebebeb" />
        <stop offset="0.1373" style="stop-color: #dedede" />
        <stop offset="0.449" style="stop-color: #c8c8c8" />
        <stop offset="0.7432" style="stop-color: #bababa" />
        <stop offset="1" style="stop-color: #b5b5b5" />
      </linearGradient>
      <path
        class="st3"
        d="M19.2,12.5C18.3,8.8,16,5.6,19.2,0c0,0-6,2.1-3.3,9.9c1.7,4.8,1.2,7.5-0.3,9c-0.6,0.6-1.7,1.4-3.5,1.2
                                                                    C10.2,20,9.2,19,8.4,18c-1.1-1.5-1.5-3.6,0-6.7c0.9-2,1.4-3.7,1.4-3.7C9.9,7.3,10,7,10.1,6.7c0.7,0.1,1-0.5,1-0.5
                                                                    c-0.3,0.1-0.4-0.1-0.4-0.1c0.6-0.3,0.4-0.8,0.4-0.8c-0.3,0.2-0.7,0.1-0.7,0.1s-0.7,0-0.8,0C8.3,4.4,7.4,4.6,6.9,5
                                                                    C6.8,5.1,6.7,5.2,6.6,5.3C6.4,5.5,6.3,5.7,6.3,5.7S5,5.9,4.6,6.7c0,0,0.9-0.6,1.7-0.3c0.1,0.2,0.5,0.4,0.8,1.2
                                                                    C7.3,8,7.1,9,6.9,9.4c-1.1,2.1-2.4,3.6-2.4,5.7c0,1.9,0.7,3.7,1.8,5c2.3,2.9,5.9,2.9,6,2.9c4.3,0,7.3-3.5,7.3-7.9
                                                                    C19.5,14.2,19.4,13.1,19.2,12.5z"
      />
    </g>
  </g>
</svg>

<svg *ngIf="name === 'icon-db-post'" class="icon-db-post" style="display: ">
  <g>
    <g id="Layer_x0020_3">
      <g>
        <path
          d="M17.4,17.2c0.1-1.2,0.1-1.4,1-1.2h0.2c0.7,0,1.6-0.1,2.1-0.4c1.1-0.5,1.8-1.4,0.7-1.2c-2.6,0.5-2.7-0.3-2.7-0.3
                                                                        c2.7-4,3.8-9.1,2.9-10.4c-2.7-3.4-7.3-1.8-7.3-1.8l0,0c-0.5-0.1-1.1-0.2-1.7-0.2c-1.2,0-2,0.3-2.7,0.8c0,0-8.2-3.4-7.8,4.3
                                                                        C2.1,8.5,4.3,19.3,7,16c1-1.2,1.9-2.2,1.9-2.2c0.5,0.3,1,0.5,1.6,0.4l0,0c0,0.1,0,0.3,0,0.5c-0.7,0.8-0.5,0.9-1.9,1.2
                                                                        c-1.4,0.3-0.6,0.8,0,0.9c0.6,0.2,2.2,0.4,3.2-1V16c0.3,0.2,0.3,1.6,0.3,2.5c0,1,0.1,1.9,0.3,2.4s0.4,1.9,2.2,1.5
                                                                        C16.2,22,17.3,21.6,17.4,17.2"
        />
        <path
          class="st0"
          d="M14.1,23.4c-0.6,0-1.2-0.1-1.7-0.6s-0.7-1.1-0.8-1.4c0-0.1,0-0.1,0-0.1c-0.2-0.6-0.3-1.5-0.4-2.7
                                                                        c0-0.2,0-0.4,0-0.5s0-0.2,0-0.4c-0.8,0.3-1.7,0.4-2.7,0.2c-0.3-0.1-0.5-0.2-0.8-0.3c-0.2-0.1-0.3-0.2-0.4-0.3
                                                                        c-0.8,0.7-1.5,0.6-1.9,0.4c-0.7-0.2-1.4-0.9-2-2.1c-0.4-0.8-0.8-1.9-1.2-3.1C1.5,10.1,1.1,7.7,1,7C0.9,4.5,1.6,2.8,3,1.8
                                                                        C4.1,1,5.7,0.7,7.7,1.1c0.8,0.1,1.6,0.4,2,0.5c0.8-0.5,1.8-0.7,2.8-0.7c0.6,0,1.1,0.1,1.6,0.2c0.5-0.2,1.6-0.4,2.8-0.4
                                                                        c2.2,0,4.1,0.9,5.4,2.5c0.5,0.6,0.9,2,0.1,5C22,10,21.2,12,20.1,13.7c0.3,0,0.6,0,1.1-0.2c0.3-0.1,0.5-0.1,0.7,0
                                                                        c0.4,0.1,0.8,0.3,0.9,0.7c0.4,1.2-1.3,2-1.8,2.3c-0.6,0.3-1.7,0.5-2.6,0.4l0,0h-0.2V17c0,0.1,0,0.1,0,0.2c-0.1,2.2-0.4,3.6-1,4.6
                                                                        c-0.7,1-1.7,1.3-2.5,1.5C14.6,23.3,14.4,23.4,14.1,23.4z M12.4,15.2c0.5,0.4,0.6,1.2,0.6,2.7c0,0.2,0,0.4,0,0.5
                                                                        c0,0.5,0.1,1.6,0.2,2.1c0,0.1,0,0.1,0.1,0.2c0.2,0.6,0.3,0.9,1.1,0.7c0.7-0.2,1.1-0.3,1.3-0.7c0.4-0.6,0.6-1.8,0.7-3.6l0,0l0,0
                                                                        c0-0.1,0-0.2,0-0.2c0.1-0.7,0.1-1.3,0.7-1.6c0.3-0.2,0.7-0.3,1-0.2c-0.3-0.3-0.3-0.5-0.4-0.7L17.6,14l0.2-0.3
                                                                        c1.2-1.8,2.2-3.9,2.7-5.9c0.6-2.3,0.3-3.2,0.2-3.3c-2.1-2.7-5.8-1.6-6.2-1.5h-0.1h-0.2H14c-0.5-0.1-1-0.1-1.5-0.2
                                                                        c-0.9,0-1.5,0.2-2.1,0.6L10,3.7L9.5,3.5c-1-0.4-3.9-1.2-5.4-0.1C3.2,4,2.9,5.1,2.9,6.9c0,0.6,0.4,2.8,1.1,5
                                                                        c0.9,3,1.7,3.8,1.9,3.8c0,0,0.1-0.1,0.3-0.3c1-1.2,1.9-2.2,2-2.2l0.6-0.6L9.5,13c0.2,0.2,0.5,0.2,0.7,0.3l1.6-1.4l-0.2,2.4
                                                                        c0,0,0,0.1,0,0.2l0.1,0.4l-0.3,0.3l0,0l0.7,0.3L12.4,15.2z"
        />
      </g>
      <path
        class="st0"
        d="M21.5,14.5c-2.6,0.5-2.7-0.3-2.7-0.3c2.7-4,3.8-9.1,2.9-10.4C19,0.4,14.4,2,14.4,2l0,0
                                                                    c-0.5-0.1-1.1-0.2-1.7-0.2c-1.2,0-2,0.3-2.7,0.8c0,0-8.2-3.4-7.8,4.3C2.1,8.5,4.3,19.3,7,16c1-1.2,1.9-2.2,1.9-2.2
                                                                    c0.5,0.3,1,0.5,1.6,0.4l0,0c0,0.1,0,0.3,0,0.5c-0.7,0.8-0.5,0.9-1.9,1.2c-1.4,0.3-0.6,0.8,0,0.9c0.6,0.2,2.2,0.4,3.2-1V16
                                                                    c0.3,0.2,0.5,1.4,0.4,2.5c0,1.1-0.1,1.8,0.2,2.4c0.2,0.6,0.4,1.9,2.2,1.5c1.5-0.3,2.3-1.2,2.4-2.6c0.1-1,0.3-0.9,0.3-1.8l0.1-0.4
                                                                    c0.2-1.4,0-1.8,1-1.6h0.2c0.7,0,1.6-0.1,2.1-0.4C21.9,15.1,22.6,14.2,21.5,14.5L21.5,14.5z"
      />
      <path
        class="st1"
        d="M14.2,22.8c-1.5,0-2-1.2-2.2-1.7c-0.3-0.7-0.4-3.5-0.3-5.8c0-0.2,0.2-0.3,0.3-0.3c0.2,0,0.3,0.2,0.3,0.3
                                                                    c-0.1,2.6,0,5,0.2,5.6c0.3,0.8,0.8,1.5,2.2,1.2s1.8-0.8,2.1-2.1c0.2-1,0.5-3.9,0.6-4.4c0-0.2,0.2-0.3,0.3-0.3
                                                                    c0.2,0,0.3,0.2,0.3,0.3c0,0.6-0.4,3.4-0.6,4.5c-0.3,1.6-1,2.3-2.6,2.6C14.6,22.8,14.4,22.8,14.2,22.8z"
      />
      <path
        class="st1"
        d="M6,16.9c-0.1,0-0.3,0-0.4-0.1c-1-0.3-1.9-1.9-2.8-4.8C2.1,9.9,1.7,7.6,1.7,6.9C1.5,4.7,2.1,3.2,3.3,2.3
                                                                    c1-0.7,2.4-0.9,4.2-0.7c1.3,0.2,2.3,0.6,2.4,0.6c0.2,0.1,0.2,0.3,0.2,0.4C10,2.9,9.8,2.9,9.7,2.9c0,0-3.9-1.6-6-0.1
                                                                    c-1,0.7-1.5,2.1-1.4,4c0,0.6,0.4,2.8,1.1,5C4.1,14.4,5,16,5.8,16.2c0.1,0,0.5,0.2,1-0.5c1-1.2,1.9-2.1,1.9-2.1
                                                                    c0.1-0.1,0.3-0.1,0.4,0c0.1,0.1,0.1,0.3,0,0.5c0,0-0.9,0.9-1.9,2.1C6.8,16.7,6.3,16.9,6,16.9z"
      />
      <path
        class="st1"
        d="M18.7,14.4c-0.1,0-0.1,0-0.2-0.1s-0.2-0.3-0.1-0.4c2.7-4.1,3.7-9,2.9-10c-1-1.3-2.5-2-4.3-2.1
                                                                    c-1.4,0-2.4,0.3-2.6,0.4l0,0c-0.2,0.1-0.3,0-0.4-0.2c-0.1-0.1,0-0.3,0.1-0.4s1.4-0.5,2.9-0.5c2,0,3.7,0.8,4.9,2.3
                                                                    c0.3,0.4,0.4,1,0.4,1.8c0,0.7-0.2,1.7-0.4,2.6c-0.6,2.1-1.6,4.5-2.9,6.3C18.9,14.4,18.8,14.4,18.7,14.4z"
      />
      <path
        class="st1"
        d="M18.9,16.3c-0.5,0-0.9-0.1-1.1-0.2c-0.3-0.2-0.3-0.4-0.3-0.5c-0.1-1.2,0.6-1.5,0.9-1.6c0-0.1-0.1-0.1-0.2-0.2
                                                                    c-0.2-0.3-0.5-0.6-0.7-1.1c0-0.1-0.1-0.3-0.3-0.5c-0.7-1.2-2-3.7-1.1-4.9c0.4-0.6,1.2-0.8,2.4-0.7c-0.2-0.5-0.6-1.3-1.3-2.2
                                                                    c-0.8-1-2.3-2.2-4.7-2.2c-1.2,0-2.2,0.3-2.9,1.1C8,4.8,8,7.9,8,7.9c0,0.2-0.1,0.3-0.3,0.3c-0.2,0-0.3-0.1-0.3-0.3
                                                                    c0-0.1-0.1-3.3,1.8-5.2c0.9-0.9,2-1.3,3.4-1.3c2.5,0,4.2,1.3,5.1,2.4c1,1.2,1.5,2.5,1.5,2.9c0,0.3-0.2,0.4-0.3,0.4h-0.1
                                                                    c-1.4-0.2-2,0.1-2.2,0.4c-0.7,0.9,0.6,3.3,1.2,4.3c0.1,0.3,0.2,0.4,0.3,0.6c0.2,0.4,0.4,0.7,0.6,1c0.2,0.2,0.3,0.4,0.4,0.6
                                                                    c0,0,0.4,0.5,2.4,0.1c0.5-0.1,0.8,0,0.9,0.3c0.2,0.6-0.9,1.2-1.4,1.5C20.4,16.2,19.6,16.3,18.9,16.3z M18,15.5
                                                                    c0.1,0.1,0.4,0.2,1,0.1c0.6,0,1.2-0.2,1.6-0.3c0.4-0.2,0.7-0.4,0.9-0.6l0,0c-1.3,0.3-2.2,0.2-2.7-0.1c0,0-0.1,0-0.1-0.1h-0.1
                                                                    C18.3,14.7,18,14.8,18,15.5z"
      />
      <path
        class="st1"
        d="M9.6,17.2c-0.3,0-0.7,0-1-0.1c-0.1,0-1-0.3-1-0.8s0.7-0.7,1-0.7c1-0.2,1-0.3,1.3-0.7c0.1-0.1,0.2-0.3,0.4-0.4
                                                                    c0.5-0.6,1-0.4,1.2-0.3c0.3,0.1,0.6,0.5,0.7,0.8c0.1,0.3,0.1,0.7-0.1,0.9C11.5,16.8,10.6,17.2,9.6,17.2z M8.3,16.3
                                                                    c0.1,0.1,0.2,0.1,0.4,0.2c0.8,0.2,2,0.2,2.8-0.9c0.1-0.1,0.1-0.3,0-0.4c-0.1-0.2-0.2-0.4-0.3-0.4c-0.2-0.1-0.3-0.1-0.5,0.1l0,0
                                                                    c-0.1,0.2-0.2,0.3-0.3,0.4C10,15.8,9.8,16,8.7,16.2C8.6,16.2,8.4,16.2,8.3,16.3z M10.6,14.6L10.6,14.6L10.6,14.6z"
      />
      <path
        class="st1"
        d="M10.5,14.9c-0.2,0-0.3-0.1-0.3-0.3v-0.1c-0.7,0-1.5-0.3-2-0.9c-0.7-0.7-1-1.7-0.9-2.8
                                                                    c0.2-1.1,0.1-2.1,0.1-2.6c0-0.1,0-0.3,0-0.3c0-0.1,0-0.4,0.8-0.8C8.5,7,9.1,6.7,9.8,6.6c1.1-0.1,1.8,0.4,2,1.4
                                                                    c0.6,2.6,0,3.8-0.3,4.7c-0.1,0.2-0.1,0.3-0.2,0.5v0.1c-0.2,0.5-0.4,1-0.3,1.3C10.9,14.8,10.8,14.9,10.5,14.9
                                                                    C10.6,14.9,10.6,14.9,10.5,14.9z M8,8c0,0.1,0,0.1,0,0.2c0,0.5,0.1,1.6-0.1,2.7c-0.1,0.8,0.1,1.7,0.7,2.2c0.4,0.5,1,0.7,1.6,0.7
                                                                    c0.1-0.3,0.2-0.6,0.3-0.9v-0.1c0.1-0.2,0.1-0.3,0.2-0.5c0.4-0.8,0.8-1.8,0.3-4.3c-0.1-0.5-0.3-0.7-0.7-0.8C9.5,7.1,8.3,7.8,8,8z"
      />
      <g>
        <path
          class="st1"
          d="M9.8,7.9c0,0.1,0.2,0.4,0.5,0.4s0.5-0.2,0.5-0.3c0-0.1-0.2-0.2-0.5-0.3C10,7.7,9.8,7.8,9.8,7.9L9.8,7.9z"
        />
        <path
          class="st1"
          d="M10.3,8.4C10.3,8.4,10.2,8.4,10.3,8.4c-0.2,0-0.4-0.1-0.5-0.3c0-0.1-0.1-0.2-0.1-0.3l0,0c0,0,0-0.1,0.1-0.1
                                                                        c0.1-0.1,0.3-0.1,0.5-0.1c0.3,0,0.6,0.2,0.5,0.4C10.8,8.2,10.6,8.4,10.3,8.4z M9.9,7.9c0,0.1,0.2,0.3,0.4,0.3s0.4-0.1,0.4-0.2
                                                                        c0,0-0.1-0.1-0.4-0.1C10.1,7.8,9.9,7.9,9.9,7.9z"
        />
      </g>
      <g>
        <path
          class="st1"
          d="M17.9,7.6c0,0.1-0.2,0.4-0.5,0.4s-0.5-0.2-0.5-0.3c0-0.1,0.2-0.2,0.5-0.3C17.6,7.5,17.9,7.5,17.9,7.6
                                                                        L17.9,7.6z"
        />
        <path
          class="st1"
          d="M17.4,8.1c-0.3,0-0.5-0.2-0.5-0.3c0-0.2,0.2-0.3,0.5-0.3s0.5,0,0.6,0.2l0,0c0,0.1,0,0.2-0.1,0.3
                                                                        C17.7,8,17.6,8.1,17.4,8.1L17.4,8.1z M17.5,7.6c0,0-0.1,0-0.2,0c-0.3,0-0.4,0.2-0.4,0.2C17,7.8,17.2,8,17.4,8
                                                                        c0.1,0,0.3-0.1,0.3-0.2c0.1-0.1,0.1-0.2,0.1-0.2C17.8,7.6,17.7,7.6,17.5,7.6z"
        />
      </g>
      <path
        class="st1"
        d="M18.3,13.7c-0.1,0-0.1,0-0.2-0.1s-0.2-0.3-0.1-0.4c0.6-0.9,0.5-1.9,0.4-2.8c0-0.4-0.1-0.8-0.1-1.2
                                                                    s0.1-0.7,0.1-1.1c0.1-0.4,0.1-0.7,0.1-1.2c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3c0,0.5,0,0.9-0.1,1.3c0,0.3-0.1,0.6-0.1,1
                                                                    c0,0.3,0,0.7,0.1,1.1c0.1,1,0.3,2.1-0.5,3.2C18.5,13.6,18.4,13.7,18.3,13.7z"
      />
      <path class="st1" d="M1,3.7" />
    </g>
  </g>
</svg>

<svg *ngIf="name === 'icon-db-presto'" class="icon-db-presto" style="display: ">
  <g>
    <path
      class="st0"
      d="M23,12.1c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4C22.4,10.7,23,11.3,23,12.1"
    />
    <path class="st1" d="M20.1,14.8c0,0.8-0.6,1.4-1.4,1.4s-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4S20.1,14.1,20.1,14.8" />
    <path
      class="st2"
      d="M17.3,17.7c0,0.8-0.6,1.4-1.4,1.4c-0.8,0-1.4-0.6-1.4-1.4s0.6-1.4,1.4-1.4C16.7,16.3,17.3,16.9,17.3,17.7"
    />
    <path
      class="st0"
      d="M20.1,9.2c0,0.7-0.6,1.3-1.3,1.3c-0.7,0-1.3-0.6-1.3-1.3S18.1,8,18.8,8C19.5,8,20.1,8.5,20.1,9.2"
    />
    <path class="st1" d="M17.2,12c0,0.7-0.6,1.3-1.3,1.3s-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3C16.7,10.7,17.2,11.3,17.2,12" />
    <path class="st2" d="M14.4,14.9c0,0.7-0.6,1.3-1.3,1.3s-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3S14.4,14.2,14.4,14.9" />
    <path class="st0" d="M17.1,6.4c0,0.6-0.5,1.2-1.2,1.2s-1.2-0.5-1.2-1.2c0-0.6,0.5-1.2,1.2-1.2S17.1,5.8,17.1,6.4" />
    <path
      class="st1"
      d="M14.3,9.2c0,0.6-0.5,1.2-1.2,1.2c-0.6,0-1.2-0.5-1.2-1.2c0-0.6,0.5-1.2,1.2-1.2C13.8,8.1,14.3,8.6,14.3,9.2"
    />
    <path
      class="st2"
      d="M11.5,12.1c0,0.6-0.5,1.2-1.2,1.2c-0.6,0-1.2-0.5-1.2-1.2c0-0.6,0.5-1.2,1.2-1.2C11,10.9,11.5,11.4,11.5,12.1"
    />
    <path class="st0" d="M14.2,3.6c0,0.6-0.5,1-1,1s-1-0.5-1-1c0-0.6,0.5-1,1-1S14.2,3,14.2,3.6" />
    <path class="st1" d="M11.4,6.4c0,0.6-0.5,1-1,1c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1S11.4,5.8,11.4,6.4" />
    <path class="st2" d="M8.6,9.3c0,0.6-0.5,1-1,1s-1-0.5-1-1c0-0.6,0.5-1,1-1S8.6,8.7,8.6,9.3" />
    <path class="st0" d="M8.5,14.9c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9S7.1,14,7.6,14C8,13.9,8.5,14.4,8.5,14.9" />
    <path
      class="st0"
      d="M11.3,17.7c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C10.8,16.7,11.3,17.2,11.3,17.7"
    />
    <path class="st0" d="M14.1,20.5c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S14.1,20,14.1,20.5" />
    <path class="st0" d="M8.5,20.5c0,0.5-0.4,0.9-0.9,0.9S6.7,21,6.7,20.5s0.4-0.9,0.9-0.9C8.1,19.5,8.5,20,8.5,20.5" />
    <path class="st0" d="M2.8,20.5c0,0.5-0.4,0.9-0.9,0.9S1,21,1,20.5s0.4-0.9,0.9-0.9C2.4,19.5,2.8,20,2.8,20.5" />
    <path class="st0" d="M5.6,17.7c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C5.2,16.7,5.6,17.2,5.6,17.7" />
    <path class="st1" d="M8.5,3.6c0,0.5-0.4,1-1,1s-1-0.4-1-1c0-0.5,0.4-1,1-1S8.5,3.1,8.5,3.6" />
    <path class="st2" d="M5.6,6.5c0,0.5-0.4,1-1,1c-0.5,0-1-0.4-1-1c0-0.5,0.4-1,1-1S5.6,5.9,5.6,6.5" />
    <path class="st2" d="M2.7,3.6c0,0.4-0.4,0.8-0.8,0.8S1.1,4,1.1,3.6s0.4-0.8,0.8-0.8S2.7,3.2,2.7,3.6" />
  </g>
</svg>

<svg *ngIf="name === 'icon-db-rds'" class="icon-db-rds">
  <g id="Layer_1_1_">
    <g>
      <polygon class="st0" points="12,17.8 20.4,19.8 20.4,4.1 12,6.1 		" />
      <polygon class="st1" points="20.4,4.1 22,4.9 22,19 20.4,19.8 		" />
      <polygon class="st1" points="12,17.8 3.6,19.8 3.6,4.1 12,6.1 		" />
      <polygon class="st0" points="3.6,4.1 2,4.9 2,19 3.6,19.8 		" />
      <polygon class="st1" points="14.1,23 17.3,21.4 17.3,2.6 14.1,1 13.1,11.5 		" />
      <polygon class="st0" points="9.9,23 6.7,21.4 6.7,2.6 9.9,1 10.9,11.4 		" />
      <rect x="9.9" y="1" class="st2" width="4.3" height="22" />
    </g>
  </g>
</svg>

<svg *ngIf="name === 'icon-db-tibero'" class="icon-db-tibero" style="display: ">
  <g>
    <polygon class="st0" points="21,5.9 6,5.9 5,10.5 9.1,10.5 6.5,23 13.2,23 15.8,10.5 20,10.5 	" />
    <polygon class="st1" points="10.9,8.4 3,8.4 4.6,1 12.4,1 	" />
  </g>
</svg>

<svg *ngIf="name === 'icon-db'" class="icon-db">
  <g>
    <g>
      <path
        class="st0"
        d="M20,3.4C20,3.4,20,3.4,20,3.4c0-0.1-0.1-0.2-0.1-0.3c0,0,0,0,0,0h0C19.8,3.1,19.6,3,19.5,3h-15
                                    C4.3,3,4.2,3.1,4.1,3.2h0c0,0,0,0,0,0C4.1,3.3,4,3.3,4,3.4c0,0,0,0,0,0.1c0,0,0,0,0,0v16.3c0,1.2,0,1.1,0.6,1.7
                                    C5,21.8,5.5,22,6.5,22h9.7c2.4,0,3.8-1.2,3.8-3.5L20,3.4C20,3.5,20,3.5,20,3.4z M19,18.5c0,1.7-0.9,2.5-2.8,2.5H6.5
                                    c-0.6,0-1-0.1-1.2-0.3c-0.3-0.3-0.3,0-0.3-1V16h14V18.5z M19,15H5v-5h14V15z M19,9H5V4h14V9z M17,18h-7v1h7V18z M17,12h-7v1h7V12z
                                     M17,6h-7v1h7V6z"
      />
    </g>
  </g>
  <path
    class="st0"
    d="M8.5,19.1h-1c-0.4,0-0.6-0.3-0.6-0.6l0,0c0-0.4,0.3-0.6,0.6-0.6h1c0.4,0,0.6,0.3,0.6,0.6l0,0
                            C9.1,18.9,8.8,19.1,8.5,19.1z"
  />
  <path
    class="st0"
    d="M8.5,13.1h-1c-0.4,0-0.6-0.3-0.6-0.6v0c0-0.4,0.3-0.6,0.6-0.6h1c0.4,0,0.6,0.3,0.6,0.6v0
                            C9.1,12.9,8.8,13.1,8.5,13.1z"
  />
  <path
    class="st0"
    d="M8.5,7.1h-1c-0.4,0-0.6-0.3-0.6-0.6v0c0-0.4,0.3-0.6,0.6-0.6h1c0.4,0,0.6,0.3,0.6,0.6v0
                            C9.1,6.9,8.8,7.1,8.5,7.1z"
  />
</svg>

<svg *ngIf="name === 'icon-db-hdfs-b'" class="icon-db-hdfs-b">
  <g>
    <polyline
      class="st0"
      points="8.3,7.1 6.7,7.3 5.2,8 3.9,8.8 2.7,10.3 2,11 1.4,11.2 1.2,10.8 1.5,10.4 1.6,9.7 1.8,9.7 2,10
                                                1.9,9.3 1.7,9.2 1.7,8.9 1.1,9.2 0.6,9.9 0.5,10.4 0.7,10.9 0.9,11.6 1.3,11.8 1.8,11.8 2.2,11.6 1.9,13 2.2,14.6 1.9,15.4
                                                0.9,16.5 1.1,17.1 1.6,17.9 2.5,18.5 3,18.6 3.5,18.6 3.2,20 4.4,20.5 6,20.7 6.5,20.4 6.5,19.4 7.1,18.5 7.2,17.7 8.6,17.8
                                                9.9,17.7 8.6,18.5 8.8,19.4 9.6,20.7 10.5,21.1 11.1,20.8 11.4,20.3 12.7,19.2 13,19.5 15.1,19.5 15.6,19.2 15.6,18.6 15.5,18.3
                                                15.4,16.7 14.6,15.2 14.8,14.6 15.2,14.8 16.4,16 17,16 17.6,15.7 18.3,15.2 18.6,14.2 20.5,14.3 21.7,13.9 22.6,13 23.3,11.7
                                                23.5,10.2 23.3,8.5 23,7 22.6,6.5 22.1,6.3 21.2,7.3 20.4,7.6 19.7,6.4 18.9,5.7 18.6,5.5 17,4.2 15.8,3.5 14.5,3.4 13.1,3.7
                                                11.8,4.2 10.9,4.9 10.2,5.7 9.5,5.9 8.3,7.1 	"
    />
    <g>
      <path
        class="st1"
        d="M16.6,14.3c0,0.1-0.1,0.2-0.1,0.3c0,0.2,0,0.3,0,0.5c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0,0.2,0.1,0.2,0.1
                                                    c0.1,0,0.1,0,0.2,0c0.3,0.2,0.3,0.3,0.2,0.4c0.2-0.1,0.4-0.2,0.6-0.2c0.1-0.2,0.3-0.4,0.4-0.5c0.1-0.3,0.2-0.5,0.3-0.8
                                                    c-0.1-0.1-0.3-0.2-0.4-0.3C17.7,14.2,17.1,14.2,16.6,14.3z"
      />
      <path class="st1" d="M15.9,13.8c0.3-0.5,0.6-0.8,1.1-1.1C16.1,12.9,16.1,13,15.9,13.8z" />
      <path
        class="st1"
        d="M21.2,7.9c0.2-0.1,0.4-0.2,0.6-0.4c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.2,0.2-0.4,0.3-0.6
                                                    c-0.1,0.1-0.3,0.1-0.4,0.2c-0.2,0.3-0.4,0.5-0.7,0.7c-0.2,0.1-0.3,0.2-0.5,0.2c-0.1,0-0.2,0-0.4,0.1c0.1,0,0.1,0,0.2,0h0.6V7.9z"
      />
      <path
        class="st1"
        d="M10.1,8.3c0,0.3,0.1,0.6,0.1,0.9c0.2-1.5,0.8-2.1,2.2-2.7c-0.4,0-0.8,0.1-1.2,0.1c-0.4,0.1-0.7,0.2-1.1,0.2
                                                    C9.9,7.1,9.8,7.4,9.6,7.7C9.5,7.9,9.4,8.2,9.3,8.4C9,9.3,8.7,10,8.2,10.9c0.6-0.7,1-1.3,1.4-2C9.7,8.5,9.7,8.2,10.1,8.3z"
      />
      <path
        class="st1"
        d="M11.2,14.9c0.5-0.2,0.8-0.2,1.3-0.2c0.5-0.8,0.5-1.2,0.3-2.2c-0.2-1.4-0.6-2.8-0.9-4.2c0,0.2,0,0.3-0.1,0.5
                                                    c0,0.3,0,0.7,0,1c0.1,0.7,0.2,1.5,0.3,2.2c-0.1,0.6-0.3,1.1-0.4,1.7c-0.4,0.1-0.8,0.2-1.2,0.2C10,14.4,9.5,14.7,9,15
                                                    c-0.3,0.1-0.6,0.1-0.9,0.2c-0.4-0.2-0.8-0.4-1.3-0.6C8.1,16.5,9.3,15.5,11.2,14.9z"
      />
      <path
        class="st1"
        d="M15.3,17.1c-0.2-0.5-0.3-1.1-0.5-1.6h-0.1v-0.1c0-0.2,0-0.5,0.1-0.7c-0.3-0.1-0.5-0.2-0.8-0.3
                                                    c-0.2-0.2-0.4-0.3-0.6-0.5c0.1,0.5,0.3,0.6,0.5,1.1c-0.2,0.8-0.4,1.8-0.7,2.5c-0.1,0.3-0.2,0.5-0.5,0.7c-0.4,0.4-0.8,0.7-1.2,1.1
                                                    c-0.3,0.2-0.5,0.1-0.9,0.1c-0.2,0.3-0.2,0.5-0.5,0.6c-0.5,0.2-0.9-0.3-1.3-0.6c0.3,0.4,0.5,0.8,0.8,1.3c0.2,0.2,0.4,0.3,0.6,0.5
                                                    c0.2,0,0.3,0,0.5,0.1c0.2-0.1,0.4-0.3,0.6-0.4c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.2,0.5-0.3,0.7-0.5c0.3-0.2,0.6-0.5,0.9-0.7
                                                    c0.2-0.3,0.4-0.5,0.5-0.8c0-0.1,0.1-0.1,0.1-0.2c-0.1,0.2-0.1,0.3-0.2,0.5c-0.2,0.3-0.5,0.5-0.7,0.8c0.2,0.1,0.3,0.1,0.5,0.2h1.1
                                                    c0-0.1,0.5,0.1,0.9-0.1c0.1,0,0.2-0.2,0.2-0.3C15.7,18.8,15.2,18.2,15.3,17.1z"
      />
      <path
        class="st1"
        d="M10.1,17.3c0.1-0.5,0.1-0.8,0.4-1.3c-0.3,0.3-0.5,0.6-0.7,0.9C9,17.3,8,17.4,7.1,17.4c0,0.1,0,0.2,0,0.2
                                                    s0,0,0.1,0.1c0.2,0,0.4,0,0.6,0.1c0.7,0,1.3,0,2-0.1C9.9,17.6,10,17.5,10.1,17.3z"
      />
      <path
        class="st1"
        d="M6.9,16.2c-0.2,0.9-0.1,1.5-0.5,2.3c-0.3,0.4-0.7,0.7-1.1,0.9c0,0.2,0,0.4,0,0.5c-0.1,0.6-1.3,0.3-1.7,0.3
                                                    c0.8,0.2,1.6,0.4,2.3,0.6c0.2-0.1,0.3-0.1,0.5-0.2c0.1-0.3,0.1-0.7,0.2-1c0.2-0.3,0.3-0.6,0.5-0.9c0-0.2,0.1-0.4,0.1-0.6
                                                    c-0.1-0.4-0.1-0.9-0.2-1.3C7,16.6,7,16.4,6.9,16.2z"
      />
      <path
        class="st1"
        d="M6.7,14.1c0-0.3,0.1-0.7,0.1-1c-0.3-0.5-0.7-1-1-1.5c0,0.4-0.1,0.6,0.1,1C6.1,13.2,6.4,13.6,6.7,14.1z"
      />
      <path
        class="st1"
        d="M2.9,16.7c-0.3-0.6-0.5-1.1-0.8-1.7C2,15.1,2,15.3,1.9,15.5c0.2,0.7,0.4,1.1,0.8,1.8
                                                    c-0.1,0.2-0.1,0.4-0.3,0.6c-0.3-0.1-0.7-0.1-1-0.1c0.4,0.3,0.8,0.6,1.2,0.9c0.2,0,0.4-0.1,0.6-0.1c0.2-0.2,0.4-0.4,0.6-0.6
                                                    C3.4,17.5,3.2,17.1,2.9,16.7z"
      />
      <path
        class="st1"
        d="M23.3,8.4c-0.1-0.5-0.3-1.1-0.4-1.6c-0.2-0.1-0.3-0.2-0.5-0.4c0.2,1.1,0.5,2.1,0.5,3.2c0,1.3-0.3,3-1.5,3.9
                                                    c-1.4,0.9-3,0.5-4.6,0.1c0.6,0.3,1.2,0.5,1.9,0.8c0.6,0,1.2,0.1,1.7,0.1c0.4-0.1,0.8-0.2,1.2-0.3c0.3-0.3,0.6-0.5,0.9-0.8
                                                    c0.2-0.5,0.5-1,0.7-1.5c0.1-0.6,0.2-1.1,0.3-1.7C23.4,9.5,23.4,8.9,23.3,8.4z"
      />
      <path
        class="st1"
        d="M2.2,11.7c0.1-0.3,0.2-0.6,0.3-1c-0.3,0.1-0.6,0.3-0.9,0.4H1.4c-0.1-0.1-0.1-0.2-0.2-0.3
                                                    c0.1-0.1,0.2-0.3,0.3-0.4c0-0.2,0.1-0.5,0.1-0.7c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0.1,0.2,0.1c0-0.1,0-0.3,0-0.4
                                                    c0-0.1-0.1-0.2-0.1-0.3L1.8,9.1c0,0.1-0.1,0.2-0.1,0.4c-0.6,0.4-0.9,0.6-0.8,1.4C1,11.1,1,11.4,1.1,11.6c0.1,0.1,0.2,0.1,0.3,0.2
                                                    c0.1,0,0.2,0.1,0.3,0.1C1.8,11.8,2,11.8,2.2,11.7z"
      />
      <path
        class="st1"
        d="M17.5,5.1c0.2,0.2,0.4,0.4,0,0.7c0.4-0.1,0.5-0.1,0.8-0.1c0.6,0.1,1.1,0.9,1.1,1.5c0,0.1-0.2,0.2-0.6,0.3
                                                    h-0.2c0,0.1,0.1,0.1,0.2,0.2c0,0.1,0,0.4,0.1,0.5c0.3,0,0.5,0,0.8,0.1c0.1,0.2,0.1,0.3,0.1,0.5c0.1,0,0.2,0,0.3-0.1
                                                    C20.1,8.5,20,8.2,20,8c0.1-0.2,0.2-0.3,0.3-0.5c-0.1-0.3-0.3-0.6-0.4-0.9c-0.3-0.2-0.5-0.5-0.8-0.7c0,0,0,0-0.1,0
                                                    c-0.3-0.2-0.6-0.4-0.9-0.5c-0.5-0.4-0.9-0.8-1.4-1.2c-0.3-0.2-0.7-0.5-1-0.7C16.4,4,17,4.5,17.5,5.1z"
      />
      <path
        class="st1"
        d="M16.9,8.8c0,0,0.1,0,0.1-0.1c0-0.3-0.2-0.5-0.4-0.7c0.1-0.1,0.1-0.1,0.1-0.2c-0.1,0-0.2,0.1-0.2,0.1
                                                    C16.3,8,16.2,8.1,16,8.1c-0.3,0.2-0.5,0.4-0.8,0.6C15.1,8.8,15,9,14.8,9.1c-0.1,0.1-0.1,0.1-0.2,0.1c0.1,0.1,0.3,0,0.5,0
                                                    c0.1,0.3,0.2,0.4,0.5,0.6c-0.1,0.2-0.2,0.4-0.2,0.7c0.4-0.9,1.2-1.3,2.1-1.8C17.3,8.7,17.1,8.7,16.9,8.8z"
      />
    </g>
    <path
      class="st1"
      d="M15.2,6.5c-0.6,0.4-1.1,1.3-1,2.1c-0.2-0.7-0.1-1.4,0.5-1.9c0.1-0.1,0.2-0.1,0.3-0.2
                                                C15.1,6.5,15.2,6.5,15.2,6.5"
    />
    <g>
      <path
        class="st2"
        d="M7.9,7.5C8,7.4,8.1,7.3,8.2,7.1C7.6,7.2,7,7.3,6.4,7.4C5.9,7.6,5.4,7.8,4.9,8C4.5,8.4,4,8.7,3.6,9.1
                                                    C3.3,9.6,3,10,2.7,10.4c-0.2,0.5-0.4,0.9-0.6,1.4c0,0.5,0,1-0.1,1.5c0.1,0.5,0.2,1,0.2,1.4c0.2,0.5,0.4,1,0.6,1.6
                                                    c-0.4-1.9-0.7-3.9,0.7-5.5c0.9-1.1,1.7-1.3,3-1.8c0.1-0.1,0.2-0.2,0.3-0.3c-0.3,0.5-0.6,1.1-0.9,1.6c-0.1,0.3-0.2,0.7-0.3,1
                                                    c0.2,0.3,0.5,0.5,0.7,0.8c0.1-0.5,0.1-0.8,0.3-1.2c0.5-0.8,1-1.5,1.4-2.3c0.6-1.1,1.1-1.8,2.1-2.8C9.9,5.9,9.6,5.9,9.4,6
                                                    C8.9,6.5,8.4,7,7.9,7.5z"
      />
      <path
        class="st2"
        d="M14.9,3.5c-0.3,0-0.5,0-0.8,0c-0.6,0.1-1.2,0.3-1.8,0.4c-0.5,0.3-0.9,0.6-1.4,0.8c-0.3,0.4-0.5,0.7-0.8,1.1
                                                    c1.8-0.3,2.1-0.5,3,0.5c-0.4-0.7-0.5-1-0.4-1.6C12.9,3.8,14.2,3.7,14.9,3.5z"
      />
    </g>
    <g>
      <path
        class="st3"
        d="M23.6,8.5c-0.1-0.7-0.3-1.4-0.6-1.9c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.3-0.2-0.5-0.3C22.2,6,22,6,21.8,6
                                                    l-0.1,0.1c-0.1,0.1-0.2,0.3-0.3,0.4C21.3,6.7,21.2,6.9,21,7c-0.1,0.1-0.3,0.2-0.5,0.3c-0.1-0.2-0.2-0.4-0.3-0.5
                                                    c-0.1-0.2-0.3-0.4-0.4-0.6c-0.1-0.1-0.2-0.3-0.4-0.4c-0.1-0.1-0.3-0.2-0.4-0.3c-0.4-0.3-0.8-0.6-1.1-0.9c-0.2-0.1-0.3-0.3-0.5-0.4
                                                    c-0.9-0.7-1.7-1.1-2.5-1.1C14,3,13.1,3.3,12.1,3.7c-0.5,0.2-0.9,0.5-1.2,0.8s-0.6,0.6-0.9,1c-0.2,0-0.3,0-0.5,0.1
                                                    C9.3,5.7,9.2,5.8,9,6C8.8,6.1,8.7,6.3,8.5,6.4C8.4,6.5,8.3,6.6,8.2,6.8C7.2,7,6.4,7.1,5.7,7.4S4.3,8,3.7,8.6
                                                    C3.4,8.9,3.2,9.1,3,9.4c-0.2,0.3-0.4,0.5-0.5,0.8c-0.1,0.1-0.3,0.3-0.4,0.4c-0.1,0.1-0.3,0.2-0.5,0.3l0,0c-0.1,0-0.1,0.1-0.1,0.1
                                                    l0,0c0.2-0.2,0.3-0.6,0.3-0.9l0.1,0.1L2,10.3l0.2,0.2l0.1-0.3c0-0.2,0.1-0.5,0-0.7c0-0.2-0.1-0.3-0.2-0.4C2,9.1,2,9,2,9
                                                    c0-0.1,0.1-0.2,0.1-0.3l0.1-0.2H2c-0.3,0-0.9,0.4-1.3,0.8C0.5,9.5,0.4,9.7,0.3,9.9s-0.1,0.4-0.1,0.6c0,0.2,0.1,0.4,0.3,0.5
                                                    c0,0.1,0.1,0.2,0.1,0.3s0.1,0.2,0.1,0.3C0.8,11.9,1,12,1.2,12.1s0.4,0.1,0.6,0c0,0.2-0.1,0.5-0.1,0.7c0,0.3,0,0.7,0,1.1
                                                    c0,0.1,0,0.2,0,0.3s0,0.2,0,0.3s-0.1,0.2-0.1,0.3c-0.1,0.2-0.1,0.3-0.2,0.5c-0.1,0.1-0.3,0.2-0.4,0.4c0.1,0.1,0,0.2-0.2,0.3
                                                    c0,0,0,0-0.1,0.1c-0.2,0.2-0.3,0.3-0.2,0.7c0,0.2,0.1,0.5,0.2,0.7s0.3,0.4,0.5,0.6c0.2,0.3,0.7,0.6,1.1,0.7
                                                    c0.2,0.1,0.5,0.1,0.7,0.1V19c0,0.1-0.1,0.2-0.1,0.3c-0.3,0.6,0,1,0.5,1.2c0.2,0.1,0.5,0.2,0.8,0.2c0.1,0,0.1,0,0.2,0
                                                    c0.3,0.1,0.8,0.2,1.3,0.3c0.5,0,1-0.1,1.1-0.5c0.1-0.2,0.1-0.3,0.1-0.5c0-0.1,0-0.3,0-0.4C7,19.3,7.1,19.2,7.2,19
                                                    c0,0,0-0.1,0.1-0.1c0.1-0.2,0.2-0.3,0.2-0.4s0-0.3,0-0.5c0,0,0,0,0-0.1c0.3,0,0.6,0.1,0.9,0.1c0.2,0,0.3,0,0.5,0
                                                    c0,0-0.1,0-0.1,0.1c0,0,0,0-0.1,0c-0.4,0.2-0.4,0.6-0.3,1c0.1,0.4,0.3,0.7,0.5,1c0.3,0.5,0.6,0.9,1,1.1s0.8,0.3,1.4,0
                                                    c0.3-0.2,0.4-0.3,0.5-0.5c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.4-0.3,0.7-0.6c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0.1,0.2,0.1,0.3,0.1
                                                    s0.3,0,0.5,0.1c0.2,0,0.6,0,1,0c0.1,0,0.2,0,0.2,0c0.3,0,0.6,0,0.8-0.1s0.3-0.3,0.4-0.7c0-0.2,0-0.3,0-0.4S16,18.5,16,18.4
                                                    c0-0.2,0-0.3,0-0.5s0-0.4,0-0.5c0-0.2,0-0.4-0.1-0.5c0-0.2-0.1-0.3-0.2-0.5c-0.1-0.1-0.1-0.2-0.1-0.4h-0.1l0,0h0.1l0,0
                                                    c-0.1-0.2-0.1-0.4-0.2-0.6v-0.1c0.1,0.1,0.1,0.1,0.2,0.2l0.3,0.3c0.2,0.2,0.4,0.4,0.6,0.5s0.5,0.2,0.8,0.2c0.4,0,0.7-0.2,1-0.4
                                                    c0.3-0.2,0.5-0.5,0.6-0.8c0-0.1,0.1-0.2,0.1-0.3s0-0.2,0.1-0.2c0.5,0.1,1,0.1,1.5,0.1c0.5-0.1,1-0.2,1.4-0.4
                                                    c0.6-0.3,1.1-0.8,1.4-1.4l0,0c0.4-0.6,0.6-1.4,0.6-2.1C23.8,10.1,23.8,9.3,23.6,8.5z M20.1,7.6L20.1,7.6c-0.1,0-0.1-0.1-0.2-0.1
                                                    H20C20.1,7.5,20.1,7.5,20.1,7.6z M1.7,11L1.7,11L1.7,11L1.7,11z M1.7,9.5C1.7,9.4,1.7,9.4,1.7,9.5c0.2,0,0.2,0.1,0.2,0.3
                                                    C1.8,9.7,1.8,9.6,1.7,9.6V9.5z M1,11.5c-0.1-0.2-0.1-0.4-0.2-0.6c-0.6-0.6,0.3-1.5,0.8-1.8C1.5,9.2,1.5,9.4,1.4,9.5
                                                    c-0.2,0.5,0.1,1-0.4,1.4c0.2,0.4,0.2,0.6,0.7,0.4c0.2-0.1,0.4-0.2,0.5-0.3c-0.1,0.2-0.1,0.4-0.2,0.6v0.1C1.7,11.7,1.2,11.8,1,11.5
                                                    z M3.4,17.9c0,0.1-0.1,0.3-0.2,0.4c-0.4,0.5-1.3-0.2-1.6-0.5s-0.5-0.7-0.6-1c0-0.3,0-0.3,0.2-0.4C1.5,16,1.7,15.8,2,15.6
                                                    c0.1-0.1,0.1-0.3,0.2-0.4C2.4,16.1,2.9,17.1,3.4,17.9L3.4,17.9z M15.2,17.3c0,0.4,0,0.8,0,1.1c0.1,0.2,0.1,0.3,0.1,0.5
                                                    c0,0.5-0.2,0.5-0.6,0.5c-0.2,0-1,0-1.2,0c-0.3,0-0.4,0-0.5-0.1c0.4-0.3,1.2-1.7,1.3-2.1c0.2-0.5,0.3-0.9,0.4-1.4
                                                    c0.1,0.2,0.1,0.4,0.2,0.6C15.1,16.7,15.2,16.9,15.2,17.3z M14.9,15.3L14.9,15.3L14.9,15.3L14.9,15.3z M23.3,10.7
                                                    c-0.1,1.2-0.7,2.5-1.8,3.1c-1.3,0.7-2.9,0.3-4.2-0.2c-0.3-0.1-0.5-0.2-0.7-0.4c0.1,0.3,0.1,0.6,0,0.9c-0.1,0.5-0.4,1.3,0.3,1.4
                                                    c0.3,0.1,0.4,0,0.7-0.1c-0.3,0.1-0.4,0-0.6,0s-0.3-0.1-0.3-0.3c0.1,0,0.2,0.1,0.3,0.1c0.5,0.1,1-0.1,1-0.5c0.1-0.2,0-0.3,0.2-0.6
                                                    c0.1,0,0.2,0.1,0.3,0.1c-0.1,0.2-0.1,0.4-0.2,0.6c-0.2,0.5-0.8,0.9-1.3,0.9s-0.8-0.3-1.2-0.6c-0.2-0.2-0.5-0.4-0.7-0.6
                                                    c-0.6-0.2-1.1-0.4-1.7-0.9c0.4,0.5,0.7,0.7,1.3,1c-0.1,0.8-0.4,1.5-0.6,2.3c-0.1,0.4-1,1.8-1.3,2c-0.2,0.1-1.3,1-1.5,1.2
                                                    s-0.3,0.5-0.5,0.6c-0.7,0.4-1.2-0.3-1.6-1c-0.2-0.2-0.7-1-0.2-1.2c0.4-0.2,0.6-0.4,1-0.6c0.1,0.1,0.2,0.2,0.2,0.3
                                                    c0-0.1,0-0.2,0-0.4c0-0.2,0-0.4,0-0.6s0-0.4,0-0.6c0,0.2-0.2,0.4-0.2,0.6c0,0.1,0,0.2-0.1,0.2c-0.9,0.2-1.8,0.2-2.7,0
                                                    c-0.1-0.3-0.1-0.7-0.2-0.9c0,0.3,0,1.1,0,1.5c0,0.3,0,0.5-0.2,0.7C6.6,19,6.6,19,6.3,19.5c0,0.3,0,0.5-0.1,0.8
                                                    c-0.1,0.5-1.4,0.1-1.8,0C4,20.2,3.1,20,3.3,19.5s0.3-1,0.4-1.7c-0.8-1.1-1.5-2.6-1.6-4c-0.1-1,0-1.7,0.2-2.3
                                                    c0.4-1,0.9-1.9,1.7-2.6C5.2,8,6.2,7.6,7.8,7.4C7.4,7.8,7,8.3,6.6,8.8s-0.7,1-0.9,1.5c-0.4,0.7-0.4,1,0.1,1.7
                                                    c0.4,0.6,0.6,0.8,0.8,1.4c-0.1,0.3-0.2,0.6-0.3,1c0.5,0.6,0.9,0.9,1.4,1.1c0.5,0.1,0.9,0.1,1.3-0.1c1-0.5,1.9-1.1,2.9-1.2
                                                    c0.5-1.2,0.5-2.3,0.2-3.5C12,9.8,12,9,11.9,8.2c-0.2,0.9-0.2,1.6-0.1,2.5c0.2,1,0.3,2.2-0.2,3.1c-1,0.1-1.8,0.7-2.7,1.1
                                                    c-0.3,0.1-0.7,0.1-1.1,0c-0.4-0.1-0.6-0.3-1-0.8c0-0.5,0.1-0.7,0.3-1.1c0.3-0.7,0.7-1.3,1.1-1.9c-0.5,0.6-1,1.1-1.4,1.7
                                                    c-0.2-0.4-0.4-0.6-0.7-1.1C5.8,11.2,5.8,11,6,10.5c0.3-0.6,0.5-1,1-1.5c0.8-0.8,1.5-1.8,2.3-2.6C9.7,6,9.9,6,10.5,5.9
                                                    c0.5-0.1,1.1-0.2,1.6-0.3c-0.5,0.1-1.1,0.1-1.6,0.1l0,0c0.5-0.7,0.8-1,1.7-1.4c2.1-0.9,3.4-1,5,0.4c0.4,0.4,0.8,0.7,1.2,1
                                                    c-0.2,0-0.3,0-0.5,0.1c0.2,0,0.5,0,0.7,0c0.1,0,0.1,0.1,0.2,0.1c0.3,0.2,0.5,0.3,0.7,0.6c0.2,0.3,0.4,0.6,0.6,0.9
                                                    c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.1,0-0.2,0s-0.3,0-0.4,0.1l0,0c-0.2,0.1-0.4,0.2-0.6,0.2c0.1,0,0.3,0,0.4,0l0,0c0,0,0,0.1,0,0.2
                                                    c0,0.1,0,0.2,0,0.3l0,0c0,0,0,0.1,0.1,0.1c-0.1,0-0.2,0.1-0.2,0.1c0.4-0.1,0.7-0.1,1.1,0c0,0.1,0,0.2,0.1,0.4H20l0,0
                                                    c-0.2-0.1-0.4-0.1-0.6-0.1c-0.8,0.2-0.6,0.6-1,1.3c0.4-0.5,0.4-1,1-1.1c0.1,0,0.3-0.1,0.4-0.1c-0.2,0.1-0.3,0.2-0.4,0.4
                                                    c-0.2,0.5-0.1,0.9-0.3,1.4c0.2-0.4,0.3-0.8,0.5-1.3C19.7,9,20,8.8,20.1,8.8h0.1c0,0.3,0.1,0.6,0.1,0.8c0,0.4-0.1,1-0.2,1.3
                                                    c0.2-0.3,0.3-0.9,0.4-1.3c0.1-0.4,0.1-1,0-1.4c-0.1-0.6,0.5-0.5,0.9-0.8c0.3-0.2,0.5-0.6,0.7-0.8c0.3-0.2,0.7,0.1,0.8,0.4
                                                    C23.2,8,23.4,9.6,23.3,10.7z"
      />
      <path class="st3" d="M10.7,3.1C10.1,3.3,10,3.4,9.8,3.9C10.1,3.5,10.3,3.4,10.7,3.1z" />
      <path class="st3" d="M11.6,3c-1.2,0.3-1.5,0.5-1.9,1.8C10.2,3.9,10.6,3.5,11.6,3z" />
      <path class="st3" d="M14.1,7.9c0.1-0.5,0.3-1.1,1.2-1.5C14.2,6.7,14,7.2,14.1,7.9z" />
      <path
        class="st3"
        d="M21.2,7.8c-0.3,0.1-0.5,0.1-0.8,0.1C20.7,8,20.9,8.1,21.3,8c0.7-0.2,1-0.8,1.1-1.4
                                                    C22.1,7.1,21.7,7.5,21.2,7.8z"
      />
      <path
        class="st3"
        d="M16.7,8.5c-0.1-0.2-0.2-0.3-0.4-0.4c0.2-0.1,0.3-0.2,0.4-0.3C16.4,8,16,7.9,15.6,8.2c-0.3,0.2-0.7,0.8-0.9,1
                                                    c0.2-0.1,0.4-0.2,0.6-0.4c0,0.1,0,0.2,0.1,0.3c0.1,0.2,0.2,0.3,0.3,0.3c-0.1,0.1-0.2,0.3-0.2,0.4c0.5-0.6,1.3-1.1,2-1.3
                                                    c-0.2,0-0.4,0-0.7,0.1C16.8,8.7,16.8,8.6,16.7,8.5z"
      />
      <path
        class="st3"
        d="M16,13.3c0,0.2-0.1,0.4-0.1,0.5c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.2,0.1-0.2,0.3-0.3c0.1-0.1,0.4-0.2,0.5-0.2
                                                    c-0.1,0-0.4,0.1-0.5,0.1C16.1,12.9,16,12.9,16,13.3z"
      />
      <path
        class="st3"
        d="M9.9,6.9c-0.4,0.5-0.8,1.9-1,2.4C9.1,8.8,9.7,7.5,10,7.2c0.1-0.1,0.2-0.1,0.3-0.2C10,7.5,10,7.6,10.1,8.2
                                                    c0.1-0.6,0.3-0.9,0.7-1.3c0.4-0.1,0.8-0.2,1.2-0.4c-0.5,0.1-0.9,0.1-1.4,0.1C10.2,6.7,10.2,6.7,9.9,6.9z"
      />
    </g>
    <path
      class="st2"
      d="M15.7,8.9c-0.2,0.1-0.2,0.3-0.1,0.4c0,0.1,0.1,0.1,0.2,0.2c0.1-0.2,0.3-0.3,0.4-0.4l0,0
                                                C16,8.9,15.8,8.8,15.7,8.9z"
    />
    <path
      class="st2"
      d="M19.3,7.7C19.1,7.7,19,7.8,19,8c0,0.1,0,0.2,0.1,0.2s0.2-0.1,0.4-0.1c0,0,0,0,0-0.1
                                                C19.5,7.9,19.4,7.8,19.3,7.7z"
    />
  </g>
</svg>
<svg *ngIf="name === 'icon-db-hive-b'" class="icon-db-hive-b">
  <g id="Layer_3">
    <g transform="translate(-.53268 66.8)">
      <path
        class="st0"
        d="M14.2-63.1c-0.2-0.6-7.3-3.3-7.4-3c-1.3,0.3-1.8,1.8-2.7,2.8l-2-0.1c-0.9,1.1-1.3,2.3-1,3.5
                                                    c1.1,1.5,2.4,2.8,3.2,4.7c0.1,0.9,3.9,0.1,4.7-0.1c-1.1,0.8-2.1,2-1.7,5.6c0.4,1.7,0.6,3.6,4,5.5c0.4,0.3,1.4,0.5,1.9,0.7
                                                    c0.5,0.3,1.2,0.4,3-0.2c1-0.4,2.2-0.7,3.2-1.2l-1.2,0.1c-1.7,0-3,0.2-3.4-0.3l-1.6-2.7l0.9-1.8c1.3,0.1,1.7,1.1,2.8,1.5l1.3-1
                                                    c3.8,2.2,5.3-1.4,5.7-4.3c0-1.2-2.5,0.4-2.4-0.2c0.1-1.2-1-2.9-1.6-4.1l0.8-2.9c0.3-0.3-1.6-3.6-3.2-4
                                                    C16.3-64.9,14.2-63.1,14.2-63.1L14.2-63.1z"
      />
    </g>
    <g>
      <g transform="translate(-.53268 66.8)">
        <path
          class="st1"
          d="M20.5-56.9c0,0.3,0,0.7,0,0.9s-0.1,0.2-0.3,0.2l0.7,0.3c0.1,0.2,0.3,0.5,0.4,0.7c0.1,0.6,0,0.6-0.1,0.7
                                                        c-0.3,0-0.5,0-0.8,0c0.1,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.3-0.2,0.5c0.2,0.1,0.6,0.2,0.9,0.4l0.3-0.7
                                                        C21.5-54.9,21.1-55.9,20.5-56.9L20.5-56.9z"
        />
        <path
          class="st1"
          d="M21.8-53.2c0.4,0.2,1,0.1,1.8-0.3c0.1-0.1,0.2,0,0,0.1C22.6-52.4,22.1-52.9,21.8-53.2L21.8-53.2z"
        />
        <path
          class="st1"
          d="M23.7-53.6c0.1,0.2,0,0.4,0,0.6c-0.1,1-0.4,1.8-1,2.6c-2,3.2-4,1.2-5.6-0.1l-0.7,1.7c0,0.1-0.2,0.3,0.9,0.7
                                                        l1.1-0.7C22.3-45.8,25.1-53.1,23.7-53.6L23.7-53.6z"
        />
        <path class="st1" d="M17.5-50.7c-0.3,0-1.1,0.3-1.1,0.5l0.4-0.6L17.5-50.7z" />
        <path
          class="st1"
          d="M17-54.1c0,0,0.3,0.1,0.3,0c0,0,0,0.3,0.1,0.5L17-52.9c0.6-0.6,1.4-0.6,2.3-0.7l-0.4-0.2
                                                        c0.1-0.2,0-0.4,0-0.6L17-54.1z"
        />
        <path
          class="st1"
          d="M18.4-56c-0.6,0.2-1.2,0.5-1.6,1.1c0.3-1.1,0.4-1,0.4-1.1C17.7-56.1,18-56,18.4-56L18.4-56z"
        />
      </g>
      <g transform="translate(-.53268 66.8)">
        <path
          class="st2"
          d="M15.2-43.7c-0.7,0.8-2.3-0.1-3.7-0.5c-3.4-2.2-4.1-4.2-4.1-6.2c-0.2-2.9,0.7-4.5,1.8-4.9
                                                        c-0.7,1.4-1.1,4-0.7,5.9c0.3,1.1,0.3,2.8,2,3.8c0.9,0.5,0.6,0.9,1.2,1.3C12.4-43.9,14.1-43.4,15.2-43.7L15.2-43.7z"
        />
        <path
          class="st2"
          d="M16.2-56.9c-1.2-1.7-2.9-2.4-4.9-2.5c0.4-0.1,0.8-0.2,1.2-0.4c0.1-0.1,0.1-0.3,0-0.5c-1.9-0.5-3-1.1-4.5-1.6
                                                        l4,1C14.8-60.8,14.5-60.1,16.2-56.9L16.2-56.9z"
        />
        <path
          class="st2"
          d="M17.6-57.7c-0.9-1.3-1.8-2.9-3-3.9c-2.9-1.3-5.7-2.3-9-3.1l1.1-1.4c3.5,0.8,6.9,2.2,9.8,4.4
                                                        C16.9-60.4,17.3-59.2,17.6-57.7L17.6-57.7z"
        />
        <path
          class="st2"
          d="M19.4-60c0,0-0.5-1.1-0.8-1.6c-0.6-0.6-0.9-1.8-2-2.4c0.4,0.1,0.6,0,1.4,0.7l1.2,2.3L19.4-60z"
        />
        <path
          class="st2"
          d="M20-58.1c0.1-1,0.3-2.6,0.1-3.2c-0.7-1-1.4-1.9-2.1-2.8c-0.1-0.1-0.2-0.3-0.3-0.4c1,0.3,2.1,1,3.5,3.7
                                                        L20-58.1z"
        />
      </g>
      <g transform="translate(-.53268 66.8)">
        <path
          d="M15.8-57.9c-0.4-0.3-0.8-1.4-1.4-1.7c-0.6,0-0.8-0.2-1.5-0.1c0.3-0.1,0.5-0.3,0.8-0.4c0.2,0,0.4,0,0.6,0
                                                        c0,0,0.1,0,0.1-0.1c-0.7-0.5-2.3-0.5-3.3-0.7c1.2,0,2.5,0,3.4,0.3C15.2-60,15.4-58.8,15.8-57.9L15.8-57.9z"
        />
        <path
          d="M18-63.3c0.2,0,1.1,1.4,1.1,1.7c0.1,0.5,0.3,1.1,0.3,1.6c-0.1-0.5-0.3-1-0.5-1.5c-0.1-0.1-0.2-0.5-0.6-0.9
                                                        C18.1-62.8,18-63.1,18-63.3L18-63.3z"
        />
        <path d="M21-55.6h-0.4H21L21-55.6z" />
        <path d="M18.4-56c-0.4,0-0.8-0.1-1.2,0C17-55.8,17-55.6,17-55.4C17.5-55.9,17.6-55.9,18.4-56L18.4-56z" />
        <path d="M23.6-53.4c-0.2,0.2-0.2,0.3-0.9,0.5c-0.5,0.1-0.7-0.1-0.9-0.3c0.3,0.1,0.4,0.3,1.3,0.1L23.6-53.4z" />
        <path
          d="M21.7-53.3c-0.2,0.4-0.3,0.8-0.5,1.2c-0.4,0.5-0.2,0.2-0.8,0.9c0.2-0.3,0.4-0.6,0.6-0.9c0.1-0.2,0.2-0.4,0.3-0.6
                                                        c-0.2-0.1-0.5-0.1-0.5-0.1c-0.5,0.3-0.6,0.6-0.9,1c0.2-0.4,0.4-0.8,0.7-1.1c0,0,0.2-0.1,0.3-0.1c-0.2,0-0.5-0.1-0.6-0.1
                                                        c-0.3,0.1-0.5,0.4-0.8,0.6c0.2-0.3,0.4-0.5,0.6-0.8c0,0,0.7,0,1,0.2v0.1l0.2,0.1l0.1-0.4L21.7-53.3z"
        />
        <path d="M17.5-50.7c-0.1,0-0.8-0.1-0.8-0.1c-0.3,0.2-0.3,0.4-0.3,0.6C16.8-50.7,16.8-50.7,17.5-50.7L17.5-50.7z" />
        <path
          d="M16.9-54.1c0,0,0.5-0.1,0.5,0c0,0.2,0.1,0.6,0.1,0.6l-0.3,0.2c0.6-0.3,1.3-0.3,2-0.3c0,0-0.6-0.2-0.5-0.2s0-0.6-0.1-0.6
                                                        c0.1,0,0.2-0.1,0.3-0.1C18.1-54.7,17.7-54.6,16.9-54.1L16.9-54.1z"
        />
        <path
          d="M21.6-53.7l-0.1,0.1c0-0.4-0.4-0.3-0.7-0.3L20.5-54c0.1,0.1,0.3,0.1,0.2,0.2c-0.1,0-0.1,0.2-0.2,0.4h-0.1
                                                        c0.3,0.1,0.5,0.2,0.8,0.3h0.2l0.4-0.2L21.6-53.7z"
        />
      </g>
      <g transform="translate(-.53268 66.8)">
        <path
          class="st3"
          d="M18-53.9c-0.1-0.1-0.4-0.1-0.4,0c-0.1,0.1,0,0.5,0.1,0.4s0.2-0.1,0.4-0.1C18.1-53.8,18.1-53.8,18-53.9
                                                        L18-53.9z"
        />
        <path
          class="st3"
          d="M21-53.3c0,0,0.1-0.2,0.1-0.3s-0.3-0.1-0.3-0.1c-0.1,0-0.1,0.3-0.1,0.3C20.6-53.3,20.9-53.3,21-53.3L21-53.3
                                                        z"
        />
      </g>
      <g transform="translate(-.53268 66.8)">
        <path d="M8.8-46.3H9c1.1,0.5,2.8,1.3,5.4,0.9l0.4,0.6c-1.1,0.5-2.3,0.4-3.5,0.5L8.8-46.3z" />
        <path d="M7.2-50.4l0.5,2.2c1.4,0.9,3.9,1.9,5.9,1.8l-0.5-1C9-48.3,8.6-49.4,7.2-50.4L7.2-50.4z" />
        <path
          d="M8.6-55.1c0.3,1.7,0.6,3.1,2.1,4.2c0.9,0.7,1.9,1.3,3,1.9c0,0-0.1,0.5-0.2,0.5c-3.3-0.4-5.8-3.1-6.1-4.4
                                                        C7.7-53.9,8.1-54.5,8.6-55.1L8.6-55.1z"
        />
        <path
          d="M10.2-56c0.4,1.9,1.5,3.9,2.6,5.7c0.4,0.5,0.5,0.7,1.1,1c0.8,0.3,1.4,0.2,2,0.2c-0.2-0.3-0.3-0.6-0.5-0.9
                                                        c-1.3-1-0.7-2-0.4-2.6c-0.7-0.2-1.7-0.6-1.8-1.1c-0.2-1.7-0.1-2.2,0.1-3C12.2-56.6,11.2-56.4,10.2-56L10.2-56z"
        />
        <path
          class="st4"
          d="M6.6-66.3c-0.3,0.2-0.6,0.5-1,0.9c-0.6,0.6-0.9,1.1-1.5,1.6c-0.1,0.1-0.4,0.3-0.8,0.3c-0.2,0-0.3,0-0.7,0
                                                        c-0.3-0.2-0.6-0.1-0.9,0.3C1.4-62.9,1-62,0.9-61.5c-0.3,1.4,0.5,2.5,1.3,3.3s1.2,1.2,1.5,1.9c0.2,0.4,0.4,1,0.6,1.4
                                                        c0.1,0.1,0.1,0.1,0.2,0.2c0.4,0.1,0.9,0.1,1.4,0.1c0.2,0,0.5,0,0.8,0c0.4-0.1,0.8-0.1,1.2-0.3c0.4-0.1,0.7-0.2,0.9-0.3
                                                        c0,0.1-0.3,0.2-0.4,0.4c-1,1.4-1.2,2.6-1.1,4.5c0.1,0.9,0.3,1.6,0.6,2.4c0.1,0.4,0.4,1,0.7,1.3c0.8,1.2,2.7,2.7,5.2,3.2
                                                        c0.4,0.1,1,0,1.4-0.1c1.3-0.4,3.8-1.3,3.8-1.3s-2.3,0.2-3.3,0.1c-0.2,0-0.5-0.1-0.7-0.2l-0.1-0.2c0.1,0,0.4-0.1,0.8-0.1
                                                        c-0.9-0.1-0.8-0.1-0.9-0.2c-0.1-0.2-0.2-0.6-0.4-0.9c0.4,0,1.2,0.1,1.5-0.2c0,0-0.5,0.1-0.9,0c-0.1,0-0.4-0.1-0.5-0.1
                                                        c-0.2-0.1-0.4-0.1-0.4-0.1c-0.1-0.2-0.1-0.2-0.2-0.5c-0.1-0.3-0.1-0.6-0.1-1c0.3,0.4,0.6,0.6,1.1,0.8c0,0,0.6,0.3,1.1,0.1h0.1
                                                        c0,0-0.3,0-0.4,0c-0.9-0.4-1.1-0.7-1.2-0.9l-0.4-0.6c0.1-0.2,0.2-0.2,0.3-0.3c0.5,0.1,0.7,0.1,1,0c0.2,0.4,0.3,0.8,0.7,1.1
                                                        c1.5,0.4,1.8-0.1,2.2-0.6c1.1,0.8,2.8,1,4.1,0c1.5-1.8,2-4.6,1.9-4.8c-0.2-0.3-0.4-0.7-0.7-0.6c-0.8,0.2-1.1,0.6-1.8,0.5
                                                        c0.1,0,0.2,0,0.2,0c0.1-0.7,0-1,0-1c-0.2-0.5-0.5-1.1-0.8-1.5c-0.1-0.1-0.2-0.7-0.5-1c-0.1-0.1-0.4-0.4-0.4-0.4v0.3
                                                        c0,0,0.1,0,0.2,0.2c0.2,0.7,1,2.2,1,2.3c0.3,0.5,0,1.1,0.2,1.4c0,0,0.4,0,0.8,0c0.5-0.1,0.5-0.4,1-0.4c0.3,0,0.3,0.6,0.3,0.6
                                                        c-0.1,0.7-0.3,1.4-0.6,2.1c-0.6,1.2-1.4,2.3-2.4,2.5c-1.2,0.2-1.9-0.3-2.5-0.7L18-48.9c-0.9,0.9-1.9,0.8-2.3-0.3
                                                        c-0.2-0.4-0.5-0.7-0.7-1.1l-1.2,0.9c-0.1,0.2-0.2,0.5-0.4,0.9c-0.1,0.3-0.2,0.7-0.2,1.1c-0.2,0.3,0.6,1.4,1,2
                                                        c0.1,0.2,0.4,0.5,0.4,0.6c0.1,0.2,0.3,0.4,0.3,0.5c0.8,1.1-1.1,0.8-1.4,0.8c-0.8-0.1-1.5-0.4-2.2-0.9l-0.1-0.1
                                                        c-0.8-0.5-1.6-1.2-2.3-1.9c-0.4-0.4-0.8-1.4-1-2c-0.4-1.6-1-4.2,0.6-6.3c0-0.1,0.1-0.3,0.2-0.3c0.5-0.3,1-0.5,1.6-0.7l-0.1-0.4
                                                        C9.9-56,9-55.7,8.7-55.5c-0.6,0.2-1.1,0.4-1.9,0.6c-0.3,0-0.5,0-0.7,0C5.6-55,4.6-55,4.6-55c-0.4-0.5-0.5-1.5-0.8-2l0,0l0,0
                                                        c-0.2-0.3-0.4-0.5-0.7-0.7c-0.8-0.8-1.5-1.6-1.7-2.4c0-0.2-0.2-0.5-0.1-1.2l0,0l0,0c0.2-0.7,0.5-1.2,1.1-1.8c0.6,0,1.1,0,1.6,0.1
                                                        c0.2,0,0.6,0.1,1,0.2c1.1,0.4,2.5,1.1,2.5,1.1c-1.1-0.6-2.3-1.4-3.1-1.5c-0.1,0-0.2-0.1-0.2-0.2c1.1-0.7,1.4-1.5,2.1-2.2
                                                        c0.3-0.2,0.5-0.2,0.8-0.3c2.7,0.4,4.4,1.5,5.8,2.2c0.5,0.3,1,0.5,1.5,0.8c0.4,0.1,1.7,1.1,2,1.6c0.4,0.8,0.6,1.6,0.9,2.5
                                                        c0.2,0.8,0.3,1.2,0.3,1.2s-0.2-0.7-0.1-0.8c0.2,0.1,0.5,0.2,0.7,0.2c0,0-0.7-0.4-0.8-0.7c-0.3-1-0.6-2.6-0.7-2.7
                                                        c-0.2-0.3-1.1-1-1.7-1.3c-0.2-0.1-0.3-0.2-0.3-0.3c0.2-0.2,0.4-0.4,0.6-0.6c0.2-0.1,0.4-0.3,0.6-0.4c1.2-0.5,1.8,0.2,2,0.1
                                                        c0,0-0.3-0.3-0.1-0.2c0.1,0.1,0.5,0.1,0.5,0.2c0.4,0.3,1.5,1.6,2.2,2.8c0.2,0.3,0.2,0.5,0.2,0.9c-0.1,0.4-0.1,0.6-0.2,0.8
                                                        S20-58.3,20-58.1c-0.1,0.6,0.3,1.4,0.3,1.4c0-0.2,0-0.3,0-0.5v-0.3v-0.1c0-0.2,0.1-0.4,0.1-0.5c0.1-0.8,0.4-1.4,0.6-2.2
                                                        c0.1-0.2,0.2-0.3,0.2-0.4c0-0.2-0.2-0.6-0.4-0.9c-0.2-0.3-0.4-0.7-0.6-1.1c-0.6-0.8-1.1-1.5-2-1.9c-0.3-0.1-1.3-0.2-1.6-0.2
                                                        c-0.4,0.1-0.8,0.2-1.1,0.4c-0.5,0.3-0.8,0.7-1.2,1c-0.9-0.5-1.4-0.8-1.5-0.9c-0.5-0.3-1.2-0.6-1.9-1C10.6-65.4,8.5-66.1,6.6-66.3
                                                        L6.6-66.3z M18.4-49.1c-0.6-0.4-1-0.9-1.4-1.4c-0.1,0.6-0.5,0.9-0.8,1.3c-0.1,0.1-0.1,0.2,0.2,0.6c0.1,0.1,0.4,0.1,0.5,0.1
                                                        c-0.2-0.1-0.5-0.3-0.5-0.4c0.3,0.2,0.6,0.3,0.9,0.3c0.1,0,0.1-0.1,0.2-0.2c0.1-0.3,0.2-0.3,0.3-0.4l0.2,0.3L18.4-49.1z"
        />
      </g>
    </g>
  </g>
</svg>
<svg *ngIf="name === 'icon-db-local-b'" class="icon-db-local-b">
  <g id="Layer_1_1_">
    <title>Group 9</title>
    <desc>Created with Sketch.</desc>
    <g id="_x32_.-component">
      <g id="list" transform="translate(-713.000000, -2063.000000)">
        <g id="Group-9" transform="translate(713.000000, 2063.000000)">
          <path
            id="Fill-1"
            class="st0"
            d="M17,4c1.1,0,2.1,0.9,2.1,2.2L20,17.8c0,1.1-0.9,2.2-2.1,2.2H6.1C4.9,20,4,19.1,4,17.8L5,6.2
                                                            C5,5,5.9,4,7.1,4H17z"
          />
          <path
            id="Stroke-3"
            class="st1"
            d="M7,4h10c1.1,0,2.1,0.9,2.1,2.2L20,17.8c0,1.1-0.9,2.2-2.1,2.2H6.1C4.9,20,4,19.1,4,17.8
                                                            L5,6.2C5,4.9,5.9,4,7,4z"
          />
          <path
            id="Fill-7"
            class="st2"
            d="M17.1,17.1c0,0.7-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1S15.3,16,16,16C16.7,16,17.1,16.5,17.1,17.1
                                                            "
          />
        </g>
      </g>
    </g>
    <rect x="4.5" y="13.5" class="st2" width="15" height="1" />
  </g>
</svg>
<svg *ngIf="name === 'icon-db-mysql-b'" class="icon-db-mysql-b">
  <g id="Layer_5">
    <g>
      <g>
        <path
          class="st0"
          d="M23.3,23c-0.2-0.2-0.3-0.3-0.5-0.5c-0.5-0.6-1-1.2-1.6-1.6c-0.5-0.4-1.6-0.8-1.8-1.4l0,0
                                                        c0.3,0,0.8-0.2,1.1-0.3c0.5-0.1,1-0.1,1.6-0.3c0.2-0.1,0.5-0.1,0.7-0.2v-0.1c-0.3-0.3-0.5-0.7-0.8-0.9c-0.8-0.7-1.7-1.4-2.6-2
                                                        c-0.5-0.3-1.1-0.5-1.6-0.8c-0.2-0.1-0.5-0.1-0.6-0.3c-0.3-0.4-0.4-0.8-0.6-1.2c-0.4-0.9-0.9-1.8-1.3-2.8C15,10,14.9,9.3,14.5,8.8
                                                        C12.8,6,11,4.4,8.3,2.8C7.7,2.5,7,2.3,6.3,2.1c-0.4,0-0.8,0-1.2-0.1C4.9,1.9,4.7,1.6,4.5,1.5C3.6,0.9,1.3-0.3,0.6,1.3
                                                        c-0.4,1,0.6,2.1,1,2.6c0.3,0.4,0.6,0.8,0.8,1.2c0.1,0.3,0.1,0.5,0.3,0.8C3,6.6,3.2,7.4,3.5,8c0.2,0.3,0.4,0.7,0.6,1
                                                        c0.1,0.2,0.4,0.3,0.4,0.5c-0.2,0.3-0.2,0.8-0.4,1.2c-0.6,1.8-0.3,4,0.5,5.3c0.2,0.4,0.8,1.3,1.6,0.9c0.7-0.3,0.5-1.2,0.7-2
                                                        c0-0.2,0-0.3,0.1-0.4l0,0c0.2,0.4,0.4,0.9,0.6,1.3c0.5,0.8,1.3,1.6,2,2.1c0.4,0.3,0.7,0.8,1.1,0.9l0,0l0,0
                                                        c-0.1-0.1-0.2-0.2-0.4-0.3c-0.3-0.3-0.6-0.6-0.8-0.9c-0.6-0.9-1.2-1.9-1.7-2.9c-0.3-0.5-0.5-1-0.7-1.5C7,13,7,12.7,6.9,12.6
                                                        c-0.2,0.4-0.6,0.7-0.7,1.1c-0.3,0.7-0.3,1.5-0.4,2.4c-0.1,0,0,0-0.1,0C5.2,16,5,15.5,4.8,15c-0.5-1.1-0.6-3-0.1-4.3
                                                        C4.8,10.4,5.3,9.3,5.1,9C5,8.9,4.7,8.8,4.5,8.5S4.1,7.8,4,7.5C3.6,6.6,3.4,5.6,3,4.7C2.8,4.3,2.5,3.9,2.2,3.5S1.6,2.8,1.3,2.3
                                                        c0-0.2-0.1-0.5,0-0.7c0-0.1,0.1-0.2,0.2-0.2c0.2-0.2,0.8,0.1,1,0.1C3.1,1.8,3.5,2,4,2.3c0.2,0.2,0.5,0.5,0.7,0.5H5
                                                        c0.5,0.1,1.1,0,1.5,0.2c0.8,0.3,1.6,0.7,2.2,1.1c2.1,1.3,3.7,3.2,4.9,5.5c0.2,0.4,0.3,0.7,0.4,1.1c0.3,0.8,0.7,1.6,1.1,2.3
                                                        c0.3,0.8,0.6,1.5,1.1,2.1c0.2,0.3,1.2,0.5,1.6,0.7c0.3,0.1,0.8,0.3,1.1,0.4c0.5,0.3,1.1,0.7,1.6,1.1c0.3,0.2,1,0.6,1.1,0.9
                                                        c-1.3,0-2.2,0.1-3,0.4C18.4,18.7,18,18.7,18,19c0.1,0.1,0.1,0.3,0.2,0.5c0.2,0.3,0.5,0.8,0.8,1s0.7,0.5,1,0.7
                                                        c0.6,0.4,1.3,0.6,1.9,1c0.3,0.2,0.7,0.5,1,0.8c0.2,0.1,0.3,0.3,0.5,0.4l0,0C23.4,23.3,23.4,23.1,23.3,23z M5.1,4.5L5.1,4.5
                                                        L5.1,4.5C5.3,4.8,5.5,5,5.6,5.2C5.7,5.5,5.9,5.7,6,6l0,0c0.2-0.2,0.3-0.4,0.3-0.8c0-0.2-0.1-0.3-0.1-0.4C6.1,4.7,5.9,4.6,5.7,4.4
                                                        C5.5,4.4,5.3,4.5,5.1,4.5z"
        />
      </g>
    </g>
  </g>
</svg>
<svg *ngIf="name === 'icon-db-oracle-b'" class="icon-db-oracle-b">
  <g id="Layer_7">
    <g>
      <g>
        <path
          class="st0"
          d="M21.5,1.5h-19c-0.6,0-1,0.4-1,1v19c0,0.6,0.4,1,1,1h19c0.6,0,1-0.4,1-1v-19C22.5,1.9,22.1,1.5,21.5,1.5z
                                                         M15.1,17.5H8.9c-3,0-5.4-2.5-5.4-5.5s2.4-5.5,5.4-5.5h6.2c3,0,5.4,2.5,5.4,5.5S18.1,17.5,15.1,17.5z M15,8.4H9
                                                        C7.1,8.4,5.5,10,5.5,12s1.6,3.6,3.5,3.6h6c1.9,0,3.5-1.6,3.5-3.6S16.9,8.4,15,8.4z"
        />
      </g>
    </g>
  </g>
</svg>
<svg *ngIf="name === 'icon-db-presto-b'" class="icon-db-presto-b">
  <g id="Layer_2_1_">
    <path class="st0" d="M22.5,12.1c0,0.8-0.6,1.3-1.3,1.3s-1.3-0.6-1.3-1.3c0-0.8,0.6-1.3,1.3-1.3S22.5,11.3,22.5,12.1" />
    <path class="st0" d="M19.7,14.7c0,0.8-0.6,1.3-1.3,1.3s-1.3-0.6-1.3-1.3c0-0.8,0.6-1.3,1.3-1.3S19.7,14,19.7,14.7" />
    <path class="st1" d="M17.1,17.4c0,0.8-0.6,1.3-1.3,1.3c-0.8,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3S17.1,16.7,17.1,17.4" />
    <path
      class="st0"
      d="M19.7,9.3c0,0.7-0.6,1.2-1.2,1.2c-0.7,0-1.2-0.6-1.2-1.2s0.6-1.1,1.2-1.1C19.2,8.2,19.7,8.7,19.7,9.3"
    />
    <path class="st0" d="M17,12c0,0.7-0.6,1.2-1.2,1.2s-1.2-0.6-1.2-1.2s0.6-1.2,1.2-1.2C16.5,10.8,17,11.3,17,12" />
    <path class="st1" d="M14.3,14.8c0,0.7-0.6,1.2-1.2,1.2s-1.2-0.6-1.2-1.2c0-0.7,0.6-1.2,1.2-1.2S14.3,14.1,14.3,14.8" />
    <path class="st0" d="M16.9,6.7c0,0.6-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1S16.9,6.1,16.9,6.7" />
    <path class="st0" d="M14.2,9.3c0,0.6-0.5,1.1-1.1,1.1S12,9.9,12,9.3s0.5-1.1,1.1-1.1C13.7,8.3,14.2,8.8,14.2,9.3" />
    <path
      class="st1"
      d="M11.5,12.1c0,0.6-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1S9.8,11,10.4,11C11,10.9,11.5,11.4,11.5,12.1"
    />
    <path class="st0" d="M14.1,4c0,0.6-0.5,1-1,1s-1-0.5-1-1c0-0.6,0.5-1,1-1S14.1,3.4,14.1,4" />
    <path class="st0" d="M11.4,6.7c0,0.6-0.5,1-1,1c-0.6,0-1-0.5-1-1c0-0.6,0.5-1,1-1S11.4,6.1,11.4,6.7" />
    <path class="st1" d="M8.8,9.4c0,0.6-0.5,1-1,1s-1-0.5-1-1c0-0.6,0.5-1,1-1S8.8,8.8,8.8,9.4" />
    <path class="st0" d="M8.7,14.8c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C8.2,13.8,8.7,14.3,8.7,14.8" />
    <path
      class="st0"
      d="M11.3,17.4c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9C10.9,16.5,11.3,17,11.3,17.4"
    />
    <path class="st0" d="M14,20.1c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S14,19.6,14,20.1" />
    <path class="st0" d="M8.7,20.1c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S8.7,19.6,8.7,20.1" />
    <path class="st0" d="M3.2,20.1c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S3.2,19.6,3.2,20.1" />
    <path class="st0" d="M5.9,17.4c0,0.5-0.4,0.9-0.9,0.9s-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9S5.9,17,5.9,17.4" />
    <path class="st0" d="M8.7,4c0,0.5-0.4,1-1,1s-1-0.4-1-1c0-0.5,0.4-1,1-1S8.7,3.5,8.7,4" />
    <path class="st1" d="M5.9,6.8c0,0.5-0.4,1-1,1c-0.5,0-1-0.4-1-1c0-0.5,0.4-1,1-1S5.9,6.2,5.9,6.8" />
    <path class="st1" d="M3.1,4c0,0.4-0.4,0.8-0.8,0.8S1.6,4.4,1.6,4S2,3.2,2.4,3.2S3.1,3.6,3.1,4" />
  </g>
</svg>

<svg *ngIf="name === 'icon-db-staging-b'" class="icon-db-staging-b">
  <g>
    <path
      class="st0"
      d="M11.1,8.1c3.4,0,6.5-1.3,6.5-2.8s-3.1-2.8-6.5-2.8c-3.3,0-6.4,1.4-6.4,2.8C4.6,6.7,7.7,8.1,11.1,8.1z"
    />
    <path
      class="st1"
      d="M4.6,16.2L4.6,16.2v0.2h-1c0.2,2.3,3.9,3.9,7.5,4c-0.2-0.3-0.3-0.7-0.5-1C7.4,19.1,4.6,17.7,4.6,16.2z"
    />
    <path
      class="st1"
      d="M4.6,7.2C6,8.4,8.6,9.1,11,9.1c2.6,0,5.3-0.7,6.6-2V5.2h0.9c-0.1-2.3-4-3.7-7.5-3.7C7.5,1.5,3.6,3,3.6,5.3
                                                c0,0.1,0,0.2,0,0.3h1V7.2z M11.1,2.5c3.4,0,6.5,1.3,6.5,2.8s-3.1,2.8-6.5,2.8c-3.3,0-6.4-1.4-6.4-2.8C4.6,3.9,7.7,2.5,11.1,2.5z"
    />
    <path class="st1" d="M17.6,7.1v3c0.3,0,0.7,0.1,1,0.1v-5h-0.1h-0.9V7.1z" />
    <path
      class="st1"
      d="M21.2,13.3l0.3-0.3c0.1-0.1,0.1-0.4,0-0.5c-0.2-0.2-0.4-0.1-0.6,0.1l-0.2,0.2l-0.3,0.3
                                                c0.2,0.2,0.4,0.3,0.5,0.5L21.2,13.3z"
    />
    <path
      class="st1"
      d="M17.6,12v-0.7c0-0.3-0.3-0.4-0.5-0.4s-0.4,0.2-0.4,0.4v0.6c0.1,0,0.3,0,0.4,0C17.3,11.9,17.4,11.9,17.6,12z"
    />
    <path
      class="st1"
      d="M12.1,16.5h-0.7c-0.2,0-0.4,0.2-0.4,0.5c0,0.2,0.2,0.4,0.4,0.4H12c0-0.1,0-0.3,0-0.4
                                                C12,16.8,12,16.7,12.1,16.5z"
    />
    <path
      class="st1"
      d="M13.5,12.8l-0.3-0.3c-0.1-0.1-0.4-0.1-0.5,0c-0.2,0.2-0.1,0.4,0,0.5l0.3,0.3l0.3,0.3c0.2-0.2,0.3-0.3,0.5-0.5
                                                L13.5,12.8z"
    />
    <path
      class="st1"
      d="M16.6,22.1c0.1,0.2,0.1,0.6,0.1,0.6c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4v-0.6c-0.1,0-0.3,0-0.4,0
                                                C16.9,22.1,16.8,22.1,16.6,22.1z"
    />
    <path
      class="st1"
      d="M20.8,21.3l0.3,0.3c0.1,0.1,0.4,0.1,0.5,0c0.2-0.1,0.2-0.5,0.1-0.6l-0.4-0.4L21,20.3c-0.2,0.2-0.4,0.4-0.6,0.6
                                                L20.8,21.3z"
    />
    <path
      class="st1"
      d="M22.7,16.5h-0.6c0,0.2,0.1,0.3,0.1,0.5c0,0.1,0,0.3,0,0.4h0.5c0.2,0,0.4-0.2,0.4-0.4S23,16.5,22.7,16.5z"
    />
    <path
      class="st1"
      d="M12.9,20.6l-0.3,0.3c-0.1,0.1-0.1,0.4,0,0.5s0.4,0.1,0.5,0l0.3-0.3l0.3-0.3c-0.2-0.2-0.3-0.3-0.5-0.5
                                                L12.9,20.6z"
    />
    <path
      class="st1"
      d="M22.1,16.5c-0.1-1.1-0.6-2.1-1.3-2.9c-0.2-0.2-0.3-0.3-0.5-0.5c-0.8-0.7-1.7-1.1-2.8-1.2
                                                c-0.2,0-0.3-0.1-0.5-0.1c-0.1,0-0.3,0-0.4,0c-1.1,0.1-2.1,0.5-2.9,1.2c-0.2,0.2-0.4,0.3-0.5,0.5c-0.7,0.8-1.2,1.8-1.3,2.9
                                                c0,0.2-0.1,0.3-0.1,0.5c0,0.1,0,0.3,0,0.4c0.1,1.1,0.5,2.1,1.2,2.9c0.2,0.2,0.3,0.4,0.5,0.5c0.8,0.7,1.8,1.2,2.9,1.3
                                                c0.2,0,0.3,0,0.5,0c0.1,0,0.3,0,0.4,0c1.1-0.1,2.1-0.5,2.9-1.2c0.2-0.2,0.4-0.4,0.6-0.6c0.7-0.8,1.1-1.8,1.2-2.9c0-0.1,0-0.3,0-0.4
                                                C22.2,16.8,22.2,16.7,22.1,16.5z M17.1,21.1c-2.2,0-4.1-1.9-4.1-4.1s1.9-4.1,4.1-4.1s4.1,1.9,4.1,4.1S19.3,21.1,17.1,21.1z"
    />
    <path
      class="st1"
      d="M17.1,14.2c-1.6,0-2.8,1.2-2.8,2.8s1.2,2.8,2.8,2.8c1.5,0,2.8-1.3,2.8-2.8C19.9,15.4,18.7,14.2,17.1,14.2z
                                                 M17.1,18.8c-1,0-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8S18.1,18.8,17.1,18.8z"
    />
    <polygon class="st1" points="4.6,16.2 4.6,7.2 4.6,5.6 3.7,5.5 3.6,5.3 3.6,16.4 3.6,16.4 4.6,16.4 	" />
  </g>
</svg>
<svg *ngIf="name === 'icon-file-csv-b'" class="icon-file-csv-b">
  <g>
    <rect x="10" y="13" class="st0" width="3" height="1" />
    <rect x="10" y="11" class="st0" width="4" height="1" />
    <rect x="9" y="12" class="st0" width="1" height="1" />
    <rect x="4" y="12" class="st0" width="1" height="4" />
    <rect x="5" y="11" class="st0" width="3" height="1" />
    <path class="st0" d="M19,7h-3.5C15.2,7,15,6.8,15,6.5V3H5.3C5.1,3,5,3.1,5,3.3V9h14V7z" />
    <polygon class="st0" points="16,3.4 16,6 18.5,6 	" />
    <rect x="13" y="14" class="st0" width="1" height="2" />
    <rect x="9" y="16" class="st0" width="4" height="1" />
    <rect x="19" y="11" class="st0" width="1" height="2" />
    <rect x="18" y="13" class="st0" width="1" height="2" />
    <rect x="5" y="16" class="st0" width="3" height="1" />
    <rect x="15" y="11" class="st0" width="1" height="2" />
    <rect x="17" y="15" class="st0" width="1" height="2" />
    <rect x="16" y="13" class="st0" width="1" height="2" />
    <path class="st0" d="M5,20.7C5,20.9,5.1,21,5.3,21h13.4c0.2,0,0.3-0.1,0.3-0.3V19H5V20.7z" />
    <path
      class="st1"
      d="M21.5,9H20V6.5c0-0.4-0.2-0.6-0.4-0.8l-3.2-3.3C16.2,2.2,15.8,2,15.5,2H5.3C4.6,2,4,2.6,4,3.3V9H2.6
                                                C1.7,9,1,9.7,1,10.5v7C1,18.4,1.7,19,2.6,19h1.5v1.7c0,0.7,0.6,1.3,1.3,1.3h13.4c0.7,0,1.3-0.6,1.3-1.3V19h1.4
                                                c0.9,0,1.5-0.7,1.5-1.5v-7C23,9.7,22.3,9,21.5,9z M16,3.4L18.5,6H16V3.4z M5,3.3C5,3.1,5.1,3,5.3,3H15v3.5C15,6.8,15.2,7,15.5,7H19
                                                v2H5V3.3z M13,13v1h1v2h-1v1H9v-1h4v-2h-3v-1H9v-1h1v-1h4v1h-4v1H13z M4,16v-4h1v-1h3v1H5v4h3v1H5v-1H4z M19,20.7
                                                c0,0.2-0.1,0.3-0.3,0.3H5.3C5.1,21,5,20.9,5,20.7V19h14V20.7z M20,13h-1v2h-1v2h-1v-2h-1v-2h-1v-2h1v2h1v2h1v-2h1v-2h1V13z"
    />
  </g>
</svg>
<svg *ngIf="name === 'icon-file-json-b'" class="icon-file-json-b">
  <g>
    <rect x="10" y="14" class="st0" width="1" height="2" />
    <rect x="15" y="12" class="st0" width="1" height="4" />
    <rect x="13" y="11" class="st0" width="2" height="1" />
    <rect x="13" y="16" class="st0" width="2" height="1" />
    <polygon class="st0" points="16,3.4 16,6 18.5,6 	" />
    <path class="st0" d="M19,7h-3.5C15.2,7,15,6.8,15,6.5V3H5.3C5.1,3,5,3.1,5,3.3V9h14V7z" />
    <rect x="7" y="16" class="st0" width="3" height="1" />
    <rect x="12" y="12" class="st0" width="1" height="4" />
    <polygon class="st0" points="18,13 18,12 18,11 17,11 17,12 17,17 18,17 18,14 19,14 19,13 	" />
    <polygon class="st0" points="20,14 19,14 19,15 20,15 20,17 21,17 21,11 20,11 	" />
    <path class="st0" d="M5,20.7C5,20.9,5.1,21,5.3,21h13.4c0.2,0,0.3-0.1,0.3-0.3V19H5V20.7z" />
    <polygon class="st0" points="6,17 6,12 6,11 3,11 3,12 5,12 5,16 3,16 3,17 5,17 	" />
    <rect x="8" y="11" class="st0" width="3" height="1" />
    <rect x="8" y="13" class="st0" width="2" height="1" />
    <rect x="7" y="12" class="st0" width="1" height="1" />
    <rect x="13" y="12" class="st1" width="2" height="4" />
    <path
      class="st1"
      d="M21.5,9H20V6.5c0-0.4-0.2-0.6-0.4-0.8l-3.2-3.3C16.2,2.2,15.8,2,15.5,2H5.3C4.6,2,4,2.6,4,3.3V9H2.6
                                                C1.7,9,1,9.7,1,10.5v7C1,18.4,1.7,19,2.6,19h1.5v1.7c0,0.7,0.6,1.3,1.3,1.3h13.4c0.7,0,1.3-0.6,1.3-1.3V19h1.4
                                                c0.9,0,1.5-0.7,1.5-1.5v-7C23,9.7,22.3,9,21.5,9z M16,3.4L18.5,6H16V3.4z M5,3.3C5,3.1,5.1,3,5.3,3H15v3.5C15,6.8,15.2,7,15.5,7H19
                                                v2H5V3.3z M12,16v-4h1v-1h2v1h1v4h-1v1h-2v-1H12z M10,13v1h1v2h-1v1H7v-1h3v-2H8v-1H7v-1h1v-1h3v1H8v1H10z M3,17v-1h2v-4H3v-1h3v1
                                                v5H5H3z M19,20.7c0,0.2-0.1,0.3-0.3,0.3H5.3C5.1,21,5,20.9,5,20.7V19h14V20.7z M21,17h-1v-2h-1v-1h-1v3h-1v-5v-1h1v1v1h1v1h1v-3h1
                                                V17z"
    />
  </g>
</svg>
<svg *ngIf="name === 'icon-file-txt-b'" class="icon-file-txt-b">
  <g>
    <rect x="10" y="15" class="st0" width="1" height="2" />
    <rect x="13" y="15" class="st0" width="1" height="2" />
    <rect x="11" y="13" class="st0" width="2" height="2" />
    <path class="st0" d="M19,7h-3.5C15.2,7,15,6.8,15,6.5V3H5.3C5.1,3,5,3.1,5,3.3V9h14V7z" />
    <polygon class="st0" points="16,3.4 16,6 18.5,6 	" />
    <rect x="10" y="11" class="st0" width="1" height="2" />
    <polygon class="st0" points="15,12 17,12 17,17 18,17 18,12 20,12 20,11 15,11 	" />
    <rect x="13" y="11" class="st0" width="1" height="2" />
    <polygon class="st0" points="6,17 7,17 7,12 9,12 9,11 4,11 4,12 6,12 	" />
    <path class="st0" d="M5,20.7C5,20.9,5.1,21,5.3,21h13.4c0.2,0,0.3-0.1,0.3-0.3V19H5V20.7z" />
    <path
      class="st1"
      d="M21.5,9H20V6.5c0-0.4-0.2-0.6-0.4-0.8l-3.2-3.3C16.2,2.1,15.8,2,15.5,2H5.3C4.6,2,4,2.6,4,3.3V9H2.6
                                                C1.7,9,1,9.7,1,10.5v6.9C1,18.3,1.7,19,2.6,19H4v1.7C4,21.4,4.6,22,5.3,22h13.4c0.7,0,1.3-0.6,1.3-1.3V19h1.5
                                                c0.9,0,1.5-0.7,1.5-1.5v-6.9C23,9.7,22.3,9,21.5,9z M16,3.4L18.5,6H16V3.4z M5,3.3C5,3.1,5.1,3,5.3,3H15v3.5C15,6.8,15.2,7,15.5,7
                                                H19v2H5V3.3z M14,15v2h-1v-2h-2v2h-1v-2h1v-2h-1v-2h1v2h2v-2h1v2h-1v2H14z M4,12v-1h5v1H7v5H6v-5H4z M19,20.7
                                                c0,0.2-0.1,0.3-0.3,0.3H5.3C5.1,21,5,20.9,5,20.7V19h14V20.7z M20,12h-2v5h-1v-5h-2v-1h5V12z"
    />
  </g>
</svg>
<svg *ngIf="name === 'icon-file-xml-b'" class="icon-file-xml-b">
  <g>
    <rect x="8" y="11" class="st0" width="1" height="2" />
    <polygon
      class="st0"
      points="15,17 15,12 15,11 10,11 10,12 10,17 11,17 11,12 12,12 12,17 13,17 13,12 14,12 14,17 	"
    />
    <rect x="5" y="11" class="st0" width="1" height="2" />
    <path class="st0" d="M19,7h-3.5C15.2,7,15,6.8,15,6.5V3H5.3C5.1,3,5,3.1,5,3.3V9h14V7z" />
    <polygon class="st0" points="16,3.4 16,6 18.5,6 	" />
    <rect x="5" y="15" class="st0" width="1" height="2" />
    <rect x="6" y="13" class="st0" width="2" height="2" />
    <polygon class="st0" points="17,11 16,11 16,17 20,17 20,16 17,16 	" />
    <path class="st0" d="M5,20.7C5,20.9,5.1,21,5.3,21h13.4c0.2,0,0.3-0.1,0.3-0.3V19H5V20.7z" />
    <rect x="8" y="15" class="st0" width="1" height="2" />
    <path
      class="st1"
      d="M21.5,9H20V6.5c0-0.4-0.2-0.6-0.4-0.8l-3.2-3.3C16.2,2.2,15.8,2,15.5,2H5.3C4.6,2,4,2.6,4,3.3V9H2.6
                                                C1.7,9,1,9.7,1,10.5v7C1,18.4,1.7,19,2.6,19h1.5v1.7c0,0.7,0.6,1.3,1.3,1.3h13.4c0.7,0,1.3-0.6,1.3-1.3V19h1.4
                                                c0.9,0,1.5-0.7,1.5-1.5v-7C23,9.7,22.3,9,21.5,9z M16,3.4L18.5,6H16V3.4z M5,3.3C5,3.1,5.1,3,5.3,3H15v3.5C15,6.8,15.2,7,15.5,7H19
                                                v2H5V3.3z M13,12v5h-1v-5h-1v5h-1v-5v-1h5v1v5h-1v-5H13z M5,15h1v-2H5v-2h1v2h2v-2h1v2H8v2h1v2H8v-2H6v2H5V15z M19,20.7
                                                c0,0.2-0.1,0.3-0.3,0.3H5.3C5.1,21,5,20.9,5,20.7V19h14V20.7z M20,17h-4v-6h1v5h3V17z"
    />
  </g>
</svg>
<svg *ngIf="name === 'icon-file-xls'" class="icon-file-xls">
  <g>
    <g>
      <path
        class="st0"
        d="M21.5,9H20V6.5c0-0.4-0.2-0.6-0.4-0.8l-3.2-3.3C16.2,2.1,15.8,2,15.5,2H5.3C4.6,2,4,2.6,4,3.3V9H2.6
                                                    C1.7,9,1,9.7,1,10.5v6.9C1,18.3,1.7,19,2.6,19H4v1.7C4,21.4,4.6,22,5.3,22h13.4c0.7,0,1.3-0.6,1.3-1.3V19h1.5
                                                    c0.9,0,1.5-0.7,1.5-1.5v-6.9C23,9.7,22.3,9,21.5,9z M18.5,6H16V3.4L18.5,6z M5,3.3C5,3.1,5.1,3,5.3,3H15v3.5C15,6.8,15.2,7,15.5,7
                                                    H19v2H5V3.3z M14,16v1h-4v-6h1v5H14z M5,17H4v-2h1v-2H4v-2h1v2h2v-2h1v2H7v2h1v2H7v-2H5V17z M19,20.7c0,0.2-0.1,0.3-0.3,0.3H5.3
                                                    C5.1,21,5,20.9,5,20.7V19h14V20.7z M19,12h-3v1h2v1h1v2h-1v1h-3v-1h3v-2h-2v-1h-1v-1h1v-1h3V12z"
      />
    </g>
  </g>
</svg>

<svg *ngIf="name === 'icon-file-xlsx'" class="icon-file-xlsx">
  <g>
    <g>
      <path
        class="st0"
        d="M21.5,9H20V6.5c0-0.4-0.2-0.6-0.4-0.8l-3.2-3.3C16.2,2.1,15.8,2,15.5,2H5.3C4.6,2,4,2.6,4,3.3V9H2.6
                                                    C1.7,9,1,9.7,1,10.5v6.9C1,18.3,1.7,19,2.6,19H4v1.7C4,21.4,4.6,22,5.3,22h13.4c0.7,0,1.3-0.6,1.3-1.3V19h1.5
                                                    c0.9,0,1.5-0.7,1.5-1.5v-6.9C23,9.7,22.3,9,21.5,9z M18.5,6H16V3.4L18.5,6z M5,3.3C5,3.1,5.1,3,5.3,3H15v3.5C15,6.8,15.2,7,15.5,7
                                                    H19v2H5V3.3z M15,13v1h1v2h-1v1h-3v-1h3v-2h-2v-1h-1v-1h1v-1h3v1h-3v1H15z M11,16v1H8v-6h1v5H11z M4,15v2H3v-2H4v-2H3v-2h1v2h2v-2
                                                    h1v2H6v2h1v2H6v-2H4z M19,20.7c0,0.2-0.1,0.3-0.3,0.3H5.3C5.1,21,5,20.9,5,20.7V19h14V20.7z M21,13h-1v2h1v2h-1v-2h-2v2h-1v-2h1
                                                    v-2h-1v-2h1v2h2v-2h1V13z"
      />
    </g>
  </g>
  <rect class="st1" width="24" height="24" />
</svg>
<svg *ngIf="name === 'icon-file-b'" class="icon-file-b">
  <g>
    <g>
      <path
        class="st0"
        d="M4,19v1.7C4,21.4,4.6,22,5.3,22h13.4c0.7,0,1.3-0.6,1.3-1.3V19V9V6.5c0-0.4-0.2-0.6-0.4-0.8l-3.2-3.3
                                                    C16.2,2.1,15.8,2,15.5,2H5.3C4.6,2,4,2.6,4,3.3V9V19z M18.5,6H16V3.4L18.5,6z M5,9V3.3C5,3.1,5.1,3,5.3,3H15v3.5
                                                    C15,6.8,15.2,7,15.5,7H19v2v10v1.7c0,0.2-0.1,0.3-0.3,0.3H5.3C5.1,21,5,20.9,5,20.7V19V9z"
      />
    </g>
  </g>
</svg>

<svg *ngIf="name === 'icon-dataset-another-focus'" class="icon-dataset-another-focus">
  <g class="st0">
    <path class="st1" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st2"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g>
    <path class="st3" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st4"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g>
    <g>
      <path
        class="st5"
        d="M36.2,17H19.8c-1.5,0-2.8,1.2-2.8,2.8v16.5c0,1.5,1.2,2.8,2.8,2.8h4.8c0,0,0,0,0,0s0,0,0,0h6.9c0,0,0,0,0,0
                                s0,0,0,0h4.8c1.5,0,2.8-1.2,2.8-2.8V19.8C39,18.2,37.8,17,36.2,17z M20.4,18.4h15.1c1.1,0,2.1,0.9,2.1,2.1v3.4h-6.2c0,0,0,0,0,0
                                s0,0,0,0h-6.9c0,0,0,0,0,0s0,0,0,0h-6.2v-3.4C18.4,19.3,19.3,18.4,20.4,18.4z M30.8,25.3v12.4h-5.5V25.3H30.8z M18.4,35.6V25.3
                                h5.5v12.4h-3.4C19.3,37.6,18.4,36.7,18.4,35.6z M35.6,37.6h-3.4V25.3h5.5v10.3C37.6,36.7,36.7,37.6,35.6,37.6z"
      />
    </g>
  </g>
</svg>
<svg *ngIf="name === 'icon-dataset-another'" class="icon-dataset-another">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g>
    <g>
      <path
        class="st2"
        d="M36.2,17H19.8c-1.5,0-2.8,1.2-2.8,2.8v16.5c0,1.5,1.2,2.8,2.8,2.8h4.8c0,0,0,0,0,0s0,0,0,0h6.9c0,0,0,0,0,0
                                s0,0,0,0h4.8c1.5,0,2.8-1.2,2.8-2.8V19.8C39,18.2,37.8,17,36.2,17z M20.4,18.4h15.1c1.1,0,2.1,0.9,2.1,2.1v3.4h-6.2c0,0,0,0,0,0
                                s0,0,0,0h-6.9c0,0,0,0,0,0s0,0,0,0h-6.2v-3.4C18.4,19.3,19.3,18.4,20.4,18.4z M30.8,25.3v12.4h-5.5V25.3H30.8z M18.4,35.6V25.3
                                h5.5v12.4h-3.4C19.3,37.6,18.4,36.7,18.4,35.6z M35.6,37.6h-3.4V25.3h5.5v10.3C37.6,36.7,36.7,37.6,35.6,37.6z"
      />
    </g>
  </g>
</svg>
<svg *ngIf="name === 'icon-dataset-wrangled-delete'" class="icon-dataset-wrangled-delete">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g>
    <g>
      <g>
        <path
          class="st2"
          d="M37.6,35.6c0,1.1-0.9,2.1-2.1,2.1h-3.4V25.3h0.5c-0.6-0.4-1.1-0.9-1.5-1.4h-6.5l0,0l0,0h-6.2v-3.4
                                    c0-1.1,0.9-2.1,2.1-2.1h9c0-0.5,0.1-0.9,0.3-1.4h-9.9c-1.5,0-2.7,1.2-2.7,2.7v16.5c0,1.5,1.2,2.7,2.7,2.7h4.8l0,0l0,0h6.9l0,0
                                    l0,0h4.8c1.5,0,2.7-1.2,2.7-2.7v-9.9c-0.4,0.1-0.9,0.2-1.4,0.3L37.6,35.6L37.6,35.6z M23.9,37.6h-3.4c-1.1,0-2.1-0.9-2.1-2.1
                                    V25.3h5.5V37.6z M30.8,37.6h-5.5V25.3h5.5V37.6z M36.9,12.9c-3.4,0-6.2,2.8-6.2,6.2s2.8,6.2,6.2,6.2s6.2-2.8,6.2-6.2
                                    C43.1,15.6,40.4,12.9,36.9,12.9z"
        />
      </g>
    </g>
    <path
      class="st0"
      d="M38,19.6c0.3-0.1,0.5-0.3,0.7-0.6c0.2-0.2,0.3-0.6,0.3-1c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.3-0.4-0.5
                            c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5-0.1-0.8-0.1h-2v5.2h1.3v-1.9h0.5l1.5,1.9h1.6L38,19.6z M37.6,18.5c0,0.1-0.1,0.2-0.2,0.2
                            c-0.1,0.1-0.2,0.1-0.3,0.1s-0.3,0-0.5,0h-0.4v-1.4h0.4c0.2,0,0.3,0,0.4,0c0.1,0,0.2,0,0.3,0.1s0.2,0.1,0.3,0.2s0.1,0.2,0.1,0.3
                            C37.7,18.3,37.7,18.4,37.6,18.5z"
    />
  </g>
</svg>
<svg *ngIf="name === 'icon-dataset-wrangled-fous'" class="icon-dataset-wrangled-fous">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g>
    <g>
      <g>
        <path
          class="st2"
          d="M37.6,35.6c0,1.1-0.9,2.1-2.1,2.1h-3.4V25.3h0.5c-0.6-0.4-1.1-0.9-1.5-1.4h-6.5c0,0,0,0,0,0s0,0,0,0h-6.2
                                    v-3.4c0-1.1,0.9-2.1,2.1-2.1h9c0-0.5,0.1-0.9,0.3-1.4h-9.9c-1.5,0-2.7,1.2-2.7,2.7v16.5c0,1.5,1.2,2.7,2.7,2.7h4.8c0,0,0,0,0,0
                                    s0,0,0,0h6.9c0,0,0,0,0,0s0,0,0,0h4.8c1.5,0,2.7-1.2,2.7-2.7v-9.9c-0.4,0.1-0.9,0.2-1.4,0.3V35.6z M23.9,37.6h-3.4
                                    c-1.1,0-2.1-0.9-2.1-2.1V25.3h5.5V37.6z M30.8,37.6h-5.5V25.3h5.5V37.6z M36.9,12.9c-3.4,0-6.2,2.8-6.2,6.2
                                    c0,3.4,2.8,6.2,6.2,6.2s6.2-2.8,6.2-6.2C43.1,15.6,40.4,12.9,36.9,12.9z"
        />
      </g>
    </g>
    <path
      class="st0"
      d="M38,19.6c0.3-0.1,0.5-0.3,0.7-0.6c0.2-0.2,0.3-0.6,0.3-1c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.3-0.4-0.5
                            c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5-0.1-0.8-0.1H35v5.2h1.3v-1.9h0.5l1.5,1.9h1.6L38,19.6z M37.6,18.5c0,0.1-0.1,0.2-0.2,0.2
                            c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.5,0h-0.4v-1.4h0.4c0.2,0,0.3,0,0.4,0c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.3,0.2
                            c0.1,0.1,0.1,0.2,0.1,0.3C37.7,18.3,37.7,18.4,37.6,18.5z"
    />
  </g>
</svg>
<svg *ngIf="name === 'icon-dataset-wangled'" class="icon-dataset-wangled">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g>
    <g>
      <g>
        <path
          class="st2"
          d="M37.6,35.6c0,1.1-0.9,2.1-2.1,2.1h-3.4V25.3h0.5c-0.6-0.4-1.1-0.9-1.5-1.4h-6.5c0,0,0,0,0,0s0,0,0,0h-6.2
                                    v-3.4c0-1.1,0.9-2.1,2.1-2.1h9c0-0.5,0.1-0.9,0.3-1.4h-9.9c-1.5,0-2.7,1.2-2.7,2.7v16.5c0,1.5,1.2,2.7,2.7,2.7h4.8c0,0,0,0,0,0
                                    s0,0,0,0h6.9c0,0,0,0,0,0s0,0,0,0h4.8c1.5,0,2.7-1.2,2.7-2.7v-9.9c-0.4,0.1-0.9,0.2-1.4,0.3V35.6z M23.9,37.6h-3.4
                                    c-1.1,0-2.1-0.9-2.1-2.1V25.3h5.5V37.6z M30.8,37.6h-5.5V25.3h5.5V37.6z M36.9,12.9c-3.4,0-6.2,2.8-6.2,6.2
                                    c0,3.4,2.8,6.2,6.2,6.2s6.2-2.8,6.2-6.2C43.1,15.6,40.4,12.9,36.9,12.9z"
        />
      </g>
    </g>
    <path
      class="st0"
      d="M38,19.6c0.3-0.1,0.5-0.3,0.7-0.6c0.2-0.2,0.3-0.6,0.3-1c0-0.3-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.3-0.4-0.5
                            c-0.2-0.1-0.4-0.2-0.6-0.2c-0.2,0-0.5-0.1-0.8-0.1H35v5.2h1.3v-1.9h0.5l1.5,1.9h1.6L38,19.6z M37.6,18.5c0,0.1-0.1,0.2-0.2,0.2
                            c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.5,0h-0.4v-1.4h0.4c0.2,0,0.3,0,0.4,0c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.1,0.3,0.2
                            c0.1,0.1,0.1,0.2,0.1,0.3C37.7,18.3,37.7,18.4,37.6,18.5z"
    />
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-custom-focus'" class="icon-chart-custom-focus">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <path
    class="st2"
    d="M39.6,19.7L30,14.5c-1.1-0.7-2.4-0.7-3.6,0l-9.6,5.3c-1.3,0.5-2.1,1.7-2.1,3.1v10.3c0,1.3,0.7,2.5,1.9,3.2
                        l9.6,5.2c1.1,0.7,2.4,0.7,3.6,0l9.6-5.3c1.2-0.7,1.9-1.9,1.9-3.2V22.8C41.5,21.6,40.7,20.3,39.6,19.7z M34,31.1
                        c-0.4,1.3-1.1,2.5-2.1,3.2c-1.1,0.8-2.3,1.1-3.7,1.1c-1.3,0-2.4-0.3-3.3-0.9c-0.9-0.5-1.5-1.2-2-1.9c-0.1-0.3-0.3-0.4-0.3-0.7
                        c-0.1-0.3-0.1-0.5-0.3-0.8c-0.1-0.5-0.1-1.7-0.1-3.3c0-1.7,0-2.8,0.1-3.3c0.3-0.5,0.4-0.9,0.7-1.3c0.4-0.7,1.1-1.3,1.9-1.9
                        c0.8-0.4,1.9-0.7,3.2-0.7c1.6,0,2.9,0.4,4,1.3c1.1,0.8,1.6,1.9,1.9,3.2l0.1,0.4h-3.3v-0.4c-0.1-0.5-0.5-0.9-0.9-1.3
                        c-0.4-0.3-1.1-0.5-1.7-0.5c-0.5,0-0.9,0.1-1.2,0.3c-0.4,0.1-0.7,0.4-0.8,0.7c-0.3,0.3-0.4,0.7-0.5,1.1c-0.1,0.5-0.3,1.5-0.3,2.8
                        s0,2.3,0.1,2.8c0.1,0.4,0.3,0.8,0.5,1.1c0.3,0.3,0.5,0.5,0.8,0.7c0.4,0.1,0.8,0.3,1.2,0.3c1.2,0,2-0.5,2.7-1.7l0.1-0.3h3.2v0.3H34z"
  />
</svg>
<svg *ngIf="name === 'icon-chart-custom'" class="icon-chart-custom">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <path
    class="st2"
    d="M39.6,19.7L30,14.5c-1.1-0.7-2.4-0.7-3.6,0l-9.6,5.3c-1.3,0.5-2.1,1.7-2.1,3.1v10.3c0,1.3,0.7,2.5,1.9,3.2
                        l9.6,5.2c1.1,0.7,2.4,0.7,3.6,0l9.6-5.3c1.2-0.7,1.9-1.9,1.9-3.2V22.8C41.5,21.6,40.7,20.3,39.6,19.7z M34,31.1
                        c-0.4,1.3-1.1,2.5-2.1,3.2c-1.1,0.8-2.3,1.1-3.7,1.1c-1.3,0-2.4-0.3-3.3-0.9c-0.9-0.5-1.5-1.2-2-1.9c-0.1-0.3-0.3-0.4-0.3-0.7
                        c-0.1-0.3-0.1-0.5-0.3-0.8c-0.1-0.5-0.1-1.7-0.1-3.3c0-1.7,0-2.8,0.1-3.3c0.3-0.5,0.4-0.9,0.7-1.3c0.4-0.7,1.1-1.3,1.9-1.9
                        c0.8-0.4,1.9-0.7,3.2-0.7c1.6,0,2.9,0.4,4,1.3c1.1,0.8,1.6,1.9,1.9,3.2l0.1,0.4h-3.3v-0.4c-0.1-0.5-0.5-0.9-0.9-1.3
                        c-0.4-0.3-1.1-0.5-1.7-0.5c-0.5,0-0.9,0.1-1.2,0.3c-0.4,0.1-0.7,0.4-0.8,0.7c-0.3,0.3-0.4,0.7-0.5,1.1c-0.1,0.5-0.3,1.5-0.3,2.8
                        s0,2.3,0.1,2.8c0.1,0.4,0.3,0.8,0.5,1.1c0.3,0.3,0.5,0.5,0.8,0.7c0.4,0.1,0.8,0.3,1.2,0.3c1.2,0,2-0.5,2.7-1.7l0.1-0.3h3.2v0.3H34z"
  />
</svg>
<svg *ngIf="name === 'icon-chart-druid-focus'" class="icon-chart-druid-focus">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g>
    <path
      class="st2"
      d="M32.2,38.6h-5.4c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h5.4c2.5,0,8.5-2.9,8.5-10c0-8-7.1-8.3-7.4-8.3H21.6
                            c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7h11.7c0.1,0,8.8,0.4,8.8,9.8C42.1,34.8,35.8,38.6,32.2,38.6z"
    />
    <path
      class="st2"
      d="M28.8,34.4H18.1c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h10.7c2.1,0,6.9-0.5,6.9-5.5c0-4.2-4.5-4.2-4.7-4.2H20
                            c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7h11c2.1,0,6.1,1.2,6.1,5.7C37.2,32.5,32.9,34.4,28.8,34.4z"
    />
    <path
      class="st2"
      d="M23.5,38.6h-2c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h2c0.4,0,0.7,0.3,0.7,0.7S23.9,38.6,23.5,38.6z"
    />
    <path
      class="st2"
      d="M17,23.2h-2.1c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7H17c0.4,0,0.7,0.3,0.7,0.7
                            C17.7,22.9,17.4,23.2,17,23.2z"
    />
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-druid'" class="icon-chart-druid">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g>
    <path
      class="st2"
      d="M32.2,38.6h-5.4c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h5.4c2.5,0,8.5-2.9,8.5-10c0-8-7.1-8.3-7.4-8.3H21.6
                            c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7h11.7c0.1,0,8.8,0.4,8.8,9.8C42.1,34.8,35.8,38.6,32.2,38.6z"
    />
    <path
      class="st2"
      d="M28.8,34.4H18.1c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h10.7c2.1,0,6.9-0.5,6.9-5.5c0-4.2-4.5-4.2-4.7-4.2H20
                            c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7h11c2.1,0,6.1,1.2,6.1,5.7C37.2,32.5,32.9,34.4,28.8,34.4z"
    />
    <path
      class="st2"
      d="M23.5,38.6h-2c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h2c0.4,0,0.7,0.3,0.7,0.7S23.9,38.6,23.5,38.6z"
    />
    <path
      class="st2"
      d="M17,23.2h-2.1c-0.4,0-0.7-0.3-0.7-0.7c0-0.4,0.3-0.7,0.7-0.7H17c0.4,0,0.7,0.3,0.7,0.7
                            C17.7,22.9,17.4,23.2,17,23.2z"
    />
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-hive-focus'" class="icon-chart-hive-focus">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g id="Layer_3">
    <g transform="translate(-.53268 66.8)">
      <path
        class="st2"
        d="M30.8-49.9c-0.3-0.8-9.7-4.4-9.9-4c-1.7,0.4-2.4,2.4-3.6,3.7l-2.7-0.1c-1.2,1.5-1.7,3.1-1.3,4.7
                                c1.5,2,3.2,3.7,4.3,6.3c0.1,1.2,5.2,0.1,6.3-0.1c-1.5,1.1-2.8,2.7-2.3,7.5c0.5,2.3,0.8,4.8,5.3,7.3c0.5,0.4,1.9,0.7,2.5,0.9
                                c0.7,0.4,1.6,0.5,4-0.3c1.3-0.5,2.9-0.9,4.3-1.6l-1.6,0.1c-2.3,0-4,0.3-4.5-0.4l-2.1-3.6l1.2-2.4c1.7,0.1,2.3,1.5,3.7,2l1.7-1.3
                                c5.1,2.9,7.1-1.9,7.6-5.7c0-1.6-3.3,0.5-3.2-0.3c0.1-1.6-1.3-3.9-2.1-5.5l1.1-3.9c0.4-0.4-2.1-4.8-4.3-5.3
                                C33.6-52.3,30.8-49.9,30.8-49.9L30.8-49.9z"
      />
    </g>
    <g>
      <g transform="translate(-.53268 66.8)">
        <path
          class="st3"
          d="M39.2-41.6c0,0.4,0,0.9,0,1.2c0,0.3-0.1,0.3-0.4,0.3l0.9,0.4c0.1,0.3,0.4,0.7,0.5,0.9c0.1,0.8,0,0.8-0.1,0.9
                                    c-0.4,0-0.7,0-1.1,0c0.1,0.1,0.1,0.1,0.1,0.3c0,0.1-0.1,0.4-0.3,0.7c0.3,0.1,0.8,0.3,1.2,0.5l0.4-0.9
                                    C40.5-38.9,40-40.3,39.2-41.6L39.2-41.6z"
        />
        <path
          class="st3"
          d="M40.9-36.7c0.5,0.3,1.3,0.1,2.4-0.4c0.1-0.1,0.3,0,0,0.1C42-35.6,41.3-36.3,40.9-36.7L40.9-36.7z"
        />
        <path
          class="st3"
          d="M43.4-37.2c0.1,0.3,0,0.5,0,0.8c-0.1,1.3-0.5,2.4-1.3,3.5c-2.7,4.3-5.3,1.6-7.5-0.1l-0.9,2.3
                                    c0,0.1-0.3,0.4,1.2,0.9l1.5-0.9C41.6-26.8,45.3-36.5,43.4-37.2L43.4-37.2z"
        />
        <path class="st3" d="M35.2-33.3c-0.4,0-1.5,0.4-1.5,0.7l0.5-0.8L35.2-33.3z" />
        <path
          class="st3"
          d="M34.5-37.9c0,0,0.4,0.1,0.4,0c0,0,0,0.4,0.1,0.7l-0.5,0.9c0.8-0.8,1.9-0.8,3.1-0.9L37-37.5
                                    c0.1-0.3,0-0.5,0-0.8L34.5-37.9z"
        />
        <path
          class="st3"
          d="M36.4-40.4c-0.8,0.3-1.6,0.7-2.1,1.5c0.4-1.5,0.5-1.3,0.5-1.5C35.4-40.5,35.8-40.4,36.4-40.4L36.4-40.4z"
        />
      </g>
      <g transform="translate(-.53268 66.8)">
        <path
          class="st4"
          d="M32.1-24c-0.9,1.1-3.1-0.1-4.9-0.7c-4.5-2.9-5.5-5.6-5.5-8.3c-0.3-3.9,0.9-6,2.4-6.5
                                    c-0.9,1.9-1.5,5.3-0.9,7.9c0.4,1.5,0.4,3.7,2.7,5.1c1.2,0.7,0.8,1.2,1.6,1.7C28.4-24.3,30.6-23.6,32.1-24L32.1-24z"
        />
        <path
          class="st4"
          d="M33.4-41.6c-1.6-2.3-3.9-3.2-6.5-3.3c0.5-0.1,1.1-0.3,1.6-0.5c0.1-0.1,0.1-0.4,0-0.7c-2.5-0.7-4-1.5-6-2.1
                                    l5.3,1.3C31.6-46.8,31.2-45.9,33.4-41.6L33.4-41.6z"
        />
        <path
          class="st4"
          d="M35.3-42.7c-1.2-1.7-2.4-3.9-4-5.2c-3.9-1.7-7.6-3.1-12-4.1l1.5-1.9c4.7,1.1,9.2,2.9,13.1,5.9
                                    C34.4-46.3,34.9-44.7,35.3-42.7L35.3-42.7z"
        />
        <path
          class="st4"
          d="M37.7-45.7c0,0-0.7-1.5-1.1-2.1c-0.8-0.8-1.2-2.4-2.7-3.2c0.5,0.1,0.8,0,1.9,0.9l1.6,3.1L37.7-45.7z"
        />
        <path
          class="st4"
          d="M38.5-43.2c0.1-1.3,0.4-3.5,0.1-4.3c-0.9-1.3-1.9-2.5-2.8-3.7c-0.1-0.1-0.3-0.4-0.4-0.5
                                    c1.3,0.4,2.8,1.3,4.7,4.9L38.5-43.2z"
        />
      </g>
      <g transform="translate(-.53268 66.8)">
        <path
          d="M32.9-42.9c-0.5-0.4-1.1-1.9-1.9-2.3c-0.8,0-1.1-0.3-2-0.1c0.4-0.1,0.7-0.4,1.1-0.5c0.3,0,0.5,0,0.8,0c0,0,0.1,0,0.1-0.1
                                    c-0.9-0.7-3.1-0.7-4.4-0.9c1.6,0,3.3,0,4.5,0.4C32.1-45.7,32.4-44.1,32.9-42.9L32.9-42.9z"
        />
        <path
          d="M35.8-50.1c0.3,0,1.5,1.9,1.5,2.3c0.1,0.7,0.4,1.5,0.4,2.1c-0.1-0.7-0.4-1.3-0.7-2c-0.1-0.1-0.3-0.7-0.8-1.2
                                    C36-49.5,35.8-49.9,35.8-50.1L35.8-50.1z"
        />
        <path d="M39.8-39.9h-0.5H39.8L39.8-39.9z" />
        <path d="M36.4-40.4c-0.5,0-1.1-0.1-1.6,0c-0.3,0.3-0.3,0.5-0.3,0.8C35.2-40.3,35.3-40.3,36.4-40.4L36.4-40.4z" />
        <path d="M43.3-36.9c-0.3,0.3-0.3,0.4-1.2,0.7c-0.7,0.1-0.9-0.1-1.2-0.4c0.4,0.1,0.5,0.4,1.7,0.1L43.3-36.9z" />
        <path
          d="M40.8-36.8c-0.3,0.5-0.4,1.1-0.7,1.6c-0.5,0.7-0.3,0.3-1.1,1.2c0.3-0.4,0.5-0.8,0.8-1.2c0.1-0.3,0.3-0.5,0.4-0.8
                                    c-0.3-0.1-0.7-0.1-0.7-0.1c-0.7,0.4-0.8,0.8-1.2,1.3c0.3-0.5,0.5-1.1,0.9-1.5c0,0,0.3-0.1,0.4-0.1c-0.3,0-0.7-0.1-0.8-0.1
                                    c-0.4,0.1-0.7,0.5-1.1,0.8c0.3-0.4,0.5-0.7,0.8-1.1c0,0,0.9,0,1.3,0.3v0.1l0.3,0.1l0.1-0.5L40.8-36.8z"
        />
        <path d="M35.2-33.3c-0.1,0-1.1-0.1-1.1-0.1c-0.4,0.3-0.4,0.5-0.4,0.8C34.2-33.3,34.2-33.3,35.2-33.3L35.2-33.3z" />
        <path
          d="M34.4-37.9c0,0,0.7-0.1,0.7,0c0,0.3,0.1,0.8,0.1,0.8l-0.4,0.3c0.8-0.4,1.7-0.4,2.7-0.4c0,0-0.8-0.3-0.7-0.3
                                    c0.1,0,0-0.8-0.1-0.8c0.1,0,0.3-0.1,0.4-0.1C36-38.7,35.4-38.5,34.4-37.9L34.4-37.9z"
        />
        <path
          d="M40.6-37.3l-0.1,0.1c0-0.5-0.5-0.4-0.9-0.4l-0.4-0.1c0.1,0.1,0.4,0.1,0.3,0.3c-0.1,0-0.1,0.3-0.3,0.5H39
                                    c0.4,0.1,0.7,0.3,1.1,0.4h0.3l0.5-0.3L40.6-37.3z"
        />
      </g>
      <g transform="translate(-.53268 66.8)">
        <path
          class="st0"
          d="M35.8-37.6c-0.1-0.1-0.5-0.1-0.5,0c-0.1,0.1,0,0.7,0.1,0.5c0.1-0.1,0.3-0.1,0.5-0.1
                                    C36-37.5,36-37.5,35.8-37.6L35.8-37.6z"
        />
        <path
          class="st0"
          d="M39.8-36.8c0,0,0.1-0.3,0.1-0.4s-0.4-0.1-0.4-0.1c-0.1,0-0.1,0.4-0.1,0.4C39.3-36.8,39.7-36.8,39.8-36.8
                                    L39.8-36.8z"
        />
      </g>
      <g transform="translate(-.53268 66.8)">
        <path d="M23.6-27.5h0.3c1.5,0.7,3.7,1.7,7.2,1.2l0.5,0.8c-1.5,0.7-3.1,0.5-4.7,0.7L23.6-27.5z" />
        <path d="M21.4-32.9l0.7,2.9c1.9,1.2,5.2,2.5,7.9,2.4l-0.7-1.3C23.8-30.1,23.3-31.6,21.4-32.9L21.4-32.9z" />
        <path
          d="M23.3-39.2c0.4,2.3,0.8,4.1,2.8,5.6c1.2,0.9,2.5,1.7,4,2.5c0,0-0.1,0.7-0.3,0.7c-4.4-0.5-7.7-4.1-8.1-5.9
                                    C22.1-37.6,22.6-38.4,23.3-39.2L23.3-39.2z"
        />
        <path
          d="M25.4-40.4c0.5,2.5,2,5.2,3.5,7.6c0.5,0.7,0.7,0.9,1.5,1.3c1.1,0.4,1.9,0.3,2.7,0.3c-0.3-0.4-0.4-0.8-0.7-1.2
                                    c-1.7-1.3-0.9-2.7-0.5-3.5c-0.9-0.3-2.3-0.8-2.4-1.5c-0.3-2.3-0.1-2.9,0.1-4C28.1-41.2,26.8-40.9,25.4-40.4L25.4-40.4z"
        />
        <path
          class="st5"
          d="M20.6-54.1c-0.4,0.3-0.8,0.7-1.3,1.2c-0.8,0.8-1.2,1.5-2,2.1c-0.1,0.1-0.5,0.4-1.1,0.4c-0.3,0-0.4,0-0.9,0
                                    c-0.4-0.3-0.8-0.1-1.2,0.4c-0.4,0.4-0.9,1.6-1.1,2.3c-0.4,1.9,0.7,3.3,1.7,4.4s1.6,1.6,2,2.5c0.3,0.5,0.5,1.3,0.8,1.9
                                    c0.1,0.1,0.1,0.1,0.3,0.3c0.5,0.1,1.2,0.1,1.9,0.1c0.3,0,0.7,0,1.1,0c0.5-0.1,1.1-0.1,1.6-0.4c0.5-0.1,0.9-0.3,1.2-0.4
                                    c0,0.1-0.4,0.3-0.5,0.5c-1.3,1.9-1.6,3.5-1.5,6c0.1,1.2,0.4,2.1,0.8,3.2c0.1,0.5,0.5,1.3,0.9,1.7c1.1,1.6,3.6,3.6,6.9,4.3
                                    c0.5,0.1,1.3,0,1.9-0.1c1.7-0.5,5.1-1.7,5.1-1.7s-3.1,0.3-4.4,0.1c-0.3,0-0.7-0.1-0.9-0.3l-0.1-0.3c0.1,0,0.5-0.1,1.1-0.1
                                    c-1.2-0.1-1.1-0.1-1.2-0.3c-0.1-0.3-0.3-0.8-0.5-1.2c0.5,0,1.6,0.1,2-0.3c0,0-0.7,0.1-1.2,0c-0.1,0-0.5-0.1-0.7-0.1
                                    C30.9-28,30.6-28,30.6-28c-0.1-0.3-0.1-0.3-0.3-0.7s-0.1-0.8-0.1-1.3c0.4,0.5,0.8,0.8,1.5,1.1c0,0,0.8,0.4,1.5,0.1h0.1
                                    c0,0-0.4,0-0.5,0c-1.2-0.5-1.5-0.9-1.6-1.2l-0.5-0.8c0.1-0.3,0.3-0.3,0.4-0.4c0.7,0.1,0.9,0.1,1.3,0c0.3,0.5,0.4,1.1,0.9,1.5
                                    c2,0.5,2.4-0.1,2.9-0.8c1.5,1.1,3.7,1.3,5.5,0c2-2.4,2.7-6.1,2.5-6.4c-0.3-0.4-0.5-0.9-0.9-0.8c-1.1,0.3-1.5,0.8-2.4,0.7
                                    c0.1,0,0.3,0,0.3,0c0.1-0.9,0-1.3,0-1.3c-0.3-0.7-0.7-1.5-1.1-2c-0.1-0.1-0.3-0.9-0.7-1.3c-0.1-0.1-0.5-0.5-0.5-0.5v0.4
                                    c0,0,0.1,0,0.3,0.3c0.3,0.9,1.3,2.9,1.3,3.1c0.4,0.7,0,1.5,0.3,1.9c0,0,0.5,0,1.1,0c0.7-0.1,0.7-0.5,1.3-0.5
                                    c0.4,0,0.4,0.8,0.4,0.8c-0.1,0.9-0.4,1.9-0.8,2.8c-0.8,1.6-1.9,3.1-3.2,3.3C38-30,37-30.7,36.2-31.2l-0.4,0.3
                                    c-1.2,1.2-2.5,1.1-3.1-0.4c-0.3-0.5-0.7-0.9-0.9-1.5l-1.6,1.2c-0.1,0.3-0.3,0.7-0.5,1.2c-0.1,0.4-0.3,0.9-0.3,1.5
                                    c-0.3,0.4,0.8,1.9,1.3,2.7c0.1,0.3,0.5,0.7,0.5,0.8c0.1,0.3,0.4,0.5,0.4,0.7c1.1,1.5-1.5,1.1-1.9,1.1c-1.1-0.1-2-0.5-2.9-1.2
                                    l-0.1-0.1c-1.1-0.7-2.1-1.6-3.1-2.5c-0.5-0.5-1.1-1.9-1.3-2.7c-0.5-2.1-1.3-5.6,0.8-8.4c0-0.1,0.1-0.4,0.3-0.4
                                    c0.7-0.4,1.3-0.7,2.1-0.9l-0.1-0.5c-0.4,0.1-1.6,0.5-2,0.8c-0.8,0.3-1.5,0.5-2.5,0.8c-0.4,0-0.7,0-0.9,0c-0.7-0.1-2-0.1-2-0.1
                                    c-0.5-0.7-0.7-2-1.1-2.7l0,0l0,0c-0.3-0.4-0.5-0.7-0.9-0.9c-1.1-1.1-2-2.1-2.3-3.2c0-0.3-0.3-0.7-0.1-1.6l0,0l0,0
                                    c0.3-0.9,0.7-1.6,1.5-2.4c0.8,0,1.5,0,2.1,0.1c0.3,0,0.8,0.1,1.3,0.3c1.5,0.5,3.3,1.5,3.3,1.5c-1.5-0.8-3.1-1.9-4.1-2
                                    c-0.1,0-0.3-0.1-0.3-0.3c1.5-0.9,1.9-2,2.8-2.9c0.4-0.3,0.7-0.3,1.1-0.4c3.6,0.5,5.9,2,7.7,2.9c0.7,0.4,1.3,0.7,2,1.1
                                    c0.5,0.1,2.3,1.5,2.7,2.1c0.5,1.1,0.8,2.1,1.2,3.3c0.3,1.1,0.4,1.6,0.4,1.6s-0.3-0.9-0.1-1.1c0.3,0.1,0.7,0.3,0.9,0.3
                                    c0,0-0.9-0.5-1.1-0.9c-0.4-1.3-0.8-3.5-0.9-3.6c-0.3-0.4-1.5-1.3-2.3-1.7c-0.3-0.1-0.4-0.3-0.4-0.4c0.3-0.3,0.5-0.5,0.8-0.8
                                    c0.3-0.1,0.5-0.4,0.8-0.5c1.6-0.7,2.4,0.3,2.7,0.1c0,0-0.4-0.4-0.1-0.3c0.1,0.1,0.7,0.1,0.7,0.3c0.5,0.4,2,2.1,2.9,3.7
                                    c0.3,0.4,0.3,0.7,0.3,1.2c-0.1,0.5-0.1,0.8-0.3,1.1c-0.1,0.3-0.7,1.7-0.7,2c-0.1,0.8,0.4,1.9,0.4,1.9c0-0.3,0-0.4,0-0.7v-0.4
                                    v-0.1c0-0.3,0.1-0.5,0.1-0.7c0.1-1.1,0.5-1.9,0.8-2.9c0.1-0.3,0.3-0.4,0.3-0.5c0-0.3-0.3-0.8-0.5-1.2c-0.3-0.4-0.5-0.9-0.8-1.5
                                    c-0.8-1.1-1.5-2-2.7-2.5c-0.4-0.1-1.7-0.3-2.1-0.3c-0.5,0.1-1.1,0.3-1.5,0.5c-0.7,0.4-1.1,0.9-1.6,1.3c-1.2-0.7-1.9-1.1-2-1.2
                                    c-0.7-0.4-1.6-0.8-2.5-1.3C26-52.9,23.2-53.9,20.6-54.1L20.6-54.1z M36.4-31.2c-0.8-0.5-1.3-1.2-1.9-1.9
                                    c-0.1,0.8-0.7,1.2-1.1,1.7c-0.1,0.1-0.1,0.3,0.3,0.8c0.1,0.1,0.5,0.1,0.7,0.1c-0.3-0.1-0.7-0.4-0.7-0.5c0.4,0.3,0.8,0.4,1.2,0.4
                                    c0.1,0,0.1-0.1,0.3-0.3c0.1-0.4,0.3-0.4,0.4-0.5l0.3,0.4L36.4-31.2z"
        />
      </g>
    </g>
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-hive'" class="icon-chart-hive">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g id="Layer_3">
    <g transform="translate(-.53268 66.8)">
      <path
        class="st2"
        d="M30.8-49.9c-0.3-0.8-9.7-4.4-9.9-4c-1.7,0.4-2.4,2.4-3.6,3.7l-2.7-0.1c-1.2,1.5-1.7,3.1-1.3,4.7
                                c1.5,2,3.2,3.7,4.3,6.3c0.1,1.2,5.2,0.1,6.3-0.1c-1.5,1.1-2.8,2.7-2.3,7.5c0.5,2.3,0.8,4.8,5.3,7.3c0.5,0.4,1.9,0.7,2.5,0.9
                                c0.7,0.4,1.6,0.5,4-0.3c1.3-0.5,2.9-0.9,4.3-1.6l-1.6,0.1c-2.3,0-4,0.3-4.5-0.4l-2.1-3.6l1.2-2.4c1.7,0.1,2.3,1.5,3.7,2l1.7-1.3
                                c5.1,2.9,7.1-1.9,7.6-5.7c0-1.6-3.3,0.5-3.2-0.3c0.1-1.6-1.3-3.9-2.1-5.5l1.1-3.9c0.4-0.4-2.1-4.8-4.3-5.3
                                C33.6-52.3,30.8-49.9,30.8-49.9L30.8-49.9z"
      />
    </g>
    <g>
      <g transform="translate(-.53268 66.8)">
        <path
          class="st3"
          d="M39.2-41.6c0,0.4,0,0.9,0,1.2c0,0.3-0.1,0.3-0.4,0.3l0.9,0.4c0.1,0.3,0.4,0.7,0.5,0.9c0.1,0.8,0,0.8-0.1,0.9
                                    c-0.4,0-0.7,0-1.1,0c0.1,0.1,0.1,0.1,0.1,0.3c0,0.1-0.1,0.4-0.3,0.7c0.3,0.1,0.8,0.3,1.2,0.5l0.4-0.9
                                    C40.5-38.9,40-40.3,39.2-41.6L39.2-41.6z"
        />
        <path
          class="st3"
          d="M40.9-36.7c0.5,0.3,1.3,0.1,2.4-0.4c0.1-0.1,0.3,0,0,0.1C42-35.6,41.3-36.3,40.9-36.7L40.9-36.7z"
        />
        <path
          class="st3"
          d="M43.4-37.2c0.1,0.3,0,0.5,0,0.8c-0.1,1.3-0.5,2.4-1.3,3.5c-2.7,4.3-5.3,1.6-7.5-0.1l-0.9,2.3
                                    c0,0.1-0.3,0.4,1.2,0.9l1.5-0.9C41.6-26.8,45.3-36.5,43.4-37.2L43.4-37.2z"
        />
        <path class="st3" d="M35.2-33.3c-0.4,0-1.5,0.4-1.5,0.7l0.5-0.8L35.2-33.3z" />
        <path
          class="st3"
          d="M34.5-37.9c0,0,0.4,0.1,0.4,0c0,0,0,0.4,0.1,0.7l-0.5,0.9c0.8-0.8,1.9-0.8,3.1-0.9L37-37.5
                                    c0.1-0.3,0-0.5,0-0.8L34.5-37.9z"
        />
        <path
          class="st3"
          d="M36.4-40.4c-0.8,0.3-1.6,0.7-2.1,1.5c0.4-1.5,0.5-1.3,0.5-1.5C35.4-40.5,35.8-40.4,36.4-40.4L36.4-40.4z"
        />
      </g>
      <g transform="translate(-.53268 66.8)">
        <path
          class="st4"
          d="M32.1-24c-0.9,1.1-3.1-0.1-4.9-0.7c-4.5-2.9-5.5-5.6-5.5-8.3c-0.3-3.9,0.9-6,2.4-6.5
                                    c-0.9,1.9-1.5,5.3-0.9,7.9c0.4,1.5,0.4,3.7,2.7,5.1c1.2,0.7,0.8,1.2,1.6,1.7C28.4-24.3,30.6-23.6,32.1-24L32.1-24z"
        />
        <path
          class="st4"
          d="M33.4-41.6c-1.6-2.3-3.9-3.2-6.5-3.3c0.5-0.1,1.1-0.3,1.6-0.5c0.1-0.1,0.1-0.4,0-0.7c-2.5-0.7-4-1.5-6-2.1
                                    l5.3,1.3C31.6-46.8,31.2-45.9,33.4-41.6L33.4-41.6z"
        />
        <path
          class="st4"
          d="M35.3-42.7c-1.2-1.7-2.4-3.9-4-5.2c-3.9-1.7-7.6-3.1-12-4.1l1.5-1.9c4.7,1.1,9.2,2.9,13.1,5.9
                                    C34.4-46.3,34.9-44.7,35.3-42.7L35.3-42.7z"
        />
        <path
          class="st4"
          d="M37.7-45.7c0,0-0.7-1.5-1.1-2.1c-0.8-0.8-1.2-2.4-2.7-3.2c0.5,0.1,0.8,0,1.9,0.9l1.6,3.1L37.7-45.7z"
        />
        <path
          class="st4"
          d="M38.5-43.2c0.1-1.3,0.4-3.5,0.1-4.3c-0.9-1.3-1.9-2.5-2.8-3.7c-0.1-0.1-0.3-0.4-0.4-0.5
                                    c1.3,0.4,2.8,1.3,4.7,4.9L38.5-43.2z"
        />
      </g>
      <g transform="translate(-.53268 66.8)">
        <path
          d="M32.9-42.9c-0.5-0.4-1.1-1.9-1.9-2.3c-0.8,0-1.1-0.3-2-0.1c0.4-0.1,0.7-0.4,1.1-0.5c0.3,0,0.5,0,0.8,0c0,0,0.1,0,0.1-0.1
                                    c-0.9-0.7-3.1-0.7-4.4-0.9c1.6,0,3.3,0,4.5,0.4C32.1-45.7,32.4-44.1,32.9-42.9L32.9-42.9z"
        />
        <path
          d="M35.8-50.1c0.3,0,1.5,1.9,1.5,2.3c0.1,0.7,0.4,1.5,0.4,2.1c-0.1-0.7-0.4-1.3-0.7-2c-0.1-0.1-0.3-0.7-0.8-1.2
                                    C36-49.5,35.8-49.9,35.8-50.1L35.8-50.1z"
        />
        <path d="M39.8-39.9h-0.5H39.8L39.8-39.9z" />
        <path d="M36.4-40.4c-0.5,0-1.1-0.1-1.6,0c-0.3,0.3-0.3,0.5-0.3,0.8C35.2-40.3,35.3-40.3,36.4-40.4L36.4-40.4z" />
        <path d="M43.3-36.9c-0.3,0.3-0.3,0.4-1.2,0.7c-0.7,0.1-0.9-0.1-1.2-0.4c0.4,0.1,0.5,0.4,1.7,0.1L43.3-36.9z" />
        <path
          d="M40.8-36.8c-0.3,0.5-0.4,1.1-0.7,1.6c-0.5,0.7-0.3,0.3-1.1,1.2c0.3-0.4,0.5-0.8,0.8-1.2c0.1-0.3,0.3-0.5,0.4-0.8
                                    c-0.3-0.1-0.7-0.1-0.7-0.1c-0.7,0.4-0.8,0.8-1.2,1.3c0.3-0.5,0.5-1.1,0.9-1.5c0,0,0.3-0.1,0.4-0.1c-0.3,0-0.7-0.1-0.8-0.1
                                    c-0.4,0.1-0.7,0.5-1.1,0.8c0.3-0.4,0.5-0.7,0.8-1.1c0,0,0.9,0,1.3,0.3v0.1l0.3,0.1l0.1-0.5L40.8-36.8z"
        />
        <path d="M35.2-33.3c-0.1,0-1.1-0.1-1.1-0.1c-0.4,0.3-0.4,0.5-0.4,0.8C34.2-33.3,34.2-33.3,35.2-33.3L35.2-33.3z" />
        <path
          d="M34.4-37.9c0,0,0.7-0.1,0.7,0c0,0.3,0.1,0.8,0.1,0.8l-0.4,0.3c0.8-0.4,1.7-0.4,2.7-0.4c0,0-0.8-0.3-0.7-0.3
                                    c0.1,0,0-0.8-0.1-0.8c0.1,0,0.3-0.1,0.4-0.1C36-38.7,35.4-38.5,34.4-37.9L34.4-37.9z"
        />
        <path
          d="M40.6-37.3l-0.1,0.1c0-0.5-0.5-0.4-0.9-0.4l-0.4-0.1c0.1,0.1,0.4,0.1,0.3,0.3c-0.1,0-0.1,0.3-0.3,0.5H39
                                    c0.4,0.1,0.7,0.3,1.1,0.4h0.3l0.5-0.3L40.6-37.3z"
        />
      </g>
      <g transform="translate(-.53268 66.8)">
        <path
          class="st0"
          d="M35.8-37.6c-0.1-0.1-0.5-0.1-0.5,0c-0.1,0.1,0,0.7,0.1,0.5c0.1-0.1,0.3-0.1,0.5-0.1
                                    C36-37.5,36-37.5,35.8-37.6L35.8-37.6z"
        />
        <path
          class="st0"
          d="M39.8-36.8c0,0,0.1-0.3,0.1-0.4s-0.4-0.1-0.4-0.1c-0.1,0-0.1,0.4-0.1,0.4C39.3-36.8,39.7-36.8,39.8-36.8
                                    L39.8-36.8z"
        />
      </g>
      <g transform="translate(-.53268 66.8)">
        <path d="M23.6-27.5h0.3c1.5,0.7,3.7,1.7,7.2,1.2l0.5,0.8c-1.5,0.7-3.1,0.5-4.7,0.7L23.6-27.5z" />
        <path d="M21.4-32.9l0.7,2.9c1.9,1.2,5.2,2.5,7.9,2.4l-0.7-1.3C23.8-30.1,23.3-31.6,21.4-32.9L21.4-32.9z" />
        <path
          d="M23.3-39.2c0.4,2.3,0.8,4.1,2.8,5.6c1.2,0.9,2.5,1.7,4,2.5c0,0-0.1,0.7-0.3,0.7c-4.4-0.5-7.7-4.1-8.1-5.9
                                    C22.1-37.6,22.6-38.4,23.3-39.2L23.3-39.2z"
        />
        <path
          d="M25.4-40.4c0.5,2.5,2,5.2,3.5,7.6c0.5,0.7,0.7,0.9,1.5,1.3c1.1,0.4,1.9,0.3,2.7,0.3c-0.3-0.4-0.4-0.8-0.7-1.2
                                    c-1.7-1.3-0.9-2.7-0.5-3.5c-0.9-0.3-2.3-0.8-2.4-1.5c-0.3-2.3-0.1-2.9,0.1-4C28.1-41.2,26.8-40.9,25.4-40.4L25.4-40.4z"
        />
        <path
          class="st5"
          d="M20.6-54.1c-0.4,0.3-0.8,0.7-1.3,1.2c-0.8,0.8-1.2,1.5-2,2.1c-0.1,0.1-0.5,0.4-1.1,0.4c-0.3,0-0.4,0-0.9,0
                                    c-0.4-0.3-0.8-0.1-1.2,0.4c-0.4,0.4-0.9,1.6-1.1,2.3c-0.4,1.9,0.7,3.3,1.7,4.4s1.6,1.6,2,2.5c0.3,0.5,0.5,1.3,0.8,1.9
                                    c0.1,0.1,0.1,0.1,0.3,0.3c0.5,0.1,1.2,0.1,1.9,0.1c0.3,0,0.7,0,1.1,0c0.5-0.1,1.1-0.1,1.6-0.4c0.5-0.1,0.9-0.3,1.2-0.4
                                    c0,0.1-0.4,0.3-0.5,0.5c-1.3,1.9-1.6,3.5-1.5,6c0.1,1.2,0.4,2.1,0.8,3.2c0.1,0.5,0.5,1.3,0.9,1.7c1.1,1.6,3.6,3.6,6.9,4.3
                                    c0.5,0.1,1.3,0,1.9-0.1c1.7-0.5,5.1-1.7,5.1-1.7s-3.1,0.3-4.4,0.1c-0.3,0-0.7-0.1-0.9-0.3l-0.1-0.3c0.1,0,0.5-0.1,1.1-0.1
                                    c-1.2-0.1-1.1-0.1-1.2-0.3c-0.1-0.3-0.3-0.8-0.5-1.2c0.5,0,1.6,0.1,2-0.3c0,0-0.7,0.1-1.2,0c-0.1,0-0.5-0.1-0.7-0.1
                                    C30.9-28,30.6-28,30.6-28c-0.1-0.3-0.1-0.3-0.3-0.7s-0.1-0.8-0.1-1.3c0.4,0.5,0.8,0.8,1.5,1.1c0,0,0.8,0.4,1.5,0.1h0.1
                                    c0,0-0.4,0-0.5,0c-1.2-0.5-1.5-0.9-1.6-1.2l-0.5-0.8c0.1-0.3,0.3-0.3,0.4-0.4c0.7,0.1,0.9,0.1,1.3,0c0.3,0.5,0.4,1.1,0.9,1.5
                                    c2,0.5,2.4-0.1,2.9-0.8c1.5,1.1,3.7,1.3,5.5,0c2-2.4,2.7-6.1,2.5-6.4c-0.3-0.4-0.5-0.9-0.9-0.8c-1.1,0.3-1.5,0.8-2.4,0.7
                                    c0.1,0,0.3,0,0.3,0c0.1-0.9,0-1.3,0-1.3c-0.3-0.7-0.7-1.5-1.1-2c-0.1-0.1-0.3-0.9-0.7-1.3c-0.1-0.1-0.5-0.5-0.5-0.5v0.4
                                    c0,0,0.1,0,0.3,0.3c0.3,0.9,1.3,2.9,1.3,3.1c0.4,0.7,0,1.5,0.3,1.9c0,0,0.5,0,1.1,0c0.7-0.1,0.7-0.5,1.3-0.5
                                    c0.4,0,0.4,0.8,0.4,0.8c-0.1,0.9-0.4,1.9-0.8,2.8c-0.8,1.6-1.9,3.1-3.2,3.3C38-30,37-30.7,36.2-31.2l-0.4,0.3
                                    c-1.2,1.2-2.5,1.1-3.1-0.4c-0.3-0.5-0.7-0.9-0.9-1.5l-1.6,1.2c-0.1,0.3-0.3,0.7-0.5,1.2c-0.1,0.4-0.3,0.9-0.3,1.5
                                    c-0.3,0.4,0.8,1.9,1.3,2.7c0.1,0.3,0.5,0.7,0.5,0.8c0.1,0.3,0.4,0.5,0.4,0.7c1.1,1.5-1.5,1.1-1.9,1.1c-1.1-0.1-2-0.5-2.9-1.2
                                    l-0.1-0.1c-1.1-0.7-2.1-1.6-3.1-2.5c-0.5-0.5-1.1-1.9-1.3-2.7c-0.5-2.1-1.3-5.6,0.8-8.4c0-0.1,0.1-0.4,0.3-0.4
                                    c0.7-0.4,1.3-0.7,2.1-0.9l-0.1-0.5c-0.4,0.1-1.6,0.5-2,0.8c-0.8,0.3-1.5,0.5-2.5,0.8c-0.4,0-0.7,0-0.9,0c-0.7-0.1-2-0.1-2-0.1
                                    c-0.5-0.7-0.7-2-1.1-2.7l0,0l0,0c-0.3-0.4-0.5-0.7-0.9-0.9c-1.1-1.1-2-2.1-2.3-3.2c0-0.3-0.3-0.7-0.1-1.6l0,0l0,0
                                    c0.3-0.9,0.7-1.6,1.5-2.4c0.8,0,1.5,0,2.1,0.1c0.3,0,0.8,0.1,1.3,0.3c1.5,0.5,3.3,1.5,3.3,1.5c-1.5-0.8-3.1-1.9-4.1-2
                                    c-0.1,0-0.3-0.1-0.3-0.3c1.5-0.9,1.9-2,2.8-2.9c0.4-0.3,0.7-0.3,1.1-0.4c3.6,0.5,5.9,2,7.7,2.9c0.7,0.4,1.3,0.7,2,1.1
                                    c0.5,0.1,2.3,1.5,2.7,2.1c0.5,1.1,0.8,2.1,1.2,3.3c0.3,1.1,0.4,1.6,0.4,1.6s-0.3-0.9-0.1-1.1c0.3,0.1,0.7,0.3,0.9,0.3
                                    c0,0-0.9-0.5-1.1-0.9c-0.4-1.3-0.8-3.5-0.9-3.6c-0.3-0.4-1.5-1.3-2.3-1.7c-0.3-0.1-0.4-0.3-0.4-0.4c0.3-0.3,0.5-0.5,0.8-0.8
                                    c0.3-0.1,0.5-0.4,0.8-0.5c1.6-0.7,2.4,0.3,2.7,0.1c0,0-0.4-0.4-0.1-0.3c0.1,0.1,0.7,0.1,0.7,0.3c0.5,0.4,2,2.1,2.9,3.7
                                    c0.3,0.4,0.3,0.7,0.3,1.2c-0.1,0.5-0.1,0.8-0.3,1.1c-0.1,0.3-0.7,1.7-0.7,2c-0.1,0.8,0.4,1.9,0.4,1.9c0-0.3,0-0.4,0-0.7v-0.4
                                    v-0.1c0-0.3,0.1-0.5,0.1-0.7c0.1-1.1,0.5-1.9,0.8-2.9c0.1-0.3,0.3-0.4,0.3-0.5c0-0.3-0.3-0.8-0.5-1.2c-0.3-0.4-0.5-0.9-0.8-1.5
                                    c-0.8-1.1-1.5-2-2.7-2.5c-0.4-0.1-1.7-0.3-2.1-0.3c-0.5,0.1-1.1,0.3-1.5,0.5c-0.7,0.4-1.1,0.9-1.6,1.3c-1.2-0.7-1.9-1.1-2-1.2
                                    c-0.7-0.4-1.6-0.8-2.5-1.3C26-52.9,23.2-53.9,20.6-54.1L20.6-54.1z M36.4-31.2c-0.8-0.5-1.3-1.2-1.9-1.9
                                    c-0.1,0.8-0.7,1.2-1.1,1.7c-0.1,0.1-0.1,0.3,0.3,0.8c0.1,0.1,0.5,0.1,0.7,0.1c-0.3-0.1-0.7-0.4-0.7-0.5c0.4,0.3,0.8,0.4,1.2,0.4
                                    c0.1,0,0.1-0.1,0.3-0.3c0.1-0.4,0.3-0.4,0.4-0.5l0.3,0.4L36.4-31.2z"
        />
      </g>
    </g>
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-mysql-focus'" class="icon-chart-mysql-focus">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                        C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g id="Layer_5">
    <g>
      <g>
        <path
          class="st2"
          d="M44.1,42.7c-0.3-0.3-0.4-0.4-0.7-0.7c-0.7-0.8-1.3-1.6-2.1-2.1c-0.7-0.5-2.1-1.1-2.4-1.9l0,0
                                c0.4,0,1.1-0.3,1.5-0.4c0.7-0.1,1.3-0.1,2.1-0.4c0.3-0.1,0.7-0.1,0.9-0.3v-0.1c-0.4-0.4-0.7-0.9-1.1-1.2
                                c-1.1-0.9-2.3-1.9-3.5-2.7c-0.7-0.4-1.5-0.7-2.1-1.1c-0.3-0.1-0.7-0.1-0.8-0.4c-0.4-0.5-0.5-1.1-0.8-1.6
                                c-0.5-1.2-1.2-2.4-1.7-3.7c-0.4-0.8-0.5-1.7-1.1-2.4c-2.3-3.7-4.7-5.9-8.3-8c-0.8-0.4-1.7-0.7-2.7-0.9c-0.5,0-1.1,0-1.6-0.1
                                c-0.3-0.1-0.5-0.5-0.8-0.7c-1.2-0.8-4.3-2.4-5.2-0.3c-0.5,1.3,0.8,2.8,1.3,3.5c0.4,0.5,0.8,1.1,1.1,1.6c0.1,0.4,0.1,0.7,0.4,1.1
                                c0.4,0.9,0.7,2,1.1,2.8c0.3,0.4,0.5,0.9,0.8,1.3c0.1,0.3,0.5,0.4,0.5,0.7c-0.3,0.4-0.3,1.1-0.5,1.6c-0.8,2.4-0.4,5.3,0.7,7.1
                                c0.3,0.5,1.1,1.7,2.1,1.2c0.9-0.4,0.7-1.6,0.9-2.7c0-0.3,0-0.4,0.1-0.5l0,0c0.3,0.5,0.5,1.2,0.8,1.7c0.7,1.1,1.7,2.1,2.7,2.8
                                c0.5,0.4,0.9,1.1,1.5,1.2l0,0l0,0c-0.1-0.1-0.3-0.3-0.5-0.4c-0.4-0.4-0.8-0.8-1.1-1.2c-0.8-1.2-1.6-2.5-2.3-3.9
                                c-0.4-0.7-0.7-1.3-0.9-2c-0.1-0.3-0.1-0.7-0.3-0.8c-0.3,0.5-0.8,0.9-0.9,1.5c-0.4,0.9-0.4,2-0.5,3.2c-0.1,0,0,0-0.1,0
                                c-0.7-0.1-0.9-0.8-1.2-1.5c-0.7-1.5-0.8-4-0.1-5.7c0.1-0.4,0.8-1.9,0.5-2.3c-0.1-0.1-0.5-0.3-0.8-0.7c-0.3-0.4-0.5-0.9-0.7-1.3
                                c-0.5-1.2-0.8-2.5-1.3-3.7c-0.3-0.5-0.7-1.1-1.1-1.6c-0.4-0.5-0.8-0.9-1.2-1.6c0-0.3-0.1-0.7,0-0.9c0-0.1,0.1-0.3,0.3-0.3
                                c0.3-0.3,1.1,0.1,1.3,0.1c0.8,0.4,1.3,0.7,2,1.1c0.3,0.3,0.7,0.7,0.9,0.7h0.4c0.7,0.1,1.5,0,2,0.3c1.1,0.4,2.1,0.9,2.9,1.5
                                c2.8,1.7,4.9,4.3,6.5,7.3c0.3,0.5,0.4,0.9,0.5,1.5c0.4,1.1,0.9,2.1,1.5,3.1c0.4,1.1,0.8,2,1.5,2.8c0.3,0.4,1.6,0.7,2.1,0.9
                                c0.4,0.1,1.1,0.4,1.5,0.5c0.7,0.4,1.5,0.9,2.1,1.5c0.4,0.3,1.3,0.8,1.5,1.2c-1.7,0-2.9,0.1-4,0.5c-0.3,0.1-0.8,0.1-0.8,0.5
                                c0.1,0.1,0.1,0.4,0.3,0.7c0.3,0.4,0.7,1.1,1.1,1.3c0.4,0.3,0.9,0.7,1.3,0.9c0.8,0.5,1.7,0.8,2.5,1.3c0.4,0.3,0.9,0.7,1.3,1.1
                                c0.3,0.1,0.4,0.4,0.7,0.5l0,0C44.2,43.1,44.2,42.8,44.1,42.7z M19.8,18L19.8,18L19.8,18c0.3,0.4,0.5,0.7,0.7,0.9
                                c0.1,0.4,0.4,0.7,0.5,1.1l0,0c0.3-0.3,0.4-0.5,0.4-1.1c0-0.3-0.1-0.4-0.1-0.5c-0.1-0.1-0.4-0.3-0.7-0.5
                                C20.3,17.9,20.1,18,19.8,18z"
        />
      </g>
    </g>
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-mysql'" class="icon-chart-mysql">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g id="Layer_5">
    <g>
      <g>
        <path
          class="st2"
          d="M44.1,42.7c-0.3-0.3-0.4-0.4-0.7-0.7c-0.7-0.8-1.3-1.6-2.1-2.1c-0.7-0.5-2.1-1.1-2.4-1.9l0,0
                                    c0.4,0,1.1-0.3,1.5-0.4c0.7-0.1,1.3-0.1,2.1-0.4c0.3-0.1,0.7-0.1,0.9-0.3v-0.1c-0.4-0.4-0.7-0.9-1.1-1.2
                                    c-1.1-0.9-2.3-1.9-3.5-2.7c-0.7-0.4-1.5-0.7-2.1-1.1c-0.3-0.1-0.7-0.1-0.8-0.4c-0.4-0.5-0.5-1.1-0.8-1.6
                                    c-0.5-1.2-1.2-2.4-1.7-3.7c-0.4-0.8-0.5-1.7-1.1-2.4c-2.3-3.7-4.7-5.9-8.3-8c-0.8-0.4-1.7-0.7-2.7-0.9c-0.5,0-1.1,0-1.6-0.1
                                    c-0.3-0.1-0.5-0.5-0.8-0.7c-1.2-0.8-4.3-2.4-5.2-0.3c-0.5,1.3,0.8,2.8,1.3,3.5c0.4,0.5,0.8,1.1,1.1,1.6c0.1,0.4,0.1,0.7,0.4,1.1
                                    c0.4,0.9,0.7,2,1.1,2.8c0.3,0.4,0.5,0.9,0.8,1.3c0.1,0.3,0.5,0.4,0.5,0.7c-0.3,0.4-0.3,1.1-0.5,1.6c-0.8,2.4-0.4,5.3,0.7,7.1
                                    c0.3,0.5,1.1,1.7,2.1,1.2c0.9-0.4,0.7-1.6,0.9-2.7c0-0.3,0-0.4,0.1-0.5l0,0c0.3,0.5,0.5,1.2,0.8,1.7c0.7,1.1,1.7,2.1,2.7,2.8
                                    c0.5,0.4,0.9,1.1,1.5,1.2l0,0l0,0c-0.1-0.1-0.3-0.3-0.5-0.4c-0.4-0.4-0.8-0.8-1.1-1.2c-0.8-1.2-1.6-2.5-2.3-3.9
                                    c-0.4-0.7-0.7-1.3-0.9-2c-0.1-0.3-0.1-0.7-0.3-0.8c-0.3,0.5-0.8,0.9-0.9,1.5c-0.4,0.9-0.4,2-0.5,3.2c-0.1,0,0,0-0.1,0
                                    c-0.7-0.1-0.9-0.8-1.2-1.5c-0.7-1.5-0.8-4-0.1-5.7c0.1-0.4,0.8-1.9,0.5-2.3c-0.1-0.1-0.5-0.3-0.8-0.7c-0.3-0.4-0.5-0.9-0.7-1.3
                                    c-0.5-1.2-0.8-2.5-1.3-3.7c-0.3-0.5-0.7-1.1-1.1-1.6c-0.4-0.5-0.8-0.9-1.2-1.6c0-0.3-0.1-0.7,0-0.9c0-0.1,0.1-0.3,0.3-0.3
                                    c0.3-0.3,1.1,0.1,1.3,0.1c0.8,0.4,1.3,0.7,2,1.1c0.3,0.3,0.7,0.7,0.9,0.7h0.4c0.7,0.1,1.5,0,2,0.3c1.1,0.4,2.1,0.9,2.9,1.5
                                    c2.8,1.7,4.9,4.3,6.5,7.3c0.3,0.5,0.4,0.9,0.5,1.5c0.4,1.1,0.9,2.1,1.5,3.1c0.4,1.1,0.8,2,1.5,2.8c0.3,0.4,1.6,0.7,2.1,0.9
                                    c0.4,0.1,1.1,0.4,1.5,0.5c0.7,0.4,1.5,0.9,2.1,1.5c0.4,0.3,1.3,0.8,1.5,1.2c-1.7,0-2.9,0.1-4,0.5c-0.3,0.1-0.8,0.1-0.8,0.5
                                    c0.1,0.1,0.1,0.4,0.3,0.7c0.3,0.4,0.7,1.1,1.1,1.3c0.4,0.3,0.9,0.7,1.3,0.9c0.8,0.5,1.7,0.8,2.5,1.3c0.4,0.3,0.9,0.7,1.3,1.1
                                    c0.3,0.1,0.4,0.4,0.7,0.5l0,0C44.2,43.1,44.2,42.8,44.1,42.7z M19.8,18L19.8,18L19.8,18c0.3,0.4,0.5,0.7,0.7,0.9
                                    c0.1,0.4,0.4,0.7,0.5,1.1l0,0c0.3-0.3,0.4-0.5,0.4-1.1c0-0.3-0.1-0.4-0.1-0.5c-0.1-0.1-0.4-0.3-0.7-0.5
                                    C20.3,17.9,20.1,18,19.8,18z"
        />
      </g>
    </g>
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-oracle-focus'" class="icon-chart-oracle-focus">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g id="Layer_7">
    <g>
      <g>
        <path
          class="st2"
          d="M40.7,14H15.3c-0.8,0-1.3,0.5-1.3,1.3v25.3c0,0.8,0.5,1.3,1.3,1.3h25.3c0.8,0,1.3-0.5,1.3-1.3V15.3
                                    C42,14.5,41.5,14,40.7,14z M32.1,35.3h-8.3c-4,0-7.2-3.3-7.2-7.3s3.2-7.3,7.2-7.3h8.3c4,0,7.2,3.3,7.2,7.3S36.1,35.3,32.1,35.3z
                                     M32,23.2h-8c-2.5,0-4.7,2.1-4.7,4.8s2.1,4.8,4.7,4.8h8c2.5,0,4.7-2.1,4.7-4.8S34.5,23.2,32,23.2z"
        />
      </g>
    </g>
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-oracle'" class="icon-chart-oracle">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g id="Layer_7">
    <g>
      <g>
        <path
          class="st2"
          d="M40.7,14H15.3c-0.8,0-1.3,0.5-1.3,1.3v25.3c0,0.8,0.5,1.3,1.3,1.3h25.3c0.8,0,1.3-0.5,1.3-1.3V15.3
                                    C42,14.5,41.5,14,40.7,14z M32.1,35.3h-8.3c-4,0-7.2-3.3-7.2-7.3s3.2-7.3,7.2-7.3h8.3c4,0,7.2,3.3,7.2,7.3S36.1,35.3,32.1,35.3z
                                     M32,23.2h-8c-2.5,0-4.7,2.1-4.7,4.8s2.1,4.8,4.7,4.8h8c2.5,0,4.7-2.1,4.7-4.8S34.5,23.2,32,23.2z"
        />
      </g>
    </g>
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-phoenix-focus'" class="icon-chart-phoenix-focus">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.3,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S14,2.5,28,2.5 M28,0
                            C12.6,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g>
    <g>
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1="1496.468"
        y1="800.9574"
        x2="1500.3971"
        y2="800.9574"
        gradientTransform="matrix(-0.999 4.430000e-02 4.430000e-02 0.999 1492.778 -842.4035)"
      >
        <stop offset="0" style="stop-color: #f6921d" />
        <stop offset="2.049476e-02" style="stop-color: #f68e1d" />
        <stop offset="0.2346" style="stop-color: #f2651f" />
        <stop offset="0.4443" style="stop-color: #ef4521" />
        <stop offset="0.6456" style="stop-color: #ee2e22" />
        <stop offset="0.8349" style="stop-color: #ec2023" />
        <stop offset="1" style="stop-color: #ec1b23" />
      </linearGradient>
      <path
        class="st2"
        d="M33,34.7c0,0,1.3-3.9-0.3-8.7c-1.2-3.6-2.7-4.1-0.1-12.5c0,0-4.9,4.4-3.1,9.5c0.4,0.9,0.3,1.1,1.5,2.9
                                C32.2,27.9,33.3,30.3,33,34.7z"
      />

      <linearGradient
        id="SVGID_2_"
        gradientUnits="userSpaceOnUse"
        x1="1497.4117"
        y1="802.4761"
        x2="1502.7467"
        y2="802.4761"
        gradientTransform="matrix(-0.999 4.430000e-02 4.430000e-02 0.999 1492.778 -842.4035)"
      >
        <stop offset="0" style="stop-color: #f6921d" />
        <stop offset="2.049476e-02" style="stop-color: #f68e1d" />
        <stop offset="0.2346" style="stop-color: #f2651f" />
        <stop offset="0.4443" style="stop-color: #ef4521" />
        <stop offset="0.6456" style="stop-color: #ee2e22" />
        <stop offset="0.8349" style="stop-color: #ec2023" />
        <stop offset="1" style="stop-color: #ec1b23" />
      </linearGradient>
      <path
        class="st3"
        d="M32.8,35.3c0,0,0.8-4.1-2.9-9.9c-1.5-2.3-2.1-6.1-0.5-9.5c0,0-4.8,6.9-0.7,11.6c0,0,0.5,0.5,0.9,1.1
                                C29.7,29.1,33,33.6,32.8,35.3z"
      />

      <linearGradient
        id="SVGID_3_"
        gradientUnits="userSpaceOnUse"
        x1="1497.7369"
        y1="805.6658"
        x2="1503.9717"
        y2="805.6658"
        gradientTransform="matrix(-0.999 4.430000e-02 4.430000e-02 0.999 1492.778 -842.4035)"
      >
        <stop offset="0" style="stop-color: #f6921d" />
        <stop offset="2.049476e-02" style="stop-color: #f68e1d" />
        <stop offset="0.2346" style="stop-color: #f2651f" />
        <stop offset="0.4443" style="stop-color: #ef4521" />
        <stop offset="0.6456" style="stop-color: #ee2e22" />
        <stop offset="0.8349" style="stop-color: #ec2023" />
        <stop offset="1" style="stop-color: #ec1b23" />
      </linearGradient>
      <path
        class="st4"
        d="M32.5,35.6c0,0,0-0.5-0.4-1.3c-0.5-1.2-1.9-4-3.7-5.7c-2.8-2.5-2.1-6.3-2.1-6.3c-1.1,6.3,0.7,7.9,1.7,8.5
                                C30.5,32.5,31.8,33.7,32.5,35.6z"
      />
    </g>

    <linearGradient
      id="SVGID_4_"
      gradientUnits="userSpaceOnUse"
      x1="17.9667"
      y1="771.4"
      x2="37.9667"
      y2="771.4"
      gradientTransform="matrix(1 0 0 1 0 -744)"
    >
      <stop offset="0" style="stop-color: #f6921d" />
      <stop offset="2.049476e-02" style="stop-color: #f68e1d" />
      <stop offset="0.2346" style="stop-color: #f2651f" />
      <stop offset="0.4443" style="stop-color: #ef4521" />
      <stop offset="0.6456" style="stop-color: #ee2e22" />
      <stop offset="0.8349" style="stop-color: #ec2023" />
      <stop offset="1" style="stop-color: #ec1b23" />
    </linearGradient>
    <path
      class="st5"
      d="M37.6,28.8c-1.3-5.1-4.3-9.2-0.1-16.7c0,0-8,2.8-4.4,13.2c2.3,6.4,1.6,10-0.4,12c-0.8,0.8-2.3,1.9-4.7,1.6
                            s-3.7-1.6-4.8-2.9c-1.5-2-2-4.8,0-8.9c1.2-2.7,1.9-4.9,1.9-4.9c0.1-0.5,0.3-0.8,0.4-1.2c0.9,0.1,1.3-0.7,1.3-0.7
                            c-0.4,0.1-0.5-0.1-0.5-0.1c0.8-0.4,0.5-1.1,0.5-1.1c-0.4,0.3-0.9,0.1-0.9,0.1s-0.9,0-1.1,0c-1.7-1.3-2.9-1.1-3.6-0.5
                            c-0.1,0.3-0.3,0.3-0.4,0.5c-0.3,0.3-0.4,0.5-0.4,0.5s-1.7,0.3-2.3,1.3c0,0,1.2-0.8,2.3-0.4c0.1,0.1,0.7,0.5,1.1,1.6
                            c0.3,0.5,0,1.9-0.3,2.3c-1.5,2.8-3.2,4.8-3.2,7.6c0,2.5,0.9,4.9,2.4,6.7c3.1,3.9,7.9,3.9,8,3.9c5.7,0,9.7-4.7,9.7-10.5
                            C38,30.9,37.8,29.6,37.6,28.8z"
    />
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-phoenix'" class="icon-chart-phoenix">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.3,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S14,2.5,28,2.5 M28,0
                            C12.6,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g>
    <g>
      <linearGradient
        id="SVGID_1_"
        gradientUnits="userSpaceOnUse"
        x1="1496.468"
        y1="800.9574"
        x2="1500.3971"
        y2="800.9574"
        gradientTransform="matrix(-0.999 4.430000e-02 4.430000e-02 0.999 1492.778 -842.4035)"
      >
        <stop offset="0" style="stop-color: #f6921d" />
        <stop offset="2.049476e-02" style="stop-color: #f68e1d" />
        <stop offset="0.2346" style="stop-color: #f2651f" />
        <stop offset="0.4443" style="stop-color: #ef4521" />
        <stop offset="0.6456" style="stop-color: #ee2e22" />
        <stop offset="0.8349" style="stop-color: #ec2023" />
        <stop offset="1" style="stop-color: #ec1b23" />
      </linearGradient>
      <path
        class="st2"
        d="M33,34.7c0,0,1.3-3.9-0.3-8.7c-1.2-3.6-2.7-4.1-0.1-12.5c0,0-4.9,4.4-3.1,9.5c0.4,0.9,0.3,1.1,1.5,2.9
                                C32.2,27.9,33.3,30.3,33,34.7z"
      />

      <linearGradient
        id="SVGID_2_"
        gradientUnits="userSpaceOnUse"
        x1="1497.4117"
        y1="802.4761"
        x2="1502.7467"
        y2="802.4761"
        gradientTransform="matrix(-0.999 4.430000e-02 4.430000e-02 0.999 1492.778 -842.4035)"
      >
        <stop offset="0" style="stop-color: #f6921d" />
        <stop offset="2.049476e-02" style="stop-color: #f68e1d" />
        <stop offset="0.2346" style="stop-color: #f2651f" />
        <stop offset="0.4443" style="stop-color: #ef4521" />
        <stop offset="0.6456" style="stop-color: #ee2e22" />
        <stop offset="0.8349" style="stop-color: #ec2023" />
        <stop offset="1" style="stop-color: #ec1b23" />
      </linearGradient>
      <path
        class="st3"
        d="M32.8,35.3c0,0,0.8-4.1-2.9-9.9c-1.5-2.3-2.1-6.1-0.5-9.5c0,0-4.8,6.9-0.7,11.6c0,0,0.5,0.5,0.9,1.1
                                C29.7,29.1,33,33.6,32.8,35.3z"
      />

      <linearGradient
        id="SVGID_3_"
        gradientUnits="userSpaceOnUse"
        x1="1497.7369"
        y1="805.6658"
        x2="1503.9717"
        y2="805.6658"
        gradientTransform="matrix(-0.999 4.430000e-02 4.430000e-02 0.999 1492.778 -842.4035)"
      >
        <stop offset="0" style="stop-color: #f6921d" />
        <stop offset="2.049476e-02" style="stop-color: #f68e1d" />
        <stop offset="0.2346" style="stop-color: #f2651f" />
        <stop offset="0.4443" style="stop-color: #ef4521" />
        <stop offset="0.6456" style="stop-color: #ee2e22" />
        <stop offset="0.8349" style="stop-color: #ec2023" />
        <stop offset="1" style="stop-color: #ec1b23" />
      </linearGradient>
      <path
        class="st4"
        d="M32.5,35.6c0,0,0-0.5-0.4-1.3c-0.5-1.2-1.9-4-3.7-5.7c-2.8-2.5-2.1-6.3-2.1-6.3c-1.1,6.3,0.7,7.9,1.7,8.5
                                C30.5,32.5,31.8,33.7,32.5,35.6z"
      />
    </g>

    <linearGradient
      id="SVGID_4_"
      gradientUnits="userSpaceOnUse"
      x1="17.9667"
      y1="771.4"
      x2="37.9667"
      y2="771.4"
      gradientTransform="matrix(1 0 0 1 0 -744)"
    >
      <stop offset="0" style="stop-color: #f6921d" />
      <stop offset="2.049476e-02" style="stop-color: #f68e1d" />
      <stop offset="0.2346" style="stop-color: #f2651f" />
      <stop offset="0.4443" style="stop-color: #ef4521" />
      <stop offset="0.6456" style="stop-color: #ee2e22" />
      <stop offset="0.8349" style="stop-color: #ec2023" />
      <stop offset="1" style="stop-color: #ec1b23" />
    </linearGradient>
    <path
      class="st5"
      d="M37.6,28.8c-1.3-5.1-4.3-9.2-0.1-16.7c0,0-8,2.8-4.4,13.2c2.3,6.4,1.6,10-0.4,12c-0.8,0.8-2.3,1.9-4.7,1.6
                            s-3.7-1.6-4.8-2.9c-1.5-2-2-4.8,0-8.9c1.2-2.7,1.9-4.9,1.9-4.9c0.1-0.5,0.3-0.8,0.4-1.2c0.9,0.1,1.3-0.7,1.3-0.7
                            c-0.4,0.1-0.5-0.1-0.5-0.1c0.8-0.4,0.5-1.1,0.5-1.1c-0.4,0.3-0.9,0.1-0.9,0.1s-0.9,0-1.1,0c-1.7-1.3-2.9-1.1-3.6-0.5
                            c-0.1,0.3-0.3,0.3-0.4,0.5c-0.3,0.3-0.4,0.5-0.4,0.5s-1.7,0.3-2.3,1.3c0,0,1.2-0.8,2.3-0.4c0.1,0.1,0.7,0.5,1.1,1.6
                            c0.3,0.5,0,1.9-0.3,2.3c-1.5,2.8-3.2,4.8-3.2,7.6c0,2.5,0.9,4.9,2.4,6.7c3.1,3.9,7.9,3.9,8,3.9c5.7,0,9.7-4.7,9.7-10.5
                            C38,30.9,37.8,29.6,37.6,28.8z"
    />
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-post-focus'" class="icon-chart-post-focus">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g id="Layer_4_1_">
    <g id="Layer_x0020_3_1_">
      <g>
        <path
          d="M35.2,35.9c0.1-1.6,0.1-1.9,1.3-1.6h0.3c0.9,0,2.1-0.1,2.8-0.5c1.5-0.7,2.4-1.9,0.9-1.6c-3.5,0.7-3.6-0.4-3.6-0.4
                                    c3.6-5.3,5.1-12.1,3.9-13.9c-3.6-4.5-9.7-2.4-9.7-2.4l0,0c-0.7-0.1-1.5-0.3-2.3-0.3c-1.6,0-2.7,0.4-3.6,1.1
                                    c0,0-10.9-4.5-10.4,5.7c0,2.3,2.9,16.7,6.5,12.3c1.3-1.6,2.5-2.9,2.5-2.9c0.7,0.4,1.3,0.7,2.1,0.5l0,0c0,0.1,0,0.4,0,0.7
                                    c-0.9,1.1-0.7,1.2-2.5,1.6c-1.9,0.4-0.8,1.1,0,1.2c0.8,0.3,2.9,0.5,4.3-1.3v0.3c0.4,0.3,0.4,2.1,0.4,3.3c0,1.3,0.1,2.5,0.4,3.2
                                    c0.3,0.7,0.5,2.5,2.9,2C33.6,42.3,35.1,41.8,35.2,35.9"
        />
        <path
          class="st2"
          d="M30.8,44.2c-0.8,0-1.6-0.1-2.3-0.8c-0.7-0.7-0.9-1.5-1.1-1.9c0-0.1,0-0.1,0-0.1c-0.3-0.8-0.4-2-0.5-3.6
                                    c0-0.3,0-0.5,0-0.7s0-0.3,0-0.5c-1.1,0.4-2.3,0.5-3.6,0.3c-0.4-0.1-0.7-0.3-1.1-0.4c-0.3-0.1-0.4-0.3-0.5-0.4
                                    c-1.1,0.9-2,0.8-2.5,0.5c-0.9-0.3-1.9-1.2-2.7-2.8c-0.5-1.1-1.1-2.5-1.6-4.1c-0.9-3.2-1.5-6.4-1.6-7.3c-0.1-3.3,0.8-5.6,2.7-6.9
                                    c1.5-1.1,3.6-1.5,6.3-0.9c1.1,0.1,2.1,0.5,2.7,0.7c1.1-0.7,2.4-0.9,3.7-0.9c0.8,0,1.5,0.1,2.1,0.3c0.7-0.3,2.1-0.5,3.7-0.5
                                    c2.9,0,5.5,1.2,7.2,3.3c0.7,0.8,1.2,2.7,0.1,6.7c-0.5,2.4-1.6,5.1-3.1,7.3c0.4,0,0.8,0,1.5-0.3c0.4-0.1,0.7-0.1,0.9,0
                                    c0.5,0.1,1.1,0.4,1.2,0.9c0.5,1.6-1.7,2.7-2.4,3.1c-0.8,0.4-2.3,0.7-3.5,0.5l0,0h-0.3v0.1c0,0.1,0,0.1,0,0.3
                                    c-0.1,2.9-0.5,4.8-1.3,6.1c-0.9,1.3-2.3,1.7-3.3,2C31.5,44.1,31.2,44.2,30.8,44.2z M28.5,33.3c0.7,0.5,0.8,1.6,0.8,3.6
                                    c0,0.3,0,0.5,0,0.7c0,0.7,0.1,2.1,0.3,2.8c0,0.1,0,0.1,0.1,0.3c0.3,0.8,0.4,1.2,1.5,0.9c0.9-0.3,1.5-0.4,1.7-0.9
                                    c0.5-0.8,0.8-2.4,0.9-4.8l0,0l0,0c0-0.1,0-0.3,0-0.3c0.1-0.9,0.1-1.7,0.9-2.1c0.4-0.3,0.9-0.4,1.3-0.3c-0.4-0.4-0.4-0.7-0.5-0.9
                                    l-0.1-0.5l0.3-0.4c1.6-2.4,2.9-5.2,3.6-7.9c0.8-3.1,0.4-4.3,0.3-4.4c-2.8-3.6-7.7-2.1-8.3-2h-0.1h-0.3h-0.3
                                    c-0.7-0.1-1.3-0.1-2-0.3c-1.2,0-2,0.3-2.8,0.8l-0.5,0.4l-0.7-0.3c-1.3-0.5-5.2-1.6-7.2-0.1c-1.2,0.8-1.6,2.3-1.6,4.7
                                    c0,0.8,0.5,3.7,1.5,6.7c1.2,4,2.3,5.1,2.5,5.1c0,0,0.1-0.1,0.4-0.4c1.3-1.6,2.5-2.9,2.7-2.9l0.8-0.8l0.9,0.5
                                    c0.3,0.3,0.7,0.3,0.9,0.4l2.1-1.9l-0.3,3.2c0,0,0,0.1,0,0.3l0.1,0.5l-0.4,0.4l0,0l0.9,0.4L28.5,33.3z"
        />
      </g>
      <path
        class="st2"
        d="M40.7,32.3c-3.5,0.7-3.6-0.4-3.6-0.4c3.6-5.3,5.1-12.1,3.9-13.9c-3.6-4.5-9.7-2.4-9.7-2.4l0,0
                                c-0.7-0.1-1.5-0.3-2.3-0.3c-1.6,0-2.7,0.4-3.6,1.1c0,0-10.9-4.5-10.4,5.7c-0.1,2.1,2.8,16.5,6.4,12.1c1.3-1.6,2.5-2.9,2.5-2.9
                                c0.7,0.4,1.3,0.7,2.1,0.5l0,0c0,0.1,0,0.4,0,0.7c-0.9,1.1-0.7,1.2-2.5,1.6c-1.9,0.4-0.8,1.1,0,1.2c0.8,0.3,2.9,0.5,4.3-1.3v0.3
                                c0.4,0.3,0.7,1.9,0.5,3.3c0,1.5-0.1,2.4,0.3,3.2c0.3,0.8,0.5,2.5,2.9,2c2-0.4,3.1-1.6,3.2-3.5c0.1-1.3,0.4-1.2,0.4-2.4l0.1-0.5
                                c0.3-1.9,0-2.4,1.3-2.1h0.3c0.9,0,2.1-0.1,2.8-0.5C41.2,33.1,42.1,31.9,40.7,32.3L40.7,32.3z"
      />
      <path
        class="st0"
        d="M30.9,43.4c-2,0-2.7-1.6-2.9-2.3c-0.4-0.9-0.5-4.7-0.4-7.7c0-0.3,0.3-0.4,0.4-0.4c0.3,0,0.4,0.3,0.4,0.4
                                c-0.1,3.5,0,6.7,0.3,7.5c0.4,1.1,1.1,2,2.9,1.6c1.9-0.4,2.4-1.1,2.8-2.8c0.3-1.3,0.7-5.2,0.8-5.9c0-0.3,0.3-0.4,0.4-0.4
                                c0.3,0,0.4,0.3,0.4,0.4c0,0.8-0.5,4.5-0.8,6c-0.4,2.1-1.3,3.1-3.5,3.5C31.5,43.4,31.2,43.4,30.9,43.4z"
      />
      <path
        class="st0"
        d="M20,35.5c-0.1,0-0.4,0-0.5-0.1c-1.3-0.4-2.5-2.5-3.7-6.4c-0.9-2.8-1.5-5.9-1.5-6.8c-0.3-2.9,0.5-4.9,2.1-6.1
                                c1.3-0.9,3.2-1.2,5.6-0.9c1.7,0.3,3.1,0.8,3.2,0.8c0.3,0.1,0.3,0.4,0.3,0.5c-0.1,0.4-0.4,0.4-0.5,0.4c0,0-5.2-2.1-8-0.1
                                c-1.3,0.9-2,2.8-1.9,5.3c0,0.8,0.5,3.7,1.5,6.7c0.9,3.5,2.1,5.6,3.2,5.9c0.1,0,0.7,0.3,1.3-0.7c1.3-1.6,2.5-2.8,2.5-2.8
                                c0.1-0.1,0.4-0.1,0.5,0c0.1,0.1,0.1,0.4,0,0.7c0,0-1.2,1.2-2.5,2.8C21.1,35.3,20.4,35.5,20,35.5z"
      />
      <path
        class="st0"
        d="M36.9,32.2c-0.1,0-0.1,0-0.3-0.1c-0.1-0.1-0.3-0.4-0.1-0.5c3.6-5.5,4.9-12,3.9-13.3c-1.3-1.7-3.3-2.7-5.7-2.8
                                c-1.9,0-3.2,0.4-3.5,0.5l0,0c-0.3,0.1-0.4,0-0.5-0.3c-0.1-0.1,0-0.4,0.1-0.5c0.1-0.1,1.9-0.7,3.9-0.7c2.7,0,4.9,1.1,6.5,3.1
                                c0.4,0.5,0.5,1.3,0.5,2.4c0,0.9-0.3,2.3-0.5,3.5c-0.8,2.8-2.1,6-3.9,8.4C37.2,32.2,37.1,32.2,36.9,32.2z"
      />
      <path
        class="st0"
        d="M37.2,34.7c-0.7,0-1.2-0.1-1.5-0.3c-0.4-0.3-0.4-0.5-0.4-0.7c-0.1-1.6,0.8-2,1.2-2.1c0-0.1-0.1-0.1-0.3-0.3
                                c-0.3-0.4-0.7-0.8-0.9-1.5c0-0.1-0.1-0.4-0.4-0.7c-0.9-1.6-2.7-4.9-1.5-6.5c0.5-0.8,1.6-1.1,3.2-0.9c-0.3-0.7-0.8-1.7-1.7-2.9
                                c-1.1-1.3-3.1-2.9-6.3-2.9c-1.6,0-2.9,0.4-3.9,1.5c-2.1,2-2.1,6.1-2.1,6.1c0,0.3-0.1,0.4-0.4,0.4s-0.4-0.1-0.4-0.4
                                c0-0.1-0.1-4.4,2.4-6.9c1.2-1.2,2.7-1.7,4.5-1.7c3.3,0,5.6,1.7,6.8,3.2c1.3,1.6,2,3.3,2,3.9c0,0.4-0.3,0.5-0.4,0.5h-0.1
                                c-1.9-0.3-2.7,0.1-2.9,0.5c-0.9,1.2,0.8,4.4,1.6,5.7c0.1,0.4,0.3,0.5,0.4,0.8c0.3,0.5,0.5,0.9,0.8,1.3c0.3,0.3,0.4,0.5,0.5,0.8
                                c0,0,0.5,0.7,3.2,0.1c0.7-0.1,1.1,0,1.2,0.4c0.3,0.8-1.2,1.6-1.9,2C39.2,34.6,38.1,34.7,37.2,34.7z M36,33.7
                                c0.1,0.1,0.5,0.3,1.3,0.1c0.8,0,1.6-0.3,2.1-0.4c0.5-0.3,0.9-0.5,1.2-0.8l0,0c-1.7,0.4-2.9,0.3-3.6-0.1c0,0-0.1,0-0.1-0.1h-0.1
                                C36.4,32.6,36,32.7,36,33.7z"
      />
      <path
        class="st0"
        d="M24.8,35.9c-0.4,0-0.9,0-1.3-0.1c-0.1,0-1.3-0.4-1.3-1.1c0-0.7,0.9-0.9,1.3-0.9c1.3-0.3,1.3-0.4,1.7-0.9
                                c0.1-0.1,0.3-0.4,0.5-0.5c0.7-0.8,1.3-0.5,1.6-0.4c0.4,0.1,0.8,0.7,0.9,1.1c0.1,0.4,0.1,0.9-0.1,1.2
                                C27.3,35.4,26.1,35.9,24.8,35.9z M23.1,34.7c0.1,0.1,0.3,0.1,0.5,0.3c1.1,0.3,2.7,0.3,3.7-1.2c0.1-0.1,0.1-0.4,0-0.5
                                c-0.1-0.3-0.3-0.5-0.4-0.5c-0.3-0.1-0.4-0.1-0.7,0.1l0,0c-0.1,0.3-0.3,0.4-0.4,0.5c-0.5,0.7-0.8,0.9-2.3,1.2
                                C23.5,34.6,23.2,34.6,23.1,34.7z M26.1,32.5L26.1,32.5L26.1,32.5z"
      />
      <path
        class="st0"
        d="M26,32.9c-0.3,0-0.4-0.1-0.4-0.4v-0.1c-0.9,0-2-0.4-2.7-1.2c-0.9-0.9-1.3-2.3-1.2-3.7
                                c0.3-1.5,0.1-2.8,0.1-3.5c0-0.1,0-0.4,0-0.4c0-0.1,0-0.5,1.1-1.1c0.4-0.1,1.2-0.5,2.1-0.7c1.5-0.1,2.4,0.5,2.7,1.9
                                c0.8,3.5,0,5.1-0.4,6.3c-0.1,0.3-0.1,0.4-0.3,0.7v0.1c-0.3,0.7-0.5,1.3-0.4,1.7C26.5,32.7,26.4,32.9,26,32.9
                                C26.1,32.9,26.1,32.9,26,32.9z M22.7,23.7c0,0.1,0,0.1,0,0.3c0,0.7,0.1,2.1-0.1,3.6c-0.1,1.1,0.1,2.3,0.9,2.9
                                c0.5,0.7,1.3,0.9,2.1,0.9c0.1-0.4,0.3-0.8,0.4-1.2v-0.1c0.1-0.3,0.1-0.4,0.3-0.7c0.5-1.1,1.1-2.4,0.4-5.7
                                c-0.1-0.7-0.4-0.9-0.9-1.1C24.7,22.5,23.1,23.4,22.7,23.7z"
      />
      <g>
        <path
          class="st0"
          d="M25.1,23.5c0,0.1,0.3,0.5,0.7,0.5s0.7-0.3,0.7-0.4c0-0.1-0.3-0.3-0.7-0.4C25.3,23.3,25.1,23.4,25.1,23.5
                                    L25.1,23.5z"
        />
        <path
          class="st0"
          d="M25.7,24.2C25.7,24.2,25.6,24.2,25.7,24.2c-0.3,0-0.5-0.1-0.7-0.4c0-0.1-0.1-0.3-0.1-0.4l0,0
                                    c0,0,0-0.1,0.1-0.1c0.1-0.1,0.4-0.1,0.7-0.1c0.4,0,0.8,0.3,0.7,0.5C26.4,23.9,26.1,24.2,25.7,24.2z M25.2,23.5
                                    c0,0.1,0.3,0.4,0.5,0.4s0.5-0.1,0.5-0.3c0,0-0.1-0.1-0.5-0.1C25.5,23.4,25.2,23.5,25.2,23.5z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M35.9,23.1c0,0.1-0.3,0.5-0.7,0.5c-0.4,0-0.7-0.3-0.7-0.4s0.3-0.3,0.7-0.4C35.5,23,35.9,23,35.9,23.1
                                    L35.9,23.1z"
        />
        <path
          class="st0"
          d="M35.2,23.8c-0.4,0-0.7-0.3-0.7-0.4c0-0.3,0.3-0.4,0.7-0.4c0.4,0,0.7,0,0.8,0.3l0,0c0,0.1,0,0.3-0.1,0.4
                                    C35.6,23.7,35.5,23.8,35.2,23.8L35.2,23.8z M35.3,23.1c0,0-0.1,0-0.3,0c-0.4,0-0.5,0.3-0.5,0.3c0.1,0,0.4,0.3,0.7,0.3
                                    c0.1,0,0.4-0.1,0.4-0.3c0.1-0.1,0.1-0.3,0.1-0.3S35.6,23.1,35.3,23.1z"
        />
      </g>
      <path
        class="st0"
        d="M36.4,31.3c-0.1,0-0.1,0-0.3-0.1C36,31,35.9,30.7,36,30.6c0.8-1.2,0.7-2.5,0.5-3.7c0-0.5-0.1-1.1-0.1-1.6
                                s0.1-0.9,0.1-1.5c0.1-0.5,0.1-0.9,0.1-1.6c0-0.3,0.1-0.4,0.4-0.4c0.3,0,0.4,0.1,0.4,0.4c0,0.7,0,1.2-0.1,1.7
                                c0,0.4-0.1,0.8-0.1,1.3c0,0.4,0,0.9,0.1,1.5c0.1,1.3,0.4,2.8-0.7,4.3C36.7,31.1,36.5,31.3,36.4,31.3z"
      />
      <path class="st3" d="M13.3,17.9" />
    </g>
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-post'" class="icon-chart-post">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g id="Layer_4">
    <g id="Layer_x0020_3">
      <g>
        <path
          d="M35.2,35.9c0.1-1.6,0.1-1.9,1.3-1.6h0.3c0.9,0,2.1-0.1,2.8-0.5c1.5-0.7,2.4-1.9,0.9-1.6c-3.5,0.7-3.6-0.4-3.6-0.4
                                    c3.6-5.3,5.1-12.1,3.9-13.9c-3.6-4.5-9.7-2.4-9.7-2.4l0,0c-0.7-0.1-1.5-0.3-2.3-0.3c-1.6,0-2.7,0.4-3.6,1.1
                                    c0,0-10.9-4.5-10.4,5.7c0,2.3,2.9,16.7,6.5,12.3c1.3-1.6,2.5-2.9,2.5-2.9c0.7,0.4,1.3,0.7,2.1,0.5l0,0c0,0.1,0,0.4,0,0.7
                                    c-0.9,1.1-0.7,1.2-2.5,1.6c-1.9,0.4-0.8,1.1,0,1.2c0.8,0.3,2.9,0.5,4.3-1.3v0.3c0.4,0.3,0.4,2.1,0.4,3.3c0,1.3,0.1,2.5,0.4,3.2
                                    c0.3,0.7,0.5,2.5,2.9,2C33.6,42.3,35.1,41.8,35.2,35.9"
        />
        <path
          class="st2"
          d="M30.8,44.2c-0.8,0-1.6-0.1-2.3-0.8c-0.7-0.7-0.9-1.5-1.1-1.9c0-0.1,0-0.1,0-0.1c-0.3-0.8-0.4-2-0.5-3.6
                                    c0-0.3,0-0.5,0-0.7s0-0.3,0-0.5c-1.1,0.4-2.3,0.5-3.6,0.3c-0.4-0.1-0.7-0.3-1.1-0.4c-0.3-0.1-0.4-0.3-0.5-0.4
                                    c-1.1,0.9-2,0.8-2.5,0.5c-0.9-0.3-1.9-1.2-2.7-2.8c-0.5-1.1-1.1-2.5-1.6-4.1c-0.9-3.2-1.5-6.4-1.6-7.3c-0.1-3.3,0.8-5.6,2.7-6.9
                                    c1.5-1.1,3.6-1.5,6.3-0.9c1.1,0.1,2.1,0.5,2.7,0.7c1.1-0.7,2.4-0.9,3.7-0.9c0.8,0,1.5,0.1,2.1,0.3c0.7-0.3,2.1-0.5,3.7-0.5
                                    c2.9,0,5.5,1.2,7.2,3.3c0.7,0.8,1.2,2.7,0.1,6.7c-0.5,2.4-1.6,5.1-3.1,7.3c0.4,0,0.8,0,1.5-0.3c0.4-0.1,0.7-0.1,0.9,0
                                    c0.5,0.1,1.1,0.4,1.2,0.9c0.5,1.6-1.7,2.7-2.4,3.1c-0.8,0.4-2.3,0.7-3.5,0.5l0,0h-0.3v0.1c0,0.1,0,0.1,0,0.3
                                    c-0.1,2.9-0.5,4.8-1.3,6.1c-0.9,1.3-2.3,1.7-3.3,2C31.5,44.1,31.2,44.2,30.8,44.2z M28.5,33.3c0.7,0.5,0.8,1.6,0.8,3.6
                                    c0,0.3,0,0.5,0,0.7c0,0.7,0.1,2.1,0.3,2.8c0,0.1,0,0.1,0.1,0.3c0.3,0.8,0.4,1.2,1.5,0.9c0.9-0.3,1.5-0.4,1.7-0.9
                                    c0.5-0.8,0.8-2.4,0.9-4.8l0,0l0,0c0-0.1,0-0.3,0-0.3c0.1-0.9,0.1-1.7,0.9-2.1c0.4-0.3,0.9-0.4,1.3-0.3c-0.4-0.4-0.4-0.7-0.5-0.9
                                    l-0.1-0.5l0.3-0.4c1.6-2.4,2.9-5.2,3.6-7.9c0.8-3.1,0.4-4.3,0.3-4.4c-2.8-3.6-7.7-2.1-8.3-2h-0.1h-0.3h-0.3
                                    c-0.7-0.1-1.3-0.1-2-0.3c-1.2,0-2,0.3-2.8,0.8l-0.5,0.4l-0.7-0.3c-1.3-0.5-5.2-1.6-7.2-0.1c-1.2,0.8-1.6,2.3-1.6,4.7
                                    c0,0.8,0.5,3.7,1.5,6.7c1.2,4,2.3,5.1,2.5,5.1c0,0,0.1-0.1,0.4-0.4c1.3-1.6,2.5-2.9,2.7-2.9l0.8-0.8l0.9,0.5
                                    c0.3,0.3,0.7,0.3,0.9,0.4l2.1-1.9l-0.3,3.2c0,0,0,0.1,0,0.3l0.1,0.5l-0.4,0.4l0,0l0.9,0.4L28.5,33.3z"
        />
      </g>
      <path
        class="st2"
        d="M40.7,32.3c-3.5,0.7-3.6-0.4-3.6-0.4c3.6-5.3,5.1-12.1,3.9-13.9c-3.6-4.5-9.7-2.4-9.7-2.4l0,0
                                c-0.7-0.1-1.5-0.3-2.3-0.3c-1.6,0-2.7,0.4-3.6,1.1c0,0-10.9-4.5-10.4,5.7c-0.1,2.1,2.8,16.5,6.4,12.1c1.3-1.6,2.5-2.9,2.5-2.9
                                c0.7,0.4,1.3,0.7,2.1,0.5l0,0c0,0.1,0,0.4,0,0.7c-0.9,1.1-0.7,1.2-2.5,1.6c-1.9,0.4-0.8,1.1,0,1.2c0.8,0.3,2.9,0.5,4.3-1.3v0.3
                                c0.4,0.3,0.7,1.9,0.5,3.3c0,1.5-0.1,2.4,0.3,3.2c0.3,0.8,0.5,2.5,2.9,2c2-0.4,3.1-1.6,3.2-3.5c0.1-1.3,0.4-1.2,0.4-2.4l0.1-0.5
                                c0.3-1.9,0-2.4,1.3-2.1h0.3c0.9,0,2.1-0.1,2.8-0.5C41.2,33.1,42.1,31.9,40.7,32.3L40.7,32.3z"
      />
      <path
        class="st0"
        d="M30.9,43.4c-2,0-2.7-1.6-2.9-2.3c-0.4-0.9-0.5-4.7-0.4-7.7c0-0.3,0.3-0.4,0.4-0.4c0.3,0,0.4,0.3,0.4,0.4
                                c-0.1,3.5,0,6.7,0.3,7.5c0.4,1.1,1.1,2,2.9,1.6c1.9-0.4,2.4-1.1,2.8-2.8c0.3-1.3,0.7-5.2,0.8-5.9c0-0.3,0.3-0.4,0.4-0.4
                                c0.3,0,0.4,0.3,0.4,0.4c0,0.8-0.5,4.5-0.8,6c-0.4,2.1-1.3,3.1-3.5,3.5C31.5,43.4,31.2,43.4,30.9,43.4z"
      />
      <path
        class="st0"
        d="M20,35.5c-0.1,0-0.4,0-0.5-0.1c-1.3-0.4-2.5-2.5-3.7-6.4c-0.9-2.8-1.5-5.9-1.5-6.8c-0.3-2.9,0.5-4.9,2.1-6.1
                                c1.3-0.9,3.2-1.2,5.6-0.9c1.7,0.3,3.1,0.8,3.2,0.8c0.3,0.1,0.3,0.4,0.3,0.5c-0.1,0.4-0.4,0.4-0.5,0.4c0,0-5.2-2.1-8-0.1
                                c-1.3,0.9-2,2.8-1.9,5.3c0,0.8,0.5,3.7,1.5,6.7c0.9,3.5,2.1,5.6,3.2,5.9c0.1,0,0.7,0.3,1.3-0.7c1.3-1.6,2.5-2.8,2.5-2.8
                                c0.1-0.1,0.4-0.1,0.5,0c0.1,0.1,0.1,0.4,0,0.7c0,0-1.2,1.2-2.5,2.8C21.1,35.3,20.4,35.5,20,35.5z"
      />
      <path
        class="st0"
        d="M36.9,32.2c-0.1,0-0.1,0-0.3-0.1c-0.1-0.1-0.3-0.4-0.1-0.5c3.6-5.5,4.9-12,3.9-13.3c-1.3-1.7-3.3-2.7-5.7-2.8
                                c-1.9,0-3.2,0.4-3.5,0.5l0,0c-0.3,0.1-0.4,0-0.5-0.3c-0.1-0.1,0-0.4,0.1-0.5c0.1-0.1,1.9-0.7,3.9-0.7c2.7,0,4.9,1.1,6.5,3.1
                                c0.4,0.5,0.5,1.3,0.5,2.4c0,0.9-0.3,2.3-0.5,3.5c-0.8,2.8-2.1,6-3.9,8.4C37.2,32.2,37.1,32.2,36.9,32.2z"
      />
      <path
        class="st0"
        d="M37.2,34.7c-0.7,0-1.2-0.1-1.5-0.3c-0.4-0.3-0.4-0.5-0.4-0.7c-0.1-1.6,0.8-2,1.2-2.1c0-0.1-0.1-0.1-0.3-0.3
                                c-0.3-0.4-0.7-0.8-0.9-1.5c0-0.1-0.1-0.4-0.4-0.7c-0.9-1.6-2.7-4.9-1.5-6.5c0.5-0.8,1.6-1.1,3.2-0.9c-0.3-0.7-0.8-1.7-1.7-2.9
                                c-1.1-1.3-3.1-2.9-6.3-2.9c-1.6,0-2.9,0.4-3.9,1.5c-2.1,2-2.1,6.1-2.1,6.1c0,0.3-0.1,0.4-0.4,0.4s-0.4-0.1-0.4-0.4
                                c0-0.1-0.1-4.4,2.4-6.9c1.2-1.2,2.7-1.7,4.5-1.7c3.3,0,5.6,1.7,6.8,3.2c1.3,1.6,2,3.3,2,3.9c0,0.4-0.3,0.5-0.4,0.5h-0.1
                                c-1.9-0.3-2.7,0.1-2.9,0.5c-0.9,1.2,0.8,4.4,1.6,5.7c0.1,0.4,0.3,0.5,0.4,0.8c0.3,0.5,0.5,0.9,0.8,1.3c0.3,0.3,0.4,0.5,0.5,0.8
                                c0,0,0.5,0.7,3.2,0.1c0.7-0.1,1.1,0,1.2,0.4c0.3,0.8-1.2,1.6-1.9,2C39.2,34.6,38.1,34.7,37.2,34.7z M36,33.7
                                c0.1,0.1,0.5,0.3,1.3,0.1c0.8,0,1.6-0.3,2.1-0.4c0.5-0.3,0.9-0.5,1.2-0.8l0,0c-1.7,0.4-2.9,0.3-3.6-0.1c0,0-0.1,0-0.1-0.1h-0.1
                                C36.4,32.6,36,32.7,36,33.7z"
      />
      <path
        class="st0"
        d="M24.8,35.9c-0.4,0-0.9,0-1.3-0.1c-0.1,0-1.3-0.4-1.3-1.1c0-0.7,0.9-0.9,1.3-0.9c1.3-0.3,1.3-0.4,1.7-0.9
                                c0.1-0.1,0.3-0.4,0.5-0.5c0.7-0.8,1.3-0.5,1.6-0.4c0.4,0.1,0.8,0.7,0.9,1.1c0.1,0.4,0.1,0.9-0.1,1.2
                                C27.3,35.4,26.1,35.9,24.8,35.9z M23.1,34.7c0.1,0.1,0.3,0.1,0.5,0.3c1.1,0.3,2.7,0.3,3.7-1.2c0.1-0.1,0.1-0.4,0-0.5
                                c-0.1-0.3-0.3-0.5-0.4-0.5c-0.3-0.1-0.4-0.1-0.7,0.1l0,0c-0.1,0.3-0.3,0.4-0.4,0.5c-0.5,0.7-0.8,0.9-2.3,1.2
                                C23.5,34.6,23.2,34.6,23.1,34.7z M26.1,32.5L26.1,32.5L26.1,32.5z"
      />
      <path
        class="st0"
        d="M26,32.9c-0.3,0-0.4-0.1-0.4-0.4v-0.1c-0.9,0-2-0.4-2.7-1.2c-0.9-0.9-1.3-2.3-1.2-3.7
                                c0.3-1.5,0.1-2.8,0.1-3.5c0-0.1,0-0.4,0-0.4c0-0.1,0-0.5,1.1-1.1c0.4-0.1,1.2-0.5,2.1-0.7c1.5-0.1,2.4,0.5,2.7,1.9
                                c0.8,3.5,0,5.1-0.4,6.3c-0.1,0.3-0.1,0.4-0.3,0.7v0.1c-0.3,0.7-0.5,1.3-0.4,1.7C26.5,32.7,26.4,32.9,26,32.9
                                C26.1,32.9,26.1,32.9,26,32.9z M22.7,23.7c0,0.1,0,0.1,0,0.3c0,0.7,0.1,2.1-0.1,3.6c-0.1,1.1,0.1,2.3,0.9,2.9
                                c0.5,0.7,1.3,0.9,2.1,0.9c0.1-0.4,0.3-0.8,0.4-1.2v-0.1c0.1-0.3,0.1-0.4,0.3-0.7c0.5-1.1,1.1-2.4,0.4-5.7
                                c-0.1-0.7-0.4-0.9-0.9-1.1C24.7,22.5,23.1,23.4,22.7,23.7z"
      />
      <g>
        <path
          class="st0"
          d="M25.1,23.5c0,0.1,0.3,0.5,0.7,0.5s0.7-0.3,0.7-0.4c0-0.1-0.3-0.3-0.7-0.4C25.3,23.3,25.1,23.4,25.1,23.5
                                    L25.1,23.5z"
        />
        <path
          class="st0"
          d="M25.7,24.2C25.7,24.2,25.6,24.2,25.7,24.2c-0.3,0-0.5-0.1-0.7-0.4c0-0.1-0.1-0.3-0.1-0.4l0,0
                                    c0,0,0-0.1,0.1-0.1c0.1-0.1,0.4-0.1,0.7-0.1c0.4,0,0.8,0.3,0.7,0.5C26.4,23.9,26.1,24.2,25.7,24.2z M25.2,23.5
                                    c0,0.1,0.3,0.4,0.5,0.4s0.5-0.1,0.5-0.3c0,0-0.1-0.1-0.5-0.1C25.5,23.4,25.2,23.5,25.2,23.5z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M35.9,23.1c0,0.1-0.3,0.5-0.7,0.5c-0.4,0-0.7-0.3-0.7-0.4s0.3-0.3,0.7-0.4C35.5,23,35.9,23,35.9,23.1
                                    L35.9,23.1z"
        />
        <path
          class="st0"
          d="M35.2,23.8c-0.4,0-0.7-0.3-0.7-0.4c0-0.3,0.3-0.4,0.7-0.4c0.4,0,0.7,0,0.8,0.3l0,0c0,0.1,0,0.3-0.1,0.4
                                    C35.6,23.7,35.5,23.8,35.2,23.8L35.2,23.8z M35.3,23.1c0,0-0.1,0-0.3,0c-0.4,0-0.5,0.3-0.5,0.3c0.1,0,0.4,0.3,0.7,0.3
                                    c0.1,0,0.4-0.1,0.4-0.3c0.1-0.1,0.1-0.3,0.1-0.3S35.6,23.1,35.3,23.1z"
        />
      </g>
      <path
        class="st0"
        d="M36.4,31.3c-0.1,0-0.1,0-0.3-0.1C36,31,35.9,30.7,36,30.6c0.8-1.2,0.7-2.5,0.5-3.7c0-0.5-0.1-1.1-0.1-1.6
                                s0.1-0.9,0.1-1.5c0.1-0.5,0.1-0.9,0.1-1.6c0-0.3,0.1-0.4,0.4-0.4c0.3,0,0.4,0.1,0.4,0.4c0,0.7,0,1.2-0.1,1.7
                                c0,0.4-0.1,0.8-0.1,1.3c0,0.4,0,0.9,0.1,1.5c0.1,1.3,0.4,2.8-0.7,4.3C36.7,31.1,36.5,31.3,36.4,31.3z"
      />
      <path class="st3" d="M13.3,17.9" />
    </g>
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-presto-focus'" class="icon-chart-presto-focus">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g id="Layer_2">
    <path class="st2" d="M44,28.1c0,1-0.8,1.8-1.8,1.8s-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8S44,27.1,44,28.1" />
    <path class="st3" d="M40.3,31.6c0,1-0.8,1.8-1.8,1.8s-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8S40.3,30.7,40.3,31.6" />
    <path class="st4" d="M36.7,35.3c0,1-0.8,1.8-1.8,1.8s-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8C36,33.5,36.7,34.2,36.7,35.3" />
    <path
      class="st2"
      d="M40.3,24.4c0,0.9-0.8,1.7-1.7,1.7c-0.9,0-1.7-0.8-1.7-1.7s0.8-1.5,1.7-1.5C39.5,22.9,40.3,23.5,40.3,24.4"
    />
    <path
      class="st3"
      d="M36.6,28c0,0.9-0.8,1.7-1.7,1.7c-0.9,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7C36,26.3,36.6,27.1,36.6,28"
    />
    <path class="st4" d="M33.1,31.7c0,0.9-0.8,1.7-1.7,1.7s-1.7-0.8-1.7-1.7c0-0.9,0.8-1.7,1.7-1.7S33.1,30.8,33.1,31.7" />
    <path
      class="st2"
      d="M36.5,20.9c0,0.8-0.6,1.5-1.5,1.5c-0.9,0-1.5-0.6-1.5-1.5c0-0.8,0.6-1.5,1.5-1.5
                            C35.9,19.3,36.5,20.1,36.5,20.9"
    />
    <path
      class="st3"
      d="M32.9,24.4c0,0.8-0.6,1.5-1.5,1.5c-0.8,0-1.5-0.6-1.5-1.5c0-0.8,0.6-1.5,1.5-1.5C32.3,23,32.9,23.7,32.9,24.4"
    />
    <path
      class="st4"
      d="M29.4,28.1c0,0.8-0.6,1.5-1.5,1.5c-0.8,0-1.5-0.6-1.5-1.5c0-0.8,0.6-1.5,1.5-1.5
                            C28.7,26.6,29.4,27.2,29.4,28.1"
    />
    <path
      class="st2"
      d="M32.8,17.3c0,0.8-0.6,1.3-1.3,1.3c-0.6,0-1.3-0.6-1.3-1.3c0-0.8,0.6-1.3,1.3-1.3C32.2,16,32.8,16.5,32.8,17.3"
    />
    <path
      class="st3"
      d="M29.2,20.9c0,0.8-0.6,1.3-1.3,1.3c-0.8,0-1.3-0.6-1.3-1.3c0-0.8,0.6-1.3,1.3-1.3S29.2,20.1,29.2,20.9"
    />
    <path
      class="st4"
      d="M25.7,24.6c0,0.8-0.6,1.3-1.3,1.3c-0.6,0-1.3-0.6-1.3-1.3c0-0.8,0.6-1.3,1.3-1.3C25,23.3,25.7,23.8,25.7,24.6"
    />
    <path
      class="st5"
      d="M25.5,31.7c0,0.6-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1C24.9,30.4,25.5,31.1,25.5,31.7"
    />
    <path
      class="st5"
      d="M29.1,35.3c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1C28.5,34,29.1,34.6,29.1,35.3"
    />
    <path
      class="st5"
      d="M32.7,38.8c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1
                            C32.2,37.7,32.7,38.2,32.7,38.8"
    />
    <path
      class="st5"
      d="M25.5,38.8c0,0.6-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1C25,37.5,25.5,38.2,25.5,38.8"
    />
    <path
      class="st5"
      d="M18.3,38.8c0,0.6-0.5,1.1-1.1,1.1S16,39.5,16,38.8c0-0.6,0.5-1.1,1.1-1.1C17.8,37.5,18.3,38.2,18.3,38.8"
    />
    <path
      class="st5"
      d="M21.9,35.3c0,0.6-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1C21.3,34,21.9,34.6,21.9,35.3"
    />
    <path
      class="st3"
      d="M25.5,17.3c0,0.6-0.5,1.3-1.3,1.3c-0.8,0-1.3-0.5-1.3-1.3c0-0.6,0.5-1.3,1.3-1.3C25,16,25.5,16.7,25.5,17.3"
    />
    <path
      class="st4"
      d="M21.9,21c0,0.6-0.5,1.3-1.3,1.3c-0.6,0-1.3-0.5-1.3-1.3c0-0.6,0.5-1.3,1.3-1.3C21.3,19.7,21.9,20.2,21.9,21"
    />
    <path class="st4" d="M18.2,17.3c0,0.5-0.5,1-1,1s-1-0.5-1-1c0-0.5,0.5-1,1-1S18.2,16.8,18.2,17.3" />
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-presto'" class="icon-chart-presto">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g id="Layer_2_1_">
    <path class="st2" d="M44,28.1c0,1-0.8,1.8-1.8,1.8s-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8S44,27.1,44,28.1" />
    <path class="st3" d="M40.3,31.6c0,1-0.8,1.8-1.8,1.8s-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8S40.3,30.7,40.3,31.6" />
    <path class="st4" d="M36.7,35.3c0,1-0.8,1.8-1.8,1.8s-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8C36,33.5,36.7,34.2,36.7,35.3" />
    <path
      class="st2"
      d="M40.3,24.4c0,0.9-0.8,1.7-1.7,1.7c-0.9,0-1.7-0.8-1.7-1.7s0.8-1.5,1.7-1.5C39.5,22.9,40.3,23.5,40.3,24.4"
    />
    <path
      class="st3"
      d="M36.6,28c0,0.9-0.8,1.7-1.7,1.7c-0.9,0-1.7-0.8-1.7-1.7s0.8-1.7,1.7-1.7C36,26.3,36.6,27.1,36.6,28"
    />
    <path class="st4" d="M33.1,31.7c0,0.9-0.8,1.7-1.7,1.7s-1.7-0.8-1.7-1.7c0-0.9,0.8-1.7,1.7-1.7S33.1,30.8,33.1,31.7" />
    <path
      class="st2"
      d="M36.5,20.9c0,0.8-0.6,1.5-1.5,1.5c-0.9,0-1.5-0.6-1.5-1.5c0-0.8,0.6-1.5,1.5-1.5
                            C35.9,19.3,36.5,20.1,36.5,20.9"
    />
    <path
      class="st3"
      d="M32.9,24.4c0,0.8-0.6,1.5-1.5,1.5c-0.8,0-1.5-0.6-1.5-1.5c0-0.8,0.6-1.5,1.5-1.5C32.3,23,32.9,23.7,32.9,24.4"
    />
    <path
      class="st4"
      d="M29.4,28.1c0,0.8-0.6,1.5-1.5,1.5c-0.8,0-1.5-0.6-1.5-1.5c0-0.8,0.6-1.5,1.5-1.5
                            C28.7,26.6,29.4,27.2,29.4,28.1"
    />
    <path
      class="st2"
      d="M32.8,17.3c0,0.8-0.6,1.3-1.3,1.3c-0.6,0-1.3-0.6-1.3-1.3c0-0.8,0.6-1.3,1.3-1.3C32.2,16,32.8,16.5,32.8,17.3"
    />
    <path
      class="st3"
      d="M29.2,20.9c0,0.8-0.6,1.3-1.3,1.3c-0.8,0-1.3-0.6-1.3-1.3c0-0.8,0.6-1.3,1.3-1.3S29.2,20.1,29.2,20.9"
    />
    <path
      class="st4"
      d="M25.7,24.6c0,0.8-0.6,1.3-1.3,1.3c-0.6,0-1.3-0.6-1.3-1.3c0-0.8,0.6-1.3,1.3-1.3C25,23.3,25.7,23.8,25.7,24.6"
    />
    <path
      class="st5"
      d="M25.5,31.7c0,0.6-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1C24.9,30.4,25.5,31.1,25.5,31.7"
    />
    <path
      class="st5"
      d="M29.1,35.3c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1C28.5,34,29.1,34.6,29.1,35.3"
    />
    <path
      class="st5"
      d="M32.7,38.8c0,0.6-0.5,1.1-1.1,1.1c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1
                            C32.2,37.7,32.7,38.2,32.7,38.8"
    />
    <path
      class="st5"
      d="M25.5,38.8c0,0.6-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1C25,37.5,25.5,38.2,25.5,38.8"
    />
    <path
      class="st5"
      d="M18.3,38.8c0,0.6-0.5,1.1-1.1,1.1S16,39.5,16,38.8c0-0.6,0.5-1.1,1.1-1.1C17.8,37.5,18.3,38.2,18.3,38.8"
    />
    <path
      class="st5"
      d="M21.9,35.3c0,0.6-0.5,1.1-1.1,1.1s-1.1-0.5-1.1-1.1s0.5-1.1,1.1-1.1C21.3,34,21.9,34.6,21.9,35.3"
    />
    <path
      class="st3"
      d="M25.5,17.3c0,0.6-0.5,1.3-1.3,1.3c-0.8,0-1.3-0.5-1.3-1.3c0-0.6,0.5-1.3,1.3-1.3C25,16,25.5,16.7,25.5,17.3"
    />
    <path
      class="st4"
      d="M21.9,21c0,0.6-0.5,1.3-1.3,1.3c-0.6,0-1.3-0.5-1.3-1.3c0-0.6,0.5-1.3,1.3-1.3C21.3,19.7,21.9,20.2,21.9,21"
    />
    <path class="st4" d="M18.2,17.3c0,0.5-0.5,1-1,1s-1-0.5-1-1c0-0.5,0.5-1,1-1S18.2,16.8,18.2,17.3" />
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-rds-focus'" class="icon-chart-rds-focus">
  <g>
    <path
      class="st0"
      d="M28,54.8c-14.8,0-26.8-12-26.8-26.7c0-14.8,12-26.8,26.8-26.8s26.8,12,26.8,26.8C54.8,42.8,42.8,54.8,28,54.8z
                            "
    />
    <path
      class="st1"
      d="M28,2.6c14.1,0,25.5,11.4,25.5,25.5S42.1,53.6,28,53.6S2.5,42.1,2.5,28.1S13.9,2.6,28,2.6 M28,0.1
                            c-15.5,0-28,12.5-28,28s12.5,28,28,28s28-12.5,28-28S43.5,0.1,28,0.1L28,0.1z"
    />
  </g>
  <g id="Layer_1_1_">
    <g>
      <polygon class="st2" points="28,35.8 39.2,38.5 39.2,17.5 28,20.2 		" />
      <polygon class="st3" points="39.2,17.5 41.3,18.6 41.3,37.4 39.2,38.5 		" />
      <polygon class="st3" points="28,35.8 16.8,38.5 16.8,17.5 28,20.2 		" />
      <polygon class="st2" points="16.8,17.5 14.7,18.6 14.7,37.4 16.8,38.5 		" />
      <polygon class="st3" points="30.8,42.7 35.1,40.6 35.1,15.5 30.8,13.4 29.5,27.3 		" />
      <polygon class="st2" points="25.2,42.7 20.9,40.6 20.9,15.5 25.2,13.4 26.5,27.3 		" />
      <rect x="25.2" y="13.4" class="st4" width="5.7" height="29.3" />
    </g>
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-rds'" class="icon-chart-rds">
  <g>
    <path
      class="st0"
      d="M28,54.8c-14.8,0-26.8-12-26.8-26.7c0-14.8,12-26.8,26.8-26.8s26.8,12,26.8,26.8C54.8,42.8,42.8,54.8,28,54.8z
                            "
    />
    <path
      class="st1"
      d="M28,2.6c14.1,0,25.5,11.4,25.5,25.5S42.1,53.6,28,53.6S2.5,42.1,2.5,28.1S13.9,2.6,28,2.6 M28,0.1
                            c-15.5,0-28,12.5-28,28s12.5,28,28,28s28-12.5,28-28S43.5,0.1,28,0.1L28,0.1z"
    />
  </g>
  <g id="Layer_1_1_">
    <g>
      <polygon class="st2" points="28,35.8 39.2,38.5 39.2,17.5 28,20.2 		" />
      <polygon class="st3" points="39.2,17.5 41.3,18.6 41.3,37.4 39.2,38.5 		" />
      <polygon class="st3" points="28,35.8 16.8,38.5 16.8,17.5 28,20.2 		" />
      <polygon class="st2" points="16.8,17.5 14.7,18.6 14.7,37.4 16.8,38.5 		" />
      <polygon class="st3" points="30.8,42.7 35.1,40.6 35.1,15.5 30.8,13.4 29.5,27.3 		" />
      <polygon class="st2" points="25.2,42.7 20.9,40.6 20.9,15.5 25.2,13.4 26.5,27.3 		" />
      <rect x="25.2" y="13.4" class="st4" width="5.7" height="29.3" />
    </g>
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-tibero-focus'" class="icon-chart-tibero-focus">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g id="Layer_8">
    <polygon class="st2" points="41,19.9 21,19.9 19.7,26 25.1,26 21.7,42.7 30.6,42.7 34.2,26 39.7,26 	" />
    <polygon class="st3" points="27.5,23.2 17,23.2 19.1,13.3 29.5,13.3 	" />
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-tibero'" class="icon-chart-tibero">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g id="Layer_8">
    <polygon class="st2" points="41,19.9 21,19.9 19.7,26 25.1,26 21.7,42.7 30.6,42.7 34.2,26 39.7,26 	" />
    <polygon class="st3" points="27.5,23.2 17,23.2 19.1,13.3 29.5,13.3 	" />
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-db-focus'" class="icon-chart-db-focus">
  <g class="st0">
    <path class="st1" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st2"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g>
    <path class="st3" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st4"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g>
    <g>
      <g>
        <path
          class="st5"
          d="M39,15.5L39,15.5c0-0.1-0.1-0.3-0.1-0.4l0,0l0,0c-0.1,0-0.4-0.1-0.5-0.1H17.7c-0.3,0-0.4,0.1-0.5,0.3l0,0
                                    l0,0c0,0.1-0.1,0.1-0.1,0.3c0,0,0,0,0,0.1l0,0V38c0,1.6,0,1.5,0.8,2.3c0.5,0.4,1.2,0.7,2.6,0.7h13.3c3.3,0,5.2-1.6,5.2-4.8V15.5
                                    C39,15.7,39,15.7,39,15.5z M37.6,36.2c0,2.3-1.2,3.4-3.8,3.4H20.4c-0.8,0-1.4-0.1-1.6-0.4c-0.4-0.4-0.4,0-0.4-1.4v-5.1h19.2V36.2
                                    z M37.6,31.4H18.4v-6.8h19.2V31.4z M37.6,23.2H18.4v-6.8h19.2V23.2z M34.9,35.5h-9.6v1.4h9.6V35.5z M34.9,27.3h-9.6v1.4h9.6V27.3
                                    z M34.9,19.1h-9.6v1.4h9.6V19.1z"
        />
      </g>
    </g>
    <path
      class="st5"
      d="M23.2,37h-1.4c-0.5,0-0.8-0.4-0.8-0.8l0,0c0-0.5,0.4-0.8,0.8-0.8h1.4c0.5,0,0.8,0.4,0.8,0.8l0,0
                            C24,36.8,23.6,37,23.2,37z"
    />
    <path
      class="st5"
      d="M23.2,28.8h-1.4c-0.5,0-0.8-0.4-0.8-0.8l0,0c0-0.5,0.4-0.8,0.8-0.8h1.4c0.5,0,0.8,0.4,0.8,0.8l0,0
                            C24,28.5,23.6,28.8,23.2,28.8z"
    />
    <path
      class="st5"
      d="M23.2,20.6h-1.4c-0.5,0-0.8-0.4-0.8-0.8l0,0c0-0.5,0.4-0.8,0.8-0.8h1.4c0.5,0,0.8,0.4,0.8,0.8l0,0
                            C24,20.3,23.6,20.6,23.2,20.6z"
    />
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-db'" class="icon-chart-db">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g>
    <g>
      <g>
        <path
          class="st2"
          d="M39,15.5L39,15.5c0-0.1-0.1-0.3-0.1-0.4l0,0l0,0c-0.1,0-0.4-0.1-0.5-0.1H17.7c-0.3,0-0.4,0.1-0.5,0.3l0,0
                                    l0,0c0,0.1-0.1,0.1-0.1,0.3c0,0,0,0,0,0.1l0,0V38c0,1.6,0,1.5,0.8,2.3c0.5,0.4,1.2,0.7,2.6,0.7h13.3c3.3,0,5.2-1.6,5.2-4.8V15.5
                                    C39,15.7,39,15.7,39,15.5z M37.6,36.2c0,2.3-1.2,3.4-3.8,3.4H20.4c-0.8,0-1.4-0.1-1.6-0.4c-0.4-0.4-0.4,0-0.4-1.4v-5.1h19.2V36.2
                                    z M37.6,31.4H18.4v-6.8h19.2V31.4z M37.6,23.2H18.4v-6.8h19.2V23.2z M34.9,35.5h-9.6v1.4h9.6V35.5z M34.9,27.3h-9.6v1.4h9.6V27.3
                                    z M34.9,19.1h-9.6v1.4h9.6V19.1z"
        />
      </g>
    </g>
    <path
      class="st2"
      d="M23.2,37h-1.4c-0.5,0-0.8-0.4-0.8-0.8l0,0c0-0.5,0.4-0.8,0.8-0.8h1.4c0.5,0,0.8,0.4,0.8,0.8l0,0
                            C24,36.8,23.6,37,23.2,37z"
    />
    <path
      class="st2"
      d="M23.2,28.8h-1.4c-0.5,0-0.8-0.4-0.8-0.8l0,0c0-0.5,0.4-0.8,0.8-0.8h1.4c0.5,0,0.8,0.4,0.8,0.8l0,0
                            C24,28.5,23.6,28.8,23.2,28.8z"
    />
    <path
      class="st2"
      d="M23.2,20.6h-1.4c-0.5,0-0.8-0.4-0.8-0.8l0,0c0-0.5,0.4-0.8,0.8-0.8h1.4c0.5,0,0.8,0.4,0.8,0.8l0,0
                            C24,20.3,23.6,20.6,23.2,20.6z"
    />
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-file type-csv-focus'" class="icon-chart-file type-csv-focus">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g>
    <rect x="24" y="33.3" class="st2" width="5.3" height="1.3" />
    <rect x="25.3" y="29.3" class="st2" width="4" height="1.3" />
    <rect x="25.3" y="26.7" class="st2" width="5.3" height="1.3" />
    <rect x="24" y="28" class="st2" width="1.3" height="1.3" />
    <polygon class="st2" points="33.3,16.5 33.3,20 36.7,20 	" />
    <rect x="29.3" y="30.7" class="st2" width="1.3" height="2.7" />
    <rect x="18.7" y="26.7" class="st2" width="4" height="1.3" />
    <path class="st2" d="M37.3,21.3h-4.7c-0.4,0-0.7-0.3-0.7-0.7V16H19.1c-0.3,0-0.4,0.1-0.4,0.4V24h18.7v-2.7H37.3z" />
    <rect x="34.7" y="32" class="st2" width="1.3" height="2.7" />
    <rect x="33.3" y="29.3" class="st2" width="1.3" height="2.7" />
    <rect x="37.3" y="26.7" class="st2" width="1.3" height="2.7" />
    <rect x="36" y="29.3" class="st2" width="1.3" height="2.7" />
    <rect x="17.3" y="28" class="st2" width="1.3" height="5.3" />
    <rect x="32" y="26.7" class="st2" width="1.3" height="2.7" />
    <rect x="18.7" y="33.3" class="st2" width="4" height="1.3" />
    <path class="st2" d="M18.7,39.6c0,0.3,0.1,0.4,0.4,0.4H37c0.3,0,0.4-0.1,0.4-0.4v-2.3H18.7V39.6z" />
    <path
      class="st3"
      d="M40.7,24h-2v-3.3c0-0.5-0.3-0.8-0.5-1.1l-4.3-4.4c-0.3-0.3-0.8-0.5-1.2-0.5H19.1c-0.9,0-1.7,0.8-1.7,1.7V24
                            h-1.9c-1.2,0-2.1,0.9-2.1,2v9.3c0,1.2,0.9,2,2.1,2h2v2.3c0,0.9,0.8,1.7,1.7,1.7h17.9c0.9,0,1.7-0.8,1.7-1.7v-2.3h1.9
                            c1.2,0,2-0.9,2-2V26C42.7,24.9,41.7,24,40.7,24z M33.3,16.5l3.3,3.5h-3.3V16.5z M18.7,16.4c0-0.3,0.1-0.4,0.4-0.4H32v4.7
                            c0,0.4,0.3,0.7,0.7,0.7h4.7V24H18.7V16.4z M29.3,29.3v1.3h1.3v2.7h-1.3v1.3H24v-1.3h5.3v-2.7h-4v-1.3H24V28h1.3v-1.3h5.3V28h-5.3
                            v1.3H29.3z M17.3,33.3V28h1.3v-1.3h4V28h-4v5.3h4v1.3h-4v-1.3H17.3z M37.3,39.6c0,0.3-0.1,0.4-0.4,0.4H19.1c-0.3,0-0.4-0.1-0.4-0.4
                            v-2.3h18.7v2.3H37.3z M38.7,29.3h-1.3V32H36v2.7h-1.3V32h-1.3v-2.7H32v-2.7h1.3v2.7h1.3V32H36v-2.7h1.3v-2.7h1.3v2.7H38.7z"
    />
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-file type-csv'" class="icon-chart-file type-csv">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g>
    <rect x="24" y="33.3" class="st2" width="5.3" height="1.3" />
    <rect x="25.3" y="29.3" class="st2" width="4" height="1.3" />
    <rect x="25.3" y="26.7" class="st2" width="5.3" height="1.3" />
    <rect x="24" y="28" class="st2" width="1.3" height="1.3" />
    <polygon class="st2" points="33.3,16.5 33.3,20 36.7,20 	" />
    <rect x="29.3" y="30.7" class="st2" width="1.3" height="2.7" />
    <rect x="18.7" y="26.7" class="st2" width="4" height="1.3" />
    <path class="st2" d="M37.3,21.3h-4.7c-0.4,0-0.7-0.3-0.7-0.7V16H19.1c-0.3,0-0.4,0.1-0.4,0.4V24h18.7V21.3z" />
    <rect x="34.7" y="32" class="st2" width="1.3" height="2.7" />
    <rect x="33.3" y="29.3" class="st2" width="1.3" height="2.7" />
    <rect x="37.3" y="26.7" class="st2" width="1.3" height="2.7" />
    <rect x="36" y="29.3" class="st2" width="1.3" height="2.7" />
    <rect x="17.3" y="28" class="st2" width="1.3" height="5.3" />
    <rect x="32" y="26.7" class="st2" width="1.3" height="2.7" />
    <rect x="18.7" y="33.3" class="st2" width="4" height="1.3" />
    <path class="st2" d="M18.7,39.6c0,0.3,0.1,0.4,0.4,0.4h17.9c0.3,0,0.4-0.1,0.4-0.4v-2.3H18.7V39.6z" />
    <path
      class="st3"
      d="M40.7,24h-2v-3.3c0-0.5-0.3-0.8-0.5-1.1l-4.3-4.4c-0.3-0.3-0.8-0.5-1.2-0.5H19.1c-0.9,0-1.7,0.8-1.7,1.7V24
                            h-1.9c-1.2,0-2.1,0.9-2.1,2v9.3c0,1.2,0.9,2,2.1,2h2v2.3c0,0.9,0.8,1.7,1.7,1.7h17.9c0.9,0,1.7-0.8,1.7-1.7v-2.3h1.9
                            c1.2,0,2-0.9,2-2V26C42.7,24.9,41.7,24,40.7,24z M33.3,16.5l3.3,3.5h-3.3V16.5z M18.7,16.4c0-0.3,0.1-0.4,0.4-0.4H32v4.7
                            c0,0.4,0.3,0.7,0.7,0.7h4.7V24H18.7V16.4z M29.3,29.3v1.3h1.3v2.7h-1.3v1.3H24v-1.3h5.3v-2.7h-4v-1.3H24V28h1.3v-1.3h5.3V28h-5.3
                            v1.3H29.3z M17.3,33.3V28h1.3v-1.3h4V28h-4v5.3h4v1.3h-4v-1.3H17.3z M37.3,39.6c0,0.3-0.1,0.4-0.4,0.4H19.1c-0.3,0-0.4-0.1-0.4-0.4
                            v-2.3h18.7V39.6z M38.7,29.3h-1.3V32H36v2.7h-1.3V32h-1.3v-2.7H32v-2.7h1.3v2.7h1.3V32H36v-2.7h1.3v-2.7h1.3V29.3z"
    />
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-file type-file-focus'" class="icon-chart-file type-file-focus">
  <g>
    <g>
      <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
      <path
        class="st1"
        d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                                C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
      />
    </g>
    <g>
      <path
        class="st2"
        d="M17.3,37.3v2.3c0,0.9,0.8,1.7,1.7,1.7h17.9c0.9,0,1.7-0.8,1.7-1.7v-2.3V24v-3.3c0-0.5-0.3-0.8-0.5-1.1
                                l-4.3-4.4c-0.3-0.4-0.8-0.5-1.2-0.5H19.1c-0.9,0-1.7,0.8-1.7,1.7V24v13.3H17.3z M36.7,20h-3.3v-3.5L36.7,20z M18.7,24v-7.6
                                c0-0.3,0.1-0.4,0.4-0.4H32v4.7c0,0.4,0.3,0.7,0.7,0.7h4.7V24v13.3v2.3c0,0.3-0.1,0.4-0.4,0.4H19.1c-0.3,0-0.4-0.1-0.4-0.4v-2.3V24
                                z"
      />
    </g>
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-file type-file'" class="icon-chart-file type-file">
  <g>
    <g>
      <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
      <path
        class="st1"
        d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                                C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
      />
    </g>
    <g>
      <path
        class="st2"
        d="M17.3,37.3v2.3c0,0.9,0.8,1.7,1.7,1.7h17.9c0.9,0,1.7-0.8,1.7-1.7v-2.3V24v-3.3c0-0.5-0.3-0.8-0.5-1.1
                                l-4.3-4.4c-0.3-0.4-0.8-0.5-1.2-0.5H19.1c-0.9,0-1.7,0.8-1.7,1.7V24V37.3z M36.7,20h-3.3v-3.5L36.7,20z M18.7,24v-7.6
                                c0-0.3,0.1-0.4,0.4-0.4H32v4.7c0,0.4,0.3,0.7,0.7,0.7h4.7V24v13.3v2.3c0,0.3-0.1,0.4-0.4,0.4H19.1c-0.3,0-0.4-0.1-0.4-0.4v-2.3V24
                                z"
      />
    </g>
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-file type-json-focus'" class="icon-chart-file type-json-focus">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g>
    <rect x="21.3" y="33.3" class="st2" width="4" height="1.3" />
    <rect x="32" y="28" class="st2" width="1.3" height="5.3" />
    <rect x="29.3" y="33.3" class="st2" width="2.7" height="1.3" />
    <rect x="25.3" y="30.7" class="st2" width="1.3" height="2.7" />
    <rect x="29.3" y="26.7" class="st2" width="2.7" height="1.3" />
    <polygon class="st2" points="33.3,16.5 33.3,20 36.7,20 	" />
    <rect x="28" y="28" class="st2" width="1.3" height="5.3" />
    <path class="st2" d="M37.3,21.3h-4.7c-0.4,0-0.7-0.3-0.7-0.7V16H19.1c-0.3,0-0.4,0.1-0.4,0.4V24h18.7v-2.7H37.3z" />
    <path class="st2" d="M18.7,39.6c0,0.3,0.1,0.4,0.4,0.4H37c0.3,0,0.4-0.1,0.4-0.4v-2.3H18.7V39.6z" />
    <polygon
      class="st2"
      points="36,29.3 36,28 36,26.7 34.7,26.7 34.7,28 34.7,34.7 36,34.7 36,30.7 37.3,30.7 37.3,29.3 	"
    />
    <polygon class="st2" points="38.7,30.7 37.3,30.7 37.3,32 38.7,32 38.7,34.7 40,34.7 40,26.7 38.7,26.7 	" />
    <polygon class="st2" points="20,34.7 20,28 20,26.7 16,26.7 16,28 18.7,28 18.7,33.3 16,33.3 16,34.7 18.7,34.7 	" />
    <rect x="21.3" y="28" class="st2" width="1.3" height="1.3" />
    <rect x="22.7" y="29.3" class="st2" width="2.7" height="1.3" />
    <rect x="22.7" y="26.7" class="st2" width="4" height="1.3" />
    <rect x="29.3" y="28" class="st3" width="2.7" height="5.3" />
    <path
      class="st3"
      d="M40.7,24h-2v-3.3c0-0.5-0.3-0.8-0.5-1.1l-4.3-4.4c-0.3-0.3-0.8-0.5-1.2-0.5H19.1c-0.9,0-1.7,0.8-1.7,1.7V24
                            h-1.9c-1.2,0-2.1,0.9-2.1,2v9.3c0,1.2,0.9,2,2.1,2h2v2.3c0,0.9,0.8,1.7,1.7,1.7h17.9c0.9,0,1.7-0.8,1.7-1.7v-2.3h1.9
                            c1.2,0,2-0.9,2-2V26C42.7,24.9,41.7,24,40.7,24z M33.3,16.5l3.3,3.5h-3.3V16.5z M18.7,16.4c0-0.3,0.1-0.4,0.4-0.4H32v4.7
                            c0,0.4,0.3,0.7,0.7,0.7h4.7V24H18.7V16.4z M28,33.3V28h1.3v-1.3H32V28h1.3v5.3H32v1.3h-2.7v-1.3H28z M25.3,29.3v1.3h1.3v2.7h-1.3
                            v1.3h-4v-1.3h4v-2.7h-2.7v-1.3h-1.3V28h1.3v-1.3h4V28h-4v1.3H25.3z M16,34.7v-1.3h2.7V28H16v-1.3h4V28v6.7h-1.3H16z M37.3,39.6
                            c0,0.3-0.1,0.4-0.4,0.4H19.1c-0.3,0-0.4-0.1-0.4-0.4v-2.3h18.7v2.3H37.3z M40,34.7h-1.3V32h-1.3v-1.3H36v4h-1.3V28v-1.3H36V28v1.3
                            h1.3v1.3h1.3v-4H40V34.7z"
    />
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-file type-json'" class="icon-chart-file type-json">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g>
    <rect x="21.3" y="33.3" class="st2" width="4" height="1.3" />
    <rect x="32" y="28" class="st2" width="1.3" height="5.3" />
    <rect x="29.3" y="33.3" class="st2" width="2.7" height="1.3" />
    <rect x="25.3" y="30.7" class="st2" width="1.3" height="2.7" />
    <rect x="29.3" y="26.7" class="st2" width="2.7" height="1.3" />
    <polygon class="st2" points="33.3,16.5 33.3,20 36.7,20 	" />
    <rect x="28" y="28" class="st2" width="1.3" height="5.3" />
    <path class="st2" d="M37.3,21.3h-4.7c-0.4,0-0.7-0.3-0.7-0.7V16H19.1c-0.3,0-0.4,0.1-0.4,0.4V24h18.7V21.3z" />
    <path class="st2" d="M18.7,39.6c0,0.3,0.1,0.4,0.4,0.4h17.9c0.3,0,0.4-0.1,0.4-0.4v-2.3H18.7V39.6z" />
    <polygon
      class="st2"
      points="36,29.3 36,28 36,26.7 34.7,26.7 34.7,28 34.7,34.7 36,34.7 36,30.7 37.3,30.7 37.3,29.3 	"
    />
    <polygon class="st2" points="38.7,30.7 37.3,30.7 37.3,32 38.7,32 38.7,34.7 40,34.7 40,26.7 38.7,26.7 	" />
    <polygon class="st2" points="20,34.7 20,28 20,26.7 16,26.7 16,28 18.7,28 18.7,33.3 16,33.3 16,34.7 18.7,34.7 	" />
    <rect x="21.3" y="28" class="st2" width="1.3" height="1.3" />
    <rect x="22.7" y="29.3" class="st2" width="2.7" height="1.3" />
    <rect x="22.7" y="26.7" class="st2" width="4" height="1.3" />
    <rect x="29.3" y="28" class="st3" width="2.7" height="5.3" />
    <path
      class="st3"
      d="M40.7,24h-2v-3.3c0-0.5-0.3-0.8-0.5-1.1l-4.3-4.4c-0.3-0.3-0.8-0.5-1.2-0.5H19.1c-0.9,0-1.7,0.8-1.7,1.7V24
                            h-1.9c-1.2,0-2.1,0.9-2.1,2v9.3c0,1.2,0.9,2,2.1,2h2v2.3c0,0.9,0.8,1.7,1.7,1.7h17.9c0.9,0,1.7-0.8,1.7-1.7v-2.3h1.9
                            c1.2,0,2-0.9,2-2V26C42.7,24.9,41.7,24,40.7,24z M33.3,16.5l3.3,3.5h-3.3V16.5z M18.7,16.4c0-0.3,0.1-0.4,0.4-0.4H32v4.7
                            c0,0.4,0.3,0.7,0.7,0.7h4.7V24H18.7V16.4z M28,33.3V28h1.3v-1.3H32V28h1.3v5.3H32v1.3h-2.7v-1.3H28z M25.3,29.3v1.3h1.3v2.7h-1.3
                            v1.3h-4v-1.3h4v-2.7h-2.7v-1.3h-1.3V28h1.3v-1.3h4V28h-4v1.3H25.3z M16,34.7v-1.3h2.7V28H16v-1.3h4V28v6.7h-1.3H16z M37.3,39.6
                            c0,0.3-0.1,0.4-0.4,0.4H19.1c-0.3,0-0.4-0.1-0.4-0.4v-2.3h18.7V39.6z M40,34.7h-1.3V32h-1.3v-1.3H36v4h-1.3V28v-1.3H36V28v1.3h1.3
                            v1.3h1.3v-4H40V34.7z"
    />
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-file type-txt-focus'" class="icon-chart-file type-txt-focus">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g>
    <rect x="25.3" y="32" class="st2" width="1.3" height="2.7" />
    <rect x="29.3" y="32" class="st2" width="1.3" height="2.7" />
    <rect x="26.7" y="29.3" class="st2" width="2.7" height="2.7" />
    <path class="st2" d="M37.3,21.3h-4.7c-0.4,0-0.7-0.3-0.7-0.7V16H19.1c-0.3,0-0.4,0.1-0.4,0.4V24h18.7v-2.7H37.3z" />
    <polygon class="st2" points="33.3,16.5 33.3,20 36.7,20 	" />
    <rect x="25.3" y="26.7" class="st2" width="1.3" height="2.7" />
    <polygon class="st2" points="32,28 34.7,28 34.7,34.7 36,34.7 36,28 38.7,28 38.7,26.7 32,26.7 	" />
    <rect x="29.3" y="26.7" class="st2" width="1.3" height="2.7" />
    <polygon class="st2" points="20,34.7 21.3,34.7 21.3,28 24,28 24,26.7 17.3,26.7 17.3,28 20,28 	" />
    <path class="st2" d="M18.7,39.6c0,0.3,0.1,0.4,0.4,0.4H37c0.3,0,0.4-0.1,0.4-0.4v-2.3H18.7V39.6z" />
    <path
      class="st3"
      d="M40.7,24h-2v-3.3c0-0.5-0.3-0.8-0.5-1.1l-4.3-4.4c-0.3-0.4-0.8-0.5-1.2-0.5H19.1c-0.9,0-1.7,0.8-1.7,1.7V24
                            h-1.9c-1.2,0-2.1,0.9-2.1,2v9.2c0,1.2,0.9,2.1,2.1,2.1h1.9v2.3c0,0.9,0.8,1.7,1.7,1.7H37c0.9,0,1.7-0.8,1.7-1.7v-2.3h2
                            c1.2,0,2-0.9,2-2v-9.2C42.7,24.9,41.7,24,40.7,24z M33.3,16.5l3.3,3.5h-3.3V16.5z M18.7,16.4c0-0.3,0.1-0.4,0.4-0.4H32v4.7
                            c0,0.4,0.3,0.7,0.7,0.7h4.7V24H18.7V16.4z M30.7,32v2.7h-1.3V32h-2.7v2.7h-1.3V32h1.3v-2.7h-1.3v-2.7h1.3v2.7h2.7v-2.7h1.3v2.7
                            h-1.3V32H30.7z M17.3,28v-1.3H24V28h-2.7v6.7H20V28H17.3z M37.3,39.6c0,0.3-0.1,0.4-0.4,0.4H19.1c-0.3,0-0.4-0.1-0.4-0.4v-2.3h18.7
                            v2.3H37.3z M38.7,28H36v6.7h-1.3V28H32v-1.3h6.7V28z"
    />
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-file type-txt'" class="icon-chart-file type-txt">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g>
    <rect x="25.3" y="32" class="st2" width="1.3" height="2.7" />
    <rect x="29.3" y="32" class="st2" width="1.3" height="2.7" />
    <rect x="26.7" y="29.3" class="st2" width="2.7" height="2.7" />
    <path class="st2" d="M37.3,21.3h-4.7c-0.4,0-0.7-0.3-0.7-0.7V16H19.1c-0.3,0-0.4,0.1-0.4,0.4V24h18.7V21.3z" />
    <polygon class="st2" points="33.3,16.5 33.3,20 36.7,20 	" />
    <rect x="25.3" y="26.7" class="st2" width="1.3" height="2.7" />
    <polygon class="st2" points="32,28 34.7,28 34.7,34.7 36,34.7 36,28 38.7,28 38.7,26.7 32,26.7 	" />
    <rect x="29.3" y="26.7" class="st2" width="1.3" height="2.7" />
    <polygon class="st2" points="20,34.7 21.3,34.7 21.3,28 24,28 24,26.7 17.3,26.7 17.3,28 20,28 	" />
    <path class="st2" d="M18.7,39.6c0,0.3,0.1,0.4,0.4,0.4h17.9c0.3,0,0.4-0.1,0.4-0.4v-2.3H18.7V39.6z" />
    <path
      class="st3"
      d="M40.7,24h-2v-3.3c0-0.5-0.3-0.8-0.5-1.1l-4.3-4.4c-0.3-0.4-0.8-0.5-1.2-0.5H19.1c-0.9,0-1.7,0.8-1.7,1.7V24
                            h-1.9c-1.2,0-2.1,0.9-2.1,2v9.2c0,1.2,0.9,2.1,2.1,2.1h1.9v2.3c0,0.9,0.8,1.7,1.7,1.7h17.9c0.9,0,1.7-0.8,1.7-1.7v-2.3h2
                            c1.2,0,2-0.9,2-2v-9.2C42.7,24.9,41.7,24,40.7,24z M33.3,16.5l3.3,3.5h-3.3V16.5z M18.7,16.4c0-0.3,0.1-0.4,0.4-0.4H32v4.7
                            c0,0.4,0.3,0.7,0.7,0.7h4.7V24H18.7V16.4z M30.7,32v2.7h-1.3V32h-2.7v2.7h-1.3V32h1.3v-2.7h-1.3v-2.7h1.3v2.7h2.7v-2.7h1.3v2.7
                            h-1.3V32H30.7z M17.3,28v-1.3H24V28h-2.7v6.7H20V28H17.3z M37.3,39.6c0,0.3-0.1,0.4-0.4,0.4H19.1c-0.3,0-0.4-0.1-0.4-0.4v-2.3h18.7
                            V39.6z M38.7,28H36v6.7h-1.3V28H32v-1.3h6.7V28z"
    />
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-file type-xls-focus'" class="icon-chart-file type-xls-focus">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g>
    <g>
      <path
        class="st2"
        d="M40.7,24h-2v-3.3c0-0.5-0.3-0.8-0.5-1.1l-4.3-4.4c-0.3-0.4-0.8-0.5-1.2-0.5H19.1c-0.9,0-1.7,0.8-1.7,1.7V24
                                h-1.9c-1.2,0-2.1,0.9-2.1,2v9.2c0,1.2,0.9,2.1,2.1,2.1h1.9v2.3c0,0.9,0.8,1.7,1.7,1.7H37c0.9,0,1.7-0.8,1.7-1.7v-2.3h2
                                c1.2,0,2-0.9,2-2v-9.2C42.7,24.9,41.7,24,40.7,24z M36.7,20h-3.3v-3.5L36.7,20z M18.7,16.4c0-0.3,0.1-0.4,0.4-0.4H32v4.7
                                c0,0.4,0.3,0.7,0.7,0.7h4.7V24H18.7V16.4z M30.7,33.3v1.3h-5.3v-8h1.3v6.7H30.7z M18.7,34.7h-1.3V32h1.3v-2.7h-1.3v-2.7h1.3v2.7
                                h2.7v-2.7h1.3v2.7h-1.3V32h1.3v2.7h-1.3V32h-2.7V34.7z M37.3,39.6c0,0.3-0.1,0.4-0.4,0.4H19.1c-0.3,0-0.4-0.1-0.4-0.4v-2.3h18.7
                                v2.3H37.3z M37.3,28h-4v1.3H36v1.3h1.3v2.7H36v1.3h-4v-1.3h4v-2.7h-2.7v-1.3H32V28h1.3v-1.3h4V28z"
      />
    </g>
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-file type-xls'" class="icon-chart-file type-xls">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g>
    <g>
      <path
        class="st2"
        d="M40.7,24h-2v-3.3c0-0.5-0.3-0.8-0.5-1.1l-4.3-4.4c-0.3-0.4-0.8-0.5-1.2-0.5H19.1c-0.9,0-1.7,0.8-1.7,1.7V24
                                h-1.9c-1.2,0-2.1,0.9-2.1,2v9.2c0,1.2,0.9,2.1,2.1,2.1h1.9v2.3c0,0.9,0.8,1.7,1.7,1.7h17.9c0.9,0,1.7-0.8,1.7-1.7v-2.3h2
                                c1.2,0,2-0.9,2-2v-9.2C42.7,24.9,41.7,24,40.7,24z M36.7,20h-3.3v-3.5L36.7,20z M18.7,16.4c0-0.3,0.1-0.4,0.4-0.4H32v4.7
                                c0,0.4,0.3,0.7,0.7,0.7h4.7V24H18.7V16.4z M30.7,33.3v1.3h-5.3v-8h1.3v6.7H30.7z M18.7,34.7h-1.3V32h1.3v-2.7h-1.3v-2.7h1.3v2.7
                                h2.7v-2.7h1.3v2.7h-1.3V32h1.3v2.7h-1.3V32h-2.7V34.7z M37.3,39.6c0,0.3-0.1,0.4-0.4,0.4H19.1c-0.3,0-0.4-0.1-0.4-0.4v-2.3h18.7
                                V39.6z M37.3,28h-4v1.3H36v1.3h1.3v2.7H36v1.3h-4v-1.3h4v-2.7h-2.7v-1.3H32V28h1.3v-1.3h4V28z"
      />
    </g>
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-file type-xlsx-focus'" class="icon-chart-file type-xlsx-focus">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g>
    <g>
      <path
        class="st2"
        d="M40.7,24h-2v-3.3c0-0.5-0.3-0.8-0.5-1.1l-4.3-4.4c-0.3-0.4-0.8-0.5-1.2-0.5H19.1c-0.9,0-1.7,0.8-1.7,1.7V24
                                h-1.9c-1.2,0-2.1,0.9-2.1,2v9.2c0,1.2,0.9,2.1,2.1,2.1h1.9v2.3c0,0.9,0.8,1.7,1.7,1.7H37c0.9,0,1.7-0.8,1.7-1.7v-2.3h2
                                c1.2,0,2-0.9,2-2v-9.2C42.7,24.9,41.7,24,40.7,24z M36.7,20h-3.3v-3.5L36.7,20z M18.7,16.4c0-0.3,0.1-0.4,0.4-0.4H32v4.7
                                c0,0.4,0.3,0.7,0.7,0.7h4.7V24H18.7V16.4z M32,29.3v1.3h1.3v2.7H32v1.3h-4v-1.3h4v-2.7h-2.7v-1.3H28V28h1.3v-1.3h4V28h-4v1.3H32z
                                 M26.7,33.3v1.3h-4v-8H24v6.7H26.7z M17.3,32v2.7H16V32H17.3v-2.7H16v-2.7h1.3v2.7H20v-2.7h1.3v2.7H20V32h1.3v2.7H20V32H17.3z
                                 M37.3,39.6c0,0.3-0.1,0.4-0.4,0.4H19.1c-0.3,0-0.4-0.1-0.4-0.4v-2.3h18.7v2.3H37.3z M40,29.3h-1.3V32H40v2.7h-1.3V32H36v2.7h-1.3
                                V32H36v-2.7h-1.3v-2.7H36v2.7h2.7v-2.7H40V29.3z"
      />
    </g>
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-file type-xlsx'" class="icon-chart-file type-xlsx">
  <g>
    <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
    <path
      class="st1"
      d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                            C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
    />
  </g>
  <g>
    <g>
      <path
        class="st2"
        d="M40.7,24h-2v-3.3c0-0.5-0.3-0.8-0.5-1.1l-4.3-4.4c-0.3-0.4-0.8-0.5-1.2-0.5H19.1c-0.9,0-1.7,0.8-1.7,1.7V24
                                h-1.9c-1.2,0-2.1,0.9-2.1,2v9.2c0,1.2,0.9,2.1,2.1,2.1h1.9v2.3c0,0.9,0.8,1.7,1.7,1.7h17.9c0.9,0,1.7-0.8,1.7-1.7v-2.3h2
                                c1.2,0,2-0.9,2-2v-9.2C42.7,24.9,41.7,24,40.7,24z M36.7,20h-3.3v-3.5L36.7,20z M18.7,16.4c0-0.3,0.1-0.4,0.4-0.4H32v4.7
                                c0,0.4,0.3,0.7,0.7,0.7h4.7V24H18.7V16.4z M32,29.3v1.3h1.3v2.7H32v1.3h-4v-1.3h4v-2.7h-2.7v-1.3H28V28h1.3v-1.3h4V28h-4v1.3H32z
                                 M26.7,33.3v1.3h-4v-8H24v6.7H26.7z M17.3,32v2.7H16V32H17.3v-2.7H16v-2.7h1.3v2.7H20v-2.7h1.3v2.7H20V32h1.3v2.7H20V32H17.3z
                                 M37.3,39.6c0,0.3-0.1,0.4-0.4,0.4H19.1c-0.3,0-0.4-0.1-0.4-0.4v-2.3h18.7V39.6z M40,29.3h-1.3V32H40v2.7h-1.3V32H36v2.7h-1.3V32
                                H36v-2.7h-1.3v-2.7H36v2.7h2.7v-2.7H40V29.3z"
      />
    </g>
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-file type-xml-focus'" class="icon-chart-file type-xml-focus">
  <g>
    <g>
      <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
      <path
        class="st1"
        d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                                C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
      />
    </g>
    <rect x="22.7" y="26.7" class="st2" width="1.3" height="2.7" />
    <polygon
      class="st2"
      points="32,34.7 32,28 32,26.7 25.3,26.7 25.3,28 25.3,34.7 26.7,34.7 26.7,28 28,28 28,34.7 29.3,34.7
                            29.3,28 30.7,28 30.7,34.7 	"
    />
    <rect x="18.7" y="26.7" class="st2" width="1.3" height="2.7" />
    <path class="st2" d="M37.3,21.3h-4.7c-0.4,0-0.7-0.3-0.7-0.7V16H19.1c-0.3,0-0.4,0.1-0.4,0.4V24h18.7v-2.7H37.3z" />
    <polygon class="st2" points="33.3,16.5 33.3,20 36.7,20 	" />
    <rect x="18.7" y="32" class="st2" width="1.3" height="2.7" />
    <rect x="20" y="29.3" class="st2" width="2.7" height="2.7" />
    <polygon class="st2" points="34.7,26.7 33.3,26.7 33.3,34.7 38.7,34.7 38.7,33.3 34.7,33.3 	" />
    <path class="st2" d="M18.7,39.6c0,0.3,0.1,0.4,0.4,0.4H37c0.3,0,0.4-0.1,0.4-0.4v-2.3H18.7V39.6z" />
    <rect x="22.7" y="32" class="st2" width="1.3" height="2.7" />
    <path
      class="st3"
      d="M40.7,24h-2v-3.3c0-0.5-0.3-0.8-0.5-1.1l-4.3-4.4c-0.3-0.3-0.8-0.5-1.2-0.5H19.1c-0.9,0-1.7,0.8-1.7,1.7V24
                            h-1.9c-1.2,0-2.1,0.9-2.1,2v9.3c0,1.2,0.9,2,2.1,2h2v2.3c0,0.9,0.8,1.7,1.7,1.7h17.9c0.9,0,1.7-0.8,1.7-1.7v-2.3h1.9
                            c1.2,0,2-0.9,2-2V26C42.7,24.9,41.7,24,40.7,24z M33.3,16.5l3.3,3.5h-3.3V16.5z M18.7,16.4c0-0.3,0.1-0.4,0.4-0.4H32v4.7
                            c0,0.4,0.3,0.7,0.7,0.7h4.7V24H18.7V16.4z M29.3,28v6.7H28V28h-1.3v6.7h-1.3V28v-1.3H32V28v6.7h-1.3V28H29.3z M18.7,32H20v-2.7
                            h-1.3v-2.7H20v2.7h2.7v-2.7H24v2.7h-1.3V32H24v2.7h-1.3V32H20v2.7h-1.3V32z M37.3,39.6c0,0.3-0.1,0.4-0.4,0.4H19.1
                            c-0.3,0-0.4-0.1-0.4-0.4v-2.3h18.7v2.3H37.3z M38.7,34.7h-5.3v-8h1.3v6.7h4V34.7z"
    />
  </g>
</svg>
<svg *ngIf="name === 'icon-chart-file type-xml'" class="icon-chart-file type-xml">
  <g>
    <g>
      <path class="st0" d="M28,54.8c-14.8,0-26.8-12-26.8-26.8S13.2,1.2,28,1.2s26.8,12,26.8,26.8S42.8,54.8,28,54.8z" />
      <path
        class="st1"
        d="M28,2.5c14.1,0,25.5,11.4,25.5,25.5S42.1,53.5,28,53.5S2.5,42.1,2.5,28S13.9,2.5,28,2.5 M28,0
                                C12.5,0,0,12.5,0,28s12.5,28,28,28s28-12.5,28-28S43.5,0,28,0L28,0z"
      />
    </g>
    <rect x="22.7" y="26.7" class="st2" width="1.3" height="2.7" />
    <polygon
      class="st2"
      points="32,34.7 32,28 32,26.7 25.3,26.7 25.3,28 25.3,34.7 26.7,34.7 26.7,28 28,28 28,34.7 29.3,34.7
                            29.3,28 30.7,28 30.7,34.7 	"
    />
    <rect x="18.7" y="26.7" class="st2" width="1.3" height="2.7" />
    <path class="st2" d="M37.3,21.3h-4.7c-0.4,0-0.7-0.3-0.7-0.7V16H19.1c-0.3,0-0.4,0.1-0.4,0.4V24h18.7V21.3z" />
    <polygon class="st2" points="33.3,16.5 33.3,20 36.7,20 	" />
    <rect x="18.7" y="32" class="st2" width="1.3" height="2.7" />
    <rect x="20" y="29.3" class="st2" width="2.7" height="2.7" />
    <polygon class="st2" points="34.7,26.7 33.3,26.7 33.3,34.7 38.7,34.7 38.7,33.3 34.7,33.3 	" />
    <path class="st2" d="M18.7,39.6c0,0.3,0.1,0.4,0.4,0.4h17.9c0.3,0,0.4-0.1,0.4-0.4v-2.3H18.7V39.6z" />
    <rect x="22.7" y="32" class="st2" width="1.3" height="2.7" />
    <path
      class="st3"
      d="M40.7,24h-2v-3.3c0-0.5-0.3-0.8-0.5-1.1l-4.3-4.4c-0.3-0.3-0.8-0.5-1.2-0.5H19.1c-0.9,0-1.7,0.8-1.7,1.7V24
                            h-1.9c-1.2,0-2.1,0.9-2.1,2v9.3c0,1.2,0.9,2,2.1,2h2v2.3c0,0.9,0.8,1.7,1.7,1.7h17.9c0.9,0,1.7-0.8,1.7-1.7v-2.3h1.9
                            c1.2,0,2-0.9,2-2V26C42.7,24.9,41.7,24,40.7,24z M33.3,16.5l3.3,3.5h-3.3V16.5z M18.7,16.4c0-0.3,0.1-0.4,0.4-0.4H32v4.7
                            c0,0.4,0.3,0.7,0.7,0.7h4.7V24H18.7V16.4z M29.3,28v6.7H28V28h-1.3v6.7h-1.3V28v-1.3H32V28v6.7h-1.3V28H29.3z M18.7,32H20v-2.7
                            h-1.3v-2.7H20v2.7h2.7v-2.7H24v2.7h-1.3V32H24v2.7h-1.3V32H20v2.7h-1.3V32z M37.3,39.6c0,0.3-0.1,0.4-0.4,0.4H19.1
                            c-0.3,0-0.4-0.1-0.4-0.4v-2.3h18.7V39.6z M38.7,34.7h-5.3v-8h1.3v6.7h4V34.7z"
    />
  </g>
</svg>
<svg *ngIf="name === 'TXT'" [attr.class]="getCss()">
  <g>
    <rect x="10" y="15" class="st0" width="1" height="2"></rect>
    <rect x="13" y="15" class="st0" width="1" height="2"></rect>
    <rect x="11" y="13" class="st0" width="2" height="2"></rect>
    <path class="st0" d="M19,7h-3.5C15.2,7,15,6.8,15,6.5V3H5.3C5.1,3,5,3.1,5,3.3V9h14V7z"></path>
    <polygon class="st0" points="16,3.4 16,6 18.5,6 	"></polygon>
    <rect x="10" y="11" class="st0" width="1" height="2"></rect>
    <polygon class="st0" points="15,12 17,12 17,17 18,17 18,12 20,12 20,11 15,11 	"></polygon>
    <rect x="13" y="11" class="st0" width="1" height="2"></rect>
    <polygon class="st0" points="6,17 7,17 7,12 9,12 9,11 4,11 4,12 6,12 	"></polygon>
    <path class="st0" d="M5,20.7C5,20.9,5.1,21,5.3,21h13.4c0.2,0,0.3-0.1,0.3-0.3V19H5V20.7z"></path>
    <path
      class="st1"
      d="M21.5,9H20V6.5c0-0.4-0.2-0.6-0.4-0.8l-3.2-3.3C16.2,2.1,15.8,2,15.5,2H5.3C4.6,2,4,2.6,4,3.3V9H2.6
                                                C1.7,9,1,9.7,1,10.5v6.9C1,18.3,1.7,19,2.6,19H4v1.7C4,21.4,4.6,22,5.3,22h13.4c0.7,0,1.3-0.6,1.3-1.3V19h1.5
                                                c0.9,0,1.5-0.7,1.5-1.5v-6.9C23,9.7,22.3,9,21.5,9z M16,3.4L18.5,6H16V3.4z M5,3.3C5,3.1,5.1,3,5.3,3H15v3.5C15,6.8,15.2,7,15.5,7
                                                H19v2H5V3.3z M14,15v2h-1v-2h-2v2h-1v-2h1v-2h-1v-2h1v2h2v-2h1v2h-1v2H14z M4,12v-1h5v1H7v5H6v-5H4z M19,20.7
                                                c0,0.2-0.1,0.3-0.3,0.3H5.3C5.1,21,5,20.9,5,20.7V19h14V20.7z M20,12h-2v5h-1v-5h-2v-1h5V12z"
    ></path>
  </g>
</svg>

<svg *ngIf="name === 'WRANGLED'" [attr.class]="getCss()">
  <defs>
    <path id="a" d="M0 0h19v19H0z"></path>
    <path id="c" d="M0 20h20V0H0z"></path>
  </defs>
  <g fill="none" fill-rule="evenodd" transform="translate(4)">
    <g transform="translate(0 1)">
      <mask id="b" fill="#fff">
        <use xlink:href="#a"></use>
      </mask>
      <path
        fill="#4A515C"
        d="M14.5 0a4.5 4.5 0 1 0 .001 9 4.5 4.5 0 0 0 0-9zM6 18h4V9H6v9zm-5 0h4V9H1v9zm14 0h-4V9h.344a5.512 5.512 0 0 1-1.086-.999H5.502 5.5 1v-4h8.025c.031-.345.096-.678.187-1H.5a.5.5 0 0 0-.5.5v15a.5.5 0 0 0 .5.5h15a.5.5 0 0 0 .5-.5V9.787a5.502 5.502 0 0 1-1 .187V18z"
        mask="url(#b)"
      ></path>
    </g>
    <mask id="d" fill="#fff">
      <use xlink:href="#c"></use>
    </mask>
    <path fill="#FFF" d="M13 8h1V4h-1zM14 7h2V6h-2zM15 6h1V4h-1zM14 5h1V4h-1zM16 8h1V7h-1z" mask="url(#d)"></path>
  </g>
</svg>

<svg *ngIf="name === 'DRUID'" [attr.class]="getCss()">
  <g>
    <path class="st0" d="M7.5,5h8.3c0,0,5.7,0.2,5.7,6.4c0,5.2-4.3,7.6-6.5,7.6c-0.9,0-3.8,0-3.8,0"></path>
    <path class="st0" d="M6.4,8.1h7.8c0,0,3.8,0,3.8,3.5c0,3.9-3.8,4.4-5.4,4.4c-0.7,0-7.6,0-7.6,0"></path>
    <line class="st0" x1="8.8" y1="19" x2="7.4" y2="19"></line>
    <line class="st0" x1="4.2" y1="8.1" x2="2.7" y2="8.1"></line>
  </g>
</svg>

<svg *ngIf="name === 'MSSQL' && !isBlackAndWhite" [attr.class]="getCss()">
  <defs>
    <path id="a" d="M.505.651H19.51v13.521H.505z"></path>
  </defs>
  <g fill="none" fill-rule="evenodd">
    <path
      fill="#918C8A"
      d="M6.953 2.446L8.08 3.788s-1.768-.405-1.896-.56c-.126-.156.77-.782.77-.782zm3.861.496L9.2 3.957h-.403S7.31 2.42 7.26 2.262c.002.002 3.166.731 3.555.68zm-3.22-.887c.152-.13 2.69-1.276 2.69-1.276s.715 1.824.484 1.824c-.23 0-3.33-.418-3.174-.548zm3.941 2.294l-1.793-.286 1.332-.833.461 1.119zm.823.093C11.38 4.302 10.378.5 10.384.5c-.57.171-4.35 1.302-4.723 2.522l.656.29 1.762.476.717.169.946.106 1.793.286.823.093zM13.661 17.094c-1.152-.285-2.39-1.25-2.39-1.25.551-.159 2.704-1.46 2.704-1.46.161 1.405-.314 2.71-.314 2.71zM9.667 18.97l1.28-2.763s1.895 1.017 2.598 1.173c.002 0-3.238 1.615-3.878 1.59zm3.08 1.616s-2.877-.522-3.338-1.068c0 0 3.994-1.642 4.136-1.929.002 0-.318 2.384-.799 2.997zm-.879 2.53s-2.852-.244-3.688-.736c0 0 2.5-.489 4.566-1.376 0 0-.62 1.797-.878 2.111zM9.2 19.933s1.7.757 3.441.782c0 0-4.1 1.607-5.279 1.664 0 0 1.86-2.289 1.838-2.446zm-4.653 1.121l4.072-1.12-1.46 2.24c0 .001-1.665-.233-2.612-1.12zm-.36-.209l2.151-2.814s.946.913 1.97 1.486l-4.009 1.486-.113-.158zM2.7 18.552c.844 0 3.2-.678 3.2-.678-.128.47-1.92 2.842-1.92 2.842-1.408-.99-1.28-2.164-1.28-2.164zm2.816-3.309s-.049.834.514 2.164c0 0-2.867.913-3.328.913-.002-.002.306-1.853 2.814-3.077zm2.51-1.225s-.744 2.14-1.485 2.892c0 0-.333-.418-.383-1.954 0 0 1.563-.834 1.868-.938zM9.2 13.55l1.747-.728s.256 1.225-.307 2.58c0 0-.885-.526-1.44-1.852zm1.036 2.788S9.885 18 9.037 19.295c0 0-1.238-.509-2.135-1.706-.002 0 3.004-1.017 3.333-1.252zm-3.077.757s1.768-3.048 1.704-3.387c0 0 .2.833 1.5 2.033 0 0-.95.65-3.204 1.354zm4.71-4.562l1.971-.682c.18.698.128 1.854.128 1.854-.562-.234-2.099-1.172-2.099-1.172zm-.358.105l2.28 1.381c-.334.34-2.522 1.382-2.522 1.382.325-.783.242-2.763.242-2.763zm-7.333 3.227c-1.614 1.246-2.223 2.307-2.174 3.204.184 3.41 9.89 4.431 10.063 4.132 3.824-6.55 2.026-11.378 2.026-11.378l-.253.028-1.97.682-.359.105-.564.184-1.747.728-.337.158-.835.311-1.868.938-.642.287-1.34.621z"
    ></path>
    <g transform="translate(3 2.25)">
      <mask id="b" fill="#fff">
        <use xlink:href="#a"></use>
      </mask>
      <path
        fill="#BC352C"
        d="M13.812 8.144s1.945-2.293 2.33-3.336c0 0 2.588 1.746 2.97 2.554 0 .003-4.301.367-5.3.782zm-2.407-1.797s3.918-1.51 4.303-1.536c0 0-.976 1.85-2.588 3.466 0-.002-.877-1.435-1.715-1.93zM8.408 9.84s2.254-2.553 2.382-2.867c0 0 1.407.703 1.895 1.46 0 0-3.683 1.173-4.277 1.407zm-4.046.822s.94-.887 2.048-1.368l-3.074 2.735 1.026-1.367zm1.537-2.517s.434.131.64.391c0 0-.744.671-1.356 1.065 0 0 .69-1.222.716-1.456zM5.183 2.1l2.458.364-1.843 1.408C5.795 3.56 5.183 2.1 5.183 2.1zm-1.41-.392l1.126.39.41 1.381c0 .002-1.075-1.433-1.537-1.77zm7.452 1.513c-.181.08-2.69 1.512-2.69 1.512.383-1.198-.267-2.139-.267-2.139l2.957.627zM7.947 7.704s.613-1.72.589-2.11l1.613.886-2.202 1.224zm-.133 2.37s-.197-1.33-.455-1.537c0 0 1.127-.548 2.791-1.538 0 0-2.111 2.736-2.336 3.075zm-.89-.964s.484.313.434 1.096c0 0-3.228 1.486-3.713 1.825 0-.003 3.102-2.61 3.28-2.92zM8.05 5.851s-.28 1.432-.946 2.293c0 0-.625-.548-.9-.729-.004 0 1.668-1.407 1.846-1.564zM6.2 4.105l1.723-1.512s.436.495.333 2.215c0 0-1.575-.599-2.056-.703zM7.814 5.41s-1.003.991-1.612 1.564c0 0-.026-2.163-.165-2.24 0-.002 1.232.308 1.777.676zm2.976.573s-1.27-.757-1.723-.887l2.695-1.77c0-.003 0 .963-.973 2.657zm4.662-1.46s-2.765.782-4.047 1.46c0 0 .716-2.269.69-2.658 0-.002 2.765.78 3.356 1.198zM8.535 2.1l-1.793-.286-.544-.106h-.404l-.715-.169s-1.768-.405-1.897-.56L2.71.651c-.17.347-.078.706.433 1.054 3.73 2.53 3.565 7.64-1.965 11.91-.248.19-.47.376-.672.557l2.654-1.466 1.869-.938c2.098-.97 6.064-2.196 6.064-2.196s4.486-1.48 8.36-2.082c.502-.075-2.21-4.165-10.094-5.298L8.537 2.1z"
        mask="url(#b)"
      ></path>
    </g>
  </g>
</svg>

<svg *ngIf="name === 'MSSQL' && isBlackAndWhite" [attr.class]="getCss()">
  <defs>
    <path id="a" d="M.505.651H19.51v13.521H.505z"></path>
  </defs>
  <g fill="none" fill-rule="evenodd">
    <path
      fill="#BFBFBF"
      d="M5.953 2.446L7.08 3.788s-1.768-.405-1.896-.56c-.126-.156.77-.782.77-.782zm3.861.496L8.2 3.957h-.403S6.31 2.42 6.26 2.262c.002.002 3.166.731 3.555.68zm-3.22-.887c.152-.13 2.69-1.276 2.69-1.276s.715 1.824.484 1.824c-.23 0-3.33-.418-3.174-.548zm3.941 2.294l-1.793-.286 1.332-.833.461 1.119zm.823.093C10.38 4.302 9.378.5 9.384.5c-.57.171-4.35 1.302-4.723 2.522l.656.29 1.762.476.717.169.946.106 1.793.286.823.093zM12.661 17.094c-1.152-.285-2.39-1.25-2.39-1.25.551-.159 2.704-1.46 2.704-1.46.161 1.405-.314 2.71-.314 2.71zM8.667 18.97l1.28-2.763s1.895 1.017 2.598 1.173c.002 0-3.238 1.615-3.878 1.59zm3.08 1.616s-2.877-.522-3.338-1.068c0 0 3.994-1.642 4.136-1.929.002 0-.318 2.384-.799 2.997zm-.879 2.53s-2.852-.244-3.688-.736c0 0 2.5-.489 4.566-1.376 0 0-.62 1.797-.878 2.111zM8.2 19.933s1.7.757 3.441.782c0 0-4.1 1.607-5.279 1.664 0 0 1.86-2.289 1.838-2.446zm-4.653 1.121l4.072-1.12-1.46 2.24c0 .001-1.665-.233-2.612-1.12zm-.36-.209l2.151-2.814s.946.913 1.97 1.486l-4.009 1.486-.113-.158zM1.7 18.552c.844 0 3.2-.678 3.2-.678-.128.47-1.92 2.842-1.92 2.842-1.408-.99-1.28-2.164-1.28-2.164zm2.816-3.309s-.049.834.514 2.164c0 0-2.867.913-3.328.913-.002-.002.306-1.853 2.814-3.077zm2.51-1.225s-.744 2.14-1.485 2.892c0 0-.333-.418-.383-1.954 0 0 1.563-.834 1.868-.938zM8.2 13.55l1.747-.728s.256 1.225-.307 2.58c0 0-.885-.526-1.44-1.852zm1.036 2.788S8.885 18 8.037 19.295c0 0-1.238-.509-2.135-1.706-.002 0 3.004-1.017 3.333-1.252zm-3.077.757s1.768-3.048 1.704-3.387c0 0 .2.833 1.5 2.033 0 0-.95.65-3.204 1.354zm4.71-4.562l1.971-.682c.18.698.128 1.854.128 1.854-.562-.234-2.099-1.172-2.099-1.172zm-.358.105l2.28 1.381c-.334.34-2.522 1.382-2.522 1.382.325-.783.242-2.763.242-2.763zm-7.333 3.227C1.563 17.11.954 18.171 1.003 19.068c.184 3.41 9.89 4.431 10.063 4.132 3.824-6.55 2.026-11.378 2.026-11.378l-.253.028-1.97.682-.359.105-.564.184-1.747.728-.337.158-.835.311-1.868.938-.642.287-1.34.621z"
    ></path>
    <g transform="translate(2 2.25)">
      <mask id="b" fill="#fff">
        <use xlink:href="#a"></use>
      </mask>
      <path
        fill="#EBEBEB"
        d="M13.812 8.144s1.945-2.293 2.33-3.336c0 0 2.588 1.746 2.97 2.554 0 .003-4.301.367-5.3.782zm-2.407-1.797s3.918-1.51 4.303-1.536c0 0-.976 1.85-2.588 3.466 0-.002-.877-1.435-1.715-1.93zM8.408 9.84s2.254-2.553 2.382-2.867c0 0 1.407.703 1.895 1.46 0 0-3.683 1.173-4.277 1.407zm-4.046.822s.94-.887 2.048-1.368l-3.074 2.735 1.026-1.367zm1.537-2.517s.434.131.64.391c0 0-.744.671-1.356 1.065 0 0 .69-1.222.716-1.456zM5.183 2.1l2.458.364-1.843 1.408C5.795 3.56 5.183 2.1 5.183 2.1zm-1.41-.392l1.126.39.41 1.381c0 .002-1.075-1.433-1.537-1.77zm7.452 1.513c-.181.08-2.69 1.512-2.69 1.512.383-1.198-.267-2.139-.267-2.139l2.957.627zM7.947 7.704s.613-1.72.589-2.11l1.613.886-2.202 1.224zm-.133 2.37s-.197-1.33-.455-1.537c0 0 1.127-.548 2.791-1.538 0 0-2.111 2.736-2.336 3.075zm-.89-.964s.484.313.434 1.096c0 0-3.228 1.486-3.713 1.825 0-.003 3.102-2.61 3.28-2.92zM8.05 5.851s-.28 1.432-.946 2.293c0 0-.625-.548-.9-.729-.004 0 1.668-1.407 1.846-1.564zM6.2 4.105l1.723-1.512s.436.495.333 2.215c0 0-1.575-.599-2.056-.703zM7.814 5.41s-1.003.991-1.612 1.564c0 0-.026-2.163-.165-2.24 0-.002 1.232.308 1.777.676zm2.976.573s-1.27-.757-1.723-.887l2.695-1.77c0-.003 0 .963-.973 2.657zm4.662-1.46s-2.765.782-4.047 1.46c0 0 .716-2.269.69-2.658 0-.002 2.765.78 3.356 1.198zM8.535 2.1l-1.793-.286-.544-.106h-.404l-.715-.169s-1.768-.405-1.897-.56L2.71.651c-.17.347-.078.706.433 1.054 3.73 2.53 3.565 7.64-1.965 11.91-.248.19-.47.376-.672.557l2.654-1.466 1.869-.938c2.098-.97 6.064-2.196 6.064-2.196s4.486-1.48 8.36-2.082c.502-.075-2.21-4.165-10.094-5.298L8.537 2.1z"
        mask="url(#b)"
      ></path>
    </g>
  </g>
</svg>
