import { Component, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';

import * as _ from 'lodash';

import { AxisLabelType, ChartAxisLabelType, UIChartAxis } from '@selfai-platform/bi-domain';

import { BaseOptionComponent } from './base-option.component';

@Component({
  selector: 'axis-category-option',
  templateUrl: './axis-category-option.component.html',
})
export class AxisCategoryOptionComponent extends BaseOptionComponent {
  public transparencyList: Object[] = [
    { name: 'None', value: 0 },
    { name: '70%', value: 0.7 },
    { name: '50%', value: 0.5 },
    { name: '30%', value: 0.3 },
    { name: '10%', value: 0.1 },
  ];

  @Input()
  public axis: UIChartAxis;

  @Input()
  public firstFl: boolean;

  @Output()
  public changeCategoryAxis: EventEmitter<any> = new EventEmitter();

  public xAxisRotateFlag = false;

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public showAxisLabel(axisLabelType: any, show: boolean): void {
    if (_.eq(this.axis.mode, axisLabelType)) {
      this.axis.showLabel = show;
    }

    this.changeCategoryAxis.emit(this.axis);
  }

  public rotateAxisLabel(axisLabelType: AxisLabelType, rotate: number): void {
    if (!this.axis.showLabel) return;

    this.xAxisRotateFlag = false;

    if (_.eq(this.axis.mode, axisLabelType)) {
      this.axis.label = this.axis.label ? this.axis.label : {};
      this.axis.label.type = ChartAxisLabelType.CATEGORY;
      this.axis.label['rotation'] = rotate;
    }

    this.changeCategoryAxis.emit(this.axis);
  }

  public setMaxLength(): void {
    this.changeCategoryAxis.emit(this.axis);
  }

  public toggleBackground(): void {
    if (this.axis.background) {
      this.axis.background = null;
    } else {
      this.axis.background = {};
      this.axis.background.color = '#eeeeee';
      this.axis.background.transparency = this.transparencyList[3]['value'];
    }

    this.changeCategoryAxis.emit(this.axis);
  }

  public changeBackgroundColor(color: string): void {
    this.axis.background.color = color;

    this.changeCategoryAxis.emit(this.axis);
  }

  public changeTransprency(data: Object): void {
    this.axis.background.transparency = data['value'];

    this.changeCategoryAxis.emit(this.axis);
  }

  public getTransparencyIndex(): number {
    return _.findIndex(this.transparencyList, (item) => {
      return item['value'] == this.axis.background.transparency;
    });
  }
}
