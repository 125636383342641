import { WidgetQueryFilter } from '@selfai-platform/bi-api';
import { FilteringOptionType } from '../../enums';

export interface Filter extends WidgetQueryFilter {
  ui?: {
    filteringSeq?: number;
    filteringOptions?: FilteringOptions;
    importanceType: string;
    widgetId?: string;
  };
}

export function createFilter(params: Partial<Filter> = {}): Filter {
  return {
    type: '',
    field: '',
    dataSource: '',
    ui: {
      importanceType: 'general',
    },
    ...params,
  };
}

export interface FilteringOptions {
  allowSelectors: string[];
  defaultSelector: string;
  type: FilteringOptionType;
}

export function createFilteringOptions(params: Partial<FilteringOptions> = {}): FilteringOptions {
  return {
    allowSelectors: [],
    defaultSelector: '',
    type: FilteringOptionType.INCLUSION,
    ...params,
  };
}
