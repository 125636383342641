import { Component, ElementRef, EventEmitter, Injector, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import cloneDeep from 'lodash/fp/cloneDeep';

import {
  ConnectionType,
  Datasource,
  DatasourceField,
  FieldRole,
  TimeFilter,
  Dashboard,
  Widget,
  InclusionFilter,
  Filter,
  BoundFilter,
  CustomField,
} from '@selfai-platform/bi-domain';

import { CommonConstant } from '../../common/constant/common.constant';
import { StringUtil } from '../../common/util/string.util';
import { AbstractFilterPopupComponent } from '../../dashboard/filters/abstract-filter-popup.component';
import { FilterUtil } from '../util/filter.util';

import { ConfigureFiltersBoundComponent } from './bound-filter/configure-filters-bound.component';
import { ConfigureFiltersInclusionComponent } from './inclusion-filter/configure-filters-inclusion.component';
import { ConfigureFiltersTimeComponent } from './time-filter/configure-filters-time.component';

@Component({
  selector: 'selfai-bi-config-filter-update',
  templateUrl: './configure-filters-update.component.html',
})
export class ConfigureFiltersUpdateComponent extends AbstractFilterPopupComponent implements OnInit, OnDestroy {
  @ViewChild(ConfigureFiltersTimeComponent)
  private _timeComp: ConfigureFiltersTimeComponent;

  @ViewChild(ConfigureFiltersInclusionComponent)
  private _inclusionComp: ConfigureFiltersInclusionComponent;

  @ViewChild(ConfigureFiltersBoundComponent)
  private _boundComp: ConfigureFiltersBoundComponent;

  @ViewChild('ddpTxtSub')
  private _ddpTxtSub: ElementRef;

  @Output()
  public goToSelectField: EventEmitter<Filter> = new EventEmitter();

  @Output()
  public done: EventEmitter<Filter> = new EventEmitter();

  public isShowDsTooltip = false;
  public isShow = false;
  public isDirectEdit = false;
  public targetFilter: Filter;
  public targetField: DatasourceField | CustomField;
  public searchText = '';
  public widget: Widget;
  public dataSource: Datasource;

  private _isEdit = false;
  private _dashboard: Dashboard;

  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public openForAddFilter(board: Dashboard, data: { key: string; filter: Filter }, widget?: Widget) {
    this.widget = widget;
    this.isDirectEdit = false;
    this._openComponent(board, data, false);
  }

  public openForEditFilter(
    board: Dashboard,
    data: { key: string; filter: Filter },
    isDirect: boolean,
    widget?: Widget,
  ) {
    this.widget = widget;
    this.isDirectEdit = isDirect;
    this._openComponent(board, data, true);
  }

  public clickBtnBack() {
    this.goToSelectField.emit(this.targetFilter);
  }

  public close() {
    this.isShow = false;
  }

  public emitUpdateFilter() {
    let filter: Filter;
    switch (this.targetFilter.type) {
      case 'include':
        filter = this._inclusionComp.getData();
        break;

      case 'bound':
        filter = this._boundComp.getData();
        break;

      default:
        filter = this._timeComp.getData();
    }

    const widgetId = this.targetFilter.ui.widgetId;

    if (this.widget && widgetId) {
      filter.ui.widgetId = this._getWidgetId();
    }

    if (this.widget && !widgetId) {
      delete filter.ui.widgetId;
    }

    this.done.emit(filter);
  }

  public isMeasure() {
    return this.targetField && this.targetField.role === FieldRole.MEASURE;
  }

  public isTimestamp(): boolean {
    return (
      (this.targetField && this.targetField.type === 'TIMESTAMP' && this.targetField.role === FieldRole.TIMESTAMP) ||
      (this.targetField == null && this.targetFilter.field === CommonConstant.COL_NAME_CURRENT_DATETIME)
    );
  }

  public isRecommend(): boolean {
    return (
      ConnectionType.ENGINE.toString() === this._dashboard.configuration.dataSource.connType &&
      this.targetFilter.ui.filteringSeq > 0
    );
  }

  public isEssential(): boolean {
    return (
      ConnectionType.LINK.toString() === this._dashboard.configuration.dataSource.connType &&
      this.targetFilter.ui.filteringSeq > 0
    );
  }

  public getWidgetName(): string {
    return this.widget.name;
  }

  public toggleFilterScope() {
    if (this.widget) {
      if (this.targetFilter.ui.widgetId) {
        delete this.targetFilter.ui.widgetId;
      } else {
        this.targetFilter.ui.widgetId = this._getWidgetId();
      }
    }
  }

  private _getWidgetId(): string {
    return StringUtil.isEmpty(this.widget.id) ? 'NEW' : this.widget.id;
  }

  private _openComponent(board: Dashboard, data: { key: string; filter: Filter }, isEdit: boolean = true) {
    const targetFilter: Filter = cloneDeep(data.filter);
    this._isEdit = isEdit;

    if (this.widget) {
      targetFilter.ui.widgetId = this._getWidgetId();
    }

    this._dashboard = cloneDeep(board);
    this.targetFilter = targetFilter;
    this.targetFilter.ui ||
      (this.targetFilter.ui = {
        importanceType: 'general',
      });
    this.targetField = this._getTargetField(targetFilter, board.configuration.fields, board.configuration.customFields);

    this.dataSource = FilterUtil.getDataSourceForFilter(targetFilter, board);

    this.isShow = true;

    this.safelyDetectChanges();

    if ('include' === targetFilter.type) {
      this._inclusionComp.showComponent(board, <InclusionFilter>targetFilter, this.targetField);
    } else if ('bound' === targetFilter.type) {
      this._boundComp.showComponent(board, <BoundFilter>targetFilter, this.targetField);
    } else {
      this._timeComp.showComponent(board, <TimeFilter>targetFilter, this.targetField);
    }

    if (this._ddpTxtSub && this.isEllipsisActive(this._ddpTxtSub)) {
      $(this._ddpTxtSub.nativeElement).attr('title', this.dataSource.name);
    }
  }

  private isEllipsisActive(el: ElementRef): boolean {
    if (el.nativeElement.offsetWidth < el.nativeElement.scrollWidth) {
      return true;
    } else {
      return false;
    }
  }

  private _getTargetField(
    filter: Filter,
    fields: DatasourceField[],
    customFields?: CustomField[],
  ): DatasourceField | CustomField {
    let fieldList: (DatasourceField | CustomField)[] = cloneDeep(fields);

    if (customFields) {
      fieldList = fieldList.concat(customFields);
    }

    if (fieldList && 0 < fieldList.length) {
      return fieldList.find(
        (field: DatasourceField | CustomField) => field.name === filter.field && field.dataSource === filter.dataSource,
      );
    } else {
      return null;
    }
  }
}
