import { Injectable } from '@angular/core';
import { LocalStorageKey } from '../local-storage';

@Injectable({
  providedIn: 'root',
})
export class TableCellSizingService {
  localStorageName = LocalStorageKey.TABLE_COL_SIZING;

  setSizing(tableName: string, value: number[]): void {
    let tableCellValue;
    if (!this.getSizing()) {
      tableCellValue = {};
    } else {
      tableCellValue = JSON.parse(<string>this.getSizing());
    }
    tableCellValue[tableName] = value;
    localStorage.setItem(this.localStorageName, JSON.stringify(tableCellValue));
  }

  getSizing(): string | null {
    return localStorage.getItem(this.localStorageName);
  }
}
