import { Pipe, PipeTransform, Injectable } from '@angular/core';
@Pipe({
  name: 'short',
  pure: false,
})
@Injectable()
export class ShortNamePipe implements PipeTransform {
  transform(items: any, term: number): any {
    if (!term || !items) return items;
    return ShortNamePipe.filter(items, term);
  }

  static filter(items: any, term: number): string {
    const toCompare = Number(term);
    if (term === null || term === undefined) {
      return items.splice(0, 20).concat('...');
    }
    return items.slice(0, term).concat('...');
  }
}
