import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { CookieService } from 'ng2-cookies';
import { ClipboardModule } from 'ngx-clipboard';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { LocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EntityDataModule } from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BiApiModule, CUSTOM_KD_API_URL } from '@selfai-platform/bi-api';
import { BiDomainModule, DashboardEntityStoreModule, WidgetEntityStoreModule } from '@selfai-platform/bi-domain';
import { ConfigService, DestroyService, SELFAI_APP_BASE_HREF, SharedModule } from '@selfai-platform/shared';
import { ErrorDialogComponentModule } from '@selfai-platform/shell';
import { SelfaiStoreModule } from '@selfai-platform/store';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import {
  CanDeactivateGuard,
  ConnectionListGuard,
  DatasourceManagementGuard,
  MetadataManagementGuard,
  RecyclebinAdminGuard,
  StagedbEnabledGuard,
  WorkspacesCreateGuard,
  WorkspacesEditGuard,
  WorkspacesViewGuard,
} from '../common';
import { EventBroadcaster } from '../common/event/event.broadcaster';
import { FileModule } from '../common/file.module';
import { AbstractService } from '../common/service/abstract.service';
import { PopupService } from '../common/service/popup.service';
import { UnloadConfirmService } from '../common/service/unload.confirm.service';
import { DashboardModule, UpdateDashboardComponent } from '../dashboard';
import { StorageService } from '../data-storage/service/storage.service';
import { UserService } from '../user/service/user.service';
import { UserWorkspaceService } from '../workspace/service/user-workspace.service';
import { WorkspaceService } from '../workspace/service/workspace.service';
import { PreImportedTranslateLoader } from './import-translate.loader';
import { WidgetEmeddedApiConfigService } from './widget-embedded-api-config.service';

library.add(fas, far);

export function createTranslateLoader(): PreImportedTranslateLoader {
  return new PreImportedTranslateLoader();
}

@NgModule({
  imports: [
    SelfaiStoreModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    EntityDataModule.forRoot({}),
    DragDropModule,
    HttpClientModule,
    SharedModule,
    DynamicDialogModule,
    ErrorDialogComponentModule,
    BrowserAnimationsModule,
    WidgetEntityStoreModule,
    DashboardEntityStoreModule,
    RouterModule.forRoot([]),
    BiApiModule.forRoot(),
    BiDomainModule,
    NgClickOutsideDirective,
    FileModule,
    ClipboardModule,
    FontAwesomeModule,
    DashboardModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
      },
      defaultLanguage: 'ru',
    }),
  ],
  providers: [
    PopupService,
    UserService,
    AuthService,
    CookieService,
    AbstractService,
    CanDeactivateGuard,
    UnloadConfirmService,
    EventBroadcaster,
    UpdateDashboardComponent,
    UserWorkspaceService,
    CookieService,
    WorkspaceService,
    MetadataManagementGuard,
    DatasourceManagementGuard,
    StorageService,
    StagedbEnabledGuard,
    ConnectionListGuard,
    RecyclebinAdminGuard,
    WorkspacesViewGuard,
    WorkspacesEditGuard,
    WorkspacesCreateGuard,
    {
      provide: SELFAI_APP_BASE_HREF,
      useFactory: setAppBasehref,
      deps: [LocationStrategy],
    },
    {
      provide: CUSTOM_KD_API_URL,
      useFactory: (widgetEmeddedApiConfigService: WidgetEmeddedApiConfigService): Observable<string> =>
        widgetEmeddedApiConfigService.getApiUrl(),
      deps: [WidgetEmeddedApiConfigService],
    },
    DestroyService,
    ConfigService,
  ],
})
export class BiWidgetEmebbedModule {}

function setAppBasehref(locStrategy: LocationStrategy) {
  return locStrategy.getBaseHref();
}
