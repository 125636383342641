<input
  type="text"
  class="w-full"
  pInputText
  [disabled]="disabled"
  [ngModel]="value"
  (ngModelChange)="inputOnChange($event)"
  [placeholder]="placeholder || label"
/>

<button
  label="Add from json tree"
  pButton
  pRipple
  type="button"
  class="mr-3 mt-1 p-button-sm"
  (click)="showJsonTreeSchemaView()"
></button>
