import { CookieService } from 'ng2-cookies';

class APIConstant {
  public API_URL = '/api/';

  public API_INTEGRATOR_URL = '/integrator/';

  public API_FULL_URL: string = URL + '/api/';

  public TIMEOUT = 5000;

  public PAGE_SIZE = 999;

  public PAGE_SORT_MODIFIED_TIME_DESC = 'modifiedTime,desc';

  public URL = URL;
}

export class CommonConstant {
  public static API_CONSTANT: APIConstant = new APIConstant();

  public static stomp: any;

  public static websocketId = '';

  public static cookieService: CookieService = new CookieService();

  public static COL_NAME_CURRENT_DATETIME = 'current_datetime';

  public static PROP_MAP_CONFIG = 'PROP_MAP_CONFIG';

  public static MAP_ANALYSIS_LAYER_NAME = 'SpatialAnalysisLayer';
}
