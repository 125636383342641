<div class="ddp-layout-popuptype" *ngIf="isShowRelationPopup">
  <em class="ddp-bg-popup"></em>
  <div
    class="ddp-ui-popup ddp-ui-popup-join ddp-multy"
    style="text-align: initial"
  >
    <div class="ddp-ui-popup-title">
      <span class="ddp-txt-title-name">{{
        "msg.board.create.set-association" | translate
      }}</span>
      <div class="ddp-ui-pop-buttons">
        <a href="javascript:" class="ddp-btn-pop" (click)="closePopup()">{{
          "msg.comm.btn.cancl" | translate
        }}</a>
        <a
          (click)="completeRelation()"
          [class.ddp-disabled]="!isValid()"
          class="ddp-btn-pop ddp-bg-black"
          href="javascript:"
        >
          {{ "msg.comm.btn.done" | translate }}
        </a>
      </div>
    </div>

    <div class="ddp-box-pop-contents ddp-default ddp-key">
      <div class="ddp-ui-part">
        <div class="ddp-ui-pop-top">
          <span class="ddp-txt-title">{{ relation.ui.source.name }}</span>
        </div>

        <div class="ddp-ui-set">
          <div
            [ngClass]="{
              'ddp-selected': isShowSrcComboOpts,
              'ddp-result': relation.ui.sourceField
            }"
            (clickOutside)="isShowSrcComboOpts = false"
            class="ddp-wrap-drop-search"
          >
            <div (click)="openSearchSourceFields()" class="ddp-type-selectbox2">
              <span class="ddp-txt-selectbox ddp-result">
                {{
                  relation.ui.sourceField
                    ? relation.ui.sourceField.name
                    : ("msg.board.create.select-association" | translate)
                }}
              </span>
            </div>
            <div
              class="ddp-ui-drop-search"
              (keydown)="sourceComboKeyEvent($event)"
            >
              <input
                #inputSourceSearchText
                [(ngModel)]="sourceSearchText"
                [class.ddp-value]="
                  isContainsSearchText(sourceSearchText, filteredSourceList)
                "
                type="text"
                class="ddp-input-search"
                placeholder="{{ 'msg.board.ui.search' | translate }}"
              />
              <ul
                #sourceFieldCombo
                class="ddp-list-selectbox2 ddp-list-popup ddp-hover-none"
              >
                <li
                  *ngFor="let field of filteredSourceList"
                  (click)="selectSourceField(field)"
                  (mouseover)="hoverSourceItem($event)"
                  [class.ddp-selected]="
                    relation.ui.sourceField &&
                    relation.ui.sourceField.name === field.name
                  "
                >
                  <a href="javascript:">
                    <span
                      [innerHTML]="highlightText(field.name, sourceSearchText)"
                    ></span>
                    <em class="ddp-icon-check"></em>
                  </a>
                </li>
                <li *ngIf="filteredSourceList.length === 0">
                  <span class="ddp-noresult">{{
                    "msg.dp.ui.search.no.rslt" | translate
                  }}</span>
                </li>
              </ul>
            </div>
          </div>

          <ul class="ddp-list-tab-button">
            <li
              (click)="changeViewModeSource(true)"
              [class.ddp-selected]="isFromGridMode"
            >
              <a href="javascript:"><em class="ddp-icon-grid"></em></a>
              <div class="ddp-ui-tooltip-info">
                <em class="ddp-icon-view-top"></em>
                {{ "msg.comm.ui.list.grid.view" | translate }}
              </div>
            </li>
            <li
              (click)="changeViewModeSource(false)"
              [class.ddp-selected]="!isFromGridMode"
            >
              <a href="javascript:"><em class="ddp-icon-listgrid"></em></a>
              <div class="ddp-ui-tooltip-info">
                <em class="ddp-icon-view-top"></em>
                {{ "msg.comm.ui.list.list.view" | translate }}
              </div>
            </li>
          </ul>
        </div>

        <div #leftSide class="ddp-ui-grid-form">
          <div
            grid-component
            #leftGrid
            [hidden]="!isFromGridMode"
            (selectedHeaderEvent)="fromGridHeaderClickHandler($event)"
            style="width: 100%; height: 100%"
          ></div>

          <div class="ddp-wrap-scroll" [hidden]="isFromGridMode">
            <table class="ddp-table-grid-list">
              <colgroup>
                <col width="60px" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr
                  *ngFor="let field of relation.ui.source.uiFields"
                  [ngClass]="{
                    'ddp-selected': field.id === relation.ui.sourceField?.id
                  }"
                >
                  <td class="ddp-txt-center">
                    <selfai-bi-shared-field-icon
                      [type]="field.type"
                      [view]="'FIELD'"
                    ></selfai-bi-shared-field-icon>
                  </td>
                  <td (click)="fromTableClickHandler(field.id)">
                    {{ field.name }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="ddp-ui-part">
        <div class="ddp-ui-pop-top">
          <span class="ddp-txt-title">{{ relation.ui.target.name }}</span>
        </div>

        <div class="ddp-ui-set">
          <div
            [ngClass]="{
              'ddp-selected': isShowTgtComboOpts,
              'ddp-result': relation.ui.targetField
            }"
            (clickOutside)="isShowTgtComboOpts = false"
            class="ddp-wrap-drop-search"
          >
            <div (click)="openSearchTargetFields()" class="ddp-type-selectbox2">
              <span class="ddp-txt-selectbox ddp-result">
                {{
                  relation.ui.targetField
                    ? relation.ui.targetField.name
                    : ("msg.board.create.select-association" | translate)
                }}
              </span>
            </div>
            <div
              class="ddp-ui-drop-search"
              (keydown)="targetComboKeyEvent($event)"
            >
              <input
                #inputTargetSearchText
                [(ngModel)]="targetSearchText"
                [class.ddp-value]="
                  isContainsSearchText(targetSearchText, filteredTargetList)
                "
                type="text"
                class="ddp-input-search"
                placeholder="{{ 'msg.board.ui.search' | translate }}"
              />
              <ul
                #targetFieldCombo
                class="ddp-list-selectbox2 ddp-list-popup ddp-hover-none"
              >
                <li
                  *ngFor="let field of filteredTargetList"
                  (click)="selectTargetField(field)"
                  (mouseover)="hoverTargetItem($event)"
                  [class.ddp-selected]="
                    relation.ui.targetField &&
                    relation.ui.targetField.name === field.name
                  "
                >
                  <a href="javascript:">
                    <span
                      [innerHTML]="highlightText(field.name, targetSearchText)"
                    ></span>
                    <em class="ddp-icon-check"></em>
                  </a>
                </li>
                <li *ngIf="filteredTargetList.length === 0">
                  <span class="ddp-noresult">{{
                    "msg.dp.ui.search.no.rslt" | translate
                  }}</span>
                </li>
              </ul>
            </div>
          </div>

          <ul class="ddp-list-tab-button">
            <li
              (click)="changeViewModeTarget(true)"
              [class.ddp-selected]="isToGridMode"
            >
              <a href="javascript:"><em class="ddp-icon-grid"></em></a>
              <div class="ddp-ui-tooltip-info">
                <em class="ddp-icon-view-top"></em>
                {{ "msg.comm.ui.list.grid.view" | translate }}
              </div>
            </li>
            <li
              (click)="changeViewModeTarget(false)"
              [class.ddp-selected]="!isToGridMode"
            >
              <a href="javascript:"><em class="ddp-icon-listgrid"></em></a>
              <div class="ddp-ui-tooltip-info">
                <em class="ddp-icon-view-top"></em>
                {{ "msg.comm.ui.list.list.view" | translate }}
              </div>
            </li>
          </ul>
        </div>

        <div #rightSide class="ddp-ui-grid-form">
          <div
            grid-component
            #rightGrid
            [hidden]="!isToGridMode"
            (selectedHeaderEvent)="toGridHeaderClickHandler($event)"
            style="width: 100%; height: 100%"
          ></div>

          <div class="ddp-wrap-scroll" [hidden]="isToGridMode">
            <table class="ddp-table-grid-list">
              <colgroup>
                <col width="60px" />
                <col width="*" />
              </colgroup>
              <tbody>
                <tr
                  *ngFor="let field of relation.ui.target.uiFields"
                  [ngClass]="{
                    'ddp-selected': field.id === relation.ui.targetField?.id
                  }"
                >
                  <td class="ddp-txt-center">
                    <selfai-bi-shared-field-icon
                      [type]="field.type"
                      [view]="'FIELD'"
                    ></selfai-bi-shared-field-icon>
                  </td>
                  <td (click)="toTableClickHandler(field.id)">
                    {{ field.name }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
