import { LocalStorageKey } from './local-storage-key.enum';

export function getDataFromLocalStorage<T>(key: LocalStorageKey | `${LocalStorageKey}_${string}`, initialData: T): T {
  try {
    const data = window.localStorage.getItem(key);
    if (data) {
      return JSON.parse(data) as T;
    }
  } catch (e) {
    console.error(e);
  }

  return initialData;
}

export function saveDataToLocalStorage<T>(key: LocalStorageKey | `${LocalStorageKey}_${string}`, data: T): void {
  window?.localStorage.setItem(key, JSON.stringify(data));
}
