import * as _ from 'lodash';

import { MetadataColumn } from '@selfai-platform/bi-domain';

import { AbstractHistoryEntity } from '../common/abstract-history-entity';

import { MetadataSource } from './metadata-source';

export class Metadata extends AbstractHistoryEntity {
  public id: string;
  public description: string;
  public name: string;
  public sourceType: SourceType;
  public source: MetadataSource;
  public favorite: boolean;
  public catalogs: any;
  public tags: any;
  public popularity: number;
  public columns: MetadataColumn[];

  public static isSourceTypeIsEngine(sourceType: SourceType) {
    return _.negate(_.isNil)(sourceType) && sourceType === SourceType.ENGINE;
  }

  public static isSourceTypeIsStaging(sourceType: SourceType) {
    return _.negate(_.isNil)(sourceType) && sourceType === SourceType.STAGING;
  }

  public static isSourceTypeIsJdbc(sourceType: SourceType) {
    return _.negate(_.isNil)(sourceType) && sourceType === SourceType.JDBC;
  }

  public static isDisableMetadataNameCharacter(name: string) {
    return /^[!@#$%^*+=()~`\{\}\[\]\-\_\;\:\'\"\,\.\/\?\<\>\|\&\\]+$/gi.test(name);
  }

  public static isEmptyTags(metadata: Metadata): boolean {
    return _.isNil(metadata.tags) || metadata.tags.length === 0;
  }
}

export enum SourceType {
  ENGINE = 'ENGINE',
  STAGING = 'STAGEDB',
  STAGEDB = 'STAGEDB',
  JDBC = 'JDBC',
  ETC = 'ETC',
}
