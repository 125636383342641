import { Component, ElementRef, EventEmitter, Injector, OnDestroy, OnInit, Output } from '@angular/core';

import { ConnectionType, Datasource, DatasourceStatus as Status } from '@selfai-platform/bi-domain';

import { AbstractPopupComponent } from '../../../common/component/abstract-popup.component';
import { StringUtil } from '../../../common/util/string.util';
import { PageResult } from '../../../domain/common/page';
import { WorkspaceService } from '../../../workspace/service/workspace.service';

@Component({
  selector: 'create-board-pop-ds-select',
  templateUrl: './create-board-pop-ds-select.component.html',
})
export class CreateBoardPopDsSelectComponent extends AbstractPopupComponent implements OnInit, OnDestroy {
  private _workspaceId: string;

  private _prevDataSourceIds: string[] = [];
  private _selectedDataSources: Datasource[] = [];

  public selectedContentSort: Order = new Order();

  public summaryTargetDsId = '';
  public dataSources: Datasource[] = [];

  public searchText = '';
  public searchType = '';
  public searchPublished = false;

  public typeFilter = [];

  public isShow = false;

  @Output('done')
  public doneEvent: EventEmitter<{ add: Datasource[]; remove: string[] }> = new EventEmitter();

  constructor(
    private workspaceService: WorkspaceService,

    protected elementRef: ElementRef,
    protected injector: Injector,
  ) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();

    this.typeFilter = [
      {
        label: this.translateService.instant('msg.comm.ui.list.all'),
        connType: 'all',
      },
      {
        label: this.translateService.instant('msg.comm.ui.list.ds.type.engine'),
        connType: ConnectionType.ENGINE.toString(),
      },
      {
        label: this.translateService.instant('msg.comm.ui.list.ds.type.link'),
        connType: ConnectionType.LINK.toString(),
      },
    ];

    this.selectedContentSort = new Order();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }

  public open(workspaceId: string, dataSourceIds: string[]) {
    this._workspaceId = workspaceId;

    this.summaryTargetDsId = '';
    this._prevDataSourceIds = dataSourceIds;
    this.selectedContentSort.key = 'modifiedTime';
    this.selectedContentSort.sort = 'desc';
    this.searchPublished = false;
    this.page.page = 0;
    this.page.size = 20;
    this.searchText = '';
    this.searchType = '';
    this._selectedDataSources = [];

    this.isShow = true;

    this._getDataSources();
  }

  public close() {
    super.close();
    this.isShow = false;
  }

  public done() {
    const addDataSources: Datasource[] = this._selectedDataSources.filter((item) => {
      return -1 === this._prevDataSourceIds.indexOf(item.id);
    });
    const removeDataSources: string[] = this._prevDataSourceIds.filter((id) => {
      return -1 === this._selectedDataSources.findIndex((item) => item.id === id);
    });

    this.doneEvent.emit({ add: addDataSources, remove: removeDataSources });
    this.close();
  }

  public searchEvent(inputText: string) {
    this.searchText = inputText;

    this.page.page = 0;

    this._getDataSources();
  }

  public togglePublished(event: MouseEvent) {
    event.stopPropagation();
    event.preventDefault();

    this.searchPublished = !this.searchPublished;

    this.page.page = 0;

    this._getDataSources();
  }

  public onChangeType(event) {
    this.searchType = event.connType;

    this.page.page = 0;

    this._getDataSources();
  }

  public changeOrder(column: string) {
    this.selectedContentSort.sort = this.selectedContentSort.key !== column ? 'default' : this.selectedContentSort.sort;

    this.selectedContentSort.key = column;

    if (this.selectedContentSort.key === column) {
      switch (this.selectedContentSort.sort) {
        case 'asc':
          this.selectedContentSort.sort = 'desc';
          break;
        case 'desc':
          this.selectedContentSort.sort = 'asc';
          break;
        case 'default':
          this.selectedContentSort.sort = 'desc';
          break;
      }
    }

    this.page.page = 0;

    this._getDataSources();
  }

  public selectDatasource(datasource: Datasource) {
    this.summaryTargetDsId = datasource.id;

    if (this.isSelectedDatasource(datasource)) {
      this._selectedDataSources = this._selectedDataSources.filter((item) => item.id !== datasource.id);
    } else {
      this._selectedDataSources.push(datasource);
    }
  }

  public isSelectedDatasource(datasource: Datasource) {
    return -1 < this._selectedDataSources.findIndex((item) => item.id === datasource.id);
  }

  public onCloseSummary() {
    this.summaryTargetDsId = '';
  }

  public getMoreList() {
    if (this.page.page < this.pageResult.totalPages) {
      this._getDataSources();
    }
  }

  private _getDataSources() {
    const workspaceId: string = this._workspaceId;

    if (StringUtil.isEmpty(workspaceId)) return;

    this.pageLoaderService.show();

    const params = {
      size: this.page.size,
      page: this.page.page,
      status: Status.ENABLED,
    };

    if (this.selectedContentSort.key !== 'seq' && this.selectedContentSort.sort !== 'default') {
      params['sort'] = this.selectedContentSort.key + ',' + this.selectedContentSort.sort;
    } else {
      params['sort'] = 'modifiedTime,desc';
    }

    if (this.searchType !== 'all') {
      params['connType'] = this.searchType;
    }

    if (this.searchText !== '') {
      params['nameContains'] = this.searchText;
    }

    this.searchPublished && (params['onlyPublic'] = this.searchPublished);

    if (this.page.page === 0) {
      this.pageResult = new PageResult();
      this.dataSources = [];
    }

    this.workspaceService
      .getDataSources(workspaceId, params)
      .then((datasources) => {
        this.pageResult = datasources['page'];

        if (datasources['_embedded']) {
          this.dataSources = this.dataSources.concat(datasources['_embedded']['datasources']);
          this.page.page += 1;
        } else {
          this.dataSources = [];
        }

        this.dataSources.forEach((item: Datasource, idx: number) => {
          item.num = this.pageResult.totalElements - idx;
          if (-1 < this._prevDataSourceIds.indexOf(item.id)) {
            this._selectedDataSources.push(item);
          }
        });

        if ('' === this.summaryTargetDsId && 0 < this.dataSources.length) {
          this.summaryTargetDsId = this.dataSources[0].id;
        }

        this.pageLoaderService.hide();

        this.changeDetect.detectChanges();
      })
      .catch((error) => {
        this.alertPrimeService.error(this.translateService.instant('msg.alert.ds.retrieve.fail'));

        this.pageLoaderService.hide();
      });
  }
}

class Order {
  key = 'seq';
  sort = 'default';
}
