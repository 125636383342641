import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MonacoEditorModule, provideMonacoEditor } from 'ngx-monaco-editor-v2';

import { DialogModule } from 'primeng/dialog';
import { CodeEditorComponent } from './components';
import { registerSparkSqlLanguage } from './languages';

@NgModule({
  imports: [CommonModule, CommonModule, FormsModule, DialogModule, MonacoEditorModule],
  declarations: [CodeEditorComponent],
  providers: [
    provideMonacoEditor({
      onMonacoLoad: () => {
        registerSparkSqlLanguage();
      },
    }),
  ],
  exports: [CodeEditorComponent],
})
export class CodeEditorComponentModule {}
