import { cloneDeep, uniq } from "lodash";
import { Filter } from "../../workbook";
import { Dashboard, BoardDataSource, BoardDataSourceRelation } from "../models";

//TODO Refactor this function
export function getAllFiltersDsRelations(dashboard: Dashboard, engineName: string, paramFilters?: Filter[]): Filter[] {
    const totalFilters: Filter[] = cloneDeep(paramFilters || dashboard.configuration.filters);

    let srcDsFilters: Filter[] = totalFilters.filter((filter) => filter.dataSource === engineName);

    const boardDs: BoardDataSource = dashboard.configuration.dataSource;
    let relDsFilters: Filter[] = [];
    if ('multi' === boardDs.type && boardDs.associations) {
      const sourceDatasource: BoardDataSource = boardDs.dataSources.find(
        (item) =>
          item.engineName === engineName || item.name === engineName || (item.connType == 'LINK' && item.engineName?.startsWith(engineName + '_')),
      ) as BoardDataSource;

      const filteredRelDsAssociations = boardDs.associations.filter(
        (rel: BoardDataSourceRelation) =>
          sourceDatasource.engineName === rel.source || sourceDatasource.engineName === rel.target,
      );

      const reducedDsFilters = filteredRelDsAssociations.reduce((acc: Filter[], rel: BoardDataSourceRelation) => {
        let srcField: string | undefined = undefined;
        let relDsEngineName: string | undefined = undefined;
        let relField: string | undefined = undefined;
        let relDsType: string | undefined = undefined;

        if (rel.source === sourceDatasource.engineName) {
          relDsEngineName = boardDs.dataSources.find((item) => item.engineName === rel.target)?.engineName;
          relDsType = boardDs.dataSources.find((item) => item.engineName === rel.target)?.connType;
          srcField = Object.keys(rel.columnPair)[0];
          relField = rel.columnPair[srcField];
        } else {
          relDsEngineName = boardDs.dataSources.find((item) => item.engineName === rel.source)?.engineName;
          relDsType = boardDs.dataSources.find((item) => item.engineName === rel.target)?.connType;
          relField = Object.keys(rel.columnPair)[0];
          srcField = rel.columnPair[relField];
        }
        const resultAcc = acc.concat(
          totalFilters
            .filter(
              (item: Filter) =>
                item.dataSource === relDsEngineName ||
                (relDsType === 'LINK' && relDsEngineName?.startsWith(item.dataSource + '_') && item.field === relField),
            )
            .map((item: Filter) => {
              item.dataSource = engineName;
              item.field = srcField;

              return item;
            }),
        );

        return resultAcc;
      }, []);
      relDsFilters = reducedDsFilters;
    }

    if (relDsFilters.length > 0) {
      srcDsFilters.forEach((item1) => {
        const idx: number = relDsFilters.findIndex((item2) => item1.field === item2.field);
        if (-1 < idx && 'include' === item1.type) {
          const selection = relDsFilters.splice(idx, 1)[0];
          item1['valueList'] = item1['valueList']
            ? uniq(item1['valueList'].concat(selection['valueList']))
            : selection['valueList'];
        }
      });
      srcDsFilters = srcDsFilters.concat(relDsFilters);
    }

    return srcDsFilters;
  }
