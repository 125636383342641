import { Injectable } from '@angular/core';
import { UserFunctionsTagsStore } from '../store/user-functions-tags.store';

@Injectable({
  providedIn: 'root'
})
export class UserFunctionsTagsDomainService {
  constructor(private readonly tagsStore: UserFunctionsTagsStore) {}

  public deleteTag(id: string): void {
    this.tagsStore.delete(id);
  }
}
