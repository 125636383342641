<form [formGroup]="form" (ngSubmit)="submit()">
  <div class="p-float-label mt-4 mb-3">
    <input pAutoFocus [autofocus]="true" class="w-full" formControlName="name" pInputText type="text" />
    <label for="float-input">Route name</label>
  </div>

  <p-fileUpload
    #fileUpload
    mode="advanced"
    name="importFile"
    accept="application/json"
    [fileLimit]="1"
    chooseLabel="Import from a file"
    [auto]="true"
    [customUpload]="true"
    (uploadHandler)="onUpload($event)"
    (onError)="onError()"
  >
    <ng-template let-file pTemplate="file">
      <div class="p-fileupload-row">
        <div class="p-fileupload-filename">{{ file.name }}</div>
        <div>{{ fileUpload.formatSize(file.size) }}</div>
      </div>
    </ng-template>
  </p-fileUpload>

  <div class="mt-5">
    <button
      type="button"
      class="mr-3 p-button-secondary p-button-outlined"
      (click)="closeDialog()"
      (keydown.enter)="closeDialog()"
      pRipple
      pButton
      label="Close"
    ></button>

    <button type="submit" class="mr-3 p-button-success" pRipple pButton label="Create"></button>
  </div>
</form>
