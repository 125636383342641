import { ResultEntitiesReportTable } from '@selfai-platform/pipeline-common';
import { WorkflowState } from '../wokflow-list';

export function extractDataSampleFromExecutionReport(
  nodeId: string,
  executionReport: WorkflowState['executionReport'],
): ResultEntitiesReportTable | null {
  const resultId: string | undefined = executionReport.nodes[nodeId]?.results[0];

  if (!resultId) {
    return null;
  }

  const tables = executionReport.resultEntities[resultId].report.tables;

  // TODO: it's a temporary solution, need to find a better way to get the table
  return tables.find((table) => table.name === 'Data Sample') ?? null;
}

export function extractColumnsDataFromExecutionReport(
  nodeId: string,
  executionReport: WorkflowState['executionReport'],
): { columnIndex: number; columnName: string; columnType: string }[] | null {
  const resultId: string | undefined = executionReport.nodes[nodeId]?.results[0];

  if (!resultId) {
    return null;
  }

  const tables = executionReport.resultEntities[resultId].report.tables;
  // TODO: it's a temporary solution, need to find a better way to get the table
  const table = tables.find((table) => table.name === 'Column Names and Types');

  if (table) {
    return table.values.map((row) => ({
      columnIndex: Number(row[table.columnNames.indexOf('Column index')]),
      columnName: row[table.columnNames.indexOf('Column name')],
      columnType: row[table.columnNames.indexOf('Column type')],
    }));
  }

  return null;
}
