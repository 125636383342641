import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  AggregationType,
  AggregatorRouteApiModel,
  AggregatorRouteFormGroup,
  AggrtMode,
  ConstantFieldValue,
  FieldType,
  FilterCondition,
  FormBuilderFieldsValueNode,
  FormFieldsValueNode,
  FormFieldsValueNodePrimitive,
  FormGroupNode,
  JsonPathFieldValue,
  PeriodUnit,
  StepApiModel,
  StepType,
  StepsFormArray,
  TypeFieldValues,
  VariableMode,
} from '@selfai-platform/pipeline-common';
import { JSON_PATH_INIT_FORM_VALUE } from '../const';
import { normalizeTypeField, typeFieldIsNotEmpty } from '../utils';
import { mapApiToField } from '../utils/map-api-to-field';

@Injectable({
  providedIn: 'root',
})
export class AggregatorFormBuilderService {
  constructor(private readonly fb: FormBuilder) {}

  buildAggregatorItemForm(route: AggregatorRouteApiModel): FormGroup<AggregatorRouteFormGroup> {
    const { uuid, input, name, isActive, steps } = route;

    return this.fb.group({
      uuid,
      input,
      name,
      isActive,
      steps: this.buildStepsForm(steps),
    }) as FormGroup<AggregatorRouteFormGroup>;
  }

  buildStepsForm(nodes: StepApiModel[]): StepsFormArray {
    return this.fb.array(
      nodes.map((node) => {
        return this.fb.group(this.mapNodeToFormField(node)) as FormGroup<FormGroupNode>;
      }),
    );
  }

  addStep(stepType: StepType, targetFormArray: StepsFormArray): void {
    targetFormArray.push(
      this.fb.group(this.mapNodeToFormField({ type: stepType } as StepApiModel)) as FormGroup<FormGroupNode>,
    );
  }

  removeStep(index: number, targetFormArray: StepsFormArray): void {
    targetFormArray.removeAt(index);
  }

  private mapNodeToFormField(node: StepApiModel): FormBuilderFieldsValueNode {
    const fieldsWithOptional: FormFieldsValueNode = { ...this.getInitialFormData(node.type), ...mapApiToField(node) };
    if (node.type === StepType.FILTER) {
      const { steps } = node;
      const { filterAttrName, filterValue, ...restFields } = fieldsWithOptional;

      const childrenForms = this.buildStepsForm(steps || []);

      return {
        ...(restFields as FormFieldsValueNodePrimitive),
        filterAttrName: this.fb.group(normalizeTypeField(filterAttrName as JsonPathFieldValue, FieldType.JSONPATH)),
        filterValue: this.fb.group(normalizeTypeField(filterValue as TypeFieldValues, FieldType.JSONPATH)),
        steps: childrenForms,
      };
    }

    if (node.type === StepType.VARIABLE) {
      const { variablePartOfIndex, variableValue } = fieldsWithOptional;

      return {
        ...(fieldsWithOptional as FormFieldsValueNodePrimitive),
        variableValue: this.fb.group(normalizeTypeField(variableValue as TypeFieldValues, FieldType.JSONPATH)),
        variablePartOfIndex: this.fb.array(
          ((variablePartOfIndex as TypeFieldValues[]) || [])
            .filter(typeFieldIsNotEmpty)
            .map((valueObj: TypeFieldValues) => this.fb.group(normalizeTypeField(valueObj, FieldType.JSONPATH))),
        ),
      };
    }

    if (
      [
        StepType.AGGREGATOR,
        StepType.AGGREGATOR_IN_TIME,
        StepType.MAX_VALUE_AGGREGATION_IN_TIME,
        StepType.MAX_VALUE_AGGREGATION,
        StepType.UNIQ_VALUES_AGGREGATION,
        StepType.UNIQ_VALUES_AGGREGATION_IN_TIME,
      ].includes(node.type)
    ) {
      const { aggrtPartOfIndex, aggrtUniqueRecordId, aggrtSource, aggrtDateField } = fieldsWithOptional;

      return {
        ...(fieldsWithOptional as FormFieldsValueNodePrimitive),
        aggrtUniqueRecordId: this.fb.group(
          normalizeTypeField(aggrtUniqueRecordId as JsonPathFieldValue, FieldType.JSONPATH),
        ),
        aggrtSource: this.fb.group(normalizeTypeField(aggrtSource as TypeFieldValues, FieldType.JSONPATH)),
        aggrtDateField: typeFieldIsNotEmpty(aggrtDateField)
          ? this.fb.group(
              normalizeTypeField(aggrtDateField as JsonPathFieldValue | ConstantFieldValue, FieldType.JSONPATH),
            )
          : undefined,
        aggrtPartOfIndex: this.fb.array(
          ((aggrtPartOfIndex as TypeFieldValues[]) || [])
            .filter(typeFieldIsNotEmpty)
            .map((valueObj: TypeFieldValues) => this.fb.group(normalizeTypeField(valueObj, FieldType.JSONPATH))),
        ),
      };
    }

    if (node.type === StepType.PARALLEL) {
      const { partitionKey } = fieldsWithOptional;

      return {
        ...(fieldsWithOptional as FormFieldsValueNodePrimitive),
        partitionKey: this.fb.array(
          ((partitionKey as JsonPathFieldValue[]) || [])
            .filter(typeFieldIsNotEmpty)
            .map((valueObj: JsonPathFieldValue) => this.fb.group(normalizeTypeField(valueObj, FieldType.JSONPATH))),
        ),
      };
    }

    return fieldsWithOptional as FormFieldsValueNodePrimitive;
  }

  private getInitialFormData(stepType: StepType): FormFieldsValueNode {
    if (stepType === StepType.FILTER) {
      return {
        isActive: true,
        type: StepType.FILTER,
        filterAttrName: JSON_PATH_INIT_FORM_VALUE,
        filterCondition: FilterCondition.BEGINS_FROM,
        filterValue: { type: FieldType.CONSTANT, value: '' },
        filterStartPosition: 1,
        filterIgnoreCase: false,
        steps: [],
      };
    }

    if (stepType === StepType.VARIABLE) {
      return {
        isActive: true,
        type: stepType,
        variableName: '',
        variableMode: VariableMode.CALC,
        variableMapName: '',
        variablePartOfIndex: [],
        variableValue: JSON_PATH_INIT_FORM_VALUE,
      };
    }

    if (
      [
        StepType.AGGREGATOR,
        StepType.AGGREGATOR_IN_TIME,
        StepType.MAX_VALUE_AGGREGATION_IN_TIME,
        StepType.MAX_VALUE_AGGREGATION,
        StepType.UNIQ_VALUES_AGGREGATION,
        StepType.UNIQ_VALUES_AGGREGATION_IN_TIME,
      ].includes(stepType)
    ) {
      return {
        isActive: true,
        type: stepType as StepType.AGGREGATOR | StepType.MAX_VALUE_AGGREGATION | StepType.UNIQ_VALUES_AGGREGATION,
        aggrtMode: AggrtMode.READ,
        aggrtType: AggregationType.SUM,
        aggrtName: '',
        aggrtOutputName: '',
        aggrtPeriodUnit: PeriodUnit.DAYS,
        aggrtPeriodInUnits: 1,
        aggrtDateField: JSON_PATH_INIT_FORM_VALUE,
        aggrtUniqueRecordId: JSON_PATH_INIT_FORM_VALUE,
        aggrtPartOfIndex: [JSON_PATH_INIT_FORM_VALUE],
        aggrtSource: JSON_PATH_INIT_FORM_VALUE,
      };
    }

    if (stepType === StepType.PARALLEL) {
      return {
        isActive: true,
        type: StepType.PARALLEL,
        partitionKey: [JSON_PATH_INIT_FORM_VALUE],
        partitions: 10,
        to: '',
      };
    }

    if (stepType === StepType.TO || stepType === StepType.OTHER_PROCESS) {
      return {
        isActive: true,
        type: stepType,
        to: '',
      };
    }

    if (stepType === StepType.OUTPUT) {
      return {
        isActive: true,
        type: StepType.OUTPUT,
        connectorType: 'KAFKA',
        to: '',
      };
    }

    return {};
  }
}
