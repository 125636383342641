import moment from 'moment';

import {
  TimeRelativeFilter,
  Filter,
  TimeRangeFilter,
  TIME_RANGE_FILTER_EARLIEST_DATETIME,
  TIME_RANGE_FILTER_LATEST_DATETIME,
} from '@selfai-platform/bi-domain';

import { TimezoneService } from '../../data-storage/service/timezone.service';

import { getDateTimeFormat } from './getDateTimeFormat';
import { isTimeRangeFilter } from './isTimeRangeFilter';
import { isTimeRelativeFilter } from './isTimeRelativeFilter';

export function convertToServerSpecForDashboard(filter: Filter): Filter {
  if (isTimeRangeFilter(filter)) {
    const timeRangeFilter = <TimeRangeFilter>filter;
    if (timeRangeFilter.intervals && 0 < timeRangeFilter.intervals.length) {
      timeRangeFilter.intervals.forEach((item: string, idx: number) => {
        const arrInterval = item.split('/');

        if (
          TIME_RANGE_FILTER_EARLIEST_DATETIME !== arrInterval[0] &&
          TIME_RANGE_FILTER_LATEST_DATETIME !== arrInterval[0]
        ) {
          arrInterval[0] = getDateTimeFormat(arrInterval[0], timeRangeFilter.timeUnit, true);
        }
        if (
          TIME_RANGE_FILTER_EARLIEST_DATETIME !== arrInterval[1] &&
          TIME_RANGE_FILTER_LATEST_DATETIME !== arrInterval[1]
        ) {
          arrInterval[1] = getDateTimeFormat(arrInterval[1], timeRangeFilter.timeUnit, false);
        }

        timeRangeFilter.intervals[idx] = arrInterval[0] + '/' + arrInterval[1];
      });
    }
  } else if (isTimeRelativeFilter(filter)) {
    const timeRelativeFilter: TimeRelativeFilter = <TimeRelativeFilter>filter;
    if (
      timeRelativeFilter.clzField &&
      timeRelativeFilter.clzField.format &&
      TimezoneService.DISABLE_TIMEZONE_KEY === timeRelativeFilter.clzField.format.timeZone
    ) {
      delete timeRelativeFilter.timeZone;
    } else {
      timeRelativeFilter.timeZone || (timeRelativeFilter.timeZone = moment.tz.guess());
    }
  }

  let keyMap: string[];
  switch (filter.type) {
    case 'interval':
      keyMap = [
        'selector',
        'startDate',
        'endDate',
        'intervals',
        'timeZone',
        'locale',
        'format',
        'rrule',
        'relValue',
        'timeUnitUI',
        'timeUnit',
        'byTimeUnit',
        'minTime',
        'maxTime',
        'valueList',
        'candidateValues',
        'discontinuous',
        'granularity',
      ];
      break;
    case 'include':
      keyMap = ['selector', 'valueList', 'candidateValues', 'definedValues', 'sort'];
      break;
    case 'timestamp':
      keyMap = ['selectedTimestamps', 'timeFormat'];
      break;
    case 'bound':
      keyMap = ['min', 'max'];
      break;
    case 'time_all':
      keyMap = [];
      break;
    case 'time_relative':
      keyMap = ['relTimeUnit', 'tense', 'value', 'timeUnit', 'byTimeUnit', 'discontinuous', 'timeZone'];
      break;
    case 'time_range':
      keyMap = ['intervals', 'timeUnit', 'byTimeUnit', 'discontinuous'];
      break;
    case 'time_list':
      keyMap = ['valueList', 'candidateValues', 'timeUnit', 'byTimeUnit', 'discontinuous'];
      break;
    case 'wildcard':
      keyMap = ['contains', 'value'];
      break;
    case 'measure_inequality':
      keyMap = ['aggregation', 'inequality', 'value'];
      break;
    case 'measure_position':
      keyMap = ['aggregation', 'position', 'value'];
      break;
  }
  keyMap = keyMap.concat(['type', 'field', 'ref', 'dataSource']);
  for (const key of Object.keys(filter)) {
    keyMap.some((item) => item === key) || delete filter[key];
  }

  return filter;
}


