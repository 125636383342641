import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';

import cloneDeep from 'lodash/cloneDeep';
import extend from 'lodash/extend';
import { Observable } from 'rxjs';

import { BaseChart, GridChartComponent, LineChartComponent } from '@selfai-platform/bi-chart-engine';
import {
  AvaliableUiCharts,
  CHART_TYPE_LIST,
  Dashboard,
  LegendConvertType,
  Pivot,
  UIOption,
} from '@selfai-platform/bi-domain';

import { MapPagePivotComponent } from '../../page-pivot/map/map-page-pivot.component';
import { PagePivotComponent } from '../../page-pivot/page-pivot.component';

@Component({
  selector: 'selfai-platform-page-section-content',
  templateUrl: './page-section-content.component.html',
  styleUrls: ['./page-section-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageSectionContentComponent {
  @ViewChild('pagePivot')
  pagePivot: PagePivotComponent;

  @ViewChild('mapPivot')
  mapPivot: MapPagePivotComponent;

  @ViewChild('chart')
  chart?: BaseChart;

  @ViewChild('gridChart')
  gridChart: GridChartComponent;

  @ViewChild(LineChartComponent)
  lineChartComponent: LineChartComponent;

  @Input() nav: any;
  @Input() rnbMenu: any;
  @Input() isChartShow: any;
  @Input() selectChart: any;
  @Input() widget: any;
  @Input() possibleChartCheck: any;
  @Input() pivot: Pivot;
  @Input() shelf: any;
  @Input() uiOption: any;
  @Input() widgetConfiguration: any;
  @Input() pageDimensions: any;
  @Input() geoType: any;
  @Input() isSankeyNotAllNode: any;
  @Input() isNoData: any;
  @Input() isChartView: any;
  @Input() isError: any;
  @Input() isAvaliableGrid: any;
  @Input() modifyDataVariable: any;
  @Input() limitInfo: any;
  @Input() isPageNameEdit: any;
  @Input() dashboard: Dashboard;
  @Input() isEditMode: boolean;

  @Input() $element: any;
  @Input() recommendCharts: any;
  @Input() eventSubject: any;
  @Input() editingPageName: any;

  @Output() onDeletePivotItem = new EventEmitter<any>();
  @Output() onChangePivotItem = new EventEmitter<any>();
  @Output() onChangePivot = new EventEmitter<any>();
  @Output() toggleFilter = new EventEmitter<any>();
  @Output() onChangePivotFormat = new EventEmitter<any>();
  @Output() openCustomFieldPopup = new EventEmitter<any>();
  @Output() onShowPopup = new EventEmitter<any>();
  @Output() removeAnalysisLayer = new EventEmitter<any>();
  @Output() toggleRnb = new EventEmitter<any>();
  @Output() onNameEdit = new EventEmitter<any>();
  @Output() onNameChange = new EventEmitter<any>();
  @Output() showDataZoom = new EventEmitter<any>();
  @Output() onSelectChart = new EventEmitter<any>();
  @Output() pivotChange: EventEmitter<any> = new EventEmitter();
  @Output() shelfChange: EventEmitter<any> = new EventEmitter();
  @Output() uiOptionChange: EventEmitter<any> = new EventEmitter();
  @Output() widgetConfigChange: EventEmitter<any> = new EventEmitter();
  @Output() drawChartTooltip: EventEmitter<any> = new EventEmitter();

  @Output() onNoData: EventEmitter<any> = new EventEmitter();
  @Output() onChangePivotData: EventEmitter<any> = new EventEmitter();
  @Output() chartSelectInfo: EventEmitter<any> = new EventEmitter();
  @Output() onShowGuide: EventEmitter<any> = new EventEmitter();
  @Output() changeDraw: EventEmitter<any> = new EventEmitter();

  actualPivot$: Observable<Pivot>;

  guideLayout = {
    layout1: ['pie', 'label', 'wordcloud', 'radar'],
    layout2: ['bar', 'grid', 'line', 'combine'],
    layout3: ['waterfall', 'sankey'],

    layout4: ['scatter', 'heatmap', 'boxplot', 'treemap', 'graph', 'graphv2'],
    layout5: ['gauge'],
    layout6: ['map'],
  };

  get ddpSize(): boolean {
    return (
      (this.rnbMenu !== 'filter' && !this.isChartShow) ||
      '' === this.rnbMenu ||
      (this.rnbMenu !== 'filter' && !this.possibleChartCheck(this.rnbMenu, this.selectChart))
    );
  }

  get pivotValue(): any {
    return this.pivot;
  }

  set pivotValue(value: Pivot) {
    this.pivot = value;
    this.pivotChange.emit(value);
  }

  get shelfValue(): any {
    return this.shelf;
  }

  set shelfValue(value: any) {
    this.shelf = value;
    this.shelfChange.emit(value);
  }

  get uiOptionValue(): any {
    return this.uiOption;
  }

  set uiOptionValue(value: any) {
    this.uiOption = value;
    this.uiOptionChange.emit(value);
  }

  get widgetConfigValue(): any {
    return this.widgetConfiguration;
  }

  set widgetConfigValue(value: any) {
    this.widgetConfiguration = value;
    this.widgetConfigChange.emit(value);
  }

  constructor(@Inject(CHART_TYPE_LIST) public readonly chartList: AvaliableUiCharts[]) {}

  changePageName($event) {
    this.isPageNameEdit ? this.onNameChange.emit($event) : null;
  }

  toggelShowLegend(): void {
    const legend = cloneDeep(this.uiOption.legend || {});
    legend.auto = legend.auto ? false : true;
    legend.convertType = LegendConvertType.SHOW;
    this.uiOptionValue = <UIOption>extend({}, this.uiOption, { legend });
  }

  changeChart(chartType: string) {
    this.onSelectChart.emit(chartType);
  }

  toggleColor() {
    if (this.uiOption?.split) {
      return;
    }
    this.toggleRnb.emit('color');
  }
}
