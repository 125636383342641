<form [formGroup]="form">
  <selfai-platform-form-field label="Variable name">
    <input type="text" pInputText formControlName="variableName" />
  </selfai-platform-form-field>

  <selfai-platform-form-field label="Variable Mode">
    <p-dropdown [options]="modeItems" formControlName="variableMode" placeholder="Choose variable mode"></p-dropdown>
  </selfai-platform-form-field>

  <selfai-platform-form-field label="Variable map name">
    <input type="text" pInputText formControlName="variableMapName" />
  </selfai-platform-form-field>

  <selfai-platform-form-field label="Select variable index parts:">
    <ng-container *ngIf="variablePartOfIndexControls">
      <ng-container *ngFor="let variablePartControl of variablePartOfIndexControls; index as i">
        <p-card class="block mb-1">
          <div class="wrapper-multiple-controls">
            <div class="multiple-controls">
              <selfai-platform-form-field label="Part of index:">
                <ng-container [formGroup]="variablePartControl">
                  <selfai-platform-type-field-switch formControlName="type">
                    <selfai-platform-json-path-field formControlName="value"></selfai-platform-json-path-field>
                    <selfai-platform-constant-value-field
                      formControlName="value"
                    ></selfai-platform-constant-value-field>
                    <selfai-platform-spel-value-field formControlName="value"></selfai-platform-spel-value-field>
                  </selfai-platform-type-field-switch>
                </ng-container>
              </selfai-platform-form-field>
            </div>
            <div class="actions-wrapper">
              <ng-container *ngIf="i > 0 || i < variablePartOfIndexControls.length - 1">
                <button
                  type="button"
                  appearance="icon"
                  pButton
                  class="p-button-secondary p-button-text p-button-rounded p-button-link mb-1 btn-arrow"
                  icon="pi pi-arrow-up"
                  [disabled]="i === 0"
                  (click)="orderUp(i)"
                ></button>
                <button
                  type="button"
                  appearance="icon"
                  pButton
                  class="p-button-secondary p-button-text p-button-rounded p-button-link mb-1 btn-arrow"
                  icon="pi pi-arrow-down"
                  [disabled]="i === variablePartOfIndexControls.length - 1"
                  (click)="orderDown(i)"
                ></button>
              </ng-container>
              <button
                (click)="removePartOfIndexControl(i)"
                pButton
                class="p-button-text p-button-rounded p-button-link mb-1 p-button-danger"
                appearance="icon"
                icon="pi pi-trash"
                type="button"
              ></button>
            </div>
          </div>
        </p-card>
      </ng-container>
    </ng-container>
    <button
      label="Add group"
      pButton
      pRipple
      icon="pi pi-plus"
      type="button"
      class="mr-3 mt-3 p-button-sm"
      (click)="addPartOfIndexControl()"
    ></button>
  </selfai-platform-form-field>

  <selfai-platform-form-field label="Variable value">
    <ng-container [formGroup]="variableValue">
      <selfai-platform-type-field-switch formControlName="type">
        <selfai-platform-json-path-field formControlName="value"></selfai-platform-json-path-field>
        <selfai-platform-constant-value-field formControlName="value"></selfai-platform-constant-value-field>
        <selfai-platform-spel-value-field formControlName="value"></selfai-platform-spel-value-field>
      </selfai-platform-type-field-switch>
    </ng-container>
  </selfai-platform-form-field>
</form>
