import {
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import * as _ from 'lodash';
import { fromEvent } from 'rxjs';

import { EventType, UIOption, Shelf, PageWidgetConfiguration } from '@selfai-platform/bi-domain';

import { AbstractComponent } from '../../../common/component/abstract.component';

import { MapSpatialComponent } from './map-spatial/map-spatial.component';
import { AnalysisPredictionComponent } from './prediction/analysis-prediction.component';
import { AnalysisPredictionService } from './service/analysis.prediction.service';
import { TrendLineComponent } from './trend.line/trend.line.component';

@Component({
  selector: 'analysis-component',
  templateUrl: './analysis.component.html',
})
export class AnalysisComponent extends AbstractComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('panel', { static: true })
  private panel: ElementRef;

  @ViewChild(AnalysisPredictionComponent)
  private analysisPredictionComponent: AnalysisPredictionComponent;

  @ViewChild(TrendLineComponent)
  private trendLineComponent: TrendLineComponent;

  @ViewChild('MapSpatialComponent')
  private mapSpatialComponent: MapSpatialComponent;

  @Output()
  private clickDataPanelNoti = new EventEmitter();

  @Output('changeAnalysisPredictionNoti')
  private changeAnalysisPrediction = new EventEmitter();

  @Output('changeForecastNoti')
  private changeForecast = new EventEmitter();

  @Output('changeConfidenceNoti')
  private changeConfidence = new EventEmitter();

  @Output('changeAnalysisNoti')
  private changeAnalysis = new EventEmitter();

  public whatKindOfChart = '';

  public dataSubLayerKey = '';

  @Input()
  public isChartShow: boolean;

  @Input()
  public selectChart: string;

  @Input()
  public widgetConfiguration: PageWidgetConfiguration;

  @Input()
  public dataLayerKey: string;

  @Input()
  public uiOption: UIOption;

  @Input()
  public shelf: Shelf;

  constructor(
    private element: ElementRef,
    protected injector: Injector,
    private analysisPredictionService: AnalysisPredictionService,
  ) {
    super(element, injector);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!_.isUndefined(changes['dataLayerKey'])) {
      if (changes['dataLayerKey'].currentValue !== 'analysis') {
        this.dataSubLayerKey = '';
      }
    }
    if (!_.isUndefined(changes['selectChart'])) {
      this.selectChart = changes['selectChart']['currentValue'];
    }
  }

  public ngOnInit(): void {
    fromEvent(this.panel.nativeElement, 'click').subscribe(() => {
      this.clickDataPanel('analysis');
    });
  }

  public ngOnDestroy(): void {}

  public drawComplete(uiOption: UIOption, param?: Object): void {
    this.synchronize(uiOption, param);
  }

  public synchronize(uiOption: UIOption, param?: Object, data?: Object): void {
    if (data && EventType.CHANGE_PIVOT == data['type']) {
      const widget = data['widget'];
      const lineChart = data['lineChart'];

      this.analysisPredictionService
        .changeAnalysisPredictionLine(this.widgetConfiguration, widget, lineChart, null)
        .then((result) => {
          this.analysisPredictionComponent.synchronize(uiOption, param, result.info.analysis);
        });
    } else {
      this.analysisPredictionComponent.synchronize(uiOption, param);
    }
  }

  public changeOption(): void {
    this.analysisPredictionComponent.changeOption();
  }

  public isValid(): boolean {
    return this.analysisPredictionComponent.isValid();
  }

  public changePredictionLineDisabled(): void {
    this.analysisPredictionComponent.changePredictionLineDisabled();
  }

  public mapSpatialChanges(uiOption, shelf) {
    if (_.isUndefined(this.mapSpatialComponent)) {
      return;
    }
    this.mapSpatialComponent.mapSpatialChanges(uiOption, shelf);
  }

  public mapSpatialAnalysisBtn() {
    this.mapSpatialComponent.spatialAnalysisBtn();
  }

  public changeAnalysisPredictionNoti(): void {
    this.changeAnalysisPrediction.emit();
  }

  public changeForecastNoti(): void {
    this.changeForecast.emit();
  }

  public changeConfidenceNoti(): void {
    this.changeConfidence.emit();
  }

  public clickDataPanel(dataLayerKey: string): void {
    this.clickDataPanelNoti.emit(dataLayerKey);
  }

  public changeAnalysisNoti(value) {
    this.changeAnalysis.emit(value);
  }
}
