import { Injectable } from '@angular/core';
import { concatAll, map, Observable, of, take } from 'rxjs';
import { PermissionService, PipelinePermission } from '@selfai-platform/shared';
import { KeCurrentUserStore } from '../../current-user';
import { Profile } from '@selfai-platform/pipeline-common';
import { EntitySelectorsFactory } from '@ngrx/data';

@Injectable({
  providedIn: 'root',
})
export class UserFunctionsPermissionService {
  private currentUser$ = this.currentUserStore
    .store
    .select((new EntitySelectorsFactory().create<Profile>('keCurrentUser')).selectEntities)
    .pipe(
      concatAll(),
      take(1)
    );

  constructor(
    private readonly permissionService: PermissionService,
    private readonly currentUserStore: KeCurrentUserStore,
  ) {
    this.currentUserStore.getByKey('id');
  }

  public hasCurrentPermission(permission: PipelinePermission): boolean {
    return this.getUserFunctionsPermissions().includes(permission);
  }

  public canCreateUserFunction(): boolean {
    return this.hasCurrentPermission(PipelinePermission.UserFunctionsCreate);
  }

  public canViewUserFunction(userFunctionOwnerId: string): Observable<boolean> {
    if (this.hasCurrentPermission(PipelinePermission.UserFunctionsGetAny)) {
      return of(true);
    }

    return this.equalCurrentUser(userFunctionOwnerId).pipe(
      map((equalCurrentUser) =>
        equalCurrentUser && this.hasCurrentPermission(PipelinePermission.UserFunctionsGetOwn))
    );
  }

  public canUpdateUserFunction(userFunctionOwnerId: string): Observable<boolean> {
    if (this.hasCurrentPermission(PipelinePermission.UserFunctionsUpdateAny)) {
      return of(true);
    }


    return this.equalCurrentUser(userFunctionOwnerId).pipe(
      map((equalCurrentUser) =>
        equalCurrentUser && this.hasCurrentPermission(PipelinePermission.UserFunctionsUpdateOwn)),
    );
  }

  public canDeleteUserFunction(userFunctionOwnerId: string): Observable<boolean> {
    if (this.hasCurrentPermission(PipelinePermission.UserFunctionsDeleteAny)) {
      return of(true);
    }

    return this.equalCurrentUser(userFunctionOwnerId).pipe(
      map((equalCurrentUser) =>
        equalCurrentUser && this.hasCurrentPermission(PipelinePermission.UserFunctionsDeleteOwn)),
    );
  }

  private getUserFunctionsPermissions(): PipelinePermission[] {
    return this.permissionService.getPipelinePermissions().filter((t) => t.startsWith('functions'));
  }

  private equalCurrentUser(userId: string): Observable<boolean> {
    return (<Observable<Profile>>this.currentUser$).pipe(map((data) => data.id === userId));
  }
}
