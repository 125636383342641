class CookieKey {
  public LOGIN_TOKEN = 'LOGIN_TOKEN';

  public LOGIN_TOKEN_TYPE = 'LOGIN_TOKEN_TYPE';

  public REFRESH_LOGIN_TOKEN = 'REFRESH_LOGIN_TOKEN';

  public LOGIN_USER_ID = 'LOGIN_USER_ID';
}

export class CookieConstant {
  public static KEY: CookieKey = new CookieKey();
}
