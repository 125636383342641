<div class="ddp-ui-contentsin ddp-full page-dashboard-edit">
  <div *ngIf="dashboard" class="ddp-wrap-naviarea ddp-editnavi ddp-board-naviarea">
    <div class="ml-3 mr-3">
      <p-button
        class="select-dashboard-button icon-button"
        icon="pi pi-th-large"
        (click)="op.toggle($event); isShowDashboardList = !isShowDashboardList"
        (clickOutside)="isShowDashboardList = false">
      </p-button>
      <p-overlayPanel #op [style]="{ width: '30%' }" [showCloseIcon]="true">
        <ng-template pTemplate="content">
          <div style="overflow-y: auto; max-height: 90vh;">
            <p-table
              #dashboardTable [value]="dashboards"
              selectionMode="single"
              [rows]="5"
              [globalFilterFields]="['name']"
              responsiveLayout="scroll">
              <ng-template pTemplate="caption">
                <div class="flex align-items-center">
                  <i class="pi pi-search mr-2"></i>
                  <input pInputText type="text"
                    class="w-full"
                    (input)="dashboardTable.filterGlobal($event.target.value, 'contains')"
                    [placeholder]="'msg.board.custom.ui.placeholder' | translate" />
                  <p-button
                    class="ml-2"
                    icon="pi pi-plus"
                    (click)="moveOrNewDashboard(); op.toggle($event)">
                  </p-button>
                </div>
              </ng-template>
              <ng-template pTemplate="header">
                <tr>
                  <th pSortableColumn="name">
                    {{'msg.comm.ui.dashboard' | translate}} <p-sortIcon field="name" />
                  </th>
                  <th>{{'msg.comm.ui.list.thumbnail.view' | translate}}</th>
                </tr>
              </ng-template>
              <ng-template
                pTemplate="body"
                let-rowData
                let-dashboardItem>
                <tr [pSelectableRow]="rowData"
                    (click)="
                          moveOrNewDashboard(dashboardItem);
                          op.toggle($event); safelyDetectChanges();
                          navigateToEditDashboardView(dashboardItem.id)
                        ">
                  <td style="max-width: 70%;">
                    <span style="display: inline-block; max-width: 250px;">
                      {{ dashboardItem.name }}
                    </span>
                    </td>
                  <td>
                    <selfai-bi-dashbord-preview-image
                      class="ddp-img-chart"
                      [imageUrl]="dashboardItem?.imageUrl">
                    </selfai-bi-dashbord-preview-image>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </ng-template>
      </p-overlayPanel>
    </div>

    <p-button
      class="truncate edit-name-button"
      *ngIf="!isDashboardNameEditMode; else editNameInput"
      icon="pi pi-pencil"
      iconPos="right"
      [text]="true"
      [label]="dashboard.name"
      (click)="toggleEditDashboardName()"
    />

    <ng-template #editNameInput>
      <input
        [ngClass]="{ 'ng-invalid': !editableName.length }"
        [(ngModel)]="editableName"
        pInputText
        [placeholder]="'msg.comm.ui.name' | translate"
      />
      <p-button
        class="ml-2 icon-button"
        (click)="setDashboardName(editableName); toggleEditDashboardName()"
        icon="pi pi-check"
      />
      <p-button
        class="ml-2 icon-button"
        (click)="toggleEditDashboardName()"
        icon="pi pi-times"
        severity="danger"
      />
    </ng-template>

    <p-button
      class="truncate ml-2 edit-description-button"
      *ngIf="!isDashboardDescriptionEditMode; else editDescriptionInput"
      icon="pi pi-pencil"
      iconPos="right"
      [label]="dashboard.description || ('msg.comm.detail.desc' | translate)"
      [text]="true"
      [plain]="true"
      [style.opacity]="dashboard.description ? '1': '0.5'"
      (click)="toggleEditDashboardDescription()"
    />

    <ng-template #editDescriptionInput>
      <div class="align-self-baseline z-2 ml-2">
        <textarea
          style="margin-top: 7px; width: 200px"
          rows="5"
          cols="30"
          pInputTextarea
          [autoResize]="true"
          [(ngModel)]="editableDescription"
          [placeholder]="'msg.comm.detail.desc' | translate"
        ></textarea>
      </div>
      <p-button
        class="ml-2 icon-button"
        (click)="setDashboardDescription(editableDescription); toggleEditDashboardDescription()"
        icon="pi pi-check"
      />
      <p-button
        class="ml-2 icon-button"
        (click)="toggleEditDashboardDescription()"
        icon="pi pi-times"
        severity="danger"
      />
    </ng-template>

    <div class="ddp-ui-navibuttons">
      <p-button
        class="mr-2 add-new-item-button"
        badge="+"
        label="{{ 'msg.board.btn.new.chart' | translate }}"
        icon="pi pi-chart-pie"
        (click)="isAppendLayout = true; addChart()"
      />
      <p-button
        class="mr-2 add-new-item-button"
        badge="+"
        label="{{ 'msg.board.btn.new.text' | translate }}"
        icon="pi pi-align-center"
        (click)="isAppendLayout = true; openTextWidgetEditor()"
      />
      <p-button
        class="mr-5 add-new-item-button"
        badge="+"
        label="{{ 'msg.board.btn.new.filter' | translate }}"
        icon="pi pi-filter"
        (click)="isAppendLayout = true; openUpdateFilterPopup()"
      />
      <p-button
        class="mr-2"
        label="{{ 'msg.comm.btn.dismiss' | translate }}"
        icon="pi pi-times"
        severity="danger"
        (click)="openDismissConfirm()"
      />
      <p-button
        class="mr-3"
        label="{{ 'msg.comm.btn.done' | translate }}"
        icon="pi pi-check"
        severity="success"
        (click)="updateDashboard()"
      />
    </div>
  </div>

  <div class="ddp-wrap-editboard" cdkDropListGroup>
    <div class="ddp-ui-edit-contents">
      <div class="ddp-box-boardedit">
        <div class="ddp-view-boardedit">
          <div
            class="ddp-ui-boardedit static"
            #layoutContainer
            id="grid-drop-container"
            cdkDropList
            [cdkDropListData]="droppedData"
            (cdkDropListDropped)="endDrop($event)"
          >
            <ktd-grid
              #gridComponent
              [gap]="gap"
              [cols]="cols"
              [rowHeight]="rowHeight"
              [scrollableParent]="layoutContainer"
              [layout]="ktGridLayout"
              (layoutUpdated)="onLayoutUpdated($event)"
              (gridItemResize)="onGridItemResized($event)"
            >
              <ktd-grid-item
                *ngFor="let item of ktGridLayout; trackBy: trackById"
                [id]="item.id"
                [draggable]="!readOnlyGrid"
                [resizable]="!readOnlyGrid"
                style="overflow: initial"
              >
                <div class="ktd-grid-widget-wrapper">
                  <dashboard-widget-header
                    [widget]="item.widget"
                    [layoutMode]="item.layoutMode"
                    [isShowTitle]="true"
                  ></dashboard-widget-header>
                  <dashboard-widget
                    [widget]="item.widget"
                    [widgetOpts]="item.widgetOpts"
                    [layoutMode]="item.layoutMode"
                    [widgetInfo]="item.widgetInfo"
                    style="height: 100%"
                    [isDashboardLoaded]="true"
                  ></dashboard-widget>
                </div>
                <ng-template ktdGridItemPlaceholder>
                  <div class="grid-placeholder"></div>
                </ng-template>
              </ktd-grid-item>
            </ktd-grid>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="selectedRightTab !== rightTab.NONE"
      class="ddp-ui-boardside-contents"
      style="z-index: 126; position: relative"
    >
      <div class="ddp-view-boardside">
        <div class="ddp-ui-boardside-in ddp-chart-boardside" *ngIf="selectedRightTab === rightTab.CHART">
          <div class="ddp-area-boardside">
            <div class="ddp-ui-boardside-title">
              <span
                class="pi pi-info-circle cursor-pointer mr-2"
                [pTooltip]="tooltipContent"
                tooltipPosition="bottom"
                tooltipStyleClass="guide-tooltip"
              ></span>
              <ng-template #tooltipContent>
                <div class="flex flex-column align-items-start">
                  <div class="ddp-data-title text-lg font-bold">
                    {{ 'msg.board.ui.chart.setting.guide' | translate }}
                  </div>
                  <br>
                  <div>
                    <strong>{{ 'msg.board.th.chart-widget' | translate }}</strong>
                  </div>
                  <div>{{ 'msg.board.th.chart-widget.description' | translate }}</div>
                  <br>
                  <div>
                    <strong>{{ 'msg.board.th.hierarchy' | translate }}</strong>
                  </div>
                  <div>{{ 'msg.board.th.hierarchy.description' | translate }}</div>
                </div>
              </ng-template>
              {{ 'msg.page.ui.chart' | translate }}
              <span class="ddp-data-num">{{ pageWidgets.length }}</span>
              <a (click)="addChart()" href="javascript:" class="ddp-icon-add">
                <div class="ddp-ui-tooltip-info ddp-down">
                  <em class="ddp-icon-view-top"></em>
                  {{ 'msg.board.ui.add.chart.tooltip' | translate }}
                </div>
              </a>
            </div>

            <div class="ddp-wrap-downmenu">
              <ul
                class="ddp-list-chart"
                cdkDropList
                [cdkDropListData]="hierarchy.get()"
                [cdkDropListConnectedTo]="['grid-drop-container']"
                cdkDropListSortingDisabled
                *ngIf="hierarchy"
              >
                <li
                  *ngFor="let widgetRel_1 of hierarchy.get()"
                  cdkDrag
                  [cdkDragData]="widgetRel_1.id"
                  [cdkDragDisabled]="isWidgetInLayout(widgetRel_1.id)"
                >
                  <div class="chart-drag-placeholder" *cdkDragPlaceholder></div>
                  <div class="ddp-chart-images" *cdkDragPreview>
                    <span class="ddp-wrap-chartimg">
                      <em class="{{ 'ddp-img-chart-' + getWidgetType(widgetRel_1.id) }}"></em>
                    </span>
                    <em *ngIf="isWidgetInLayout(widgetRel_1.id)" class="ddp-icon-used"></em>
                  </div>
                  <a href="javascript:">
                    <div class="ddp-chart-images">
                      <span class="ddp-wrap-chartimg">
                        <em class="{{ 'ddp-img-chart-' + getWidgetType(widgetRel_1.id) }}"></em>
                      </span>
                      <em *ngIf="isWidgetInLayout(widgetRel_1.id)" class="ddp-icon-used"></em>
                    </div>

                    <div class="ddp-data-info">
                      <span class="ddp-data-name">{{ widgetRel_1.name }}</span>
                      <span class="ddp-data-used">{{ getChartFields(widgetRel_1.id) }}</span>
                    </div>

                    <div class="ddp-btn-control">
                      <em class="ddp-icon-edit-s" (click)="editWidgetEventHandler(widgetRel_1.id)"></em>
                      <em class="ddp-icon-del-s" (click)="setRemoveWidget(widgetRel_1.id)"></em>
                    </div>
                  </a>

                  <ul
                    *ngIf="widgetRel_1.children && 0 < widgetRel_1.children.length"
                    class="ddp-list-chart-2depth"
                    cdkDropList
                    [cdkDropListData]="widgetRel_1.children"
                    [cdkDropListConnectedTo]="['grid-drop-container']"
                    cdkDropListSortingDisabled
                  >
                    <li
                      *ngFor="let widgetRel_2 of widgetRel_1.children"
                      cdkDrag
                      [cdkDragData]="widgetRel_2.id"
                      [cdkDragDisabled]="isWidgetInLayout(widgetRel_2.id)"
                    >
                      <div class="chart-drag-placeholder" *cdkDragPlaceholder></div>
                      <div class="ddp-chart-images" *cdkDragPreview>
                        <span class="ddp-wrap-chartimg">
                          <em class="{{ 'ddp-img-chart-' + getWidgetType(widgetRel_2.id) }}"></em>
                        </span>
                        <em *ngIf="isWidgetInLayout(widgetRel_2.id)" class="ddp-icon-used"></em>
                      </div>
                      <a href="javascript:">
                        <div class="ddp-chart-images">
                          <span class="ddp-wrap-chartimg">
                            <em class="{{ 'ddp-img-chart-' + getWidgetType(widgetRel_2.id) }}"></em>
                          </span>
                          <em *ngIf="isWidgetInLayout(widgetRel_2.id)" class="ddp-icon-used"></em>
                        </div>

                        <div class="ddp-data-info">
                          <span class="ddp-data-name">{{ widgetRel_2.name }}</span>
                          <span class="ddp-data-used">{{ getChartFields(widgetRel_2.id) }}</span>
                        </div>

                        <div class="ddp-btn-control">
                          <em class="ddp-icon-edit-s" (click)="editWidgetEventHandler(widgetRel_2.id)"></em>
                          <em class="ddp-icon-del-s" (click)="setRemoveWidget(widgetRel_2.id)"></em>
                        </div>
                      </a>

                      <ul
                        *ngIf="widgetRel_2.children && 0 < widgetRel_2.children.length"
                        class="ddp-list-chart-3depth"
                        cdkDropList
                        [cdkDropListData]="widgetRel_2.children"
                        [cdkDropListConnectedTo]="['grid-drop-container']"
                        cdkDropListSortingDisabled
                      >
                        <li
                          *ngFor="let widgetRel_3 of widgetRel_2.children"
                          cdkDrag
                          [cdkDragData]="widgetRel_3.id"
                          [cdkDragDisabled]="isWidgetInLayout(widgetRel_3.id)"
                        >
                          <div class="chart-drag-placeholder" *cdkDragPlaceholder></div>
                          <div class="ddp-chart-images" *cdkDragPreview>
                            <span class="ddp-wrap-chartimg">
                              <em class="{{ 'ddp-img-chart-' + getWidgetType(widgetRel_3.id) }}"></em>
                            </span>
                            <em *ngIf="isWidgetInLayout(widgetRel_3.id)" class="ddp-icon-used"></em>
                          </div>
                          <a href="javascript:">
                            <div class="ddp-chart-images">
                              <span class="ddp-wrap-chartimg">
                                <em class="{{ 'ddp-img-chart-' + getWidgetType(widgetRel_3.id) }}"></em>
                              </span>
                              <em *ngIf="isWidgetInLayout(widgetRel_3.id)" class="ddp-icon-used"></em>
                            </div>

                            <div class="ddp-data-info">
                              <span class="ddp-data-name">{{ widgetRel_3.name }}</span>
                              <span class="ddp-data-used">{{ getChartFields(widgetRel_3.id) }}</span>
                            </div>

                            <div class="ddp-btn-control">
                              <em class="ddp-icon-edit-s" (click)="editWidgetEventHandler(widgetRel_3.id)"></em>
                              <em class="ddp-icon-del-s" (click)="setRemoveWidget(widgetRel_3.id)"></em>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>

            <div class="ddp-foot-buttons">
              <a href="javascript:" class="ddp-btn-set" (click)="showSetPageRelation()">
                <em class="ddp-icon-hierarch"></em>
                {{ 'msg.board.btn.hierarchy' | translate }}
              </a>
            </div>
          </div>
        </div>

        <text-widgets-panel
          *ngIf="selectedRightTab === rightTab.TEXT"
          [dashboard]="dashboard"
          [widgetCompsInLayout]="getWidgetComps()"
          (changeEvent)="setTextWidget($event)"
        ></text-widgets-panel>

        <div class="ddp-ui-boardside-in ddp-filter-boardside" *ngIf="selectedRightTab === rightTab.FILTER">
          <div class="ddp-area-boardside">
            <div class="ddp-ui-boardside-title">
              {{ 'msg.board.filter.title.global-filter' | translate }}
              <span class="ddp-data-num">
                {{ boardFilters.length }}
              </span>
              <a href="javascript:" class="ddp-icon-add" (click)="openUpdateFilterPopup()">
                <div class="ddp-ui-tooltip-info ddp-down">
                  <em class="ddp-icon-view-top"></em>
                  {{ 'msg.board.ui.filter.tooltip' | translate }}
                </div>
              </a>
            </div>

            <div class="ddp-ui-boardside-scroll">
              <div [class.ddp-border-none]="pageFilters.length === 0">
                <div
                  *ngFor="let item of pageFilters; trackBy: filterListTrackByFn; let idx = index"
                  (click)="openIndexFilterPanel = idx"
                  [class.ddp-selected]="idx === openIndexFilterPanel"
                  class="ddp-wrap-divide ddp-dropdown filter-panel-container"
                  cdkDropList
                  [cdkDropListData]="pageFilters"
                  [cdkDropListConnectedTo]="['grid-drop-container']"
                  cdkDropListSortingDisabled
                >
                  <div
                    *ngIf="isTimeFilter(getFilterForFilterWidget(item))"
                    cdkDrag
                    [cdkDragData]="item.id"
                    [cdkDragDisabled]="isWidgetInLayout(item.id)"
                  >
                    <time-filter-panel
                      [filter]="getFilterForFilterWidget(item)"
                      [dashboard]="dashboard"
                      [isWidgetInLayout]="isWidgetInLayout(item.id)"
                      [isDraggable]="isDraggableFilterWidget(item)"
                      (openUpdateFilterPopupEvent)="openUpdateFilterPopup($event)"
                      (updateFilterEvent)="updateFilter($event)"
                      (deleteFilterEvent)="deleteFilter($event)"
                      (changeChartToGlobalEvent)="openChangeFilterConfirm($event)"
                    ></time-filter-panel>
                    <div class="chart-drag-placeholder" *cdkDragPlaceholder></div>
                    <div class="ddp-chart-images" *cdkDragPreview>
                      <span class="ddp-wrap-chartimg">
                        <em class="ddp-icon-dimension-calen"></em>
                      </span>
                    </div>
                  </div>
                  <div
                    *ngIf="getFilterForFilterWidget(item).type === 'include'"
                    cdkDrag
                    [cdkDragData]="item.id"
                    [cdkDragDisabled]="isWidgetInLayout(item.id)"
                  >
                    <inclusion-filter-panel
                      [filter]="getFilterForFilterWidget(item)"
                      [dashboard]="dashboard"
                      [isWidgetInLayout]="isWidgetInLayout(item.id)"
                      [isDraggable]="isDraggableFilterWidget(item)"
                      (openUpdateFilterPopupEvent)="openUpdateFilterPopup($event)"
                      (updateFilterEvent)="updateFilter($event)"
                      (deleteFilterEvent)="deleteFilter($event)"
                      (changeChartToGlobalEvent)="openChangeFilterConfirm($event)"
                    ></inclusion-filter-panel>
                    <div class="chart-drag-placeholder" *cdkDragPlaceholder></div>
                    <div class="ddp-chart-images" *cdkDragPreview>
                      <span class="ddp-wrap-chartimg">
                        <em class="ddp-icon-dimension-ab"></em>
                      </span>
                      <em *ngIf="isWidgetInLayout(item.id)" class="ddp-icon-used"></em>
                    </div>
                  </div>
                  <div
                    *ngIf="getFilterForFilterWidget(item).type === 'bound'"
                    cdkDrag
                    [cdkDragData]="item.id"
                    [cdkDragDisabled]="isWidgetInLayout(item.id)"
                  >
                    <bound-filter-panel
                      [filter]="getFilterForFilterWidget(item)"
                      [dashboard]="dashboard"
                      [isWidgetInLayout]="isWidgetInLayout(item.id)"
                      [isDraggable]="isDraggableFilterWidget(item)"
                      (openUpdateFilterPopupEvent)="openUpdateFilterPopup($event)"
                      (updateFilterEvent)="updateFilter($event)"
                      (deleteFilterEvent)="deleteFilter($event)"
                      (changeChartToGlobalEvent)="openChangeFilterConfirm($event)"
                    ></bound-filter-panel>
                    <div class="chart-drag-placeholder" *cdkDragPlaceholder></div>
                    <div class="ddp-chart-images" *cdkDragPreview>
                      <span class="ddp-wrap-chartimg">
                        <em class="ddp-icon-measure-sharp"></em>
                      </span>
                      <em *ngIf="isWidgetInLayout(item.id)" class="ddp-icon-used"></em>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <app-dashboard-layout-config
          *ngIf="selectedRightTab === rightTab.LAYOUT"
          [dashboard]="dashboard"
          [boardConf]="boardConfiguration"
          (changeBoardConf)="changeBoardConf($event)"
        ></app-dashboard-layout-config>

        <datasource-panel
          [dashboard]="dashboard"
          [chartFilters]="chartFilters"
          (changeFilter)="changeFilter($event)"
          (onFilterClick)="selectedRightTab = rightTab.FILTER"
          (onUpdateFilter)="addFilter($event)"
          (onDeleteFilter)="deleteFilter($event)"
          (onUpdateCustomField)="updateCustomField($event)"
          (onDeleteCustomField)="deleteCustomField($event)"
          (changeFieldAlias)="updateFieldAndWidgetPivotAndRender($event)"
        ></datasource-panel>
      </div>
    </div>

    <div class="ddp-ui-boardside-menu">
      <a
        class="boardside-menu-item"
        href="javascript:"
        [ngClass]="{ 'ddp-selected': selectedRightTab === rightTab.CHART }"
        (click)="toggleRnb(rightTab.CHART)"
      >
        <i class="pi pi-chart-pie" style="font-size: 1.5rem"></i>
      </a>
      <a
        class="boardside-menu-item"
        href="javascript:"
        [ngClass]="{ 'ddp-selected': selectedRightTab === rightTab.TEXT }"
        (click)="toggleRnb(rightTab.TEXT)"
      >
        <i class="pi pi-align-center" style="font-size: 1.5rem"></i>
      </a>
      <a
        class="boardside-menu-item"
        href="javascript:"
        [ngClass]="{ 'ddp-selected': selectedRightTab === rightTab.FILTER }"
        (click)="toggleRnb(rightTab.FILTER)"
      >
        <i class="pi pi-filter" style="font-size: 1.5rem"></i>
      </a>
      <span class="ddp-ui-menu-layout">
        <a
          class="boardside-menu-item"
          href="javascript:"
          [ngClass]="{ 'ddp-selected': selectedRightTab === rightTab.LAYOUT }"
          (click)="toggleRnb(rightTab.LAYOUT)"
        >
          <i class="pi pi-cog" style="font-size: 1.5rem"></i>
        </a>
      </span>
      <span class="ddp-ui-menu-layout ddp-bottom">
        <a href="javascript:" (click)="toggleDatasourcePanel()">
           <i class="pi pi-database" style="font-size: 1.5rem"></i>
        </a>
      </span>
    </div>
  </div>
</div>

<app-config-filter (done)="configureFilter($event)" (close)="closeComponent('UPDATE-FILTER')"></app-config-filter>
<app-page
  #page
  *ngIf="isShowPage"
  [widget]="selectedPageWidget"
  (changeFieldAlias)="updateFieldAndWidgetPivot($event)"
  (updateCustomField)="updateCustomField($event)"
  (close)="closeComponent('PAGE')"
></app-page>
<app-page-relation (changeRelation)="changePageWidgetRelation($event)"></app-page-relation>
<app-update-datasource
  *ngIf="isUpdateDataSource"
  [workbookId]="workbook.id"
  [workbookName]="workbook.name"
  [workspaceId]="workbook['workspaceId']"
  [sourceDashboard]="dashboard"
  (done)="changeDataSource($event)"
  (close)="closeUpdateDataSource()"
></app-update-datasource>
