import { Component, ElementRef, Injector, OnInit } from '@angular/core';

import { AbstractFilterPanelComponent } from '../abstract-filter-panel.component';

@Component({
  selector: 'app-parameter',
  templateUrl: './parameter.component.html',
})
export class ParameterComponent extends AbstractFilterPanelComponent implements OnInit {
  constructor(protected elementRef: ElementRef, protected injector: Injector) {
    super(elementRef, injector);
  }

  public ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    super.ngOnDestroy();
  }
}
