import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { DataListViewItem } from '../models';

@Injectable()
export class SelectedItemsService<T extends DataListViewItem> {
  private readonly selectedItems$ = new BehaviorSubject([]);

  getSelectedItems(): Observable<T[]> {
    return this.selectedItems$.asObservable();
  }

  getSelectedItemIds(): Observable<T['id'][]> {
    return this.getSelectedItems().pipe(map((items) => items.map((item) => item.id)));
  }

  setSelectedItems(selectedItems: T[]): void {
    this.selectedItems$.next(selectedItems);
  }

  resetSelectedItems(): void {
    this.setSelectedItems([]);
  }
}
