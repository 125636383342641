import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { KdBackendApiService } from '../../common';
import { RecycleApiPageParams, WrapperRecycle } from '../models';

@Injectable({
  providedIn: 'root',
})
export class RecycleApiService {
  constructor(private readonly kdBackendApiService: KdBackendApiService) {}

  getAll(params: RecycleApiPageParams): Observable<WrapperRecycle> {
    const url = this.kdBackendApiService.buildPath('recycleBinItems', {
      projection: 'default',
      ...params,
    });

    return this.kdBackendApiService.get<WrapperRecycle>(url);
  }

  removePermanently(id: number): Observable<unknown> {
    return this.kdBackendApiService.post(`recycleBinItems/${id}/removePermanently`, {});
  }

  recover(id: number): Observable<unknown> {
    return this.kdBackendApiService.post(`recycleBinItems/${id}/recover`, {});
  }

  forCurrentUser(params: RecycleApiPageParams): Observable<WrapperRecycle> {
    const url = this.kdBackendApiService.buildPath('recycleBinItems/forCurrentUser', {
      ...params,
    });

    return this.kdBackendApiService.get<WrapperRecycle>(url);
  }

  recycleBinWidget(widgetId: string): Observable<unknown> {
    return this.kdBackendApiService.put(`widgets/${widgetId}/recycleBinItem`, {});
  }

  recycleBinDashboard(dashboardId: string): Observable<unknown> {
    return this.kdBackendApiService.put(`dashboards/${dashboardId}/recycleBinItem`, {});
  }

  recycleBinWorkbook(workbookId: string): Observable<unknown> {
    return this.kdBackendApiService.put(`workbooks/${workbookId}/recycleBinItem`, {});
  }

  recycleBinWorkspace(workspaceId: string): Observable<unknown> {
    return this.kdBackendApiService.put(`workspaces/${workspaceId}/recycleBinItem`, {});
  }

  recycleBinDatasource(datasourceId: string): Observable<unknown> {
    return this.kdBackendApiService.put(`datasources/${datasourceId}/recycleBinItem`, {});
  }
}
