import { Injectable } from '@angular/core';
import { GraphNodeOptionSerialized, LoggingApiDisabled, WorkflowSerialized } from '@selfai-platform/pipeline-common';

import { WorkflowStateService } from '@selfai-platform/storage';
import isEqual from 'lodash/isEqual';
import { BehaviorSubject, map, Observable, take } from 'rxjs';

@Injectable()
export class SaveConfirmationService extends BehaviorSubject<GraphNodeOptionSerialized['parameters'] | undefined> {
  nodeId?: string;

  constructor(private readonly workflowStateService: WorkflowStateService) {
    super(undefined);
  }

  setNodeInitState(nodeId: string, state: GraphNodeOptionSerialized['parameters']): void {
    this.nodeId = nodeId;
    this.next(state);
  }

  stateIsChanged(newData: GraphNodeOptionSerialized['parameters']): Observable<boolean> {
    return this.workflowStateService.getWorkflowState().pipe(
      take(1),
      map((workflow) => {
        if (workflow) {
          const nodeData = this.extractNodeDataFromWorkflow(workflow);

          if (!nodeData) {
            return false;
          }

          const updatedData = this.getUpdatedNodeParameters(nodeData.parameters, newData);

          if (!this.value?.Logging && Boolean((updatedData.Logging as LoggingApiDisabled)?.Disabled)) {
            updatedData.Logging = this.value?.Logging;
          }

          // unused field. we shouldn't use it for compare
          delete updatedData['report type'];

          return !isEqual(updatedData, this.value);
        }

        return false;
      }),
    );
  }

  private extractNodeDataFromWorkflow(workflow: WorkflowSerialized): GraphNodeOptionSerialized {
    return workflow.workflow.nodes.find(({ id }) => id === this.nodeId) as GraphNodeOptionSerialized;
  }

  protected getUpdatedNodeParameters(
    workflowParameters: GraphNodeOptionSerialized['parameters'],
    newParameters: GraphNodeOptionSerialized['parameters'],
  ): GraphNodeOptionSerialized['parameters'] {
    return {
      ...workflowParameters,
      ...newParameters,
    };
  }
}
