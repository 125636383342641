import { Injectable } from '@angular/core';
import { RecycleApiService, WrapperRecycle } from '@selfai-platform/bi-api';
import { Observable } from 'rxjs';
import { RecycleBinPageParams } from '../models';

@Injectable({ providedIn: 'root' })
export class RecycleBinApiToDomainService {
  constructor(private readonly recycleApiService: RecycleApiService) {}

  /**
   * Admin
   */
  getAll(options: RecycleBinPageParams): Observable<WrapperRecycle> {
    return this.recycleApiService.getAll(options);
  }

  /** User */
  removePermanently(id: number): Observable<unknown> {
    return this.recycleApiService.removePermanently(id);
  }

  recover(id: number): Observable<unknown> {
    return this.recycleApiService.recover(id);
  }

  forCurrentUser(options: RecycleBinPageParams): Observable<WrapperRecycle> {
    return this.recycleApiService.forCurrentUser(options);
  }

  recycleBinWidget(widgetId: string): Observable<unknown> {
    return this.recycleApiService.recycleBinWidget(widgetId);
  }

  recycleBinDashboard(dashboardId: string): Observable<unknown> {
    return this.recycleApiService.recycleBinDashboard(dashboardId);
  }

  recycleBinWorkbook(workbookId: string): Observable<unknown> {
    return this.recycleApiService.recycleBinWorkbook(workbookId);
  }

  recycleBinWorkspace(workspaceId: string): Observable<unknown> {
    return this.recycleApiService.recycleBinWorkspace(workspaceId);
  }

  recycleBinDatasource(datasourceId: string): Observable<unknown> {
    return this.recycleApiService.recycleBinDatasource(datasourceId);
  }
}
