<div class="ddp-type-top-option ddp-type">
  <ul class="ddp-ui-tab ddp-type">
    <li (click)="changeTab(tab.PREVIEW)" [class.ddp-selected]="tab.PREVIEW === selectedTab">
      <a href="javascript:">{{ 'msg.board.create.data-preview' | translate }}</a>
    </li>
    <li (click)="changeTab(tab.JOIN)" [class.ddp-selected]="tab.JOIN === selectedTab">
      <a href="javascript:">{{ 'msg.board.create.join' | translate }}</a>
    </li>
    <li *ngIf="isLinkedDataSource" (click)="changeTab(tab.FILTER)" [class.ddp-selected]="tab.FILTER === selectedTab">
      <a href="javascript:">{{ 'msg.board.create.essential-filter' | translate }}</a>
    </li>
  </ul>

  <div class="ddp-ui-rightoption ddp-type-option">
    <div (click)="deleteDataSource()" class="ddp-part">
      <a href="javascript:" class="ddp-link-unlink">{{ 'msg.board.create.unlink-datasource' | translate }}</a>
    </div>
    <a (click)="closeDsInfo()" href="javascript:" class="ddp-btn-box-close"></a>
  </div>
</div>

<div class="ddp-wrap-tab-contents">
  <div [hidden]="tab.PREVIEW !== selectedTab" class="ddp-ui-tab-contents">
    <div class="ddp-wrap-grid-option ddp-clear">
      <span class="ddp-txt-title">
        {{ dataSource.name }}
        <span *ngIf="dataSource.uiDescription" class="ddp-txt-title-info">{{ dataSource.uiDescription }}</span>
      </span>
      <div class="ddp-part-right">
        <div *ngIf="dataSourceSummary" class="ddp-data-form">
          <strong>{{ commonUtil.formatBytes(dataSourceSummary.size, 1) }}</strong>
        </div>
        <div class="ddp-data-form">
          <strong>{{ headerCnt }}</strong>
          {{ 'msg.comm.detail.columns' | translate }}
        </div>
        <div class="ddp-data-form">
          <component-input
            [value]="rowNum"
            [valueType]="'number'"
            [placeHolder]="'msg.board.ui.search.full-column.ph' | translate"
            (changeValue)="setGridRow($event)"
          ></component-input>
          <strong>/ {{ dataSourceSummary?.count }}</strong>
          {{ 'msg.comm.detail.rows' | translate }}
        </div>
        <div *ngIf="colTypes" class="ddp-data-form">
          <strong (mouseover)="isShowColTypeLayer = true" (mouseout)="isShowColTypeLayer = false" class="ddp-cursor">
            {{ colTypes.length }}
          </strong>
          Types
          <div *ngIf="isShowColTypeLayer" class="ddp-wrap-popup2 ddp-types">
            <ul class="ddp-list-popup">
              <li *ngFor="let colType of colTypes">
                <a href="javascript:" style="cursor: pointer">
                  <em class="{{ getTypeClass(colType.type) }}"></em>
                  {{ colType.cnt }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="ddp-ui-grid-form">
      <div class="ddp-data-empty" *ngIf="isEmptyMainGrid">
        <span class="ddp-data-contents">{{ 'msg.space.ui.no.data' | translate }}</span>
      </div>
      <div grid-component #main style="width: 100%; height: 100%"></div>
    </div>
  </div>

  <div [hidden]="tab.JOIN !== selectedTab" class="ddp-ui-tab-contents">
    <div class="ddp-box-join-option">
      <div class="ddp-view-join">
        <a href="javascript:" class="ddp-data-name">
          <em class="ddp-icon-database-w"></em>
          {{ dataSource.name }}
          <em class="ddp-icon-view"></em>
        </a>

        <div class="ddp-box-lookup">
          <ul class="ddp-list-lookup">
            <li *ngFor="let join of joinMappings; let i = index">
              <div class="ddp-ui-lookup">
                <a href="javascript:" class="ddp-ui-joinicon" (click)="showJoinPopup(join, dataSource.id)">
                  <em
                    [class.ddp-icon-join-left]="join.type === 'LEFT_OUTER'"
                    [class.ddp-icon-join-right]="join.type === 'RIGHT_OUTER'"
                    [class.ddp-icon-join-inner]="join.type === 'INNER'"
                    [class.ddp-icon-join-outer]="join.type === 'FULL'"
                  ></em>
                </a>

                <a
                  *ngIf="joinRenameStarted[join.id] !== true; else renameRootJoinForm"
                  href="javascript:"
                  class="ddp-link-joinname"
                >
                  <em class="ddp-icon-database2"></em>
                  <span class="ddp-data-joinname">{{ join.joinAlias }} ({{ join.name }})</span>
                  <div class="ddp-btn-edit">
                    <em class="ddp-icon-control-edit" (click)="showRenameJoinForm(join.id)"></em>
                    <em class="ddp-icon-editdel" (click)="removeJoin(joinMappings, i)"></em>
                  </div>
                </a>

                <ng-template #renameRootJoinForm>
                  <ng-container *ngTemplateOutlet="renameJoinForm; context: { $implicit: join }"></ng-container>
                </ng-template>
              </div>

              <div class="ddp-ui-add-data" *ngIf="!join.join || !join.join.id">
                <a href="javascript:" class="ddp-link-add-data" (click)="showJoinPopup(join)">
                  <em class="ddp-icon-add"></em>
                  {{ 'msg.board.btn.add.datasource.join' | translate }}
                </a>
              </div>

              <div class="ddp-ui-lookup" *ngIf="join.join && join.join.id">
                <a href="javascript:" class="ddp-ui-joinicon" (click)="showJoinPopup(join.join, join.id)">
                  <em
                    [class.ddp-icon-join-left]="join.join.type === 'LEFT_OUTER'"
                    [class.ddp-icon-join-right]="join.join.type === 'RIGHT_OUTER'"
                    [class.ddp-icon-join-inner]="join.join.type === 'INNER'"
                    [class.ddp-icon-join-outer]="join.join.type === 'FULL'"
                  ></em>
                </a>

                <a
                  *ngIf="joinRenameStarted[join.join.id] !== true; else renameSubJoinForm"
                  href="javascript:"
                  class="ddp-link-joinname"
                >
                  <em class="ddp-icon-database2"></em>
                  <span class="ddp-data-joinname">{{ join.join.joinAlias }} ({{ join.join.name }})</span>
                  <div class="ddp-btn-edit">
                    <em class="ddp-icon-control-edit" (click)="showRenameJoinForm(join.join.id)"></em>
                    <em class="ddp-icon-editdel" (click)="join.join = null"></em>
                  </div>
                </a>
                <ng-template #renameSubJoinForm>
                  <ng-container *ngTemplateOutlet="renameJoinForm; context: { $implicit: join.join }"></ng-container>
                </ng-template>
              </div>
            </li>

            <li class="ddp-list-add" *ngIf="isEnableJoin">
              <a href="javascript:" class="ddp-link-add-data" (click)="showJoinPopup()">
                <em class="ddp-icon-add"></em>
                {{ 'msg.board.btn.add.datasource.join' | translate }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isLinkedDataSource" [hidden]="tab.FILTER !== selectedTab" class="ddp-ui-tab-contents">
    <div class="ddp-essential-filter">
      <div class="ddp-ui-filter-result">
        <span
          [innerHTML]="
            'msg.board.ui.essential.info' | translate: { count: essentialFilters.length, dsName: dataSource.name }
          "
        ></span>
        <a (click)="showEssentialFilerPopup()" href="javascript:" class="ddp-btn-line-s">
          <em class="ddp-icon-btn-edit2"></em>
          {{ 'msg.comm.ui.edit' | translate }}
        </a>
      </div>

      <ul class="ddp-list-essential">
        <li *ngFor="let filter of essentialFilters">
          <div class="ddp-data-filter">
            <span class="ddp-txt-filter">
              <selfai-bi-shared-field-icon
                *ngIf="filter.type !== 'bound'"
                [view]="'DIMENSION'"
                [type]="filter['fieldObj'].type"
                [logicalType]="filter['fieldObj'].logicalType"
              ></selfai-bi-shared-field-icon>
              <selfai-bi-shared-field-icon
                *ngIf="filter.type === 'bound'"
                [view]="'MEASURE'"
                [type]="filter['fieldObj'].type"
                [logicalType]="filter['fieldObj'].logicalType"
              ></selfai-bi-shared-field-icon>

              {{ filter.field }}
            </span>
          </div>
          <div class="ddp-data-det" [innerHTML]="filter['panelContents']"></div>
        </li>
      </ul>
    </div>
  </div>
</div>

<ng-template let-join #renameJoinForm>
  <div class="join-rename-form">
    <div class="flex">
      <input class="mr-2" type="text" [(ngModel)]="join.joinAlias" />

      <button
        pButton
        (click)="saveRenameJoin(join)"
        class="p-button-text p-button-link p-0"
        appearance="icon"
        icon="pi pi-save"
        tooltipPosition="right"
        pTooltip="{{ 'msg.board.btn.add.datasource.join.rename' | translate }}"
      ></button>
    </div>

    <div *ngIf="validateJoinAlias(join) === false" class="alias-validate-error">
      {{ 'msg.board.btn.add.datasource.join.rename.invalid' | translate }}
    </div>
  </div>
</ng-template>
