import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AggregatorDictionariesApiModel } from '../../models';
import { AggregatorCoreApiService } from './aggregator-core-api.service';
import { AggrtConfigService } from './aggrt-config.service';

@Injectable({
  providedIn: 'root',
})
export class AggregatorDictionariesApiService extends AggregatorCoreApiService {
  loadDictionaries(): Observable<AggregatorDictionariesApiModel> {
    const url = this.buildUrl('/routes/getDictionaries');

    return this.http.get<AggregatorDictionariesApiModel>(url);
  }

  constructor(private readonly http: HttpClient, aggrtConfigService: AggrtConfigService) {
    super(aggrtConfigService);
  }
}
