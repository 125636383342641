<form [formGroup]="schedulesForm">
  <div class="grid">

    <div class="col-3 mb-5">
      <p-floatLabel>
        <p-dropdown
          id="preset"
          optionLabel="name"
          optionValue="id"
          [options]="presets"
          formControlName="preset"
          [style]="{ width: '100%' }"
        ></p-dropdown>
        <label for="preset">{{ 'schedules.form.preset' | translate }}</label>
      </p-floatLabel>
    </div>

    <div class="col-3 mb-5">
      <p-floatLabel>
        <p-dropdown
          id="timezone"
          optionLabel="name"
          optionValue="id"
          [options]="generateTimeZones()"
          formControlName="timeZone"
          [style]="{ width: '100%' }"
        ></p-dropdown>
        <label for="timezone">{{ 'schedules.form.timezone' | translate }}</label>
      </p-floatLabel>
    </div>

    <div class="col-4 mb-5 flex">
      <p-floatLabel>
        <input
          id="cron"
          class="w-full"
          pInputText
          formControlName="cron"
        />
        <label for="cron">{{ 'schedules.form.cron' | translate }}</label>
      </p-floatLabel>
      <i class="edit-cron-button pi pi-pen-to-square" (click)="toggleEditCronDialog()"></i>
    </div>

    <p-dialog header="{{ 'msg.workspace.settings.schedules.modal.cron-edit.title' | translate }}" [modal]="true" [(visible)]="cronEditorVisible" [style]="{ width: '75rem' }">
      <cron-jobs class="col-10" [config]="cronConfig" [validate]="cronValidate" formControlName="cron"></cron-jobs>

      <p-button
        label="{{ 'msg.workspace.settings.schedules.modal.cron-edit.button.cancel' | translate }}"
        severity="secondary"
        [outlined]="true"
        (click)="toggleEditCronDialog()"
      >
      </p-button>

      <p-button
        label="{{ 'msg.workspace.settings.schedules.modal.cron-edit.button.apply' | translate }}"
        severity="success"
        (click)="applyCronFromEditor()"
      >
      </p-button>
    </p-dialog>

    <div *ngIf="showBtn" class="col-2 mb-5 text-right flex align-items-start justify-content-end">
      <div class=" flex align-items-center">
        <i class="pi pi-info-circle mr-3" (mouseover)="op.show($event)" (mouseleave)="op.hide()"></i>

        <p-overlayPanel #op>{{ schedulesForm.get('cron').value || '&mdash;' }}</p-overlayPanel>

        <p-button
          icon="pi pi-ban"
          severity="secondary"
          [outlined]="true"
          pTooltip="{{ 'msg.workspace.settings.schedules.button.cancel' | translate }}"
          tooltipPosition="top"
          (click)="onCancel()">
        </p-button>

        <p-button
          icon="pi pi-save"
          pTooltip="{{ 'msg.workspace.settings.schedules.button.save' | translate }}"
          tooltipPosition="top"
          (click)="onSubmit()">
        </p-button>
      </div>
    </div>
  </div>
</form>
