export enum OperationType {
  STRING = 'string',
  BOOLEAN = 'boolean',
  NUMERIC = 'numeric',
  MULTIPLIER = 'multiplier',
  SELECTOR = 'selector',
  CHOICE = 'choice',
  DATASOURCE_ID_FOR_READ = 'datasourceIdForRead',
  CODE_SNIPPET = 'codeSnippet',
}
