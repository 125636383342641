import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { NgModel, ValidatorFn } from '@angular/forms';
import { KeRefButtonItem, KeRefButtonItemColors } from '@selfai-platform/pipeline-common';
import { DestroyService, USER_PROFILE_UNKNOWN_NAME, UserProfileService } from '@selfai-platform/shared';
import { Table } from 'primeng/table';
import { v4 as uuidv4 } from 'uuid';
import { KeRefButtonItemsService } from '../../services/ke-ref-button-items.service';

@Component({
  selector: 'selfai-platform-ke-ref-buttons-table',
  templateUrl: './ke-ref-buttons-table.component.html',
  styleUrls: ['./ke-ref-buttons-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
})
export class KeRefButtonsTableComponent implements OnInit {
  viewItems: KeRefButtonItem[] = [];
  colors = Object.values(KeRefButtonItemColors);

  @ViewChild('tableRef', { static: false, read: Table }) tableRef!: Table;

  readonly options: NgModel['options'] = { standalone: true, updateOn: 'blur' } as const;

  constructor(
    private readonly keRefButtonsService: KeRefButtonItemsService,
    private readonly userService: UserProfileService,
  ) {}

  readonly required: ValidatorFn = ({ value }) => {
    return value ? null : { required: 'Value cannot be empty' };
  };

  ngOnInit(): void {
    this.viewItems = this.keRefButtonsService.value;
  }

  filter(event: Event): void {
    this.tableRef.filterGlobal((event.target as HTMLInputElement).value, 'contains');
  }

  // TODO: fix on enter keydown save values
  onValueChange<K extends keyof KeRefButtonItem>(value: KeRefButtonItem[K], key: K, current: KeRefButtonItem): void {
    const updated: KeRefButtonItem = {
      ...current,
      [key]: value,
      modified: new Date(),
      modifiedAuthor: this.userService.value ? this.userService.value.username : USER_PROFILE_UNKNOWN_NAME,
    };
    const updatedItems = this.keRefButtonsService.value.map((item) => (item === current ? updated : item));
    this.keRefButtonsService.next(updatedItems);
    this.viewItems = this.viewItems?.map((item) => (item === current ? updated : item));
  }

  onEnterClick(event: Event): void {
    event.stopPropagation();
  }

  removeItem(itemForRemove: KeRefButtonItem): void {
    const updatedItems = this.keRefButtonsService.value.filter((item) => item !== itemForRemove);
    this.keRefButtonsService.next(updatedItems);
    this.viewItems = this.viewItems?.filter((item) => item !== itemForRemove);
  }

  addVariable(): void {
    const newItem: KeRefButtonItem = {
      description: '',
      modified: new Date(),
      modifiedAuthor: 'admin',
      id: uuidv4(),
      color: KeRefButtonItemColors.Primary,
      link: '',
      title: '',
    };

    const updatedItems = [...this.keRefButtonsService.value, newItem];
    this.keRefButtonsService.next(updatedItems);
    this.viewItems = [...this.viewItems, newItem];
  }

  toHintContent(names: string[]): string {
    return names.join('\n');
  }
}
