<div
  (mouseover)="setForceStyle(true)"
  (mouseout)="setForceStyle(false)"
  [ngClass]="{
    'ddp-active': isShowDownloadPopup,
    'ddp-popup-show': isShowDownloadPopup,
    'ddp-page-mapview': 'map' === chartType,
    'type-event-trigger': isShowEvtTriggerEditor,
    readonly: readonly
  }"
  class="ddp-box-widget ddp-box-graph page-widget"
  style="display: block"
>
  <div *ngIf="isViewMode && (!errorInfo || !errorInfo.show) && !isShowEvtTriggerEditor">
    <p-toolbar *ngIf="!readonly" class="page-widget-toolbar">
      <div class="p-toolbar-group-end align-items-start">
<!--        <div class="ddp-box-btn2" *ngIf="isRealTimeDs">-->
<!--          <em (click)="toggleSync()" class="ddp-icon-widget-synch" [class.ddp-on]="isRealTimeWidget"></em>-->

<!--          <div class="ddp-ui-tooltip-info">-->
<!--            <em class="ddp-icon-view-down"></em>-->
<!--            {{ 'msg.board.ui.sync' | translate }}-->
<!--          </div>-->
<!--        </div>-->

        <div *ngIf="limitInfo.isShow" class="mr-2 limit-info">
          <span
            [pTooltip]="limitInfoTooltip"
            class="pi pi-info-circle"
          ></span>
          <ng-template #limitInfoTooltip>
            <div
              [innerHTML]="
              'msg.page.ui.limit-msg-1' | translate
                : { currentCnt: limitInfo.currentCnt | numberCommas, maxCnt: limitInfo.maxCnt | numberCommas }
              ">
            </div>
            <div>
              {{ 'msg.page.ui.limit-msg-2' | translate }}
            </div>
          </ng-template>
        </div>

        <button
          pButton
          pRipple
          type="button"
          class="mr-2 p-button-outlined"
          *ngIf="isShowChartTools && isNotMapType"
          [icon]="duringDataDown ? 'pi pi-spinner' : 'pi pi-file-excel'"
          (click)="showPreviewDownData($event)"
          pTooltip="{{ 'msg.book.widget.header.savetable' | translate }}"
          tooltipPosition="bottom"
        ></button>

         <button
          pButton
          pRipple
          type="button"
          class="mr-2 p-button-outlined"
          *ngIf="isShowChartTools && !isGridType()"
          [icon]="duringImageDown ? 'pi pi-spinner' : 'pi pi-image'"
          (click)="downloadChartImage()"
          pTooltip="{{ 'msg.book.widget.header.savechart' | translate }}"
          tooltipPosition="bottom"
        ></button>

        <button
          pButton
          pRipple
          type="button"
          class="mr-2 p-button-outlined"
          *ngIf="isAuthMgmtViewMode"
          icon="pi pi-pencil"
          (click)="editWidget('MOVE_EDIT_WIDGET')"
          pTooltip="{{ 'msg.comm.ui.edit' | translate }}"
          tooltipPosition="bottom"
        ></button>

        <button
          pButton
          pRipple
          type="button"
          class="mr-2 p-button-outlined"
          [routerLink]="['/', kdRootRoute, 'preview', 'widget', widget.id]"
          [icon]="!isMaximize ? 'pi pi-window-maximize' : 'pi pi-window-minimize'"
          [pTooltip]="(isMaximize ? 'msg.comm.ui.edit' : 'msg.comm.ui.expansion') | translate"
          tooltipPosition="bottom"
        ></button>

        <!-- <div class="flex flex-column">
          <div class="page-widget-toolbar-gap mb-2"></div>
          <div
            class="flex flex-column"
            *ngIf="isShowChartTools && !isGridType() && chartFuncValidator.checkUseZoomByTypeString(chartType)"
          >
            <button
              pButton
              pRipple
              type="button"
              class="mr-2 p-button-outlined"
              icon="pi pi-search-plus"
              (click)="changeMouseZoomMode('zoomin')"
            ></button>
            <button
              pButton
              pRipple
              type="button"
              class="mr-2 p-button-outlined"
              icon="pi pi-search-minus"
              (click)="changeMouseZoomMode('zoomout')"
            ></button>
            <button
              pButton
              pRipple
              type="button"
              class="mr-2 p-button-outlined"
              icon="pi pi-undo"
              (click)="changeMouseZoomMode('revert')"
            ></button>
          </div>
        </div> -->

        <div class="ddp-ui-btnview" *ngIf="false">
          <div class="ddp-box-btn" [class.ddp-selected]="'chart' === widget.mode" (click)="changeMode('chart')">
            <em class="ddp-icon-widget-graph"></em>
            <div class="ddp-ui-tooltip-info">
              <em class="ddp-icon-view-down"></em>
              {{ 'msg.book.widget.header.chartview' | translate }}
            </div>
          </div>
          <div class="ddp-box-btn" [class.ddp-selected]="'grid' === widget.mode" (click)="changeMode('grid')">
            <em class="ddp-icon-widget-grid"></em>
            <div class="ddp-ui-tooltip-info">
              <em class="ddp-icon-view-down"></em>
              {{ 'msg.book.widget.header.tableview' | translate }}
            </div>
          </div>
        </div>
      </div>
    </p-toolbar>
  </div>

  <div *ngIf="isShowWidgetName()" class="ddp-ui-graph-name">
    <span class="ddp-data-name">{{ widget.name }}</span>
  </div>

  <div
    class="ddp-view-widget ddp-view-widget-chart"
    *ngIf="widget && uiOption"
    [class.ddp-view-name]="isShowWidgetName()"
    [style.display]="!isShowNoData && widget.mode === 'chart' ? 'block' : 'none'"
  >
    <bar-chart
      #chart
      *ngIf="chartType === 'bar'"
      [widgetId]="widget.id"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></bar-chart>
    <grid-chart
      #chart
      *ngIf="chartType === 'grid'"
      [widgetId]="widget.id"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></grid-chart>

    <!-- <input-grid-chart #chart *ngIf="chartType === 'input'"></input-grid-chart> -->

    <line-chart
      #chart
      *ngIf="chartType === 'line'"
      [widgetId]="widget.id"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></line-chart>

    <scatter-chart
      #chart
      *ngIf="chartType === 'scatter'"
      [widgetId]="widget.id"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></scatter-chart>

    <heatmap-chart
      #chart
      *ngIf="chartType === 'heatmap'"
      [widgetId]="widget.id"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></heatmap-chart>

    <pie-chart
      #chart
      *ngIf="chartType === 'pie'"
      [widgetId]="widget.id"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></pie-chart>

    <label-chart
      #chart
      *ngIf="chartType === 'label'"
      [widgetId]="widget.id"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      [query]="query"
      [widget]="widget"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></label-chart>

    <boxplot-chart
      #chart
      *ngIf="chartType === 'boxplot'"
      [widgetId]="widget.id"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></boxplot-chart>

    <waterfall-chart
      #chart
      *ngIf="chartType === 'waterfall'"
      [widgetId]="widget.id"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></waterfall-chart>

    <wordcloud-chart
      #chart
      *ngIf="chartType === 'wordcloud'"
      [widgetId]="widget.id"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></wordcloud-chart>

    <combine-chart
      #chart
      *ngIf="chartType === 'combine'"
      [widgetId]="widget.id"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></combine-chart>

    <treemap-chart
      #chart
      *ngIf="chartType === 'treemap'"
      [widgetId]="widget.id"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></treemap-chart>

    <radar-chart
      #chart
      *ngIf="chartType === 'radar'"
      [widgetId]="widget.id"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></radar-chart>

    <network-chart
      #chart
      *ngIf="chartType === 'graph'"
      [widgetId]="widget.id"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      [widgetId]="widget.id"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></network-chart>
    <selfai-bi-chart-graph-chart
      #chart
      *ngIf="chartType === 'graphv2' && !isShowNoData && widget.mode === 'chart'"
      [widgetId]="widget.id"
    ></selfai-bi-chart-graph-chart>
    <sankey-chart
      #chart
      *ngIf="chartType === 'sankey'"
      [widgetId]="widget.id"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></sankey-chart>
    <gauge-chart
      #chart
      *ngIf="chartType === 'gauge'"
      [widgetId]="widget.id"
      [uiOption]="uiOption"
      [isUpdateRedraw]="isUpdateRedraw"
      [userCustomFunction]="userCustomFunction"
      (chartSelectInfo)="chartSelectInfo($event)"
      (noData)="showNoData()"
      (drawFinished)="updateComplete()"
    ></gauge-chart>
  </div>

  <div
    [style.display]="!isShowNoData && widget.mode === 'grid' ? 'block' : 'none'"
    style="width: 100%; height: 100%"
    *ngIf="isAvaliableGrid(widget)"
  >
    <grid-chart #gridChart *ngIf="gridUiOption" [uiOption]="gridUiOption" [viewMode]="true"></grid-chart>
  </div>

  <div *ngIf="isShowHierarchyView" class="ddp-view-widget ddp-view-widget-grid">
    <div class="ddp-box-data-none">
      <div class="ddp-wrap-data-none">
        <div class="ddp-ui-data-none">
          <div class="ddp-txt-none">
            <em class="ddp-img-chartnodata"></em>
            <span class="ddp-data-name">{{ widget.name }}</span>
            <span
              class="ddp-txt-det"
              [innerHTML]="'msg.board.ui.select-data' | translate: { value: parentWidget.name }"
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div [style.display]="isInvalidPivot ? 'block' : 'none'" class="ddp-view-widget ddp-view-widget-grid">
    <div class="ddp-box-data-none">
      <div class="ddp-wrap-data-none">
        <div class="ddp-ui-data-none">
          <div class="ddp-txt-none">
            <selfai-bi-chart-icon
              class="ddp-error"
              [chartType]="widget?.configuration?.chart?.type"
            ></selfai-bi-chart-icon>
            <span class="ddp-data-name">{{ widget.name }}</span>
            <span class="ddp-txt-det" [innerHTML]="'msg.board.ui.invalid-pivot' | translate"></span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div [style.display]="isShowNoData ? 'block' : 'none'" class="ddp-view-widget ddp-view-widget-grid">
    <div class="ddp-box-data-none">
      <div class="ddp-wrap-data-none">
        <div class="ddp-ui-data-none">
          <div class="ddp-txt-none">
            <selfai-bi-chart-icon [chartType]="widget?.configuration?.chart?.type"></selfai-bi-chart-icon>
            <span class="ddp-data-name">{{ widget.name }}</span>
            <span class="ddp-txt-det">
              {{ 'msg.page.ui.no.data.show' | translate }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <error-widget
    [isShow]="isError"
    [iconClass]="widgetChartIconClass"
    [widgetName]="widget.name"
    [errorInfo]="errorInfo"
  ></error-widget>

  <div *ngIf="isEditMode" class="ddp-hover-edit">
    <div class="ddp-hover-edit-in">
      <button (click)="editWidgetHover($event)" type="button" class="ddp-btn-edit"></button>
    </div>
  </div>

  <p-dialog
    appendTo="body"
    [modal]="true"
    header=" {{ 'msg.board.ui.view-chart-data' | translate }}"
    [(visible)]="isShowDownloadPopup"
    [style]="{ width: '700px', height: '500px' }"
    contentStyleClass="bi-incapsulation relative"
  >
    <div class="ddp-ui-preview-contents ddp-type-preview ddp-pop-datadetail">
      <div class="ddp-type-top-option">
        <ul class="ddp-ui-tab ddp-type">
          <li [class.ddp-selected]="!isOriginDown">
            <a (click)="drawDataGrid()" href="javascript:">{{ 'msg.board.ui.aggregated-data' | translate }}</a>
          </li>
          <li [class.ddp-selected]="isOriginDown">
            <a (click)="drawDataGrid(true)" href="javascript:">{{ 'msg.board.ui.original-data' | translate }}</a>
          </li>
        </ul>

        <div *ngIf="!isCanNotDownAggr" class="ddp-ui-rightoption ddp-type-option">
          <div (click)="showDownloadLayer($event)" class="ddp-box-btn2 ddp-box-selected ddp-selected">
            <a href="javascript:" class="ddp-link-txt">
              <em class="ddp-icon-widget-gridsave"></em>
              Download
            </a>
          </div>
          <div class="ddp-box-btn2 ddp-box-selected ddp-selected">
            <a href="javascript:" class="ddp-link-txt" (click)="saveWidget()">
              <em class="ddp-icon-widget-gridsave"></em>
              Save
            </a>
          </div>

          <data-download
            [title]="
              isOriginDown
                ? ('msg.board.download.title-org' | translate)
                : ('msg.board.download.title-aggr' | translate)
            "
            [query]="query"
            (startDownload)="duringDataDown = true"
            (endDownload)="duringDataDown = false"
          ></data-download>
        </div>
      </div>

      <div class="ddp-wrap-tab-contents">
        <div class="ddp-ui-tab-contents">
          <div class="ddp-wrap-grid-option ddp-clear">
            <div class="ddp-fleft">
              <component-input
                [compType]="'search'"
                [value]="srchText"
                [placeHolder]="'Search data'"
                (changeValue)="setSearchText($event)"
              ></component-input>
            </div>
          </div>

          <div *ngIf="!isCanNotDownAggr" #dataGrid grid-component class="ddp-ui-grid2" style="top: 44px"></div>
          <div *ngIf="isCanNotDownAggr" class="ddp-ui-grid2 ddp-type">
            <div class="ddp-ui-error-message">
              {{ 'msg.board.ui.cant-not-down-aggregate' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-dialog>

  <div
    *ngIf="isShowEvtTriggerEditor"
    #userFuncInputContainer
    style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 1"
  >
    <div class="ddp-top-control ddp-clear">
      <div class="ddp-ui-buttons">
        <a (click)="saveUserFunc()" href="javascript:" class="ddp-btn-solid-type">
          {{ 'msg.comm.btn.save' | translate }}
        </a>
        <div class="ddp-box-btn2">
          <em (click)="closeUserFuncInput()" class="ddp-icon-widget-cut-w ddp-hover-tooltip">
            <div class="ddp-ui-tooltip-info ddp-down">
              <em class="ddp-icon-view-top"></em>
              {{ 'msg.comm.ui.del' | translate }}
            </div>
          </em>
        </div>
      </div>

      <div *ngIf="isShowWidgetName()" class="ddp-top-label">{{ widget.name }}</div>
    </div>
    <div class="ddp-view-widget" style="padding: 25px 0 0 0">
      <textarea
        #userFuncInput
        [ngModel]="userCustomFunction"
        class="ddp-textarea-widget"
        placeholder="P.H Event trigger script"
        style="background: white"
      ></textarea>
    </div>
  </div>
</div>
