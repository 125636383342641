import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { CommonUtil } from '../util/common.util';

@Pipe({
  name: 'numberCommas',
})
@Injectable()
export class NumberCommasPipe implements PipeTransform {
  transform(num: any, args?: any): string {
    return CommonUtil.numberWithCommas(num);
  }
}
