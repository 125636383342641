<div class="ddp-ui-chart-side ddp-show">
  <div class="ddp-ui-side-title">
    {{ 'msg.page.ui.secondary.axis.title' | translate }}
  </div>
  <div class="ddp-wrap-downmenu">
    <div *ngIf="possibleChartCheck('axis', uiOption.type)" class="ddp-box-down">
      <div class="ddp-wrap-divide" *ngIf="possibleChartCheck('yAxisTitle', uiOption.type)">
        <div class="ddp-divide2">
          <div class="ddp-wrap-option-slider">
            <span class="ddp-label-slider" style="width: 100px; display: block">
              {{ 'msg.page.chart.axis.show.subaxis' | translate }}
            </span>

            <div class="ddp-checkbox-slide ddp-checkbox-automatic2">
              <input
                type="checkbox"
                (click)="showSecondaryAxis()"
                [ngModel]="uiOption.secondaryAxis.disabled == undefined || !uiOption.secondaryAxis.disabled"
              />
              <label><span class="ddp-slide"></span></label>
            </div>
          </div>

          <div
            class="ddp-wrap-option-slider"
            *ngIf="uiOption.secondaryAxis.disabled == undefined || !uiOption.secondaryAxis.disabled"
          >
            <span class="ddp-label-slider" style="width: 100px; display: block">
              {{ 'msg.page.chart.axis.show.axis.title' | translate }}
            </span>

            <div class="ddp-checkbox-slide ddp-checkbox-automatic2">
              <input
                type="checkbox"
                (click)="showAxisName('sub_column', !uiOption.secondaryAxis.showName)"
                [ngModel]="uiOption.secondaryAxis.showName"
              />
              <label><span class="ddp-slide"></span></label>
            </div>
          </div>

          <component-input
            *ngIf="uiOption.secondaryAxis.disabled == undefined || !uiOption.secondaryAxis.disabled"
            [disabled]="!uiOption.secondaryAxis.showName"
            [inputClass]="'ddp-input-typebasic'"
            [placeHolder]="'msg.page.ui.y-axis.title.ph' | translate"
            [value]="nameUiOption.secondaryAxis.customName"
            (changeValue)="nameUiOption.secondaryAxis.customName = $event; axisName('column', 1, $event)"
          ></component-input>
        </div>
      </div>

      <axis-value-option
        *ngIf="isSecondaryAxis && (uiOption.secondaryAxis.disabled == undefined || !uiOption.secondaryAxis.disabled)"
        [axis]="uiOption.secondaryAxis"
        [uiOption]="uiOption"
        (changeValueAxis)="changeSecondaryAxisValue($event)"
        (changeBaseline)="changeSecondaryAxisBaseline($event)"
      ></axis-value-option>
    </div>
  </div>
</div>
